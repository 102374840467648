/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FiltersCategories from 'components/Pages/Categories/FiltersCategories';
import { Creators as ProductsByStoreCreators } from 'store/ducks/stores/producstByStore';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import appColors from 'utils/appColors';
import ProductsListCategory from '../Pages/Categories/ProductsListCategorySmall';
import notImage from 'images/Products/not_image.jpg';
import styles, { CategoriesContainer, BoxImage } from './styles';
import useQuery from 'hooks/useQuery';

const ProductsByStore = ({
  slug,
  storesByCode,
  action,
  selected,
  viewFilter,
}) => {
  const { productsByStore, productsByStoreTotal, productsByStoreLoading } =
    useSelector(state => state.stores.productsByStore);

  const dispatch = useDispatch();

  const query = useQuery();

  const [filters, setFilters] = React.useState({
    page: query.get('page') !== null ? Number(query.get('page')) : 1,
    per_page: 12,
    search: '',
    orderByColumn: '',
    orderByDirection: '',
    price_min: 0,
    price_max: 5000,
    sizes: [],
    colors: [],
    order: '1',
  });
  const { page, per_page } = filters;

  React.useEffect(() => {
    dispatch(ProductsByStoreCreators.getProductsByStoreRequest(filters, slug));
    setFilters({ ...filters, page: 1, per_page: 12 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  const onFormFiltersSubmit = values => {
    setFilters(values);
  };

  const nextPage = () => {
    if (page + 1 <= Math.ceil(productsByStoreTotal / per_page)) {
      setFilters({ ...filters, page: page + 1 });
    }
  };

  const previousPage = () => {
    if (page - 1 >= 1) {
      setFilters({ ...filters, page: page - 1 });
    }
  };

  const handlePage = selectPage => {
    setFilters({ ...filters, page: selectPage });
  };

  const getProducts = () => {
    return productsByStore?.filter(
      item => selected?.findIndex(select => select.id === item.id) === -1
    );
  };

  React.useEffect(() => {
    dispatch(ProductsByStoreCreators.getProductsByStoreRequest(filters, slug));
  }, [slug, filters]);

  const getImage =
    storesByCode.image && storesByCode.image.original
      ? storesByCode?.image?.original
      : notImage;

  return (
    <CategoriesContainer flex="1" style={{ width: '100%' }}>
      <Box
        display="flex"
        flex="1"
        flexDirection="row"
        flexWrap="wrap"
        style={{ marginTop: 0 }}>
        <Box style={{ display: viewFilter }}>
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            style={{ marginTop: 20 }}>
            <BoxImage flex="1" display="flex" justifyContent="flex-start">
              <img
                alt=""
                style={{ width: '100%', objectFit: 'cover' }}
                src={getImage}
              />
            </BoxImage>
            <FiltersCategories
              data={{ colors: [], sizes: [] }}
              onSubmit={onFormFiltersSubmit}
              initialValues={filters}
            />
          </Box>
        </Box>
        <Box flex="1" flexDirection="column">
          {productsByStore.length > 0 && !productsByStoreLoading > 0 && (
            <ProductsListCategory
              data={getProducts()}
              total={productsByStoreTotal}
              nextPage={nextPage}
              previousPage={previousPage}
              handlePage={handlePage}
              loading={productsByStoreLoading}
              page={page}
              perPage={per_page}
              action={action}
            />
          )}
          {!productsByStoreLoading && productsByStore.length === 0 && (
            <Box
              flex="1"
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={styles.boxEmpty}>
              <Typography style={styles.purpleTitle}>
                Nenhum resultado encontrado
              </Typography>
            </Box>
          )}
          {productsByStoreLoading && (
            <Box
              flex="1"
              display="flex"
              justifyContent="center"
              style={{ marginTop: 20 }}>
              <CircularProgress style={{ color: appColors.PRIMARY_COLOR }} />
            </Box>
          )}
        </Box>
      </Box>
    </CategoriesContainer>
  );
};

export default ProductsByStore;
