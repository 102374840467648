import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { ThemeProvider } from '@material-ui/core/styles';
import App from './containers/App';
import BuritiTheme from './BuritiTheme';
import AppProvider from 'hooks';
import { ChakraProvider } from '@chakra-ui/react';

import configureStore, { history } from './store/index';
import { themeChakra } from 'theme';

import 'focus-visible/dist/focus-visible';

const { persistor, store } = configureStore();

function ReactApp() {
  return (
    <>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ConnectedRouter history={history}>
            <ThemeProvider theme={BuritiTheme}>
              <ChakraProvider theme={themeChakra}>
                <AppProvider>
                  <App />
                </AppProvider>
              </ChakraProvider>
            </ThemeProvider>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </>
  );
}

export default ReactApp;
