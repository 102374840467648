import styled from 'styled-components';
import { Box } from '@material-ui/core';
import appColors from 'utils/appColors';

export const ProductDetailContainer = styled(Box)`
    && {
        width:1266px;
        margin-top:10px;
        margin-left:auto;
        margin-right:auto;

        @media all and (max-width:1650px) {
            margin-top:80px;
            width:90%;
        }
    }
`;

export const BoxImage = styled.div`
    && {
        flex: 1;

        @media all and (max-width:500px) {
            width:100%;

        }

        @media all and (min-width:850px) and (max-width:1270px) {
            max-height:420px;
        }
    }
`;

export const StyledBoxDetail = styled(Box)`
    && {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid ${appColors.PRIMARY_COLOR};

        @media all and (max-width:500px) {
            margin-left: -5%;
            margin-right: -5%;
            margin-top: -100px
        }

    }
`;
