import styled from 'styled-components';
import { Box } from '@material-ui/core';

export const Container = styled(Box)`
  display: flex;
`;

export const Content = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const Time = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: #555;
`;

export const Minutes = styled.span`
  font-size: 10px;
  padding: 0;
  color: #555;
  margin-top: -6px;
`;

export const Expired = styled.div`
  font-size: 10px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
