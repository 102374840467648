import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@material-ui/core';
import CustomModal from 'components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as AddressCreators } from 'store/ducks/address';
import appColors from 'utils/appColors';
import styles from './styles';
import NewAddressForm from './NewAddressForm';
import ConsultCepForm from './ConsultCepForm';


const NewAddressModal = ({ isOpen, closeModal, address, onSubmitCEP, addressLoading, addressError, isNew = false }) => {

  const dispatch = useDispatch();
  const { addressInsertLoading } = useSelector(state => state.address);
  const onSubmit = values => {
    dispatch(AddressCreators.getAddressInsertRequest(values, closeModal));
  };

  return (
    <CustomModal isOpen={isOpen} closeModal={closeModal} title="Novo Endereço">
      <Box display="flex" justifyContent="center" flexDirection="column" flex="1" style={styles.containerForm}>
        {Object.keys(address).length > 0 && !addressLoading && (
          <NewAddressForm onSubmit={onSubmit} onSubmitCEP={onSubmitCEP} cepError={addressError} addressInsertLoading={addressInsertLoading} initialValues={address} />
        )}
        {Object.keys(address).length === 0 && !addressLoading && (
          <ConsultCepForm onSubmit={onSubmitCEP} addressLoading={addressLoading} cepError={addressError} />
        )}
        {addressLoading && (
          <Box flex="1" display="flex" justifyContent="center" alignItems="center">
            <CircularProgress style={{ color: appColors.PRIMARY_COLOR }} />
          </Box>
        )}
      </Box>
    </CustomModal>
  );
};

NewAddressModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  address: PropTypes.object.isRequired,
  onSubmitCEP: PropTypes.func.isRequired,
  addressLoading: PropTypes.bool.isRequired,
  addressError: PropTypes.any,
};

export default NewAddressModal;
