/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as ProductCreators } from 'store/ducks/product';
import { Creators as StoresListCreators } from 'store/ducks/stores/list';
import { CircularProgress, Typography, Box } from '@material-ui/core';
import ProductsList from 'components/Pages/Home/ProductsList';
import Carrossel from 'components/Carrossel';
import { Creators as HomeCreators } from 'store/ducks/home';
import CategoriesSlide from 'components/Pages/Home/CategoriesSlide';
import { SkeletonCarousel } from 'components/Carrossel/styles';
import StoresSlide from 'components/Pages/Home/StoresSlide';
import InfoBeneficios from 'components/Pages/Home/InfoBeneficios';
import ConfigMessage from 'components/Pages/Home/ConfigMessage';
import InfoExtra from 'components/Pages/Home/InfoExtra';
import appColors from 'utils/appColors';
import styles, { BoxContainer } from './styles';
import { Helmet } from 'react-helmet';
import appNaming from 'utils/appNaming';

const HomePageBuriti = ({ match }) => {
  // Verifica largura da tela
  const [width, setWidth] = React.useState(window.innerWidth);
  const [imagesBanner, setImagesBanner] = React.useState([]);
  // Filtros Lojas
  const [filters, setFilters] = React.useState({
    page: 1,
    perPage: 9999,
    search: '',
    orderByColumn: '',
    orderByDirection: '',
    order: '',
  });

  const dispatch = useDispatch();

  const { productByCategory, productByCategoryLoading } = useSelector(
    state => state.product.product
  );
  const { categoryList, categoryListLoading } = useSelector(
    state => state.category.list
  );
  const { menu_all, shopping } = useSelector(state => state.config.config);
  const { storesList, storesLoading, storesListTotal } = useSelector(
    state => state.stores.list
  );
  // Categorias Home
  const { categoryListSelected, categoryListSelectedLoading } = useSelector(
    state => state.product.product
  );
  const { categoryListNews, categoryListNewsLoading } = useSelector(
    state => state.product.product
  );
  const { categoryListSales, categoryListSalesLoading } = useSelector(
    state => state.product.product
  );
  const { preco } = useSelector(state => state.parameters);

  const { categoryName } = useSelector(state => state.category.category);
  const { home, homeLoading } = useSelector(state => state.home);

  const { banner_principal, categories_principal } = home;

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    dispatch(HomeCreators.getHomeRequest());

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // acaba aqui

  React.useEffect(() => {
    if (categoryList.length > 0) {
      const filters = {
        page: 1,
        perPage: 8,
        search: '',
        orderByColumn: '',
        orderByDirection: '',
      };
      dispatch(
        ProductCreators.getProductsByCategoryRequest(
          filters,
          categoryList[0].id
        )
      );
      // Por Categoria Home
      dispatch(ProductCreators.getCategorySelectedRequest(filters));
      dispatch(ProductCreators.getCategoryListNewsRequest(filters));
      dispatch(ProductCreators.getCategoryListSalesRequest(filters));
    }
  }, [categoryList]);

  const { perPage, page } = filters;
  const addPage = () => {
    const totalPages =
      storesListTotal % perPage === 0
        ? Math.floor(storesListTotal / perPage)
        : Math.floor(storesListTotal / perPage) + 1;
    if (totalPages && page + 1 > totalPages) return null;
    setFilters({ ...filters, page: page + 1 });
  };

  React.useEffect(() => {
    dispatch(StoresListCreators.getStoresListRequest(filters));
  }, [filters]);

  React.useEffect(() => {
    if (Object.keys(home).length > 0) {
      if (width <= 500) {
        setImagesBanner(
          banner_principal.images.filter(item => item.show_mobile === true)
        );
      } else if (width > 500 && width <= 1024) {
        setImagesBanner(
          banner_principal.images.filter(item => item.show_tablet === true)
        );
      } else {
        setImagesBanner(
          banner_principal.images.filter(item => item.show_desktop === true)
        );
      }
    }
  }, [width, home]);

  return (
    <>
      <Helmet>
        <meta name="kdt:page" content="home" charSet="utf-8" />
        <meta name="kdt:home" content="home" charSet="utf-8" />
        <title>
          {appNaming.TITLE}
        </title>
        <link rel="canonical" href="/" />
      </Helmet>
      {homeLoading ? (
        <Box
          marginBottom="700px"
          marginTop="300px"
          flex="1"
          display="flex"
          justifyContent="center"
          alignItems="center">
          {/* INTEGRAÇÃO KDT = HOME */}

          <CircularProgress style={{ color: appColors.PRIMARY_COLOR }} />
        </Box>
      ) : (
        <>
          {imagesBanner.length > 0 && (
            <>
              {Object.keys(home).length > 0 && !homeLoading && (
                <Carrossel images={imagesBanner} />
              )}
            </>
          )}
          {homeLoading && <SkeletonCarousel width={'100%'} />}
          <BoxContainer
            display="flex"
            flex="1"
            flexDirection="column"
            style={{ padding: '0px 20px' }}>
            {menu_all.length > 0 && <CategoriesSlide data={menu_all} />}
            {shopping && shopping.closed && (
              <ConfigMessage data={shopping.closed} />
            )}
            <BoxContainer display="flex" flex="1" flexDirection="column">
              <StoresSlide
                title="Lojas em Destaque"
                subtitle="Clique na sua loja preferida e conheça todas as novidades!"
                onEndScroll={addPage}
                data={storesList}
              />
            </BoxContainer>
            {Object.keys(home).length > 0 && !homeLoading && (
              <>
                {categories_principal.map(item => (
                  <ProductsList
                    preco={preco}
                    key={item.code_internal}
                    title={item.name}
                    subtitle={item.description}
                    data={item.products}
                  />
                ))}
              </>
            )}
          </BoxContainer>
          {/* <InfoExtra /> */}

          {/* <InfoBeneficios /> */}
        </>
      )}
    </>
  );
};

/*
    <MainCategories />
*/

export default HomePageBuriti;
