/* eslint-disable react/button-has-type */
import React from 'react';
import api from 'services/api';
import { CircularProgress } from '@material-ui/core';
import CustomButton from 'components/form/components/CustomButton';
import DescriptionIcon from '@material-ui/icons/Description';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as OrderCreators } from 'store/ducks/order';
import useQuery from 'hooks/useQuery';
import * as S from './style';

const InvoceForm = () => {
  const dispatch = useDispatch();
  const inputRef = React.useRef();
  const query = useQuery();
  const { order } = useSelector(state => state.order);
  const [item, setItem] = React.useState();

  async function getFiles() {
    let response = '';
    try {
      response = await api.get(
        `/v1/admin/attachments/${query.get('id_pedido')}`
      );
      console.log(response.data.data);
    } catch {
      setFileError('Não foi buscar o arquivo, tente novamente mais tarde');
    }
    if (response.data.data !== null) {
      const data = response.data.data;
      setItem(data);
    }
    setFileLoading(false);
  }

  React.useEffect(() => {
    getFiles();
    dispatch(
      { type: 'GET_ORDER_FILES' },
      OrderCreators.getOrderFiles({ id: query.get('id_pedido') })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, order.id]);

  const [fileLoading, setFileLoading] = React.useState(false);
  const [fileError, setFileError] = React.useState(null);

  const [file, setFile] = React.useState('');

  const onChangeHandler = async event => {
    if (event.target.files.length > 0) setFile(event.target.files[0].name);
    else setFile(null);
  };

  const handleSubmit = async () => {
    setFileLoading(true);
    setFileError(null);
    if (!file) setFileError('Selecione o arquivo para continuar.');
    else {
      try {
        const data = new FormData();
        let file_type = inputRef.current.files[0]['type'];
        let flag_size = false;
        let flag_type = false;
        let size = inputRef.current.files[0]['size'];
        if (size < 500000) flag_size = true;
        let types = ['pdf', 'docx', 'jpeg', 'gif', 'jpg', 'mpeg', 'png', 'svg'];
        for (let i = 0; i < types.length; i++) {
          let position = '';
          position = file_type.search(types[i]);
          if (position !== -1) {
            flag_type = true;
            break;
          }
        }
        if (!flag_size && !flag_type) {
          setFileError(
            'Tipo do arquivo inválido ou muito grande, tente novamente'
          );
          setFileLoading(false);
          return false;
        }
        data.append('file', inputRef.current.files[0]);
        data.append('order_id', query.get('id_pedido'));
        data.append('status', inputRef.current.files[0]['name']);
        data.append('name', inputRef.current.files[0]['name']);
        const response = await api.post('/v1/admin/attachments/files', data);
        if (response.data.status === 'OK') {
          setFileError(null);
          setFileLoading(false);

          setFile('');

          inputRef.current.value = null;
          window.location.reload();
        } else {
          setFileError(
            'Não foi possível enviar o arquivo, tente novamente mais tarde'
          );
          setFileLoading(false);
        }
      } catch (err) {
        setFileError(
          'Não foi possível enviar o arquivo, tente novamente mais tarde'
        );
        setFileLoading(false);
      }
    }
    setFileLoading(false);
  };

  return (
    <>
      {item ? (
        <S.Box>
          <a
            href={item.file.original}
            rel="noopener noreferrer"
            target="_blank">
            <DescriptionIcon />
          </a>
          <a
            href={item.file.original}
            rel="noopener noreferrer"
            target="_blank"
            data-cy="attached_file">
            {item.name}
          </a>
        </S.Box>
      ) : (
        <S.Header>
          <TextField
            label="Arquivo"
            type="search"
            variant="outlined"
            InputProps={{
              readOnly: true,
              value: file,
            }}
            value={file}
            onClick={() => !fileLoading && inputRef.current.click()}
          />
          {fileLoading ? (
            <CircularProgress
              style={{ position: 'relative', left: '30px', top: '10px' }}
            />
          ) : (
            <CustomButton
              onClick={() => handleSubmit()}
              label="Enviar Arquivo"
              style={{ width: '170px', height: '55px', marginLeft: '10px' }}
              data-cy="btn_send_file"
            />
          )}
        </S.Header>
      )}
      {fileError && (
        <div
          style={{
            width: '100%',
            margin: '10px 20px',
            textAlign: 'center',
            fontWeight: 'bold',
            backgroundColor: '#d73d32',
            color: '#fff',
          }}>
          {fileError}
        </div>
      )}
      <input
        type="file"
        name="file"
        ref={inputRef}
        onChange={onChangeHandler}
        style={{ display: 'none' }}
      />
    </>
  );
};

export default InvoceForm;
