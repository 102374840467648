import { call, takeLatest, all, put } from 'redux-saga/effects';
import api from 'services/api';
import { Types, Creators } from 'store/ducks/admin/dashboard';
import { callApi } from 'store/sagas/auth';

function* getDashboard() {
  try {
    const request = call(api.get, '/v1/admin/dashboard');
    const response = yield call(callApi, request);
    if (response.status !== 200 && response.status !== 201) throw response;
    yield put(Creators.getDashboardSuccess(response.data));
  } catch (err) {
    yield put(Creators.getDashboardFailure('Erro ao buscar na API'));
  }
}

// Individual exports for testing
export default function* dashboardSaga() {
  yield all([takeLatest(Types.GET_DASHBOARD_REQUEST, getDashboard)]);
}
