import { useLocation } from 'react-router-dom';

function useQuery() {
  /*
    import useQuery from 'hooks/useQuery';
    const query = useQuery();
    query.get('hash')
   */
  return new URLSearchParams(useLocation().search);
}

export default useQuery;
