/*
 *
 * Report reducer
 *
 */

export const Types = {
  // Obtem catergorias
  GET_DASHBOARD_REQUEST: 'dashboard/GET_DASHBOARD_REQUEST',
  GET_DASHBOARD_SUCCESS: 'dashboard/GET_DASHBOARD_SUCCESS',
  GET_DASHBOARD_FAILURE: 'dashboard/GET_DASHBOARD_FAILURE',
};

export const initialState = {
  // Categoria por id
  dashboard: [],
  dashboardLoading: false,
  dashboardError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Categoria por id
    case Types.GET_DASHBOARD_REQUEST:
      return {
        ...state,
        dashboard: {},
        dashboardError: null,
        dashboardLoading: true,
      };
    case Types.GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboard: action.payload.data,
        dashboardLoading: false,
        dashboardError: null,
      };
    case Types.GET_DASHBOARD_FAILURE:
      return {
        ...state,
        dashboardLoading: false,
        dashboardError: action.payload,
      };

    default:
      return state;
  }
};

export const Creators = {
  // Busca uma categoria
  getDashboardRequest: () => {
    return {
      type: Types.GET_DASHBOARD_REQUEST,
    };
  },
  getDashboardSuccess: ({ data }) => ({
    type: Types.GET_DASHBOARD_SUCCESS,
    payload: { data },
  }),
  getDashboardFailure: error => ({
    type: Types.GET_DASHBOARD_FAILURE,
    payload: error,
  }),
};
