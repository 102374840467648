import React from 'react';
import LoginPage from '../LoginPage';
import { useSelector } from 'react-redux';
import DashboardUser from 'Pages/Account';

const MyAccountPage = ({ location }) => {
  const {
    data: { access_token },
  } = useSelector(state => state.auth);

  if (access_token === '') return <LoginPage link={location.params} />;
  else return <DashboardUser />;
};

export default MyAccountPage;
