// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import SearchIcon from '@material-ui/icons/Search';
import PersonIcon from '@material-ui/icons/Person';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import FavoriteIcon from '@material-ui/icons/Favorite';
import DehazeIcon from '@material-ui/icons/Dehaze';
import {
  Tabs,
  Box,
  createMuiTheme,
  MuiThemeProvider,
  CircularProgress,
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { type CheckoutCartType } from 'store/ducks/checkout/cart';
import { Creators as AppCreators } from 'store/ducks/app';
import CategoriesSlideDesktop from 'components/Pages/Home/CategoriesSlideDesktop';
import appColors from 'utils/appColors';
import CloseIcon from '@material-ui/icons/Close';
import styles, {
  BoxContainer,
  ImgLogo,
  WhiteMenu,
  TabStyle,
  BoxMenuCategory,
  BarContainer,
  LogoWhite,
  SearchPanel,
  PanelButtonsDesktop,
  PanelButtonsMobile,
  ButtonIcon,
} from './styles';
import SearchForm from './searchForm';
import ClientToogleMenu from 'components/ToogleMenu/ClientToogleMenu';
import ShopToogleMenu from 'components/ToogleMenu/ShopToogleMenu';
import ResellerToogleMenu from 'components/ToogleMenu/ResellerToogleMenu';
import { Creators as ClientCreators } from 'store/ducks/client';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles(theme => ({
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    // pointerEvents: 'none',
    right: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'white',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  inputSearch: {
    width: '100%',
    height: '100%',
  },
  inputInputMobi: {
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  searchIconMobi: {
    color: 'black',
    marginTop: 5,
    marginRight: 10,
  },
  containerInputMobi: {
    width: '100%',
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '100%',
    position: 'absolute',
    top: 0,
    zIndex: 999,
    backgroundColor: 'white',
    boxShadow: '0px 1px 10px -2px black',
  },
  contentIconMobi: {
    display: 'none',
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 5,
    paddingRight: 10,
    paddingLeft: 10,
    backgroundColor: appColors.SECUNDARY_COLOR,
    borderRadius: 5,
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
    },
  },
}));

const newTheme = createMuiTheme({
  palette: {
    primary: {
      // works
      main: '#165788',
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiTab: {
      root: {
        padding: 10,
        margin: '6px 12px',
        '&$selected': {
          color: appColors.PRIMARY_COLOR,
        },
      },
    },
  },
});

const HeaderMenu = ({ categoryList, pagesList }) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const checkoutCartStore: CheckoutCartType = useSelector(
    state => state.checkout.cart
  );
  const { isAuth } = useSelector(state => state.auth);
  const { page } = useSelector(state => state.app);
  const totalItems = checkoutCartStore.list?.reduce(
    (accumulator, item) => accumulator + item.quantity,
    0
  );

  const [localState, setLocalState] = React.useState({
    mobileMoreAnchorEl: null,
    redirect: null,
    search: '',
    searchOpen: false,
    menuCategoryOpen: false,
  });

  const { searchOpen, menuCategoryOpen } = localState;

  const handleChange = (event, newValue) => {
    dispatch(AppCreators.getUpdatePage(newValue));
  };

  const handleCaregoryOpen = () => {
    if (!menuCategoryOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    setLocalState({ ...localState, menuCategoryOpen: !menuCategoryOpen });
  };

  const onSubmitSearch = ({ search }) => {
    history.push({
      pathname: `/pesquisa/${search}`,
    });
  };

  const openSearchMobi = () => {
    setLocalState({
      ...localState,
      searchOpen: true,
    });
  };

  const closeSearchMobi = () => {
    setLocalState({
      ...localState,
      searchOpen: false,
    });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    if (!!client.group && client.group.id === 5) history.push('/minha-conta');
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { client, clientLoading } = useSelector(state => state.client);
  const { image } = useSelector(state => state.config.config.shopping);

  // const { tab, params } = useSelector(state => state.panelTabs);

  const [clientLoadingFirst, setClientLoadingFirst] = React.useState(true);

  React.useEffect(() => {
    dispatch(ClientCreators.getClientRequest());
  }, [dispatch]);

  // const handleTab = (value, tabParams = {}) => {
  //   dispatch(PanelTabCreators.changeTab(value, tabParams));
  // };

  React.useEffect(() => {
    if (clientLoadingFirst && clientLoading === false && client.name) {
      setClientLoadingFirst(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientLoading, client]);

  // const logout = () => {
  //   dispatch(AuthCreators.getLogoutRequest());
  // };
  return (
    <BarContainer>
      <AppBar
        position="fixed"
        style={{ flex: 1, boxShadow: 'none', background: 'red' }}>
        <div
          className={classes.containerInputMobi}
          style={{ display: !searchOpen ? 'none' : '' }}>
          <SearchForm
            isMobile
            onSubmit={onSubmitSearch}
            closeSearchMobi={closeSearchMobi}
          />
        </div>
        <Toolbar
          variant="dense"
          style={{
            display: 'flex',
            backgroundColor: appColors.HEADER_COLOR,
            borderBottom: `4px solid ${appColors.PRIMARY_COLOR}`,
          }}>
          <BoxContainer>
            <LogoWhite>
              <Link
                to="/"
                style={{
                  height: '100%',
                  textDecoration: 'none',
                  color: 'inherit',
                }}>
                {image && image.small ? (
                  <ImgLogo src={image.small} />
                ) : (
                  <CircularProgress />
                )}
              </Link>
            </LogoWhite>

            <SearchPanel>
              <SearchForm isMobile={false} onSubmit={onSubmitSearch} />
            </SearchPanel>

            <PanelButtonsDesktop>
              <Link
                to="/meus-desejos"
                style={{
                  height: '100%',
                  textDecoration: 'none',
                  color: 'inherit',
                }}>
                <IconButton color="inherit" style={styles.contentIcon}>
                  <FavoriteIcon style={styles.iconsPrimaryMenu} />
                  <Typography
                    style={{
                      marginLeft: 10,
                      fontWeight: 'bold',
                      minWidth: '130px',
                      color: appColors.COLOR_FONT_MENU,
                    }}>
                    Lista de Desejos
                  </Typography>
                </IconButton>
              </Link>
              {isAuth ? (
                <IconButton
                  color="inherit"
                  style={styles.contentIconRadius}
                  onClick={handleClick}>
                  <PersonIcon style={styles.iconPersonalMenu} />
                  <Typography style={{ color: appColors.COLOR_FONT_MENU }}>
                    <b>Painel</b>
                  </Typography>
                </IconButton>
              ) : (
                <Link
                  to="/minha-conta"
                  style={{
                    height: '100%',
                    textDecoration: 'none',
                    color: 'inherit',
                  }}>
                  <IconButton color="inherit" style={styles.contentIconRadius}>
                    <PersonIcon style={styles.iconPersonalMenu} />
                    <Typography style={{ color: appColors.COLOR_FONT_MENU }}>
                      <b>Entrar</b>
                    </Typography>
                  </IconButton>
                </Link>
              )}
              {isAuth && (
                <>
                  {Object.keys(client).length > 0 && (
                    <React.Fragment>
                      {(client.group.id === 5 ||
                        client.group.id === 6 ||
                        client.group.id === 7) && (
                        <ClientToogleMenu
                          anchorEl={anchorEl}
                          handleClose={handleClose}
                        />
                      )}
                      {(client.group.id === 3 || client.group.id === 2) && (
                        <ShopToogleMenu
                          anchorEl={anchorEl}
                          handleClose={handleClose}
                        />
                      )}
                      {client.group.id === 4 && (
                        <ResellerToogleMenu
                          anchorEl={anchorEl}
                          handleClose={handleClose}
                        />
                      )}
                    </React.Fragment>
                  )}
                </>
              )}

              {/* Menu antigo => <ToogleMenu anchorEl={anchorEl} handleClose={handleClose} /> */}

              <Link
                to="/carrinho"
                style={{ textDecoration: 'none', color: 'white' }}>
                <IconButton style={styles.contentIcon} color="inherit">
                  <Badge
                    className={classes.margin}
                    badgeContent={totalItems}
                    color="primary">
                    <LocalMallIcon style={styles.iconsPrimaryMenu} />
                  </Badge>
                </IconButton>
              </Link>
            </PanelButtonsDesktop>

            <PanelButtonsMobile>
              <ButtonIcon
                color="inherit"
                onClick={openSearchMobi}
                className={classes.contentIconMobi}>
                <SearchIcon />
              </ButtonIcon>

              <Link
                to="/carrinho"
                style={{ textDecoration: 'none', color: 'white' }}>
                <ButtonIcon>
                  <FavoriteIcon style={styles.iconsPrimaryMenuMobi} />
                  <Typography>0</Typography>
                </ButtonIcon>
              </Link>

              <Link
                to="/carrinho"
                style={{ textDecoration: 'none', color: 'white' }}>
                <ButtonIcon>
                  <LocalMallIcon style={styles.iconsPrimaryMenuMobi} />
                  <Typography>{totalItems}</Typography>
                </ButtonIcon>
              </Link>

              <Link
                to="/minha-conta"
                style={{ textDecoration: 'none', color: 'inherit' }}>
                <ButtonIcon person>
                  <PersonIcon />
                </ButtonIcon>
              </Link>
            </PanelButtonsMobile>
          </BoxContainer>
        </Toolbar>
      </AppBar>
      <Box
        flex="1"
        display="flex"
        flexDirection="column"
        style={{ marginTop: '58px' }}>
        <WhiteMenu position="static">
          <Toolbar style={styles.secondaryToolbar}>
            <BoxContainer display="flex" flexDirection="row" flexWrap="wrap">
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                onClick={handleCaregoryOpen}>
                {!menuCategoryOpen ? (
                  <DehazeIcon
                    fontSize="large"
                    style={{
                      color: appColors.TOPBAR_COLOR_FONT,
                      marginRight: 10,
                    }}
                  />
                ) : (
                  <CloseIcon
                    fontSize="large"
                    style={{
                      color: appColors.TOPBAR_COLOR_FONT,
                      marginRight: 10,
                    }}
                  />
                )}
                <Typography>
                  <b
                    style={{
                      color: appColors.TOPBAR_COLOR_FONT,
                      cursor: 'pointer',
                    }}>
                    Todas as Categorias
                  </b>
                </Typography>
              </Box>

              <MuiThemeProvider theme={newTheme}>
                <Tabs
                  variant="scrollable"
                  scrollButtons="on"
                  value={page}
                  style={{ display: 'flex', flex: 1, color: '#333333' }}
                  onChange={handleChange}
                  aria-label="wrapped label tabs example"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: appColors.PRIMARY_COLOR,
                      height: 0,
                    },
                  }}>
                  <TabStyle
                    onClick={() => {
                      history.push('/');
                    }}
                    label={
                      <span
                        to="/"
                        style={{
                          textDecoration: 'none',
                          color: appColors.TOPBAR_COLOR_FONT,
                        }}>
                        Início
                      </span>
                    }
                  />
                  {pagesList.map((item, index) => (
                    <>
                      {/* INTEGRAÇÃO KDT = CATEGORIAS */}
                      <Helmet>
                        <meta name="kdt:page" content="category" />
                        {/* <meta
                          name="kdt:category"
                          content={`sku:${item.id}, name=${item.name}`}
                          charSet="utf-8"
                        />*/}
                      </Helmet>
                      <TabStyle
                        key={index}
                        onClick={() => {
                          history.push({
                            pathname: `${item.route_web}`,
                          });
                        }}
                        label={
                          <span
                            style={{
                              textDecoration: 'none',
                              color: 'inherit',
                            }}>
                            {item.name}
                          </span>
                        }
                      />
                    </>
                  ))}
                </Tabs>
              </MuiThemeProvider>
            </BoxContainer>
          </Toolbar>
        </WhiteMenu>
        <BoxMenuCategory isOpen={menuCategoryOpen}>
          {categoryList.length > 0 && (
            <CategoriesSlideDesktop
              onClose={handleCaregoryOpen}
              data={categoryList}
            />
          )}
        </BoxMenuCategory>
      </Box>
    </BarContainer>
  );
};

HeaderMenu.propTypes = {
  totalItems: PropTypes.number,
  categoryList: PropTypes.array.isRequired,
  pagesList: PropTypes.array.isRequired,
};

HeaderMenu.defaultProps = {
  totalItems: 0,
};

export default HeaderMenu;
