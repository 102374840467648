import React from 'react';
import GoogleMapReact from 'google-map-react';
import Point from 'assets/map-point.png';
import { key as googleMapskey } from 'services/maps';

const MapComponent = ({ coordinates, handleClick }) => {
  const AnyReactComponent = ({ point }) => (
    <div>
      <img alt="" style={{ height: '45px', width: 'auto' }} src={point} />
    </div>
  );

  const handleClickMap = ({ lat, lng }) => {
    const location = {};

    location.lat = lat;
    location.lng = lng;

    handleClick(location);
  };

  if (!coordinates.lat) return null;

  return (
    <div style={{ height: '300px', width: '100%' }}>
      <GoogleMapReact
        options={{ fullscreenControl: false }}
        bootstrapURLKeys={{ key: googleMapskey }}
        defaultCenter={coordinates}
        yesIWantToUseGoogleMapApiInternals
        defaultZoom={15}
        onClick={handleClickMap}>
        <AnyReactComponent
          lat={coordinates.lat}
          lng={coordinates.lng}
          text="My Marker"
          point={Point}
        />
      </GoogleMapReact>
    </div>
  );
};

export default MapComponent;
