import React, { useState } from 'react';
import * as S from './styles';
import moment from 'moment';
import appColors from 'utils/appColors';
import Countdown from 'react-countdown';

const CountDown = ({ accepted, status, preparation = 20 }) => {
  const [time] = useState(() => {
    if (accepted && accepted.trim() !== '' && moment(accepted).isValid()) {
      return new moment(accepted).toDate().getTime() + preparation * 60000;
    }

    return new moment().toDate().getTime() + preparation * 60000;
  });

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return <span style={{ color: appColors.DANGER }}>00:00</span>;
    } else {
      return (
        <span style={{ color: appColors.PRIMARY_COLOR }}>
          {minutes}:{seconds}
        </span>
      );
    }
  };

  if (status !== 4 && status !== 5) return false;

  return (
    <S.Container>
      {time && <Countdown renderer={renderer} date={time} />}
    </S.Container>
  );
};

export default CountDown;
