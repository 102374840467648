import CustomButton from 'components/form/CustomButton';
import styled from 'styled-components';

export const InputView = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
`;

export const InputItem = styled.div`
  flex-grow: ${props => props.flexGrow || 1};
  margin-right: 8px;
  @media all and (max-width: 500px) {
    margin: 15px 0px 0px 0px;
  }
  margin-top: 15px !important;
`;

export const StyledInput = styled.input`
  && {
    opacity: 0;
    max-width: 1px;
    display: block;
    height: 1px;
  }
`;

export const StyledCustomButton = styled(CustomButton)`
  && {
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
  }
`;
