import { useEffect } from 'react';

const kondutoVisitorScript = () => `
    var visitorID; 
    (function() {     
        var period = 300;     
        var limit = 20 * 1e3;     
        var nTry = 0;     
        var intervalID = setInterval(function() {         
            var clear = limit/period <= ++nTry;         
            if ((typeof(Konduto) !== "undefined") &&
                    (typeof(Konduto.getVisitorID) !== "undefined")) {             
                visitorID = window.Konduto.getVisitorID();             
                clear = true;         
            }         
            if (clear) {
                clearInterval(intervalID);
            }     
        }, period);
    })(visitorID);`;

const useKondutoVisitor = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = kondutoVisitorScript();
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);
};

export default useKondutoVisitor;