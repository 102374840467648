/* eslint-disable react/no-unescaped-entities */
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Switch,
  Typography,
} from '@material-ui/core';
import CustomCurrencyField from 'components/form/components/CustomCurrencyField';
import { Creators as StoreCreators } from 'store/ducks/admin/stores';
import { Creators as NotificationCreators } from 'store/ducks/app';
import CustomMaskField2 from 'components/form/components/CustomMaskField2';
import { FastField, Formik } from 'formik';
import React, { useState } from 'react';
import { useRef } from 'react';
import api from 'services/api';
import appTheme from 'utils/appTheme';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import appUtils from 'utils/appUtils';
import moment from 'moment';

const schema = Yup.object().shape({
  delivery_fixed_time: Yup.number()
    .test('len', 'Deve ser maior ou igual a 20', val => val >= 20)
    .required('Campo obrigatório'),
  delivery_km_time: Yup.number().required('Campo obrigatório'),
  delivery_fixed_price: Yup.number().required('Campo obrigatório'),
  delivery_km_price: Yup.number().required('Campo obrigatório'),
});

const initial = {
  delivery_fixed_time: '',
  delivery_km_time: '',
  delivery_fixed_price: '',
  delivery_km_price: '',
};

const style_inupt = { margin: '5px 0px' };

const DeliveryCharges = ({ store }) => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const app_name = appTheme.name;
  const [useBluemap, setUseBluemap] = useState(false);

  const {
    config: { shopping },
  } = useSelector(state => state.config);
  const { shipping_option } = shopping.shopping_global;

  const setUseBluemapRequest = () => {
    setUseBluemap(m => !m);
  };

  const inititalValues = store.delivery_fixed_price ? store : initial;

  const [loading, setLoading] = useState(false);
  const handleSetLocal = props => {
    setLoading(true);
    api
      .put(`/v1/admin/stores/${store.id}`, props)
      .then(() => {
        dispatch(StoreCreators.getStoreRequest({ id: store.id }));
        setLoading(false);
        dispatch(
          NotificationCreators.openNotification({
            message: 'Alterações salvas com sucesso',
            type: 'success',
          })
        );
      })
      .catch(() => {
        setLoading(false);
        dispatch(
          NotificationCreators.openNotification({
            message: 'Erro ao atualizar',
            type: 'danger',
          })
        );
      });
  };

  const getTime = time => {
    if (time >= 60)
      return moment.utc().startOf('day').add(time, 'minutes').format('H:mm[h]');
    else return time + 'min';
  };

  return (
    <Box>
      {shipping_option === 'bluemap' ? (
        <Typography
          style={{
            fontSize: 20,
            padding: 30,
          }}>
          O valor da entrega é definido pela <b>Bluemap</b> no momento da compra
          de acordo com a distância do usuário
        </Typography>
      ) : (
        <Formik
          initialValues={inititalValues}
          onSubmit={handleSetLocal}
          ref={formRef}
          enableReinitialize
          validationSchema={schema}>
          {({ handleSubmit, values }) => (
            <Box>
              {app_name !== 'Oferta' && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={useBluemap}
                      onChange={() => setUseBluemapRequest(useBluemap)}
                      name="use_bluemap"
                      color="primary"
                    />
                  }
                  label="Usar o serviço de entrega local da Bluemap"
                />
              )}

              {(!useBluemap || app_name === 'Oferta') && (
                <Box>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}>
                    <Box style={{ flex: 1, marginRight: 6 }}>
                      <Typography
                        style={{
                          fontSize: 20,
                          fontWeight: 'bold',
                          marginTop: 20,
                          color: '#444',
                        }}>
                        Tempo de entrega
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 13,
                          color: '#555',
                          marginBottom: 6,
                          lineHeight: '1.5em',
                          height: '3em',
                        }}>
                        O <b>"Tempo Mínimo"</b> somado ao{' '}
                        <b>"Tempo médio por Km"</b> informará o tempo de entrega
                        do pedido.
                      </Typography>
                      <FastField
                        name="delivery_fixed_time"
                        label="Tempo mínimo de entrega"
                        style={style_inupt}
                        component={CustomMaskField2}
                        mask="999"
                        endAdornment="minutos"
                      />
                      <FastField
                        name="delivery_km_time"
                        label="Tempo médio por Km"
                        style={style_inupt}
                        component={CustomMaskField2}
                        mask="999"
                        endAdornment="minutos"
                      />
                    </Box>

                    <Box style={{ flex: 1, marginLeft: 6 }}>
                      <Typography
                        style={{
                          fontSize: 20,
                          fontWeight: 'bold',
                          marginTop: 20,
                          color: '#444',
                        }}>
                        Preço de entrega
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 13,
                          color: '#555',
                          marginBottom: 6,
                          lineHeight: '1.5em',
                          height: '3em',
                        }}>
                        O <b>"Preço Mínimo"</b> somado ao{' '}
                        <b>"Preço médio por Km"</b> informará ao cliente o valor
                        de entrega do pedido
                      </Typography>

                      <FastField
                        name="delivery_fixed_price"
                        label="Preço mínimo de entrega"
                        style={style_inupt}
                        component={CustomCurrencyField}
                      />
                      <FastField
                        name="delivery_km_price"
                        label="Preço médio por Km"
                        style={style_inupt}
                        component={CustomCurrencyField}
                      />
                    </Box>
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: 20,
                    }}>
                    <Button
                      data-cy="btn_submit"
                      onClick={handleSubmit}
                      style={{ width: 200, padding: 10 }}
                      variant="contained"
                      color="primary">
                      {!!loading && (
                        <CircularProgress
                          size={12}
                          style={{ marginRight: 10, color: '#fff' }}
                        />
                      )}
                      Salvar
                    </Button>
                  </Box>

                  <Box style={{ width: 500 }}>
                    <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>
                      Simular cálculo
                    </Typography>
                    <Typography style={{ marginBottom: 30, color: '#555' }}>
                      O cálculo é feito somando o tempo mínimo inicial + o tempo
                      médio multiplicado pela distância do usuário em km
                    </Typography>
                    <Box>
                      <Typography>
                        Distância do usuário: <b>8 km</b> Simulação
                      </Typography>

                      <Typography>
                        Tempo mínimo:{' '}
                        <b data-cy="delivery_fixed_time">
                          {values.delivery_fixed_time}min
                        </b>
                      </Typography>

                      <Typography>
                        Tempo médio por km:{' '}
                        <b data-cy="delivery_km_time">
                          {values.delivery_km_time}min
                        </b>
                      </Typography>

                      <Typography>
                        Preço mínimo:{' '}
                        <b data-cy="delivery_fixed_price">
                          {appUtils.formatPrice(values.delivery_fixed_price)}
                        </b>
                      </Typography>

                      <Typography>
                        Preço médio por km:{' '}
                        <b data-cy="delivery_km_price">
                          {appUtils.formatPrice(values.delivery_km_price)}
                        </b>
                      </Typography>

                      <hr />
                      <Typography>
                        Tempo de entrega{' '}
                        <b data-cy="delivery_time">
                          {getTime(
                            Number(values.delivery_fixed_time) +
                              Number(values.delivery_km_time) * 8
                          )}
                        </b>
                      </Typography>
                      <Typography>
                        Valor da entrega{' '}
                        <b data-cy="delivery_price">
                          {appUtils.formatPrice(
                            Number(values.delivery_fixed_price) +
                              Number(values.delivery_km_price) * 8
                          )}
                        </b>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Formik>
      )}
    </Box>
  );
};

export default DeliveryCharges;
