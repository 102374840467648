import { call, takeLatest, all, put } from 'redux-saga/effects';
import api from 'services/api';
import { Types, Creators } from 'store/ducks/favorite/reduced';
import { callApi } from 'store/sagas/auth';
import interceptResponse from 'utils/request/interceptResponse';
import { Types as AuthTypes } from 'store/ducks/auth';

function* getFavoritesProductsRecucedList() {
  try {
    const request = call(api.get, '/v1/client/favorites/reduced');
    const response = yield call(callApi, request);
    yield interceptResponse(response);
    yield put(Creators.getFavoriteProductListReducedSuccess(response.data));
  } catch (err) {
    yield put(Creators.getFavoriteProductListReducedFailure('Erro ao buscar na API'));
  }
}

export default function*() {
  yield all([
    takeLatest(Types.GET_LIST_REQUEST, getFavoritesProductsRecucedList),
    takeLatest(AuthTypes.GET_SUCCESS, getFavoritesProductsRecucedList),

  ]);
}
