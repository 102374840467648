/* eslint-disable no-duplicate-case */
/*
 *
 * Address reducer
 *
 */

export const Types = {
  // Obtem endereço
  GET_REQUEST: 'address/GET_REQUEST',
  GET_SUCCESS: 'address/GET_SUCCESS',
  GET_FAILURE: 'address/GET_FAILURE',

  // Obtem endereço
  GET_LIST_REQUEST: 'address/GET_LIST_REQUEST',
  GET_LIST_SUCCESS: 'address/GET_LIST_SUCCESS',
  GET_LIST_FAILURE: 'address/GET_LIST_FAILURE',

  // Obtem dados do CEP
  GET_CEP_REQUEST: 'address/GET_CEP_REQUEST',
  GET_CEP_SUCCESS: 'address/GET_CEP_SUCCESS',
  GET_CEP_FAILURE: 'address/GET_CEP_FAILURE',

  // Reseta State do CEP
  GET_CLEAR_REQUEST: 'address/GET_CLEAR_REQUEST',
  GET_CLEAR_SUCCESS: 'address/GET_CLEAR_SUCCESS',
  GET_CLEAR_FAILURE: 'address/GET_CLEAR_FAILURE',

  // Insere um endereço
  GET_INSERT_REQUEST: 'address/GET_INSERT_REQUEST',
  GET_INSERT_SUCCESS: 'address/GET_INSERT_SUCCESS',
  GET_INSERT_FAILURE: 'address/GET_INSERT_FAILURE',

  // Atualiza um endereço
  GET_UPDATE_REQUEST: 'address/GET_UPDATE_REQUEST',
  GET_UPDATE_SUCCESS: 'address/GET_UPDATE_SUCCESS',
  GET_UPDATE_FAILURE: 'address/GET_UPDATE_FAILURE',

  // Deleta um endereço
  GET_DELETE_REQUEST: 'address/GET_DELETE_REQUEST',
  GET_DELETE_SUCCESS: 'address/GET_DELETE_SUCCESS',
  GET_DELETE_FAILURE: 'address/GET_DELETE_FAILURE',
};

export const initialState = {
  // Endereço por id
  address: {},
  addressLoading: false,
  addressError: null,
  // Lista usuarios
  addressList: [],
  total: 0,
  addressListLoading: false,
  addressListError: null,
  // Pega dados do CEP
  cep: {},
  cepLoading: false,
  cepError: null,
  // Insere um endereço
  addressInsertLoading: false,
  addressInsertError: null,
  // Atualiza um endereço
  addressUpdateLoading: false,
  addressUpdateError: null,
  // Deleta endereço
  addressDeleteLoading: false,
  addressDeleteError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Lista um  endereço
    case Types.GET_REQUEST:
      return { ...state, addressLoading: true };
    case Types.GET_SUCCESS:
      return {
        ...state,
        address: action.payload,
        addressLoading: false,
        addressError: null,
      };
    case Types.GET_FAILURE:
      return {
        ...state,
        addressLoading: false,
        addressError: action.payload,
      };
    // Lista  endereços
    case Types.GET_LIST_REQUEST:
      return { ...state, addressListLoading: true, addressListError: false };
    case Types.GET_LIST_SUCCESS:
      return {
        ...state,
        addressList: action.payload.data,
        total: action.payload.total,
        addressListLoading: false,
        addressListError: null,
      };
    case Types.GET_LIST_FAILURE:
      return {
        ...state,
        addressListLoading: false,
        addressListError: action.payload,
      };
    // Lista  dados do CEP
    case Types.GET_CEP_REQUEST:
      return { ...state, cepLoading: true };
    case Types.GET_CEP_SUCCESS:
      return {
        ...state,
        cep: action.payload,
        cepLoading: false,
        cepError: null,
      };
    case Types.GET_CEP_FAILURE:
      return {
        ...state,
        cepLoading: false,
        cepError: action.payload,
      };
    // Inserse um endereço
    case Types.GET_INSERT_REQUEST:
      return {
        ...state,
        addressInsertLoading: true,
        addressInsertError: null,
      };
    case Types.GET_INSERT_SUCCESS:
      return {
        ...state,
        addressList: [...state.addressList, action.payload],
        addressInsertLoading: false,
        addressInsertError: null,
      };
    case Types.GET_INSERT_FAILURE:
      return {
        ...state,
        addressInsertLoading: false,
        addressInsertError: action.payload,
      };
    // Atualiza um endereço
    case Types.GET_UPDATE_REQUEST:
      return {
        ...state,
        addressUpdateLoading: true,
        addressUpdateError: null,
      };
    case Types.GET_UPDATE_SUCCESS:
      return {
        ...state,
        addressList: [
          ...state.addressList.filter(a => a.id !== action.payload.id),
          action.payload,
        ],
        addressUpdateLoading: false,
        addressUpdateError: null,
      };
    case Types.GET_UPDATE_FAILURE:
      return {
        ...state,
        addressUpdateLoading: false,
        addressUpdateError: action.payload,
      };
    // Deleta um endereço
    case Types.GET_DELETE_REQUEST:
      return {
        ...state,
        addressDeleteLoading: true,
        addressDeleteError: null,
      };
    case Types.GET_DELETE_SUCCESS:
      return {
        ...state,
        addressDeleteLoading: false,
        addressDeleteError: null,
      };
    case Types.GET_DELETE_FAILURE:
      return {
        ...state,
        addressDeleteLoading: false,
        addressDeleteError: action.payload,
      };
    // Limpa State do cep
    case Types.GET_CLEAR_REQUEST:
      return {
        ...state,
        cep: {},
      };
    default:
      return state;
  }
};

export const Creators = {
  // Busca um endereço
  getAddressRequest: () => ({
    type: Types.GET_REQUEST,
  }),
  getAddressSuccess: ({ data }) => ({
    type: Types.GET_SUCCESS,
    payload: data,
  }),
  getAddressFailure: error => ({
    type: Types.GET_FAILURE,
    payload: error,
  }),
  // Busca lista de endereço
  getAddressListRequest: ({
    page,
    perPage,
    search,
    orderByColumn,
    orderByDirection,
  }) => ({
    type: Types.GET_LIST_REQUEST,
    payload: { page, perPage, search, orderByColumn, orderByDirection },
  }),
  getAddressListSuccess: ({ data, total }) => ({
    type: Types.GET_LIST_SUCCESS,
    payload: { data, total },
  }),
  getAddressListFailure: error => ({
    type: Types.GET_LIST_FAILURE,
    payload: error,
  }),
  // Busca um CEP
  getAddressCEPRequest: ({ code_post }) => ({
    type: Types.GET_CEP_REQUEST,
    payload: { code_post },
  }),
  getAddressCEPSuccess: ({ data }) => ({
    type: Types.GET_CEP_SUCCESS,
    payload: data,
  }),
  getAddressCEPFailure: error => ({
    type: Types.GET_CEP_FAILURE,
    payload: error,
  }),
  // Insere um endereço
  getAddressInsertRequest: (
    { code_post, street, number, district, complement, city, state },
    closeModal
  ) => ({
    type: Types.GET_INSERT_REQUEST,
    payload: {
      code_post,
      street,
      number,
      district,
      complement,
      city,
      state,
    },
    changeModal: closeModal,
  }),
  getAddressInsertSuccess: ({ data }) => ({
    type: Types.GET_INSERT_SUCCESS,
    payload: data,
  }),
  getAddressInsertFailure: error => ({
    type: Types.GET_INSERT_FAILURE,
    payload: error,
  }),
  // Atualiza um  endereço
  getAddressUpdateRequest: (
    { code_post, street, number, district, complement, city, state },
    id
  ) => ({
    type: Types.GET_UPDATE_REQUEST,
    payload: {
      code_post,
      street,
      number,
      district,
      complement,
      city,
      state,
    },
    id,
  }),
  getAddressUpdateSuccess: ({ data }) => ({
    type: Types.GET_UPDATE_SUCCESS,
    payload: data,
  }),
  getAddressUpdateFailure: error => ({
    type: Types.GET_UPDATE_FAILURE,
    payload: error,
  }),
  // Deleta um endereço
  getAddressDeleteRequest: id => ({
    type: Types.GET_DELETE_REQUEST,
    payload: { id },
  }),
  getAddressDeleteSuccess: () => ({
    type: Types.GET_DELETE_SUCCESS,
  }),
  getAddressDeleteFailure: error => ({
    type: Types.GET_DELETE_FAILURE,
    payload: error,
  }),
  // Reseta State do cep
  getClearCepRequest: () => ({
    type: Types.GET_CLEAR_REQUEST,
  }),
};
