import React from 'react';
import HeaderButton from 'components/HeaderComponent/HeaderButton';
import PropTypes from 'prop-types';
import { Formik, Field, Form, FastField } from 'formik';
import { useHistory } from 'react-router-dom';
import { Creators as PanelTabsCreators } from 'store/ducks/panelTabs';
import { useDispatch } from 'react-redux';
import CustomSelect from 'components/form/CustomSelect';

import { Stack, Box } from '@chakra-ui/react';
import { tabsName } from 'Pages/Account/menu/constants';
import CustomTextField from 'components/form/CustomTextField';
import HeaderFab from 'components/HeaderComponent/HeaderFab';

const VirtualCatalogTableHeader = ({ getFunction, initialValues }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = values => {
    getFunction(values);
  };

  const navigateToTab = tab => () => {
    history.push({
      search: `?aba=novo-catalogo`,
    });
    dispatch(PanelTabsCreators.changeTab(tab));
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {({ setFieldValue, values }) => (
        <Form>
          <Stack mb="20px" direction={['column', 'row']}>
            <Box w="full">
              <FastField
                name="show_expired"
                label="Vencimentos"
                options={[
                  { id: 1, name: 'Mostrar todos' },
                  { id: 0, name: 'Mostrar Ativos' },
                ]}
                component={CustomSelect}
                placeholder="Vencimentos"
                setFieldValue={setFieldValue}
                isLoading={false}
                onClick={() => getFunction(values)}
              />
            </Box>
            <Stack direction="row" spacing="10px" w="full">
              <Box flex="1" w="full">
                <Field
                  data-cy="input_serach_orders"
                  component={CustomTextField}
                  name="search"
                  label="Buscar por nome"
                  placeholder="Informe uma palavra-chave"
                />
              </Box>
              <Box>
                <HeaderFab
                  data-cy="btn_serach_orders"
                  icon="search"
                  type="submit"
                />
              </Box>
            </Stack>
            <Box w="full">
              <HeaderButton
                data-cy="btn_add_catalog"
                icon="add"
                onClick={navigateToTab(tabsName['novo-catalogo'])}>
                NOVO CATÁLOGO
              </HeaderButton>
            </Box>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

VirtualCatalogTableHeader.propTypes = {
  getFunction: PropTypes.func.isRequired,
  initialValues: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default VirtualCatalogTableHeader;
