import React from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Creators as NotificationCreators } from 'store/ducks/app';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import appUtils from 'utils/appUtils';
import { useDispatch } from 'react-redux';
import styles, {
  ImageProducts,
  ContainerProducts,
  BoxProduct,
  BoxInfo,
} from './styles';
import { useSelector } from 'react-redux';
import appColors from 'utils/appColors';

const TrendProducts = ({ title, subtitle, data, trendRoute }) => {
  const [totalLoad, setTotalLoad] = React.useState(0);
  const [isLoad, setIsLoad] = React.useState(true);

  React.useEffect(() => {
    if (totalLoad === data.length && data.length > 0) {
      setIsLoad(false);
    }
  }, [totalLoad, data.length]);

  const dispatch = useDispatch();

  const handleCopy = () => {
    dispatch(
      NotificationCreators.openNotification({
        message: 'Link copiado para a área de transferência',
        type: 'success',
      })
    );
  };

  const { preco } = useSelector(state => state.parameters);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        flex="1"
        alignItems="center"
        style={{ display: !isLoad ? '' : 'none' }}>
        <Typography style={styles.purpleTitle}>{title}</Typography>
        <Typography style={styles.smallSubTitle}>{subtitle}</Typography>
        <CopyToClipboard text={trendRoute} onCopy={handleCopy}>
          <Button
            style={{
              width: 250,
              border: `1px solid ${appColors.PRIMARY_COLOR}`,
              color: appColors.PRIMARY_COLOR,
              backgroundColor: 'transparent',
            }}>
            Compartilhar Link
          </Button>
        </CopyToClipboard>
        <ContainerProducts
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          flex="1">
          {data.map(item => (
            <BoxProduct display="flex" flexDirection="column" key={item.id}>
              <Link
                to={`/produto/${item.slug}`}
                style={{
                  width: '100%',
                  textDecoration: 'none',
                  color: 'inherit',
                }}>
                <ImageProducts>
                  <img
                    alt=""
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                    onLoad={() => {
                      setTotalLoad(totalLoad + 1);
                    }}
                    src={
                      item.images.length > 0
                        ? item.images[0].sizes.original
                        : undefined
                    }
                  />
                </ImageProducts>
                <BoxInfo
                  display="flex"
                  flex="1"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start">
                  <Typography>{item.name}</Typography>
                  <Typography style={styles.ProductPrice}>
                    R${' '}
                    {preco === 'atacado'
                      ? ` ${appUtils.formatPrice(item.price_whole)}`
                      : ` ${appUtils.formatPrice(item.price)}`}
                  </Typography>
                </BoxInfo>
              </Link>
            </BoxProduct>
          ))}
        </ContainerProducts>
      </Box>
    </>
  );
};

TrendProducts.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  trendRoute: PropTypes.string.isRequired,
};

export default TrendProducts;
