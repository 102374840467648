import styled, { css } from 'styled-components';
import ScrollContainer from 'react-indiana-drag-scroll';
import { Box } from '@material-ui/core';
import appColors from 'utils/appColors';

export const StyledScrollContainer = styled(ScrollContainer)`
  && {
    height: 100%;
    width: 100%;
    margin: 50px;
    display: flex;

    @media all and (max-width: 500px) {
      display: flex;
      /*height: 350px;*/
      width: 96%;
      margin: 20px;
      margin-right: auto;
      margin-left: auto;
    }
  }
`;

export const BoxThumbnail = styled(Box)`
  && {
    flex-direction: row;
    padding-left: 10px;
    padding-right: 10px;
    @media all and (max-width: 500px) {
      flex-direction: row;
    }
  }
`;

export const Img = styled.div`
  width: 200px;
  height: 200px;

  @media all and (max-width: 500px) {
    width: 140px;
    height: 140px;
  }

  ${props =>
    props.src &&
    css`
      background-image: url(${props.src});
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    `}
`;

const styles = {
  purpleTitle: {
    color: appColors.SECUNDARY_COLOR,
    fontWeight: 'bold',
    fontSize: 32,
    textAlign: 'center',
  },
  smallSubTitle: {
    marginTop: 10,
    color: '#373737',
    fontSize: 17,
    textAlign: 'center',
  },
};

export default styles;
