import React from 'react';
import { useSelector } from 'react-redux';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import PageBase from 'components/PageBase';
import PageHeader from 'components/PageHeader';
import appColors from 'utils/appColors';
import { useWebhookEdit } from 'hooks/webhook';
import ButtonDefault from 'components/ButtonDefault';

const DashboardPage = () => {
  const { webhookStatus, loading, getwebhookStatus, ReRegisterwebhook } =
    useWebhookEdit();
  let webhookStatusItens = false;
  let value = {};
  let tittle = '';
  const { client } = useSelector(state => state.client);
  const groupId = client?.group?.id;
  console.log(groupId);
  const handleModalOpen = () => {
    ReRegisterwebhook();
  };
  React.useEffect(() => {
    if (!loading) {
      console.log('chegou aquiv2');
      getwebhookStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  return (
    <PageBase>
      <PageHeader title="Status do Webhook" />

      {!loading &&
        webhookStatus &&
        webhookStatus.map(item => {
          value = item.webhook_payment
            ? item.webhook_payment
            : item.webhook_transfer;
          if (item.webhook_payment === null || item.webhook_payment) {
            tittle = 'Webhook de pagamento';
          } else if (item.webhook_transfer === null || item.webhook_transfer) {
            tittle = 'Webhook de tranferência';
          }
          return (
            <>
              <Card style={{ height: '100%' }}>
                <CardHeader title={tittle} />
                <CardContent style={{ lineHeight: 2.0, color: '#4e3e51' }}>
                  <span
                    style={{
                      color: appColors.PRIMARY_COLOR,
                      fontWeight: '500',
                    }}>
                    {value?.enabled === true ? 'Habilitado: ' : 'Habilitado:'}
                  </span>{' '}
                  {value?.enabled === true ? 'Sim' : 'Não'} <br />
                  <span
                    style={{
                      color: appColors.PRIMARY_COLOR,
                      fontWeight: '500',
                    }}>
                    {value.enabled === false
                      ? ''
                      : value?.enabled === true
                      ? 'Fila Interrompida:'
                      : ''}
                  </span>{' '}
                  {value.enabled === false
                    ? ''
                    : value?.enabled === true
                    ? value?.interrupted === true
                      ? 'Sim'
                      : 'Não'
                    : ''}
                  <br />
                  <span
                    style={{
                      color: appColors.PRIMARY_COLOR,
                      fontWeight: '500',
                    }}>
                    {value.enabled === false
                      ? ''
                      : value?.enabled === true
                      ? 'Versão da api:'
                      : ''}
                  </span>{' '}
                  {value.enabled === false
                    ? ''
                    : value?.enabled === true
                    ? value?.apiVersion
                      ? value?.apiVersion
                      : 'Não'
                    : ''}
                  <br />
                </CardContent>
              </Card>
            </>
          );
        })}
      {!loading && groupId === 1 && (
        <div
          style={{
            textAlign: 'right',
            marginTop: 30,
            marginRight: 30,
            marginBottom: 30,
          }}>
          <ButtonDefault onClick={handleModalOpen}>
            Recadastrar webhook
          </ButtonDefault>
        </div>
      )}
      {!loading && webhookStatusItens === true && (
        <Card style={{ height: '100%' }}>
          <CardHeader title={tittle} />
          <CardContent style={{ lineHeight: 2.0, color: '#4e3e51' }}>
            <span
              style={{
                color: appColors.PRIMARY_COLOR,
                fontWeight: '500',
              }}>
              Status:
            </span>
            {' Não registrado'}
          </CardContent>
        </Card>
      )}
    </PageBase>
  );
};

export default DashboardPage;
