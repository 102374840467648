import React from 'react';

import PropTypes from 'prop-types';
import { Formik, Field, Form, FastField } from 'formik';
import HeaderBoxContainer from 'components/HeaderComponent/HeaderBoxContainer';
import HeaderBoxItem from 'components/HeaderComponent/HeaderBoxItem';
import HeaderFab from 'components/HeaderComponent/HeaderFab';
import CustomDateRange from 'components/form/CustomDateRange';
import moment from 'moment';
import { Box } from '@chakra-ui/react';
import CustomSelect from 'components/form/CustomSelect';

const DateRangeInput = ({ getFunction, initialValues }) => {
  const onSubmit = values => {
    const data = {
      dateStart: moment(values.dateStart).format('YYYY-MM-DD'),
      dateEnd: moment(values.dateEnd).format('YYYY-MM-DD'),
      status: values.status,
      search: values.search,
    };
    getFunction(data);
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={() => (
        <Form>
          <HeaderBoxContainer style={{ justifyContent: 'flex-end' }}>
            <Box>
              <FastField
                name="status"
                options={[
                  { id: '', name: 'Todos os saques' },
                  { id: 'PENDING', name: 'Saques Pendentes' },
                  {
                    id: 'BANK_PROCESSING',
                    name: 'Saques Em processamento bancário',
                  },
                  { id: 'DONE', name: 'Saques Efetivados' },
                  { id: 'CANCELLED', name: ' Saques Cancelados' },
                  {
                    id: 'FAILED',
                    name: 'Saques com Falha na transferência.',
                  },
                ]}
                component={CustomSelect}
                placeholder="Filtros"
                isLoading={false}
              />
            </Box>
            <HeaderBoxItem style={{ paddingRight: 8 }}>
              <Field name="dateStartEnd" component={CustomDateRange} />
            </HeaderBoxItem>
            <HeaderBoxItem style={{ paddingLeft: 6, paddingRight: 0 }}>
              <HeaderFab icon="search" type="submit" />
            </HeaderBoxItem>
          </HeaderBoxContainer>
        </Form>
      )}
    />
  );
};

DateRangeInput.propTypes = {
  getFunction: PropTypes.func.isRequired,
  initialValues: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default DateRangeInput;
