import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
`;
export const Box = styled.div`
  margin: 20px 15px;
  background: #f4f4f4;
  border-radius: 8px;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: relative;

  a {
    cursor: pointer;
    color: #666;
    text-decoration: none;
    border: 0px;
    > svg {
      font-size: 50px;
    }
  }

  button {
    border-radius: 0px 0px 8px 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #d73d32;
    border: 0;
    color: #fff;
    width: 100%;
    flex: 1;
    padding: 3px 10px;
    position: absolute;
    height: 25px;
    bottom: -25px;
    left: 0px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.2s;

    svg {
      font-size: 15px;
      color: #fff;
    }
  }

  &:hover {
    border-radius: 8px 8px 0px 0px;
    button {
      opacity: 1;
    }
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;
