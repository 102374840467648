
import styled from 'styled-components';
import { Box, Button } from '@material-ui/core';
import appColors from 'utils/appColors';

export const BoxButtons = styled(Box)`
    && {
        max-width: 400px;
        @media all and (max-width:1270px) {
            max-width:100%;
        }
        @media all and (min-width:501px) and (max-width:1270px) {
            margin-right:-20px;
            backgroundColor:red;
        }
    }
    
`;

export const BoxTags = styled(Box)`
    && {
        max-width: 400px;
        @media all and (max-width:1270px) {
            max-width:100%;
        }
        @media all and (min-width:501px) and (max-width:1270px) {
            margin-right:-20px;
            backgroundColor:red;
        }
    }
    
`;

export const BoxMoreInfo = styled(Box)`
    border-top: 1px solid #9E9E9E; 
    width: 400px;
    margin-top:30px;

    @media all and (max-width:1270px) {
        width: 100%;
    }


`;

export const ColorButton = styled(Button)`
    && {
        min-width: 250px;
        flex: 1;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 50px;
        margin-top: 30px;
        border: 1px solid ${appColors.SECUNDARY_COLOR};
        color: ${appColors.SECUNDARY_COLOR};
        display: flex;
        align-items: center;
        background-color: white;
        border: 1px solid ${appColors.SECUNDARY_COLOR};
        &:hover {
            color: white;
            background-color: ${appColors.SECUNDARY_COLOR};
        }

        @media all and (min-width:501px) and (max-width:1650px) {
            margin-right: 20px;
        }

    }
    
`;

const styles = {
    titleButton: {
        marginLeft:10,
        fontSize:16,
    },
    smallTextPurple: {
        color:appColors.SECUNDARY_COLOR,
        fontSize:14,
        fontWeight:'bold',
    },
    miniBox:{
        border: `1px solid ${appColors.SECUNDARY_COLOR}`,
        textAlign:'center',
        overflow:'hidden',
        paddingTop:2,
        paddingBottom:2,
        paddingLeft: 8,
        paddingRight:8,
        marginRight:10,
        marginTop:10,
    },
    titleMiniBox:{
        fontSize:12,
        color:'#373737',
    },
}

export default styles;