import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const TextDesk = styled(Typography)`
  &&{
    display:flex;

    @media all and (max-width: 500px){
      display:none;
    }
  }
`;