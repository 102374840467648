import React from 'react';
import { Box, Typography } from '@material-ui/core';
import styles, { BoxLoginContainer } from './styles';
import InitialRegister from '../InitialRegister';
import { Redirect } from 'react-router-dom';

const BoxCLientRegister = ({ link }) => {
  const [localState, setLocalState] = React.useState({
    data: null,
  });

  const { data } = localState;

  const handleSubmit = data => {
    setLocalState({ ...localState, data });
  };

  return (
    <BoxLoginContainer
      flex="1"
      display="flex"
      flexDirection="column"
      justifyContent="center">
      <Box
        flex="1"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center">
        <Typography style={styles.purpleTitle}>
          Quero me tornar cliente
        </Typography>
      </Box>
      <Box flex="1">
        <InitialRegister onSubmit={handleSubmit} />
      </Box>
      {data !== null && (
        <Redirect
          to={{
            pathname: '/cadastro-cliente',
            params: { data, redirect: link },
          }}
        />
      )}
    </BoxLoginContainer>
  );
};

export default BoxCLientRegister;
