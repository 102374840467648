import React from 'react';
import { SectionContainer } from './styles';
import FiltersCategories from 'components/Pages/Categories/FiltersCategories';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as ProductsBySectionCreators } from 'store/ducks/product/productsBySection';
import { Box, CircularProgress } from '@material-ui/core';
import ProductsListSection from 'components/Pages/Section/ProductsListSection';
import { useHistory } from 'react-router-dom';
import appColors from 'utils/appColors';
import HeaderCategories from 'components/Pages/Categories/HeaderCategories';
import useQuery from 'hooks/useQuery';

const SectionPage = ({ match }) => {
  const query = useQuery();

  const history = useHistory();

  const dispatch = useDispatch();

  const [filters, setFilters] = React.useState({
    page: query.get('page') !== null ? Number(query.get('page')) : 1,
    per_page: 12,
    search: '',
    orderByColumn: '',
    orderByDirection: '',
    price_min: 0,
    price_max: 5000,
    size: [],
    color: [],
    order: '1',
  });

  const { page, per_page } = filters;

  const { menu_all } = useSelector(state => state.config.config);

  const {
    productsBySection,
    productsBySectionLoading,
    productsBySectionTotal,
  } = useSelector(state => state.product.productsBySection);

  const nextPage = () => {
    if (page + 1 <= Math.ceil(productsBySectionTotal / per_page)) {
      setFilters({ ...filters, page: page + 1 });
    }
  };

  const previousPage = () => {
    if (page - 1 >= 1) {
      setFilters({ ...filters, page: page - 1 });
    }
  };

  const handlePage = selectPage => {
    setFilters({ ...filters, page: selectPage });
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (query.get('page') === null) {
      setFilters({ ...filters, page: 1 });
    }
    dispatch(ProductsBySectionCreators.getProductsBySectionReset());
    dispatch(
      ProductsBySectionCreators.getProductsBySectionRequest(
        filters,
        match.params.slug
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.slug]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (page > 1) {
      history.push({
        search: `?page=${page}`,
      });
    } else {
      history.push({
        search: null,
      });
    }
    dispatch(
      ProductsBySectionCreators.getProductsBySectionRequest(
        filters,
        match.params.slug
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const onFormFiltersSubmit = values => {
    setFilters(values);
  };

  return (
    <SectionContainer>
      <Box
        display="flex"
        flex="1"
        flexDirection="row"
        flexWrap="wrap"
        style={{ marginTop: 40 }}>
        <FiltersCategories
          data={{ colors: [], sizes: [] }}
          father={/*category.category_child.length > 0 ? true : */ false}
          onSubmit={onFormFiltersSubmit}
          section={match.params.slug}
          initialValues={filters}
        />
        <Box flex="1" flexDirection="column">
          <HeaderCategories
            title={menu_all.find(item => item.slug === match.params.slug).name}
          />
          {productsBySectionLoading && (
            <Box
              flex="1"
              display="flex"
              justifyContent="center"
              style={{ marginTop: 20, minWidth: 200 }}>
              <CircularProgress style={{ color: appColors.PRIMARY_COLOR }} />
            </Box>
          )}
          {productsBySection.length > 0 && (
            <ProductsListSection
              data={productsBySection}
              total={productsBySectionTotal}
              nextPage={nextPage}
              previousPage={previousPage}
              handlePage={handlePage}
              loading={productsBySectionLoading}
              page={page}
              perPage={per_page}
            />
          )}
        </Box>
      </Box>
    </SectionContainer>
  );
};

export default SectionPage;
