import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import appColors from 'utils/appColors';
import { Creators } from 'store/ducks/client';
// LOAD IMAGE FIX ORIENTATION
import * as ReadImage from 'blueimp-load-image';

import {
  ProfilePic,
  StyledBox,
  StyledInput,
  StyledPersonIcon,
  ProfileAvatarImage,
  HoverImageUpdate,
  TextInput,
} from './styles';

//
import { Form } from 'formik';
import { useDispatch } from 'react-redux';

const ProfileContainer = ({ client, clientLoading }) => {
  const dispatch = useDispatch();
  const [localState, setLocalState] = useState({ previewUrl: '', image: '' });

  const onSubmit = image => {
    const data = { image, id: client.id };
    dispatch(Creators.getClientUploadProfileRequest(data));
  };

  const ReadImageFile = file => {
    var options = { canvas: true };
    ReadImage.parseMetaData(file, data => {
      if (data.exif && data.exif.get('Orientation')) {
        options.orientation = data.exif.get('Orientation');
      }
      ReadImage(
        file,
        img => {
          setLocalState({
            ...localState,
            previewUrl: img.toDataURL(),
            image: img.toDataURL(),
          });
        },
        options
      );
    });
  };

  return (
    <React.Fragment>
      <Form>
        {!clientLoading && client.name && (
          <StyledBox
            flex="0"
            display="flex"
            flexDirection="column"
            alignItems="center">
            {client.image.small || localState.previewUrl ? (
              <React.Fragment>
                <ProfileAvatarImage
                  src={
                    localState.previewUrl
                      ? localState.previewUrl
                      : client.image.original
                  }
                  alt={`Foto de ${client.name}`}
                />
                <HoverImageUpdate>
                  <TextInput>Alterar imagem</TextInput>
                  <StyledInput
                    name="image"
                    onChange={event => {
                      const file = event.currentTarget.files[0];
                      ReadImageFile(file);
                      onSubmit(file);
                    }}
                    type="file"
                    accept="image/*"
                    multiple={false}
                  />
                </HoverImageUpdate>
              </React.Fragment>
            ) : (
              <ProfilePic>
                <StyledPersonIcon />
                <HoverImageUpdate>
                  <TextInput>Alterar imagem</TextInput>
                  <StyledInput
                    name="image"
                    onChange={event => {
                      const file = event.currentTarget.files[0];
                      ReadImageFile(file);
                      onSubmit(file);
                    }}
                    type="file"
                    accept="image/*"
                    multiple={false}
                  />
                </HoverImageUpdate>
              </ProfilePic>
            )}
            <Typography
              data-cy="name_user_logged"
              variant="h6"
              style={{ color: appColors.SECUNDARY_COLOR, fontWeight: 600 }}>
              {client.name}
            </Typography>
            <Typography
              data-cy="type_user_logged"
              variant="p"
              style={{ color: appColors.SECUNDARY_COLOR, fontWeight: 300 }}>
              {client.group.name}
            </Typography>
          </StyledBox>
        )}
      </Form>
    </React.Fragment>
  );
};

ProfileContainer.propTypes = {
  client: PropTypes.oneOfType([PropTypes.object]).isRequired,
  clientLoading: PropTypes.bool.isRequired,
};

export default ProfileContainer;
