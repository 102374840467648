/* eslint-disable react/display-name */
import React from 'react';
import DefaultTable from 'components/Tables/DefaultTable';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as OrderCreators } from 'store/ducks/admin/order';
import HeaderComponent from 'components/HeaderComponent';
import { Paper } from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';
import OrderActions from 'components/OrderPage/OrderActions';
import { formatDate, toPrice } from 'utils/converters';
import moment from 'moment';
import StatusColorOrderForm from 'components/OrderPage/OrderView/StatusColorOrderForm';

const ListPageReseller = ({ handleTab }) => {
  const columns = ({ onDeleteRequest }) => [
    {
      title: 'Pedido',
      field: 'id',
      render: rowData => <span>{rowData.id}</span>,
    },
    {
      title: 'R$',
      field: 'payment_type',
      render: rowData => <span>R$ {toPrice(rowData.price)}</span>,
    },
    {
      title: 'Loja',
      field: 'store.name',
      render: rowData =>
        rowData.stores.map(item => (
          <>
            <p key={item.id}>{item.store.name}</p>
          </>
        )),
    },
    {
      title: 'Status',
      field: 'status',
      render: rowData =>
        rowData.stores.map(item => (
          <>
            <StatusColorOrderForm item={item} />
          </>
        )),
    },
    {
      title: 'Data',
      field: 'created_at',
      render: rowData => <span>{formatDate(rowData.created_at)}</span>,
    },
    {
      field: 'actions',
      render: rowData => (
        <OrderActions
          handleTab={handleTab}
          rowData={rowData}
          onDeleteRequest={onDeleteRequest}
        />
      ),
    },
  ];

  const dispatch = useDispatch();
  const [deleteState, setDeleteState] = React.useState({
    open: false,
    item: {},
  });

  const today = moment();
  const actualDate = moment();
  const weekdate = today.add(-7, 'day');

  const [localState, setLocalState] = React.useState({
    search: '',
    orderByColumn: '',
    orderByDirection: '',
    page: 1,
    perPage: 5,
    dateStart: weekdate.format('YYYY-MM-DD'),
    dateEnd: actualDate.format('YYYY-MM-DD'),
  });

  const { orderList, orderListLoading, orderListTotal, orderDeleteLoading } =
    useSelector(state => state.admin.order);

  const handleAlertDialogClose = () => {
    setDeleteState({ open: false, item: {} });
  };

  React.useEffect(() => {
    if (orderDeleteLoading === false && deleteState.open) {
      handleAlertDialogClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDeleteLoading]);

  const getFunction = data => {
    setLocalState(oldLocalState => ({ ...oldLocalState, search: data.search }));
  };

  React.useEffect(() => {
    dispatch(OrderCreators.getOrderListRequest(localState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState]);

  const onDeleteRequest = item => {
    setDeleteState({ open: true, item });
  };

  const onDeleteConfirm = () => {
    dispatch(OrderCreators.getOrderDeleteRequest(deleteState.item.id));
  };

  return (
    <div style={{ marginLeft: 10 }}>
      <HeaderComponent title="Últimos Pedidos" />
      <Paper>
        <DefaultTable
          getFunction={getFunction}
          columns={columns({ onDeleteRequest })}
          data={orderList}
          total={orderListTotal}
          isLoading={orderListLoading}
          page={localState.page}
          perPage={localState.perPage}
        />
      </Paper>
      <AlertDialog
        isOpen={deleteState.open}
        isLoading={orderListLoading}
        handleClose={handleAlertDialogClose}
        onConfirm={onDeleteConfirm}
        title="Excluir registro?"
        description={`Remover categoria: ${deleteState.item.name}`}
      />
      {/* <Sound
        url={require('assets/sound.mp3')}
        playStatus={Sound.status.PLAYING}
        playFromPosition={300 /* in milliseconds 
      /> */}
    </div>
  );
};

export default ListPageReseller;
