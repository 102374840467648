import appColors from 'utils/appColors';

const styles = () => ({
  statContainer: {
    boxShadow: '1px 2px 6px rgba(0,0,0,0.2)',
    padding: '14px',
    backgroundColor: appColors.LIGHT_COLOR,
    width: '100%',
  },
  statIconContainer: {
    paddingRight: '16px',
    paddingTop: '6px',
    direction: 'rtl',
  },
  statContent: {
    float: 1,
  },
  statDescription: {
    color: '#1f1d1f',
  },

  statContainerTituloUm: {
    color: '#000000',
  },

  statContainerTituloDois: {
    color: appColors.COLOR_ICON,
  },
});

export default styles;
