/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useCallback, useContext, useState } from 'react';
import { uuid } from 'uuidv4';
import Toast from 'components/Toast';
import { useSelector } from 'react-redux';

const ToastContext = createContext({});

export const ToastProvider = ({ children }) => {
  const reduxToast = useSelector(state => state.toast);
  const [messages, setMessages] = useState([]);

  const addToast = useCallback(({ type, title, description }) => {
    const id = uuid();
    const toast = {
      id,
      type,
      title,
      description,
    };
    setMessages(state => [...state, toast]);
  }, []);

  const removeToast = useCallback(id => {
    setMessages(state => state.filter(message => message.id !== id));
  }, []);

  React.useEffect(() => {
    if (reduxToast.type) {
      addToast(reduxToast);
    }
  }, [reduxToast]);
  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      <Toast messages={messages} />
    </ToastContext.Provider>
  );
};

export function useToast() {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('O contexto Toast deve estar dentro de um Provider válido');
  }

  return context;
}
