import React from 'react';
import { Typography, Button, Box } from '@material-ui/core';
import notImage from 'images/Products/not_image.jpg';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Creators as NotificationCreators } from 'store/ducks/app';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  ImageProducts,
  ContainerProducts,
  BoxProduct,
  BoxInfo,
} from './styles';
import appConfig from 'utils/appConfig';
import appColors from 'utils/appColors';

const TrendsList = ({ data }) => {
  const dispatch = useDispatch();

  const handleCopy = () => {
    dispatch(
      NotificationCreators.openNotification({
        message: 'Link copiado para a área de transferência',
        type: 'success',
      })
    );
  };

  return (
    <Box display="flex" flexDirection="column" flex="1">
      <ContainerProducts
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        flex="1">
        {data.map(item => (
          <BoxProduct display="flex" key={item.id} flexDirection="column">
            <Link
              to={`/tendencias/${item.slug}`}
              style={{ textDecoration: 'none', color: 'inherit' }}>
              <ImageProducts
                src={
                  item.images.length > 0
                    ? item.images[0].sizes.medium
                    : notImage
                }
              />
              <BoxInfo
                display="flex"
                flex="1"
                flexDirection="column"
                alignItems="center"
                justifyContent="center">
                <Typography
                  style={{
                    overflow: 'hidden',
                    fontWeight: 'bold',
                    fontSize: 20,
                    color: appColors.PRIMARY_COLOR,
                  }}>
                  {item.name}
                </Typography>
              </BoxInfo>
            </Link>
            <CopyToClipboard
              text={`${appConfig.SITE_WEB}/produto/${item.id}/${item.slug}`}
              onCopy={handleCopy}>
              <Button
                style={{
                  width: '100%',
                  border: `1px solid ${appColors.PRIMARY_COLOR}`,
                  color: appColors.PRIMARY_COLOR,
                  backgroundColor: 'transparent',
                }}>
                Compartilhar
              </Button>
            </CopyToClipboard>
          </BoxProduct>
        ))}
      </ContainerProducts>
    </Box>
  );
};

TrendsList.propTypes = {
  data: PropTypes.array.isRequired,
};

export default TrendsList;
