// @flow
import React, { useEffect } from 'react';
import HeaderProductDetails from 'components/Pages/ProductDetails/HeaderProductDetails';
import InfoProduct from 'components/Pages/ProductDetails/InfoProduct';
import MoreInfoProduct from 'components/Pages/ProductDetails/MoreInfoProduct';
import ImageProductSlide from 'components/Pages/ProductDetails/ImageProductSlide';
import { Creators as ProductCreators } from 'store/ducks/product';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress, Box, Typography } from '@material-ui/core';
import { Creators as CheckoutCartCreators } from 'store/ducks/checkout/cart';
import appColors from 'utils/appColors';
import { Creators as FavoritesInsertCreators } from 'store/ducks/favorite/insert';
import ProductsList from 'components/Pages/Home/ProductsList';
import { ProductDetailContainer, BoxImage, StyledBoxDetail } from './styles';
import DescTecProduct from 'components/Pages/ProductDetails/DescTecProduct';
import Page404 from '../404Page';
import { DialogConfirm } from 'components/DialogConfirm';
import notImage from 'images/Products/not_image.jpg';
import { Helmet } from 'react-helmet';
import useQuery from 'hooks/useQuery';
import useProduct from 'hooks/products';
import { useState } from 'react';

const ProductDetailsPage = props => {
  const {
    match,
    history: { location },
  } = props;

  const { product: Product2, productLoading: loading2 } = useSelector(
    state => state.product.product
  );

  const { getProduct, product: asProduct, loading: loading1 } = useProduct();

  const product = asProduct || Product2;

  const query = useQuery();
  const catalog_code_internal = query.get('catalog_code_internal');
  const catalog_product_id = query.get('catalog_product_id');

  const [loading3, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (typeof match.params.id.split('-')[1] !== 'string') {
        await getProduct(match.params.id);
      } else {
        dispatch(
          ProductCreators.getProductRequest(
            match.params.id.split('-')[1],
            catalog_code_internal,
            catalog_product_id
          )
        );
      }
      setLoading(false);
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
        dispatch(ProductCreators.getReset());
      };
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalog_code_internal, catalog_product_id]);

  const loading = loading2 || loading1 || loading3;

  const [width, setWidth] = React.useState(window.innerWidth);
  const dispatch = useDispatch();

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  const { preco } = useSelector(state => state.parameters);

  const [activeDialog, setActiveDialog] = React.useState(false);
  const [productInst, setProduct] = React.useState(false);
  const { list: cartList }: { list: ProductInCart[] } = useSelector(
    state => state.checkout.cart
  );

  const addToCart = (variationSelected, code_partner, quantity) => {
    const productItem: ProductInCart = {
      ...product,
      code_partner,
      image: product.images.length ? product.images[0].sizes.medium : notImage,
      ...variationSelected,
      name: product.name,
      id: product.id,
      quantity: quantity,
      code_internal: variationSelected.code_internal,
      quantity_min_whole: product.quantity_min_whole,
      price_whole:
        variationSelected.length > 0
          ? variationSelected.price_whole
          : product.price_whole,
      store: product.store,
      variation: variationSelected,
    };

    ///
    const cartFind = cartList.find(x => x.id === product.id);
    const sharedFind = cartList.find(
      // x => product.store.share_cart === x.store.share_cart
      x => product.store.share_cart === x.store.share_cart
    );

    if (cartFind) {
      if (
        // sharedFind.store.share_cart
        (sharedFind !== undefined && sharedFind.store.share_cart) ||
        (sharedFind !== undefined && !sharedFind.store.share_cart)
      ) {
        // SE EXISTE E É O MESMO
        dispatch(CheckoutCartCreators.addItemToCart(productItem));
      } else {
        // SHARE CART NÃO É O MESMO
        setActiveDialog(true);
        setProduct(productItem);
      }
    } else {
      if (
        // sharedFind.store.share_cart
        (sharedFind !== undefined && sharedFind.store.share_cart) ||
        (sharedFind !== undefined && !sharedFind.store.share_cart)
      ) {
        // SE NÃO EXISTE NO CARRINHO MAS O SHARE_ID É O MESMO
        dispatch(CheckoutCartCreators.addItemToCart(productItem));
      } else {
        if (cartList.length === 0) {
          dispatch(CheckoutCartCreators.addItemToCart(productItem));
        } else {
          // SHARE CART NÃO É O MESMO
          setActiveDialog(true);
          setProduct(productItem);
        }
      }
    }
  };

  const whishesAdd = () => {
    dispatch(FavoritesInsertCreators.getFavoriteInsertRequest(product.id));
  };

  const handleClose = () => {
    setActiveDialog(false);
  };

  const handleSubmit = () => {
    dispatch(CheckoutCartCreators.resetCart());
    dispatch(CheckoutCartCreators.resetBudgetHashCart());
    dispatch(CheckoutCartCreators.resetManpowerCart());
    dispatch(CheckoutCartCreators.addItemToCart(productInst));
    setActiveDialog(false);
  };

  return (
    <ProductDetailContainer>
      {!loading && Object.keys(product).length > 0 && (
        <>
          <HeaderProductDetails product={product} />
          <StyledBoxDetail
            flex="1"
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
            flexWrap="wrap">
            <BoxImage flex="1">
              <ImageProductSlide
                flex="1"
                width={width}
                images={
                  product.images.length > 0
                    ? product.link && product.link.length > 10
                      ? [
                          ...product.images,
                          {
                            id: 0,
                            image_key:
                              'https://images.squarespace-cdn.com/content/v1/59113264cd0f6871c6ee99d3/1498770034028-C148VVNDSYPQQ16XSLGZ/ke17ZwdGBToddI8pDm48kPqY296jC3WqOqBZ--Cl45JZw-zPPgdn4jUwVcJE1ZvWEtT5uBSRWt4vQZAgTJucoTqqXjS3CfNDSuuf31e0tVFHwqjKfkrZoe4IaECDDTFnYFUeIABom_xNCwdSd7ef4DqWIIaSPh2v08GbKqpiV54/PlayButton.png',
                            video: true,
                            link: product.link,
                            sizes: {
                              small:
                                'https://images.squarespace-cdn.com/content/v1/59113264cd0f6871c6ee99d3/1498770034028-C148VVNDSYPQQ16XSLGZ/ke17ZwdGBToddI8pDm48kPqY296jC3WqOqBZ--Cl45JZw-zPPgdn4jUwVcJE1ZvWEtT5uBSRWt4vQZAgTJucoTqqXjS3CfNDSuuf31e0tVFHwqjKfkrZoe4IaECDDTFnYFUeIABom_xNCwdSd7ef4DqWIIaSPh2v08GbKqpiV54/PlayButton.png',
                            },
                          },
                        ]
                      : product.images
                    : [{ sizes: { small: notImage, original: notImage } }]
                }
              />
            </BoxImage>
            {/* INTEGRAÇÃO KDT = PRODUTO*/}
            <Helmet>
              <meta name="kdt:page" content="product" />
              <meta
                name="kdt:product"
                content={`sku:${product.id}, name=${product.name}`}
                charSet="utf-8"
              />
            </Helmet>
            <InfoProduct
              data={product}
              isWhole={preco === 'atacado' ? true : false}
              addToCart={addToCart}>
              {width > 1270 && (
                <MoreInfoProduct whishesAdd={whishesAdd} data={product} />
              )}
            </InfoProduct>
          </StyledBoxDetail>
          {width <= 1270 && (
            <MoreInfoProduct whishesAdd={whishesAdd} data={product} />
          )}
          <DescTecProduct data={product} />
          {(product.related.length > 0 || catalog_code_internal) && (
            <Box style={{ marginTop: 50 }}>
              {typeof location.params !== 'undefined' ? (
                typeof location.params.storeRedirect !== 'undefined' && (
                  <Typography
                    variant="h4"
                    style={{ color: appColors.SECUNDARY_COLOR }}>
                    {catalog_code_internal
                      ? 'Mais produtos desse catálogo'
                      : 'Mais produtos desta loja'}
                  </Typography>
                )
              ) : (
                <Typography
                  variant="h4"
                  style={{ color: appColors.SECUNDARY_COLOR }}>
                  {catalog_code_internal
                    ? 'Mais produtos desse catálogo'
                    : 'Recomendações do Vendedor'}
                </Typography>
              )}
              <ProductsList
                preco={preco}
                title={''}
                catalog_code_internal={catalog_code_internal}
                catalog_product_id={catalog_product_id}
                subtitle={''}
                data={
                  (!!product.catalog &&
                    !!product.catalog.content &&
                    product.catalog.content.length > 0 &&
                    product.catalog.content[1].content) ||
                  product.related
                }
              />
            </Box>
          )}
        </>
      )}
      {loading && (
        <Box
          flex="1"
          display="flex"
          justifyContent="center"
          alignItems="flex-start"
          style={{ minHeight: 800 }}>
          <CircularProgress style={{ color: appColors.PRIMARY_COLOR }} />
        </Box>
      )}
      {Object.keys(product).length === 0 && !loading && <Page404 />}
      <DialogConfirm
        active={activeDialog}
        title="Esta loja não permite ter um carrinho compartilhado"
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        description="Deseja esvaziar seu carrinho e adicionar este item?"
        buttonCancel="Cancelar"
        ButtonSubmit="Esvaziar carrinho e adicionar"
      />
    </ProductDetailContainer>
  );
};

export default ProductDetailsPage;
