import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Title = styled(Typography)`
  font-size: 36px;
  color: #444;
  margin-top: 12px;
  font-weight: 300;
`;

export const Image = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
`;

export const Gallery = styled.div`
  width: 330px;
  margin-right: 30px;
  margin-bottom: 20px;
`;

export const GridView = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  margin-top: 30px;

  @media (max-width: 650px) {
    justify-content: center;
  }
`;
