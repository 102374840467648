import {
  call, takeLatest, all, put,
} from 'redux-saga/effects';
import api from 'services/api';
import { Types, Creators } from 'store/ducks/account/passwordRecovery';
import { Creators as NotificationCreators } from 'store/ducks/app';
import { callApi } from 'store/sagas/auth';
import interceptResponse from 'utils/request/interceptResponse';
import interceptError from 'utils/request/interceptError';
import { push } from 'connected-react-router';

function* getPasswordRecovery({ payload }) {
  try {
    const {
      password,
      hash,
    } = payload;
    const request = call(api.put, '/v1/client/recover', {
      password,
      hash,
    });
    const response = yield call(callApi, request);
    yield interceptResponse(response);
    yield put(Creators.getPasswordRecoverySuccess());
    yield put(push('/minha-conta'));
    yield put(
      NotificationCreators.openNotification({ message: response.data.msg, type: 'success' }),
    );
  } catch (err) {
    const error = yield interceptError(Creators.getPasswordRecoveryFailure, err);
    yield put(NotificationCreators.openNotification({ message: error, type: 'error' }));
  }
}

export default function* PasswordRecoverySaga() {
  yield all([
    takeLatest(Types.GET_RECOVERY_REQUEST, getPasswordRecovery),
  ]);
}
