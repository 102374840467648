/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Icon } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  border-radius: 10px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;

export const Header = styled.div`
  display: flex;
  padding: 10px 5px;
  background: #f4f4f4;
  position: relative;
  align-items: center;
  top: 0;
  .icons {
    display: flex;
    align-items: center;
    position: absolute;
    right: 5px;
  }
`;

export const Centralize = styled.div`
  display: flex;
  justify-content: center;
`

export const Content = styled.div`
  display: flex;
  background: #f5f5f5;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 1px;
  }

  ::-webkit-scrollbar-thumb {
    background: #8b8e93;
    border-radius: 20px;
  }
`;

export const Footer = styled.div`
  height: 35px;
  display: grid;
  background: #fff;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;

export const ColImage = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
`;

export const ColIcon = styled.div`
  display: flex;
`;

export const Image = styled.img`
  height: 15px;
  width: auto;
  display: flex;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 3px 5px;
  background: #fff;
  justify-content: space-between;
`;

export const Col = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledIcon = styled(Icon)`
  width: auto;
  height: auto;
  font-size: 15px;
  text-align: center;
  color: ${props => props.color || '#000'};
`;

export const Text = styled.span`
  color: ${props => props.color || '#000'};
  font-size: ${props => props.fontSize || 10}px;
`;

export const Title = styled.span`
  color: #000;
  font-size: 18px;
  font-weight: bold;
  width: 100%;
  text-align: left;
`;

export const SeeMore = styled.span`
  color: #ee8742;
  font-weight: bold;
  font-size: 10px;
  cursor: pointer;
`;

export const BoxTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  flex: 1;
  width: 100%;
`;

export const FooterTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
