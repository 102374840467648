// @flow
import React from 'react';
import { Typography, Paper } from '@material-ui/core';
import { Creators as CheckoutCartCreators } from 'store/ducks/checkout/cart';
import { Creators as CupomCreators } from 'store/ducks/cupom';
import { useDispatch, useSelector } from 'react-redux';
import FinalizationBox from 'components/Pages/ShoppingCart/FinalizationBox';
import HeaderShoppingCart from 'components/Pages/ShoppingCart/HeaderShoppingCart';
import HeaderShoppingSharedCart from 'components/Pages/ShoppingCart/HeaderShoppingSharedCart';
import ErrorIcon from '@material-ui/icons/Error';
import ProductsGroup from 'components/Pages/ShoppingCart/ProductsGroup';
import { MobileBar } from './styles';
import { Creators as NotificationCreators } from 'store/ducks/app';
import { Creators as OrderCreators } from 'store/ducks/order';
import { useHistory, useLocation } from 'react-router-dom';
import appColors from 'utils/appColors';
import checkoutUtils from 'utils/checkoutUtils';
import { DialogConfirm } from 'components/DialogConfirm';
import { Helmet } from 'react-helmet';
import { Stack, Box } from '@chakra-ui/react';
import appTheme from 'utils/appTheme';
import ServicesGroup from 'components/Pages/ShoppingCart/ServicesGroup';
// import CancelIcon from '@material-ui/icons/Cancel';

const ShoppingCart = () => {
  React.useEffect(
    () => () => {
      window.scrollTo(0, 0);
    },
    []
  );

  const history = useHistory();
  const { state } = useLocation();

  // DIALOGS
  const [activeDialog, setActiveDialog] = React.useState(false);
  const [removeGroups, setRemoveGroups] = React.useState(false);
  const [productItem, setProductItem] = React.useState([]);

  const dispatch = useDispatch();

  const { list: cartList }: { list: ProductInCart[] } = useSelector(
    state => state.checkout.cart
  );
  const { manpower: manpowerList }: { list: ManpowerInCart[] } = useSelector(
    state => state.checkout.cart
  );

  const { listErrors } = useSelector(state => state.order);
  const { data: cupomData, loading: cupomLoading } = useSelector(
    state => state.cupom
  );

  const addItem = (item: ProductInCart) => {
    if (item.quantity + 1 <= item.stock || item.stock_control === 0) {
      dispatch(CheckoutCartCreators.addItemToCart({ ...item, quantity: 1 }));
    } else {
      dispatch(
        NotificationCreators.openNotification({
          message: 'Quantidade indisponível nesta variação',
          type: 'error',
        })
      );
    }
  };

  React.useEffect(() => {
    return () => {
      dispatch(OrderCreators.getQuoteReset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (cartList.length === 0 || cartList === []) {
      // Call a function to clear the entire cart

      dispatch(CheckoutCartCreators.resetCart());
      dispatch(CheckoutCartCreators.resetBudgetHashCart());
      dispatch(CheckoutCartCreators.resetManpowerCart());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartList === []]);

  const removeItem = (item: ProductInCart) => {
    if (item.quantity === 1) {
      setActiveDialog(true);
      setRemoveGroups(false);
      setProductItem(item);
    } else {
      dispatch(CheckoutCartCreators.removeItemFromCart(item));
    }
  };

  const handleSubmit = () => {
    if (removeGroups) {
      dispatch(CheckoutCartCreators.removeGroupFromCart(productItem));
    } else {
      dispatch(CheckoutCartCreators.removeItemFromCart(productItem));
    }

    setActiveDialog(false);
  };

  const removeGroupItems = (item: ProductInCart) => {
    setActiveDialog(true);
    setRemoveGroups(true);
    setProductItem(item);
  };

  const handleClose = () => {
    setActiveDialog(false);
    setRemoveGroups(false);
  };

  const onSubmitQuote = values => {
    if (cartList.length > 0) {
      dispatch(OrderCreators.getQuoteRequest(values, cartList));
    } else {
      dispatch(
        NotificationCreators.openNotification({
          message: 'Simulação disponível apenas se houver produtos no carrinho',
          type: 'error',
        })
      );
    }
  };

  const onSubmitCupom = values => {
    if (cartList.length > 0) {
      dispatch(CupomCreators.getCupomListRequest(values, cartList));
    } else {
      dispatch(
        NotificationCreators.openNotification({
          message: 'Cupom disponível apenas se houver produtos no carrinho',
          type: 'error',
        })
      );
    }
  };

  const { quote, quoteLoading, quoteError } = useSelector(state => state.order);
  const proccessCart = checkoutUtils.proccessCart(cartList, cupomData);

  return (
    <Stack alignItems="center">
      <Stack>
        {state > 0 &&
          appTheme.name ===
            'SimerPay'(
              <MobileBar href={`ofertaplay://ofertaplay/cart/${state?.shared}`}>
                Clique aqui para finalizar a compra em nosso aplicativo.
              </MobileBar>
            )}
        <Box p="0 20px">
          {state && state?.shared ? (
            <HeaderShoppingSharedCart />
          ) : (
            <HeaderShoppingCart />
          )}
        </Box>
        {typeof listErrors?.error !== 'undefined' && (
          <Box
            flex="1"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            style={{
              padding: 20,
              backgroundColor: '#C4170C',
              borderRadius: 5,
              marginTop: 20,
            }}>
            <Typography style={{ fontSize: 20, color: 'white' }}>
              Não conseguimos receber o seu pedido, por favor verifique o seu
              carrinho.
            </Typography>
            <ErrorIcon fontSize="large" style={{ color: 'white' }} />
          </Box>
        )}

        <Stack p="20px" as={Paper} direction={['column-reverse', 'row']}>
          <Box pt="30px">
            {/* INÍCIO DOS SERVIÇOS */}
            {manpowerList && manpowerList?.length > 0 && (
              <Box
                key={'serviços'}
                style={{
                  // border: `1px solid ${appColors.SECUNDARY_COLOR}`,
                  marginBottom: 20,
                  borderRadius: 5,
                }}>
                <Box
                  bg={`linear-gradient(135deg, ${appColors.SECUNDARY_COLOR} 55%, #FFBF4E 98%)`}
                  style={{
                    padding: 10,
                    borderRadius: 10,
                    backgroundColor: appColors.SECUNDARY_COLOR,
                    cursor: 'pointer',
                  }}
                  onClick={() => {}}>
                  <Typography
                    style={{
                      color: 'white',
                      fontWeight: 'bold',
                      fontSize: '18px',
                    }}>
                    Mão de obra
                  </Typography>
                  {/* <Button
                    onClick={() => dispatch(CartCreators.resetManpowerCart())}
                    variant="outlined"
                    style={{
                      fontSize: '12px',
                      color: 'white',
                      borderColor: 'transparent',
                      fontWeight: 'bold',
                    }}>
                    Limpar orçamento de mão de obra
                    <CancelIcon
                      style={{
                        color: 'white',
                        marginLeft: 10,
                      }}
                      fontSize="large"
                    />
                  </Button> */}
                </Box>

                <Box style={{ paddingLeft: 10, paddingRight: 10 }}>
                  <ServicesGroup services={manpowerList && manpowerList} />
                </Box>
              </Box>
            )}
            {/* FIM DOS SERVIÇOS */}
            {Object.values(proccessCart.productsAgruppedByStore).map(
              products => (
                <Box
                  key={products[0].id}
                  style={{
                    // border: `1px solid ${appColors.SECUNDARY_COLOR}`,
                    marginBottom: 20,
                    borderRadius: 5,
                  }}>
                  {/* INTEGRAÇÃO KDT = CHEKCOUT/CARRINHO/BASKET*/}
                  <Helmet>
                    <meta name="kdt:page" content="basket" charset="utf-8" />
                    <meta
                      name="kdt:product"
                      content={`sku:${products[0].store.id}, name=${products[0].store.name}`}
                      charSet="utf-8"
                    />
                  </Helmet>
                  <Box
                    bg={`linear-gradient(135deg, ${appColors.SECUNDARY_COLOR} 55%, #FFBF4E 98%)`}
                    style={{
                      padding: 10,
                      borderRadius: 10,
                      backgroundColor: appColors.SECUNDARY_COLOR,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      history.push({
                        pathname: `/lojas/${products[0].store.slug}`,
                      });
                    }}>
                    <Typography
                      style={{
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: '18px',
                      }}>
                      {products[0].store.name}
                    </Typography>
                    {proccessCart.storeQuantityToWhole(products[0].store.id) >
                      0 && (
                      <Typography style={{ color: 'white', fontSize: 12 }}>
                        {`Tudo em atacado comprando a partir de
                       ${proccessCart.storeQuantityToWhole(
                         products[0].store.id
                       )} itens nesta loja`}
                      </Typography>
                    )}
                  </Box>

                  <Box style={{ paddingLeft: 10, paddingRight: 10 }}>
                    {products.map((product, index) => (
                      <ProductsGroup
                        productValues={proccessCart.getValuesForProduct(
                          product.store.id,
                          product.id,
                          product
                        )}
                        data={product}
                        addItem={addItem}
                        removeItem={removeItem}
                        removeGroups={removeGroupItems}
                        key={product.code_internal}
                        last={index === Number(products.length - 1)}
                        storesLength={proccessCart.getQuantityByStore(
                          product.store.id
                        )}
                        minWholeStore={proccessCart.storeQuantityToWhole(
                          product.store.id
                        )}
                      />
                    ))}
                  </Box>
                </Box>
              )
            )}
          </Box>

          <FinalizationBox
            cartList={cartList}
            quote={quote}
            quoteLoading={quoteLoading}
            quoteError={quoteError}
            onSubmitQuote={onSubmitQuote}
            onSubmitCupom={onSubmitCupom}
            cupomLoading={cupomLoading}
            cupomError={quoteError}
            valuesOfAllProducts={proccessCart.getValuesOfAllProducts()}
          />
        </Stack>
        <DialogConfirm
          active={activeDialog}
          title="Remover"
          handleSubmit={handleSubmit}
          handleClose={handleClose}
          description="Tem certeza que deseja remover o item do carrinho?"
          buttonCancel="Cancelar"
          ButtonSubmit="Remover"
        />
      </Stack>
    </Stack>
  );
};

export default ShoppingCart;
