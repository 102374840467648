// @flow
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Typography, Box, Button } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircle';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import appUtils from 'utils/appUtils';
import styles, {
  StyleProductsGroup,
  StyleProductsGroupMobile,
  BoxColors,
  DiscoutDisplay,
  StyleProductsGroupMobile2,
} from './styles';
import ReactMarkdown from 'react-markdown/with-html';
import { Creators as CartCreators } from 'store/ducks/checkout/cart';
import { useSelector, useDispatch } from 'react-redux';
import WarningIcon from '@material-ui/icons/Warning';
import { Link } from 'react-router-dom';
// import LinesEllipsis from 'react-lines-ellipsis';

const ProductsGroup = ({
  data,
  addItem,
  removeItem,
  removeGroups,
  last,
  productValues,
}: {
  data: ProductInCart,
}) => {
  const dispatch = useDispatch();
  const [showDescription, setShowDescription] = useState(false);

  const onClickAdd = () => {
    addItem(data);
  };

  const onClickRemove = () => {
    removeItem(data);
  };

  const onClickRemoveGoup = () => {
    removeGroups(data);
  };

  const { listErrors } = useSelector(state => state.order);

  React.useEffect(() => {
    if (typeof listErrors.error !== 'undefined') {
      const product = listErrors.products.find(
        item => item.code_internal === data.code_internal
      );
      if (typeof product.price_actual !== 'undefined') {
        dispatch(CartCreators.updatePriceRequest(product));
      }
    }
  }, [listErrors]);

  return (
    <Box>
      <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={1}>
        <CancelIcon
          onClick={onClickRemoveGoup}
          style={styles.iconsDelete}
          fontSize="large"
        />
      </Box>
      <StyleProductsGroup
        display="flex"
        flex="1"
        flexDirection="row"
        last={last}>
        <Link
          to={`produto/${productValues.product.slug}`}
          style={{ textDecoration: 'none', color: 'inherit' }}>
          <Box>
            <img
              alt=""
              src={data.image}
              width="70"
              style={{ marginRight: 20 }}
            />
          </Box>
        </Link>
        <Box
          display="flex"
          flex="1"
          alignItems="flex-start"
          flexDirection="column"
          style={{ minWidth: 200 }}>
          <Link
            to={`produto/${productValues.product.slug}`}
            style={{ textDecoration: 'none', color: 'inherit' }}>
            <Typography
              data-cy="name_product_badge"
              style={styles.titleProduct}>
              {data.name}
            </Typography>
          </Link>

          <Box style={{ minWidth: '500px' }}>
            {showDescription ? (
              <>
                <Box
                  data-cy="variation_product_badge"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  Variação:{' '}
                  {typeof data.color.hexa !== 'undefined' && (
                    <BoxColors hexa={productValues.product.color.hexa} />
                  )}
                  {data.color.name}
                </Box>
                <Typography style={{ fontSize: '0.875rem' }}>
                  Tamanho: {productValues.product.size.name}
                </Typography>
                <p>
                  Descrição:
                  <ReactMarkdown
                    style={styles.subTitle}
                    source={data.description}
                  />
                </p>
              </>
            ) : (
              <></>
            )}
          </Box>

          <Button
            style={styles.descriptionButton}
            variant="contained"
            onClick={() => setShowDescription(old => !old)}>
            {showDescription ? 'Ver menos' : 'Ver mais'}
          </Button>

          {typeof listErrors.error !== 'undefined' && (
            <>
              {listErrors.products
                .find(
                  item =>
                    item.code_internal === productValues.product.code_internal
                )
                .errors.map(item => (
                  <Box
                    key={item.id}
                    display="flex"
                    flexDirection="column"
                    style={{
                      backgroundColor: '#f7eda5',
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingLeft: 20,
                      paddingRight: 20,
                      borderRadius: 5,
                      marginBottom: 5,
                    }}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      flexDirection="row">
                      <Typography>{item.msg}</Typography>
                      {item.id === 2 && (
                        <Typography style={{ marginLeft: 10, marginRight: 10 }}>
                          De{' '}
                          <b>
                            {appUtils.formatPrice(
                              listErrors.products.find(
                                item => item.id === productValues.product.id
                              ).price
                            )}
                          </b>{' '}
                          para{' '}
                          <b>
                            {appUtils.formatPrice(
                              listErrors.products.find(
                                item => item.id === productValues.product.id
                              ).price_actual
                            )}
                          </b>
                        </Typography>
                      )}
                      <WarningIcon
                        fontSize="small"
                        style={{ marginLeft: 10, color: '#d6c035' }}
                      />
                    </Box>
                  </Box>
                ))}
            </>
          )}
        </Box>

        <Box
          display="flex"
          flex="1"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column">
            <Box display="flex" flexDirection="row" alignItems="center">
              <b style={{ fontSize: 14 }}>
                {appUtils.formatPrice(productValues.price)}
              </b>
            </Box>
            {productValues.temDesconto && (
              <>
                <DiscoutDisplay>
                  {`- ${appUtils.formatPrice(
                    productValues.descontoPorProduto
                  )}`}
                </DiscoutDisplay>
                <div>
                  {productValues.ehAtacado && (
                    <div>
                      <Typography variant="caption">Atacado</Typography>
                    </div>
                  )}
                  {productValues.ehCupom && (
                    <div>
                      <Typography variant="caption">Cupom</Typography>
                    </div>
                  )}
                </div>
              </>
            )}
          </Box>
          <RemoveCircleOutlineIcon
            onClick={onClickRemove}
            style={styles.iconsAddRemove}
            fontSize="large"
          />
          <Typography>{productValues.quantity}</Typography>
          <AddCircleOutlineIcon
            onClick={onClickAdd}
            style={styles.iconsAddRemove}
            fontSize="large"
          />
          <Typography>
            <b style={{ color: 'grey', fontSize: 18 }}>
              {appUtils.formatPrice(productValues.subtotal)}
            </b>
            <br />
            {productValues.descontos > 0 && (
              <DiscoutDisplay>
                {`- ${appUtils.formatPrice(productValues.descontos)}`}
              </DiscoutDisplay>
            )}
          </Typography>
        </Box>
      </StyleProductsGroup>
      <>
        <StyleProductsGroupMobile
          display="flex"
          flex="1"
          flexDirection="column"
          last={last}>
          <Box
            flex="1"
            display="flex"
            justifyContent="flex-start"
            style={{ marginBottom: 20, gap: 15 }}>
            <Box flex={2}>
              <img
                alt=""
                src={data.image}
                width="100%"
                style={{
                  marginRight: 20,
                  border: '1px solid #80808040',
                  borderRadius: '5px',
                }}
              />
            </Box>

            <Box
              display="flex"
              flex="3"
              paddingTop={1}
              alignItems="flex-start"
              flexDirection="column"
              style={{ minWidth: 100 }}>
              <Typography style={styles.titleProduct}>{data.name}</Typography>

              {showDescription ? (
                <StyleProductsGroupMobile2>
                  <Box style={{ minWidth: '150px' }}>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      Variação:{' '}
                      {typeof data.color.hexa !== 'undefined' && (
                        <BoxColors hexa={data.color.hexa} />
                      )}
                      {data.color.name}
                    </Box>
                    <Typography style={{ fontSize: '0.875rem' }}>
                      Tamanho: {data.size.name}
                    </Typography>
                    <p>
                      Descrição:{' '}
                      <ReactMarkdown
                        style={styles.subTitle}
                        source={data.description}
                      />
                    </p>
                  </Box>
                </StyleProductsGroupMobile2>
              ) : (
                <></>
              )}

              <Box
                width="90%"
                display="flex"
                flexDirection="row"
                justifyContent="flex-end">
                <Button
                  style={styles.descriptionButton}
                  variant="contained"
                  onClick={() => setShowDescription(old => !old)}>
                  {showDescription ? 'Ver menos' : 'Ver mais'}
                </Button>
              </Box>

              {typeof listErrors.error !== 'undefined' && (
                <>
                  {listErrors.products
                    .find(item => item.id === data.id)
                    .errors.map(item => (
                      <Box
                        key={item.id}
                        display="flex"
                        flexDirection="column"
                        style={{
                          backgroundColor: '#f7eda5',
                          paddingTop: 10,
                          paddingBottom: 10,
                          paddingLeft: 20,
                          paddingRight: 20,
                          borderRadius: 5,
                          marginBottom: 5,
                        }}>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          flexDirection="row">
                          <Typography>{item.msg}</Typography>
                          <WarningIcon
                            fontSize="small"
                            style={{ marginLeft: 10, color: '#d6c035' }}
                          />
                        </Box>
                      </Box>
                    ))}
                </>
              )}
            </Box>
          </Box>

          <Box
            display="flex"
            flex="1"
            flexDirection="row"
            alignItems="center"
            marginBottom="20px"
            marginRight="20px"
            justifyContent="flex-end">
            <RemoveCircleOutlineIcon
              onClick={onClickRemove}
              style={styles.iconsAddRemove}
              fontSize="large"
            />
            <Typography>{productValues.quantity}</Typography>
            <AddCircleOutlineIcon
              onClick={onClickAdd}
              style={styles.iconsAddRemove}
              fontSize="large"
            />
            <Typography>
              <b style={{ color: 'grey', fontSize: 18 }}>
                {appUtils.formatPrice(productValues.subtotal)}
              </b>
              <br />
              {productValues.descontos > 0 && (
                <DiscoutDisplay>
                  {`- ${appUtils.formatPrice(productValues.descontos)}`}
                </DiscoutDisplay>
              )}
            </Typography>
          </Box>
        </StyleProductsGroupMobile>
      </>
    </Box>
  );
};

export default ProductsGroup;
