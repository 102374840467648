import React from 'react';
import { Box } from '@chakra-ui/react';
import { Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as ClientCreators } from 'store/ducks/client';
import PropTypes from 'prop-types';
import styles from './styles';
import ShopperMenuPage from '../ShopperMenuPage';

const PanelClientTab = ({ logout, handleTab }) => {
  const { client, clientLoading } = useSelector(state => state.client);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(ClientCreators.getClientRequest());
  }, [dispatch]);

  return (
    <Box w="full">
      {Object.keys(client).length > 0 &&
        !clientLoading &&
        client.group &&
        client.group.id !== 3 && (
          <>
            {client.group.id === 5 && (
              <>
                <Typography data-cy="name_user_logged" style={styles.helloClient}>
                  Olá, <b>{client.name}</b> (não é <b>{client.name}</b>?{' '}
                  <span style={styles.linkSimulatorRed} onClick={logout}>
                    Sair
                  </span>
                  )
                </Typography>

                <Typography style={styles.instructions}>
                  A partir do painel de controle de sua conta, você pode ver
                  suas compras recentes, gerenciar seus endereços de entrega e
                  cobrança, e editar sua senha e detalhes da conta.
                </Typography>
              </>
            )}
          </>
        )}
      {Object.keys(client).length > 0 &&
        !clientLoading &&
        client.group &&
        client.group.id !== 3 && (
          <>
            {client.group.id === 7 && (
              <>
                <Typography style={styles.helloClient}>
                  Olá, <b>{client.name}</b> (não é <b>{client.name}</b>?{' '}
                  <span style={styles.linkSimulatorRed} onClick={logout}>
                    Sair
                  </span>
                  )
                </Typography>

                <Typography style={styles.instructions}>
                  A partir do painel de controle de sua conta, você pode gerar
                  catálogo virtual, visualizar perfil, cadastrar e editar
                  produtos.
                </Typography>
              </>
            )}
          </>
        )}
      {Object.keys(client).length > 0 && (
        <>
          {client.group.id === 3 && <ShopperMenuPage handleTab={handleTab} />}
          {client.group.id === 4 && <ShopperMenuPage handleTab={handleTab} />}
        </>
      )}
    </Box>
  );
};

PanelClientTab.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default PanelClientTab;
