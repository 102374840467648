/* eslint-disable react/jsx-key */
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Creators as OrderCreators } from 'store/ducks/admin/order';
import CountDown from 'components/CountDown';
import PropTypes from 'prop-types';
import appUtils from 'utils/appUtils';
import { useDispatch } from 'react-redux';
import PrintLoad from 'components/Pages/PrintLoad';
import { Button } from '@material-ui/core';
import { Print } from '@material-ui/icons';
import BodyItem from '../BodyItem';
import styles, {
  PriceContainer,
  StatusContainerStyled,
  OrderContainer,
} from './styles';
import moment from 'moment';
import { formatStatus } from 'utils/converters';
const HeaderItem = ({ data, isOpen, handleOpen, onSubmit }) => {
  const dispatch = useDispatch();
  const date = new Date(data.created_at);
  const mountPrint = id => {
    dispatch(OrderCreators.getPrintRequest(id));
  };

  return (
    <Box flex="1" style={styles.containerItem}>
      <StatusContainerStyled
        display="flex"
        justifyContent="space-between"
        flex="1"
        flexWrap="wrap"
        style={{ padding: '16px 30px 14px' }}
        alignItems="center">
        {/*  */}

        <Box display="flex" flexDirection="column">
          <OrderContainer
            display="flex"
            flexDirection="row"
            alignItems="center"
            flexWrap="wrap">
            <Typography style={styles.headerPurple}> Pedido: </Typography>
            <Typography data-cy="id_order" style={styles.fontBold}>
              {' '}
              {data.id}{' '}
            </Typography>
          </OrderContainer>
          <PriceContainer
            display="flex"
            flexDirection="row"
            alignItems="center"
            flexWrap="wrap">
            <Typography data-cy="price_order" style={styles.headerPurple}>
              Valor Total:
            </Typography>
            <Typography style={styles.fontBold}>
              {appUtils.formatPrice(data.price)}
            </Typography>
          </PriceContainer>
          <OrderContainer
            display="flex"
            flexDirection="row"
            alignItems="center"
            flexWrap="wrap">
            <Typography style={styles.headerPurple}>Agendamento: </Typography>
            <Typography data-cy="schedule_order" style={styles.fontBold}>
              {moment(data.schedule.date).format('DD/MM/YYYY')}{' '}
              {data.schedule.start} - {data.schedule.end}
            </Typography>
          </OrderContainer>
        </Box>

        <CountDown
          accepted={data.stores[0].order_accepted_at}
          status={data.stores[0].status}
          preparation={data.mim_time_for_preparation}
        />

        <Box display="flex" flexDirection="column">
          <OrderContainer
            display="flex"
            flexDirection="row"
            alignItems="center"
            flexWrap="wrap">
            <Typography style={styles.headerPurple}>Data: </Typography>
            <Typography data-cy="date_order" style={styles.fontBold}>
              {moment(date).format('DD/MM/YYYY [às] HH:mm')}
            </Typography>
          </OrderContainer>
          <PriceContainer
            display="flex"
            flexDirection="row"
            alignItems="center"
            flexWrap="wrap">
            <Typography style={styles.headerPurple}>Status: </Typography>
            <Typography data-cy="status_order" style={styles.fontBold}>
              {formatStatus(data.stores[0].status)}
            </Typography>
          </PriceContainer>
        </Box>

        <Button
          variant="contained"
          color="Dark"
          onClick={() => mountPrint(data.id)}>
          <Print />
        </Button>

        <Box onClick={handleOpen}>
          {isOpen ? (
            <ExpandLessIcon fontSize="default" style={styles.iconExpand} />
          ) : (
            <ExpandMoreIcon fontSize="default" style={styles.iconExpand} />
          )}
        </Box>
      </StatusContainerStyled>

      <BodyItem data={data} isOpen={isOpen} onSubmit={onSubmit} />
      <PrintLoad />
    </Box>
  );
};

HeaderItem.propTypes = {
  data: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  handleOpen: PropTypes.func.isRequired,
};

export default HeaderItem;
