import styled from 'styled-components';
import { Box, Typography } from '@material-ui/core';
import appColors from 'utils/appColors';

export const CartContainer = styled(Box)`
&&{
  display: flex;
  flex: 1;
  width: 60%;
  margin: 40px;
  margin-bottom: 15%;

  @media all and (max-width: 500px) {
    margin-top: 80px;
  }

  @media all and (min-width: 501px) and (max-width: 768px) {

    margin-bottom: 15%;
    margin-top: 80px;
  }

  @media all and (min-width: 769px) and (max-width: 1024px) {
    margin-bottom: 15%;
    margin-top: 80px;
  }
}
`;

export const BoxContainerOkImg = styled(Box)`
&&{
    
    @media all and (max-width:500px) {
      height: auto;
      width: auto;
      flex: 1;
      justify-content: center;
    }
    @media all and (min-width: 501px) and (max-width: 768px){
      flex:1;
      justify-content: center;
    }
  }
`;

export const BoxContainer = styled(Box)`
    && {
      padding: 30px;
      background-color: #eeeaef;
      border-radius: 10px;
      margin-top: 20px;
     
      @media all and (max-width: 500px) {
        margin-top: 20px;
        padding: 10px;
      }
    }
`;

export const BigTextBlack = styled(Typography)`
    && {
      color: #373737;
      font-size: 22px;
      text-transform: uppercase;

      @media all and (max-width: 500px) {
        font-size: 14px;
        text-align:center;
      }

      @media all and (min-width: 501px) and (max-width:768px) {
        margin-top:10px;
        text-align:center;
      }
      
      @media all and (min-width: 769px) and (max-width:1024px) {
        text-align:center;
        margin-top: 10px;
      }
    }
`;

export const BoxContainerText = styled(Box)`
    && {
      padding-left: 60px;

      @media all and (min-width: 501px) and (max-width: 768px) {
        padding-left: 0px;
        flex: 1;
        margin-bottom: 30px;
      }
      @media all and (min-width: 769px) and (max-width: 1024px) {
        padding-left: 10px;
        flex: 1;
        margin-bottom: 30px;
        padding-right: 10px;
      }
      @media all and (max-width: 500px) {
        padding-left: 0px;
        flex: 1;
        margin-bottom: 30px;
      }
    }
`;

export const BigTitlePurple = styled(Typography)`
    && {
      color: ${appColors.PRIMARY_COLOR};
      font-weight: bold;
      font-size: 20px;
      margin-top: 30px;

      @media all and (max-width: 500px) {
        font-size: 14px;
        text-align:center;
        margin-top: 10px;
      }

      @media all and (min-width: 501px) and (max-width:768px) {
        text-align:center;
        margin-top: 10px;
      }

      @media all and (min-width: 769px) and (max-width:1024px) {
        text-align:center;
        margin-top: 10px;
      }
    }
`;


const styles = {

  purpleButton: {
    backgroundColor: appColors.PRIMARY_COLOR,
    color: 'white',
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 16,
    paddingTop: 10,
    paddingBottom: 10,
  },
};

export default styles;
