import { Types as AuthTypes } from 'store/ducks/auth';

export const Types = {
  CHANGE_TAB: 'panelTabs/CHANGE_TAB',
};

export const initialState = {
  tab: 0,
  params: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.CHANGE_TAB:
      return {
        ...state,
        tab: action.payload.tab,
        params: action.payload.params,
      };

    case AuthTypes.GET_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export const Creators = {
  changeTab: (tab, params = {}) => ({
    type: Types.CHANGE_TAB,
    payload: { tab, params },
  }),
};
