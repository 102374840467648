import styled from 'styled-components';
import { Box } from '@material-ui/core';
import appColors from 'utils/appColors';

export const BoxLoginContainer = styled(Box)`
  && {
    margin-bottom: 40px;
    height: 355px;

    @media all and (max-width: 500px) {
      height: auto;
      margin-bottom: 20px;
      margin-top: 40px;
    }
  }
`;

const styles = {
  purpleTitle: {
    color: appColors.SECUNDARY_COLOR,
    fontSize: 22,
  },
};

export default styles;
