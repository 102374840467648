import React from 'react';
import { Grid } from '@material-ui/core';
import StatisticItem from 'components/StatisticItem';
import PropTypes from 'prop-types';
import { toPrice } from 'utils/converters';

const SaleCommissionInfo = ({ reportCommission, reportCommissionLoading }) => (
  <Grid
    justify="center"
    style={{ marginTop: 30, marginBottom: 30 }}
    spacing={2}
    container
  >
    {!reportCommissionLoading && reportCommission && reportCommission.prices && (
      <>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <StatisticItem
            icon="credit_card"
            title="Total"
            description={`R$ ${toPrice(reportCommission.prices.total)}`}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} md={4} lg={4}>
          <StatisticItem
            icon="aspect_ratio"
            title="Shopping"
            description={`R$ ${toPrice(reportCommission.prices.shopping)}`}
          />
        </Grid> */}
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <StatisticItem
            icon="receipt"
            title="Revendedor"
            description={`R$ ${toPrice(reportCommission.prices.reseller)}`}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <StatisticItem
            icon="attach_money"
            title="Financeiro"
            description={`R$ ${toPrice(reportCommission.prices.financial)}`}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} md={4} lg={4}>
          <StatisticItem
            icon="attach_money"
            title="Frete"
            description={`R$ ${toPrice(reportCommission.prices.delivery)}`}
          />
        </Grid> */}
      </>
    )}
  </Grid>
);

SaleCommissionInfo.propTypes = {
  reportCommission: PropTypes.oneOfType([PropTypes.object]).isRequired,
  reportCommissionLoading: PropTypes.bool.isRequired,
};

export default SaleCommissionInfo;
