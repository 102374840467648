import React from 'react';
import styles, { BoxInfoDetailProd, BoxAlign, TextCenter } from './styles';
import { Stack } from '@chakra-ui/react';

const InfoText = ({ data }) => {
  return (
    <Stack alignItems="center" p={['20px', '20px 100px']}>
      {data.show_attach && data.attachment !== null ? (
        // eslint-disable-next-line
        <iframe
          title=""
          src={data.attachment.url}
          width="100%"
          height="1000"
          style={{ border: 'none' }}
        />
      ) : (
        <BoxInfoDetailProd>
          <BoxAlign>
            <TextCenter style={styles.purpleTitle}>{data.name}</TextCenter>
            <TextCenter style={styles.mediumText}>
              <div dangerouslySetInnerHTML={{ __html: data.description }} />
            </TextCenter>
          </BoxAlign>
        </BoxInfoDetailProd>
      )}
    </Stack>
  );
};

export default InfoText;
