import React from 'react';
import { Typography } from '@material-ui/core';
import { Formik, Field } from 'formik';
import CustomTextField from 'components/form/CustomTextField';
import { InputContainer, InputItem } from 'components/form/StyledComponents';
import CustomSelect from 'components/form/CustomSelect';
import CustomButton from 'components/form/CustomButton';

const ContactForm = () => {
  return (
    <Formik
      initialValues={{}}
      validationSchema={{}}
      onSubmit={() => {}}
      validateOnBlur
      enableReinitialize
      render={() => (
        <>
          <InputContainer>
            <InputItem>
              <Typography>Nome</Typography>
              <Field component={CustomTextField} name="first_name" />
            </InputItem>
            <InputItem>
              <Typography>Sobrenome</Typography>
              <Field component={CustomTextField} name="last_name" />
            </InputItem>
          </InputContainer>
          <InputContainer>
            <InputItem>
              <Typography>Email</Typography>
              <Field component={CustomTextField} name="email" />
            </InputItem>
            <InputItem>
              <Typography>Telefone</Typography>
              <Field component={CustomTextField} name="cell_phone" />
            </InputItem>
          </InputContainer>
          <InputContainer>
            <InputItem>
              <Typography>Assunto</Typography>
              <Field
                component={CustomSelect}
                placeholder="Selecione um assunto"
                options={[]}
                name="subject"
              />
            </InputItem>
          </InputContainer>
          <InputContainer>
            <InputItem>
              <Typography>Mensagem</Typography>
              <Field
                component={CustomTextField}
                multiline
                rows="4"
                placeholder="Selecione um assunto"
                name="subject"
              />
            </InputItem>
          </InputContainer>
          <InputContainer
            style={{
              justifyContent: 'flex-end',
              marginTop: 20,
              marginBottom: 20,
              marginRight: 12,
            }}>
            <CustomButton label="ENVIAR" />
          </InputContainer>
        </>
      )}
    />
  );
};

export default ContactForm;
