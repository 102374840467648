import React from 'react';
import { useSelector } from 'react-redux';
import StoreClosed from './StoreClosed';
import DeliveryStore from './DeliveryStore';
import { BoxStyled } from 'components/Pages/MyAccountPage/PainelAccount/TabsPanel/ProductsTab/components/ProductForm/styles';
import { Tab, Tabs } from 'components/Layout/Tabs';
import { Icon } from '@material-ui/core';
import DeliveryCharges from './DeliveryCharges';
import ScheduleProduct from './ScheduleProduct';
import appTheme from 'utils/appTheme';
import HeaderComponent from 'components/HeaderComponent';

const ConfigStore = () => {
  const { store } = useSelector(state => state.admin.store);
  const [stage, setStage] = React.useState(0);

  const RenderSteps = () => {
    const Oferta = {
      0: <DeliveryCharges store={store} />,
      1: <DeliveryStore store={store} />,
      2: <ScheduleProduct store={store} />,
    }[stage];
    const Buriti = {
      0: <StoreClosed />,
      1: <DeliveryCharges store={store} />,
      2: <DeliveryStore store={store} />,
      3: <ScheduleProduct store={store} />,
    }[stage];
    const SimerPay = {
      0: <DeliveryCharges store={store} />,
      1: <DeliveryStore store={store} />,
      2: <ScheduleProduct store={store} />,
    }[stage];

    const app = {
      Oferta,
      Buriti,
      SimerPay,
    }[appTheme.name];

    return app;
  };

  return (
    <>
      <HeaderComponent title="Configuração de Entrega" />

      <BoxStyled
        style={{
          marginBottom: 20,
          backgroundColor: '#f4f4f4',
          borderRadius: 10,
        }}>
        <Tabs value={stage} onChange={(a, b) => setStage(b)}>
          {appTheme.name !== 'SimerPay' && (
            <Tab
              label="DIAS SEM FUNCIONAMENTO"
              icon={<Icon>store_mall_directory</Icon>}
            />
          )}
          <Tab
            label="TAXAS DE ENTREGA LOCAL"
            icon={<Icon>attach_money</Icon>}
          />
          <Tab
            data-cy="tab_delivery_rule"
            label="REGRA DE ENTREGA"
            icon={<Icon>checkbox</Icon>}
          />
          <Tab
            data-cy="tab_delivery_rule"
            label="AGENDAMENTO DE ENTREGA"
            icon={<Icon>date_range</Icon>}
          />
        </Tabs>
      </BoxStyled>
      {store && RenderSteps()}
    </>
  );
};

export default ConfigStore;
