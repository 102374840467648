import styled from 'styled-components';
import { Box, Card } from '@material-ui/core';

export const TrendsContainer = styled(Box)`
&&{
  width:1266px;
  margin-top:40px;

  @media all and (max-width: 500px) {
    margin-top:80px;
    width:90%;
  }
  @media all and (min-width:501px) and (max-width: 920px) {
    width:96%;
  }
  @media all and (min-width:921px) and (max-width:1740px) {
    width:90%;
  }

}
`;

export const CardShopCart = styled(Card)`
    && {
        flex: 1;
        display: flex;
        padding: 40px;
        flex-direction: row;
        margin-top: 20px;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 20px;

        @media all and (max-width: 500px) {
          padding:10px;
        }
    }
`;