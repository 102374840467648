// @flow
import React from 'react';
import { Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import appUtils from 'utils/appUtils';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles';
import appColors from 'utils/appColors';
import QuoteCepForm from './QuoteCepForm';
import CouponForm from './CupomForm';
import { Creators as CartCreators } from 'store/ducks/checkout/cart';
import { Stack, Box, Text } from '@chakra-ui/react';

const FinalizationBox = ({
  quote,
  quoteLoading,
  onSubmitQuote,
  onSubmitCupom,
  cupomLoading,
  valuesOfAllProducts,
  cartList,
}) => {
  const dispatch = useDispatch();
  const [localState, setLocalState] = React.useState({
    isCalc: false,
    budget_hash: null,
  });
  const { manpower: manpowerList }: { list: ManpowerInCart[] } = useSelector(
    state => state.checkout.cart
  );
  const { budget_hash } = useSelector(state => state.checkout.cart);
  console.log('budget_hash redux:', budget_hash);

  const [localStateCupom, setLocalStateCupom] = React.useState({
    isCupom: false,
  });

  const { isCalc } = localState;
  const { isCupom } = localStateCupom;

  const { isAuth } = useSelector(state => state.auth);

  const handleTrue = () => {
    setLocalState({ ...localState, isCalc: true });
  };

  const handleTrueCupom = () => {
    setLocalStateCupom({ ...localStateCupom, isCupom: true });
  };

  const cart = cartList.find(a => !!a.code_partner);

  const totalServices =
    manpowerList?.reduce(
      (accumulator, service) => accumulator + service.price,
      0
    ) ?? 0;

  React.useEffect(() => {
    // Atualiza o estado com o budget_hash do estado quando disponível
    if (budget_hash) {
      setLocalState(prev => ({ ...prev, budget_hash: budget_hash }));
    }
  }, [budget_hash]);

  return (
    <Stack w={['full', '420px']}>
      <Box
        style={{
          borderRadius: 5,
          border: `1px solid ${appColors.SECUNDARY_COLOR}`,
          padding: 20,
        }}>
        <Box>
          <Text mb="30px" fontSize="34px">
            Resumo
          </Text>
        </Box>

        <Box mb="20px">
          <Text fontWeight="bold" fontSize="20px">
            Subtotal
          </Text>
          <Box>
            <Text>{appUtils.formatPrice(valuesOfAllProducts.subtotal)}</Text>
          </Box>
        </Box>
        <Box mb="20px">
          <Text fontWeight="bold" fontSize="20px">
            Mão de obra
          </Text>
          <Box>
            <Text>{appUtils.formatPrice(totalServices ?? 0)}</Text>
          </Box>
        </Box>

        <Box mb="20px">
          <Text fontWeight="bold" fontSize="20px">
            Frete
          </Text>
          {isCalc ? (
            <Box flex="1">
              <QuoteCepForm
                quoteLoading={quoteLoading}
                onSubmit={onSubmitQuote}
                onSubmitQuote={onSubmitQuote}
              />
            </Box>
          ) : (
            <Box>
              <Text>
                Digite seu endereço para ver as opções de frete.
                <span
                  data-cy="btn_delivery_price"
                  style={styles.fontshipping}
                  onClick={handleTrue}>
                  {' '}
                  Calcular Frete
                </span>
              </Text>
            </Box>
          )}
        </Box>

        <Stack mb="20px">
          <Text fontWeight="bold" fontSize="20px">
            Cupom
          </Text>
          {isCupom ? (
            <Box>
              <CouponForm
                quoteLoading={cupomLoading}
                onSubmit={onSubmitCupom}
                onSubmitQuote={onSubmitCupom}
              />
            </Box>
          ) : (
            <Box>
              <Text>
                Insira seu cupom para validar o desconto.
                <span style={styles.fontshipping} onClick={handleTrueCupom}>
                  {` Inserir Cupom`}
                </span>
              </Text>
            </Box>
          )}
        </Stack>

        <Box flex="1" mb="20px">
          {quote.length > 0 &&
            !quoteLoading &&
            quote.map(item => (
              <Box
                data-cy="box_type_delivery"
                key={item.quote_hash}
                style={styles.quoteContainer}>
                <Typography>
                  <span data-cy="type_delivery" style={styles.purpleTextBold}>
                    Tipo
                  </span>
                  : {item.name}
                </Typography>
                <Typography>
                  <span
                    data-cy="via_type_delivery"
                    style={styles.purpleTextBold}>
                    Via
                  </span>
                  : {item.carrier}
                </Typography>
                {item.name === 'Retirar no Local' && (
                  <Typography>
                    <span
                      data-cy="price_withdraw_site"
                      style={styles.purpleTextBold}>
                      Valor
                    </span>
                    : R$ 00,00
                  </Typography>
                )}
                {item.name !== 'Retirar no Local' && (
                  <Typography>
                    <span
                      data-cy="price_delivery"
                      style={styles.purpleTextBold}>
                      Valor
                    </span>
                    : {appUtils.formatPrice(item.price)}
                  </Typography>
                )}
                <Typography>{item.description}</Typography>
              </Box>
            ))}
        </Box>

        <Box flex="1" display="flex" alignItems="flex-start">
          {valuesOfAllProducts.descontos > 0 && (
            <Box mb="20px">
              <Text fontWeight="bold" fontSize="20px">
                Descontos
              </Text>
              <Box>
                <Text>
                  {appUtils.formatPrice(
                    valuesOfAllProducts.descontos + totalServices
                  )}
                </Text>
              </Box>
            </Box>
          )}

          <Box mb="20px">
            <Text fontWeight="bold" fontSize="20px">
              Valor Total
            </Text>
            <Box>
              <Text
                fontWeight="bold"
                color={appColors.PRIMARY_COLOR}
                fontSize="22px">
                {appUtils.formatPrice(
                  valuesOfAllProducts.total + totalServices
                )}
              </Text>
            </Box>
          </Box>
        </Box>

        {(valuesOfAllProducts.total > 0 || totalServices > 0) && (
          <Stack flex="1" pt="20px">
            {isAuth ? (
              <Box display="flex" flexDir="row" justifyContent="space-around">
                <Button
                  onClick={() => {
                    dispatch(CartCreators.resetCart());
                    dispatch(CartCreators.resetBudgetHashCart());
                    dispatch(CartCreators.resetManpowerCart());
                  }}
                  variant="outlined"
                  style={{
                    color: appColors.SECUNDARY_COLOR,
                    borderColor: appColors.SECUNDARY_COLOR,
                    fontWeight: 'bold',
                    padding: '10px 20px',
                    marginRight: '10px',
                  }}>
                  Limpar carrinho
                </Button>
                <Link
                  to={
                    budget_hash
                      ? `/finalizar-compra?budget_hash=${budget_hash}`
                      : cart && budget_hash
                      ? `/finalizar-compra?code_partner=${cart.code_partner}&budget_hash=${budget_hash}`
                      : cart
                      ? `/finalizar-compra?code_partner=${cart.code_partner}`
                      : '/finalizar-compra'
                  }
                  style={{ textDecoration: 'none', color: 'white' }}>
                  <Button data-cy="btn_checkout" style={styles.purpleButton}>
                    FINALIZAR COMPRA
                  </Button>
                </Link>
              </Box>
            ) : (
              <Box display="flex" flexDir="row" justifyContent="space-around">
                <Button
                  onClick={() => {
                    dispatch(CartCreators.resetCart());
                    dispatch(CartCreators.resetBudgetHashCart());
                    dispatch(CartCreators.resetManpowerCart());
                  }}
                  variant="outlined"
                  style={{
                    color: appColors.SECUNDARY_COLOR,
                    borderColor: appColors.SECUNDARY_COLOR,
                    fontWeight: 'bold',
                    padding: '10px 20px',
                    marginRight: '10px',
                  }}>
                  Limpar carrinho
                </Button>
                <Link
                  to={{
                    pathname: '/minha-conta',
                    params: budget_hash
                      ? cart
                        ? {
                            redirect: `/finalizar-compra?code_partner=${cart.code_partner}&budget_hash=${budget_hash}`,
                          }
                        : {
                            redirect: `/finalizar-compra?budget_hash=${budget_hash}`,
                          }
                      : cart
                      ? {
                          redirect: `/finalizar-compra?code_partner=${cart.code_partner}`,
                        }
                      : {
                          redirect: '/finalizar-compra',
                        },
                  }}
                  style={{ textDecoration: 'none', color: 'white' }}>
                  <Button
                    data-cy="btn_checkout_redirect_login"
                    style={styles.purpleButton}>
                    FINALIZAR COMPRA
                  </Button>
                </Link>
              </Box>
            )}
          </Stack>
        )}
      </Box>
    </Stack>
  );
};

FinalizationBox.propTypes = {
  dataCart: PropTypes.array.isRequired,
  quote: PropTypes.array.isRequired,
  quoteLoading: PropTypes.bool.isRequired,
  quoteError: PropTypes.any,
  cupom: PropTypes.array.isRequired,
  cupomLoading: PropTypes.bool.isRequired,
  cupomError: PropTypes.any,
};

export default FinalizationBox;
