import {
  Box,
  CircularProgress,
  FormControl,
  Input,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import {
  CustomObjectCheckboxGroup,
  CustomObjectCheckboxGroupItem,
} from 'components/form/components/CustomObjectCheckboxGroup';
import { Field } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputItem, InputView } from './styles';
import api from 'services/api';
import { Creators as CategoryCreators } from 'store/ducks/admin/category';

function Categories({ errors, setFieldValue, values }) {
  const dispatch = useDispatch();
  const [categorySelectedData, setCategorySelectedData] = useState({});
  const [subCategories, setSubCategories] = useState([]);
  const [genres, setGenres] = React.useState([]);
  const [textSeachCategory, setTextSeachCategory] = React.useState('');
  const {
    admin: {
      category: { categoryList, categoryListLoading },
      product: { productInsertLoading },
    },
  } = useSelector(state => state);

  const [categorySelected, setCategorySelected] = useState(
    categoryList.filter(a => {
      if (values.categories.length > 0) {
        return values.categories.some(b => b.id === a.id);
      }
      return null;
    }).length > 0 &&
      categoryList.filter(a => {
        if (values.categories.length > 0) {
          return values.categories.some(b => b.id === a.id);
        }
        return null;
      })[0].id
  );

  const handleCategorySelect = ({ target: { value } }) => {
    setCategorySelected(value);
    const category = categoryList.find(item => item.id === value);
    if (category) {
      if (
        categorySelectedData.id !== null &&
        categorySelectedData.id !== value
      ) {
        setFieldValue('categories', []);
      }
      setSubCategories(category.category_child);
      setCategorySelectedData(category);
    }
  };

  useEffect(() => {
    const category = categoryList.find(item => item.id === categorySelected);
    if (category) {
      setSubCategories(category.category_child);
      setCategorySelectedData(category);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryList]);

  useEffect(() => {
    dispatch(CategoryCreators.getCategoryListRequest({ perPage: 1000 }));
    api.get('/v1/admin/products/genres').then(response => {
      setGenres(response.data.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <InputView>
        <InputItem>
          <Box style={{ paddingRight: 20 }}>
            <Typography variant="h6">Categoria principal</Typography>
            <Box
              style={{
                maxHeight: 190,
                minWidth: '100%',
                overflow: 'auto',
                marginTop: 0,
              }}>
              {categoryList.length > 0 && (
                <>
                  <FormControl variant="outlined">
                    <Select
                      id="categoria"
                      value={categorySelected}
                      placeholder="Selecionar uma categoria"
                      onChange={e => handleCategorySelect(e)}
                      style={{ minWidth: '300px' }}
                      fullWidth>
                      {categoryList
                        .filter(
                          item =>
                            (!item.category_father ||
                              Object.keys(item.category_father).length === 0) &&
                            item.category_child.length > 0
                        )
                        .map((category, idx) => (
                          <MenuItem
                            key={idx}
                            style={{ minWidth: '300px' }}
                            value={category.id}>
                            {category.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </>
              )}
            </Box>
            {typeof errors.categories !== 'undefined' && (
              <Box>
                <Typography style={{ color: 'red' }}>
                  {errors.categories}
                </Typography>
              </Box>
            )}
            {categoryListLoading ||
              (productInsertLoading && (
                <Box flex="1" display="flex" justifyContent="center">
                  <CircularProgress size={25} style={{ color: '#ce4899' }} />
                </Box>
              ))}
          </Box>
        </InputItem>
        <InputItem>
          {categorySelected && (
            <Box
              display="flex"
              flexDirection="column"
              style={{
                marginTop: 0,
                overflow: 'auto',
                maxHeight: '400px',
                paddingRight: '20px',
              }}>
              <Typography variant="h6">Categoria secundária</Typography>
              <Input
                onChange={e => setTextSeachCategory(e.target.value)}
                value={textSeachCategory}
                placeholder="Pesquisar categoria secundária"
              />
              {subCategories
                .filter(x =>
                  textSeachCategory.length > 0
                    ? x.name
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .toLowerCase()
                        .indexOf(
                          String(textSeachCategory)
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                        ) !== -1
                    : x
                )
                .map(category => (
                  <Box key={category.id} style={{ marginTop: 10 }}>
                    <Box>
                      <Field
                        name="categories"
                        component={CustomObjectCheckboxGroup}>
                        <Field
                          label={category.name}
                          name="categories"
                          value={{
                            id: category.id,
                            name: category.name,
                            father: category.category_father,
                          }}
                          component={CustomObjectCheckboxGroupItem}
                        />
                      </Field>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      style={{ marginLeft: 25 }}>
                      {category.category_child.map((subcategory, idx) => (
                        <Box key={idx}>
                          <Box>
                            <Field
                              name="categories"
                              component={CustomObjectCheckboxGroup}>
                              <Field
                                label={subcategory.name}
                                name="categories"
                                value={{
                                  id: subcategory.id,
                                  name: subcategory.name,
                                  father: category.category_father,
                                }}
                                component={CustomObjectCheckboxGroupItem}
                              />
                            </Field>
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            style={{ marginLeft: 25 }}>
                            {subcategory.category_child
                              .filter(x =>
                                textSeachCategory.length > 0
                                  ? x.name
                                      .normalize('NFD')
                                      .replace(/[\u0300-\u036f]/g, '')
                                      .toLowerCase()
                                      .indexOf(
                                        String(textSeachCategory)
                                          .toLowerCase()
                                          .normalize('NFD')
                                          .replace(/[\u0300-\u036f]/g, '')
                                      ) !== -1
                                  : x
                              )
                              .map((item, idx) => (
                                <Field
                                  key={idx}
                                  name="categories"
                                  component={CustomObjectCheckboxGroup}>
                                  <Field
                                    label={item.name}
                                    name="categories"
                                    value={{
                                      id: item.id,
                                      name: item.name,
                                      father: category.category_father,
                                    }}
                                    component={CustomObjectCheckboxGroupItem}
                                  />
                                </Field>
                              ))}
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ))}
            </Box>
          )}
        </InputItem>

        {categorySelectedData && categorySelectedData.has_genre && (
          <InputItem>
            <Box
              style={{
                paddingLeft: 20,
                overflow: 'auto',
                maxHeight: '190px',
                paddingRight: '20px',
              }}>
              <>
                <Typography variant="h6">Gêneros</Typography>
                <Box
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}>
                  <Box
                    style={{
                      marginTop: 20,
                      marginRight: 20,
                    }}>
                    <Field name="genres" component={CustomObjectCheckboxGroup}>
                      {genres.map((genre, idx) => (
                        <Field
                          key={idx}
                          label={genre.name}
                          value={{
                            id: genre.id,
                            name: genre.name,
                          }}
                          component={CustomObjectCheckboxGroupItem}
                        />
                      ))}
                    </Field>
                  </Box>
                </Box>
              </>
            </Box>
          </InputItem>
        )}
      </InputView>
    </div>
  );
}

export default Categories;
