const orderStatus = status => {
  switch (status) {
    case 0:
      return 'Aguardando Pagamento';
    case 1:
      return 'Pagamento Autorizado';
    case 2:
      return 'Faturado Parcialmente';
    case 3:
      return 'Faturado';
    case 4:
      return 'Preparando pedido';
    case 5:
      return 'Aguardando Envio';
    case 6:
      return 'Enviado';
    case 7:
      return 'Entregue';
    case 8:
      return 'Entregue Parcialmente';
    case 9:
      return 'Aguardando Retirada';
    case 10:
      return 'Devolvido';
    case 11:
      return 'Devolvido Parcialmente';
    case 16:
      return 'Pagamento na entrega';
    case 98:
      return 'Não Autorizado / Pagamento Recusado';
    case 99:
      return 'Cancelado';
    default:
      return '';
  }
};

export const StatusArray = [
  { id: 0, name: 'Aguardando Pagamento' },
  { id: 1, name: 'Pagamento Autorizado' },
  { id: 2, name: 'Faturado Parcialmente' },
  { id: 3, name: 'Faturado' },
  { id: 4, name: 'Preparando pedido' },
  { id: 5, name: 'Aguardando CD' },
  { id: 6, name: 'Enviado' },
  { id: 7, name: 'Entregue' },
  { id: 8, name: 'Entregue Parcialmente' },
  { id: 9, name: 'Aguardando Retirada' },
  { id: 10, name: 'Devolvido' },
  { id: 11, name: 'Devolvido Parcialmente' },
  { id: 12, name: 'Aguardando Retirada Cliente' },
  { id: 98, name: 'Não Autorizado / Pagamento Recusado' },
  { id: 99, name: 'Cancelado' },
];

export const StatusArrayPayment = [
  { id: 0, name: 'Aguardando Pagamento' },
  { id: 1, name: 'Pagamento Autorizado' },
  { id: 2, name: 'Faturado Parcialmente' },
  { id: 3, name: 'Faturado' },
];
export const StatusArrayQuote = [
  { id: 4, name: 'Preparando pedido' },
  { id: 5, name: 'Aguardando CD' },
  { id: 6, name: 'Enviado' },
  { id: 7, name: 'Entregue' },
  { id: 8, name: 'Entregue Parcialmente' },
  { id: 9, name: 'Aguardando Retirada' },
  { id: 10, name: 'Devolvido' },
  { id: 11, name: 'Devolvido Parcialmente' },
  { id: 98, name: 'Não Autorizado / Pagamento Recusado' },
  { id: 99, name: 'Cancelado' },
];

export default orderStatus;
