/*
 *
 * Report reducer
 *
 */

export const Types = {
  // Obtem catergorias
  GET_WALLET_REQUEST: 'wallet/GET_WALLET_REQUEST',
  GET_WALLET_SUCCESS: 'wallet/GET_WALLET_SUCCESS',
  GET_WALLET_FAILURE: 'wallet/GET_WALLET_FAILURE',
  //obtem o status da wallet
  GET_WALLETSTATUS_REQUEST: 'wallet/GET_WALLETSTATUS_REQUEST',
  GET_WALLETSTATUS_SUCCESS: 'wallet/GET_WALLETSTATUS_SUCCESS',
  GET_WALLETSTATUS_FAILURE: 'wallet/GET_WALLETSTATUS_FAILURE',

  GET_WALLET_BANK_RESELLER_REQUEST: 'wallet/GET_WALLET_BANK_RESELLER_REQUEST',
  GET_WALLET_BANK_RESELLER_SUCCESS: 'wallet/GET_WALLET_BANK_RESELLER_SUCCESS',
  SET_WALLET_BANK_RESELLER_REQUEST: 'wallet/SET_WALLET_BANK_RESELLER_REQUEST',

  // ANTICIPATION CALCULE
  GET_WALLET_ANTICIPATIONS_CALC: 'wallet/GET_WALLET_ANTICIPATIONS_CALC',
  GET_WALLET_ANTICIPATIONS_CALC_SUCCESS:
    'wallet/GET_WALLET_ANTICIPATIONS_CALC_SUCCESS',
  GET_WALLET_ANTICIPATIONS_CALC_RESET:
    'wallet/GET_WALLET_ANTICIPATIONS_CALC_RESET',

  // ANTICIPATION
  GET_WALLET_ANTICIPATION_REQUEST: 'wallet/GET_WALLET_ANTICIPATION_REQUEST',
  GET_WALLET_ANTICIPATION_SUCCESS: 'wallet/GET_WALLET_ANTICIPATION_SUCCESS',
  GET_WALLET_ANTICIPATION_FAILURE: 'wallet/GET_WALLET_ANTICIPATION_FAILURE',

  GET_WALLET_EXTRACT_REQUEST: 'wallet/GET_WALLET_EXTRACT_REQUEST',
  GET_WALLET_EXTRACT_SUCCESS: 'wallet/GET_WALLET_EXTRACT_SUCCESS',

  //walletdocuments
  GET_WALLETDOCUMENTS_REQUEST: 'wallet/GET_WALLETDOCUMENTS_REQUEST',
  GET_WALLETDOCUMENTS_SUCCESS: 'wallet/GET_WALLETDOCUMENTS_SUCCESS',
  GET_WALLETDOCUMENTS_FAILURE: 'wallet/GET_WALLETDOCUMENTS_FAILURE',
};

export const initialState = {
  // Categoria por id
  wallet: {},
  walletDocuments: {},
  walletStatus: {},
  bank_reseller: {},
  walletLoading: false,
  walletDocumentsLoading: false,
  walletError: null,
  walletDocumentsError: null,
  calc_anticipation: {
    calc_anticipation: {},
    calcLoading: false,
  },
  anticipation: {
    loading: false,
    error: false,
    data: {},
    amount: false,
  },
  extract: { extrat: [], extractLoading: false },
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Categoria por id
    case Types.GET_WALLET_REQUEST:
      return {
        ...state,
        wallet: {},
        walletError: null,
        walletLoading: true,
      };
    case Types.GET_WALLET_SUCCESS:
      return {
        ...state,
        wallet: action.payload.data,
        walletLoading: false,
        walletError: null,
      };
    case Types.GET_WALLET_FAILURE:
      return {
        ...state,
        walletLoading: false,
        walletError: action.payload,
      };
    case Types.GET_WALLETDOCUMENTS_REQUEST:
      return {
        ...state,
        walletDocuments: {},
        walletDocumentsError: null,
        walletDocumentsLoading: true,
      };
    case Types.GET_WALLETDOCUMENTS_SUCCESS:
      return {
        ...state,
        walletDocuments: action.payload.data,
        walletDocumentsLoading: false,
        walletDocumentsError: null,
      };
    case Types.GET_WALLETDOCUMENTS_FAILURE:
      return {
        ...state,
        walletDocumentsLoading: false,
        walletDocumentsError: action.payload,
      };
    case Types.GET_WALLETSTATUS_REQUEST:
      return {
        ...state,
        walletStatus: {},
        walletStatusError: null,
        walletStatusLoading: true,
      };
    case Types.GET_WALLETSTATUS_SUCCESS:
      return {
        ...state,
        walletStatus: action.payload.data,
        walletStatusLoading: false,
        walletStatusError: null,
      };
    case Types.GET_WALLETSTATUS_FAILURE:
      return {
        ...state,
        walletStatusLoading: false,
        walletStatusError: action.payload,
      };
    case Types.GET_WALLET_BANK_RESELLER_SUCCESS:
      return {
        ...state, // falta concluir, testando outro método
        bank_reseller: action.payload.data,
      };
    case Types.GET_WALLET_ANTICIPATIONS_CALC:
      return {
        ...state,
        calc_anticipation: {
          calc_anticipation: {},
          calcLoading: true,
        },
      };
    case Types.GET_WALLET_ANTICIPATIONS_CALC_SUCCESS:
      return {
        ...state,
        calc_anticipation: {
          calc_anticipation: action.payload.data,
          calcLoading: false,
        },
      };
    case Types.GET_WALLET_ANTICIPATIONS_CALC_RESET:
      return {
        ...state,
        calc_anticipation: { calc_anticipation: {}, calcLoading: false },
      };

    case Types.GET_WALLET_ANTICIPATION_REQUEST:
      return {
        ...state,
        anticipation: { loading: true, error: false, amount: action.amount },
      };

    case Types.GET_WALLET_ANTICIPATION_SUCCESS:
      return {
        ...state,
        anticipation: { loading: false, error: false, data: action.payload },
      };

    case Types.GET_WALLET_ANTICIPATION_FAILURE:
      return { ...state, anticipation: { loading: false, error: true } };

    case Types.GET_WALLET_EXTRACT_SUCCESS:
      return {
        ...state,
        extract: {
          extract: action.payload.data,
          extractLoading: false,
          summary: action.payload.summary,
          total: action.payload.total,
        },
      };
    default:
      return state;
  }
};

export const Creators = {
  // Busca uma categoria
  getWalletRequest: () => ({
    type: Types.GET_WALLET_REQUEST,
    payload: {},
  }),
  getWalletSuccess: ({ data }) => ({
    type: Types.GET_WALLET_SUCCESS,
    payload: { data },
  }),
  getWalletFailure: error => ({
    type: Types.GET_WALLET_FAILURE,
    payload: error,
  }),
  getWalletDocumentsRequest: () => ({
    type: Types.GET_WALLETDOCUMENTS_REQUEST,
    payload: {},
  }),
  getWalletDocumentsSuccess: ({ data }) => ({
    type: Types.GET_WALLETDOCUMENTS_SUCCESS,
    payload: { data },
  }),
  getWalletDocumentsFailure: error => ({
    type: Types.GET_WALLETDOCUMENTS_FAILURE,
    payload: error,
  }),
  getWalletStatusRequest: () => ({
    type: Types.GET_WALLETSTATUS_REQUEST,
    payload: {},
  }),
  getWalletStatusSuccess: ({ data }) => ({
    type: Types.GET_WALLETSTATUS_SUCCESS,
    payload: { data },
  }),
  getWalletStatusFailure: error => ({
    type: Types.GET_WALLETSTATUS_FAILURE,
    payload: error,
  }),
  getBankReseller: () => ({
    type: Types.GET_WALLET_BANK_RESELLER_REQUEST,
    payload: {},
  }),
  getBankResellerSuccess: ({ data }) => ({
    type: Types.GET_WALLET_BANK_RESELLER_SUCCESS,
    payload: { data },
  }),
  setWalletBankResellerRequest: data => ({
    type: Types.SET_WALLET_BANK_RESELLER_REQUEST,
    payload: { data },
  }),
  getWalletAnticipationsCalc: data => ({
    type: Types.GET_WALLET_ANTICIPATIONS_CALC,
    payload: { amount: data },
  }),
  getWalletAnticipationsCalcSuccess: ({ data }) => ({
    type: Types.GET_WALLET_ANTICIPATIONS_CALC_SUCCESS,
    payload: { data },
  }),
  getWalletAnticipationsCalcReset: () => ({
    type: Types.GET_WALLET_ANTICIPATIONS_CALC_RESET,
  }),
  getWalletExtractRequest: data => ({
    type: Types.GET_WALLET_EXTRACT_REQUEST,
    payload: data,
  }),
  getWalletExtractSuccess: ({ data, total, summary }) => ({
    type: Types.GET_WALLET_EXTRACT_SUCCESS,
    payload: { data, total, summary },
  }),

  // ANTICIPATION
  getAnticipationRequest: amount => ({
    type: Types.GET_WALLET_ANTICIPATION_REQUEST,
    amount,
  }),
  getAnticipationSuccess: payload => ({
    type: Types.GET_WALLET_ANTICIPATION_SUCCESS,
    payload,
  }),
  getAnticipationFailure: () => ({
    type: Types.GET_WALLET_ANTICIPATION_FAILURE,
  }),
};
