import React from 'react';
import { Box, Button, Typography, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import styles from './styles';
import { InputContainer, InputItem } from 'components/form/StyledComponents';
import CustomTextField from 'components/form/CustomTextField';
import * as Yup from 'yup';
import CepMaskField from './CepMaskField';

export const initialValues = {
    code_post: '',
    street: '',
    number: '',
    district: '',
    complement: '',
    city: {
        id: '',
        name: '',
        state: {
            id: '',
            name:'',
        }
    },
};

const schema = Yup.object().shape({
    code_post: Yup.string()
        .required('Informe o CEP'),
    street: Yup.string()
        .required('Infome a Rua ou Avenida'),
    number: Yup.string()
        .required('Informe o numero do local'),
    district: Yup.string()
        .required('Informe o bairro'),
    complement: Yup.string(),
    city: Yup.object().shape({
        id: Yup.string()
            .required('Informe a cidade'),
        state: Yup.object().shape({
            id: Yup.string()
                .required('Informe o estado')
        }),
    }),

});

const NewAddressForm = ({ onSubmit, initialValues, addressInsertLoading, onSubmitCEP, cepError }) => {

    const validateCEP = value => {
        let error;
        if (value.replace(/_/g, "").replace(/-/g, "").length < 8) {
            error = 'Complete o CEP';
        }
        return error;
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={onSubmit}
            validateOnBlur
            render={({ handleSubmit }) => (
                <Box flex='1' display='flex' flexDirection='column' style={{ minWidth: 200 }}>
                    <Form onSubmit={handleSubmit} style={{ marginBottom: 20 }}>
                        <InputContainer>
                            <InputItem>
                                <Typography>CEP</Typography>
                                <Field
                                    name="code_post"
                                    label="Informe o CEP"
                                    mask="99999-999"
                                    validate={validateCEP}
                                    onSubmitCEP={onSubmitCEP}
                                    component={CepMaskField}
                                />
                            </InputItem>
                            <InputItem style={{ flexGrow: 10, }}>
                                <Typography>Rua/Avenida</Typography>
                                <Field
                                    name="street"
                                    label="Infome o nome da rua ou avenida"
                                    component={CustomTextField}
                                />
                            </InputItem>
                        </InputContainer>
                        <InputContainer>
                            <InputItem>
                                <Typography>Número</Typography>
                                <Field
                                    name="number"
                                    label="Infome o número do local"
                                    component={CustomTextField}
                                />
                            </InputItem>
                            <InputItem>
                                <Typography>Bairro</Typography>
                                <Field
                                    name="district"
                                    label="Informe o bairro"
                                    component={CustomTextField}
                                />
                            </InputItem>
                        </InputContainer>
                        <InputContainer>
                            <InputItem>
                                <Typography>Cidade</Typography>
                                <Field
                                    disabled
                                    name="city.name"
                                    label="Infome o número do local"
                                    component={CustomTextField}
                                />
                            </InputItem>
                            <InputItem>
                                <Typography>Estado</Typography>
                                <Field
                                    disabled
                                    name="city.state.name"
                                    label="Infome o número do local"
                                    component={CustomTextField}
                                />
                            </InputItem>
                        </InputContainer>
                        <InputContainer>
                            <InputItem>
                                <Typography>Complemento</Typography>
                                <Field
                                    name="complement"
                                    label="Adicione um complemento (Opcional)"
                                    component={CustomTextField}
                                />
                            </InputItem>
                            <Box flex='1' display='flex' alignItems='flex-end' justifyContent='flex-end'>
                                <Button data-cy="btn_save_address" type='submit' style={styles.purpleButton}>
                                    Cadastrar Endereço 
                                    {addressInsertLoading &&
                                        <CircularProgress size={15} style={{ color: 'white', marginLeft: 10 }} />
                                    }
                                </Button>
                            </Box>
                        </InputContainer>
                        {cepError &&
                            <Box style={{ paddingTop: 5, marginLeft: 10, marginTop: 10, color: 'red' }}>
                                <Typography>{cepError}</Typography>
                            </Box>
                        }
                    </Form>
                </Box >
            )} />

    );
}

NewAddressForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    addressInsertLoading: PropTypes.bool.isRequired,
}

NewAddressForm.defaultProps = {
    initialValues,
}

export default NewAddressForm;




