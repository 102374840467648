import React, { useState } from 'react';
import { Box, Button, Flex, Tag, Text } from '@chakra-ui/react';
import { IoWarningOutline } from 'react-icons/io5';
import HeaderComponent from 'components/HeaderComponent';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as WalletCreators } from 'store/ducks/admin/wallet';
import { Creators as NotificationCreators } from 'store/ducks/app';
import api from 'services/api';
import DocumentsModal from './DocumentsModal';
import appColors from 'utils/appColors';
import PageBase from 'components/PageBase';

const WalletDocuments = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [documentOpen, setDocumentOpen] = useState({});

  const [modalOpen, setModalOpen] = React.useState(false);
  const status = {
    PENDING: {
      label: 'Pendente',
      color: '#D3C333',
    },
    FAILED: {
      label: 'Recusado',
      color: '#EB0000',
    },
    DONE: {
      label: 'Aprovada',
      color: '#0A9330',
    },
    APPROVED: {
      label: 'Aprovada',
      color: '#0A9330',
    },
    NOT_SENT: {
      label: 'Não Enviado',
      color: '#D3C333',
    },
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleModalOpen = item => {
    setDocumentOpen(item);
    setModalOpen(true);
  };

  const handleSubmit = async (event, documentInfo, files) => {
    setLoading(true);
    event.preventDefault();
    const formData = new FormData();
    console.log(documentInfo);
    formData.append('document_file', files[documentInfo.id].document_file);
    formData.append('document_type', documentInfo.type);
    formData.append('document_id', documentInfo.id);

    const res = await api.post('/v1/admin/wallets/documents', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log(res);
    if (res.status !== 200 && res.status !== 201) {
      dispatch(
        NotificationCreators.openNotification({
          message: `${res?.data?.msg}`,
          type: 'error',
        })
      );
    } else {
      dispatch(
        NotificationCreators.openNotification({
          message: `Documento enviado com sucesso!`,
          type: 'success',
        })
      );
      handleModalClose();
      await dispatch(WalletCreators.getWalletDocumentsRequest());
    }
    setLoading(false);
  };

  const {
    admin: {
      wallet: { walletDocuments },
    },
  } = useSelector(state => state);

  React.useEffect(() => {
    dispatch(WalletCreators.getWalletDocumentsRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageBase>
      <HeaderComponent title="Documentos da Conta Digital" />

      <DocumentsModal
        handleClose={handleModalClose}
        isOpen={modalOpen}
        onSubmit={handleSubmit}
        document={documentOpen}
        // isFinished={isFinished}
        // isOk={isOk}
        loading={loading}
      />

      <Box
        mb="40px"
        w="830px"
        h="100%"
        bg="#fff"
        marginRight={-1}
        borderRadius={'30px'}>
        {walletDocuments && (
          <Box mb="20px">
            <Text mb="10px" fontSize="25px" fontWeight="bold">
              Status Geral
            </Text>

            {walletDocuments?.errors?.map((item, idx) => (
              <Tag key={idx} background="red" color="white">
                {item.description}
              </Tag>
            ))}
          </Box>
        )}

        {walletDocuments?.length > 0 ? (
          <>
            {walletDocuments?.map((item, idx) => (
              <Box mb="20px" key={idx} borderTop="1px solid #8B8E93">
                <Flex
                  mt="20px"
                  mb="8px"
                  alignItems="center"
                  justifyContent="space-between">
                  <Text fontSize="16px" fontWeight="bold">
                    {item.title}
                    <Text fontSize="16px" fontWeight="400">
                      {item.description}
                    </Text>
                    <Text fontSize="16px" fontWeight="bold">
                      {item?.status === 'PENDING' &&
                        ' Aguardando os documentos enviados serem analisados! '}
                    </Text>
                  </Text>

                  <Tag
                    background={status[item?.status]?.color}
                    color="white"
                    fontWeight="bold">
                    {status[item?.status]?.label}
                  </Tag>
                </Flex>
                <Button
                  isDisabled={item?.status === 'APPROVED' ? true : false}
                  style={{
                    height: '40px',
                    background: appColors.PRIMARY_COLOR,
                    fontSize: '16px',
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                  onClick={() => {
                    if (item.onboarding_url)
                      window.open(item.onboarding_url, '_blank');
                    else handleModalOpen(item);
                  }}>
                  Enviar documento
                </Button>
              </Box>
            ))}
          </>
        ) : (
          <Box marginLeft={4} display="flex" alignItems="center">
            <IoWarningOutline fontSize="50px" />
            <Text ml="10px" fontSize="30px">
              Não existem documentos pendentes de envio.
            </Text>
          </Box>
        )}
      </Box>
    </PageBase>
  );
};

export default WalletDocuments;
