import styled from 'styled-components';
import { Box } from '@material-ui/core';
import appColors from 'utils/appColors';

export const BoxLoginContainer = styled(Box)`
  && {
    margin-bottom: 40px;
    height: 355px;

    @media all and (max-width: 500px) {
      height: auto;
      margin-bottom: 20px;
      margin-top: 40px;
    }
  }
`;

const styles = {
  purpleTitle: {
    color: appColors.SECUNDARY_COLOR,
    fontSize: 22,
    width: '100%',
    textAlign: 'center',
  },
  purpleButtonProceed: {
    backgroundColor: appColors.PRIMARY_COLOR,
    color: 'white',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    width: '50%',
    marginBottom: 30,
  },

  secondaryButtonProceed: {
    backgroundColor: appColors.GRAY,
    color: 'white',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    width: '50%',
    marginBottom: 30,
  },

  boxRegister: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    height: '100%',
  },
};

export default styles;
