import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as OrderCreators } from 'store/ducks/admin/order';
import appColors from 'utils/appColors';
import OrderItem from './OrderItem';
import useQuery from 'hooks/useQuery';

const OrdersTabShop = () => {
  const [filters] = React.useState({
    page: 1,
    perPage: 10,
    search: '',
    orderByColumn: '',
    orderByDirection: '',
    order: '',
  });

  const dispatch = useDispatch();
  const query = useQuery();
  const {
    admin: {
      order: { order, orderLoading },
    },
  } = useSelector(state => state);

  React.useEffect(() => {
    dispatch(OrderCreators.getOrderRequest({ id: query.get('id_pedido') }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filters]);

  const onSubmit = form => {
    dispatch(
      OrderCreators.getUpdateStatusRequest({
        id: order.id,
        status: form.status,
      })
    );
  };

  return (
    <Box flex="1" display="flex" flexDirection="column">
      {Object.keys(order).length > 0 && !orderLoading && (
        <>
          <OrderItem key={order.id} open data={order} onSubmit={onSubmit} />
        </>
      )}
      {Object.keys(order).length === 0 && orderLoading && (
        <Box
          flex="1"
          display="flex"
          justifyContent="center"
          alignItems="center">
          <CircularProgress style={{ color: appColors.PRIMARY_COLOR }} />
        </Box>
      )}
    </Box>
  );
};

export default OrdersTabShop;
