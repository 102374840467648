/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import OkImage from 'images/logos/order-ok.png';
import BarCodeImage from 'images/others/bar-code.png';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import { Creators as NotificationCreators } from 'store/ducks/app';
import { useDispatch, useSelector } from 'react-redux';
import appColors from 'utils/appColors';
import styles, {
  BoxContainer,
  CartContainer,
  BigTextBlack,
  BigTitlePurple,
  BoxContainerText,
  BoxContainerBarcode,
  BoxContainerOkImg,
} from './styles';

const IssueTicketPage = ({ handleStep }) => {
  const dispatch = useDispatch();
  const { orderListSuccess } = useSelector(state => state.order);

  const [ok, setOk] = React.useState(false);

  const handleOk = () => {
    setOk(true);
  };

  React.useEffect(() => {
    handleStep(3);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const boleto =
    typeof orderListSuccess !== 'undefined'
      ? orderListSuccess.payments?.line_code
      : '';

  const handleCopy = () => {
    const link = orderListSuccess.payments.line_code;
    navigator.clipboard.writeText(link);
    dispatch(
      NotificationCreators.openNotification({
        message: 'Código copiado para a área de transferência',
        type: 'success',
      })
    );
  };

  return (
    <CartContainer flex="1" flexDirection="column" flexWrap="wrap">
      {boleto !== '' && (
        <>
          <BoxContainer
            flex="1"
            display="flex"
            flexDirection="row"
            flexWrap="wrap">
            <BoxContainerOkImg>
              <img alt="" src={OkImage} />
            </BoxContainerOkImg>
            <BoxContainerText
              flex="1"
              display="flex"
              justifyContent="center"
              flexDirection="column">
              <Box>
                <BigTextBlack>
                  RECEBEMOS SEU PEDIDO!
                  <br />
                  ESTAMOS AGUARDANDO APROVAÇÃO
                </BigTextBlack>
              </Box>
              <Box style={{ marginTop: 30 }}>
                <BigTitlePurple>AGRADECEMOS A PREFERÊNCIA!</BigTitlePurple>
              </Box>
            </BoxContainerText>
            <BoxContainerText
              flex="1"
              display="flex"
              justifyContent="center"
              flexDirection="column">
              <BoxContainerBarcode>
                <img alt="" src={BarCodeImage} width={250} />{' '}
              </BoxContainerBarcode>
              <Box
                flex="1"
                display="flex"
                alignItems="center"
                flexDirection="column"
                justifyContent="center">
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                  style={{ marginBottom: 20 }}>
                  <Typography
                    id="bar_code"
                    style={{
                      marginLeft: 15,
                      fontSize: 22,
                      wordWrap: 'break-word',
                      maxWidth: 150,
                    }}>
                    {boleto}
                  </Typography>
                  <CopyToClipboard
                    text={boleto}
                    style={{ marginLeft: 10 }}
                    onCopy={handleCopy}>
                    <Box>
                      <Button
                        style={{
                          backgroundColor: appColors.PRIMARY_COLOR,
                          height: 60,
                          width: 60,
                          borderRadius: 100,
                        }}>
                        <FilterNoneIcon style={{ color: 'white' }} />
                      </Button>
                    </Box>
                  </CopyToClipboard>
                </Box>
                <a
                  href={
                    typeof orderListSuccess.payments !== 'undefined'
                      ? orderListSuccess.payments.line_url
                      : null
                  }
                  onClick={handleOk}
                  target="_self"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  rel="noreferrer">
                  <Button style={styles.greenButton}>Baixar Boleto</Button>
                </a>
                {ok && window.open(orderListSuccess.payments?.line_url, '_self')}
              </Box>
            </BoxContainerText>
          </BoxContainer>
        </>
      )}
      {boleto === '' && (
        <Box>
          <Typography>
            Usuário sem conta wirecard ou acesso direto, entre em contato com
            nosso suporte e verifique!
          </Typography>
        </Box>
      )}
    </CartContainer>
  );
};

export default IssueTicketPage;
