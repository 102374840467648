import CustomRichText from 'components/form/components/CustomRichText';
import { FastField } from 'formik';
import React from 'react';

import { InputItem, InputView } from './styles';

function Description() {
  return (
    <div>
      <InputView>
        <InputItem>
          <FastField
            name="description"
            isClient={true}
            label="Descrição Principal"
            component={CustomRichText}
          />
        </InputItem>
        <InputItem>
          <FastField
            name="description_tec"
            label="Descrição Técnica"
            component={CustomRichText}
          />
        </InputItem>
      </InputView>
    </div>
  );
}

export default Description;
