// @flow
import { takeLatest, all, put, select } from 'redux-saga/effects';

import {
  Types,
  Creators,
  type CheckoutCartType,
} from 'store/ducks/checkout/cart';
import { Creators as NotificationCreators } from 'store/ducks/app';

function* addItemToCart({ payload }) {
  const { item }: { item: ProductInCart } = payload;
  const checkoutCartStore: CheckoutCartType = yield select(
    state => state.checkout.cart
  );
  const existsInCart = checkoutCartStore.list.find(
    x => x.code_internal === item.code_internal
  );
  let updatedProducts: ProductInCart[] = [];
  if (existsInCart) {
    updatedProducts = checkoutCartStore.list.map(x =>
      x.code_internal === item.code_internal
        ? {
            ...x,
            quantity: x.quantity + item.quantity,
          }
        : x
    );
  } else {
    updatedProducts = [...checkoutCartStore.list, item];
  }
  yield put(Creators.addItemToCartSucess(updatedProducts));
  yield put(
    NotificationCreators.openNotification({
      message: 'Item adicionado ao carrinho',
      type: 'success',
    })
  );
}

function* removeItemFromCart({ payload }) {
  const { item }: { item: ProductInCart } = payload;
  const checkoutCartStore: CheckoutCartType = yield select(
    state => state.checkout.cart
  );
  const existsInCart = checkoutCartStore.list.find(
    x => x.code_internal === item.code_internal
  );
  let updatedProducts: ProductInCart[] = [];
  if (existsInCart) {
    if (existsInCart.quantity === 1) {
      updatedProducts = checkoutCartStore.list.filter(
        x => x.code_internal !== item.code_internal
      );
    } else {
      updatedProducts = checkoutCartStore.list.map(x =>
        x.code_internal === item.code_internal
          ? {
              ...x,
              quantity: x.quantity - 1,
            }
          : x
      );
    }
    yield put(Creators.removeItemFromCartSuccess(updatedProducts));
    yield put(
      NotificationCreators.openNotification({
        message: 'Item removido do carrinho',
        type: 'success',
      })
    );
  }
}

function* removeGroupItensFromCart({ payload }) {
  const { item }: { item: ProductInCart } = payload;
  const checkoutCartStore: CheckoutCartType = yield select(
    state => state.checkout.cart
  );
  const existsInCart = checkoutCartStore.list.find(
    x => x.code_internal === item.code_internal
  );
  if (existsInCart) {
    const updatedProducts = checkoutCartStore.list.filter(
      x => x.code_internal !== item.code_internal
    );
    yield put(Creators.removeGroupFromCartSuccess(updatedProducts));
  }
}

function* updatePriceItem({ price_actual, code_internal }) {
  const { list } = yield select(state => state.checkout.cart);
  const newState = [...list];
  const result = newState.map(item => {
    if (item.code_internal === code_internal) {
      item.price = price_actual;
    }
    return item;
  });

  yield put(Creators.updatePriceSuccess(result));
}

// Individual exports for testing
export default function* productSaga() {
  yield all([
    takeLatest(Types.ADD_ITEM_TO_CART_REQUEST, addItemToCart),
    takeLatest(Types.REMOVE_ITEM_TO_CART_REQUEST, removeItemFromCart),
    takeLatest(Types.REMOVE_GROUP_TO_CART_REQUEST, removeGroupItensFromCart),
    takeLatest(Types.UPDATE_PRICE_REQUEST, updatePriceItem),
  ]);
}
