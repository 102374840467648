import React from 'react';
import { ContactContainer } from './styles';
import HeaderContact from 'components/Pages/Contact/HeaderContact';
import ContactForm from 'components/Pages/Contact/ContactForm';


const ContactPage = () => {

  return(
    <ContactContainer>
      <HeaderContact title="Contato" />
      <ContactForm />
    </ContactContainer>
  )
}

export default ContactPage;