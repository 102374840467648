import styled from 'styled-components';
import { Box, Button } from '@material-ui/core';
import appColors from 'utils/appColors';

export const CategoriesContainer = styled(Box)`
  && {
    margin-top: 10px;

    @media all and (max-width: 500px) {
      /*margin-top:80px;*/
      width: 100%;
      padding: 5px;
    }
    @media all and (min-width: 501px) and (max-width: 920px) {
      width: 96%;
    }
    @media all and (min-width: 921px) and (max-width: 1740px) {
      width: 90%;
    }
  }
`;

export const ColorButton = styled(Button)`
  && {
    display: flex;
    justify-content: space-between;
    flex: 1;
    min-width: 0px;
    border: 1px solid ${appColors.SECUNDARY_COLOR};
    color: ${appColors.SECUNDARY_COLOR};
    background-color: white;
    margin-bottom: 20px;
  }
`;

export const BoxImage = styled(Box)`
  && {
    height: 305px;
    width: 305px;
    margin-bottom: 10px;

    @media all and (max-width: 500px) {
      height: 400px;
      display: flex;
      justify-content: center;
      align-itens: center;
      flex-direction: column;
    }
  }
`;

const styles = {
  fontFilterButton: {
    fontSize: 12,
  },
  boxEmpty: {
    /*backgroundColor: appColors.LIGHT_COLOR,*/
    borderRadius: 5,
    marginTop: 20,
    marginBottom: 80,
    padding: 50,
  },
  purpleTitle: {
    color: appColors.SECUNDARY_COLOR,
    fontWeight: 'bold',
    fontSize: 28,
  },
};

export default styles;
