export const Types = {
  // Obtem tendencias
  GET_LIST_REQUEST: 'Trend/GET_LIST_REQUEST',
  GET_LIST_SUCCESS: 'Trend/GET_LIST_SUCCESS',
  GET_LIST_FAILURE: 'Trend/GET_LIST_FAILURE',
  

};

export const initialState = {
  // Lista de tendencias
  TrendList: [],
  TrendListLoading: false,
  TrendListError: null,
  TrendListTotal: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Lista de tendencias
    case Types.GET_LIST_REQUEST:
      return { ...state, TrendListLoading: true };
    case Types.GET_LIST_SUCCESS:
      return {
        ...state,
        TrendList: action.payload.data,
        TrendListLoading: false,
        TrendListError: null,
        TrendListTotal: action.payload.total,
      };
    case Types.GET_LIST_FAILURE:
      return {
        ...state,
        TrendListLoading: false,
        TrendListError: action.payload,
      };
    default:
      return state;
  }
};

export const Creators = {
  // Busca lista de tendencias
  getTrendListRequest: ({
    page,
    perPage,
    search,
    orderByColumn,
    orderByDirection,
  }) => ({
    type: Types.GET_LIST_REQUEST,
    payload: {
      page, perPage, search, orderByColumn, orderByDirection,
    },
  }),
  getTrendListSuccess: ({ data, total }) => ({
    type: Types.GET_LIST_SUCCESS,
    payload: { data, total },
  }),
  getTrendListFailure: error => ({
    type: Types.GET_LIST_FAILURE,
    payload: error,
  }),

};
