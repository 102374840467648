/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PageBase from 'components/PageBase';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as WalletCreators } from 'store/ducks/admin/wallet';
import transfer, {
  Creators as TransferCreators,
} from 'store/ducks/admin/wallet/transfer';
import { Creators as StoreCreators } from 'store/ducks/admin/stores';
import HeaderComponent from 'components/HeaderComponent';
import {
  Card,
  CardHeader,
  CardContent,
  Box,
  Grid,
  Modal,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Typography,
} from '@material-ui/core';
import CashTransferInfo from 'components/CashTransferPage/CashTransferInfo';
// import CashExpressWithdraw from 'components/CashExpressPage/CashExpressWithdraw';
import RegisterTransferForm from 'components/Pages/RegisterStore/RegisterTransferForm';

import styles from './styles';
import appColors from 'utils/appColors';
import { ModalContainer } from 'components/Modal/styles';

const InfoItem = ({ label, children }) => (
  <Grid item lg={4} sm={6} xs={12}>
    <span
      style={{
        color: appColors.COLOR_ICON,
        fontWeight: '500',
        marginRight: '5px',
      }}>
      {label}
    </span>
    {children}
  </Grid>
);
// CashTranferMenu
const CashTranferMenu = () => {
  const dispatch = useDispatch();

  const [modal, setModal] = React.useState(false);
  const [records, setRecords] = React.useState([]);

  const {
    admin: {
      wallet: { wallet, walletLoading, bank_reseller },

      store: { storeLoading },
      withdraw: { transferLoading },
    },
    client: { client },
  } = useSelector(state => state);

  React.useEffect(() => {
    if (Object.values(transfer).length) {
      setRecords([transfer]);
      setModal(true);
    }
  }, [transferLoading]);

  React.useEffect(() => {
    dispatch(WalletCreators.getWalletRequest());
    dispatch(WalletCreators.getBankReseller()); // GET BANK RESELLER
    dispatch(
      StoreCreators.getStoreRequest({
        id: client.store ? client.store.id : '',
      })
    );
  }, []);

  const onSubmit = payload => {
    dispatch(TransferCreators.getTRANSFERRequest(payload));
    console.log(payload);
    setRecords([payload]);
    setModal(true);
  };

  // const handleSubmitBankForm = bankData => {
  //   dispatch(WalletCreators.setWalletBankResellerRequest(bankData));
  // };

  return (
    <>
      <PageBase>
        <HeaderComponent title=" Realizar Transferência" />
        <CashTransferInfo wallet={wallet} walletLoading={walletLoading} />
        {bank_reseller?.id !== null && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            flexWrap="wrap"
            alignItems="stretch"
            marginLeft={-1}
            marginRight={-1}>
            <Box flexGrow={1} p={1}>
              <Card style={{ height: '100%' }}>
                <CardHeader title="Conta Bancária" />
                <CardContent style={{ lineHeight: 2.0, color: '#4e3e51' }}>
                  <Grid container spacing={2}>
                    <InfoItem label="Banco">
                      {`${bank_reseller?.bank.code} - ${bank_reseller?.bank.name}`}
                    </InfoItem>
                    <InfoItem label="Número da agência">
                      {bank_reseller?.agency}
                    </InfoItem>
                    <InfoItem label="Dígito da agência">
                      {bank_reseller?.agency_check}
                    </InfoItem>
                    <InfoItem label="Número da conta">
                      {bank_reseller?.account}
                    </InfoItem>
                    <InfoItem label="Dígito da conta">
                      {bank_reseller?.account_check}
                    </InfoItem>
                    <InfoItem label="Tipo de conta">
                      {bank_reseller?.type}
                    </InfoItem>
                    <InfoItem label="Titular da conta">
                      {bank_reseller?.account_holder}
                    </InfoItem>
                    <InfoItem label="Valor a ser Transferido">
                      {bank_reseller?.doc_type}
                    </InfoItem>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Box>
        )}{' '}
        {!storeLoading && !bank_reseller?.id && (
          <>
            <h4 style={{ color: appColors.COLOR_ICON, marginTop: '80px' }}>
              Informe a conta para qual deseja fazer a transfêrencia:{' '}
            </h4>
            <RegisterTransferForm
              onBack={() => alert('back')}
              onSubmit={onSubmit}
              initialValues={{}}
              isLoading={false}
            />
          </>
        )}
      </PageBase>
      <Modal open={modal} onClose={() => setModal(!modal)}>
        <ModalContainer style={{ justifyContent: 'center', margin: 500 }}>
          <Typography style={styles.titleInput}>
            Transferência feita para:
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Nome</TableCell>
                <TableCell align="center">Agência</TableCell>
                <TableCell align="center">N° da Conta</TableCell>
                <TableCell align="center">Tipo de conta</TableCell>
                <TableCell align="center">Valor</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {records.map(row => (
                <TableRow key={row.id}>
                  <TableCell align="center">{row.account_holder}</TableCell>
                  <TableCell align="center">{row.agency}</TableCell>
                  <TableCell align="center">{row.account}</TableCell>
                  <TableCell align="center">{row.type}</TableCell>
                  <TableCell align="center">{row.amount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default CashTranferMenu;
