import React from 'react';
import SnackbarProvider  from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from './MySnackbarContentWrapper';
import Fade from '@material-ui/core/Fade';


const SnackbarNotfication = ({ open, type, close, message }) => {
  

  return (
    <div>
      <SnackbarProvider 
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        TransitionComponent={Fade}
        open={open}
        autoHideDuration={5000}
        onClose={close}
      >
        <MySnackbarContentWrapper
          onClose={close}
          variant={type}
          message={message}
        />
      </SnackbarProvider >
    </div>
  );
}

export default SnackbarNotfication;