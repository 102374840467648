import { Button } from '@material-ui/core';
import React from 'react';
import appColors from 'utils/appColors';
import { withStyles } from '@material-ui/core/styles';
import { Container, Content, Titles, Buttons } from './styles';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import MotorcycleOutlinedIcon from '@material-ui/icons/MotorcycleOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import AddToHomeScreenOutlinedIcon from '@material-ui/icons/AddToHomeScreenOutlined';

// eslint-disable-next-line no-unused-vars
const ButtonApp = withStyles(theme => ({
  root: {
    color: appColors.PRIMARY_COLOR,
    borderRadius: '50%',
    padding: '20px',
    '&:hover': {
      backgroundColor: appColors.PRIMARY_COLOR,
      color: '#fff',
    },
  },
}))(Button);

const BannerHome = () => {
  return window.innerWidth > 500 ? (
    <Container>
      <Content>
        <h4>Economia e praticidade</h4>
        <Titles>
          <Buttons>
            <ButtonApp variant="outlined" color="primary">
              <LocalOfferOutlinedIcon />
            </ButtonApp>
            <strong>As melhores ofertas de sua cidade</strong>
          </Buttons>
          <Buttons>
            <ButtonApp variant="outlined" color="primary">
              <MotorcycleOutlinedIcon />
            </ButtonApp>
            <strong>Peça o que precisa do conforto de sua casa</strong>
          </Buttons>
          <Buttons>
            <ButtonApp variant="outlined" color="primary">
              <AttachMoneyOutlinedIcon />
            </ButtonApp>
            <strong>Aproveite as melhores promoções</strong>
          </Buttons>
        </Titles>
      </Content>
    </Container>
  ) : (
    <Container>
      <Content>
        <h2>Economia e praticidade</h2>
        <Titles>
          <Buttons>
            <ButtonApp variant="outlined" color="primary">
              <LocalOfferOutlinedIcon />
            </ButtonApp>
            <strong>As melhores ofertas de sua cidade</strong>
          </Buttons>
          <Buttons>
            <ButtonApp variant="outlined" color="primary">
              <MotorcycleOutlinedIcon />
            </ButtonApp>
            <strong>Peça o que precisa do conforto de sua casa</strong>
          </Buttons>
          <Buttons>
            <ButtonApp variant="outlined" color="primary">
              <AttachMoneyOutlinedIcon />
            </ButtonApp>
            <strong>Aproveite as melhores promoções</strong>
          </Buttons>
          <Buttons>
            <ButtonApp variant="outlined" color="primary">
              <AddToHomeScreenOutlinedIcon />
            </ButtonApp>
            <strong>Tecnologia de ponta</strong>
          </Buttons>
        </Titles>
      </Content>
    </Container>
  );
};

export default BannerHome;
