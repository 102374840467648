import React from 'react';
import { FormControl, Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import appColors from 'utils/appColors';

const FilterCheckboxGroup = props => {
  const {
    children,
    row,
    field: { value, name },
    form: { setFieldValue, handleSubmit },
  } = props;

  const handleBoxChange = valueReceived => {
    setFieldValue(name, valueReceived);
    setTimeout(handleSubmit, 0);
  };

  const style = row
    ? { display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }
    : null;

  return (
    <FormControl style={style} fullWidth>
      {React.Children.map(children, child =>
        React.cloneElement(child, {
          field: {
            selected: value === child.props.value,
            value: child.props.value,
            onChange: handleBoxChange,
            name,
          },
        })
      )}
    </FormControl>
  );
};

FilterCheckboxGroup.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  row: PropTypes.bool,
  field: PropTypes.oneOfType([PropTypes.object]).isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

FilterCheckboxGroup.defaultProps = {
  row: false,
};

const FilterCheckboxGroupItem = props => {
  const {
    value,
    label,
    field: { onChange, selected },
  } = props;

  const funcCheck = () => {
    onChange(value);
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      style={{ cursor: 'pointer', paddingTop: 20 }}>
      {selected || selected ? (
        <RadioButtonCheckedIcon
          onClick={funcCheck}
          style={{ color: appColors.COLOR_ICON }}
        />
      ) : (
        <RadioButtonUncheckedIcon
          onClick={funcCheck}
          style={{ color: appColors.COLOR_ICON }}
        />
      )}
      <Box style={{ marginLeft: 5 }}>
        <Typography>{label}</Typography>
      </Box>
    </Box>
  );
};

FilterCheckboxGroupItem.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
};

export { FilterCheckboxGroupItem, FilterCheckboxGroup };
