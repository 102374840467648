import React from 'react';
import PropTypes from 'prop-types';
import ActionFab from 'components/Actions/ActionFab';
import ActionMenu from 'components/Actions/ActionMenu';
import ActionMenuItem from 'components/Actions/ActionMenuItem';
import { useHistory } from 'react-router-dom';
import AlertDialog from 'components/AlertDialog';
import { useDispatch } from 'react-redux';
import { Creators as NotificationCreators } from 'store/ducks/app';
import api from 'services/api';

const OrderActions = ({ rowData, reloadData }) => {
  const [localState, setLocalState] = React.useState({
    anchorEl: null,
  });

  const [isOpenDialog, setIsOpenDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const dispatch = useDispatch();

  const onCloseDialog = () => {
    setIsOpenDialog(false);
  };

  const handleClose = () => {
    setLocalState({ anchorEl: null });
  };

  const onOpenDialog = () => {
    handleClose();
    setIsOpenDialog(true);
  };

  const history = useHistory();

  const handleClick = event => {
    setLocalState({ anchorEl: event.currentTarget });
  };

  const onDeleteSubmit = async () => {
    setLoading(true);
    try {
      const response = await api.post(
        `/v1/client/orders/cancel/void/${rowData.order_id}`
      );
      if (response.data.data.Error) {
        throw new Error('Error');
      }
      onCloseDialog();
      reloadData();
      dispatch(
        NotificationCreators.openNotification({
          message: 'Cancelamento confirmado!',
          type: 'success',
        })
      );
    } catch (error) {
      dispatch(
        NotificationCreators.openNotification({
          message: 'Não foi possível confirmar',
          type: 'error',
        })
      );
    }
  };

  return (
    <>
      <ActionFab icon="more_vert" onClick={handleClick} />
      <ActionMenu
        anchorEl={localState.anchorEl}
        onClose={handleClose}
        open={Boolean(localState.anchorEl)}>
        <ActionMenuItem
          onClick={() => {
            history.push({
              search: `?id_pedido=` + rowData.order_id,
            });
          }}>
          Visualizar
        </ActionMenuItem>
        {!rowData.status && (
          <ActionMenuItem onClick={onOpenDialog}>Confirmar</ActionMenuItem>
        )}
      </ActionMenu>
      <AlertDialog
        isOpen={isOpenDialog}
        handleClose={onCloseDialog}
        onConfirm={onDeleteSubmit}
        isLoading={loading}
        description="Deseja confirmar este cancelamento? esta ação não pode ser revertida."
      />
    </>
  );
};

OrderActions.propTypes = {
  rowData: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default OrderActions;
