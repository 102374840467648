import styled from 'styled-components';
import { Box } from '@material-ui/core';
import appColors from 'utils/appColors';

export const BoxInfoDetailProd = styled(Box)`
  && {
    max-width: 400px;

    @media all and (max-width: 850px) {
      max-width: 100%;
      padding: 0px 20px;
    }
  }
`;

const styles = {
  purpleTitle: {
    color: appColors.SECUNDARY_COLOR,
    fontSize: 36,
    fontWeight: 'bold',
    lineHeight: 1.3,
  },
  priceWhole: {
    marginBottom: 40,
  },
  subTitle: {
    color: '#555',
    fontSize: 16,
    margin: '10px 0px',
  },
  price: {
    color: appColors.PRIMARY_COLOR,
  },
  titleButton: {
    marginLeft: 10,
    fontSize: 16,
  },
  smallTextPurple: {
    color: appColors.SECUNDARY_COLOR,
    fontSize: 14,
    fontWeight: 'bold',
  },
  miniBox: {
    width: 90,
    border: `1px solid ${appColors.SECUNDARY_COLOR}`,
    textAlign: 'center',
    overflow: 'hidden',
    paddingTop: 2,
    paddingBottom: 2,
    marginRight: 10,
    marginTop: 10,
  },
  titleMiniBox: {
    fontSize: 12,
    color: '#373737',
  },
  unavailableProduct: {
    backgroundColor: '#343434',
    padding: 10,
    heigth: 100,
    borderRadius: 10,
  },
  whiteTitle: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 22,
  },

  price_through: {
    fontSize: 14,
    color: '#e74c3c',
    textDecoration: 'line-through',
  },
};

export default styles;
