import React from 'react';
import { Typography, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles, { ColorButton } from './styles';


const CustomButtonChecked = (props) => {
  const {
    value, label, onClick, isChecked, field: { checked, onChange }, form: { handleSubmit },
  } = props;

  const funcCheck = (e) => {
    if (typeof onClick === 'function') {
      onClick(e);
      setTimeout(handleSubmit, 0);
      return;
    }
    onChange({ currentTarget: { value, checked: !checked } });
    setTimeout(handleSubmit, 0);
  };

  return (
    <Box>
      <ColorButton
        checked={checked ? checked : isChecked}
        onClick={funcCheck}
      >
        <Typography style={styles.fontButton}>{label}</Typography>
      </ColorButton>
    </Box>
  );
};

CustomButtonChecked.propTypes = {
  label: PropTypes.string.isRequired,
};


export { CustomButtonChecked };
