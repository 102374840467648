import React from 'react';
import PropTypes from 'prop-types';
import { Typography, TextField } from '@material-ui/core';

const CustomRichText = ({ field, label, form }) => {
  const onChange = value => {
    // form.setFieldValue(field.name, value);
    form.setFieldValue(field.name, value.target.value);
  };
  return (
    <>
      <Typography
        variant="h6"
        style={{
          color: '#282829',
          marginTop: 10,
          marginBottom: 14,
          marginLeft: -1,
        }}>
        {label}
      </Typography>
      <TextField
        id="standard-multiline-static"
        label={label}
        multiline
        variant="outlined"
        value={field.value}
        onChange={onChange}
        rows={6}
        style={{ width: '100%' }}
      />
    </>
  );
};

CustomRichText.propTypes = {
  field: PropTypes.oneOfType([PropTypes.object]).isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  label: PropTypes.string.isRequired,
};

export default CustomRichText;
