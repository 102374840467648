const setKondutoCustomerId = (customerID) => {
    let period = 300;
    let limit = 20 * 1e3;
    let nTry = 0;
    let intervalID = setInterval(function() {
      let clear = limit/period <= ++nTry;
      if ((typeof(window.Konduto) !== "undefined") &&
      (typeof(window.Konduto.setCustomerID) !== "undefined")) {
        window.Konduto.setCustomerID(customerID);
        clear = true;
      }
      if (clear) {
        clearInterval(intervalID);
      }
    }, period);
}

const getKondutoCustomerId = () => {
  if ((typeof(window.Konduto) !== "undefined") &&
      (typeof(window.Konduto.getCustomerID) !== "undefined")) {
        return window.Konduto.getCustomerID();
  }

  return null;
}

const getKondutoVisitorId = () => {
  if ((typeof(window.Konduto) !== "undefined") &&
      (typeof(window.Konduto.getVisitorID) !== "undefined")) {
        return window.Konduto.getVisitorID();
  }

  return null;
}

export { setKondutoCustomerId, getKondutoCustomerId, getKondutoVisitorId };