import React from 'react';
import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import appColors from 'utils/appColors';

import { Text } from '@chakra-ui/react';

const StatisticItemNoIcon = ({
  classes,
  title,
  descriptionOne,
  descriptionTwo,
  client,
}) => (
  <>
    <Paper
      className={classes.statContainer}
      style={{ background: appColors.LIGHT_COLOR }}>
      <div className={classes.statContent} style={{ padding: '0px 15px' }}>
        <Grid
          justify="center"
          style={{ marginTop: 1, marginBottom: 1 }}
          container>
          <Grid item xs={12}>
            <div className={classes.statTitle}>
              <div>
                <Text
                  color={appColors.PRIMARY_COLOR}
                  fontSize="18px"
                  fontWeight="bold"
                  pb="10px"
                  textTransform="uppercase">
                  {title}
                </Text>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} style={{ marginTop: 1, marginBottom: 2 }}>
            {client.group.id === 3 && descriptionOne && (
              <div>
                <div>
                  <p style={{ color: '#606060' }}>
                    <b
                      style={{
                        fontWeight: 'bold',
                        color: appColors.SECUNDARY_COLOR,
                      }}>
                      PEDIDOS
                    </b>
                    <b
                      style={{
                        opacity: 0.7,
                        margin: -3,
                        fontWeight: '100',
                        marginLeft: 8,
                      }}>
                      HOJE
                    </b>
                  </p>
                </div>
                <b style={{ color: '#606060' }}>{descriptionOne} </b>
              </div>
            )}
            {client.group.id === 4 && descriptionOne && (
              <>
                <div>
                  <p style={{ color: '#606060' }}>
                    <b
                      style={{
                        fontWeight: 'bold',
                        color: appColors.SECUNDARY_COLOR,
                      }}>
                      VENDAS
                    </b>
                    <b
                      style={{
                        opacity: 0.7,
                        margin: -3,
                        fontWeight: '100',
                        marginLeft: 8,
                      }}>
                      HOJE
                    </b>
                  </p>
                </div>
                <b style={{ color: '#606060' }}>{descriptionOne} </b>
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={6} style={{ marginTop: 1, marginBottom: 1 }}>
            {client.group.id === 3 && descriptionTwo && (
              <>
                <div>
                  <p style={{ color: '#606060' }}>
                    <b
                      style={{
                        fontWeight: 'bold',
                        color: appColors.SECUNDARY_COLOR,
                      }}>
                      LANÇAMENTOS
                    </b>
                    <b
                      style={{
                        opacity: 0.7,
                        margin: -3,
                        fontWeight: '100',
                        marginLeft: 8,
                      }}>
                      HOJE
                    </b>
                  </p>
                </div>
                <b style={{ color: '#606060' }}>{descriptionTwo} </b>
              </>
            )}
            {client.group.id === 4 && descriptionTwo && (
              <>
                <div>
                  <p style={{ color: '#606060' }}>
                    <b
                      style={{
                        fontWeight: 'bold',
                        color: appColors.SECUNDARY_COLOR,
                      }}>
                      COMISSÕES
                    </b>
                    <b
                      style={{
                        opacity: 0.7,
                        margin: -3,
                        fontWeight: '100',
                        marginLeft: 8,
                      }}>
                      HOJE
                    </b>
                  </p>
                </div>
                <b style={{ color: '#606060' }}>{descriptionTwo} </b>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    </Paper>
  </>
);

StatisticItemNoIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default withStyles(theme => styles(theme))(StatisticItemNoIcon);
