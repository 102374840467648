/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
import React, { useCallback } from 'react';
import Card from './Card';
import update from 'immutability-helper';
import { Grid } from '@material-ui/core';

const Container = ({
  featured,
  setFieldValue,
  onDelete,
  images,
  changeImages,
  onEdit,
}) => {
  {
    const moveCard = useCallback(
      (dragIndex, hoverIndex) => {
        const dragCard = images[dragIndex];

        changeImages(
          update(images, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
            ],
          })
        );
      },
      [images]
    );
    const setFeature = image => {
      const index = images.findIndex(item => item.id === image.id);
      setFieldValue('featured_index', index);
      setFieldValue('featured', image.id);
    };

    const renderCard = (card, index) => {
      return (
        <Card
          key={card.id}
          index={index}
          id={card.id}
          image={card}
          onEdit={onEdit}
          moveCard={moveCard}
          featured={featured}
          setFieldValue={setFieldValue}
          onDelete={onDelete}
          setFeature={setFeature}
        />
      );
    };
    return (
      <>
        <Grid container spacing={1} style={{ marginBottom: 10 }}>
          {images.map((card, i) => renderCard(card, i))}
        </Grid>
      </>
    );
  }
};
export default Container;
