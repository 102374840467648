// import { typeCastExpression } from '@babel/types';

export const Types = {
  GET_REQUEST: 'toast/GET_REQUEST',
  GET_FAILURE: 'toast/GET_FAILURE',
};

const initialState = {
  type: null,
  title: null,
  description: null,
};

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case Types.GET_REQUEST:
      return {
        ...state,
        type: payload.type,
        title: payload.title,
        description: payload.description,
      };
    case Types.GET_FAILURE:
      return { ...state, type: null, title: null, description: null };
    default:
      return state;
  }
}

export const Creators = {
  getOpenToast: ({ type, title, description }) => ({
    type: Types.GET_REQUEST,
    payload: { type, title, description },
  }),
  getToastFailure: () => ({
    type: Types.GET_FAILURE,
    payload: null,
  }),
};
