import React from 'react';
import { Box, Collapse } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';
import ProductItem from './ProductItem';
import ResumeOrder from './ResumeOrder';
import StatusDataMobile from './StatusDataMobile';

const BodyItem = ({ data, isOpen }) => {
  return (
    <Collapse in={isOpen} timeout="auto" unmountOnExit>
      <Box
        display="flex"
        flexDirection="column"
        flex="1"
        style={styles.containerItem}>
        <StatusDataMobile data={data} />
        <ProductItem data={data} />
        <ResumeOrder data={data} />
      </Box>
    </Collapse>
  );
};

BodyItem.propTypes = {
  data: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default BodyItem;
