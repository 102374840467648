/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import DefaultTable from 'components/Tables/DefaultTable';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as ProductCreators } from 'store/ducks/admin/product';
import { Button, Paper } from '@material-ui/core';
import HeaderComponent from 'components/HeaderComponent';
import ProductTableHeader from 'components/Pages/ProductDetails/TableHeader';
import ProductActions from 'components/Pages/ProductDetails/Actions';
import ProductStatus from 'components/Pages/ProductDetails/Status';
import ProductFooter from 'components/Pages/ProductDetails/Footer';
import AlertDialog from 'components/AlertDialog';
import ProductImage from 'components/Pages/ProductDetails/Image';
import appUtils from 'utils/appUtils';

import { useMediaQuery, Stack } from '@chakra-ui/react';
import useProduct from 'hooks/products';

const CashBack = ({ item }) => {
  const { toggleCashback } = useProduct();
  const [enable, setEnable] = useState(item.enable_cashback);

  const toggleCashh = () => {
    setEnable(m => !m);
    toggleCashback({ enable_cashback: !enable, id: item.id });
  };

  return (
    <Button
      data-cy="toogle_cashback"
      style={{ color: enable ? 'red' : 'green' }}
      onClick={toggleCashh}>
      {enable ? 'Desativar' : 'Ativar'}
    </Button>
  );
};

const columns = ({ onDeleteRequest, handleTab }) => [
  {
    title: 'Referência',
    field: 'code_internal',
    sorting: false,
  },
  {
    title: 'Imagem',
    field: 'images',
    sorting: false,
    render: rowData => <ProductImage rowData={rowData} />,
  },
  {
    title: 'Nome',
    field: 'name',
    sorting: false,
  },
  {
    title: 'Dimensões',
    field: 'type',
    sorting: false,
    render: rowData => (
      <span>
        {rowData.width}x{rowData.height}x{rowData.length} cm
      </span>
    ),
  },
  {
    title: 'Preço',
    field: 'price',
    sorting: false,
    render: rowData => <span>{appUtils.formatPrice(rowData.price)}</span>,
  },
  {
    title: 'Loja',
    field: 'store.name',
    sorting: false,
  },
  {
    title: 'Status',
    sorting: false,
    render: rowData => <ProductStatus rowData={rowData} />,
  },
  {
    title: 'Cashback / Orçamentista',
    sorting: false,
    render: rowData => <CashBack item={rowData} />,
  },
  {
    title: 'Ações',
    render: rowData => (
      <ProductActions
        rowData={rowData}
        handleTab={handleTab}
        onDeleteRequest={onDeleteRequest}
      />
    ),
  },
];

const ProductListPage = ({ handleTab }) => {
  const dispatch = useDispatch();
  const [desktop] = useMediaQuery('(min-width: 475px)');

  const [localState, setLocalState] = React.useState({
    search: '',
    orderByColumn: '',
    orderByDirection: '',
    page: 1,
    perPage: 10,
  });

  const [deleteState, setDeleteState] = React.useState({
    open: false,
    item: {},
  });

  const {
    admin: {
      product: {
        productList,
        productListLoading,
        productListTotal,
        productDeleteLoading,
      },
    },
  } = useSelector(state => state);

  React.useEffect(() => {
    if (productDeleteLoading === false) {
      dispatch(ProductCreators.getProductListRequest(localState));
    }
  }, [productDeleteLoading]);

  const getFunction = data => {
    setLocalState(oldLocalState => ({ ...oldLocalState, ...data }));
  };

  React.useEffect(() => {
    dispatch(ProductCreators.getProductListRequest(localState));
  }, [localState]);

  const handleAlertDialogClose = () => {
    setDeleteState({ open: false, item: {} });
  };

  const onDeleteRequest = item => {
    setDeleteState({ open: true, item });
  };

  const onDeleteConfirm = () => {
    dispatch(ProductCreators.getProductDeleteRequest(deleteState.item.id));
  };

  React.useEffect(() => {
    if (productDeleteLoading === false && deleteState.open) {
      handleAlertDialogClose();
    }
  }, [productDeleteLoading]);

  return (
    <Stack>
      <HeaderComponent title="Meus Produtos"></HeaderComponent>
      <ProductTableHeader
        getFunction={getFunction}
        initialValues={{ search: localState.search }}
        handleTab={handleTab}
      />

      <Paper>
        <DefaultTable
          getFunction={getFunction}
          columns={columns({ onDeleteRequest, handleTab })}
          data={productList}
          total={productListTotal}
          isLoading={productListLoading}
          page={localState.page}
          perPage={localState.perPage}
          footer={desktop ? <ProductFooter /> : undefined}
        />
      </Paper>
      <AlertDialog
        isOpen={deleteState.open}
        isLoading={productDeleteLoading}
        handleClose={handleAlertDialogClose}
        onConfirm={onDeleteConfirm}
        title="Excluir registro?"
        description={`Remover produto: ${deleteState.item.name}`}
      />
    </Stack>
  );
};

export default ProductListPage;
