import { Paper } from '@material-ui/core';
import styled from 'styled-components';

export const ModalContainer = styled(Paper)`
  && {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    @media (max-width: 968px) {
      width: 70%;
    }
    @media (max-width: 768px) {
      width: 90%;
    }
    padding: 30px;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const LoadingContainer = styled.div`
  margin: 10%;
  display: flex;
  justify-content: center;
`;
