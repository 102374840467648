/* eslint-disable no-undef */
import Immutable from 'seamless-immutable';
import moment from 'moment';
import 'moment/locale/pt-br';

const toPrice = (val, adjustsNegative = false) => {
  const value = val ?? 0;

  // Converte o valor para número, formata com duas casas decimais e substitui '.' por ','
  return Number(
    adjustsNegative ? (value < 0 ? 0 : value) : value
  ).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
};

const toMutable = data => {
  return Immutable.asMutable(data, { deep: true });
};

const formatCityName = cityData => {
  return cityData.map(cityInfo => {
    return {
      id: cityInfo.id,
      name: `${cityInfo.name} - ${cityInfo.state.name}`,
    };
  });
};

const formatDate2 = date => {
  return moment.utc(date).format('DD/MM/YYYY');
};

const formatBankName = bankData => {
  return bankData.map(bankInfo => {
    return {
      id: bankInfo.id,
      name: `${bankInfo.code} - ${bankInfo.name}`,
    };
  });
};

const formatPaymentType = paymentType => {
  const paymentTypeInt = Number(paymentType);
  if (paymentTypeInt === 1) {
    return 'Cartão';
  }
  if (paymentTypeInt === 2) {
    return 'Boleto';
  }
  return '';
};

const formatStoresName = stores => {
  const storesNames = [];
  if (stores) {
    stores.forEach(store => {
      if (store.store) {
        storesNames.push(store.store.name);
      }
    });
    return storesNames.join(', \n');
  }
  return '';
};

const formatDate = date => {
  moment.locale('pt-br');
  return moment(date).format('DD/MM/YYYY HH:mm');
};

const formatStatus = status => {
  const validStatus = {
    0: 'Aguardando',
    1: 'Pagamento Autorizado',
    2: 'Faturado Parcialmente',
    3: 'Faturado',
    4: 'Em separação',
    5: 'Aguardando envio',
    6: 'Enviado',
    7: 'Entregue',
    8: 'Entregue Parcialmente',
    9: 'Aguardando Retirada',
    10: 'Devolvido',
    11: 'Devolvido Parcialmente',
    12: 'Aguardando Retirada Cliente',
    13: 'Disputado',
    14: 'Disputa Bem Sucedida',
    15: 'Charged Back',
    16: 'Pagamento na entrega',
    98: 'Pagamento Recusado',
    99: 'Cancelado',
  };
  return validStatus[status];
};
const formatStatusPayment = status => {
  const validStatus = {
    WAITING_APPROVAL: 'Aguardando aprovação',
    PENDING: 'Cobrança aguardando pagamento',
    CONFIRMED: 'Cobrança Confirmada',
    RECEIVED: 'Cobrança Recebida',
    RECEIVED_IN_CASH: 'Cobrança Recebida em Dinheiro (não gera saldo)',
    OVERDUE: 'Cobrança Atrasada',
    REFUND_REQUESTED: 'Estorno Solicitado',
    REFUNDED: 'Cobrança Estornada',
    CHARGEBACK_REQUESTED: 'Recebido chargeback',
    CHARGEBACK_DISPUTE:
      'Em disputa de chargeback (caso sejam apresentados documentos para contestação)',
    AWAITING_CHARGEBACK_REVERSAL:
      'Disputa vencida, aguardando repasse da adquirente',
    DUNNING_REQUESTED: 'Em processo de negativação',
    DUNNING_RECEIVED: 'Recuperada',
    AWAITING_RISK_ANALYSIS: 'Pagamento em análise',
    null: 'Não processado',
  };
  return validStatus[status];
};

const imageToBase64 = image => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(image);
    } catch (ex) {
      reject(ex);
    }
  });
};

export {
  toMutable,
  toPrice,
  formatCityName,
  formatBankName,
  formatPaymentType,
  formatStoresName,
  formatDate,
  formatStatus,
  formatStatusPayment,
  imageToBase64,
  formatDate2,
};
