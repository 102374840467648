import { Types as AuthTypes } from 'store/ducks/auth';

export const Types = {
  GET_INFO_PAGE_REQUEST: 'info/GET_INFO_PAGE_REQUEST',
  GET_INFO_PAGE_SUCCESS: 'info/ GET_INFO_PAGE_SUCCESS',
  GET_INFO_PAGE_FAILURE: 'info/ GET_INFO_PAGE_FAILURE',
};

export const initialState = {
  infoPage: [],
  infoPageLoading: false,
  infoPageError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_INFO_PAGE_REQUEST:
      return {
        ...state,
        infoPageError: null,
        infoPageLoading: true,
      };
    case Types.GET_INFO_PAGE_SUCCESS:
      return {
        ...state,
        infoPage: action.payload.data,
        infoPageLoading: false,
        infoPageError: null,
      };
    case Types.GET_INFO_PAGE_FAILURE:
      return {
        ...state,
        infoPageLoading: false,
        infoPageError: action.payload,
      };

    case AuthTypes.GET_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export const Creators = {
  getInfoPageRequest: route => ({
    type: Types.GET_INFO_PAGE_REQUEST,
    payload: { route },
  }),
  getInfoPageSuccess: ({ data }) => ({
    type: Types.GET_INFO_PAGE_SUCCESS,
    payload: { data },
  }),
  getInfoPageFailure: error => ({
    type: Types.GET_INFO_PAGE_FAILURE,
    payload: error,
  }),
};
