import React from 'react';
import { Box, Button, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { InputContainer, InputItem } from 'components/form/StyledComponents';
import styles, { CouponContainer, ContainerBottonCoupon } from './styles';
import CepMaskField from './CepMaskField';

const QuoteCepForm = ({ onSubmit, onSubmitQuote, quoteLoading }) => {
  const validateCEP = value => {
    let error;
    if (value.replace(/_/g, '').replace(/-/g, '').length < 8) {
      error = 'Complete o CEP';
    }
    return error;
  };

  return (
    <Box flex="1" display="flex">
      <Formik
        initialValues={{ code_post: '' }}
        onSubmit={onSubmit}
        validateOnBlur
        render={({ handleSubmit }) => (
          <form style={{ flex: '1', marginLeft: 15 }} onSubmit={handleSubmit}>
            <CouponContainer display="flex" flexWrap="wrap">
              <InputContainer style={{ marginTop: -10 }}>
                <InputItem style={{ margin: 0 }}>
                  <Field
                    name="code_post"
                    label="Digite o CEP para Frete"
                    mask="99999-999"
                    validate={validateCEP}
                    onSubmitCEP={onSubmitQuote}
                    component={CepMaskField}
                  />
                </InputItem>
              </InputContainer>
              <ContainerBottonCoupon flex="1" display="flex" justifyContent="flex-end">
                <Button data-cy="btn_calculate_price_delivery" type="submit" style={styles.purpleButton}>
                  Calcular
                  {quoteLoading
                    && <CircularProgress data-cy="loading_calculate_price_delivery" size={15} style={{ color: 'white', marginLeft: 10 }} />}
                </Button>
              </ContainerBottonCoupon>
            </CouponContainer>
          </form>
        )}
      />
    </Box>

  );
};

QuoteCepForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSubmitQuote: PropTypes.func.isRequired,
};

export default QuoteCepForm;
