import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
// import { ModalFinishWallet } from './ModalFinishDocuments';
// import appColors from 'utils/appColors';
import { Box, Button, Text } from '@chakra-ui/react';
import Icon from '@material-ui/core/Icon';
import appColors from 'utils/appColors';

const ModalContainer = styled(Paper)`
  && {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    @media (max-width: 968px) {
      width: 70%;
    }
    @media (max-width: 768px) {
      width: 90%;
    }
    padding: 30px;
  }
`;

const ModalHeader = styled(Box)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
    font-weight: bold;
    font-size: 20px;
  }
`;
const ModalBody = styled(Box)`
  && {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
  }
`;

const ModalFooter = styled(Box)`
  gap: 10px;
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const DocumentModal = ({
  handleClose,
  isOpen,
  onSubmit,
  document,
  // isFinished,
  // isOk,
  loading,
}) => {
  const [files, setFiles] = useState();
  return (
    <Modal close open={isOpen} onClose={handleClose}>
      <ModalContainer>
        <form
          onSubmit={async event => {
            await onSubmit(event, document, files);
          }}>
          <ModalHeader>
            <Text>Enviando: {document.title}</Text>
            {!loading ? (
              <Button onClick={handleClose} variant="ghost">
                <Icon style={{ marginRight: 5 }} fontSize="medium">
                  close
                </Icon>
              </Button>
            ) : null}
          </ModalHeader>

          <ModalBody>
            <input
              type="file"
              required
              disabled={
                document?.status === 'APPROVED' || loading ? true : false
              }
              style={{ maxWidth: '400px' }}
              onChange={e =>
                setFiles(m => ({
                  ...m,
                  [document.id]: {
                    document_file: e.target.files[0],
                  },
                }))
              }
            />
            <br></br>
            <Text>Obs: {document.description}</Text>
          </ModalBody>

          <ModalFooter>
            <Button
              style={{
                width: '50%',
                height: '40px',
                color: '#8B8E93',
                fontWeight: 400,
                fontSize: '16px',
              }}
              isDisabled={loading}
              onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              type="submit"
              style={{
                width: '50%',
                height: '40px',
                background: appColors.PRIMARY_COLOR,
                fontSize: '16px',
                color: 'white',
                fontWeight: 400,
              }}
              isLoading={loading}
              disabled={loading || document?.status === 'APPROVED'}
              _hover={{
                opacity: 0.6,
              }}
              transition="all 0.3s ease 0s">
              Enviar documento
            </Button>
            {/* <ModalFinishWallet
              buttonDisabled={document?.status === 'APPROVED' ? true : false}
              buttonText="Enviar documento"
              styleButton={{
                width: '100%',
                height: '40px',
                background: appColors.PRIMARY_COLOR,
                fontSize: '16px',
                color: 'white',
                fontWeight: 400,
              }}
              isFinished={isFinished}
              isOk={isOk}
              loading={loading}
              previousPagePath=""
              successMsg="Documento enviado com sucesso!"
              errorMsg="Ocorreu um erro"
            /> */}
          </ModalFooter>
        </form>
      </ModalContainer>
    </Modal>
  );
};

DocumentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default DocumentModal;
