import React from 'react';
import { useHistory } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const CategoriesSlideDesktop = ({ data, onClose }) => {
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleGoCategory = category => {
    history.push(category.route_web);
    onClose();
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="on"
        indicatorColor="primary"
        textColor="primary"
        aria-label="scrollable force tabs example">
        {data &&
          data.map((category, index) => (
            <Tab
              key={index}
              onClick={() => handleGoCategory(category)}
              label={category.name}
              icon={<img alt="" src={category.image.small} height={85} />}
            />
          ))}
      </Tabs>
    </>
  );
};

export default CategoriesSlideDesktop;
