/* eslint-disable react/display-name */
import React from 'react';
import DefaultTable from 'components/Tables/DefaultTable';
import HeaderComponent from 'components/HeaderComponent';
import DateRangeInput from 'components/DateRangeInput';
import { Paper, CircularProgress } from '@material-ui/core';
import { formatDate, toPrice } from 'utils/converters';
import moment from 'moment';
import appColors from 'utils/appColors';
import appUtils from 'utils/appUtils';
import { useWebhookEdit } from 'hooks/webhook';

import { Stack, Box, Center, Text } from '@chakra-ui/react';

const OrderListPage = () => {
  const columns = () => [
    {
      title: 'Data',
      field: 'date',
      render: rowData => <span>{formatDate(rowData.date)}</span>,
    },
    {
      title: 'Valor',
      field: 'amount',
      render: rowData => <span>R$ {toPrice(rowData.value)}</span>,
    },
    {
      title: 'Descrição',
      field: 'description',
      render: rowData => <span>{rowData.description}</span>,
    },
  ];

  const {
    getwebhookExtrato,
    webhookExtrato,
    webhookExtratoSummary,
    loadingExtrato,
    extratoPages,
  } = useWebhookEdit();
  const today = moment();
  const actualDate = moment().format('YYYY-MM-DD');
  const weekdate = today.add(-7, 'day');
  const weekdateFormat = weekdate.format('YYYY-MM-DD');
  const [deleteState, setDeleteState] = React.useState({
    open: false,
    item: {},
  });
  const [localState, setLocalState] = React.useState({
    dateStart: weekdateFormat,
    dateEnd: actualDate,
    perPage: 5,
    page: 1,
  });

  const getFunction = data => {
    setLocalState(oldLocalState => ({
      ...oldLocalState,
      ...data,
    }));
  };
  const onDeleteRequest = item => {
    console.log(deleteState);
    setDeleteState({ open: true, item });
  };
  React.useEffect(() => {
    console.log(localState);
    getwebhookExtrato(localState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState]);

  return (
    <Stack w="full">
      <HeaderComponent title="Extrato" />
      <DateRangeInput
        getFunction={getFunction}
        initialValues={{
          search: localState.search,
          perPage: localState.perPage,
          page: localState.page,
          dateStart: weekdateFormat,
          dateEnd: actualDate,
        }}
      />

      {webhookExtratoSummary ? (
        <Stack p="30px 0" direction="row" justify="space-between">
          <Box>
            <Text fontWeight="bold">CRÉDITO DO PERÍODO </Text>
            <Text fontSize={['30px', '45px']} fontWeight="bold" color="#d73d32">
              {appUtils.formatPrice(
                webhookExtratoSummary?.value_negative
                  ? webhookExtratoSummary?.value_negative
                  : 0
              )}
            </Text>
          </Box>
          <Box>
            <Text fontWeight="bold">DÉBITO DO PERÍODO </Text>
            <Text fontSize={['30px', '45px']} fontWeight="bold" color="#53a93f">
              {appUtils.formatPrice(
                webhookExtratoSummary?.value_positive
                  ? webhookExtratoSummary?.value_positive
                  : 0
              )}
            </Text>
          </Box>
        </Stack>
      ) : (
        <Center p="50px">
          <CircularProgress style={{ color: appColors.PRIMARY_COLOR }} />
        </Center>
      )}

      <Paper>
        <DefaultTable
          getFunction={getFunction}
          columns={columns({ onDeleteRequest })}
          data={webhookExtrato}
          total={extratoPages}
          isLoading={loadingExtrato}
          page={localState.page}
          perPage={localState.perPage}
        />
      </Paper>
    </Stack>
  );
};

export default OrderListPage;
