import styled from 'styled-components';
import { shade } from 'polished';
import { Box, AppBar, Tab } from '@material-ui/core';
import appColors from 'utils/appColors';

export const BarContainer = styled.div`
  flex: 1;
  display: flex;
`;

export const LogoWhite = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  padding-top: 5px;
  margin-right: 140px;
  @media screen and (min-width: 50px) and (max-width: 840px) {
    margin-right: 0px;
    padding-top: 4px;
  }
`;

export const SearchPanel = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  width: 100%;
  color: #ffff;
  background: ${appColors.SEARCHBAR_BACKGROUD};
  border-radius: 5px;
  padding: 5px 0px;
  transition: background 0.2s;

  &:hover {
    background: ${shade(0.1, appColors.SEARCHBAR_BACKGROUD)};
  }
  @media screen and (min-width: 50px) and (max-width: 840px) {
    display: none;
  }
`;

export const PanelButtonsDesktop = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  @media screen and (min-width: 50px) and (max-width: 840px) {
    display: none;
  }
`;

export const PanelButtonsMobile = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  @media screen and (min-width: 800px) {
    display: none;
  }
`;

export const ButtonIcon = styled.div`
  padding: 5px 6px;
  border-radius: ${props => (props.person ? '40px' : '5px')};
  background-color: ${appColors.PRIMARY_COLOR};
  display: flex;
  margin: 10px 2px;
`;

export const BoxContainer = styled(Box)`
  && {
    max-width: 1266px;
    display: flex;
    flex: 1;
    align-items: center;
    margin-right: auto;
    margin-left: auto;

    @media screen and (min-width: 501px) and (max-width: 840px) {
      max-width: 700px;
    }
    @media screen and (min-width: 841px) and (max-width: 1024px) {
      max-width: 930px;
    }

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: rgba(255, 0, 0, 0.8);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }
  }
`;

export const BoxMenuCategory = styled.div`
  && {
    position: absolute;
    min-height: 180px;
    width: 1266px;
    display: ${props => (props.isOpen ? '' : 'none')};
    flex: 1;
    background-color: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top: 1px solid #dedede;
    padding: 20px;
    align-self: center;
    margin-top: 61px;
    z-index: 999;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);

    @media screen and (max-width: 1280px) {
      margin-top: 61px;
      width: 60%;
    }
  }
`;

export const MenuContainer = styled(Box)`
  && {
    display: flex;
    align-items: center;
    width: 1266px;
    margin-right: auto;
    margin-left: auto;

    @media screen and (max-width: 1025px) {
      flex: 1;
      width: 100%;
    }
  }
`;

export const ImgLogo = styled.img`
  && {
    max-width: 230px;
    max-height: 50px;
    margin-right: 15px;

    @media screen and (max-width: 500px) {
      height: 35px;
    }
  }
`;

export const WhiteMenu = styled(AppBar)`
  && {
    z-index: 99;
    justify-content: center;
    margin-top: 11px;
    flex: 1;

    /* @media screen and (max-width: 1280px) {
      margin-top: 69px;
    }*/
    @media screen and (max-width: 840px) {
      display: none;
    }
  }
`;

export const CategoryMenu = styled(Box)`
  && {
    display: ${props => (props.isOpen ? '' : 'none')};
    z-index: 999;
    justifycontent: center;
    margin-top: 127px;
    width: 100%;
    flex: 1;

    @media screen and (max-width: 963px) {
      display: none;
      margin-top: 58px;
    }
  }
`;

export const TabStyle = styled(Tab)`
  && {
    opacity: 1;
    text-transform: none !important;
    font-size: 16px;
    position: relative;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 0px;

    /* @media screen and (max-width: 500px) {
      padding-top: 10px;
      padding-bottom: 10px;
      margin-left: 10px;
      margin-right: 10px;
    } */
  }
`;

const styles = {
  secondaryToolbar: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    minHeight: 50,
    color: '#333333',
    backgroundColor: appColors.TOPBAR_BACKGROUND,
  },
  fontMenuItems: {
    fontWeight: '500',
    textTransform: 'capitalize',
  },
  iconMenuItems: {
    marginRight: 10,
  },
  iconsPrimaryMenu: {
    fontSize: 25,
    color: appColors.COLOR_FONT_MENU,
  },
  iconPersonalMenu: {
    fontSize: 27,
    marginRight: 10,
    color: appColors.COLOR_FONT_MENU ?? 'white',
  },
  iconsPrimaryMenuMobi: {
    fontSize: 17,
    color: 'white',
    marginRight: 2,
  },
  contentIcon: {
    margin: 10,
    borderRadius: 5,
  },
  contentIconMobi: {
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 5,
    paddingRight: 10,
    paddingLeft: 10,
    backgroundColor: appColors.SECUNDARY_COLOR,
    borderRadius: 5,
  },
  contentIconRadius: {
    marginTop: 10,
    marginLeft: 10,
    height: '100%',
    width: 'auto',
    borderRadius: 10,
  },
  contentIconRadiusMobi: {
    marginTop: 5,
    marginLeft: 5,
    height: '100%',
    width: 'auto',
    backgroundColor: appColors.SECUNDARY_COLOR,
    borderRadius: '50%',
  },
};

export default styles;
