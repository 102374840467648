import styled from 'styled-components';

const FormContainer = styled.div`
  margin: 0;
`;

const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;

  /* & + & {
    margin-top: 15px;
  } */
`;

const InputItem = styled.div`
  flex-grow: ${props => props.flexGrow || 1};

  margin-right: 5px;

  @media all and (max-width: 500px) {
    margin-right: 10px;
  }

  margin-top: 15px !important;
`;

export { InputContainer, InputItem, FormContainer };
