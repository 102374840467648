/*
 *
 * Report reducer
 *
 */

export const Types = {
  // Obtem catergorias
  GET_COMMISSION_REQUEST: 'report/GET_COMMISSION_REQUEST',
  GET_COMMISSION_SUCCESS: 'report/GET_COMMISSION_SUCCESS',
  GET_COMMISSION_FAILURE: 'report/GET_COMMISSION_FAILURE',
};

export const initialState = {
  // Categoria por id
  reportCommission: {},
  reportCommissionLoading: false,
  reportCommissionError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Categoria por id
    case Types.GET_COMMISSION_REQUEST:
      return {
        ...state,
        reportCommission: {},
        reportCommissionError: null,
        reportCommissionLoading: true,
      };
    case Types.GET_COMMISSION_SUCCESS:
      return {
        ...state,
        reportCommission: action.payload.data,
        reportCommissionLoading: false,
        reportCommissionError: null,
      };
    case Types.GET_COMMISSION_FAILURE:
      return {
        ...state,
        reportCommissionLoading: false,
        reportCommissionError: action.payload,
      };
    default:
      return state;
  }
};

export const Creators = {
  // Busca uma categoria
  getReportCommissionRequest: ({
    dateStart,
    dateEnd,
    store_id,
    client_id,
    payment_type,
    status,
  }) => {
    return {
      type: Types.GET_COMMISSION_REQUEST,
      payload: {
        dateStart,
        dateEnd,
        store_id,
        client_id,
        payment_type,
        status,
      },
    };
  },
  getReportCommissionSuccess: ({ data }) => ({
    type: Types.GET_COMMISSION_SUCCESS,
    payload: { data },
  }),
  getReportCommissionFailure: error => ({
    type: Types.GET_COMMISSION_FAILURE,
    payload: error,
  }),
};
