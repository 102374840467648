/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-dupe-keys */
/* eslint-disable react/display-name */
import React from 'react';
import PageBase from 'components/PageBase';
import DefaultTable from 'components/Tables/DefaultTable';
import { useDispatch, useSelector } from 'react-redux';
import HeaderComponent from 'components/HeaderComponent';
import OrderTableHeader from 'components/OrderPage/OrderTableHeader';
import { Paper, Typography, Box } from '@material-ui/core';
import { formatDate } from 'utils/converters';
import moment from 'moment';
import appColors from 'utils/appColors';
import StatusOrderForm from 'components/OrderPage/OrderView/StatusOrderForm';
import RefreshOrder from 'components/RefreshOrder';

// WEB SOCKET NOTIFICATIONS
import StatusColorOrderForm from 'components/OrderPage/OrderView/StatusColorOrderForm';
import { Creators as NotificationCreators } from 'store/ducks/app';
import io from 'socket.io-client';
import NewOrderStatus from 'components/OrderPage/OrderView/StatusColorOrderForm/statusNewOrder';
import { Creators as OrderCreators } from 'store/ducks/admin/order';
import { baseSocket } from 'services/api';
import PrintLoad from 'components/Pages/PrintLoad';
import SoundComponent from 'components/Sound';
import ActionsCD from './components/ActionsCD';
const _ = require('lodash');

const OrderListPage = () => {
  const dispatch = useDispatch();

  const {
    orderList,
    orderListLoading,
    orderListTotal,
    orderDeleteLoading,
    orderSummary,
  } = useSelector(state => state.admin.order);

  const columns = () => [
    {
      title: 'ID',
      field: 'id',
      render: rowData => (
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <NewOrderStatus rowData={rowData} />
          {rowData.view ? (
            <a
              style={{
                fontWeight: 'bold',
                color: appColors.SECUNDARY_COLOR,
                cursor: 'pointer',
              }}
              href={`?id_pedido=${rowData.id}`}
              target="_blank"
              rel="noopener noreferrer" // Adicionando os atributos rel
            >
              {rowData.id}
            </a>
          ) : (
            <span
              style={{
                fontWeight: 'bold',
                color: appColors.SECUNDARY_COLOR,
                cursor: 'pointer',
              }}
              onClick={() => {
                window.open(`?id_pedido=${rowData.id}`, '_blank');
              }}>
              {rowData.id}
            </span>
          )}
        </div>
      ),
    },
    {
      title: 'Cód. Ext.',
      field: 'code_order',
      render: rowData => <p>{rowData.code_external}</p>,
    },
    {
      title: 'Cód. OS',
      field: 'delivery_os',
      render: rowData => <p>{rowData.delivery_os || '-'}</p>,
    },
    {
      title: 'Loja',
      field: 'store.name',
      render: rowData =>
        rowData.stores.map(item => (
          <>
            {item.store.name}
            <br />
          </>
        )),
    },
    {
      title: 'Status',
      field: 'status',
      render: rowData =>
        rowData.stores.map(item => (
          <>
            <StatusColorOrderForm
              item={item}
              delivery={
                rowData.original_delivery_name
                  ? rowData.original_delivery_name
                  : rowData.delivery_name
              }
            />
          </>
        )),
    },
    {
      title: 'Data',
      field: 'created_at',
      render: rowData => <span>{formatDate(rowData.created_at)}</span>,
    },
    {
      title: 'Nome do entregador',
      field: 'delivery_name',
      render: rowData => <p>{rowData.delivery_man.name}</p>,
    },
    {
      title: 'Colméia',
      field: 'hive',
      render: rowData =>
        rowData.stores[0].status != 6 && rowData.stores[0].status != 7 ? (
          <StatusOrderForm
            id={rowData.id}
            initialValues={{
              hive_number: rowData.hive_number,
            }}
            onSubmit={form => onSubmit(form, rowData.id)}
          />
        ) : null,
    },
    {
      title: 'Status',
      field: 'actions',
      render: rowData => <ActionsCD data={rowData} onSubmit={onSubmit} />,
    },
    {
      title: 'Atualizar',
      field: 'refresh',
      render: rowData => (
        <RefreshOrder id={rowData.id} stores={rowData.stores} />
      ),
    },
    {
      field: 'print',
      render: rowData => <PrintLoad id={rowData.id} />,
    },
  ];

  const { client } = useSelector(state => state.client);
  const SoundRef = React.useRef();
  const [deleteState, setDeleteState] = React.useState({
    open: false,
    item: {},
  });

  const today = moment();
  const actualDate = moment();
  const weekdate =
    client.group.id == 6 ? today.add(-1, 'day') : today.add(-7, 'day');

  const [localState, setLocalState] = React.useState({
    search: '',
    orderByColumn: '',
    orderByDirection: '',
    page: 1,
    perPage: 20,
    dateStart: weekdate.format('YYYY-MM-DD'),
    dateEnd: actualDate.format('YYYY-MM-DD'),
    statusNotIn: [98],
    order:
      'order=5&order=9&order=6&order=4&order=3&order=2&order=1&order=7&order=8&order=10&order=11&order=12&order=99&order=0',
  });

  const handleAlertDialogClose = () => {
    setDeleteState({ open: false, item: {} });
  };

  React.useEffect(() => {
    if (orderDeleteLoading == false && deleteState.open) {
      handleAlertDialogClose();
    }
  }, [orderDeleteLoading]);

  const getFunction = data => {
    setLocalState(oldLocalState => ({
      ...oldLocalState,
      ...data,
      search: data.search,
    }));
  };

  React.useEffect(() => {
    dispatch(OrderCreators.getOrderListRequest(localState));
  }, [localState]);

  const onDeleteRequest = item => {
    setDeleteState({ open: true, item });
  };
  const onSubmit = (form, id) => {
    SoundRef.current.stop();
    dispatch(
      OrderCreators.getUpdateStatusRequest({
        ...form,
        id: id,
      })
    );
  };

  React.useEffect(() => {
    const socket = io(baseSocket);
    socket.on('connect', () => {});
    socket.on(`user:shopper:new_order`, res => {
      dispatch(OrderCreators.getUpdateListSuccess({ ...res, view: true }));
      dispatch(
        NotificationCreators.openNotification({
          message: `Novo pedido recebido (id: ${res.id})`,
          type: 'info',
        })
      );
      // toca o som se o status for 5
      if (SoundRef.current && res.stores[0].status == 1) {
        SoundRef.current.play();
        setTimeout(() => SoundRef.current.stop(), 6000);
      }
    });
    // update socket
    socket.on(`user:shopper:order:changed`, res => {
      dispatch(OrderCreators.getOrderWSStatusUpdated(res));
      // toca o som se o status for 5
      if (SoundRef.current && res.stores[0].status == 5) {
        SoundRef.current.play();
        setTimeout(() => SoundRef.current.stop(), 6000);
      }
    });
  }, []);

  // ORDENAÇÃO DA LISTA SEM USAR O SET STATE
  const OrdersList = _.filter(orderList, prev => prev.status !== 98);

  return (
    <PageBase>
      <HeaderComponent
        title={client.group.id == 6 ? 'Pedidos' : 'Minhas Vendas'}
      />
      <OrderTableHeader
        getFunction={getFunction}
        initialValues={{
          search: localState.search,
          dateStart: weekdate,
          dateEnd: actualDate,
        }}
      />
      <Box style={{ marginTop: 15, marginBottom: 15 }}>
        <Typography
          style={{ fontWeight: 'bold', color: appColors.PRIMARY_COLOR }}>
          RELATÓRIO DA BUSCA
        </Typography>
      </Box>
      <Box
        flex="wrap"
        style={{
          display: 'flex',
          marginTop: 15,
          marginBottom: 15,
          flexDirection: 'row',
        }}>
        <Box style={{ marginRight: 200 }}>
          <Typography style={{ fontWeight: 'bolder' }}>PEDIDOS</Typography>
          <Typography
            style={{ fontWeight: 'bold', fontSize: 45, color: 'gray' }}>
            {orderSummary.total == undefined ? 0 : orderSummary.total}
            <span style={{ color: appColors.PRIMARY_COLOR }}></span>
          </Typography>
        </Box>
      </Box>
      <Paper>
        <DefaultTable
          getFunction={getFunction}
          columns={columns({ onDeleteRequest })}
          data={OrdersList}
          total={orderListTotal}
          isLoading={orderListLoading}
          page={localState.page}
          perPage={localState.perPage}
        />
      </Paper>
      <SoundComponent loop={false} ref={SoundRef} />
    </PageBase>
  );
};

export default OrderListPage;
