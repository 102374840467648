export const Types = {
  // Obtem bancos
  GET_LIST_REQUEST: 'bank/GET_LIST_REQUEST',
  GET_LIST_SUCCESS: 'bank/GET_LIST_SUCCESS',
  GET_LIST_FAILURE: 'bank/GET_LIST_FAILURE',
};

export const initialState = {
  // Lista de bancos
  bankList: [],
  bankListLoading: false,
  bankListError: null,
  bankListTotal: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Lista de bancos
    case Types.GET_LIST_REQUEST:
      return { ...state, bankListLoading: true };
    case Types.GET_LIST_SUCCESS:
      return {
        ...state,
        bankList: action.payload.data,
        bankListLoading: false,
        bankListError: null,
        bankListTotal: action.payload.total,
      };
    case Types.GET_LIST_FAILURE:
      return {
        ...state,
        bankListLoading: false,
        bankListError: action.payload,
      };
    default:
      return state;
  }
};

export const Creators = {
  // Busca lista de bancos
  getBankListRequest: ({
    page,
    perPage,
    search,
    orderByColumn,
    orderByDirection,
  }) => ({
    type: Types.GET_LIST_REQUEST,
    payload: {
      page,
      perPage,
      search,
      orderByColumn,
      orderByDirection,
    },
  }),
  getBankListSuccess: ({ data, total }) => ({
    type: Types.GET_LIST_SUCCESS,
    payload: { data, total },
  }),
  getBankListFailure: error => ({
    type: Types.GET_LIST_FAILURE,
    payload: error,
  }),
};
