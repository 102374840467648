import styled from 'styled-components';
import { Box } from '@material-ui/core';
import appColors from 'utils/appColors';

export const Container404 = styled(Box)`
  && {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 1266px;
    margin-bottom: 20px;
    height: 700px;

    @media all and (max-width: 1025px) {
      width: 100%;
      padding: 10px;
    }
  }
`;

const styles = {
  styleText: {
    color: appColors.PRIMARY_COLOR,
    fontWeight: 'bold',
    fontSize: 40,
    textAlign: 'center',
  },
};

export default styles;
