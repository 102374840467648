import { useState } from 'react';
import api from 'services/api';

function useAsaas() {
  const [loading, setLoading] = useState(false);
  const [fees, setFees] = useState({});

  const getFees = async () => {
    try {
      setLoading(true);
      const response = await api.get('/v1/admin/asaas/fees');
      if (response.data.status !== 'OK') {
        throw response.data;
      }
      setFees(response.data.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return {
    getFees,

    fees,
    loading,
  };
}

export default useAsaas;
