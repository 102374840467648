import React from 'react';
import { Box, Typography, Breadcrumbs } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from 'react-router-dom';
import styles from './styles';

const HeaderShoppingCart = () => (
  <React.Fragment>
    <Box display="flex" justifyContent="flex-start">
      <Typography style={styles.purpleTitle}>Orçamento</Typography>
    </Box>
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb">
      <Link style={styles.link} to="/">
        Início
      </Link>
      <Typography color="textPrimary">Orçamento</Typography>
    </Breadcrumbs>
  </React.Fragment>
);

export default HeaderShoppingCart;
