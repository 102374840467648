import React, { useEffect } from 'react';
import { Typography, Breadcrumbs } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link, useLocation } from 'react-router-dom';
import styles from './styles';
import { useDispatch } from 'react-redux';
import { Creators } from 'store/ducks/historypath';
import { Stack } from '@chakra-ui/react';

const HeaderProductDetails = ({ product }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(Creators.setHistoryPatch(location.pathname));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack display={['none', 'initial']}>
      <Stack>
        <Typography style={styles.purpleTitle}>Detalhes do produto</Typography>
      </Stack>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb">
        <Link style={styles.links} to="/">
          Início
        </Link>
        <Typography style={styles.primary} color="textPrimary">
          Detalhes
        </Typography>
        {!!product.store && (
          <Link
            style={styles.links}
            to={{ pathname: `/lojas/${product.store.slug}` }}>
            Ir para loja: <b>{product.store.name}</b>
          </Link>
        )}
      </Breadcrumbs>
    </Stack>
  );
};

export default HeaderProductDetails;
