import React from 'react';
import { FormControl, Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import appColors from 'utils/appColors';

const CustomCheckboxGroup = props => {
  const {
    children,
    row,
    field: { value, name },
    form: { setFieldValue, handleBlur },
  } = props;
  const handleBoxChange = event => {
    const target = event.currentTarget;
    let valueArray = [...value] || [];
    if (target.checked) {
      valueArray = [...valueArray, target.value];
    } else {
      valueArray = valueArray.filter(item => item !== target.value);
    }
    setFieldValue(name, valueArray);
  };

  const handleBoxBlur = () => {
    handleBlur(name, true);
  };
  const style = row
    ? { display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }
    : null;

  return (
    <FormControl style={style} fullWidth>
      {React.Children.map(children, child =>
        React.cloneElement(child, {
          field: {
            checked: value.includes(child.props.value),
            value: child.props.value,
            onChange: handleBoxChange,
            onBlur: handleBoxBlur,
            name,
          },
        })
      )}
    </FormControl>
  );
};

CustomCheckboxGroup.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  row: PropTypes.bool,
  field: PropTypes.oneOfType([PropTypes.object]).isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

CustomCheckboxGroup.defaultProps = {
  row: false,
};

const CustomCheckboxGroupItem = props => {
  const {
    value,
    label,
    onClick,
    isChecked,
    field: { checked, onChange },
    form: { handleSubmit },
  } = props;

  const funcCheck = (e, v) => {
    if (typeof onClick === 'function') {
      onClick(e);
      setTimeout(handleSubmit, 0);
      return;
    }
    onChange({ currentTarget: { value, checked: !checked } });
    setTimeout(handleSubmit, 0);
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      style={{ cursor: 'pointer', paddingTop: 20 }}>
      {checked || isChecked ? (
        <CheckBoxIcon
          onClick={funcCheck}
          style={{ color: appColors.COLOR_ICON }}
        />
      ) : (
        <CheckBoxOutlineBlankIcon
          onClick={funcCheck}
          style={{ color: appColors.COLOR_ICON }}
        />
      )}
      <Box style={{ marginLeft: 5 }}>
        <Typography>{label}</Typography>
      </Box>
    </Box>
  );
};

CustomCheckboxGroupItem.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
};

export { CustomCheckboxGroupItem, CustomCheckboxGroup };
