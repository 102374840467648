/* eslint-disable flowtype/no-types-missing-file-annotation */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Typography, CircularProgress } from '@material-ui/core';
import { Creators as OrderCreators } from 'store/ducks/order';
import { Creators as AddresCreators } from 'store/ducks/address';
import { useDispatch, useSelector } from 'react-redux';
import appUtils from 'utils/appUtils';
import CheckoutFooter from 'containers/Layout/CheckoutFooter';
import ItemsCard from 'components/Pages/FinalizationOrder/IntemsCard';
import appColors from 'utils/appColors';
import FinalizationForm from './FinalizationForm';
import styles, { CartContainer, ContainerForm } from './styles';
import checkoutUtils from 'utils/checkoutUtils';
import NewAddressModal from 'components/Pages/MyAccountPage/PainelAccount/NewAddressModal';
import { Creators as AddressCreators } from 'store/ducks/address';
import Radio from '@material-ui/core/Radio';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Creators as InstallmentCreators } from 'store/ducks/stores/installment';
import useQuery from 'hooks/useQuery';
import { Stack, Box } from '@chakra-ui/react';
import { Creators as NotificationCreators } from 'store/ducks/app';
import { useLocation } from 'react-router-dom';
import ServicesCard from 'components/Pages/FinalizationOrder/ServicesCard';

const FinalizationOrder = () => {
  const [filters] = React.useState({
    page: 1,
    perPage: 10,
    search: '',
    orderByColumn: '',
    orderByDirection: '',
  });

  const dispatch = useDispatch();
  const query = useQuery();

  const { cep, cepLoading, cepError } = useSelector(state => state.address);
  const { addressList } = useSelector(state => state.address);
  const { list: cartList } = useSelector(state => state.checkout.cart);

  const { addresses: sharedAddress } = useSelector(
    state => state.checkout.sharedAddress
  );

  const { quote, quoteLoading, quoteError, orderInsertLoading, quoteCodePost } =
    useSelector(state => state.order);
  quote['store_data'] = cartList;
  const { codePromo } = useSelector(state => state.app);
  const { data: cupomData } = useSelector(state => state.cupom);

  const installment = useSelector(state => state.stores.installment);
  const [quoteSelected, setQuoteSelected] = React.useState('');

  const [selectedCodePost, setSelectedCodePost] = React.useState(null);

  const handleChangeCodePost = event => {
    setSelectedCodePost(event.target.value);
    getQuotes(event.target.value);
  };

  const handleQuoteSelect = e => {
    if (e.target.name === 'quote_hash') {
      setQuoteSelected(e.target.value);
    }
  };

  const [localState, setLocalState] = React.useState({
    AddressModalOpen: false,
    EditAddressModalOpen: false,
  });

  const onSubmitCEP = values => {
    dispatch(AddressCreators.getAddressCEPRequest(values));
  };

  const openFormAddress = () => {
    setLocalState({ ...localState, AddressModalOpen: true });
  };

  const closeFormAddress = () => {
    dispatch(AddressCreators.getClearCepRequest());
    setLocalState({ ...localState, AddressModalOpen: false });
  };

  React.useEffect(() => {
    if (quote.length > 0) {
      setQuoteSelected(quote[0].quote_hash);
    }
  }, [quote]);

  React.useEffect(() => {
    dispatch(AddresCreators.getAddressListRequest(filters));
  }, []);

  React.useEffect(() => {
    if (quoteCodePost) {
      getQuotes(quoteCodePost);
      // se o CEP em cache já foi cadastrado, busca o valor do frete
      if (addressList.findIndex(a => a.code_post === quoteCodePost) >= 0)
        setSelectedCodePost(quoteCodePost);
    } else if (addressList.length > 0) {
      getQuotes(addressList[0].code_post);
      setSelectedCodePost(addressList[0].code_post);
    }
  }, [addressList]);

  const getQuotes = async code => {
    dispatch(OrderCreators.getQuoteRequest({ code_post: code }, cartList));
  };

  const quotePrice = quote.find(item => item.quote_hash === quoteSelected)
    ? Number(quote.find(item => item.quote_hash === quoteSelected).price)
    : 0;

  const quoteLocal = quote.find(item => item.quote_hash === quoteSelected)
    ? quote.find(item => item.quote_hash === quoteSelected).name ===
      'Retirar no Local'
      ? true
      : false
    : false;

  const proccessCart = checkoutUtils.proccessCart(cartList, cupomData);
  const getValuesOfAllProducts = proccessCart.getValuesOfAllProducts();

  const location = useLocation();
  const budgetHash = new URLSearchParams(location.search).get('budget_hash');

  const onSubmit = values => {
    values.products = cartList;

    let totalPrice = getValuesOfAllProducts.total + quotePrice;

    if (totalPrice < 5.0) {
      dispatch(
        NotificationCreators.openNotification({
          message:
            'Não foi possível efetuar a compra pois o valor minímo é de R$ 5,00',
          type: 'error',
        })
      );
    } else {
      const tempdate = values.payment.card.holder.birth_date;

      if (selectedCodePost === null) values.address = addressList[0];
      else {
        const indexAddress = addressList.findIndex(
          a => a.code_post === selectedCodePost
        );
        values.address = addressList[indexAddress];
      }

      if (codePromo !== null) {
        values.code_promo = codePromo;
      }

      if (values.payment.type === '1') {
        const formatDate =
          tempdate.substr(6, 4) +
          '-' +
          tempdate.substr(3, 2) +
          '-' +
          tempdate.substr(0, 2);
        values.payment.card.holder.birth_date = formatDate;
      }

      dispatch(
        OrderCreators.getOrderInsertRequest({
          ...values,
          code_partner: query.get('code_partner'),
          budget_hash: budgetHash,
          cash_back: {
            amount: 0,
          },
        })
      );

      values.payment.card.holder.birth_date = tempdate;
    }
  };

  const { manpower: manpowerList }: { list: ManpowerInCart[] } = useSelector(
    state => state.checkout.cart
  );

  React.useEffect(() => {
    let products = cartList.map(cartitem => cartitem.id);
    let stores = cartList.map(cartitem => cartitem.store.id);
    dispatch(
      InstallmentCreators.getMaxInstallmentRequest({ stores, products })
    );
  }, [dispatch]);

  const services = manpowerList;
  const totalServices =
    services?.reduce(
      (accumulator, service) => accumulator + service.price,
      0
    ) ?? 0;

  return (
    <Stack alignItems="center">
      {quote.filter(item => item.quote_hash === quoteSelected).length > 0 && (
        <Stack alignItems="center">
          <CartContainer flex="1" flexDirection="row" flexWrap="wrap">
            <ContainerForm>
              {quoteCodePost && selectedCodePost === null ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}>
                  <Typography
                    style={{
                      backgroundColor: appColors.PRIMARY_COLOR,
                      color: 'white',
                      flex: 1,
                      textTransform: 'uppercase',
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingLeft: 20,
                      fontWeight: 'bold',
                    }}>
                    ENDEREÇO DE ENTREGA
                  </Typography>

                  <h3
                    data-cy="text_fill_dados_delivery"
                    style={{
                      color: '#666',
                      fontSize: '20px',
                      textAlign: 'center',
                    }}>
                    Para continuar, preencha os dados de entrega.
                  </h3>
                  <button
                    data-cy="btn_clique_aqui"
                    onClick={openFormAddress}
                    style={{
                      alignSelf: 'center',
                      borderRadius: '8px',
                      padding: '4px 10px',
                      height: '30px',
                      border: 0,
                      backgroundColor: '#343434',
                      color: '#fff',
                      width: '150px',
                      fontSize: '18px',
                    }}>
                    Clique aqui
                  </button>
                </div>
              ) : (
                <FinalizationForm
                  address={addressList.find(
                    a => a.code_post === selectedCodePost
                  )}
                  onSubmit={onSubmit}
                  onChange={handleQuoteSelect}
                  maxInstallment={installment.max_installment_number || 1}
                  quote={quote}
                  orderInsertLoading={orderInsertLoading}
                  total={
                    getValuesOfAllProducts.total + quotePrice + totalServices
                  }
                  budgetHash={budgetHash}
                />
              )}
            </ContainerForm>
            <Box style={{ flex: 1.5 }}>
              <Typography
                style={{
                  backgroundColor: appColors.PRIMARY_COLOR,
                  color: 'white',
                  textTransform: 'uppercase',
                  paddingTop: 10,
                  paddingBottom: 10,
                  paddingLeft: 20,
                  fontWeight: 'bold',
                }}>
                Resumo do pedido
              </Typography>
              {quoteLocal ? (
                ''
              ) : (
                <Box
                  style={{
                    backgroundColor: '#e8e8e8',
                    padding: 20,
                    marginTop: 20,
                  }}>
                  <Typography style={styles.bigBoldTextBlack}>
                    Endereço de Entrega
                    <IconButton
                      style={{
                        float: 'right',
                        position: 'relative',
                        top: '-10px',
                      }}
                      onClick={openFormAddress}>
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </Typography>
                  {sharedAddress.length > 0 && (
                    <>
                      {sharedAddress.map((a, i) => (
                        <div
                          key={String(i)}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}>
                          <Radio
                            checked={selectedCodePost === a.code_post}
                            onChange={handleChangeCodePost}
                            value={a.code_post}
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': a.code_post }}
                          />
                          <div>
                            <Box
                              style={{
                                marginTop: 20,
                                marginBottom: 10,
                                borderTop: '1px solid #ccc',
                                paddingTop: '15px',
                              }}>
                              <Typography style={styles.mediumBoldTextBlack}>
                                {i === 0 ? 'PRINCIPAL' : `ADICIONAL ${i}`}
                              </Typography>
                            </Box>
                            <Typography>{`${a.street}, ${a.number}`}</Typography>
                            <Typography>
                              <span style={styles.mediumBoldTextBlack}>
                                Bairro:{' '}
                              </span>{' '}
                              {a.district}
                            </Typography>
                            <Typography>
                              <span style={styles.mediumBoldTextBlack}>
                                Cidade:{' '}
                              </span>{' '}
                              {a.city.name} - {a.city.state.uf}
                            </Typography>
                            <Typography>
                              <span style={styles.mediumBoldTextBlack}>
                                CEP:{' '}
                              </span>{' '}
                              {a.code_post}
                            </Typography>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                  {addressList.map((a, i) => (
                    <div
                      key={String(i)}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      <Radio
                        checked={selectedCodePost === a.code_post}
                        onChange={handleChangeCodePost}
                        value={a.code_post}
                        name="radio-button-demo"
                        inputProps={{ 'aria-label': a.code_post }}
                      />
                      <div>
                        <Box
                          style={{
                            marginTop: 20,
                            marginBottom: 10,
                            borderTop: '1px solid #ccc',
                            paddingTop: '15px',
                          }}>
                          <Typography style={styles.mediumBoldTextBlack}>
                            {i === 0 ? 'PRINCIPAL' : `ADICIONAL ${i}`}
                          </Typography>
                        </Box>
                        <Typography>{`${a.street}, ${a.number}`}</Typography>
                        <Typography>
                          <span style={styles.mediumBoldTextBlack}>
                            Bairro:{' '}
                          </span>{' '}
                          {a.district}
                        </Typography>
                        <Typography>
                          <span style={styles.mediumBoldTextBlack}>
                            Cidade:{' '}
                          </span>{' '}
                          {a.city.name} - {a.city.state.uf}
                        </Typography>
                        <Typography>
                          <span style={styles.mediumBoldTextBlack}>CEP: </span>{' '}
                          {a.code_post}
                        </Typography>
                      </div>
                    </div>
                  ))}
                  {quoteCodePost &&
                  addressList.findIndex(a => a.code_post === quoteCodePost) <
                    0 ? (
                    <>
                      <Box
                        style={{
                          marginTop: 20,
                          marginBottom: 10,
                          borderTop: '1px solid #ccc',
                          paddingTop: '15px',
                        }}>
                        <Typography style={styles.mediumBoldTextBlack}>
                          ADICIONAL
                        </Typography>
                      </Box>
                      <Typography>Rua: </Typography>
                      <Typography>
                        <span style={styles.mediumBoldTextBlack}>Bairro: </span>{' '}
                        <button onClick={openFormAddress}>Preencher</button>
                      </Typography>
                      <Typography>
                        <span style={styles.mediumBoldTextBlack}>Cidade: </span>{' '}
                      </Typography>
                      <Typography>
                        <span style={styles.mediumBoldTextBlack}>CEP: </span>{' '}
                        {quoteCodePost}
                      </Typography>
                    </>
                  ) : (
                    <Box
                      flex="1"
                      style={{
                        borderBottom: '1px solid #ccc',
                        marginBottom: 20,
                        marginTop: 10,
                      }}
                    />
                  )}
                </Box>
              )}
              {/* carrinho */}
              <ItemsCard proccessCart={proccessCart} />
              {manpowerList && manpowerList.length > 0 && (
                <ServicesCard services={manpowerList} />
              )}
              <>
                <Box
                  display="flex"
                  flex="1"
                  flexDirection="row"
                  justifyContent="space-between"
                  style={{
                    backgroundColor: '#e8e8e8',
                    padding: 20,
                    marginTop: 20,
                  }}>
                  <Box style={{ flexGrow: '4', width: 200 }}>
                    <Typography style={styles.smallTextBlackBold}>
                      Sub Total
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <Typography
                      data-cy="price_sub_total"
                      style={styles.smallTextBlackBold}>
                      {appUtils.formatPrice(getValuesOfAllProducts.subtotal)}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flex="1"
                  flexDirection="row"
                  justifyContent="space-between"
                  style={{
                    backgroundColor: '#e8e8e8',
                    padding: 20,
                    marginTop: 20,
                  }}>
                  <Box style={{ flexGrow: '4', width: 200 }}>
                    <Typography style={styles.smallTextBlackBold}>
                      {quoteLocal
                        ? 'Taxas de serviço'
                        : 'Taxas de serviços e Frete'}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <Typography
                      data-cy="price_service"
                      style={styles.smallTextBlackBold}>
                      {appUtils.formatPrice(quotePrice)}
                    </Typography>
                  </Box>
                </Box>
                {/* EXIBE VALOR DO DESCONTO CASO HAJA */}
                {getValuesOfAllProducts.descontos > 0 && (
                  <Box
                    display="flex"
                    flex="1"
                    flexDirection="row"
                    justifyContent="space-between"
                    style={{
                      backgroundColor: '#e8e8e8',
                      padding: 20,
                      marginTop: 20,
                    }}>
                    <Box style={{ flexGrow: '4', width: 200 }}>
                      <Typography style={styles.smallTextBlackBold}>
                        Descontos
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="center">
                      <Typography
                        data-cy="price_discount"
                        style={styles.smallTextBlackBold}>
                        {appUtils.formatPrice(getValuesOfAllProducts.descontos)}
                      </Typography>
                    </Box>
                  </Box>
                )}
                <Box
                  display="flex"
                  flex="1"
                  flexDirection="row"
                  justifyContent="space-between"
                  style={{
                    backgroundColor: '#e8e8e8',
                    padding: 20,
                    marginTop: 20,
                  }}>
                  <Box style={{ flexGrow: '4', width: 200 }}>
                    <Typography style={styles.smallTextBlackBold}>
                      TOTAL
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <Typography
                      data-cy="price_total"
                      style={styles.smallTextBlackBold}>
                      {appUtils.formatPrice(
                        getValuesOfAllProducts.total +
                          quotePrice +
                          totalServices
                      )}
                    </Typography>
                  </Box>
                </Box>
              </>
            </Box>
          </CartContainer>
          <CheckoutFooter />
        </Stack>
      )}
      {!quoteLoading && quoteError === 0 && (
        <CartContainer flex="1" flexDirection="row" flexWrap="wrap">
          <Typography>
            Desculpe, não temos disponibilidade de frete para o seu endereço.
          </Typography>
        </CartContainer>
      )}
      {quoteLoading && quote.length === 0 && (
        <CartContainer flex="1" flexDirection="row" flexWrap="wrap">
          <Box
            flex="1"
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
            style={{ height: 800 }}>
            <CircularProgress style={{ color: appColors.PRIMARY_COLOR }} />
          </Box>
        </CartContainer>
      )}

      <NewAddressModal
        closeModal={closeFormAddress}
        isOpen={localState.AddressModalOpen}
        address={cep}
        onSubmitCEP={onSubmitCEP}
        addressLoading={cepLoading}
        addressError={cepError}
        isNew={true}
      />
    </Stack>
  );
};

export default FinalizationOrder;
