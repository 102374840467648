import React from 'react';
import PropTypes from 'prop-types';
import { formatStatus } from 'utils/converters';
import { Box, Popover, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

const StatusColorOrderForm = ({ item = {}, delivery }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      style={{
        flexDirection: 'row',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}>
      {item.status === 0 && (
        <Box
          style={{
            width: 5,
            height: 18,
            backgroundColor: '#edff2b',
            marginRight: 10,
            borderRadius: 20,
          }}
        />
      )}
      {item.status === 1 && (
        <Box
          style={{
            width: 5,
            height: 18,
            backgroundColor: '#00dd24',
            marginRight: 10,
            borderRadius: 20,
          }}
        />
      )}
      {item.status === 2 && (
        <Box
          style={{
            width: 5,
            height: 18,
            backgroundColor: '#16a085',
            marginRight: 10,
            borderRadius: 20,
          }}
        />
      )}
      {item.status === 3 && (
        <Box
          style={{
            width: 5,
            height: 18,
            backgroundColor: '#27ae60',
            marginRight: 10,
            borderRadius: 20,
          }}
        />
      )}
      {item.status === 4 && (
        <Box
          style={{
            width: 5,
            height: 18,
            backgroundColor: '#e67e22',
            marginRight: 10,
            borderRadius: 20,
          }}
        />
      )}
      {item.status === 5 && (
        <Box
          style={{
            width: 5,
            height: 18,
            backgroundColor: '#2980b9',
            marginRight: 10,
            borderRadius: 20,
          }}
        />
      )}
      {item.status === 6 && (
        <Box
          style={{
            width: 5,
            height: 18,
            backgroundColor: '#9b59b6',
            marginRight: 10,
            borderRadius: 20,
          }}
        />
      )}
      {item.status === 7 && (
        <Box
          style={{
            width: 5,
            height: 18,
            backgroundColor: '#00dd24',
            marginRight: 10,
            borderRadius: 20,
          }}
        />
      )}
      {item.status === 8 && (
        <Box
          style={{
            width: 5,
            height: 18,
            backgroundColor: '#00dd24',
            marginRight: 10,
            borderRadius: 20,
          }}
        />
      )}
      {item.status === 9 && (
        <Box
          style={{
            width: 5,
            height: 18,
            backgroundColor: '#0984e3',
            marginRight: 10,
            borderRadius: 20,
          }}
        />
      )}
      {item.status > 16 && (
        <Box
          style={{
            width: 5,
            height: 18,
            backgroundColor: 'red',
            marginRight: 10,
            borderRadius: 20,
          }}
        />
      )}
      {item.status === 16 && (
        <Box
          style={{
            width: 5,
            height: 18,
            backgroundColor: '#ccc',
            marginRight: 10,
            borderRadius: 20,
          }}
        />
      )}
      {item.status === '' && (
        <Box
          style={{
            width: 5,
            height: 18,
            backgroundColor: '#e2bbed',
            marginRight: 10,
            borderRadius: 20,
          }}
        />
      )}
      <p
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        style={{
          marginRight: 10,
          color: '#999',
          fontWeight: 'bold',
          borderBottomWidth: 2,
          borderColor: '#999',
        }}>
        {delivery === 'Retirar no Local' ? '(R)' : '(D)'}
      </p>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus>
        <Typography>{delivery}</Typography>
      </Popover>
      {formatStatus(item.status)}
    </Box>
  );
};

StatusColorOrderForm.propTypes = {
  rowData: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default StatusColorOrderForm;
