/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as notifications } from 'react-notification-system-redux';
import productReducer from './product';
import authReducer from './auth';
import clientReducer from './client';
import address from './address';
import categoryCategory from './category';
import categoryList from './category/list';
import checkoutCart from './checkout/cart';
import sharedAddress from './checkout/sharedAddress';
import order from './order';
import app from './app';
import homeReducer from './home';
import configReducer from './config';
import storesInsert from './stores/insert';
import storesList from './stores/list';
import storesByCode from './stores/byCode';
import productsByStore from './stores/producstByStore';
import trendList from './trend/list';
import trendProduct from './trend/product';
import trend from './trend';
import infoPage from './infoPage';
import favoriteList from './favorite/list';
import favoriteInsert from './favorite/insert';
import passwordRecovery from './account/passwordRecovery';
import validateHash from './account/validate';
import validateAccount from './account/validateAccount';
import parameters from './parameters';
import productsBySection from './product/productsBySection';
import categoryAdmin from './admin/category';
import productColor from './admin/productColor';
import productSize from './admin/productSize';
import storeAdmin from './admin/stores';
import storeClosed from './admin/closed';
import productAdmin from './admin/product';
import productStock from './admin/product/productStockUpdate';
import orderAdmin from './admin/order';
import virtualCatalog from './admin/virtualCatalog';
import wallet from './admin/wallet';
import withdraw from './admin/wallet/withdraw';
import panelTabs from './panelTabs';
import bankAdmin from './admin/bank';
import user from './admin/user';
import addressAdmin from './admin/address';
import group from './admin/group';
import bank from './bank/list';
import favoriteReduced from './favorite/reduced';
import dashboardAdmin from './admin/dashboard';
import reseller from './reseller';
import reportReducer from './admin/report';
import reportCommissionReducer from './admin/reportCommission';
import reportStoreReducer from './admin/reportStore';
import cupomReducer from './cupom';
import couponStoreReducer from './admin/coupon';
import historyPatch from './historypath';
import installment from './stores/installment';
import collaborator from './collaborator';
import partner from './partner';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default history =>
  combineReducers({
    router: connectRouter(history),
    bank,
    cupom: cupomReducer,
    notifications,
    auth: authReducer,
    product: combineReducers({
      product: productReducer,
      productsBySection,
    }),
    reseller,
    client: clientReducer,
    parameters,
    address,
    order,
    history: historyPatch,
    app,
    infoPage,
    category: combineReducers({
      list: categoryList,
      category: categoryCategory,
    }),
    checkout: combineReducers({
      cart: checkoutCart,
      sharedAddress,
    }),
    home: homeReducer,
    config: configReducer,
    admin: combineReducers({
      category: categoryAdmin,
      productStock,
      product: productAdmin,
      productColor,
      productSize,
      order: orderAdmin,
      virtualCatalog,
      store: storeAdmin,
      closed: storeClosed,
      wallet,
      withdraw,
      bank: bankAdmin,
      user,
      address: addressAdmin,
      group,
      dashboard: dashboardAdmin,
      report: reportReducer,
      reportCommission: reportCommissionReducer,
      reportStore: reportStoreReducer,
      coupon: couponStoreReducer,
    }),
    stores: combineReducers({
      insert: storesInsert,
      list: storesList,
      byCode: storesByCode,
      productsByStore,
      installment,
    }),
    trend: combineReducers({
      trend,
      list: trendList,
      product: trendProduct,
    }),
    favorite: combineReducers({
      list: favoriteList,
      insert: favoriteInsert,
      reduced: favoriteReduced,
    }),
    account: combineReducers({
      passwordRecovery,
      validateHash,
      validateAccount,
    }),
    panelTabs,
    collaborator,
    partner,
  });
