import styled from 'styled-components';
import { Box } from '@material-ui/core';
import appColors from 'utils/appColors';

export const FinalContainer = styled(Box)`
  && {
    width: 400px;
    margin-left: 40px;

    @media all and (max-width: 500px) {
      max-width: 100%;
      min-width: 200px;
      margin-left: 0px;
      flex: 1;
      margin-bottom: 10px;
    }
    @media all and (min-width: 501px) and (max-width: 920px) {
      max-width: 100%;
      min-width: 400px;
      margin-left: 0px;
      flex: 1;
    }
    @media all and (min-width: 921px) and (max-width: 1024px) {
      max-width: 100%;
      min-width: 500px;
      margin-left: 0px;
      flex: 1;
    }
    @media all and (min-width: 1025px) and (max-width: 1250px) {
      max-width: 100%;
      min-width: 700px;
      margin-left: 0px;
      flex: 1;
    }
  }
`;

const styles = {
  purpleTitle: {
    color: appColors.SECUNDARY_COLOR,
    fontWeight: 'bold',
    fontSize: 32,
  },

  resumeTitleBoxCart: {
    textAlign: 'left',
    fontSize: 20,
    fontWeight: 'bold',
  },

  iconsAddRemove: {
    marginLeft: 10,
    marginRight: 10,
    color: appColors.SECUNDARY_COLOR,
    cursor: 'pointer',
  },
  titleProduct: {
    color: appColors.SECUNDARY_COLOR,
    fontWeight: 'bold',
    textAlign: 'left',
  },
  fontshipping: {
    color: appColors.SECUNDARY_COLOR,
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  resumeText: {
    borderBottom: '2px solid #FA8F47',
    fontWeight: 'bold',
  },
  totalText: {
    borderBottom: '2px solid #FA8F47',
    fontWeight: 'bold',
    marginLeft: 10,
    marginBottom: 20,
  },
  smallTextBlackBold: {
    color: '#373737',
    fontWeight: 'bold',
  },
  titleDescriptionBoldDiscount: {
    padding: 10,
    paddingBottom: 0,
    color: appColors.PRIMARY_COLOR,
  },
  titleDescriptionBold: {
    padding: 10,
    paddingBottom: 0,
    color: '#555',
  },
  smallTextBlack: {
    color: '#373737',
    textAlign: 'left',
  },
  bigBoldTextBlack: {
    color: '#373737',
    fontWeight: 'bold',
    fontSize: 22,
  },
  descriptionDiscount: {
    color: appColors.PRIMARY_COLOR,
    fontWeight: 'bold',
    fontSize: 22,
  },
  purpleButton: {
    backgroundColor: appColors.PRIMARY_COLOR,
    color: 'white',
    padding: '10px 20px',
  },
  quoteContainer: {
    flex: 1,
    border: `1px solid ${appColors.PRIMARY_COLOR}`,
    padding: 10,
    marginTop: 20,
    minWidth: 200,
  },
  purpleTextBold: {
    color: appColors.SECUNDARY_COLOR,
    fontWeight: 'bold',
  },
};

export default styles;
