import React from 'react';
import HeaderButiri from './components/buriti';
import HeaderOferta from './components/oferta';
import appTheme from 'utils/appTheme';
import HeaderSimer from './components/simerpay';

const headers = {
  Buriti: HeaderButiri,
  Oferta: HeaderOferta,
  SimerPay: HeaderSimer,
};

export default function HeaderScreen(props) {
  const Header = headers[appTheme.name] || HeaderOferta;
  return <Header {...props} />;
}
