/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PageBase from 'components/PageBase';
import DefaultTable from 'components/Tables/DefaultTable';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from '@material-ui/core';
import HeaderComponent from 'components/HeaderComponent';
import CollaboratorDeletedActions from 'components/Pages/PartnerPage/CollaboratorDeletedActions';
import AlertDialog from 'components/AlertDialog';
import CollaboratorDeletedTableHeader from 'components/Pages/PartnerPage/CollaboratorDeletedTableHeader';
import { Creators as ClienteCreators } from 'store/ducks/client';

const columns = ({ onDeleteRequest }) => [
  {
    title: 'Nome',
    field: 'name',
    sorting: true,
  },

  {
    title: 'E-mail',
    field: 'email',
    sorting: false,
  },
  {
    title: 'Telefone',
    field: 'cell_phone',
    sorting: false,
  },
  {
    title: 'Ações',
    // eslint-disable-next-line react/display-name
    render: rowData => (
      <CollaboratorDeletedActions
        rowData={rowData}
        onDeleteRequest={onDeleteRequest}
      />
    ),
  },
];

const CollaboratorDeletedListPage = () => {
  const dispatch = useDispatch();
  const [deleteState, setDeleteState] = React.useState({
    open: false,
    item: {},
  });

  const [localState, setLocalState] = React.useState({
    search: '',
    orderByColumn: '',
    orderByDirection: '',
    page: 1,
    perPage: 10,
  });

  const {
    clientListDeletedALL,
    clientListDeletedALLLoading,
    clientRestoreALLLoading,
    clientListDeletedALLTotal,
  } = useSelector(state => state.client);
  React.useEffect(() => {
    dispatch(ClienteCreators.getCleinteDeletedListRequest(localState));
  }, [dispatch, localState]);

  const handleAlertDialogClose = () => {
    setDeleteState({ open: false, item: {} });
  };

  React.useEffect(() => {
    if (clientRestoreALLLoading === false && deleteState.open) {
      handleAlertDialogClose();
    }
  }, [clientRestoreALLLoading]);

  const getFunction = data => {
    setLocalState(oldLocalState => ({ ...oldLocalState, ...data }));
  };

  const onDeleteRequest = item => {
    setDeleteState({ open: true, item });
  };
  const onRestoreConfirm = () => {
    dispatch(ClienteCreators.getClientRestoreALLRequest(deleteState.item.id));
  };

  return (
    <PageBase>
      <HeaderComponent title="Listar Clientes Deletados">
        <CollaboratorDeletedTableHeader
          getFunction={getFunction}
          initialValues={{ search: localState.search }}
        />
      </HeaderComponent>
      <Paper>
        <DefaultTable
          getFunction={getFunction}
          columns={columns({ onDeleteRequest })}
          data={clientListDeletedALL}
          total={clientListDeletedALLTotal}
          isLoading={clientListDeletedALLLoading}
          page={localState.page}
          perPage={localState.perPage}
        />
      </Paper>
      <AlertDialog
        isOpen={deleteState.open}
        isLoading={clientRestoreALLLoading}
        handleClose={handleAlertDialogClose}
        onConfirm={onRestoreConfirm}
        title="Reabilitar Colaborador?"
        description={`Reabilitar colaborador: ${deleteState.item.name}`}
      />
    </PageBase>
  );
};

export default CollaboratorDeletedListPage;
