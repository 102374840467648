import React from 'react';
import orderStatus, { StatusArrayQuote } from 'utils/order/status';
import { Typography, Box, CircularProgress } from '@material-ui/core';
import appUtils from 'utils/appUtils';
import { useSelector } from 'react-redux';
import { Formik, FastField } from 'formik';
import { InputItem } from 'components/form/StyledComponents';
import CustomSelect from 'components/form/CustomSelect';
import CustomButton from 'components/form/CustomButton';
import appColors from 'utils/appColors';
import styles, { OnlyModile } from './styles';

const StatusDataMobile = ({ data, onSubmit }) => {
  const {
    client: { client },
    admin: {
      order: { orderStatusUpdateLoading },
    },
  } = useSelector(state => state);

  return (
    <OnlyModile display="flex" flexDirection="column">
      <Box display="flex" alignItems="center">
        <Typography style={styles.headerPurple}>Status:</Typography>
        {client.group.id !== 3 && client.group.id !== 7 && (
          <Box style={styles.containerStatus}>
            <Typography style={styles.status}>
              {orderStatus(data.status)}
            </Typography>
          </Box>
        )}
        {(client.group.id === 3 || client.group.id === 7) && (
          <Formik
            initialValues={{ status: Number(data.status) }}
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <Box display="flex" flexDirection="column">
                <Box alignItems="center">
                  <InputItem style={{ margin: '20px 0px 0px 0px' }}>
                    {data.status < 4 && (
                      <Box style={styles.containerStatus}>
                        <Typography style={styles.status}>
                          {orderStatus(data.status)}
                        </Typography>
                      </Box>
                    )}
                    {data.status > 4 && (
                      <FastField
                        name="status"
                        label="Status"
                        options={StatusArrayQuote}
                        component={CustomSelect}
                        placeholder="Status"
                        isLoading={false}
                      />
                    )}
                  </InputItem>
                </Box>
                {data.status > 4 && (
                  <Box
                    flex="1"
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end">
                    <CustomButton
                      label="Alterar"
                      style={styles.purpleButton}
                      onClick={handleSubmit}
                    />
                    {orderStatusUpdateLoading && (
                      <CircularProgress
                        style={{ color: appColors.PRIMARY_COLOR, margin: 10 }}
                        size={20}
                      />
                    )}
                  </Box>
                )}
              </Box>
            )}
          />
        )}
      </Box>
      <Box display="flex" flexDirection="row" style={{ marginTop: 5 }}>
        <Typography style={styles.headerPurple}>Pedido:</Typography>
        <Typography style={styles.fontBold}>{data.id}</Typography>
      </Box>
      <Box display="flex" flexDirection="row" style={{ marginTop: 5 }}>
        <Typography style={styles.headerPurple}>Valor Total:</Typography>
        <Typography style={styles.fontBold}>
          {appUtils.formatPrice(data.price + data.delivery_price)}
        </Typography>
      </Box>
    </OnlyModile>
  );
};

export default StatusDataMobile;
