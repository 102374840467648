import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { Stack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as AddressCreators } from 'store/ducks/address';
import useQuery from 'hooks/useQuery';
import appColors from 'utils/appColors';
import styles, { RegisterClientContainer } from './styles';
import RegisterCollaboratorForm from './registerClientByUserForm';
import CustomStepper from 'components/Stepper2';
import RegisterSucess from 'components/Pages/RegisterReseller/RegisterSucess';
import HeaderPages from 'components/HeaderPages';
import Page404 from 'containers/Pages/404Page';
import { Creators as ClienteCreators } from 'store/ducks/client';

const RegisterClientePage = () => {
  const dispatch = useDispatch();
  const query = useQuery();

  const { clientById, clientByIdError, clientByIdLoading } = useSelector(
    state => state.client
  );

  const access_token = query.get('user_id');
  const [localState, setLocalState] = React.useState({
    activeStep: 0,
    collaboratorForm: {
      cell_phone: '',
      name: '',
      birth_date: '',
      genre: '',
      cpf: '',
      email: '',
      password: '',
      confirmPassword: '',
      address: {
        code_post: '',
        street: '',
        number: '',
        district: '',
        complement: '',
        city: {
          id: '',
        },
        state: {
          id: '',
        },
      },
    },
  });

  let { activeStep, collaboratorForm } = localState;

  const getStepContent = [
    'Dados do Colaborador(a)',
    'Cadastro Realizado com Sucesso!',
  ];

  const handleBack = (value = 0) => {
    window.scrollTo(0, 0);
    setLocalState({ ...localState, activeStep: value });
  };

  const onSubmitCEP = values => {
    dispatch(AddressCreators.getAddressCEPRequest(values));
  };

  const onSubmitCollaborator = values => {
    values.birth_date = values.birth_date.split('');
    values.birth_date = values.birth_date.join('');
    const dateInverse = values.birth_date.split('/');
    values.birth_date = `${dateInverse[2]}-${dateInverse[1]}-${dateInverse[0]}`;
    console.log('values' + JSON.stringify(values));
    window.scrollTo(0, 0);
    dispatch(
      ClienteCreators.getClientALLUpdateRequest({
        ...values,
        id: access_token,
      })
    );

    setLocalState({ ...localState, collaborator: values, activeStep: 1 });
  };

  const onSubmitRegister = () => {
    window.scrollTo(0, 0);
    setLocalState({ ...localState, activeStep: 2 });
  };

  if (!access_token) {
    return (
      <RegisterClientContainer>
        <Page404 />
      </RegisterClientContainer>
    );
  }

  return (
    <Stack alignItems="center" py="100px">
      <HeaderPages
        title="Quero me tornar um Colaborador(a)"
        currentPage="Cadastro de Colaborador"
      />

      <Stack w={['90%', '80%']}>
        <Box
          style={{
            borderBottom: `1px solid ${appColors.PRIMARY_COLOR}`,
            marginBottom: 20,
          }}>
          <CustomStepper steps={getStepContent} activeStep={activeStep} />
        </Box>
        {activeStep === 0 && (
          <>
            <Typography style={styles.purpleTitle}>
              Dados do Responsável
            </Typography>
            <RegisterCollaboratorForm
              onSubmit={onSubmitCollaborator}
              onSubmitCEP={onSubmitCEP}
              initialValues={collaboratorForm}
              access_token={access_token}
              collaborator={clientById}
              collaboratorError={clientByIdError}
              collaboratorLoading={clientByIdLoading}
            />
          </>
        )}
        {activeStep === 1 && (
          <>
            <Typography style={styles.purpleTitle}>
              Finalização de Cadastro
            </Typography>
            <RegisterSucess onBack={handleBack} onSubmit={onSubmitRegister} />
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default RegisterClientePage;
