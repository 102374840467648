/*
 *
 * Trends reducer
 *
 */

export const Types = {
  // Obtem tendencias por id
  GET_REQUEST: 'trend/GET_REQUEST',
  GET_SUCCESS: 'trend/GET_SUCCESS',
  GET_FAILURE: 'trend/GET_FAILURE',
};

export const initialState = {
  // tendencias por id
  trend: {},
  trendLoading: false,
  trendError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // tendencias por id
    case Types.GET_REQUEST:
      return {
        ...state,
        trend: {},
        trendError: null,
        trendLoading: true,
      };
    case Types.GET_SUCCESS:
      return {
        ...state,
        trend: action.payload.data,
        trendLoading: false,
        trendError: null,
      };
    case Types.GET_FAILURE:
      return {
        ...state,
        trendLoading: false,
        trendError: action.payload,
      };
    default:
      return state;
  }
};

export const Creators = {
  // Busca uma tendencias
  getTrendRequest: (id) => ({
    type: Types.GET_REQUEST,
    payload: { id },
  }),
  getTrendSuccess: ({ data }) => ({
    type: Types.GET_SUCCESS,
    payload: { data },
  }),
  getTrendFailure: error => ({
    type: Types.GET_FAILURE,
    payload: error,
  }),
};
