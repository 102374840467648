import React from 'react';
import { Box, Button, Typography, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import styles from './styles';
import { InputContainer, InputItem } from 'components/form/StyledComponents';
import CustomTextField from 'components/form/CustomTextField';
import * as Yup from 'yup';

export const initialValues = {
  newPassword: '',
  confirmPassword: '',
};

const schema = Yup.object().shape({
  newPassword: Yup
    .string()
    .min(6, 'A senha deve ter no mínimo 6 dígitos'),
  confirmPassword: Yup.string()
    .test('passwords-match', 'Senhas não correspondem', function (value) {
      return this.parent.newPassword === value;
    }),
});

const RecoveryForm = ({ onSubmit, initialValues, passwordRecoveryLoading }) => {

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      validateOnBlur
      render={({ handleSubmit }) => (
        <Box flex='1' display='flex' flexDirection='column' style={{ minWidth: 200 }}>
          <Form onSubmit={handleSubmit} style={{ marginBottom: 30, marginTop: 30 }}>
            <InputContainer style={{ marginBottom: 30 }}>
              <InputItem style={{ margin: 0 }}>
                <Typography style={{ marginBottom: 6 }}>Cadastre sua nova Senha</Typography>
                <Field
                  name="newPassword"
                  type="password"
                  label="Informe sua senha"
                  component={CustomTextField}
                />
              </InputItem>
            </InputContainer>
            <InputContainer>
              <InputItem style={{ margin: 0 }}>
                <Typography style={{ marginBottom: 6 }}>Confirme sua nova Senha</Typography>
                <Field
                  style={{ marginBottom: 50 }}
                  name="confirmPassword"
                  type="password"
                  label="Informe sua senha"
                  component={CustomTextField}
                />
              </InputItem>
              <Button type='submit' style={styles.purpleButton}>Cadastrar nova Senha
              {passwordRecoveryLoading &&
                  <CircularProgress size={15} style={{ color: 'white', marginLeft: 10 }} />
                }
              </Button>
            </InputContainer>
          </Form>
        </Box>
      )} />

  );
}

RecoveryForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

RecoveryForm.defaultProps = {
  initialValues,
}

export default RecoveryForm;

