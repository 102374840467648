export const formData = {
  activeStep: 0,
  name: '',
  email: '',
  description: '',
  cnpj: '',
  long: '',
  lat: '',
  terms: null,
  social_name: '',
  state_register: '',
  cell_phone: '',
  cnae: '',
  website: '',
  address: {
    code_post: '',
    street: '',
    number: '',
    district: '',
    complement: '',
    city: {
      id: 977,
    },
  },
  manager: {
    name: '',
    birth_date: '',
    cpf: '',
    cell_phone: '',
    email: '',
    genre: '',
    password: '',
    address: {
      code_post: '',
      street: '',
      number: '',
      district: '',
      complement: '',
      city: {
        id: '',
      },
    },
  },
  bank: {
    bank: {
      id: '',
    },
    agency: '',
    agency_check: '',
    account: '',
    account_check: '',
    type: '',
    doc_type: 'CPF',
    doc_number: '',
    account_holder: '',
  },
  segments: [],
};
