import { Button } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const ContainerForm = styled(Box)`
  flex-direction: row;
  margin-bottom: 30px;
  width: 500px;
  float: left;
  @media all and (max-width: 500px) {
    width: 100%;
  }
`;

export const FormContainer = styled.div`
  margin: 0;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
`;

export const InputItem = styled.div`
  flex-grow: ${props => props.flexGrow || 1};
  margin-right: 5px;
  @media all and (max-width: 500px) {
    margin: 15px 0px 0px 0px;
  }
  margin-top: 15px !important;
`;

export const ButtonProsseguir = styled(Button)`
  background-color: #fa8f47;
  color: white;
  padding: 10px 20px;
  margin-left: 20px;
`;

export const Title = styled(Typography)`
  font-size: 24px;
  color: #fa8f47;
  font-weight: bold;
`;
