/* eslint-disable consistent-return */
/* eslint-disable no-console */
/* eslint-disable eqeqeq */
import { call, takeLatest, all, put, select } from 'redux-saga/effects';
import api from 'services/api';

import { Types, Creators } from 'store/ducks/partner';
import { callApi } from 'store/sagas/auth';

import { push } from 'connected-react-router';
import { Creators as ToastCreators } from 'store/ducks/toast';

// Teste
function* getProfile() {
  try {
    const request = call(api.get, 'v1/admin/users/profile');
    const response = yield call(callApi, request);
    if (response.status !== 200 && response.status !== 201) throw response;
    yield put(Creators.getProfileSucess(response.data));
  } catch (err) {
    yield put(Creators.getProfileFailure('Erro ao buscar na API'));
    yield put(
      ToastCreators.getOpenToast({
        type: 'error',
        title: err.data.msg,
        description: '',
      })
    );
  }
}

function* getPartner({ payload }) {
  // try {
  //   const { id } = payload;
  //   const request = call(api.get, `/v1/admin/users/${id}`);
  //   const response = yield call(callApi, request);
  //   yield put(Creators.getPartnerSuccess(response.data));
  // } catch (err) {
  //   yield put(Creators.getPartnerFailure('Erro ao buscar na API'));
  //   yield put(
  //     ToastCreators.getOpenToast({
  //       type: 'error',
  //       title: err.data.msg,
  //       description: '',
  //     }),
  //   );
  // // }
}

// function* getPartnerUpdateEmailVerify({ payload }) {
//   try {
//     const { id, email_verified } = payload;
//     const request = call(api.put, `/v1/admin/users/${id}/validate_email`, {
//       email_verified,
//     });
//     const response = yield call(callApi, request);
//     yield put(Creators.getEmailVerifySuccess(response.data));
//   } catch (err) {
//     yield put(
//       ToastCreators.getOpenToast({
//         type: 'error',
//         title: err.data.msg,
//         description: '',
//       }),
//     );
// //   }
// // }

function* getPartnerImageUpload(id, image_data = null, type = 'perfil') {
  if (!image_data || !id) return;
  try {
    const data = new FormData();
    data.append('image', image_data);
    const request = call(
      api.post,
      `/v1/admin/business/partner/${id}/${
        type == 'perfil' ? 'images' : 'images/back_cover'
      }`,
      data
    );
    yield call(callApi, request);
    return true;
  } catch (err) {
    return false;
  }
}

function* getBannerImagesUpload(payload) {
  try {
    const { id, images_data, images_info } = payload;
    let iLocal = 0;
    for (let i = 0; i < images_info.length; i += 1) {
      const image_info = images_info[i];
      if (image_info.type === 'local') {
        // insere o serviço antes da imagem
        const response_service = yield call(
          api.post,
          `/v1/admin/business/partner/${id}`,
          {
            order_position: image_info.order_position,
            name: image_info.name,
            description: image_info.description,
            route: image_info.route || '',
          }
        );

        const id_service = response_service.data.data.id;
        const image = images_data[iLocal];
        const data = new FormData();
        data.append('image', image);
        const response = yield call(
          api.post,
          `/v1/admin/business/partner/content/${id_service}/images`,
          data
        );
        // eslint-disable-next-line no-unused-vars
        const id_image = response.data.data.id;
        image_info.id = id_service;
        iLocal += 1;
      } else {
        yield call(
          api.put,
          `/v1/admin/business/partner/content/${image_info.id}`,
          {
            order_position: image_info.order_position,
            name: image_info.name,
            description: image_info.description,
            route: image_info.route,
          }
        );
      }
    }
    yield put({ type: 'UPLOAD_SUCCESS' });
  } catch (err) {
    yield put({ type: 'UPLOAD_FAILURE' });
    yield put(
      ToastCreators.getOpenToast({
        type: 'error',
        title: err.data.msg,
        description: '',
      })
    );
  }
}

// jhe

function* getPartnerInsert({ payload }) {
  try {
    const {
      name,
      status,
      images_data,
      images_info,
      route = '',
      lat,
      long,
      image_tmp_cover_data,
      image_tmp_data,
    } = payload;

    const request = call(api.post, '/v1/admin/business/partner', {
      name,
      status,
      route,
      lat,
      long,
    });
    const response = yield call(callApi, request);
    const { id } = response.data.data;
    yield getBannerImagesUpload({ id, images_data, images_info });
    yield getPartnerImageUpload(id, image_tmp_cover_data, 'cover');
    yield getPartnerImageUpload(id, image_tmp_data);
    yield put(Creators.getPartnerInsertSuccess());
    yield put(
      ToastCreators.getOpenToast({
        type: 'success',
        title: 'Cadastro concluido com sucesso',
        description: '',
      })
    );
    yield put(push(`/partner/update/${id}`));
  } catch (err) {
    yield put(Creators.getPartnerInsertFailure('Erro ao buscar na API'));
    yield put(
      ToastCreators.getOpenToast({
        type: 'error',
        title: err.data.msg,
        description: '',
      })
    );
  }
}

function* getPartnerUpdate({ payload }) {
  try {
    const {
      id,
      name,
      status,
      images_data,
      images_info,
      route,
      lat,
      long,
      image_tmp_cover_data,
      image_tmp_data,
    } = payload;

    const request = call(api.put, `/v1/admin/business/partner/${id}`, {
      name,
      status,
      route,
      lat,
      long,
    });
    yield call(callApi, request);
    yield getBannerImagesUpload({ id, images_data, images_info });
    yield getPartnerImageUpload(id, image_tmp_cover_data, 'cover');
    yield getPartnerImageUpload(id, image_tmp_data);
    yield put(Creators.getPartnerUpdateSuccess());
    yield put(push(`/partner/update/${id}`));
    yield put(
      ToastCreators.getOpenToast({
        type: 'success',
        title: 'Edição concluida com sucesso',
        description: '',
      })
    );
  } catch (err) {
    yield put(Creators.getPartnerUpdateFailure('Erro ao buscar na API'));
    yield put(
      ToastCreators.getOpenToast({
        type: 'error',
        title: err.data.msg,
        description: '',
      })
    );
  }
}

function* getPartnerDelete({ payload }) {
  try {
    const { id } = payload;

    const request = call(
      api.delete,
      `/v1/admin/business/partner/content/${id}`
    );
    yield call(callApi, request);
    yield put(Creators.getPartnerDeleteSuccess());
  } catch (err) {
    yield put(Creators.getPartnerDeleteFailure('Erro ao buscar na API'));
  }
}

// function* getPartnerIdDelete({ payload }) {
//   try {
//     const { id } = payload;

//     const request = call(api.delete, `/v1/admin/business/partner/${id}`);
//     yield call(callApi, request);
//     yield put(Creators.getPartnerDeleteSuccess());
//   } catch (err) {
//     yield put(Creators.getPartnerDeleteFailure('Erro ao buscar na API'));
//   }
// }

function* getPartnerIdDelete({ payload }) {
  try {
    const { id } = payload;
    const request = call(api.delete, `/v1/admin/business/partner/${id}`);
    yield call(callApi, request);
    yield put(Creators.getPartnerDeleteSuccess());
    // Remove a categoria deletada da lista
    const { partnerList, partnerListTotal } = yield select(
      state => state.partner
    );

    yield put(
      Creators.getPartnerListSuccess({
        data: partnerList.filter(doc => doc.id !== id),
        total: partnerListTotal - 1,
      })
    );
  } catch (err) {
    yield put(Creators.getVirtualCatalogDeleteFailure('Erro ao buscar na API'));
  }
}

function* getPartnerList({ payload }) {
  try {
    const {
      page,
      perPage,
      search,
      orderByColumn,
      orderByDirection,
      group_id,
      callback,
    } = payload;
    const request = call(api.get, '/v1/admin/business/partner', {
      page,
      search,
      per_page: perPage,
      order: orderByDirection,
      order_by: orderByColumn,
      group_id,
    });

    const response = yield call(callApi, request);
    if (response.status !== 200 && response.status !== 201) throw response;
    yield put(Creators.getPartnerListSuccess(response.data));
    if (callback) callback(response.data.data);
  } catch (err) {
    if (err.status === 404) {
      yield put(
        Creators.getPartnerListSuccess({
          total: 0,
          data: [],
        })
      );
    } else {
      yield put(Creators.getPartnerListFailure('Erro ao buscar na API'));
    }
    yield put(
      ToastCreators.getOpenToast({
        type: 'error',
        title: err.data.msg,
        description: '',
      })
    );
  }
}

export function* getPartnerStatusRequest({ payload }) {
  try {
    const { client, status, email } = payload;
    const request = call(api.put, `/v1/client/${client}`, {
      status: status === 1 ? 0 : 1,
      email,
    });
    yield call(callApi, request);
    yield put(Creators.getPartnerRequest({ id: client }));
  } catch (err) {
    yield put(Creators.getPartnerFailure('Erro ao buscar na API'));
  }
}

// Individual exports for testing
export default function* productListSaga() {
  yield all([
    takeLatest(Types.GET_REQUEST_PROFILE, getProfile),
    takeLatest(Types.GET_REQUEST, getPartner),
    takeLatest(Types.GET_INSERT_REQUEST, getPartnerInsert),
    takeLatest(Types.GET_UPDATE_REQUEST, getPartnerUpdate),
    takeLatest(Types.GET_DELETE_REQUEST, getPartnerDelete),
    takeLatest(Types.GET_DELETE_ID_REQUEST, getPartnerIdDelete),
    takeLatest(Types.GET_LIST_REQUEST, getPartnerList),
    takeLatest(Types.GET_USER_STATUS_REQUEST, getPartnerStatusRequest),
  ]);
}
