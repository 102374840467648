import { Types as AuthTypes } from 'store/ducks/auth';

export const Types = {
  // Lista Produtos Favoritos
  GET_LIST_REQUEST: 'favoriteProductListReduced/GET_LIST_REQUEST',
  GET_LIST_SUCCESS: 'favoriteProductListReduced/GET_LIST_SUCCESS',
  GET_LIST_FAILURE: 'favoriteProductListReduced/GET_LIST_FAILURE',
};

export const initialState = {
  // Lista de Produtos Favoritos
  list: [],
  loading: false,
  error: null,
  total: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Lista de Produtos Favoritos
    case Types.GET_LIST_REQUEST:
      return { ...state, loading: true };
    case Types.GET_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload.data,
        loading: false,
        error: null,
        total: action.payload.total,
      };
    case Types.GET_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        list: [],
        error: action.payload,
      };

    case AuthTypes.GET_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export const Creators = {
  // Busca lista de Produto Favorito
  getFavoriteProductListReducedRequest: () => ({
    type: Types.GET_LIST_REQUEST,
  }),
  getFavoriteProductListReducedSuccess: ({ data, total }) => ({
    type: Types.GET_LIST_SUCCESS,
    payload: { data, total },
  }),
  getFavoriteProductListReducedFailure: error => ({
    type: Types.GET_LIST_FAILURE,
    payload: error,
  }),
};
