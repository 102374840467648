import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { responseToSelect } from 'utils/response';
import { FormHelperText } from '@material-ui/core';
import { ErrorMessage } from 'formik';
import styled from 'styled-components';

const StyledSelect = styled(Select)`
  && {
    & > div {
      min-width: 243px;,
      margin-bottom: 5px;
      height:43px;
      font-family: Arial;
      border: ${props => (props.error ? '1px solid red' : null)};
    }
  }
`;

const CustomSelect = ({
  placeholder,
  field,
  form,
  options,
  isClearable,
  isDisabled,
  isMulti,
  isLoading,
  onClick = null,
}) => {
  const [selectOptions, setSelectOptions] = React.useState([]);
  const [selectedOption, setSelectedOption] = React.useState(isMulti ? [] : '');
  const onChange = option => {
    if (!option) {
      form.setFieldValue(field.name, undefined);
      return;
    }
    form.setFieldValue(
      field.name,
      isMulti
        ? option.map(item => ({ id: item.value, name: item.label }))
        : option.value
    );
    if (onClick) onClick();
  };

  const getValue = () => {
    if (isMulti) {
      const selecteds = options.filter(option =>
        field.value.map(x => x.id).includes(option.id)
      );
      setSelectedOption(
        selecteds.map(item => ({ value: item.id, label: item.name }))
      );
    } else {
      const selected = selectOptions.find(
        option => option.value === field.value
      );
      if (selected) {
        setSelectedOption(selected);
      } else {
        setSelectedOption('');
      }
    }
  };

  React.useEffect(() => {
    setSelectOptions(responseToSelect(options));
  }, [options]);

  React.useEffect(() => {
    getValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOptions, field.value]);

  return (
    <>
      <StyledSelect
        styles={{
          menuPortal: base => {
            const { ...rest } = base; // remove zIndex from base by destructuring
            return { ...rest, zIndex: 9999, fontFamily: 'Arial' };
          },
        }}
        {...field}
        value={selectedOption}
        noOptionsMessage={() => 'Nenhum resultado encontrado'}
        onChange={onChange}
        placeholder={placeholder}
        id="genero"
        options={selectOptions}
        isDisabled={isDisabled}
        isClearable={isClearable}
        isMulti={isMulti}
        menuPortalTarget={document.querySelector('body')}
        isLoading={isLoading}
        error={
          typeof form.errors[field.name.replace('.id', '')] === 'undefined'
            ? false
            : true
        }
      />
      <ErrorMessage name={field.name}>
        {msg => <FormHelperText error>{msg}</FormHelperText>}
      </ErrorMessage>
    </>
  );
};

CustomSelect.propTypes = {
  field: PropTypes.oneOfType([PropTypes.object]).isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  options: PropTypes.oneOfType([PropTypes.array]).isRequired,
  placeholder: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMulti: PropTypes.bool,
};

CustomSelect.defaultProps = {
  isMulti: false,
  isLoading: true,
};

export default CustomSelect;
