import { call, takeLatest, all, put, take } from 'redux-saga/effects';
import api from 'services/api';
import { Types, Creators } from 'store/ducks/reseller';
import { Creators as NotificationCreators } from 'store/ducks/app';
import { Types as AuthTypes, Creators as AuthCreators } from 'store/ducks/auth';

function* getResellerInsertRequest({ payload }) {
  try {
    const { resellerData, enterpriseData } = payload;
    const response = yield call(api.post, '/v1/client/reseller', {
      ...resellerData,
      enterprise: enterpriseData,
    });

    if (response.status !== 200 && response.status !== 201) throw response;

    yield put(Creators.getResellerInsertSuccess());
    yield put(
      AuthCreators.getAuthRequest({
        username: resellerData.email,
        password: resellerData.password,
      })
    );
    const action = yield take([AuthTypes.GET_SUCCESS, AuthTypes.GET_FAILURE]);
    if (action.type === AuthTypes.GET_FAILURE) {
      throw response;
    }

    const code_recommendation =
      localStorage.getItem('@sensi-marketplace#reseller') || null;
    if (code_recommendation) {
      yield call(api.post, '/v1/site/users/partners/link', {
        invitee: code_recommendation,
        invited: response.data.data.id,
      });
      localStorage.removeItem('@sensi-marketplace#reseller');
    }
  } catch (err) {
    yield put(
      NotificationCreators.openNotification({
        message:
          err.data.data.Error || err.data.msg || 'não foi possível enviar',
        type: 'error',
      })
    );
    yield put(
      Creators.getResellerInsertFailure(
        err.data.data.Error || err.data.msg || 'não foi possível enviar'
      )
    );
  }
}

export default function* resellerSaga() {
  yield all([takeLatest(Types.GET_INSERT_REQUEST, getResellerInsertRequest)]);
}
