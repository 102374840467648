import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import { InputContainer, InputItem } from 'components/form/StyledComponents';
import CustomTextField from 'components/form/CustomTextField';
import CustomButton from 'components/form/CustomButton';
import * as Yup from 'yup';
import styles from './styles';
import { useSelector } from 'react-redux';

const ForgotForm = ({ onSubmit, cancel }) => {
  const { clientForgotLoading } = useSelector(state => state.client);

  const schema = Yup.object().shape({
    email: Yup.string()
      .email('Este email não é valido')
      .required('Este campo é obrigatório'),
  });

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={schema}
      onSubmit={onSubmit}
      validateOnBlur
      render={({ handleSubmit }) => (
        <Box flex="1" display="flex" flexDirection="column">
          <Form onSubmit={handleSubmit} style={{ marginBottom: 20 }}>
            <InputContainer>
              <InputItem style={{ margin: 0 }}>
                <Field
                  name="email"
                  label="Informe seu e-mail cadastrado. Ex: nome@email.com"
                  component={CustomTextField}
                />
              </InputItem>
            </InputContainer>
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="row"
              flex="1"
              alignItems="center"
              style={{ marginTop: 20 }}>
              <CustomButton
                type="submit"
                label="Cancelar"
                onClick={cancel}
                style={styles.cancelButton}
              />
              <CustomButton
                type="submit"
                label="Confirmar"
                isLoading={clientForgotLoading}
                style={styles.purpleButton}
              />
            </Box>
          </Form>
        </Box>
      )}
    />
  );
};

ForgotForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default ForgotForm;
