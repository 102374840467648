import { all, fork } from 'redux-saga/effects';
import authSaga from './auth';
import clientSaga from './client';
import addressSaga from './address';
import categorySaga from './category/index';
import categoryListSaga from './category/list';
import checkoutCartSaga from './checkout/cart';
import checkoutAddressSaga from './checkout/sharedAddress';
import productSaga from './product';
import productsBySectionSaga from './product/productsBySection';
import orderSaga from './order';
import collaboratorSaga from './collaborator';
import homeSaga from './home';
import configSaga from './config';
import storeListSaga from './stores/list';
import storesByCodeSaga from './stores/byCode';
import productsByStoreSaga from './stores/producstByStore';
import trendListSaga from './trend/list';
import trendSaga from './trend';
import trendProductSaga from './trend/product';
import infoPageSaga from './infoPage';
import favoriteInsertSaga from './favorite/insert';
import favoritesListSaga from './favorite/list';
import passwordRecoverySaga from './account/passwordRecovery';
import validateHashSaga from './account/validate';
import validateAccount from './account/validateAccount';
import storeInsertSaga from './stores/insert';
import productColorAdminSaga from './admin/productColor';
import productSizeAdminSaga from './admin/productSize';
import storesAdminSaga from './admin/stores';
import storeClosedAdminSaga from './admin/closed';
import orderListSagaAdmin from './admin/order';
import virtualCatalogAdminSaga from './admin/virtualCatalog';
import productStock from './admin/product/productStockUpdate';
import productAdminSaga from './admin/product';
import walletSaga from './admin/wallet';
import withdrawSaga from './admin/wallet/withdraw';
import bankListAdminSaga from './admin/bank';
import userListSagas from './admin/user';
import addressAdminSaga from './admin/address';
import groupSagas from './admin/group';
import bankListSaga from './bank/list';
import favoritesReducedSaga from './favorite/reduced';
import dashboardSagas from './admin/dashboard';
import reseller from './reseller';
import reportSaga from './admin/report';
import reportStoreSaga from './admin/reportStore';
import reportCommissionSaga from './admin/reportCommission';
import cupomSaga from './cupom';
import couponStoreSaga from './admin/coupon';
import installment from './stores/installment';
import partnerSaga from 'store/sagas/partner';

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(clientSaga),
    fork(productStock),
    fork(addressSaga),
    fork(categorySaga),
    fork(categoryListSaga),
    fork(productSaga),
    fork(productsBySectionSaga),
    fork(productColorAdminSaga),
    fork(productSizeAdminSaga),
    fork(storesAdminSaga),
    fork(storeClosedAdminSaga),
    fork(checkoutCartSaga),
    fork(checkoutAddressSaga),
    fork(orderSaga),
    fork(collaboratorSaga),
    fork(homeSaga),
    fork(configSaga),
    fork(storeInsertSaga),
    fork(storeListSaga),
    fork(storesByCodeSaga),
    fork(productsByStoreSaga),
    fork(trendSaga),
    fork(trendProductSaga),
    fork(trendListSaga),
    fork(infoPageSaga),
    fork(favoriteInsertSaga),
    fork(favoritesListSaga),
    fork(favoritesReducedSaga),
    fork(passwordRecoverySaga),
    fork(validateHashSaga),
    fork(validateAccount),
    fork(orderListSagaAdmin),
    fork(virtualCatalogAdminSaga),
    fork(productAdminSaga),
    fork(walletSaga),
    fork(withdrawSaga),
    fork(bankListAdminSaga),
    fork(userListSagas),
    fork(addressAdminSaga),
    fork(groupSagas),
    fork(bankListSaga),
    fork(dashboardSagas),
    fork(reseller),
    fork(reportSaga),
    fork(reportStoreSaga),
    fork(reportCommissionSaga),
    fork(cupomSaga),
    fork(couponStoreSaga),
    fork(installment),
    fork(partnerSaga),
  ]);
}
