import { Box } from '@material-ui/core';
import { Field, Formik } from 'formik';
import React from 'react';
import { cardInitialValues, validationSchema } from './validation';
import Cards from 'react-credit-cards';
import {
  ContainerForm,
  InputContainer,
  InputItem,
  ButtonProsseguir,
  Title,
} from './styles';
import CustomMaskField from 'components/form/CustomMaskField';
import CustomTextField from 'components/form/CustomTextField';
import { Button } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import appUtils from 'utils/appUtils';

function StoreCreatePayment({ onSubmit, onBack, isLoading, shopping }) {
  const [cardValidate, setCardValidate] = React.useState(false);
  const validateDateValid = value => {
    let error;
    if (value.replace(/[^\d]+/g, '').length < 6) {
      error = 'Digite a data de validade corretamente';
    }
    return error;
  };
  const handleIsValid = (type, isValid) => {
    setCardValidate(isValid);
  };

  const { store_recurrence_amount, store_free_trial_duration } = shopping;

  const validateCard = () => {
    let error;
    if (!cardValidate) {
      error = 'Número de cartão inválido';
    }
    return error;
  };

  return (
    <Formik
      initialValues={cardInitialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
      render={({ handleSubmit, values }) => (
        <Box flex="1" display="flex" flexDirection="column">
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            alignItems="center"
            flexWrap="wrap"
            justifyContent="center"
            data-cy="text_qtd_days"
            style={{ marginTop: 60 }}>
            <Title>
              Você tem {store_free_trial_duration} dias de teste gratuito em
              nossa plataforma
            </Title>
            <p
              data-cy="text_value_recurrence"
              style={{ fontSize: 20, color: '#555' }}>
              Após o teste, você pagará apenas{' '}
              {appUtils.formatPrice(store_recurrence_amount)} por mês
            </p>
          </Box>
          <Box
            display="flex"
            flex="1"
            flexDirection="row"
            alignItems="center"
            flexWrap="wrap"
            justifyContent="center"
            style={{ marginTop: 60 }}>
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{ marginBottom: 30, marginRight: 20 }}>
              <Cards
                cvc={values.card.cvc}
                expiry={values.card.validate}
                focus="name"
                name={values.card.holder.name}
                number={values.card.number}
                acceptedCards={['visa', 'mastercard', 'elo']}
                locale={{ valid: 'data de val.' }}
                placeholders={{
                  name: 'NOME DO TITULAR',
                }}
                callback={handleIsValid}
              />
            </Box>
            <ContainerForm>
              <InputContainer style={{ marginTop: 0 }}>
                <InputItem style={{ marginTop: 0 }}>
                  <Field
                    name="card.number"
                    label="Numero do cartao"
                    validate={validateCard}
                    mask="9999 9999 9999 9999"
                    component={CustomMaskField}
                  />
                </InputItem>
              </InputContainer>
              <InputContainer>
                <InputItem>
                  <Field
                    name="card.validate"
                    validate={validateDateValid}
                    label="Data de Validade"
                    mask="99/9999"
                    component={CustomMaskField}
                  />
                </InputItem>
                <InputItem>
                  <Field
                    name="card.cvc"
                    label="CVC"
                    type="text"
                    component={CustomTextField}
                  />
                </InputItem>
              </InputContainer>
              <InputContainer>
                <InputItem style={{ flexGrow: 3 }}>
                  <Field
                    name="card.holder.name"
                    label="Nome do titular"
                    component={CustomTextField}
                  />
                </InputItem>
              </InputContainer>
              <InputContainer>
                <InputItem>
                  <Field
                    name="card.holder.birth_date"
                    label="Data de nascimento do titular"
                    mask="99/99/9999"
                    component={CustomMaskField}
                  />
                </InputItem>
                <InputItem>
                  <Field
                    name="card.holder.cpf"
                    label="CPF do titular"
                    mask="999.999.999-99"
                    component={CustomMaskField}
                  />
                </InputItem>
              </InputContainer>
            </ContainerForm>
          </Box>
          {false && (
            <InputItem
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: 80,
              }}>
              <ButtonProsseguir
                variant="contained"
                onClick={handleSubmit}
                id="btnProsseguir">
                Salvar cartão
              </ButtonProsseguir>
            </InputItem>
          )}
          <Box style={{ padding: 10, marginTop: 40 }}>
            <InputItem style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={() => onBack(0, values)} id="btnVoltar">
                Voltar
              </Button>
              <ButtonProsseguir
                variant="contained"
                disabled={isLoading}
                onClick={handleSubmit}
                id="btnProsseguir">
                {isLoading && (
                  <CircularProgress
                    size={15}
                    style={{ color: 'white', marginRight: 10 }}
                  />
                )}
                Finalizar cadastro
              </ButtonProsseguir>
            </InputItem>
          </Box>
        </Box>
      )}
    />
  );
}

export default StoreCreatePayment;
