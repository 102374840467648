/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable linebreak-style */
import React from 'react';
import { Box, Button, Typography, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import { InputContainer, InputItem } from 'components/form/StyledComponents';
import CustomTextField from 'components/form/CustomTextField';
import * as Yup from 'yup';
import CustomSelect from 'components/form/CustomSelect';
import validators from 'utils/validators';
import CustomMaskField from 'components/form/CustomMaskField';
import styles from './styles';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as BankCreators } from 'store/ducks/bank/list';

const RegisterBankForm = ({ onSubmit, initialValues, onBack }) => {
  const [filters] = React.useState({
    page: 1,
    perPage: 999,
    search: '',
    orderByColumn: '',
    orderByDirection: '',
    order: '',
  });

  const dispatch = useDispatch();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(BankCreators.getBankListRequest(filters));
  }, []);

  const schema = Yup.object().shape({
    bank: Yup.object().shape({
      id: Yup.string()
        .test(...validators.numberNotRequired())
        .required('Campo Obrigatório'),
    }),
    agency: Yup.string()
      .test(...validators.numberNotRequired())
      .required('Campo Obrigatório'),
    agency_check: Yup.string()
      .test(...validators.numberNotRequired('Apenas numeros'))
      .required('Campo Obrigatório'),
    account: Yup.string()
      .test(...validators.numberNotRequired())
      .required('Campo Obrigatório'),
    account_check: Yup.string().test(...validators.numberNotRequired()),
    type: Yup.string().required('Campo Obrigatório'),
    doc_type: Yup.string().required('Campo Obrigatório'),
    doc_number: Yup.string().when('doc_type', {
      is: 'CNPJ',
      then: Yup.string()
        .test(...validators.cnpjInvalid('CNPJ inválido'))
        .required('Campo Obrigatório'),
      otherwise: Yup.string()
        .test(...validators.cpfInvalid('CPF inválido'))
        .required('Campo Obrigatório'),
    }),
    // pix_key_type: Yup.string(),
    // pix_key: Yup.string().when('pix_key_type', (pixKeyType, schema) => {
    //   if (pixKeyType === 'CNPJ')
    //     return schema
    //       .test(...validators.cnpjInvalid('CNPJ inválido'))
    //       .required('Campo Obrigatório');
    //   if (pixKeyType === 'CPF')
    //     return schema
    //       .test(...validators.cpfInvalid('CPF inválido'))
    //       .required('Campo Obrigatório');
    //   if (pixKeyType === 'PHONE') return schema.required('Campo Obrigatório');
    //   if (pixKeyType === 'EMAIL')
    //     return schema.required('Campo Obrigatório').email('Email inválido');
    //   if (pixKeyType === 'EVA')
    //     return schema
    //       .test(...validators.uuidInvalid('Chave aleatória inválida'))
    //       .required('Campo Obrigatório');
    //   return schema;
    // }),
    account_holder: Yup.string().required('Campo Obrigatório'),
  });

  const {
    bank: { bankList, bankListLoading },
    stores: {
      insert: { storeInsertLoading },
    },
  } = useSelector(state => state);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      validateOnBlur
      onSubmit={onSubmit}
      render={({ handleSubmit, values }) => {
        console.log(values);
        return (
          <Box flex="1" display="flex" flexDirection="column">
            <Form onSubmit={handleSubmit}>
              <InputContainer style={{ marginBottom: 35 }}>
                <InputItem>
                  <Typography style={styles.titleInput}>
                    Banco <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <Field
                    name="bank.id"
                    label="Banco"
                    options={bankList}
                    component={CustomSelect}
                    placeholder="Banco"
                    isLoading={bankListLoading}
                  />
                </InputItem>
                <InputItem>
                  <Typography style={styles.titleInput}>
                    Número da Agência <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <Field
                    name="agency"
                    placeholder="Informe o número da agência"
                    component={CustomTextField}
                  />
                </InputItem>
                <InputItem>
                  <Typography style={styles.titleInput}>
                    Dígito da Agência <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <Field
                    name="agency_check"
                    placeholder="Informe o dígito da agência"
                    component={CustomTextField}
                  />
                </InputItem>
              </InputContainer>
              <InputContainer>
                <InputItem>
                  <Typography style={styles.titleInput}>
                    Número da conta <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <Field
                    name="account"
                    placeholder="Informe o número da conta"
                    component={CustomTextField}
                  />
                </InputItem>
                <InputItem>
                  <Typography style={styles.titleInput}>
                    Dígito da conta
                  </Typography>
                  <Field
                    name="account_check"
                    placeholder="Informe o dígito da conta"
                    component={CustomTextField}
                  />
                </InputItem>
                <InputItem>
                  <Typography style={styles.titleInput}>
                    Tipo de conta <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <Field
                    name="type"
                    label="Tipo de Conta"
                    options={[
                      { id: 'Corrente', name: 'Corrente' },
                      { id: 'Poupança', name: 'Poupança' },
                    ]}
                    component={CustomSelect}
                    placeholder="Informe tipo de conta"
                    isLoading={false}
                  />
                </InputItem>
              </InputContainer>
              <InputContainer>
                <InputItem>
                  <Typography style={styles.titleInput}>
                    Títular da conta <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <Field
                    name="account_holder"
                    placeholder="Informe o titular da conta"
                    component={CustomTextField}
                  />
                </InputItem>
              </InputContainer>

              <InputContainer>
                <InputItem>
                  <Typography style={styles.titleInput}>
                    Tipo de Documento <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <Field
                    name="doc_type"
                    label="Tipo de Documento"
                    options={[
                      { id: 'CPF', name: 'CPF' },
                      { id: 'CNPJ', name: 'CNPJ' },
                    ]}
                    isDisabled
                    component={CustomSelect}
                    placeholder="Informe tipo de documento"
                    isLoading={false}
                  />
                </InputItem>
                <InputItem>
                  <Typography style={styles.titleInput}>
                    Número do Documento <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <Field
                    name="doc_number"
                    placeholder="Número do documento"
                    component={CustomMaskField}
                    disabled
                    mask={
                      '99.999.999/9999-99'
                      // values.doc_type === 'CPF'
                      //   ? '999.999.999-99'
                      //   : '99.999.999/9999-99'
                    }
                  />
                </InputItem>
              </InputContainer>

              {/* <InputContainer>
                <InputItem>
                  <Typography style={styles.titleInput}>Chave Pix</Typography>
                  <Field
                    name="pix_key_type"
                    options={[
                      { id: 'CPF', name: 'CPF' },
                      { id: 'CNPJ', name: 'CNPJ' },
                      { id: 'PHONE', name: 'Telefone' },
                      { id: 'EMAIL', name: 'Email' },
                      { id: 'EVA', name: 'Aleatória' },
                    ]}
                    isClearable
                    component={CustomSelect}
                    placeholder="Informe tipo de chave Pix"
                    isLoading={false}
                  />
                </InputItem>
                {values?.pix_key_type && (
                  <InputItem>
                    <Typography style={styles.titleInput}>
                      Número do Documento{' '}
                      <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <Field
                      name="pix_key"
                      placeholder={
                        values.pix_key_type === 'CPF'
                          ? 'CPF'
                          : values.pix_key_type === 'CNPJ'
                          ? 'CNPJ'
                          : values.pix_key_type === 'PHONE'
                          ? 'Telefone'
                          : values.pix_key_type === 'EMAIL'
                          ? 'Email'
                          : 'Chave Aleatória'
                      }
                      component={CustomMaskField}
                      mask={
                        values.pix_key_type === 'CPF'
                          ? '999.999.999-99'
                          : values.pix_key_type === 'CNPJ'
                          ? '99.999.999/9999-99'
                          : values.pix_key_type === 'PHONE'
                          ? '(99) 9 9999-9999'
                          : values.pix_key_type === 'EVA'
                          ? '********-****-****-****-************'
                          : ''
                      }
                    />
                  </InputItem>
                )}
              </InputContainer> */}

              <Box style={{ padding: 10, marginTop: 40 }}>
                <InputItem
                  style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    onClick={() => {
                      onBack(1);
                    }}
                    style={styles.purpleButton}>
                    Voltar
                  </Button>
                  <Button type="submit" style={styles.purpleButton} is>
                    {storeInsertLoading && (
                      <CircularProgress
                        size={15}
                        style={{ color: 'white', marginLeft: 10 }}
                      />
                    )}
                    Finalizar
                  </Button>
                </InputItem>
              </Box>
            </Form>
          </Box>
        );
      }}
    />
  );
};

RegisterBankForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default RegisterBankForm;
