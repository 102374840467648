/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import PropTypes from 'prop-types';
import ActionFab from 'components/Actions/ActionFab';
import { useDispatch } from 'react-redux';
import { Creators as PanelTabsCreators } from 'store/ducks/panelTabs';
import { useHistory } from 'react-router-dom';
import { appTabs } from 'components/Pages/MyAccountPage/PainelAccount/TabPanelShopkeeper';

const CollaboratorActions = ({ rowData }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const navigateToTab = (tab, params = {}) => {
    dispatch(PanelTabsCreators.changeTab(tab, params));
  };

  return (
    <>
      <ActionFab
        icon="article"
        onClick={() => {
          navigateToTab(appTabs.SetofPlots, { id: rowData.id });
          history.push({
            search: `?Plots_id=` + rowData.id,
          });
        }}
      />
    </>
  );
};

CollaboratorActions.propTypes = {
  onTranferRequest: PropTypes.func,
  rowData: PropTypes.oneOfType([PropTypes.object]).isRequired,
  validateEmail: PropTypes.func,
};

CollaboratorActions.defaultProps = {
  onTranferRequest: () => {},
  validateEmail: () => {},
};

export default CollaboratorActions;
