import React from 'react';
import styles from './styles';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@material-ui/core';
import appColors from 'utils/appColors';
import EditAddressForm from './EditAddressForm';
import ConsultaCepForm from './ConsultCepForm';

const RegisterAddress = ({
  onBack,
  onSubmit,
  address,
  onSubmitCEP,
  addressLoading,
  addressError,
}) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      flex="1"
      style={styles.containerForm}>
      {Object.keys(address).length > 0 && !addressLoading && (
        <EditAddressForm
          onBack={onBack}
          onSubmit={onSubmit}
          onSubmitCEP={onSubmitCEP}
          cepError={addressError}
          initialValues={address}
        />
      )}
      {Object.keys(address).length === 0 && !addressLoading && (
        <ConsultaCepForm
          onBack={onBack}
          onSubmit={onSubmitCEP}
          addressLoading={addressLoading}
          cepError={addressError}
        />
      )}
      {addressLoading && (
        <Box
          flex="1"
          display="flex"
          justifyContent="center"
          alignItems="center">
          <CircularProgress style={{ color: appColors.PRIMARY_COLOR }} />
        </Box>
      )}
    </Box>
  );
};

RegisterAddress.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  address: PropTypes.object.isRequired,
  onSubmitCEP: PropTypes.func.isRequired,
  addressLoading: PropTypes.bool.isRequired,
  addressError: PropTypes.any,
};

export default RegisterAddress;
