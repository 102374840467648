import appColors from "utils/appColors";

const styles = () => ({
  statContainer: {
    boxShadow: '0px 1px 2px rgba(0,0,0,0.2)',
    marginLeft: '15px',
    padding: '10px',
  },
  statIconContainer: {
    paddingRight: '16px',
    paddingTop: '6px',
  },
  statIcon: {
    color: appColors.COLOR_ICON,
    fontSize: '32px',
  },
  statContent: {
    float: 1,
  },
  statDescription: {
    color: '#1976D2',
  },
});

export default styles;
