/* eslint-disable no-undef */
import { call, takeLatest, all, put, select } from 'redux-saga/effects';
import api from 'services/api';
import { Types, Creators } from 'store/ducks/admin/order';
import { Creators as NotificationCreators } from 'store/ducks/app';
import { callApi } from 'store/sagas/auth';
import { push } from 'connected-react-router';
import Notifications from 'react-notification-system-redux';

function* getOrder({ payload }) {
  try {
    const { id } = payload;
    const request = call(api.get, `/v1/admin/orders/${id}`);
    const response = yield call(callApi, request);
    if (response.status !== 200) throw response;
    yield put(Creators.getOrderSuccess(response.data));
  } catch (err) {
    yield put(Creators.getOrderFailure('Erro ao buscar na API'));
  }
}

function* getOrderInsert({ payload }) {
  try {
    const { category_father, description, name, order_position } = payload;
    const request = call(api.post, '/v1/admin/orders', {
      name,
      order_position,
      description: description.toString('markdown'),
      category_father,
    });
    const response = yield call(callApi, request);
    yield put(Creators.getOrderInsertSuccess());
    yield put(
      Notifications.success({ title: 'Cadastro concluido com sucesso' })
    );
    yield put(push(`/order/update/${response.data.data.id}`));
  } catch (err) {
    yield put(Creators.getOrderInsertFailure('Erro ao buscar na API'));
  }
}

function* getOrderUpdate() {
  // try {
  //   const { id, category_father, description, name, order_position } = payload;
  //   const response = yield call(api.put, `/v1/admin/orders/${id}`, {
  //     name,
  //     order_position,
  //     description: description.toString('markdown'),
  //     category_father,
  //   });
  //   yield put(Creators.getOrderUpdateSuccess());
  //   yield put(Notifications.success({ title: 'Edição concluida com sucesso' }));
  // } catch (err) {
  //   yield put(Creators.getOrderUpdateFailure('Erro ao buscar na API'));
  // }
}

function* getOrderDelete({ payload }) {
  try {
    const { id } = payload;
    const request = call(api.delete, `/v1/admin/orders/${id}`);
    const response = yield call(callApi, request);
    console.log(response);
    yield put(Creators.getOrderDeleteSuccess());
    yield put(
      NotificationCreators.openNotification({
        message: 'Pedido removido com sucesso',
        type: 'success',
      })
    );
    // Remove a categoria deletada da lista
    const { categoryList, categoryListTotal } = yield select(
      state => state.category
    );
    window.location.reload();
    if (categoryList) {
      yield put(
        Creators.getOrderListSuccess({
          data: categoryList.filter(doc => doc.id !== id),
          total: categoryListTotal - 1,
        })
      );
    }
  } catch (err) {
    console.log(err);
    yield put(
      NotificationCreators.openNotification({
        message: 'Falha na requisição',
        type: 'error',
      })
    );
    yield put(Creators.getOrderDeleteFailure('Erro ao buscar na API'));
  }
}

function* getOrderList({ payload }) {
  try {
    const {
      page,
      perPage,
      search,
      orderByDirection,
      dateStart,
      dateEnd,
      statusNotIn,
      // order,
    } = payload;
    // const request = call(api.get, `/v1/admin/orders?${order}`, {
    const request = call(api.get, `/v1/admin/orders`, {
      page,
      search,
      per_page: perPage,
      order_by: orderByDirection,
      date_start: dateStart,
      date_end: dateEnd,
      status_not_in: statusNotIn,
    });

    const response = yield call(callApi, request);
    if (response.status !== 200) throw new Error(response);
    yield put(Creators.getOrderListSuccess(response.data));
  } catch (err) {
    if (err.status === 404) {
      yield put(
        Creators.getOrderListSuccess({
          total: 0,
          data: [],
          summary: { total: 0, price: 0 },
        })
      );
    } else {
      yield put(Creators.getOrderListFailure('Erro ao buscar na API'));
    }
    yield put(
      NotificationCreators.openNotification({
        message: err.data.data.Error || err.data.msg,
        type: 'error',
      })
    );
  }
}

function* getStatusUpdate({ payload }) {
  try {
    const { id, print, status } = payload;
    const request = call(api.put, `/v1/admin/orders/${id}`, payload);
    const response = yield call(callApi, request);
    if (response.status !== 200 && response.status !== 201) throw response;
    let newData = response.data;
    newData.data.status = status;
    yield put(Creators.getUpdateStatusSuccess(newData));
    yield put(
      NotificationCreators.openNotification({
        message: 'Status Alterado com sucesso',
        type: 'success',
      })
    );
    if (print) yield put(Creators.getPrintRequest(id));
  } catch (err) {
    console.log(err, 'erro aq!!!!!!!!');
    yield put(
      NotificationCreators.openNotification({
        message: 'Falha na requisição',
        type: 'error',
      })
    );
    yield put(Creators.getUpdateStatusFailure('Erro ao buscar na API'));
  }
}

// Individual exports for testing
export default function* orderListSagaAdmin() {
  yield all([
    takeLatest(Types.GET_UPDATE_STATUS_REQUEST, getStatusUpdate),
    takeLatest(Types.GET_REQUEST, getOrder),
    takeLatest(Types.GET_INSERT_REQUEST, getOrderInsert),
    takeLatest(Types.GET_UPDATE_REQUEST, getOrderUpdate),
    takeLatest(Types.GET_DELETE_REQUEST, getOrderDelete),
    takeLatest(Types.GET_LIST_REQUEST, getOrderList),
    takeLatest(Types.GET_LIST_REQUEST_WITHOUT_LOADING, getOrderList),
    //takeLatest(Types.GET_WS_ORDER_REQUEST, WSOpen),
  ]);
}
