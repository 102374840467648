import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Content,
  ImgLogo,
  ImgLogoApp,
  GroupButtons,
  Title,
} from './styles';
import Button from '@material-ui/core/Button';
import { useHistory, useRouteMatch } from 'react-router-dom';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import { Avatar, Badge, Box, Typography } from '@material-ui/core';
import useQuery from 'hooks/useQuery';
import notImage from 'images/Products/not_image.jpg';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { ArrowDropDownCircleOutlined } from '@material-ui/icons';
import { Creators as AuthCreators } from 'store/ducks/auth';
import styled from 'styled-components';
import CustomButton from 'components/form/CustomButton';
import { Creators as ClientCreators } from 'store/ducks/client';

const StyledCustomButton = styled(CustomButton)`
  && {
    &:hover {
      background-color: transparent;
    }
    background-color: white;
    border: 1px solid red;
    padding-top: 9px;
    padding-bottom: 9px;
    color: red;
    min-width: 170px;

    @media (max-width: 900px) {
      margin-bottom: 0;
      margin: 0;
    }
    @media (max-width: 437px) {
    }
  }
`;

const HeaderSimer = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Referências separadas para o dropdown e os botões
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const cartButtonRef = useRef(null);

  // Função para abrir o dropdown
  const handleOpenDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // Função para fechar o dropdown
  const handleCloseDropdown = () => {
    setDropdownOpen(false);
  };
  const history = useHistory();
  // Obtém a URL atual
  const urlAtual = window.location.href;

  // Verifica se a URL contém '/minha-conta'
  const isMinhaContaQueryPresent = urlAtual.includes('/minha-conta');

  let match = useRouteMatch('/catalogs/:catalog');
  const { client } = useSelector(state => state.client);
  const { image } = useSelector(state => state.config.config.shopping);
  const { product: product_item, productLoading: p_loading } = useSelector(
    state => state.product.product
  );

  const isCatalog = match;

  const {
    data: { access_token },
  } = useSelector(state => state.auth);

  const handleGoToHome = () => {
    history.push('/');
  };

  const handleGoToStore = () => {
    history.push(`/lojas/${product_item.store.slug}`);
  };

  const query = useQuery();
  const checkoutCartStore = useSelector(state => state.checkout.cart);
  const totalItems = checkoutCartStore?.list?.reduce(
    (accumulator, item) => accumulator + item.quantity,
    0
  );
  const notUserImg =
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcEBtY77QjjLL6MmlmtA73Eg-uQpRUJ_00xKB4oKIJRA&s';
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(ClientCreators.getClientRequest());
  }, [dispatch]);

  // Função para verificar se o clique ocorreu fora do dropdown e dos botões
  const handleClickOutside = event => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target) &&
      cartButtonRef.current &&
      !cartButtonRef.current.contains(event.target)
    ) {
      setDropdownOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleGoToLogin = () => {
    handleCloseDropdown();
    history.push('/minha-conta');
  };

  const logout = () => {
    handleCloseDropdown();
    dispatch(AuthCreators.getLogoutRequest());
  };

  return (
    <Container>
      <Content>
        {query.get('code_partner') && product_item.length > 0 && !p_loading ? (
          <>
            <ImgLogo
              src={product_item.store.image.sizes.small || notImage}
              onClick={handleGoToStore}
            />
            <Title>{product_item.store.name}</Title>
          </>
        ) : (
          <ImgLogoApp
            data-cy="logo-home"
            src={image.small}
            onClick={handleGoToHome}
          />
        )}
        <>
          {access_token === '' && (
            <>
              {!isMinhaContaQueryPresent && (
                <>
                  {window.innerWidth > 500 ? (
                    <GroupButtons>
                      <div style={{ position: 'relative', marginRight: 10 }}>
                        <div
                          ref={dropdownRef}
                          style={{
                            width: 220,
                            marginRight: 10,
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            height: '45px',
                          }}
                          onClick={handleOpenDropdown}>
                          <Avatar
                            style={{ marginRight: 10 }}
                            alt="Foto de Perfil"
                            src={
                              client && client?.image
                                ? client?.image?.original
                                : notUserImg
                            }
                          />
                          <Typography variant="body1">
                            Olá, faça seu <strong>login</strong> <br />
                            ou <strong>cadastre-se</strong>
                          </Typography>
                          <ArrowDropDownCircleOutlined
                            style={{ marginLeft: 10 }}
                          />
                        </div>

                        {dropdownOpen && (
                          <Box
                            ref={cartButtonRef}
                            sx={{
                              position: 'absolute',
                              top: '100%',
                              right: 0,
                              bgcolor: 'white',
                              border: '1px solid #000',
                              borderRadius: 15,
                              boxShadow: 24,
                              p: 2,
                            }}>
                            <Typography style={{ marginBottom: 10 }}>
                              pra ter uma experiência personalizada, acesse sua
                              conta
                            </Typography>
                            <Button
                              ref={buttonRef}
                              id="fazerLogin"
                              variant="contained"
                              color="primary"
                              style={{ marginRight: '7px', fontSize: 12 }}
                              onClick={() => {
                                handleGoToLogin();
                                handleCloseDropdown();
                              }}>
                              Login
                            </Button>
                            <Button
                              id="cadastrar"
                              style={{ fontSize: 12 }}
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                handleGoToLogin();
                                handleCloseDropdown();
                              }}>
                              Cadastrar
                            </Button>
                          </Box>
                        )}
                      </div>

                      {!!isCatalog && (
                        <Button
                          style={{
                            marginLeft: '20px',
                            backgroundColor: '#f4f4f4',
                          }}
                          onClick={() => history.push('/carrinho')}
                          variant="contained"
                          color="primary">
                          <Badge badgeContent={totalItems} color="primary">
                            <ShoppingCart style={{ color: '#333' }} />
                          </Badge>
                        </Button>
                      )}
                    </GroupButtons>
                  ) : (
                    <GroupButtons>
                      <div style={{ position: 'relative', marginRight: 10 }}>
                        <div
                          ref={dropdownRef}
                          style={{
                            marginRight: 10,
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            height: '45px',
                          }}
                          onClick={handleOpenDropdown}>
                          <Avatar
                            style={{
                              marginRight: 10,
                              height: window.innerWidth > 500 ? 50 : 25,
                              width: window.innerWidth > 500 ? 50 : 25,
                            }}
                            alt="Foto de Perfil"
                            src={
                              client && client?.image
                                ? client?.image?.original
                                : notUserImg
                            }
                          />
                          <Typography style={{ fontSize: 10 }}>
                            Olá, faça seu <strong>login</strong> <br />
                            ou <strong>cadastre-se</strong>
                          </Typography>
                          <ArrowDropDownCircleOutlined
                            style={{ marginLeft: 10 }}
                          />
                        </div>

                        {dropdownOpen && (
                          <Box
                            ref={cartButtonRef}
                            sx={{
                              position: 'absolute',
                              top: '100%',
                              right: 0,
                              bgcolor: 'white',
                              border: '1px solid #000',
                              borderRadius: 15,
                              boxShadow: 24,
                              p: 2,
                            }}>
                            <Typography style={{ marginBottom: 10 }}>
                              pra ter uma experiência personalizada, acesse sua
                              conta
                            </Typography>
                            <Button
                              ref={buttonRef}
                              id="fazerLogin"
                              variant="contained"
                              color="primary"
                              style={{
                                marginRight: '7px',
                                fontSize: 12,
                                marginBottom: window.innerWidth < 500 && 5,
                              }}
                              onClick={() => {
                                handleGoToLogin();
                                handleCloseDropdown();
                              }}>
                              Login
                            </Button>
                            <Button
                              ref={buttonRef}
                              id="cadastrar"
                              style={{ fontSize: 12 }}
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                handleGoToLogin();
                                handleCloseDropdown();
                              }}>
                              Cadastrar
                            </Button>
                          </Box>
                        )}
                      </div>
                    </GroupButtons>
                  )}
                </>
              )}
            </>
          )}
        </>

        {access_token !== '' && (
          <GroupButtons>
            {window.innerWidth > 500 ? (
              <div style={{ position: 'relative', marginRight: 10 }}>
                <div
                  ref={dropdownRef}
                  style={{
                    width: 520,
                    marginRight: 10,
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    height: '45px',
                  }}
                  onClick={handleOpenDropdown}>
                  <Avatar
                    style={{ marginRight: 10 }}
                    alt="Foto de Perfil"
                    src={
                      client && client?.image
                        ? client?.image?.original
                        : notUserImg
                    }
                  />
                  <Typography variant="body1" id="Painel">
                    Olá <strong>{client?.name}</strong>,
                    <br />
                    acesse aqui seu painel
                  </Typography>
                  <ArrowDropDownCircleOutlined style={{ marginLeft: 10 }} />
                </div>

                {dropdownOpen && (
                  <Box
                    ref={cartButtonRef}
                    sx={{
                      position: 'absolute',
                      top: '100%',
                      right: 0,
                      bgcolor: 'white',
                      border: '1px solid #000',
                      borderRadius: 15,
                      boxShadow: 24,
                      p: 2,
                    }}>
                    <Typography style={{ marginBottom: 10 }}>
                      pra ter uma experiência personalizada, acesse seus dados
                      ou faça logout em:
                    </Typography>
                    <Button
                      ref={buttonRef}
                      id="minhaConta"
                      variant="contained"
                      color="primary"
                      style={{
                        marginLeft: '30px',
                        marginRight: '15px',
                        height: 45,
                      }}
                      onClick={handleGoToLogin}>
                      Minha Conta
                    </Button>
                    <StyledCustomButton
                      ref={buttonRef}
                      type="button"
                      id="SairConta"
                      label="Sair da conta"
                      onClick={logout}
                    />
                  </Box>
                )}
              </div>
            ) : (
              <Button
                ref={buttonRef}
                id="minhaConta"
                variant="contained"
                color="primary"
                style={{ marginLeft: '10px', height: 45 }}
                onClick={handleGoToLogin}>
                <DashboardIcon
                  name="Dashboard"
                  style={{ marginRight: 5 }}
                  fontSize="small"
                />
              </Button>
            )}

            <Button
              style={{
                marginLeft: '20px',
                backgroundColor: '#f4f4f4',
                height: 45,
              }}
              data-cy="btn_bdge"
              onClick={() => history.push('/carrinho')}
              variant="contained"
              color="primary">
              <Badge
                data-cy="total_itens_badge"
                badgeContent={totalItems}
                color="primary">
                <ShoppingCart style={{ color: '#333' }} />
              </Badge>
            </Button>
          </GroupButtons>
        )}
      </Content>
    </Container>
  );
};
export default HeaderSimer;
