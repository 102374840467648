import React from 'react';
import { CardContainerLogin } from '../../styles';
import { ContainerRequest } from './styles';
import Button from '@material-ui/core/Button';
import GoogleMapReact from 'google-map-react';
import { key as googleMapsKey } from 'services/maps';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const Map = ({ coords, handleConfirmPosition }) => {
  return (
    <CardContainerLogin>
      <ContainerRequest style={{ position: 'relative' }}>
        <h3>Confirma sua localização?</h3>
        <div style={{ height: '60vh', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: googleMapsKey }}
            defaultCenter={{ lat: coords.latitude, lng: coords.longitude }}
            defaultZoom={15}>
            <AnyReactComponent
              lat={coords.latitude}
              lng={coords.longitude}
              text="My Marker"
            />
          </GoogleMapReact>
        </div>
        <div style={{ position: 'absolute', right: 50, top: 10 }}>
          <Button
            variant="contained"
            color="light"
            style={{ margin: '0px 10px' }}>
            Lista de endereços
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirmPosition}>
            Confirmar
          </Button>
        </div>
      </ContainerRequest>
    </CardContainerLogin>
  );
};

export default Map;
