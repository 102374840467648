/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { call, takeLatest, all, put, select } from 'redux-saga/effects';
import api from 'services/api';
import { Types, Creators } from 'store/ducks/admin/product';
import { callApi } from 'store/sagas/auth';
import { push } from 'connected-react-router';
import { Creators as NotificationCreators } from 'store/ducks/app';

function* getProduct({ payload }) {
  try {
    const { id } = payload;
    const request = call(api.get, `/v1/admin/products/${id}`);
    const response = yield call(callApi, request);
    if (response.status !== 200 || response.status !== 201) throw response;
    yield put(Creators.getProductSuccess(response.data));
  } catch (err) {
    yield put(Creators.getProductFailure('Erro ao buscar na API'));
  }
}

function* getProductImagesUpload(payload) {
  try {
    const { id, images_data, featured_index, product_prototype_id } = payload;

    yield all(
      images_data.map((image, index) => {
        const featured = featured_index === index;

        if (image.isPrototype) {
          if (product_prototype_id) {
            return call(api.post, `/v1/admin/products/${id}/images`, {
              featured,
              product_prototype_id: product_prototype_id,
            });
          }
        } else {
          let data = new FormData();
          data.append('image', image);
          data.append('featured', featured);
          return call(api.post, `/v1/admin/products/${id}/images`, data);
        }
      })
    );

    yield put({ type: 'UPLOAD_SUCCESS' });
  } catch (err) {
    yield put({ type: 'UPLOAD_FAILURE' });
  }
}

function* getProductInsert({ payload }) {
  try {
    const {
      description,
      description_tec,
      featured_index,
      images_data,
      product_prototype_id,
    } = payload;
    const request = call(api.post, '/v1/admin/products', {
      ...payload,
      description: description.toString('markdown'),
      description_tec: description_tec.toString('markdown'),
    });
    const response = yield call(callApi, request);
    if (response.status !== 200 || response.status !== 201) throw response;
    const { id } = response.data.data;

    yield getProductImagesUpload({
      id,
      featured_index,
      images_data,
      product_prototype_id,
    });

    yield put(push(`/minha-conta?aba=produtos`));
    yield put(
      NotificationCreators.openNotification({
        message: 'Cadastro concluido com sucesso',
        type: 'success',
      })
    );
    yield put(Creators.getProductInsertSuccess());
  } catch (err) {
    if (err.status === 409) {
      yield put(
        NotificationCreators.openNotification({
          message: 'Já existe um produto com esse código de barras',
          type: 'error',
        })
      );
    }
    yield put(Creators.getProductInsertFailure('Erro ao buscar na API'));
  }
}

function* getProductUpdate({ payload }) {
  try {
    const request = call(api.put, `/v1/admin/products/${payload.id}`, {
      ...payload,
      description: payload.description.toString('markdown'),
      description_tec: payload.description_tec.toString('markdown'),
    });
    const response = yield call(callApi, request);
    yield getProductImagesUpload({
      id: payload.id,
      images_data: payload.images_data,
      product_prototype_id: payload.product_prototype_id,
    });
    if (response.status !== 200 || response.status !== 201) throw response;
    window.scroll(0, 0);
    yield put(Creators.getProductUpdateSuccess(response.data));
    yield put(Creators.getProductRequest({ id: payload.id }));
    yield put(push(`/minha-conta?aba=produtos`));
    yield put(
      NotificationCreators.openNotification({
        message: 'Produto alterado com sucesso',
        type: 'success',
      })
    );
  } catch (err) {
    if (err.status === 409) {
      yield put(
        NotificationCreators.openNotification({
          message: 'Já existe um produto com esse código de barras',
          type: 'error',
        })
      );
    }
    yield put(Creators.getProductUpdateFailure('Erro ao buscar na API'));
  }
}

function* getProductDelete({ payload }) {
  try {
    const { id } = payload;
    yield call(api.delete, `/v1/admin/products/${id}`);
    yield put(Creators.getProductDeleteSuccess());
    const { productList, productListTotal } = yield select(
      state => state.product
    );

    yield put(
      Creators.getProductListSuccess({
        data: productList.filter(doc => doc.id !== id),
        total: productListTotal - 1,
      })
    );
  } catch (err) {
    yield put(Creators.getProductDeleteFailure('Erro ao buscar na API'));
  }
}

function* getImageProductDelete({ payload }) {
  try {
    const { id, id_product } = payload;
    yield call(api.delete, `/v1/admin/products/${id_product}/images/${id}`);
    yield put(Creators.getImageProductDeleteSuccess());
  } catch (err) {
    yield put(Creators.getImageProductDeleteFailure('Erro ao buscar na API'));
  }
}

function* getProductList({ payload }) {
  try {
    const { page, perPage, search, orderByColumn, orderByDirection } = payload;
    const request = call(api.get, '/v1/admin/products', {
      page,
      search,
      per_page: perPage,
      order: orderByColumn,
      order_by: orderByDirection,
    });

    const response = yield call(callApi, request);
    yield put(Creators.getProductListSuccess(response.data));
  } catch (err) {
    if (err.status === 404) {
      yield put(
        Creators.getProductListSuccess({
          total: 0,
          data: [],
        })
      );
    } else {
      yield put(Creators.getProductListFailure('Erro ao buscar na API'));
    }
    yield put(
      NotificationCreators.openNotification({
        message: err.data.data.Error || err.data.msg,
        type: 'error',
      })
    );
  }
}

// Individual exports for testing
export default function* productListSaga() {
  yield all([
    takeLatest(Types.GET_REQUEST, getProduct),
    takeLatest(Types.GET_INSERT_REQUEST, getProductInsert),
    takeLatest(Types.GET_UPDATE_REQUEST, getProductUpdate),
    takeLatest(Types.GET_DELETE_REQUEST, getProductDelete),
    takeLatest(Types.GET_IMAGE_DELETE_REQUEST, getImageProductDelete),
    takeLatest(Types.GET_LIST_REQUEST, getProductList),
  ]);
}
