import styled from 'styled-components';
import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

export const CardCarrossel = styled(Box)`
  && {
    margin-bottom: 20px;
    display: flex;
    flex: 1;
    height: auto;
    max-height: 750px;
    display: block;
    overflow: hidden;
    background-repeat: no-repeat;
    @media all and (max-width: 500px) {
      /*height: 380px;*/
    }
  }
`;

export const CarrosselContainer = styled(Box)`
  && {
    flex: 1;
    position: relative;
    width: 100%;
    margin-top: 0px;
    overflow: hidden;
    height: 100%;
    max-height: 850px;

    @media all and (max-width: 500px) {
      /* margin-top: 55px; */
    }
  }
`;

export const SkeletonCarousel = styled(Skeleton)`
  && {
    height: 740px;

    @media all and (max-width: 500px) {
      height: 380px;
      margin-top: 55px;
    }
  }
`;

const styles = {
  fab: {
    backgroundColor: '#F48A2A',
    color: 'white',
  },
};

export default styles;
