import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';

const StyledButton = styled(Button)`
  && {
    background-color: #3bbcd4;
    color: #fff;
    opacity: ${props => (props.disabled ? 0.7 : 1)};
    &:hover {
      background-color: #319db1;
    }
  }
`;

const CustomButton = ({ label, onClick, Icon, isLoading, ...rest }) => (
  <StyledButton {...rest} color="primary" variant="text" onClick={onClick}>
    {Icon && <Icon style={{ marginRight: 10 }} />}
    {label}
    {isLoading && (
      <CircularProgress size={18} style={{ color: 'white', marginLeft: 10 }} />
    )}
  </StyledButton>
);

CustomButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  Icon: PropTypes.oneOfType([PropTypes.any, PropTypes.bool]),
  isLoading: PropTypes.bool,
};

CustomButton.defaultProps = {
  Icon: false,
  label: 'Label',
  isLoading: false,
  onClick: () => {},
};

export default CustomButton;
