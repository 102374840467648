import styled from 'styled-components';
import { Box } from '@material-ui/core';
import appColors from 'utils/appColors';

export const SecondaryFooter = styled(Box)`
  && {
    background-color: ${appColors.SECUNDARY_COLOR};
    color: white;
    padding-top: 60px;
    padding-bottom: 60px;

    @media all and (max-width: 500px) {
      padding: 10px;
      padding-top: 20px;
      margin-right: 0px;
    }
  }
`;

export const BoxContainer = styled(Box)`
  && {
    max-width: 1266px;
    display: flex;
    flex: 1;

    @media all and (min-width: 501px) and (max-width: 840px) {
      max-width: 700px;
    }
    @media all and (min-width: 841px) and (max-width: 1024px) {
      max-width: 850px;
    }
  }
`;

const styles = {
  primaryFooter: {
    marginTop: 100,
    backgroundColor: appColors.LIGHT_COLOR,
    color: '#858585',
    padding: 60,
  },

  mainTitle: {
    fontWeight: '600',
    color: 'white',
    fontSize: 22,
  },
  fontSmall: {
    marginTop: 25,
    fontSize: 14,
  },
  fontSmaller: {
    marginTop: 5,
    fontSize: 12,
  },
  columns: {
    marginRight: 40,
    marginBottom: 40,
    flex: 1,
  },
  boxSAC: {
    border: '1px solid #F48A2A',
    borderRadius: 5,
    padding: 15,
    marginTop: 10,
    alignItems: 'center',
  },
  iconSAC: {
    marginRight: 25,
    fontSize: 35,
    color: '#F48A2A',
  },
  fontTellSAC: {
    fontSize: 14,
  },
  imgFlag: {
    width: 50,
    height: 35,
    marginRight: 10,
    marginTop: 10,
  },
  FabSocial: {
    backgroundColor: '#F48A2A',
    color: 'white',
    marginRight: 20,
    marginTop: 10,
  },
  imgStoreIOS: {
    width: 150,
    marginTop: 30,
    marginRight: 20,
    marginLeft: 20,
    cursor: 'pointer',
  },
  imgGooglePlay: {
    width: 150,
    marginTop: 30,
    marginRight: 20,
    marginLeft: 20,
    cursor: 'pointer',
  },
  contentSecure: {
    backgroundColor: '#DEDEDE',
    borderRadius: 5,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 40,
    paddingRight: 40,
    marginTop: 10,
    border: '1px solid grey',
  },
  purpleTitle: {
    color: appColors.SECUNDARY_COLOR,
    fontWeight: 'bold',
    fontSize: 32,
    textAlign: 'center',
  },
  smallSubTitle: {
    textAlign: 'center',
    marginTop: 20,
    color: '#373737',
    fontSize: 14,
  },
};

export default styles;
