import styled from 'styled-components';
import { Box } from '@material-ui/core';
import appColors from 'utils/appColors';

export const ContainerForm = styled(Box)`
  && {
    flex-direction: row;
    margin-bottom: 30px;
    width: 500px;
    float: left;

    @media all and (max-width: 500px) {
      width: 100%;
    }
  }
`;

export const TicketContainer = styled(Box)`
 && {

    @media all and (min-width:501px) and (max-width:1023px){
    min-width:700px;
    }

`;

const styles = {
  purpleTitle: {
    color: appColors.SECUNDARY_COLOR,
    fontWeight: 'bold',
    fontSize: 32,
  },

  purpleTextBold: {
    color: appColors.SECUNDARY_COLOR,
    fontWeight: 'bold',
  },

  iconsAddRemove: {
    marginLeft: 10,
    marginRight: 10,
    color: appColors.SECUNDARY_COLOR,
    cursor: 'pointer',
  },
  titleProduct: {
    color: appColors.SECUNDARY_COLOR,
    fontWeight: 'bold',
    textAlign: 'left',
  },
  fontshipping: {
    color: appColors.SECUNDARY_COLOR,
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  resumeText: {
    borderBottom: '2px solid #FA8F47',
    fontWeight: 'bold',
  },
  totalText: {
    borderBottom: '2px solid #FA8F47',
    fontWeight: 'bold',
    marginLeft: 20,
  },
  smallTextBlackBold: {
    color: '#373737',
    fontWeight: 'bold',
  },
  smallTextBlack: {
    color: '#373737',
    textAlign: 'left',
  },
  bigBoldTextBlack: {
    color: '#373737',
    fontWeight: 'bold',
    fontSize: 22,
  },
  purpleButton: {
    backgroundColor: appColors.PRIMARY_COLOR,
    color: 'white',
    fontSize: 18,
    paddingLeft: 45,
    paddingRight: 45,
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: 43,
  },
  quoteContainer: {
    flex: 1,
    border: `1px solid ${appColors.PRIMARY_COLOR}`,
    padding: 10,
    marginRight: 20,
    marginBottom: 30,
    minWidth: 200,
  },
  quoteContainerSchedule: {
    flex: '0 1 850px',
    border: `1px solid ${appColors.PRIMARY_COLOR}`,
    padding: 10,
    marginRight: 20,
    marginBottom: 30,
    marginTop: 15,
    minWidth: 200,
  },
  cardSchedule: {
    flex: 1,
    border: `1px solid ${appColors.PRIMARY_COLOR}`,
    borderRadius: 6,
    padding: 10,
    marginRight: 20,
    marginBottom: 30,
    minWidth: 200,
    cursor: 'pointer',
  },
  cardScheduleCk: {
    flex: 1,
    border: `1px solid ${appColors.PRIMARY_COLOR}`,
    borderRadius: 6,
    padding: 10,
    marginRight: 20,
    marginBottom: 30,
    minWidth: 200,
    cursor: 'pointer',
    backgroundColor: 'orange',
  },
  purpleTextBoldCk: {
    color: '#fff',
    fontWeight: 'bold',
  },
  cardDaySchedule: {
    flex: 1,
    borderRadius: 6,
    padding: 10,
    marginRight: 20,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 30,
    marginBottom: 30,
    maxWidth: 85,
    cursor: 'pointer',
  },
  dayText: {
    fontWeight: 700,
    fontSize: 20,
  },
  textScheduleCard: {
    color: '#000000',
    fontSize: 16,
  },
  textScheduleCardCk: {
    color: '#fff',
    fontSize: 16,
  },
  moneyScheduleCard: {
    color: 'black',
    fontWeight: 700,
    fontSize: 18,
  },
  moneyScheduleCardCk: {
    color: 'white',
    fontWeight: 700,
    fontSize: 18,
  },
  billetPrice: {
    color: '#373737',
    fontWeight: 'bold',
    fontSize: 28,
  },
};

export default styles;
