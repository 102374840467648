import React from 'react';
import { Box, Typography } from '@material-ui/core';
import styles, { Container404 } from './style';
import appNaming from 'utils/appNaming';
import { useSelector } from 'react-redux';
import { Center } from '@chakra-ui/react';

const Page404 = () => {
  const { image } = useSelector(state => state.config.config.shopping);

  return (
    <Center>
      <Container404 display="flex" justifyContent="center" alignItems="center">
        <Box
          style={{ height: 700 }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column">
          <Typography style={styles.styleText}>
            Página não encontrada
          </Typography>
          {image && image.small && (
            <img
              width={300}
              src={image.small}
              style={{ marginTop: 20 }}
              alt={appNaming.REGULAR_NAMING}
            />
          )}
        </Box>
      </Container404>
    </Center>
  );
};

export default Page404;
