import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PersonIcon from '@material-ui/icons/Person';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import InputIcon from '@material-ui/icons/Input';
import { Typography, Box } from '@material-ui/core';
import { Creators as AuthCreators } from 'store/ducks/auth';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import appColors from 'utils/appColors';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: `${appColors.LIGHT_COLOR}`,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function ResellerToogleMenu({ anchorEl, handleClose }) {
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(AuthCreators.getLogoutRequest());
    handleClose();
  };

  return (
    <div>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <Link
          to="/minha-conta?aba=dashboard"
          onClick={handleClose}
          style={{ textDecoration: 'none', color: 'inherit' }}>
          <StyledMenuItem>
            <Box color="#666666" display="flex" alignItems="center">
              <DashboardIcon
                name="Dashboard"
                style={{ marginRight: 5 }}
                fontSize="small"
              />
              <Typography>Dashboard</Typography>
            </Box>
          </StyledMenuItem>
        </Link>
        <Link
          to="/minha-conta?aba=vendas"
          onClick={handleClose}
          style={{ textDecoration: 'none', color: 'inherit' }}>
          <StyledMenuItem>
            <Box color="#666666" display="flex" alignItems="center">
              <ShoppingBasketIcon style={{ marginRight: 5 }} fontSize="small" />
              <Typography>Minhas Compras</Typography>
            </Box>
          </StyledMenuItem>
        </Link>

        <Link
          to="/minha-conta?aba=relatorio-comissoes"
          onClick={handleClose}
          style={{ textDecoration: 'none', color: 'inherit' }}>
          <StyledMenuItem>
            <Box color="#666666" display="flex" alignItems="center">
              <AttachMoneyIcon style={{ marginRight: 5 }} fontSize="small" />
              <Typography>Relatório de comissões</Typography>
            </Box>
          </StyledMenuItem>
        </Link>
        <Link
          to="/minha-conta?aba=antecipacao"
          onClick={handleClose}
          style={{ textDecoration: 'none', color: 'inherit' }}>
          <StyledMenuItem>
            <Box color="#666666" display="flex" alignItems="center">
              <AttachMoneyIcon style={{ marginRight: 5 }} fontSize="small" />
              <Typography>Antecipação</Typography>
            </Box>
          </StyledMenuItem>
        </Link>
        <Link
          to="/minha-conta?aba=cash_express"
          onClick={handleClose}
          style={{ textDecoration: 'none', color: 'inherit' }}>
          <StyledMenuItem>
            <Box color="#666666" display="flex" alignItems="center">
              <AttachMoneyIcon style={{ marginRight: 5 }} fontSize="small" />
              <Typography>Sacar Dinheiro</Typography>
            </Box>
          </StyledMenuItem>
        </Link>
        <Link
          to="/minha-conta?aba=tranferencia"
          onClick={handleClose}
          style={{ textDecoration: 'none', color: 'inherit' }}>
          <StyledMenuItem>
            <Box color="#666666" display="flex" alignItems="center">
              <AttachMoneyIcon style={{ marginRight: 5 }} fontSize="small" />
              <Typography>Transferir Dinheiro</Typography>
            </Box>
          </StyledMenuItem>
        </Link>
        <Link
          to="/minha-conta?aba=conta"
          onClick={handleClose}
          style={{ textDecoration: 'none', color: 'inherit' }}>
          <StyledMenuItem>
            <Box color="#666666" display="flex" alignItems="center">
              <PersonIcon style={{ marginRight: 5 }} fontSize="small" />
              <Typography>Meu Perfil</Typography>
            </Box>
          </StyledMenuItem>
        </Link>

        <StyledMenuItem onClick={logout}>
          <Box color="#666666" display="flex" alignItems="center">
            <InputIcon style={{ marginRight: 5 }} fontSize="small" />
            <Typography>Sair</Typography>
          </Box>
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
