/* eslint-disable react/display-name */
import React from 'react';
// import PageBase from 'components/PageBase';
import DefaultTable from 'components/Tables/DefaultTable';
import HeaderComponent from 'components/HeaderComponent';
import CollaboratorTableHeader from './InstallmentsTableHeader';
import CollaboratorActions from './InstallmentsActions';
import { toPrice, formatDate2 } from 'utils/converters';
import { useInvoice } from 'hooks/invoice';
import {
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import useUser from 'hooks/user';
import { Box, Stack } from '@chakra-ui/react';
import StatisticItemPanelDashboard from 'components/StatisticItemPanelDashboard';
function renderSwitch(param) {
  switch (param) {
    case 'WAITING_APPROVAL':
      return 'Aguardando Aprovação';
    case 'PENDING':
      return 'Pendente';
    case 'BANK_PROCESSING':
      return 'Em processamento bancário';
    case 'DONE':
      return 'Efetivada';
    case 'CANCELLED':
      return 'Cancelada';
    case 'RECEIVED':
      return 'Recebida';
    case 'FAILED':
      return 'Falha na transferência. Normalmente ocorre em caso de dados bancários inválidos';
    default:
      return 'Cancelada';
  }
}
const columns = () => [
  {
    title: 'ID',
    field: 'id',
    render: rowData => <span>{rowData.id}</span>,
  },
  {
    title: 'Cliente',
    field: 'client',
    render: rowData => <span>{rowData.client.name}</span>,
  },
  {
    title: 'Status',
    field: 'status',
    render: rowData => <span>{renderSwitch(rowData.status)}</span>,
  },
  {
    title: 'Cashback',
    field: 'commission_cashback',
    render: rowData => (
      <span>R$ {toPrice(rowData.comissions?.commission_cashback)}</span>
    ),
  },
  {
    title: 'C. Total',
    field: 'commission',
    render: rowData => (
      <span>R$ {toPrice(rowData.comissions?.commission)}</span>
    ),
  },
  {
    title: 'C. Financeira',
    field: 'commission_financial',
    render: rowData => (
      <span>R$ {toPrice(rowData.comissions?.commission_financial)}</span>
    ),
  },
  {
    title: 'C. Loja',
    field: 'commission_shop',
    render: rowData => (
      <span>R$ {toPrice(rowData.comissions?.commission_shop)}</span>
    ),
  },
  {
    title: 'Split Fixo ($)',
    field: 'split_fixed',
    render: rowData => (
      <span>
        {rowData.value_fixed === null && rowData.value_fixed_edit === null
          ? 'Realizado em %'
          : rowData.value_fixed !== null
          ? `R$ ${toPrice(rowData.value_fixed)}`
          : `R$ ${toPrice(rowData.value_fixed_edit)}`}
      </span>
    ),
  },
  {
    title: 'Split Percentual (%)',
    field: 'split_percent',
    render: rowData => (
      <span>
        {rowData?.value_percentage === null &&
        rowData?.value_percentage_edit === null
          ? 'Realizado em $'
          : rowData?.value_percentage !== null
          ? `${rowData?.value_percentage} %`
          : `${rowData?.value_percentage_edit} %`}
      </span>
    ),
  },
  {
    title: 'Preço Total',
    field: 'total_value',
    render: rowData => <span>R$ {toPrice(rowData?.total_value)}</span>,
  },
  {
    title: 'Parcelas',
    field: 'installment_count',
    render: rowData => <span>{rowData?.installment_count}</span>,
  },
  {
    title: 'Descrição',
    field: 'description',
    render: rowData => <span>{rowData?.description}</span>,
  },
  {
    title: 'Vencimento',
    field: 'dueDate',
    render: rowData => <span>{formatDate2(rowData?.due_date)}</span>,
  },
  {
    title: 'Boletos',
    // eslint-disable-next-line react/display-name
    render: rowData => <CollaboratorActions rowData={rowData} />,
  },
];

const InstallmentsListPage = () => {
  const {
    invoiceData,
    invoiceSum,
    invoiceLoading,
    GetInvoice,
    invoiceListTotal,
  } = useInvoice();

  const [TranferState, setTranferState] = React.useState({
    open: false,
    item: {},
  });
  const [localState, setLocalState] = React.useState({
    page: 1,
    perPage: 10,
    status: '',
  });
  const { PayParttheTicketRec } = useUser();
  const onSubmit = form => {
    const model_dict = {
      value: form.amount,
      password: form.password,
      payment_id: TranferState.item.payment_id_asaas,
    };
    PayParttheTicketRec(model_dict);
  };

  console.log(onSubmit);

  const [selectedStatus, setSelectedStatus] = React.useState('');

  const handleStatusChange = event => {
    setSelectedStatus(event.target.value);
  };

  React.useEffect(() => {
    GetInvoice({ ...localState, status: selectedStatus });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState, selectedStatus]);

  const getFunction = data => {
    setLocalState(oldLocalState => ({ ...oldLocalState, ...data }));
  };

  const onTranferRequest = item => {
    setTranferState({ open: true, item });
  };

  return (
    <Box flex="1" maxW="70vw">
      <Stack mt="20px" mb="20px" direction={['column', 'row']} spacing="20px">
        <StatisticItemPanelDashboard
          icon="attach_money"
          title="VALOR TOTAL COBRANÇAS"
          description={
            invoiceSum?.sum_value
              ? `R$ ${toPrice(invoiceSum?.sum_value)}`
              : `R$ 0`
          }
          descriptionButton="Este é o valor total estimado das cobranças"
        />
        <StatisticItemPanelDashboard
          icon="attach_money"
          title="VALOR TOTAL CASHBACK"
          description={
            invoiceSum?.sum_cashback
              ? `R$ ${toPrice(invoiceSum?.sum_cashback)}`
              : `R$ 0`
          }
          descriptionButton="Este é o valor total estimado dos cashbacks"
        />
        <StatisticItemPanelDashboard
          icon="attach_money"
          title="VALOR TOTAL COMISSÃO"
          description={
            invoiceSum?.sum_commission
              ? `R$ ${toPrice(invoiceSum?.sum_commission)}`
              : `R$ 0`
          }
          descriptionButton="Este é o valor total estimado das comissões"
        />
        <StatisticItemPanelDashboard
          icon="attach_money"
          title="VALOR TOTAL FINANCEIRO"
          description={
            invoiceSum?.sum_financial
              ? `R$ ${toPrice(invoiceSum?.sum_financial)}`
              : `R$ 0`
          }
          descriptionButton="Este é o valor total estimado do financeiro"
        />
        <StatisticItemPanelDashboard
          icon="attach_money"
          title="VALOR TOTAL MARKETPLACE"
          description={
            invoiceSum?.sum_shop
              ? `R$ ${toPrice(invoiceSum?.sum_shop)}`
              : `R$ 0`
          }
          descriptionButton="Este é o valor total estimado do marketplace"
        />
      </Stack>
      <HeaderComponent title="Listar Cobranças feitas">
        <CollaboratorTableHeader
          getFunction={getFunction}
          initialValues={{ search: localState.search }}
        />
        {/* Filtro de seleção de status */}
        <FormControl>
          <InputLabel>Status</InputLabel>
          <Select
            fullWidth
            value={selectedStatus}
            onChange={handleStatusChange}>
            <MenuItem value="">Todos</MenuItem>
            <MenuItem value="WAITING_APPROVAL">Pendentes</MenuItem>
            {/* Adicione mais opções de status conforme necessário */}
          </Select>
        </FormControl>
        <CollaboratorTableHeader
          getFunction={getFunction}
          initialValues={{ search: localState.search }}
        />
      </HeaderComponent>
      <Paper>
        <DefaultTable
          getFunction={getFunction}
          columns={columns({ onTranferRequest, invoiceData })}
          data={invoiceData}
          total={invoiceListTotal}
          isLoading={invoiceLoading}
          page={localState.page}
          perPage={localState.perPage}
        />
      </Paper>
    </Box>
  );
};

export default InstallmentsListPage;
