import appColors from 'utils/appColors';
import styled from 'styled-components';

export const Container = styled.div`
  @media(max-width: 500px){
    display:none
  }
`

const styles = {
	purpleTitle: {
		color: appColors.SECUNDARY_COLOR,
		fontWeight: 'bold',
		fontSize: 32,
		padding: 1,
		marginTop: 23
	},
	links: {
		textDecoration: 'none',
		color: appColors.PRIMARY_COLOR,
		cursor: 'pointer'
	},
	primary: {
		fontWeight: 900,
		color: appColors.PRIMARY_COLOR
	}
};

export default styles;
