import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { Title, Logo, Grid, Prosseguir } from './styles';

import { Stack, Box, Text } from '@chakra-ui/react';
import appColors from 'utils/appColors';

function SegmentsList({ onSubmit, segments, initialValues }) {
  const [selected, setSelected] = useState(initialValues || []);

  const handleSelect = id => {
    if (selected.includes(id)) {
      setSelected(list => list.filter(ids => ids !== id));
    } else setSelected(m => [...m, id]);
  };

  return (
    <Stack w="full">
      <Title style={{ marginBottom: 20, opacity: 0.7 }}>
        Selecione o segmento adequado para seu negócio
      </Title>

      <Grid>
        {segments.map(item => {
          const active = selected.includes(item.id);
          return (
            <Stack
              alignItems="center"
              p="8px"
              mr="10px"
              mb="10px"
              w={['full', '200px']}
              bg="#f3f3f3"
              data-cy="icon_segment"
              onClick={() => handleSelect(item.id)}
              border={
                active
                  ? `3px solid ${appColors.PRIMARY_COLOR}`
                  : '3px solid transparent'
              }
              key={item.id}>
              <Logo src={item.image.medium} />
              <Text data-cy="name_segment">{item.name}</Text>
            </Stack>
          );
        })}
      </Grid>

      <Stack
        w="full"
        pt="30px"
        direction="row"
        justify="space-between"
        align="center">
        <Box flex="1">
          {selected.length === 0 && (
            <Typography style={{ color: 'red', marginTop: 10 }}>
              * Você precisa selecionar um ou mais segmentos
            </Typography>
          )}
        </Box>
        <Stack flex="1" align="flex-end">
          <Prosseguir
            id="btnProsseguir"
            disabled={selected.length === 0}
            onClick={() => onSubmit(selected)}
            type="submit">
            Prosseguir
          </Prosseguir>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default SegmentsList;
