// Obtem Lojas por id (code_internal)
export const Types = {
  GET_PRODUCTS_BY_STORE_REQUEST: 'stores/GET_PRODUCTS_BY_STORE_REQUEST',
  GET_PRODUCTS_BY_STORE_SUCCESS: 'stores/GET_PRODUCTS_BY_STORE_SUCCESS',
  GET_PRODUCTS_BY_STORE_FAILURE: 'stores/GET_PRODUCTS_BY_STORE_FAILURE',
};

export const initialState = {

  productsByStore: [],
  productsByStoreLoading: false,
  productsByStoreTotal: 0,
  productsByStoreError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_PRODUCTS_BY_STORE_REQUEST:
      return {
        ...state,
        productsByStoreError: null,
        productsByStoreLoading: true,
      };
    case Types.GET_PRODUCTS_BY_STORE_SUCCESS:
      return {
        ...state,
        productsByStore: action.payload.data,
        productsByStoreTotal: action.payload.total,
        productsByStoreLoading: false,
        productsByStoreError: null,
      };
    case Types.GET_PRODUCTS_BY_STORE_FAILURE:
      return {
        ...state,
        productsByStoreLoading: false,
        productsByStoreError: action.payload,
      };
    default:
      return state;
  }
};

export const Creators = {
  getProductsByStoreRequest: ({ page, per_page, search, orderByColumn, orderByDirection, order, price_min, price_max }, slug ) => ({
    type: Types.GET_PRODUCTS_BY_STORE_REQUEST,
    payload: { page, per_page, search, orderByColumn, orderByDirection, order, price_min, price_max },
    slug,
  }),
  getProductsByStoreSuccess: ({ data, total }) => ({
    type: Types.GET_PRODUCTS_BY_STORE_SUCCESS,
    payload: { data, total },
  }),
  getProductsByStoreFailure: error => ({
    type: Types.GET_PRODUCTS_BY_STORE_FAILURE,
    payload: error,
  }),
};
