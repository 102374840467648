/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PageBase from 'components/PageBase';
import DefaultTable from 'components/Tables/DefaultTable';
import { Paper } from '@material-ui/core';
import HeaderComponent from 'components/HeaderComponent';
import CollaboratorTableHeader from './InstallmentsTableHeader';
import { toPrice, formatDate2 } from 'utils/converters';
import { useInvoice } from 'hooks/invoice';
import { withRouter } from 'react-router-dom';
import useQuery from 'hooks/useQuery';
import CollaboratorActions from './tableActions';
import AlertDialog from 'components/AlertDialog';

import useUser from 'hooks/user';

function renderSwitch(param) {
  switch (param) {
    case 'WAITING_APPROVAL':
      return 'Aguardando Aprovação';
    case 'PENDING':
      return 'Aguardando pagamento';
    case 'RECEIVED':
      return 'Recebida';
    case 'CONFIRMED':
      return 'Pagamento confirmado (saldo ainda não creditado)';
    case 'OVERDUE':
      return 'Vencida';
    case 'REFUNDED':
      return 'Estornada';
    case 'RECEIVED_IN_CASH':
      return 'Recebida em dinheiro (não gera saldo na conta)';
    case 'REFUND_REQUESTED':
      return 'Estorno Solicitado';
    case 'CHARGEBACK_REQUESTED':
      return 'Recebido chargeback';
    case 'CHARGEBACK_DISPUTE':
      return 'Em disputa de chargeback (caso sejam apresentados documentos para contestação)';
    case 'AWAITING_CHARGEBACK_REVERSAL':
      return 'Disputa vencida, aguardando repasse da adquirente';
    case 'DUNNING_REQUESTED':
      return 'Em processo de negativação';
    case 'DUNNING_REICEIVED':
      return 'Recuperada';
    case 'AWAITING_RISK_ANALYSIS':
      return 'Pagamento em análise';
    case 'CANCELED_BY_SELLER':
      return 'Cancelada';
    case 'FAILED':
      return 'Falha na transferência. Normalmente ocorre em caso de dados bancários inválidos';
    default:
      return 'Cancelada';
  }
}
const columns = ({ onDeleteRequest, onTranferRequest, InstallmentsList }) => [
  {
    title: 'ID',
    field: 'id',
    render: rowData => <span>{rowData.id}</span>,
  },
  // {
  //   title: 'Cliente',
  //   field: 'client',
  //   render: rowData => <span>{rowData.client.name}</span>,
  // },
  {
    title: 'Status',
    field: 'status',
    render: rowData => <span>{renderSwitch(rowData.status)}</span>,
  },
  {
    title: 'Preço',
    field: 'installment_value',
    render: rowData => <span>R$ {toPrice(rowData.installment_value)}</span>,
  },
  {
    title: 'Preço Total',
    field: 'total_value',
    render: rowData => <span>R$ {toPrice(rowData.installment_value)}</span>,
  },
  {
    title: 'Número da parcela',
    field: 'installment_count',
    render: rowData => <span>{rowData.installment_step}</span>,
  },
  {
    title: 'Descrição',
    field: 'description',
    render: rowData => <span>{rowData.description}</span>,
  },
  {
    title: 'Vencimento',
    field: 'dueDate',
    render: rowData => <span>{formatDate2(rowData.due_date)}</span>,
  },
  {
    title: 'Deletado',
    field: 'deleted_by_seller',
    render: rowData => (
      <span>
        {rowData?.deleted_by_seller?.name
          ? `Deletado por ${rowData?.deleted_by_seller?.name}`
          : 'Não foi deletado'}
      </span>
    ),
  },
  {
    title: 'Ações',
    // eslint-disable-next-line react/display-name
    render: rowData => (
      <CollaboratorActions
        rowData={rowData}
        onDeleteRequest={onDeleteRequest}
      />
    ),
  },
];

const SetofPlotsPage = () => {
  const {
    invoiceDetailData,
    invoiceDetailTotal,
    invoiceDetailLoading,
    GetInvoiceDetail,
    DeleteChargesReq,
    DeleteCharges,
    DeleteChargesLoading,
  } = useInvoice();
  const query = useQuery();
  const Plots_id = query.get('Plots_id');
  const tittle = `Listar parcelas da cobrança de id ${Plots_id}`;
  const [TranferState, setTranferState] = React.useState({
    open: false,
    item: {},
  });
  const [localState, setLocalState] = React.useState({
    page: 1,
    perPage: 10,
  });
  const [deleteState, setDeleteState] = React.useState({
    open: false,
    item: {},
  });
  const onDeleteRequest = item => {
    setDeleteState({ open: true, item });
  };
  const handleAlertDialogClose = () => {
    setDeleteState({ open: false, item: {} });
  };
  const onDeleteConfirm = () => {
    DeleteChargesReq(deleteState.item, Plots_id);
  };

  const getFunction = data => {
    setLocalState(oldLocalState => ({ ...oldLocalState, ...data }));
  };

  React.useEffect(() => {
    if (Plots_id) {
      GetInvoiceDetail(Plots_id, localState);
    }
  }, [localState]);

  const onTranferRequest = item => {
    setTranferState({ open: true, item });
  };
  const handleModalClose = () => {
    setTranferState({ open: false, item: {} });
  };
  return (
    <PageBase>
      <HeaderComponent title={tittle}>
        <CollaboratorTableHeader
          getFunction={getFunction}
          initialValues={{ search: localState.search }}
        />
      </HeaderComponent>
      <Paper>
        <DefaultTable
          getFunction={getFunction}
          columns={columns({
            onTranferRequest,
            invoiceDetailData,
            onDeleteRequest,
          })}
          data={invoiceDetailData}
          total={invoiceDetailTotal}
          isLoading={invoiceDetailLoading}
          page={localState.page}
          perPage={localState.perPage}
        />
      </Paper>
      <AlertDialog
        isOpen={deleteState.open}
        isLoading={DeleteChargesLoading}
        handleClose={handleAlertDialogClose}
        onConfirm={onDeleteConfirm}
        title="Excluir registro?"
        description={`Remover Cobrança de id ${deleteState.item.id}`}
      />
    </PageBase>
  );
};

export default withRouter(SetofPlotsPage);
