import { validateBr } from 'js-brasil';

const validators = {
  numberNotRequired: msg => [
    'numberNotRequired',
    msg,
    val => (val ? String(val).match(/^([\d]+)?$/g) : true),
  ],
  cpfInvalid: msg => [
    'cpf',
    msg,
    val => {
      if (val === undefined) return true;
      if (val === '') return true;
      if (val === '___.___.___-__') return true;
      return validateBr.cpf(val);
    },
  ],
  cnpjInvalid: msg => [
    'cnpj',
    msg,
    val => {
      if (val === undefined) return true;
      if (val === '') return true;
      return validateBr.cnpj(val);
    },
  ],
  uuidInvalid: msg => [
    'uuid',
    msg,
    val =>
      val
        ? String(val).match(
            /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/i
          )
        : true,
  ],
};

export default validators;
