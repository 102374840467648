import React, { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import { schema, initialValues, getErrorStep } from './components/validation';
import RichTextEditor from 'react-rte';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { ViewStep, ErrorView, Footer } from './styles';
import { Error } from '@material-ui/icons';
import { useSelector } from 'react-redux';

import { Stack, Center, useMediaQuery, Text, Box } from '@chakra-ui/react';

// COMPONENTS
import Details from './components/details';
import Description from './components/description';
import Images from './components/images';
import Variations from './components/variations';
import Categories from './components/categories';
import Related from './components/related';
import useQuery from 'hooks/useQuery';
import appColors from 'utils/appColors';
import useProduct from 'hooks/products';

const NewProduct = () => {
  const { getAdminProduct, createProduct, updateProduct, product, loading } =
    useProduct();

  const [desktop] = useMediaQuery('(min-width: 475px)');
  const query = useQuery();
  const {
    client: { client },
  } = useSelector(state => state);
  initialValues.store = client.store;

  const formRef = useRef();
  const [activeStep, setActiveStep] = React.useState(0);
  const [productLocal, setProductLocal] = useState();
  const isUpdate = query.get('id_produto') !== null;

  useEffect(() => {
    if (isUpdate) {
      getAdminProduct(query.get('id_produto'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      product !== null &&
      product !== undefined &&
      Object.keys(product).length > 0 &&
      !loading &&
      product.id
    ) {
      setProductLocal({
        ...product,
        description: RichTextEditor.createValueFromString(
          product.description,
          'markdown'
        ),
        description_tec: RichTextEditor.createValueFromString(
          product.description_tec,
          'markdown'
        ),
        category_father: product.categories,
        images_info: product.images,
        images: '',
        price: product.price,
        images_data: [],
      });
    }
  }, [product, loading]);

  const steps = {
    0: 'Produto',
    1: 'Variações',
    2: 'Imagens',
    3: 'Categoria',
    4: 'Descrição',
    5: 'Produtos Relacionados',
  };

  const RenderSteps = {
    0: Details,
    1: Variations,
    2: Images,
    3: Categories,
    4: Description,
    5: Related,
  }[activeStep];

  const next = () => {
    formRef.current.validateForm();
    if (activeStep === Object.keys(steps).length - 1) {
      if (formRef.current) {
        formRef.current.executeSubmit();
      }
      return true;
    } else return false;
  };

  const onSubmit = data => {
    setProductLocal(data);
    if (isUpdate) updateProduct(data);
    else createProduct(data);
  };

  const handleConcluse = async () => {
    const errors = await formRef.current.validateForm();
    const valid = await getErrorStep(errors, activeStep, steps);
    const isNext = next();
    if (!isNext)
      if (!valid) {
        setActiveStep(m => m + 1);
        window.scrollTo(0, 80);
      }
  };

  console.log(productLocal);

  return (
    <Stack w="full">
      {loading ? (
        <Center p="130px">
          <CircularProgress size={30} />
        </Center>
      ) : (
        <Box>
          <Formik
            ref={formRef}
            initialValues={{ ...initialValues, ...productLocal }}
            validationSchema={schema}
            enableReinitialize
            onSubmit={onSubmit}
            render={props => (
              <Stack>
                {desktop ? (
                  <Stepper activeStep={activeStep} alternativeLabel>
                    {Object.values(steps).map((label, index) => (
                      <Step
                        key={index}
                        style={{ cursor: 'pointer' }}
                        onClick={() => !!isUpdate && setActiveStep(index)}>
                        <StepLabel style={{ fontSize: 6 }}>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                ) : (
                  <Stack
                    direction="row"
                    wrap="wrap"
                    justify="center"
                    spacing="25px">
                    {Object.values(steps).map((label, index) => {
                      const ac = activeStep === index;
                      return (
                        <Stack
                          p="14px 0"
                          key={String(index)}
                          align="center"
                          justify="center">
                          <Box
                            w="22px"
                            h="22px"
                            textAlign="center"
                            color={ac ? 'white' : '#777'}
                            borderRadius="40px"
                            bg={ac ? appColors.PRIMARY_COLOR : '#ddd'}>
                            {index + 1}
                          </Box>
                          <Text
                            fontSize="16px"
                            color={ac ? appColors.PRIMARY_COLOR : '#7777'}
                            fontWeight={ac ? 'bold' : '500'}>
                            {label}
                          </Text>
                        </Stack>
                      );
                    })}
                  </Stack>
                )}

                <RenderSteps {...props} />

                <Footer>
                  {!!getErrorStep(props.errors, activeStep, steps) && (
                    <ErrorView>
                      <Error style={{ marginRight: 20, color: '#fff' }} />
                      <Typography style={{ color: '#fff' }}>
                        Preencha todos os campo obrigatórios
                      </Typography>
                    </ErrorView>
                  )}

                  <ViewStep>
                    <Button
                      disabled={activeStep === 0}
                      id="voltar"
                      onClick={() => setActiveStep(m => m - 1)}>
                      Voltar
                    </Button>

                    <Button
                      disabled={
                        getErrorStep(props.errors, activeStep, steps) ||
                        !!loading ||
                        (activeStep === Object.keys(steps).length - 1 &&
                          !!isUpdate)
                      }
                      variant="contained"
                      id="Avancar"
                      color="primary"
                      style={{ marginLeft: 10 }}
                      onClick={handleConcluse}>
                      {!!loading &&
                        activeStep === Object.keys(steps).length - 1 && (
                          <CircularProgress
                            size={12}
                            style={{ marginRight: 10 }}
                          />
                        )}
                      {activeStep === Object.keys(steps).length - 1
                        ? 'Concluir'
                        : 'Avançar'}
                    </Button>

                    {!!isUpdate && (
                      <Button
                        disabled={
                          getErrorStep(props.errors, activeStep, steps) ||
                          !!loading ||
                          (activeStep === Object.keys(steps).length - 1 &&
                            !!isUpdate)
                        }
                        style={{
                          marginLeft: 10,
                          backgroundColor: '#607d8b',
                        }}
                        data-cy="btn_salve_product"
                        variant="contained"
                        color="primary"
                        onClick={props.submitForm}>
                        {!!loading && (
                          <CircularProgress
                            size={12}
                            style={{ marginRight: 10 }}
                          />
                        )}
                        Salvar
                      </Button>
                    )}
                  </ViewStep>
                </Footer>
              </Stack>
            )}
          />
        </Box>
      )}
    </Stack>
  );
};

export default NewProduct;
