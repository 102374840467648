import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import styles, {
  CardCarrossel,
  StyleGlassMagnifier,
  ImgMobile,
} from './styles';

const CarrosselImages = ({ images, handleImage, thumbSelect }) => {
  const theme = useTheme();
  const [totalLoad, setTotalLoad] = React.useState(0);

  function handleStepChange(step) {
    handleImage(step);
  }

  return (
    <SwipeableViews
      axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
      index={thumbSelect}
      onChangeIndex={handleStepChange}
      enableMouseEvents>
      {images.map((step, index) => {
        var getYoutubeID = function (url) {
          if (url) {
            var code = url.match(
              /youtu(?:.*\/v\/|.*v\\=|\.be\/)([A-Za-z0-9_\\-]{11})/
            );
            return typeof code[1] === 'string' ? code[1] : false;
          } else return '';
        };
        return (
          <div key={index} style={{ height: '100%' }}>
            {Math.abs(thumbSelect - index) <= 2 ? (
              <>
                {step.video && (
                  <div
                    style={{
                      height: '100%',
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <iframe
                      title={index}
                      width="100%"
                      height="100%"
                      src={`https://www.youtube.com/embed/${getYoutubeID(
                        step.link
                      )}`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen></iframe>
                  </div>
                )}
                {step.link !== null && !step.video && (
                  <CardCarrossel key={step.label} style={styles.img}>
                    <StyleGlassMagnifier
                      style={{ height: '100%', objectFit: 'cover' }}
                      zoomEndTimeout={0}
                      imageSrc={step.sizes.original}
                      magnifierSize={'200%'}
                      square={true}
                      largeImageSrc={step.sizes.original}
                    />
                    <ImgMobile
                      onLoad={() => {
                        setTotalLoad(totalLoad + 1);
                      }}
                      src={step.sizes.original}
                    />
                  </CardCarrossel>
                )}
                {step.link === null && (
                  <CardCarrossel
                    key={step.label}
                    style={styles.img}
                    source={step.sizes.original}
                  />
                )}
              </>
            ) : null}
          </div>
        );
      })}
    </SwipeableViews>
  );
};

CarrosselImages.propTypes = {
  images: PropTypes.array.isRequired,
};

export default CarrosselImages;
