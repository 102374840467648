import styled from 'styled-components';
import { Box, Card } from '@material-ui/core';
import appColors from 'utils/appColors';

export const LoginContainer = styled(Box)`
&&{
  width:1266px;
  margin-top:60px;

  @media all and (max-width: 500px) {
    margin-top:80px;
    width:90%;
  }
  @media all and (min-width:501px) and (max-width: 920px) {
    width:96%;
  }
  @media all and (min-width:921px) and (max-width:1740px) {
    width:90%;
  }

}
`;

export const CardContainerLogin = styled(Card)`
  padding-top:40px;
  padding-bottom:40px;
  margin-bottom:40px;
  flex:1;
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
`;


const styles = {
  purpleTitle: {
    color:appColors.SECUNDARY_COLOR,
    fontSize:22
  }

}

export default styles;