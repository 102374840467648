import React from 'react';
import styles from './styles';
import { Box, Typography, Icon } from '@material-ui/core';

const ConfigMessage = ({data}) => {
  return (
    <Box display='flex' flexDirection='space-arround' height={100} padding={5} marginBottom={5} style={styles.container}>
      <Box>
      <Icon style={styles.icon} paddingRight={20}>info</Icon>
      </Box>
      <Box display='flex'>
      <Typography style={styles.texto}>
        {' '}
        {data || `Em razão da pandemia causada pelo COVID-19, não é permitida a
        entrega/retirada de produtos entre "domingo" e "segunda-feiras". Os
        pedidos realizados nesse período serão entregues na "terça-feira.`}{' '}
      </Typography>
      </Box>
    </Box>
  );
};

export default ConfigMessage;
