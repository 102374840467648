import Notifications from 'react-notification-system-redux';
import { call, takeLatest, all, put, select } from 'redux-saga/effects';
import api from 'services/api';

import { Types, Creators } from 'store/ducks/collaborator';
import { Creators as NotificationCreators } from 'store/ducks/app';
import { Creators as ToastCreators } from 'store/ducks/toast';
import { callApi } from 'store/sagas/auth';

function* getCollaboratorInsert({ payload }) {
  try {
    const {
      name,
      phone,
      email,
      address,
      password,
      cpf,
      genre,
      role,
      status,
      birth_date,
    } = payload;
    const response = yield call(
      api.post,
      '/v1/admin/business/partner/collaborator/Site',
      {
        name,
        phone,
        email,
        role,
        status,
        address,
        password,
        cpf,
        genre,
        birth_date,
      }
    );

    if (response.status !== 201) {
      const errorMessage = response.data.msg?.Error || 'Algo deu errado!';
      throw new Error(errorMessage);
    }

    yield put(Creators.getColllaboratorInsertSuccess(response.data.data));
    yield put(
      NotificationCreators.openNotification({
        message: 'Parceiro Criado com succeso',
        type: 'success',
      })
    );
  } catch (err) {
    yield put(
      Creators.getColllaboratorInsertFailure('Erro ao cadastrar colaborador')
    );
    console.log(err);
    yield put(
      NotificationCreators.openNotification({
        message: 'Já existe um usuário com o email ou telefone cadastrado',
        type: 'error',
      })
    );
  }
}
// CollaboratorCSV

function* getCollaboratorCsvInsert({ payload }) {
  try {
    const { file, partner_id } = payload;
    const form = new FormData();
    form.append('file', file);

    const response = yield call(
      api.post,
      `/v1/admin/business/partner/collaborator/import-csv/${partner_id}`,
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    if (response.status !== 201) {
      const errorMessageCsv = response.data.msg?.Error || 'Algo deu errado!';
      throw new Error(errorMessageCsv);
    }

    yield put(Creators.getColllaboratorCsvInsertSuccess(response.data.data));
    yield put(
      ToastCreators.getOpenToast({
        type: 'success',
        title: 'CSV do Colaborador cadastrado com sucesso',
      })
    );
  } catch (err) {
    yield put(
      Creators.getColllaboratorCsvInsertFailure(
        'Erro ao cadastrar CSV do colaborador'
      )
    );
    yield put(
      ToastCreators.getOpenToast({
        type: 'error',
        title: 'Falha ao cadastrar CSV do Colaborador',
      })
    );
  }
}

function* getCollaboratorList({ payload }) {
  try {
    const { page, perPage, search, orderByColumn, orderByDirection, group_id } =
      payload;
    const request = call(
      api.get,
      '/v1/admin/business/partner/getCollaboratos',
      {
        page,
        search,
        per_page: perPage,
        order: orderByDirection,
        order_by: orderByColumn,
        group_id,
      }
    );

    const response = yield call(callApi, request);
    if (response.status !== 200 && response.status !== 201) throw response;
    yield put(Creators.getCollaboratorListSuccess(response.data));
  } catch (err) {
    if (err.status === 404) {
      yield put(
        Creators.getCollaboratorListSuccess({
          total: 0,
          data: [],
        })
      );
    } else {
      yield put(Creators.getCollaboratorListFailure('Erro ao buscar na API'));
    }
    yield put(
      ToastCreators.getOpenToast({
        type: 'error',
        title: 'Teste',
        description: '',
      })
    );
  }
}

function* getCollaboratorDelete({ payload }) {
  try {
    const { id } = payload;
    const request = call(api.delete, `/v1/client/AllClientesSite/delete/${id}`);
    yield call(callApi, request);
    yield put(Creators.getCollaboratorDeleteSuccess());
    // Remove a categoria deletada da lista
    const { collaboratorList, collaboratorListTotal } = yield select(
      state => state.collaborator
    );

    yield put(
      Creators.getCollaboratorListSuccess({
        data: collaboratorList.filter(doc => doc.id !== id),
        total: collaboratorListTotal - 1,
      })
    );
  } catch (err) {
    yield put(Creators.getCollaboratorDeleteFailure('Erro ao buscar na API'));
  }
}
function* getCollaboratorById({ payload }) {
  try {
    const { id } = payload;
    const request = call(
      api.get,
      `/v1/admin/business/partner/collaborator/${id}`
    );
    const response = yield call(callApi, request);
    if (response.status !== 200 && response.status !== 201) throw response;
    yield put(Creators.getColllaboratorByIDSuccess(response.data));
  } catch (err) {
    if (err.status === 404) {
      yield put(
        Creators.getColllaboratorByIDSuccess({
          total: 0,
          data: [],
        })
      );
    } else {
      yield put(Creators.getColllaboratorByIDFailure('Erro ao buscar na API'));
    }
    yield put(
      ToastCreators.getOpenToast({
        type: 'error',
        title: 'Teste',
        description: '',
      })
    );
  }
}

function* getCollaboratorRestore({ payload }) {
  try {
    const { id } = payload;
    const request = call(api.put, `/v1/client/AllClientesSite/${id}/activate`);
    yield call(callApi, request);
    yield put(Creators.getCollaboratorRestoreSuccess());
    // Reativa a categoria deletada da lista
    const { collaboratorListDeleted, collaboratorListDeletedTotal } =
      yield select(state => state.collaborator);

    yield put(
      Creators.getCollaboratorDeletedListSuccess({
        data: collaboratorListDeleted.filter(doc => doc.id !== id),
        total: collaboratorListDeletedTotal - 1,
      })
    );
    yield put(
      ToastCreators.getOpenToast({
        type: 'success',
        title: 'Ativação concluida com sucesso',
        description: '',
      })
    );
  } catch (err) {
    yield put(Creators.getCollaboratorRestoreFailure('Erro ao buscar na API'));
  }
}

function* getCollaboratorCashback({ payload }) {
  try {
    const { partner_id } = payload;
    const request = call(
      api.get,
      `/v1/admin/business/partner/collaborator/balance/${partner_id}`
    );
    const response = yield call(callApi, request);
    const request_statement = call(
      api.get,
      '/v1/admin/business/partner/statement/collaborator',
      payload
    );
    const response_statement = yield call(callApi, request_statement);
    const { cashback_statement, ...rest } = response_statement.data.data;
    yield put(
      Creators.getCashbackCollaboratorSuccess({
        data: {
          balance: response.data.data,
          statement: cashback_statement,
          pagination: rest,
        },
      })
    );
  } catch (err) {
    yield put(Creators.getCashbackCollaboratorFailure('Erro ao buscar na API'));
    yield put(Notifications.error({ title: err.data.msg }));
  }
}

function* getCollaboratorUpdate({ payload }) {
  try {
    const {
      id,
      address,
      birth_date,
      cpf,
      email,
      genre,
      name,
      phone,
      role,
      status,
    } = payload;

    const response = yield call(
      api.put,
      `/v1/admin/business/partner/collaborator/site/${id}`,
      {
        id,
        address,
        birth_date,
        cpf,
        email,
        genre,
        name,
        phone,
        role,
        status,
      }
    );
    if (response.status !== 200) throw response;

    if (response.status === 200) {
      yield put(Creators.getCollaboratorUpdateSuccess());
      yield put(
        NotificationCreators.openNotification({
          message: 'Colaborador alterado com sucesso',
          type: 'success',
        })
      );
    }
  } catch (err) {
    yield put(Creators.getPartnerUpdateFailure('Erro ao buscar na API'));
    yield put(
      NotificationCreators.openNotification({
        message: 'Erro ao atualizar o colaborador',
        type: 'error',
      })
    );
  }
}

function* getCollaboratorDeletedList({ payload }) {
  try {
    const { page, perPage, search, orderByColumn, orderByDirection, group_id } =
      payload;
    const request = call(api.get, '/v1/client/getDeleteAllClientesSite', {
      page,
      search,
      per_page: perPage,
      order: orderByDirection,
      order_by: orderByColumn,
      group_id,
    });

    const response = yield call(callApi, request);
    if (response.status !== 200 && response.status !== 201) throw response;
    yield put(Creators.getCollaboratorDeletedListSuccess(response.data));
  } catch (err) {
    if (err.status === 404) {
      yield put(
        Creators.getCollaboratorDeletedListSuccess({
          total: 0,
          data: [],
        })
      );
    } else {
      yield put(
        Creators.getCollaboratorDeletedListFailure('Erro ao buscar na API')
      );
    }
    yield put(
      ToastCreators.getOpenToast({
        type: 'error',
        title: 'Teste',
        description: '',
      })
    );
  }
}
function* getCollaborator({ payload }) {
  try {
    const { access_token } = payload;
    const response = yield call(
      api.get,
      `/v1/site/business/partner/collaborator?token=${access_token}`,
      { headers: { 'Content-Type': 'application/json' } }
    );

    if (response.status !== 200) throw response;

    yield put(Creators.getCollaboratorSuccess(response.data));
  } catch (err) {
    yield put(Creators.getCollaboratorFailure('Erro ao buscar na API'));
    console.log(err.data.data.Error || err.data.msg);
  }
}

export default function* collaboratorSaga() {
  yield all([
    takeLatest(Types.GET_COLLABORATOR_REQUEST, getCollaborator),
    takeLatest(Types.GET_COLLABORATORBYID_REQUEST, getCollaboratorById),
    takeLatest(Types.GET_INSERT_REQUEST, getCollaboratorInsert),
    takeLatest(Types.GET_INSERT_CSV_REQUEST, getCollaboratorCsvInsert),
    takeLatest(Types.GET_LIST_REQUEST, getCollaboratorList),
    takeLatest(Types.GET_DELETE_REQUEST, getCollaboratorDelete),
    takeLatest(Types.GET_RESTORE_REQUEST, getCollaboratorRestore),
    takeLatest(Types.GET_REQUEST, getCollaboratorCashback),
    takeLatest(Types.GET_UPDATE_REQUEST, getCollaboratorUpdate),
    takeLatest(Types.GET_LIST_DELETED_REQUEST, getCollaboratorDeletedList),
  ]);
}
