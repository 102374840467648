/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Box, Typography, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import { InputContainer, InputItem } from 'components/form/StyledComponents';
import CustomTextField from 'components/form/CustomTextField';
import * as Yup from 'yup';
import CustomSelect from 'components/form/CustomSelect';
import validators from 'utils/validators';
import CustomMaskField from 'components/form/CustomMaskField';
import appColors from 'utils/appColors';
import CepMaskField from './CepMaskField';
import styles from './styles';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as StoreCreators } from 'store/ducks/admin/stores';
import { Creators as CepCreators } from 'store/ducks/address';
import { Creators as ClienteCreators } from 'store/ducks/client';
import CustomButton from 'components/form/CustomButton';

const RegisterCollaboratorForm = ({
  onSubmit,
  initialValues,
  onSubmitCEP,
  access_token,
  collaborator,
  collaboratorError,
  collaboratorLoading,
}) => {
  const dispatch = useDispatch();
  const { cep, cepError, cepLoading } = useSelector(state => state.address);

  React.useEffect(() => {
    dispatch(CepCreators.getClearCepRequest());
    window.scrollTo(0, 0);
  }, []);

  const validateCEP = value => {
    let error;
    if (value.replace(/[()_-]/g, '').replace(/ /g, '').length < 8) {
      error = 'Número inválido';
    }
    return error;
  };

  React.useEffect(() => {
    dispatch(StoreCreators.getStoreListRequest({ perPage: 1000 }));
  }, []);

  React.useEffect(() => {
    if (!collaboratorError && Object.values(collaborator).length) {
      const fields = [
        'name',
        'email',
        'cell_phone',
        'birth_date',
        'cpf',
        'genre',
      ];

      // eslint-disable-next-line no-unused-vars
      for (let field of fields) {
        formikRef.current.setFieldValue(field, collaborator[field]);
      }

      formikRef.current.setFieldValue(
        'address.code_post',
        collaborator.address?.code_post
      );
    }
  }, [collaboratorLoading]);

  const formikRef = React.useRef(null);
  const setFieldValue = (name, value) => {
    formikRef.current.setFieldValue(name, value);
  };

  useEffect(() => {
    dispatch(
      ClienteCreators.getclientByIDRequest({
        access_token,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(cep)?.length > 0) {
      setFieldValue('address.code_post', cep.code_post);
      setFieldValue('address.street', cep.street);
      setFieldValue('address.number', cep.number);
      setFieldValue('address.district', cep.district);
      setFieldValue('address.complement', cep.complement);
      setFieldValue('address.city.name', cep.city.name);
      setFieldValue('address.city.id', cep.city.id);
      setFieldValue('address.city.state.id', cep.city.state.id);
      setFieldValue('address.city.state.name', cep.city.state.name);
      dispatch(CepCreators.getClearCepRequest());
    }
  }, [cep]);

  const schema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Nome inválido')
      .test(
        'match',
        'Por favor, insira nome e sobrenome com as iniciais maiúsculas',
        val => {
          let error;
          if (
            val !==
            val.replace(
              /[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]* [A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]*/
            )
          ) {
            error =
              'Por favor, insira nome e sobrenome com as iniciais maiúsculas';
          }
          return error;
        }
      )
      .required('Este campo é obrigatório'),
    //Inserir Loja no cadastro
    store: Yup.object().shape({
      id: Yup.string(),
    }),
    email: Yup.string()
      .email('Este email não é valido')
      .required('Este campo é obrigatório'),
    cpf: Yup.string()
      .test('valid-length', 'Preencha o campo com CPF/CNPJ válido', val => {
        return val.length <= 14
          ? validators.cpfInvalid('CPF inválido')
          : validators.cnpjInvalid('CNPJ inválido');
      })
      .required('Este campo é obrigatório'),
    birth_date: Yup.mixed()
      .test('valid-date', 'Por Favor, insira uma data válida', val =>
        moment(val, 'DD/MM/YYYY').isValid()
      )
      .test('valid-length', 'Por Favor, insira uma data válida', val => {
        return val ? val.replace(/[/_-]/g, '').length === 8 : false;
      })
      .test('is-of-age', 'É necessário ser maior de 18 anos', val => {
        return moment().diff(moment(val, 'DD/MM/YYYY'), 'year') >= 18;
      }),
    cell_phone: Yup.string().required('Este campo é obrigatório'),
    genre: Yup.string().required('Escolha o gênero'),
    password: Yup.string()
      .required('Este campo é obrigatório')
      .min(6, 'A senha deve ter no mínimo 6 dígitos'),
    confirmPassword: Yup.string().test(
      'passwords-match',
      'Senhas não correspondem',
      function (value) {
        return this.parent.password === value;
      }
    ),
    address: Yup.object().shape({
      code_post: Yup.string().required('Informe o CEP'),
      street: Yup.string().required('Infome a Rua ou Avenida'),
      number: Yup.string().required('Informe o numero do local'),
      district: Yup.string().required('Informe o bairro'),
      complement: Yup.string(),
    }),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      ref={formikRef}
      validateOnBlur
      onSubmit={onSubmit}
      render={({ handleSubmit, values }) => (
        <Box flex="1" display="flex" flexDirection="column">
          <Form onSubmit={handleSubmit}>
            <InputContainer style={{ marginBottom: 35 }}>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Celular <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  disabled
                  name="cell_phone"
                  placeholder="Informe um número para contato"
                  // mask="(99) 9 9999-9999"
                  component={CustomTextField}
                />
              </InputItem>
            </InputContainer>
            <InputContainer style={{ marginBottom: 35 }}>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Nome Completo <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="name"
                  id="NomeCompleto"
                  label="Informe seu nome"
                  component={CustomTextField}
                />
              </InputItem>
            </InputContainer>
            <InputContainer style={{ marginBottom: 35 }}>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Data de Nascimento <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="birth_date"
                  label="Informe sua data de nascimento"
                  mask="99/99/9999"
                  component={CustomMaskField}
                />
              </InputItem>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Gênero <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="genre"
                  options={[
                    { id: 'F', name: 'Feminino' },
                    { id: 'M', name: 'Masculino' },
                    { id: 'O', name: 'Outro' },
                  ]}
                  placeholder="Informe o seu sexo"
                  component={CustomSelect}
                  isLoading={false}
                />
              </InputItem>
            </InputContainer>
            <InputContainer style={{ marginBottom: 25 }}>
              <InputItem>
                <Typography style={styles.titleInput}>
                  CPF/CNPJ <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="cpf"
                  label="Informe seu CPF/CNPJ"
                  mask={
                    values?.cpf?.length && values?.cpf?.length >= 15
                      ? '99.999.999/9999-99'
                      : '999.999.999-999'
                  }
                  component={CustomMaskField}
                />
              </InputItem>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Email <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="email"
                  id="emailRevendedor"
                  label="Informe seu email. Ex: nomedaloja@email.com"
                  component={CustomTextField}
                />
              </InputItem>
            </InputContainer>
            <InputContainer style={{ marginBottom: 35 }}>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Senha <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  autoComplete="new-password"
                  name="password"
                  id="senha"
                  type="password"
                  label="Informe a sua senha"
                  component={CustomTextField}
                />
              </InputItem>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Confirmação de senha <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="confirmPassword"
                  type="password"
                  id="confirmarSenha"
                  label="Confirme a sua senha"
                  component={CustomTextField}
                />
              </InputItem>
            </InputContainer>
            <Box
              style={{
                borderBottom: '1px solid #FE37A2',
                marginTop: 60,
                marginBottom: 40,
              }}
            />
            <Typography style={styles.purpleTitle}>
              Endereço do Responsável
            </Typography>
            {cepError && (
              <Box
                style={{
                  paddingTop: 5,
                  marginLeft: 10,
                  marginTop: 10,
                  color: 'red',
                }}>
                <Typography>{cepError}</Typography>
              </Box>
            )}
            <InputContainer>
              <InputItem>
                <Typography style={styles.titleInput}>
                  CEP <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="address.code_post"
                  label="Informe o CEP da loja"
                  id="inputEnderecoCEP"
                  mask="99999-999"
                  validate={validateCEP}
                  onSubmitCEP={onSubmitCEP}
                  component={CepMaskField}
                />
              </InputItem>
            </InputContainer>
            {((Object.keys(cep).length > 0 && !cepLoading) ||
              values.address.city.id !== '') && (
              <>
                <Box>
                  <InputContainer>
                    <InputItem style={{ flexGrow: 10 }}>
                      <Typography>Rua/Avenida</Typography>
                      <Field
                        name="address.street"
                        id="enderecoRua"
                        label="Infome o nome da rua ou avenida"
                        component={CustomTextField}
                      />
                    </InputItem>
                  </InputContainer>
                  <InputContainer>
                    <InputItem>
                      <Typography>Número</Typography>
                      <Field
                        name="address.number"
                        id="enderecoNumero"
                        label="Infome o número do local"
                        component={CustomTextField}
                      />
                    </InputItem>
                    <InputItem>
                      <Typography>Bairro</Typography>
                      <Field
                        name="address.district"
                        id="enderecoBairro"
                        label="Informe o bairro"
                        component={CustomTextField}
                      />
                    </InputItem>
                  </InputContainer>
                  <InputContainer>
                    <InputItem>
                      <Typography>Cidade</Typography>
                      <Field
                        disabled
                        name="address.city.name"
                        id="endereçoCidade"
                        label="Infome o número do local"
                        component={CustomTextField}
                      />
                    </InputItem>
                    <InputItem>
                      <Typography>Estado</Typography>
                      <Field
                        disabled
                        name="address.city.state.name"
                        id="endereçoEstado"
                        label="Infome o número do local"
                        component={CustomTextField}
                      />
                    </InputItem>
                  </InputContainer>
                  <InputContainer>
                    <InputItem>
                      <Typography>Complemento</Typography>
                      <Field
                        name="address.complement"
                        id="endereçoComplemento"
                        label="Adicione um complemento (Opcional)"
                        component={CustomTextField}
                      />
                    </InputItem>
                  </InputContainer>
                </Box>
              </>
            )}
            {cepLoading && (
              <Box
                flex="1"
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ marginTop: 10 }}>
                <CircularProgress style={{ color: appColors.PRIMARY_COLOR }} />
              </Box>
            )}

            <Box display="flex" justifyContent="end" mt={5}>
              <CustomButton
                style={{
                  width: 'auto',
                  paddingRight: 20,
                  paddingLeft: 20,
                  backgroundColor: appColors.PRIMARY_COLOR,
                }}
                label="Prosseguir"
                Icon={false}
                type="submit"
                isLoading={collaboratorLoading}
                size="large"
              />
            </Box>
          </Form>
        </Box>
      )}
    />
  );
};

RegisterCollaboratorForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  access_token: PropTypes.string.isRequired,
  collaborator: PropTypes.object.isRequired,
  collaboratorLoading: PropTypes.bool.isRequired,
};

export default RegisterCollaboratorForm;
