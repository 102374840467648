/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { useSelector } from 'react-redux';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import appColors from 'utils/appColors';
import ProductsCatalogBanner from 'components/Pages/Categories/ProductsCatalogBanner';
import styles, { CategoriesContainer, BoxInfo } from './styles';
import useQuery from 'hooks/useQuery';
import { useHistory } from 'react-router-dom';
import Page404 from '../404Page';
import { ReactSVG } from 'react-svg';
import useProduct from 'hooks/products';

var NotFound = require('assets/notfound.svg');

const CategoriesPage = ({ match }) => {
  const { productByCategoryTotal } = useSelector(
    state => state.product.product
  );
  const { category } = useSelector(state => state.category.category);

  const {
    loadingMultiCatalogBanner,
    multiCatalogBanner,
    getproductMultiCatalogBanner,
    multiCatalogBannerTotal,
  } = useProduct();
  const [, /* localState */ setLocalState] = React.useState({
    categoryName: '',
  });

  const query = useQuery();
  const history = useHistory();

  const [filters, setFilters] = React.useState({
    page: query.get('page') !== null ? Number(query.get('page')) : 1,
    per_page: 12,
    search: '',
    orderByColumn: '',
    orderByDirection: '',
    price_min: 0,
    price_max: 5000,
    size: [],
    color: [],
    order: '1',
    section: match.params.id,
    store: query.get('store') ? query.get('store') : '',
  });
  const { page, per_page } = filters;

  const nextPage = () => {
    if (page + 1 <= Math.ceil(productByCategoryTotal / per_page)) {
      setFilters({ ...filters, page: page + 1 });
    }
  };

  const previousPage = () => {
    if (page - 1 >= 1) {
      setFilters({ ...filters, page: page - 1 });
    }
  };

  const handlePage = selectPage => {
    setFilters({ ...filters, page: selectPage });
  };

  React.useEffect(() => {
    getproductMultiCatalogBanner(match.params.id);
  }, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    getproductMultiCatalogBanner(match.params.id);

    setFilters({
      page: query.get('page') !== null ? Number(query.get('page')) : 1,
      per_page: 12,
      search: '',
      orderByColumn: '',
      orderByDirection: '',
      price_min: 0,
      price_max: 5000,
      size: [],
      color: [],
      order: '1',
      //section: match.params.name,
      store: query.get('store') ? query.get('store') : '',
    });
  }, [match.params.name, history.location.search]);

  React.useEffect(() => {
    setLocalState({
      categoryName: category.name,
    });
  }, [setLocalState]);

  return (
    <CategoriesContainer flex="1" display="flex" flexDirection="column">
      {loadingMultiCatalogBanner && (
        <Box
          flex="1"
          display="flex"
          justifyContent="center"
          style={{ marginTop: 20, minWidth: 200 }}>
          <CircularProgress style={{ color: appColors.PRIMARY_COLOR }} />
        </Box>
      )}
      {multiCatalogBannerTotal > 0 && (
        <>
          <BoxInfo
            display="flex"
            flex="1"
            flexDirection="row"
            flexWrap="wrap"
            style={{ margin: '10px 10px' }}>
            <Box flex="1" flexDirection="column">
              {loadingMultiCatalogBanner && (
                <Box
                  flex="1"
                  display="flex"
                  justifyContent="center"
                  style={{ marginTop: 20, minWidth: 200 }}>
                  <CircularProgress
                    style={{ color: appColors.PRIMARY_COLOR }}
                  />
                </Box>
              )}
              {multiCatalogBanner.length > 0 && (
                <ProductsCatalogBanner
                  data={multiCatalogBanner}
                  total={multiCatalogBannerTotal}
                  nextPage={nextPage}
                  previousPage={previousPage}
                  handlePage={handlePage}
                  loading={loadingMultiCatalogBanner}
                  page={page}
                  perPage={per_page}
                />
              )}
              {multiCatalogBanner.length === 0 && !loadingMultiCatalogBanner && (
                <Box
                  flex="1"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={styles.boxEmpty}>
                  <div style={{ width: 500, height: 400 }}>
                    <Typography style={styles.purpleTitle}>
                      Nenhum resultado encontrado
                    </Typography>
                    <ReactSVG src={NotFound} />
                  </div>
                </Box>
              )}
            </Box>
          </BoxInfo>
        </>
      )}
      {multiCatalogBannerTotal === 0 && !loadingMultiCatalogBanner && (
        <Page404 />
      )}
    </CategoriesContainer>
  );
};

export default CategoriesPage;
