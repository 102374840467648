import styled from 'styled-components';
import { Typography, Box } from '@material-ui/core';
import appColors from 'utils/appColors';

export const TextFlex = styled(Typography)`
  && {

    @media all and (max-width: 500px){
      font-size: 14px;
    }
    
  }
`;

export const NoMobile = styled(Box)`
  && {

    @media all and (max-width: 500px){
      display:none;
    }
    
  }
`;

export const OnlyModile = styled(Box)`
  && {
    display:none;
    @media all and (max-width: 500px){
      display:flex;
    }
    
  }
`;


const styles = {
  containerItem:{
    borderTop:`1px solid ${appColors.PRIMARY_COLOR}`,
    padding:20,
  },
  headerPurple:{
    color:appColors.PRIMARY_COLOR
  },
  purpleBold:{
    color:appColors.PRIMARY_COLOR,
    fontWeight:'bold',
  },
  fontBold:{
    fontWeight:'bold',
  },
  status:{
    fontWeight:'bold',
    color:'white',
  },
  containerStatus:{
    backgroundColor:appColors.PRIMARY_COLOR,
    padding:8,
    borderRadius:5
  },
  iconExpand:{
    color:appColors.PRIMARY_COLOR,
    cursor:'pointer'
  }
}

export default styles;