import appColors from 'utils/appColors';


const styles = {

  purpleButton: {
    backgroundColor: appColors.PRIMARY_COLOR,
    color: 'white',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    marginLeft: 20,
  },
  cancelButton: {
    backgroundColor: 'white',
    border: '1px solid red',
    paddingTop: 9,
    paddingBottom: 9,
    color: 'red'
  },
};

export default styles;
