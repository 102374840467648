import React from 'react';
import Butiri from './components/buriti';
import Oferta from './components/oferta';
import appTheme from 'utils/appTheme';

const pages = {
  Buriti: Butiri,
  Oferta: Oferta,
};

export default function HeaderStores(props) {
  const HeaderStoresDetail = pages[appTheme.name] || Oferta;
  return (
    <HeaderStoresDetail {...props} />
  );
}

