import { useEffect } from 'react';

const kondutoCollectorScript = () => `
    var __kdt = __kdt || [];
    __kdt.push({"public_key": "T3DF27DE166"});
    (function() {   
        var kdt = document.createElement('script');   
        kdt.id = 'kdtjs'; kdt.type = 'text/javascript';   
        kdt.async = true;    kdt.src = 'https://i.k-analytix.com/k.js';   
        var s = document.getElementsByTagName('body')[0];   
        s.parentNode.insertBefore(kdt, s);
    })();`;

const useKondutoCollector = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = kondutoCollectorScript();
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);
};

export default useKondutoCollector;