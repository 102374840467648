/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { Formik, Field, Form } from 'formik';
import { InputBase, Box } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import appColors from 'utils/appColors';

const SearchForm = ({ onSubmit, closeSearchMobi, isMobile }) => {

  const initialValues = {
    search: '',
  }

  const useStyles = makeStyles(theme => ({
    inputSearch: {
      width: '100%',
      height: '100%'
    },
    inputInputMobi: {
      transition: theme.transitions.create('width'),
      width: '100%',
    },
    inputRoot: {
      color: 'white',
    },
    inputInput: {
      padding: theme.spacing(1, 4, 1, 2),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.only("xs")]: {
        padding: theme.spacing(1, 1, 1, 1),
        width: 40,
        color: appColors.SECUNDARY_COLOR,
        "&:focus": {
          width: 200,
          color: 'white',
        }
      }
    },

  }));

  const classes = useStyles();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, setFieldValue }) => (
        <>
          <Form style={{ flex: 1 }}>
            {isMobile ?
              (<Box display='flex' flex='1' flexDirection='row' alignItems='center' justifyContent='space-between' >
                <Field
                  name="search"
                  placeholder="Pesquise pela loja, produto ou categoria..."
                  classes={{
                    root: classes.inputSearch,
                    input: classes.inputInputMobi,
                  }}
                  onChange={(e) => { setFieldValue('search', e.target.value) }}
                  inputProps={{ 'aria-label': 'search' }}
                  component={InputBase}
                />
                <Box display='flex' flexDirection='row'>
                  <SearchIcon type='submit' onClick={handleSubmit} style={{ color: 'black' }} />
                  <ClearIcon onClick={closeSearchMobi} style={{ color: 'black' }} />
                </Box>
              </Box>)
              :
              (<Box display='flex' flex='1' flexDirection='row' alignItems='center' justifyContent='space-between'>
                <Field
                  name="search"
                  placeholder="Pesquise pela loja, produto ou categoria..."
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  onChange={(e) => { setFieldValue('search', e.target.value) }}
                  inputProps={{ 'aria-label': 'search' }}
                  style={{ flex: 1 }}
                  component={InputBase}
                />
                <Box display='flex' flexDirection='row'>
                  <SearchIcon type='submit' onClick={handleSubmit} style={{ marginRight: 10 }} />
                </Box>
              </Box>
              )}

          </Form>
        </>
      )} />
  )

}

export default SearchForm;