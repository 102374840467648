import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';

const breakpoints = createBreakpoints({
  sm: '48em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  '2xl': '96em',
});
// 3. Extend the theme
export const themeChakra = extendTheme({ breakpoints });
