import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import TabPanel from 'components/TabPanel';
import OrderListPage from 'components/Pages/ListPage';
import OrderListShopper from 'components/Pages/ListPage/OrderShopper';
import ExtractPage from 'components/Pages/ExtractPage';
import CancelPage from 'components/Pages/CancelPage';
import OrderListPageReseller from 'components/Pages/OrderListPageReseller';
import RecommendationReseller from 'Pages/Revendedor/RecommendationReseller';
import CashAnticipation from 'components/Pages/CashAnticipation';
import OrdersTabShop from 'components/OrderPage/OrdersTabShop';
import { Box } from '@material-ui/core';
import UserInsertPage from 'components/UserMenu/InsertPage';
import UserUpdatePage from 'components/UserMenu/UpdatePage';
import SalePage from 'containers/Pages/ReportMenu/SalePage';
import SaleCommissionPage from 'containers/Pages/ReportMenu/SaleCommissionPage';
import PanelClientTab from '../TabsPanel/PanelClientTab';
import AddressTab from '../TabsPanel/AddressTab';
import AccountDetailsTab from '../TabsPanel/AccountDetailsTab';
import ProductsTab from '../TabsPanel/ProductsTab';
import FeaturedProduct from '../TabsPanel/ProductsTab/Featured';
import TabPanelContainer from '../TabPanelContainer';
import NewProduct from 'Pages/Tabs/NewProduct';
import VirtualCatalogList from '../TabsPanel/VirtualCatalogTab/VirtualCatalogList';
import VirtualCatalogInsert from '../TabsPanel/VirtualCatalogTab/VirtualCatalogInsert';
import VirtualCatalogUpdate from '../TabsPanel/VirtualCatalogTab/VirtualCatalogUpdate';
import SetofPlotsPage from '../TabsPanel/SetofPlotsTab';
import CashExpressMenu from '../TabsPanel/CashExpressMenu';
import Documents from '../TabsPanel/Documents';
import CashExpressResellerMenu from '../TabsPanel/CashExpressResellerMenu';
import CashTranferMenu from '../TabsPanel/CashTranferMenu';
import UpdateStorePage from '../TabsPanel/UpdateStorePage';
import OrdersTab from '../TabsPanel/OrdersTab';
import TermsPage from '../TabsPanel/TermsPage';
import ClosedPage from '../TabsPanel/ClosedPage';
import CouponListPage from 'containers/Pages/PromotionMenu/CouponListPage';
import CouponInsertPage from 'containers/Pages/PromotionMenu/CouponInsertPage';

export const appTabs = {
  panelClient: 0,
  orderList: 1,
  address: 2,
  accountDetails: 3,
  productList: 4,
  productInsert: 5,
  orderShop: 6,
  virtualCatalogList: 7,
  virtualCatalogInsert: 8,
  virtualCatalogUpdate: 9,
  updateProduct: 10,
  cashExpress: 11,
  updateStore: 15,
  insertUser: 16,
  updateUser: 17,
  orderClient: 18,
  reportSales: 19,
  reportCommission: 20,
  cashExpressReseller: 21,
  promotionPage: 22,
  insertCoupon: 23,
  cashAnticipation: 24,
  extractPage: 25,
  cancelPage: 34,
  terms: 31,
  closed: 32,
  showcase: 33,
  recommendationReseller: 34,
  cashTranferMenu: 35,
  documents: 110,
  ReportTransfer: 90,
  FinancialResellerTab: 45,
  sendDealerChargeTab: 46,
  SetofPlots: 47,
  SetofPlotsClient: 48,
};

const TabPanelShopkeeper = ({ logout, openTab, handleTab, params, client }) => {
  const theme = useTheme();
  return (
    <TabPanelContainer>
      <Box>
        <TabPanel value={openTab} index={0} dir={theme.direction}>
          {appTabs.panelClient === openTab && (
            <PanelClientTab handleTab={handleTab} logout={logout} />
          )}
        </TabPanel>
        <TabPanel value={openTab} index={18} dir={theme.direction}>
          {appTabs.orderClient === openTab && <OrdersTab />}
        </TabPanel>
        {(client.group.id === 3 || client.group.id === 7) && (
          <TabPanel value={openTab} index={1} dir={theme.direction}>
            {appTabs.orderList === openTab && (
              <OrderListPage handleTab={handleTab} />
            )}
          </TabPanel>
        )}
        {client.group.id === 6 && (
          <TabPanel value={openTab} index={1} dir={theme.direction}>
            {appTabs.orderList === openTab && (
              <OrderListShopper handleTab={handleTab} />
            )}
          </TabPanel>
        )}
        {client.group.id === 4 && (
          <TabPanel value={openTab} index={1} dir={theme.direction}>
            {appTabs.orderList === openTab && (
              <OrderListPageReseller handleTab={handleTab} />
            )}
          </TabPanel>
        )}
        {client.group.id === 4 && (
          <TabPanel value={openTab} index={34} dir={theme.direction}>
            {appTabs.recommendationReseller === openTab && (
              <RecommendationReseller handleTab={handleTab} />
            )}
          </TabPanel>
        )}
        <TabPanel value={openTab} index={2} dir={theme.direction}>
          {appTabs.address === openTab && <AddressTab />}
        </TabPanel>
        <TabPanel value={openTab} index={3} dir={theme.direction}>
          {appTabs.accountDetails === openTab && <AccountDetailsTab />}
        </TabPanel>
        {(client.group.id === 3 || client.group.id === 7) && (
          <TabPanel value={openTab} index={4} dir={theme.direction}>
            {appTabs.productList === openTab && (
              <ProductsTab handleTab={handleTab} />
            )}
          </TabPanel>
        )}
        <TabPanel value={openTab} index={5} dir={theme.direction}>
          {appTabs.productInsert === openTab && <NewProduct />}
        </TabPanel>
        <TabPanel value={openTab} index={6} dir={theme.direction}>
          {appTabs.orderShop === openTab && <OrdersTabShop />}
        </TabPanel>
        <TabPanel
          value={openTab}
          index={appTabs.virtualCatalogList}
          dir={theme.direction}>
          {appTabs.virtualCatalogList === openTab && <VirtualCatalogList />}
        </TabPanel>
        <TabPanel
          value={openTab}
          index={appTabs.virtualCatalogInsert}
          dir={theme.direction}>
          {appTabs.virtualCatalogInsert === openTab && <VirtualCatalogInsert />}
        </TabPanel>
        <TabPanel
          value={openTab}
          index={appTabs.virtualCatalogUpdate}
          dir={theme.direction}>
          {appTabs.virtualCatalogUpdate === openTab && (
            <VirtualCatalogUpdate params={params} />
          )}
        </TabPanel>
        <TabPanel
          value={openTab}
          index={appTabs.updateProduct}
          dir={theme.direction}>
          {appTabs.updateProduct === openTab && <NewProduct />}
        </TabPanel>
        <TabPanel
          value={openTab}
          index={appTabs.insertUser}
          dir={theme.direction}>
          {appTabs.insertUser === openTab && (
            // inserir cadastro de usuário
            <UserInsertPage />
          )}
        </TabPanel>
        <TabPanel
          value={openTab}
          index={appTabs.updateUser}
          dir={theme.direction}>
          {appTabs.updateUser === openTab && <UserUpdatePage />}
        </TabPanel>
        {client.group.id === 3 && (
          <>
            <TabPanel
              value={openTab}
              index={appTabs.updateStore}
              dir={theme.direction}>
              {appTabs.updateStore === openTab && (
                <UpdateStorePage handleTab={handleTab} />
              )}
            </TabPanel>
            <TabPanel
              value={openTab}
              index={appTabs.cashExpress}
              dir={theme.direction}>
              {appTabs.cashExpress === openTab && <CashExpressMenu />}
            </TabPanel>
            <TabPanel
              value={openTab}
              index={appTabs.terms}
              dir={theme.direction}>
              {appTabs.terms === openTab && <TermsPage />}
            </TabPanel>
            <TabPanel
              value={openTab}
              index={appTabs.closed}
              dir={theme.direction}>
              {appTabs.closed === openTab && <ClosedPage />}
            </TabPanel>
            <TabPanel
              value={openTab}
              index={appTabs.reportSales}
              dir={theme.direction}>
              {appTabs.reportSales === openTab && <SalePage />}
            </TabPanel>
            <TabPanel
              value={openTab}
              index={appTabs.promotionPage}
              dir={theme.direction}>
              {appTabs.promotionPage === openTab && <CouponListPage />}
            </TabPanel>
            <TabPanel
              value={openTab}
              index={appTabs.insertCoupon}
              dir={theme.direction}>
              {appTabs.insertCoupon === openTab && <CouponInsertPage />}
            </TabPanel>
            <TabPanel
              value={openTab}
              index={appTabs.showcase}
              dir={theme.direction}>
              {appTabs.showcase === openTab && <FeaturedProduct />}
            </TabPanel>
          </>
        )}
        {client.group.id === 4 && (
          <TabPanel
            value={openTab}
            index={appTabs.cashExpressReseller}
            dir={theme.direction}>
            {appTabs.cashExpressReseller === openTab && (
              <CashExpressResellerMenu />
            )}
          </TabPanel>
        )}
        {client.group.id === 4 && (
          <TabPanel
            value={openTab}
            index={appTabs.documents}
            dir={theme.direction}>
            {appTabs.documents === openTab && <Documents />}
          </TabPanel>
        )}
        {client.group.id === 4 && (
          <TabPanel
            value={openTab}
            index={appTabs.cashTranferMenu}
            dir={theme.direction}>
            {appTabs.cashTranferMenu === openTab && <CashTranferMenu />}
          </TabPanel>
        )}

        {client.group.id === 4 && (
          <TabPanel
            value={openTab}
            index={appTabs.reportCommission}
            dir={theme.direction}>
            {appTabs.reportCommission === openTab && <SaleCommissionPage />}
          </TabPanel>
        )}
        {(client.group.id === 4 || client.group.id === 3) && (
          <TabPanel
            value={openTab}
            index={appTabs.cashAnticipation}
            dir={theme.direction}>
            {appTabs.cashAnticipation === openTab && <CashAnticipation />}
          </TabPanel>
        )}
        {(client.group.id === 4 || client.group.id === 3) && (
          <TabPanel
            value={openTab}
            index={appTabs.extractPage}
            dir={theme.direction}>
            {appTabs.extractPage === openTab && <ExtractPage />}
          </TabPanel>
        )}
        {(client.group.id === 4 || client.group.id === 3) && (
          <TabPanel
            value={openTab}
            index={appTabs.SetofPlots}
            dir={theme.direction}>
            {appTabs.SetofPlots === openTab && <SetofPlotsPage />}
          </TabPanel>
        )}
        {client.group.id === 3 && (
          <TabPanel
            value={openTab}
            index={appTabs.cancelPage}
            dir={theme.direction}>
            {appTabs.cancelPage === openTab && <CancelPage />}
          </TabPanel>
        )}
      </Box>
    </TabPanelContainer>
  );
};

TabPanelShopkeeper.propTypes = {
  openTab: PropTypes.number.isRequired,
  handleTab: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  params: PropTypes.oneOfType([PropTypes.object]),
};

TabPanelShopkeeper.defaultProps = {
  params: {},
};

export default TabPanelShopkeeper;
