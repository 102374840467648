/* eslint-disable no-unused-vars */
import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { responseToSelect } from 'utils/response';
import {
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { InputContainer, InputItem } from 'components/form/StyledComponents';
import styled from 'styled-components';
import CustomButton from 'components/form/components/CustomButton';
import NumberFormat from 'react-number-format';
import CloseIcon from '@material-ui/icons/Close';
import appColors from 'utils/appColors';

const StyledSelect = styled(Select)`
  && {
    & > div {
      min-height: 43px;
      min-width: 100px;
    }
  }
`;

const StyledFab = styled(Button)`
  && {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    background: ${appColors.COLOR_ICON};
    color: white;
    width: 45px;
    height: 42px;
    margin: 0px 2px;
    min-height: 0;
    &:hover {
      background: ${appColors.LIGHT_COLOR};
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.4);
    }
    &:active {
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.4);
    }
  }
`;

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={({ floatValue }) => {
        if (!floatValue) {
          onChange('');
        } else {
          onChange(floatValue);
        }
      }}
      thousandSeparator="."
      decimalSeparator=","
      fixedDecimalScale
      decimalScale={2}
      prefix="R$ "
    />
  );
}

const StyledTextField = styled(TextField)`
  && {
    .MuiOutlinedInput-root {
      background: white;
    }
    & .MuiOutlinedInput-input {
      padding: 12px 12px;
    }
    & .MuiInputLabel-outlined[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }
`;
const VariationField = ({
  field,
  form,
  colors,
  sizes,
  isLoadingColor,
  isLoadingSize,
  stock_control,
}) => {
  const newVariation = (price = '') => [
    {
      color: {
        id: null,
        name: null,
      },
      size: {
        id: null,
        name: null,
      },
      price_whole: price,
      code_ean: '',
      stock: '',
    },
  ];

  const [detailState, setDetailState] = React.useState({
    open: false,
    item: {},
  });

  const onDetailsConfirm = () => {
    setDetailState({ open: false, item: {} });
  };

  const onChangeVariation = (indexVariation, curField) => option => {
    form.setFieldValue(
      field.name,
      field.value.map((fieldValue, i) => {
        if (i === indexVariation) {
          if (curField === 'color') {
            return {
              ...fieldValue,
              color: {
                id: option.value,
                name: option.label,
              },
            };
          }

          if (curField === 'size') {
            return {
              ...fieldValue,
              size: {
                id: option.value,
                name: option.label,
              },
            };
          }

          if (curField === 'price_whole') {
            const curFieldInfo = {};
            curFieldInfo[curField] = option;
            return {
              ...fieldValue,
              ...curFieldInfo,
            };
          }
          const curFieldInfo = {};
          curFieldInfo[curField] = option.currentTarget.value;
          return {
            ...fieldValue,
            ...curFieldInfo,
          };
        }
        return fieldValue;
      })
    );
  };

  const convertAttributes = (variation, variations) => {
    if (!variation || variation.id === null) {
      return null;
    }
    const actualVariation = variations.find(variationElm => {
      return variation.id === variationElm.id;
    });

    if (actualVariation !== undefined) {
      return {
        value: actualVariation.id,
        label: actualVariation.name,
      };
    }
  };

  const removeVariation = indexVariation => () => {
    form.setFieldValue(
      field.name,
      field.value.filter((v, i) => {
        return i !== indexVariation;
      })
    );
  };

  const addVariation = () => {
    const values = field.value.concat(newVariation(form.values.price_whole));
    form.setFieldValue(field.name, values);
  };

  return (
    <>
      {field &&
        field.value &&
        field.value.map((fieldValue, indexVariation) => (
          <div
            data-cy={indexVariation}
            key={indexVariation}
            style={{ marginTop: '15px' }}>
            <InputContainer>
              <InputItem>
                <StyledSelect
                  styles={{
                    menuPortal: base => {
                      const { zIndex, ...rest } = base; // remove zIndex from base by destructuring
                      return { ...rest, zIndex: 9999 };
                    },
                  }}
                  value={convertAttributes(fieldValue.color, colors)}
                  onChange={onChangeVariation(indexVariation, 'color')}
                  placeholder="Variação"
                  noOptionsMessage={() => 'Nenhum resultado encontrado'}
                  options={responseToSelect(colors)}
                  isMulti={false}
                  menuPortalTarget={document.querySelector('body')}
                  isLoading={isLoadingColor}
                />
              </InputItem>
              <InputItem>
                <StyledSelect
                  styles={{
                    menuPortal: base => {
                      const { zIndex, ...rest } = base; // remove zIndex from base by destructuring
                      return { ...rest, zIndex: 9999 };
                    },
                  }}
                  value={convertAttributes(fieldValue.size, sizes)}
                  onChange={onChangeVariation(indexVariation, 'size')}
                  placeholder="Tamanho"
                  noOptionsMessage={() => 'Nenhum resultado encontrado'}
                  options={responseToSelect(sizes)}
                  isMulti={false}
                  menuPortalTarget={document.querySelector('body')}
                  isLoading={isLoadingSize}
                />
              </InputItem>
              <InputContainer>
                <InputItem>
                  <StyledTextField
                    data-cy="input_price_variation"
                    value={fieldValue.price_whole}
                    onChange={onChangeVariation(indexVariation, 'price_whole')}
                    variant="outlined"
                    style={{ maxWidth: '150px' }}
                    label="Preço"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </InputItem>
                <InputItem>
                  <StyledTextField
                    data-cy="input_code_ean"
                    value={fieldValue.code_ean}
                    onChange={onChangeVariation(indexVariation, 'code_ean')}
                    style={{ maxWidth: '150px' }}
                    variant="outlined"
                    label="Código EAN"
                  />
                </InputItem>
                {!!stock_control && (
                  <InputItem>
                    <StyledTextField
                      data-cy="input_stock_variation"
                      value={fieldValue.stock}
                      onChange={onChangeVariation(indexVariation, 'stock')}
                      style={{ maxWidth: '150px' }}
                      variant="outlined"
                      label="Estoque"
                    />
                  </InputItem>
                )}

                <InputItem>
                  {field.value.length > 0 && (
                    <StyledFab size="small">
                      <CloseIcon
                        data-cy="btn_delete_variation"
                        onClick={removeVariation(indexVariation)}
                      />
                    </StyledFab>
                  )}
                </InputItem>
              </InputContainer>
            </InputContainer>
          </div>
        ))}
      <InputContainer style={{ marginTop: '25px' }}>
        <InputItem>
          {field && field.value && (
            <>
              <CustomButton
                data-cy="btn_add_variation"
                onClick={addVariation}
                label="Adicionar variação"
              />
              <br />
              <br />
              <i style={{ color: '#888' }}>
                Obs: Quando as variações são ativadas, o estoque e preço do
                produto são substituído pela variação escolhida.
              </i>
            </>
          )}
        </InputItem>
      </InputContainer>
      <Dialog
        onClose={onDetailsConfirm}
        aria-labelledby="customized-dialog-title"
        open={detailState.open}>
        <DialogContent dividers>
          Ao cadastrar uma variação, os valores e estoque do produto serão
          utilizados o da variação. Por isso, preencher{' '}
          <negrito>sempre</negrito> o valor e o estoque.
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onDetailsConfirm} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

VariationField.propTypes = {
  field: PropTypes.oneOfType([PropTypes.object]).isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  colors: PropTypes.oneOfType([PropTypes.array]).isRequired,
  sizes: PropTypes.oneOfType([PropTypes.array]).isRequired,
  placeholder: PropTypes.string.isRequired,
  isLoadingColor: PropTypes.bool.isRequired,
  isLoadingSize: PropTypes.bool.isRequired,
};

export default VariationField;
