import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { InputContainer, InputItem } from 'components/form/StyledComponents';
import CustomTextField from 'components/form/CustomTextField';
import * as Yup from 'yup';
import CustomSelect from 'components/form/CustomSelect';
import validators from 'utils/validators';
import CustomMaskField from 'components/form/CustomMaskField';
import styles from './styles';
import EditAddressForm from '../../../EditAddressModal/EditAddressForm';
import moment from 'moment';
import appColors from 'utils/appColors';

export const FormInitialValues = {
  name: '',
  email: '',
  newsletter: '',
  cpf: '',
  birth_date: '',
  cell_phone: '',
  genre: '',
  password: '',
  confirmPassword: '',
};

const EditAccountForm = ({ onSubmit, initialValues }) => {
  const schema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Nome inválido')
      .test('name-complete', 'Preencha seu nome completo', val => {
        const test = val.split(' ');
        if (test.length < 2) return false;
        if (test[1] === '') return false;
        return true;
      })
      .required('Este campo é obrigatório'),
    email: Yup.string()
      .email('Este email não é valido')
      .required('Este campo é obrigatório'),
    cpf: Yup.string()
      .test('valid-length', 'Preencha o campo com CPF/CNPJ válido', val => {
        return val.length < 14
          ? validators.cpfInvalid('CPF inválido')
          : validators.cnpjInvalid('CNPJ inválido');
      })
      .required('Este campo é obrigatório'),
    birth_date: Yup.mixed()
      .test('valid-date', 'Por Favor, insira uma data válida', val =>
        moment(val, 'DD/MM/YYYY').isValid()
      )
      .test('valid-length', 'Por Favor, insira uma data válida', val =>
        val ? val.replace(/[/_-]/g, '').length === 8 : false
      )
      .test(
        'is-of-age',
        'É necessário ser maior de 18 anos',
        val => moment().diff(moment(val, 'DD/MM/YYYY'), 'year') >= 18
      ),
    cell_phone: Yup.string().required('Este campo é obrigatório'),
    genre: Yup.string().required('Escolha o gênero'),
    password: Yup.string().min(6, 'A senha deve ter no mínimo 6 dígitos'),
    confirmPassword: Yup.string().test(
      'passwords-match',
      'Senhas não correspondem',
      function (value) {
        return this.parent.password === value;
      }
    ),
  });

  const [changePass, setChangePass] = React.useState(false);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      validateOnBlur
      render={({ handleSubmit }) => (
        <Box flex="1" display="flex" flexDirection="column">
          <form autoComplete="off" onSubmit={handleSubmit}>
            <InputContainer style={{ marginBottom: 35 }}>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Nome
                  <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="name"
                  id="nomeUsuarioLogado"
                  label="Informe seu nome"
                  component={CustomTextField}
                />
              </InputItem>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Email
                  <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  autoComplete="off"
                  id="emailUsuarioLogado"
                  name="email"
                  label="Informe seu email. Ex: nomedaloja@email.com"
                  component={CustomTextField}
                />
              </InputItem>
            </InputContainer>
            <InputContainer style={{ marginBottom: 25 }}>
              <InputItem>
                <Typography style={styles.titleInput}>
                  CPF/CNPJ
                  <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="cpf"
                  label="Informe seu CPF/CNPJ"
                  onchange={console.log(FormInitialValues)}
                  mask={
                    initialValues &&
                    initialValues?.cpf?.length &&
                    initialValues?.cpf?.length > 14
                      ? '99.999.999/9999-99'
                      : '999.999.999-99'
                  }
                  component={CustomMaskField}
                />
              </InputItem>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Data de Nascimento
                  <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="birth_date"
                  label="Informe sua data de nascimento"
                  mask="99/99/9999"
                  component={CustomMaskField}
                />
              </InputItem>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Gênero
                  <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="genre"
                  options={[
                    { id: 'F', name: 'Feminino' },
                    { id: 'M', name: 'Masculino' },
                    { id: 'O', name: 'Outro' },
                  ]}
                  placeholder="Informe o seu sexo"
                  isLoading={false}
                  component={CustomSelect}
                />
              </InputItem>
            </InputContainer>
            <InputContainer style={{ marginBottom: 35 }}>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Celular
                  <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="cell_phone"
                  label="Informe um número para contato"
                  mask="(99) 9 9999-9999"
                  component={CustomMaskField}
                />
              </InputItem>
              <InputItem></InputItem>
            </InputContainer>
            <FormControlLabel
              control={
                <Checkbox
                  checked={changePass}
                  onChange={() => setChangePass(!changePass)}
                  value="checkedA"
                  style={{ color: appColors.PRIMARY_COLOR }}
                />
              }
              label="Deseja alterar senha?"
            />
            {changePass && (
              <InputContainer style={{ marginBottom: 35 }}>
                <InputItem>
                  <Typography style={styles.titleInput}>
                    Senha
                    <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <Field
                    autoComplete="new-password"
                    name="password"
                    type="password"
                    label="Informe a sua senha"
                    component={CustomTextField}
                  />
                </InputItem>
                <InputItem>
                  <Typography style={styles.titleInput}>
                    Confirmação de senha
                    <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <Field
                    name="confirmPassword"
                    type="password"
                    label="Confirme a sua senha"
                    component={CustomTextField}
                  />
                </InputItem>
              </InputContainer>
            )}
            <Button
              type="submit"
              data-cy="btn_save_profile"
              style={styles.purpleButtonProceed}>
              Salvar alterações
            </Button>
          </form>
        </Box>
      )}
    />
  );
};

EditAccountForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
};

EditAddressForm.defaultProps = {
  initialValues: FormInitialValues,
};

export default EditAccountForm;
