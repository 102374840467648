import appColors from 'utils/appColors';

const styles = {
  resumeContainer: {
    backgroundColor: '#f1f1f1',
    borderRadius: 5,
    margin: '20px 0px',
    padding: 16,
  },
  titleBoldPurple: {
    color: appColors.PRIMARY_COLOR,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  bigFontBold: {
    fontWeight: 'bold',
    fontSize: 20,
  },
};

export default styles;
