import appColors from 'utils/appColors';

const styles = {
    containerForm:{
        padding:20,
    },
    purpleTitle: {
        fontSize:22,
        color: appColors.SECUNDARY_COLOR,
    },
    subTitle:{
        color:'#393939',
        fontSize: 12,
    },
    headerTitle:{
        fontSize:20
    }
}

export default styles;