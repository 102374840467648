/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as StoreCreators } from 'store/ducks/admin/stores';
import HeaderComponent from 'components/HeaderComponent';
import StoreForm from 'components/StorePage/StoreForm';
import { withRouter } from 'react-router-dom';
import RichTextEditor from 'react-rte';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import appColors from 'utils/appColors';
import { Box, CircularProgress } from '@material-ui/core';
import useSegments from 'hooks/segments';

const StoreUpdatePage = ({ handleTab }) => {
  const dispatch = useDispatch();
  const [localState, setLocalState] = React.useState(null);
  const { store, storeLoading, storeUpdateLoading } = useSelector(
    state => state.admin.store
  );
  const { getAll, segments } = useSegments();

  React.useEffect(() => {
    if (!storeUpdateLoading) {
      getAll();
    }
  }, [storeUpdateLoading]);

  const [stateImages, setStateImages] = React.useState({
    previewUrl: '',
    images: [],
  });

  React.useEffect(() => {
    if (Object.keys(store).length > 0 && !storeLoading) {
      setLocalState({
        ...store,
        description: RichTextEditor.createValueFromString(
          store.description,
          'markdown'
        ),
        image_info: store.image,
        image: '',
        enable_automatic_withdrawal: store.enable_automatic_withdrawal,
      });
    }
  }, [store, storeUpdateLoading]);

  const onSubmit = data => {
    dispatch(
      StoreCreators.getStoreUpdateRequest({
        ...data,
        bank: undefined,
        bank_account_term: undefined,
      })
    );
  };

  const handleBack = () => {
    dispatch(push(`/stores`));
  };

  return (
    <>
      <HeaderComponent title="Dados da Loja" />
      {localState && !storeUpdateLoading && !storeLoading ? (
        <StoreForm
          segments={segments}
          initialValues={localState}
          onSubmit={onSubmit}
          store={store}
          handleBack={handleBack}
          isLoading={storeUpdateLoading}
          stateImages={stateImages}
          setStateImages={setStateImages}
          handleTab={handleTab}
        />
      ) : (
        <Box
          flex="1"
          display="flex"
          justifyContent="center"
          alignItems="center">
          <CircularProgress style={{ color: appColors.PRIMARY_COLOR }} />
        </Box>
      )}
    </>
  );
};

StoreUpdatePage.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withRouter(StoreUpdatePage);
