import React from 'react';
import LegendContainer from 'components/Legend/LegendContainer';
import LegendRow from 'components/Legend/LegendRow';
import LegendItem from 'components/Legend/LegendItem';

const ProductFooter = () => (
  <LegendContainer>
    <LegendRow>
      <LegendItem color="#00A146" letter="A">
        Ativo
      </LegendItem>
      <LegendItem color="#FF5151" letter="I">
        Inativo
      </LegendItem>
      <LegendItem color="#EFA700" letter="E">
        Sem Estoque
      </LegendItem>
      <LegendItem color="#B145E6" letter="SI">
        Sem Imagem
      </LegendItem>
    </LegendRow>
  </LegendContainer>
);

export default ProductFooter;
