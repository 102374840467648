import React, { useState } from 'react';
import { Stack, Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as ClientCreators } from 'store/ducks/client';
import { Creators as WalletCreators } from 'store/ducks/admin/wallet';
import { Creators as DashboardCreators } from 'store/ducks/admin/dashboard';
import { Creators as StoreCreators } from 'store/ducks/admin/stores';
import { Creators as CategoryCreators } from 'store/ducks/admin/category';

import MenuComponent from './menu';
import { PageTabs } from './pages';

function DashboardUser() {
  const dispatch = useDispatch();
  const { tab } = useSelector(state => state.panelTabs);
  const { client } = useSelector(state => state.client);
  const [loaded, setLoaded] = useState(false);

  React.useEffect(() => {
    dispatch(ClientCreators.getClientRequest());
    dispatch(CategoryCreators.getCategoryListRequest({ perPage: 1000 }));
  }, [dispatch]);

  React.useEffect(() => {
    if (
      client?.store !== undefined &&
      client?.store !== null &&
      client?.group !== undefined &&
      !loaded
    ) {
      dispatch(WalletCreators.getWalletRequest());
      dispatch(DashboardCreators.getDashboardRequest());
      dispatch(
        StoreCreators.getStoreRequest({
          id: client.store.id,
        })
      );
      setLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client?.store, dispatch]);

  if (client?.group === undefined) return <div />;

  const CurrentPage = PageTabs[tab];

  return (
    <Stack justify="center" justifyContent="center" p={[4, '0 100px']}>
      <Stack direction={['column', 'row']} mt={['0', '80px']}>
        <Stack w={['full', '380px']} mr={['0', '20px']}>
          <Stack mb="20px">
            <MenuComponent />
          </Stack>
        </Stack>

        <Box w="full">
          <CurrentPage />
        </Box>
      </Stack>
    </Stack>
  );
}

export default DashboardUser;
