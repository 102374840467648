import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import HeaderBoxContainer from 'components/HeaderComponent/HeaderBoxContainer';
import HeaderBoxItem from 'components/HeaderComponent/HeaderBoxItem';
import CustomTextField from 'components/form/components/CustomTextField';
import HeaderFab from 'components/HeaderComponent/HeaderFab';
import CustomDateRange from 'components/form/CustomDateRange';
import { Creators as StoreCreators } from 'store/ducks/admin/stores';
import { Creators as UserCreators } from 'store/ducks/admin/user';
import { useDispatch } from 'react-redux';
import CustomSelect from 'components/form/components/CustomSelect';
import Dropdown from 'components/Dropdown';

const SaleTableHeader = ({ getFunction, initialValues }) => {
  const onSubmit = values => {
    getFunction(values);
  };

  const dropdownState = 'closed';

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(StoreCreators.getStoreListRequest({ perPage: 1000 }));
    dispatch(UserCreators.getUserListRequest({ perPage: 1000, group_id: 5 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={() => (
        <Form style={{ width: '100%' }}>
          <HeaderBoxContainer>
            <HeaderBoxItem style={{ paddingTop: 12, paddingRight: 6 }}>
              Busca
            </HeaderBoxItem>
            <HeaderBoxItem style={{ paddingLeft: 6, paddingRight: 6 }}>
              <Field name="dateStartEnd" component={CustomDateRange} />
            </HeaderBoxItem>
            <HeaderBoxItem style={{ paddingLeft: 6, paddingRight: 6 }}>
              <Field
                component={CustomTextField}
                name="search"
                placeholder="Informe uma palavra-chave"
              />
            </HeaderBoxItem>
            <HeaderBoxItem
              style={{
                paddingLeft: 6,
                paddingRight: 0,
                display: dropdownState === 'opened' ? 'none' : 'block',
              }}>
              <HeaderFab icon="search" type="submit" />
            </HeaderBoxItem>
            <Dropdown
              dropdownState={dropdownState}
              style={{ width: '100%', paddingTop: 10 }}>
              <HeaderBoxContainer>
                <HeaderBoxItem
                  style={{ paddingLeft: 6, paddingRight: 6, minWidth: 200 }}>
                  <Field
                    name="status"
                    label="Status"
                    options={[
                      { id: '', name: '(Todos)' },
                      { id: 0, name: 'Aguardando Pagamento' },
                      { id: 1, name: 'Pagamento Autorizado' },
                      { id: 2, name: 'Faturado Parcialmente' },
                      { id: 3, name: 'Faturado' },
                      { id: 4, name: 'Em Separação' },
                      { id: 5, name: 'Aguardando Envio' },
                      { id: 6, name: 'Enviado' },
                      { id: 7, name: 'Entregue' },
                      { id: 8, name: 'Entregue Parcialmente' },
                      { id: 9, name: 'Aguardando Retirada' },
                      { id: 10, name: 'Devolvido' },
                      { id: 11, name: 'Devolvido Parcialmente' },
                      { id: 12, name: 'Aguardando Retirada Cliente' },
                      { id: 98, name: 'Não autorizado / Pagamento Recusado' },
                      { id: 99, name: 'Cancelado' },
                    ]}
                    component={CustomSelect}
                    placeholder="Status"
                    isLoading={false}
                  />
                </HeaderBoxItem>
                <HeaderBoxItem
                  style={{
                    paddingLeft: 6,
                    paddingRight: 0,
                    display: dropdownState === 'closed' ? 'none' : 'block',
                  }}>
                  <HeaderFab icon="search" type="submit" />
                </HeaderBoxItem>
              </HeaderBoxContainer>
            </Dropdown>
          </HeaderBoxContainer>
        </Form>
      )}
    />
  );
};

SaleTableHeader.propTypes = {
  getFunction: PropTypes.func.isRequired,
  initialValues: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default SaleTableHeader;
