import React from 'react';
import SearchInput from 'components/HeaderComponent/SearchInput';
import HeaderButton from 'components/HeaderComponent/HeaderButton';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import HeaderBoxContainer from 'components/HeaderComponent/HeaderBoxContainer';
import { Creators as PanelTabsCreators } from 'store/ducks/panelTabs';
import HeaderBoxItem from 'components/HeaderComponent/HeaderBoxItem';
import { useDispatch } from 'react-redux';
import { appTabs } from 'components/Pages/MyAccountPage/PainelAccount/TabPanelShopkeeper';

const CouponTableHeader = ({ getFunction, initialValues }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = values => {
    getFunction(values);
  };

  const navigateToTab = tab => () => {
    history.push({
      search: `?aba=novo-cupom`,
    });
    dispatch(PanelTabsCreators.changeTab(tab));
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={() => (
        <Form>
          <HeaderBoxContainer>
            <HeaderBoxItem style={{ paddingTop: 12, paddingRight: 6 }}>
              Busca
            </HeaderBoxItem>
            <HeaderBoxItem style={{ paddingLeft: 6, paddingRight: 6 }}>
              <Field
                component={SearchInput}
                name="search"
                placeholder="Informe uma palavra-chave"
              />
            </HeaderBoxItem>
            <HeaderBoxItem style={{ paddingLeft: 6, paddingRight: 0 }}>
              <HeaderButton icon="add" onClick={navigateToTab(appTabs.insertCoupon)}>Adicionar Cupom</HeaderButton>
            </HeaderBoxItem>
          </HeaderBoxContainer>
        </Form>
      )}
    />
  );
};

CouponTableHeader.propTypes = {
  getFunction: PropTypes.func.isRequired,
  initialValues: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default CouponTableHeader;
