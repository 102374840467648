/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { DialogConfirm } from 'components/DialogConfirm';
import appUtils from 'utils/appUtils';
import { FastField, Formik } from 'formik';
import CustomMaskField from 'components/form/CustomMaskField';
import notImage from 'images/Products/not_image.jpg';

const StyledGridImg = styled.img`
  && {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
`;

const VirtualCatalogPreview = ({
  form: { values },
  items,
  isLoading,
  setCategoryList,
}) => {
  const [active, setActive] = useState(null);
  const { products } = values;
  const [disc, setDisc] = useState([]);

  const changeProducts = (id, value) => {
    products.map(item => {
      if (item.id === id) {
        item.catalog_price_discount = value;
        setDisc(a => [...a, item]);
      }
      return item;
    });

    setActive(null);
  };

  useEffect(() => {
    products
      .map(b =>
        disc.find(a => a.id === b.id) ? disc.find(a => a.id === b.id) : b
      )
      .map(product => {
        const porcent = product.catalog_price_discount
          ? product.catalog_price_discount
          : product.price_discount;
        product.price_discount = porcent;

        return product;
      });

    const i = items.map(b =>
      disc.find(a => a.id === b.id) ? disc.find(a => a.id === b.id) : b
    );

    setCategoryList(i);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, disc]);

  return (
    <>
      {!isLoading && items.length > 0 && products.length > 0 && (
        <>
          <Typography variant="h6" style={{ marginTop: -20, marginBottom: 30 }}>
            Pré-Visualização
          </Typography>
          <Grid container spacing={1} style={{ marginBottom: 10 }}>
            {products.map((product, idx) => {
              const porcent = product.catalog_price_discount
                ? product.catalog_price_discount
                : product.price_discount;

              return (
                <Grid
                  item
                  xs={6}
                  sm={3}
                  key={String(idx)}
                  style={{
                    position: 'relative',
                    boxShadow: '1px 2px 4px #b3b3b3',
                    padding: 15,
                    marginRight: '15px',
                    backgroundColor: '#fff',
                    borderRadius: 6,
                    marginBottom: 20,
                    maxWidth: 230,
                  }}>
                  <StyledGridImg
                    src={
                      product.images.length > 0
                        ? product.images[0].sizes.medium
                        : notImage
                    }
                  />
                  <p style={{ color: '#FA8F47', fontSize: 18 }}>
                    {product.name}
                  </p>
                  <p style={{ fontSize: 12, color: '#777' }}>Preço da loja:</p>
                  <b> {appUtils.formatPrice(product.price_whole)}</b>
                  {product.catalog_price_discount ? (
                    <div>
                      <p style={{ fontSize: 12, color: '#777' }}>
                        Preço promocional:
                      </p>
                      <b>
                        {appUtils.formatPrice(
                          product.price_whole -
                            product.price_whole * (porcent / 100)
                        )}
                      </b>
                      <p style={{ fontSize: 12, color: '#777' }}>
                        Desconto aplicado:
                      </p>
                      <b>
                        {porcent}%
                        <a
                          style={{
                            fontWeight: '400',
                            color: '#FA8F47',
                            cursor: 'pointer',
                            margin: '0 10px',
                            fontSize: 14,
                          }}
                          onClick={() => setActive(idx)}>
                          editar
                        </a>
                      </b>
                    </div>
                  ) : (
                    <div>
                      <a
                        style={{
                          fontWeight: '400',
                          color: '#FA8F47',
                          cursor: 'pointer',
                          margin: '4px 0',
                          fontSize: 14,
                        }}
                        onClick={() => setActive(idx)}>
                        adicionar desconto
                      </a>
                    </div>
                  )}

                  <DialogConfirm
                    active={active === idx}
                    handleSubmit={() => {}}
                    handleClose={() => setActive(null)}
                    dialogContent={() => (
                      <div
                        style={{
                          width: 450,
                          backgroundColor: '#fff',
                          padding: 20,
                        }}>
                        <h3 style={{ marginBottom: 20 }}>
                          Alterar a procentagem da promoção
                        </h3>
                        <Formik
                          onSubmit={a => {
                            const nprice = Number(
                              a.price_discount.replace(' %', '')
                            );
                            changeProducts(product.id, nprice);
                          }}
                          initialValues={{
                            price_discount: product.new_price
                              ? Math.round(
                                  (product.price / product.price_whole - 1) *
                                    100
                                )
                              : '',
                          }}>
                          {({ submitForm }) => (
                            <Box>
                              <FastField
                                name="price_discount"
                                label="Insira a procentagem de desconto"
                                mask="99 %"
                                component={CustomMaskField}
                              />
                              <p style={{ fontSize: 12, color: '#555' }}>
                                Exemplo: 08%
                              </p>

                              <Box
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  marginTop: 20,
                                }}>
                                <Button
                                  onClick={() => setActive(null)}
                                  style={{ padding: 8, marginRight: 10 }}
                                  color="primary">
                                  Cancelar
                                </Button>
                                <Button
                                  onClick={submitForm}
                                  style={{ width: 200, padding: 8 }}
                                  variant="contained"
                                  color="primary">
                                  Salvar
                                </Button>
                              </Box>
                            </Box>
                          )}
                        </Formik>
                      </div>
                    )}
                  />
                </Grid>
              );
            })}
          </Grid>
        </>
      )}
      {/* {values.id && (
        <CustomButton
          data-cy="btn_download_catalog_details"
          style={{ marginBottom: 10 }}
          isLoading={virtualCatalogGeneratedLoading}
          label="Baixar Catálogo"
          onClick={onDownloadCatalog}
        />
      )} */}
    </>
  );
};

VirtualCatalogPreview.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  items: PropTypes.oneOfType([PropTypes.array]).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default VirtualCatalogPreview;
