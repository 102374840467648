// renovação de senha
export const Types = {
  GET_VALIDATE_REQUEST: 'validateAccount/GET_VALIDATE_REQUEST',
  GET_VALIDATE_SUCCESS: 'validateAccount/GET_VALIDATE_SUCCESS',
  GET_VALIDATE_FAILURE: 'validateAccount/GET_VALIDATE_FAILURE',
};

export const initialState = {
  validateAccountLoading: false,
  validateAccountError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_VALIDATE_REQUEST:
      return {
        ...state,
        validateAccountError: null,
        validateAccountLoading: true,
      };
    case Types.GET_VALIDATE_SUCCESS:
      return {
        ...state,
        hash: action.payload,
        validateAccountLoading: false,
        validateAccountError: null,
      };
    case Types.GET_VALIDATE_FAILURE:
      return {
        ...state,
        validateAccountLoading: false,
        validateAccountError: action.payload,
      };

    default:
      return state;
  }
};

export const Creators = {
  getValidateAccountRequest: hash => ({
    type: Types.GET_VALIDATE_REQUEST,
    payload: { hash },
  }),
  getValidateAccountSuccess: ({ data }) => ({
    type: Types.GET_VALIDATE_SUCCESS,
    payload: data.hash,
  }),
  getValidateAccountFailure: error => ({
    type: Types.GET_VALIDATE_FAILURE,
    payload: error,
  }),
};
