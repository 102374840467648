import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import { Grid, Typography } from '@material-ui/core';
import appColors from 'utils/appColors';
import { FastField } from 'formik';
import CustomTextField from 'components/form/CustomTextField';
import CustomButton from 'components/form/CustomButton';
import { InputContainer } from 'components/form/StyledComponents';

const ModalContainer = styled(Paper)`
  && {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    @media (max-width: 968px) {
      width: 70%;
    }
    @media (max-width: 768px) {
      width: 90%;
    }
    padding: 30px;
  }
`;

const WithDrawInfoItemLabel = styled('div')`
  && {
    color: ${appColors.PRIMARY_COLOR};
  }
`;

const WithDrawInfoItem = ({ label, children }) => (
  <Grid item lg={4} sm={6} xs={12}>
    <WithDrawInfoItemLabel>{label}</WithDrawInfoItemLabel>
    {children}
  </Grid>
);

const PaymentContainer = styled(Grid)`
  && {
    margin-top: 30px;
  }
`;

WithDrawInfoItem.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const CashConfirmModal = ({ isOpen, handleClose, isLoading, onSubmit }) => {
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <ModalContainer>
        <PaymentContainer container spacing={2}>
          <Grid sm={6} xs={12}>
            <Typography>Por favor, confirme sua senha:</Typography>
            <FastField
              name="password"
              label="Digite sua senha"
              type="password"
              component={CustomTextField}
            />
          </Grid>
          <Grid sm={6} xs={12} style={{ paddingTop: 42 }}>
            <InputContainer style={{ justifyContent: 'flex-end', margin: 20 }}>
              <CustomButton
                style={{
                  width: 'auto',
                  marginRight: 5,
                  paddingRight: 20,
                  paddingLeft: 20,
                  backgroundColor: '#797d80',
                }}
                onClick={handleClose}
                variant="contained"
                label="VOLTAR"
                color="primary"
                Icon={false}
              />
              <CustomButton
                style={{
                  width: 'auto',
                  paddingRight: 20,
                  paddingLeft: 20,
                  backgroundColor: appColors.COLOR_ICON,
                }}
                label={'Sacar'}
                Icon={false}
                onClick={onSubmit}
                isLoading={isLoading}
              />
            </InputContainer>
          </Grid>
        </PaymentContainer>
      </ModalContainer>
    </Modal>
  );
};

CashConfirmModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  store: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default CashConfirmModal;
