/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PageBase from 'components/PageBase';
import DefaultTable from 'components/Tables/DefaultTable';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as ClienteCreators } from 'store/ducks/client';
import { Paper } from '@material-ui/core';
import HeaderComponent from 'components/HeaderComponent';
import CollaboratorTableHeader from 'components/Pages/PartnerPage/CollaboratorTableHeader';
import CollaboratorActions from 'components/Pages/PartnerPage/CollaboratorActions';
import AlertDialog from 'components/AlertDialog';

const columns = ({ onDeleteRequest, handleTab }) => [
  {
    title: 'Nome',
    field: 'name',
    sorting: true,
  },
  {
    title: 'E-mail',
    field: 'email',
    sorting: false,
  },
  {
    title: 'Telefone',
    field: 'cell_phone',
    sorting: false,
  },
  {
    title: 'Ações',
    // eslint-disable-next-line react/display-name
    render: rowData => (
      <CollaboratorActions
        rowData={rowData}
        handleTab={handleTab}
        onDeleteRequest={onDeleteRequest}
      />
    ),
  },
];

const CollaboratorListPage = () => {
  const dispatch = useDispatch();
  const [deleteState, setDeleteState] = React.useState({
    open: false,
    item: {},
  });

  const [localState, setLocalState] = React.useState({
    search: '',
    orderByColumn: '',
    orderByDirection: '',
    page: 1,
    perPage: 10,
  });

  const {
    clientAll,
    clientAllTotal,
    clientDeleteALLLoading,
    clientAllLoading,
  } = useSelector(state => state.client);
  React.useEffect(() => {
    dispatch(ClienteCreators.getClientListAllRequest(localState));
  }, [dispatch, localState]);

  const handleAlertDialogClose = () => {
    setDeleteState({ open: false, item: {} });
  };

  React.useEffect(() => {
    if (clientDeleteALLLoading === false && deleteState.open) {
      handleAlertDialogClose();
    }
  }, [clientDeleteALLLoading]);

  const getFunction = data => {
    setLocalState(oldLocalState => ({ ...oldLocalState, ...data }));
  };

  const onDeleteRequest = item => {
    setDeleteState({ open: true, item });
  };
  const onDeleteConfirm = () => {
    dispatch(ClienteCreators.getClientDeletALLeRequest(deleteState.item.id));
  };

  return (
    <PageBase>
      <HeaderComponent title="Listar Clientes">
        <CollaboratorTableHeader
          getFunction={getFunction}
          initialValues={{ search: localState.search }}
        />
      </HeaderComponent>
      <Paper>
        <DefaultTable
          getFunction={getFunction}
          columns={columns({ onDeleteRequest })}
          data={clientAll}
          total={clientAllTotal}
          isLoading={clientAllLoading}
          page={localState.page}
          perPage={localState.perPage}
        />
      </Paper>
      <AlertDialog
        isOpen={deleteState.open}
        isLoading={clientDeleteALLLoading}
        handleClose={handleAlertDialogClose}
        onConfirm={onDeleteConfirm}
        title="Excluir registro?"
        description={`Remover Cliente: ${deleteState.item.name}`}
      />
    </PageBase>
  );
};

export default CollaboratorListPage;
