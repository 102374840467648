import styled from 'styled-components';
import { Box } from '@material-ui/core';
import appColors from 'utils/appColors';

export const BoxContainer = styled(Box)`
&&{
    max-width: 1266px; 
    margin-right:auto;
    margin-left:auto;
    padding-top:20px;
    display: flex; 
    flex: 1; 
    align-items: center;

    @media all and (max-width:500px) {
        flex-direction: column;
    }    
    @media all and (min-width:501px) and (max-width: 840px) {
        max-width: 700px;
    }
    @media all and (min-width:841px) and (max-width:1024px) {
        max-width: 850px;
    }
}
`;

export const MenuContainer = styled(Box)`
&&{
    display: flex; 
    flex: 1;
    align-items: center; 
    max-width: 1750px;
    
}
`;


const styles = {
    secondaryToolbar:{
        display:'flex',
        justifyContent:'center',
        flexDirection:'row', 
        minHeight:0,
        color:'#333333',
        backgroundColor:'white',
    },
    fontMenuItems:{
        fontWeight:'500', 
        textTransform: 'capitalize',
    },
    iconMenuItems:{
        marginRight:10, 
    },
    iconsPrimaryMenu:{
        fontSize: 25, 
        color:'white',
        marginRight:20
    },
    contentIcon:{
        margin: 10, 
        backgroundColor:appColors.SECUNDARY_COLOR,
        borderRadius:5,
    },
    contentIconRadius:{
        marginTop:10,
        marginLeft: 10,
        height:'100%',
        width:'auto', 
        backgroundColor:appColors.SECUNDARY_COLOR,
        borderRadius:'50%',
    },
    tabsStyle:{
        opacity:1,
        paddingTop:25,
        paddingBottom:25,
        textTransform: 'capitalize', 
        fontSize:16,
        postiton:'relative',
        minWidth:0,
        marginLeft:30,
        marginRight:30,
    }
    
}



export default styles