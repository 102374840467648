export const Types = {
  //request get
  ADD_CODE_PROMO: 'parameters/ADD_CODE_PROMO',
  ADD_PRECO: 'parameters/ADD_PRECO',
};

const initialState = {
  //request get
  codePromo: null,
  preco: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case Types.ADD_CODE_PROMO:
      return {
        ...state,
        codePromo: action.payload,
      };
    case Types.ADD_PRECO:
      return {
        ...state,
        preco: action.payload,
      };
    default:
      return state;
  }
}

export const Creators = {
  //request get
  codePromoAdd: code => ({
    type: Types.ADD_CODE_PROMO,
    payload: code,
  }),
  precoAdd: preco => ({
    type: Types.ADD_PRECO,
    payload: preco,
  }),
};
