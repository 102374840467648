/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import PropTypes from 'prop-types';
import ActionFab from 'components/Actions/ActionFab';
import ActionMenuItem from 'components/Actions/ActionMenuItem';
import ActionMenu from 'components/Actions/ActionMenu';

import { useSelector } from 'react-redux';

const CollaboratorActions = ({ rowData, onDeleteRequest }) => {
  const [localState, setLocalState] = React.useState({
    anchorEl: null,
  });

  const handleClick = event => {
    setLocalState({ anchorEl: event.currentTarget });
  };

  const handleClose = () => {
    setLocalState({ anchorEl: null });
  };
  const { client } = useSelector(state => state.client);

  const onDelete = () => {
    onDeleteRequest(rowData);
  };

  const resseller = () => {
    return (
      <>
        {rowData.status === 'CANCELED_BY_SELLER' ? (
          <>
            <ActionFab icon="more_vert" onClick={handleClick} />
            <ActionMenu
              anchorEl={localState.anchorEl}
              onClose={handleClose}
              open={Boolean(localState.anchorEl)}>
              <ActionMenuItem>Deletado</ActionMenuItem>
            </ActionMenu>
          </>
        ) : (
          <>
            {rowData.status !== 'WAITING_APPROVAL' && (
              <>
                {console.log(rowData.status)}
                <ActionFab icon="more_vert" onClick={handleClick} />
                <ActionMenu
                  anchorEl={localState.anchorEl}
                  onClose={handleClose}
                  open={Boolean(localState.anchorEl)}>
                  <ActionMenuItem>
                    <a href={rowData.ticket_url} target="_blank">
                      Download
                    </a>
                  </ActionMenuItem>
                  <ActionMenuItem onClick={onDelete}>Apagar</ActionMenuItem>
                </ActionMenu>
              </>
            )}
          </>
        )}
      </>
    );
  };
  return (
    <>
      {client.group.id === 5 ? (
        <ActionFab
          icon="download"
          href={rowData.ticket_url}
          target="_blank"
          rel="noreferrer"
        />
      ) : (
        resseller()
      )}
    </>
  );
};

CollaboratorActions.propTypes = {
  onTranferRequest: PropTypes.func,
  rowData: PropTypes.oneOfType([PropTypes.object]).isRequired,
  validateEmail: PropTypes.func,
};

CollaboratorActions.defaultProps = {
  onTranferRequest: () => {},
  validateEmail: () => {},
};

export default CollaboratorActions;
