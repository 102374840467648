import React from 'react';
import { Typography, Breadcrumbs } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from 'react-router-dom';
import styles, { ContainerHeader } from './styles';

const HeaderInfoDetails = () => (
  <ContainerHeader>
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb">
      <Link to="/" style={styles.link}>
        Início
      </Link>
      <Typography color="textPrimary">Informações</Typography>
    </Breadcrumbs>
  </ContainerHeader>
);

export default HeaderInfoDetails;
