import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import HeaderComponent from 'components/HeaderComponent';

const OrdersTab = () => {
  const { terms } = useSelector(({ config }) => config.config);
  return (
    <Box flex="1" display="flex" flexDirection="column">
      <HeaderComponent title="Termos" />
      {terms &&
        terms.store &&
        terms.store.map(term => (
          // eslint-disable-next-line react/jsx-key
          <Link to={term.route_web} style={{ textDecoration: 'none' }}>
            <Box
              flex="1"
              display="flex"
              flexDirection="row"
              style={{
                width: '500px',
                padding: '5px 15px',
                borderRadius: '6px',
                background: '#f3f3f3',
                marginBottom: '8px',
              }}>
              <Typography style={{ color: '#363636', textDecoration: 'none' }}>
                {term.name}
              </Typography>
            </Box>
          </Link>
        ))}
    </Box>
  );
};

export default OrdersTab;
