import { call, takeLatest, all, put} from 'redux-saga/effects';
import api from 'services/api';

import { Types, Creators } from 'store/ducks/category/list';

function* getCategoryList({ payload }) {
  try {
    const { page, perPage, search, orderByColumn, orderByDirection } = payload;
    const response = yield call(api.get, '/v1/site/categories', {
      page,
      search,
      per_page: perPage,
      order: orderByColumn,
      order_by: orderByDirection,
    });
    if (response.status !== 200) throw response;
    yield put(Creators.getCategoryListSuccess(response.data));
  } catch (err) {
    yield put(Creators.getCategoryListFailure('Erro ao buscar na API'));
  }
}


// Individual exports for testing
export default function* categorySaga() {
  yield all([
    takeLatest(Types.GET_LIST_REQUEST, getCategoryList),
  ]);
}
