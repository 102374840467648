import React from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { InputItem } from 'components/form/StyledComponents';
import CustomSelect from 'components/form/components/CustomSelect';

const TabContainer = ({ children }) => {
  return (
    <Typography component="div" style={{ padding: 8 }}>
      {children}
    </Typography>
  );
};

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export const formInitialValues = {
  hive_number: 1,
};

const schema = Yup.object().shape({
  status: Yup.string().required('Escolha uma opção de status'),
  hive_number: Yup.string().required('Escolha uma colméia'),
});

const StatusOrderForm = ({ onSubmit, initialValues = formInitialValues }) => {
  const onCanChange = hive_number => {
    onSubmit({ hive_number });
  };

  const data_beehive = [
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },
    { id: 4, name: '4' },
    { id: 5, name: '5' },
    { id: 6, name: '6' },
    { id: 7, name: '7' },
    { id: 8, name: '8' },
    { id: 9, name: '9' },
    { id: 10, name: '10' },
    { id: 11, name: '11' },
    { id: 12, name: '12' },
    { id: 13, name: '13' },
    { id: 14, name: '14' },
    { id: 15, name: '15' },
    { id: 16, name: '16' },
    { id: 17, name: '17' },
    { id: 18, name: '18' },
    { id: 19, name: '19' },
    { id: 20, name: '20' },
    { id: 21, name: '21' },
    { id: 22, name: '22' },
    { id: 23, name: '23' },
    { id: 24, name: '24' },
    { id: 25, name: '25' },
    { id: 26, name: '26' },
    { id: 27, name: '27' },
    { id: 28, name: '28' },
    { id: 29, name: '29' },
    { id: 30, name: '30' },
    { id: 31, name: '31' },
    { id: 32, name: '32' },
  ];

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      validateOnBlur
      enableReinitialize
      render={() => (
        <Form>
          <InputItem flexGrow={4}>
            <Field
              style={{ minWidth: 100 }}
              name="hive_number"
              label="Colméia"
              options={data_beehive}
              component={CustomSelect}
              placeholder="Colméia"
              isLoading={false}
              onChangeField={a => onCanChange(a)}
            />
          </InputItem>
        </Form>
      )}
    />
  );
};

StatusOrderForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.oneOfType([PropTypes.object]),
};

StatusOrderForm.defaultProps = {
  initialValues: formInitialValues,
  onSubmit: () => {},
  submitText: 'ENVIAR',
};

export default StatusOrderForm;
