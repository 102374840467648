import styled from 'styled-components';
import { Box, Card, Typography } from '@material-ui/core';
import appColors from 'utils/appColors';

export const CartContainer = styled(Box)`
&&{
  display: flex;
  flex: 1;
  width: 100%;


  @media all and (max-width: 500px) {
  }


}
`;

export const BoxContainerOkImg = styled(Box)`
&&{
      @media all and (min-width: 501px) and (max-width: 1024px){
        flex:1;
        justify-content: center;
      }
    }
`;

export const BoxContainer = styled(Box)`
    && {
      padding: 30px;
      background-color: #eeeaef;
      border-radius: 10px;
      margin-top: 20px;
     
      @media all and (max-width: 500px) {
        margin-top: 20px;
        padding: 10px;
      }
    }
`;

export const FooterContainer = styled(Box)`
&&{
  display: flex;
  flex: 1;
  margin-top:60px;
  width: 90%;
  margin-bottom:40px;
}
`;

export const CardShopCart = styled(Card)`
    && {
        flex: 1;
        display: flex;
        padding: 40px;
        flex-direction: row;
        margin-top: 20px;
        flex-wrap: wrap;
        margin-bottom: 20px;

        @media all and (max-width: 500px) {
          padding:10px;
        }
    }
`;

export const BigTextBlack = styled(Typography)`
    && {
      color: #373737;
      font-size: 22px;
      text-transform: uppercase;

      @media all and (max-width: 500px) {
        font-size: 14px;
        text-align:center;
      }

      @media all and (min-width: 501px) and (max-width:768px) {
        margin-top:10px;
        text-align:center;
      }
    }
`;

export const BoxContainerText = styled(Box)`
    && {
      padding-left: 60px;

      @media all and (max-width: 768px) {
        padding-left: 0px;
      }
    }
`;

export const BigTitlePurple = styled(Typography)`
    && {
      color: ${appColors.PRIMARY_COLOR};
      font-weight: bold;
      font-size: 20px;

      @media all and (max-width: 500px) {
        font-size: 14px;
        text-align:center;
      }

      @media all and (min-width: 501px) and (max-width:768px) {
        text-align:center;
      }
    }
`;


const styles = {

  purpleButton: {
    backgroundColor: appColors.PRIMARY_COLOR,
    color: 'white',
    paddingLeft: 20,
    paddingRight: 20,
    fontSize:16,
    paddingTop:10,
    paddingBottom:10,
  },
  bigTextBlack: {
    color: '#373737',
    fontSize: 32,
    textTransform: 'uppercase',
  },

  bigTitlePurple:{
    color: appColors.PRIMARY_COLOR,
    fontWeight:'bold',
    fontSize:35,
  }
};

export default styles;
