import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as StoreCreators } from 'store/ducks/stores/insert';
import { Creators as AddressCreators } from 'store/ducks/address';
import CustomStepper from 'components/Stepper2';
import styles from './styles';
import HeaderNewStore from 'components/Pages/RegisterStore/HeaderNewStore';
import RegisterStoreForm from 'components/Pages/RegisterStore/RegisterStoreForm';
import RegisterManagerForm from 'components/Pages/RegisterStore/RegisterManagerForm';
import appColors from 'utils/appColors';
import StoreCreatePayment from '../StoreCreatePayment';
import SegmentsList from './segments';
import useSegments from 'hooks/segments';
import { useState } from 'react';

import { Stack, Box } from '@chakra-ui/react';
import { formData } from './validations';

const RegisterStorePage = () => {
  const dispatch = useDispatch();
  const {
    config: {
      shopping: { shopping_global },
    },
  } = useSelector(state => state.config);
  const { store_recurrence } = shopping_global;
  const [localState, setLocalState] = useState(formData);
  const { activeStep } = localState;
  const { storeInsertLoading } = useSelector(state => state.stores.insert);
  const getStepContent = [
    'Dados da Loja',
    'Segmento da loja',
    'Dados do Responsável',
    shopping_global.store_recurrence ? 'Dados de pagamento' : false,
  ].filter(Boolean);
  const { getAll, segments } = useSegments();

  useEffect(() => {
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = (value, tmpManager = null) => {
    window.scrollTo(0, 0);
    setLocalState({
      ...localState,
      activeStep: value,
      manager: tmpManager ? tmpManager : localState.manager,
    });
  };

  const onSubmitCEP = values => {
    dispatch(AddressCreators.getAddressCEPRequest(values));
  };

  const goNextStore = values => {
    window.scrollTo(0, 0);
    setLocalState({ ...localState, ...values, activeStep: 1 });
  };

  const goNextSegments = values => {
    window.scrollTo(0, 0);
    setLocalState({ ...localState, segments: values, activeStep: 2 });
  };

  const goNextManager = values => {
    window.scrollTo(0, 0);
    setLocalState({
      ...localState,
      manager: values,
      activeStep: 3,
    });
  };

  const handleSubmitSingup = payment => {
    setLocalState({ ...localState, payment });
    dispatch(StoreCreators.getStoreInsertRequest({ ...localState, payment }));
  };

  const handleSubmitManager = manager => {
    setLocalState({ ...localState, manager });
    dispatch(StoreCreators.getStoreInsertRequest({ ...localState, manager }));
  };

  return (
    <Stack alignItems="center" py="100px">
      <HeaderNewStore title="Cadastro de Loja" />

      <Box w={['90%', '80%']}>
        <Stack>
          <Box
            style={{
              borderBottom: `1px solid ${appColors.PRIMARY_COLOR}`,
              marginBottom: 20,
            }}>
            <CustomStepper steps={getStepContent} activeStep={activeStep} />
          </Box>

          {activeStep === 0 && (
            <Box>
              <Typography style={styles.purpleTitle}>Dados da Loja</Typography>
              <RegisterStoreForm
                onSubmitCEP={onSubmitCEP}
                onSubmit={goNextStore}
                initialValues={localState}
              />
            </Box>
          )}

          {activeStep === 1 && (
            <SegmentsList
              segments={segments}
              initialValues={localState.segments}
              onSubmit={goNextSegments}
            />
          )}

          {activeStep === 2 && (
            <Box>
              <Typography style={styles.purpleTitle}>
                Dados do Responsável
              </Typography>
              <RegisterManagerForm
                onSubmitCEP={onSubmitCEP}
                onBack={handleBack}
                isNext={store_recurrence}
                onSubmit={data => {
                  if (!store_recurrence) {
                    handleSubmitManager(data);
                  } else {
                    goNextManager(data);
                  }
                }}
                initialValues={localState.manager}
                isLoading={storeInsertLoading}
              />
            </Box>
          )}

          {activeStep === 3 && store_recurrence && (
            <StoreCreatePayment
              isLoading={storeInsertLoading}
              onSubmit={handleSubmitSingup}
              onBack={handleBack}
              shopping={shopping_global}
            />
          )}
        </Stack>
      </Box>
    </Stack>
  );
};

export default RegisterStorePage;
