import { useState } from 'react';
import api from 'services/api';

function useSegments() {
  const [segments, setSegments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [stores, setStores] = useState([]);

  const getAll = async () => {
    try {
      const response = await api.get('/v1/site/stores/segments');
      setSegments(response.data.data);
    } catch (error) {
      //
    }
  };

  const getStoresBySegment = async (id, search) => {
    try {
      setLoading(true);
      const response = await api.get('/v1/site/stores/search', {
        segment_id: id,
        search,
      });
      setLoading(false);
      setStores(response.data.data);
    } catch (error) {
      setLoading(false);
      //
    }
  };

  return { getAll, getStoresBySegment, segments, loading, stores };
}

export default useSegments;
