import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import appColors from 'utils/appColors';
import PropTypes from 'prop-types';

const CustomRadioGroup = ({
  children,
  row,
  field: { value, name },
  form: { handleChange, handleBlur },
}) => (
    <FormControl fullWidth component="fieldset">
      <RadioGroup row={row} name={name} onChange={handleChange} value={value}>
        {children}
      </RadioGroup>
    </FormControl>
  );

CustomRadioGroup.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  row: PropTypes.bool,
  field: PropTypes.oneOfType([PropTypes.object]).isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

CustomRadioGroup.defaultProps = {
  row: false,
};

const CustomRadioGroupItem = ({ value, label }) => (
  <FormControlLabel style={{ minWidth: 70 }} control={<Radio style={{ color: appColors.SECUNDARY_COLOR }} />} value={value} label={label} />
);

CustomRadioGroupItem.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.any.isRequired,
};

export { CustomRadioGroupItem, CustomRadioGroup };