import React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import useQuery from 'hooks/useQuery';
import Icon from '@material-ui/core/Icon';
import { menuList, tabsName } from 'Pages/Account/menu/constants';

const StyledContainer = styled.div`
  && {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
`;

const StyledTypography = styled(Typography)`
  && {
    font-weight: 300;
    flex: 1;
  }
`;

const HeaderComponent = ({ title, variant, children }) => {
  const query = useQuery();
  const { tab } = useSelector(state => state.panelTabs);
  const aba_query = query.get('aba');
  const aba = aba_query ?? tab;

  const list = Object.keys(menuList).map(name => ({
    data: menuList[name],
    id: tabsName[name],
    name,
  }));

  const current = list.find(a => aba === a.name || aba === a.id);
  return (
    <StyledContainer>
      <Icon style={{ marginRight: 5 }} fontSize="medium">
        {current?.data?.icon}
      </Icon>
      <StyledTypography variant={variant || 'h4'}>{title}</StyledTypography>
      <>{children}</>
    </StyledContainer>
  );
};

export default HeaderComponent;
