import React, { createContext, useState, useContext } from 'react';
import { push } from 'connected-react-router';
import { Creators as AuthCreators } from 'store/ducks/auth';
import { useDispatch } from 'react-redux';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [data, setData] = useState({});
  const [coords, setCoords] = useState({});
  const dispatch = useDispatch();

  const confirmPosition = async () => {
    await dispatch(AuthCreators.getAuthSuccess(data));
    await dispatch(push('/minha-conta'));
  };

  return (
    <AuthContext.Provider
      value={{ data, setData, coords, setCoords, confirmPosition }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
