import styled from 'styled-components';
import { Box, Card } from '@material-ui/core';
import appColors from 'utils/appColors';

export const RegisterClientContainer = styled(Box)`
  && {
      width:1266px;
      margin-top:30px;
      margin-left:auto;
      margin-right:auto;
      @media all and (max-width:1650px) {
          margin-top:80px;
          width:90%;
      }
  }
`;

export const CardContainerRegisterClient = styled(Card)`
  padding:40px;
  margin-bottom:40px;
  margin-top:40px;
  flex:1;
  display:flex;
  flex-direction:column;
`;

const styles = {
  purpleTitle: {
    color: appColors.SECUNDARY_COLOR,
    fontWeight: 'bold',
    fontSize: 24,
  },
}

export default styles
