import appColors from 'utils/appColors';

const styles = {
	purpleTitle: {
		color: appColors.SECUNDARY_COLOR,
		fontWeight: 'bold',
		fontSize: 32
	},
	link: {
		color: appColors.SECUNDARY_COLOR,
		textDecoration: 'none'
	}
};

export default styles;
