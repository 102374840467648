/*
 *
 * Report reducer
 *
 */

export const Types = {
  // Obtem catergorias
  GET_STORE_REQUEST: 'report/GET_STORE_REQUEST',
  GET_STORE_SUCCESS: 'report/GET_STORE_SUCCESS',
  GET_STORE_FAILURE: 'report/GET_STORE_FAILURE',
};

export const initialState = {
  // Categoria por id
  reportSaleStore: [],
  reportSaleStoreLoading: false,
  reportSaleStoreError: null,
  reportSaleStoreTotal: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Categoria por id
    case Types.GET_STORE_REQUEST:
      return {
        ...state,
        reportSaleStore: [],
        reportSaleStoreError: null,
        reportSaleStoreLoading: true,
      };
    case Types.GET_STORE_SUCCESS:
      return {
        ...state,
        reportSaleStore: action.payload.data,
        reportSaleStoreLoading: false,
        reportSaleStoreError: null,
        reportSaleStoreTotal: action.payload.total,
      };
    case Types.GET_STORE_FAILURE:
      return {
        ...state,
        reportSaleStoreLoading: false,
        reportSaleStoreError: action.payload,
      };
    default:
      return state;
  }
};

export const Creators = {
  // Busca uma categoria
  getReportSaleStoreRequest: ({
    page,
    perPage,
    search,
    orderByColumn,
    orderByDirection,
    dateStart,
    dateEnd,
  }) => {
    return {
      type: Types.GET_STORE_REQUEST,
      payload: {
        page,
        perPage,
        search,
        orderByColumn,
        orderByDirection,
        dateStart,
        dateEnd,
      },
    };
  },
  getReportSaleStoreSuccess: ({ data, total }) => ({
    type: Types.GET_STORE_SUCCESS,
    payload: { data, total },
  }),
  getReportSaleStoreFailure: error => ({
    type: Types.GET_STORE_FAILURE,
    payload: error,
  }),
};
