import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FiltersCategories from 'components/Pages/Categories/FiltersCategories';
import { Creators as ProductsByStoreCreators } from 'store/ducks/stores/producstByStore';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import appColors from 'utils/appColors';
import ProductsListCategory from '../Categories/ProductsListCategory';
import notImage from 'images/Products/not_image.jpg';
import styles, { CategoriesContainer, BoxImage, BoxInfo } from './styles';
import HeaderStoreDetails from 'components/Pages/StoreDetails/HeaderStoreDetails';
import useQuery from 'hooks/useQuery';
import { useHistory } from 'react-router-dom';

const ProductsByStorePage = ({ slug, storesByCode }) => {
  const { productsByStore, productsByStoreTotal, productsByStoreLoading } =
    useSelector(state => state.stores.productsByStore);

  const dispatch = useDispatch();

  const query = useQuery();

  const history = useHistory();

  const [filters, setFilters] = React.useState({
    page: query.get('page') !== null ? Number(query.get('page')) : 1,
    per_page: 12,
    search: '',
    orderByColumn: '',
    orderByDirection: '',
    price_min: 0,
    price_max: 5000,
    sizes: [],
    colors: [],
    order: '1',
  });
  const { page, per_page } = filters;

  React.useEffect(() => {
    dispatch(ProductsByStoreCreators.getProductsByStoreRequest(filters, slug));
    setFilters({ ...filters, page: 1, per_page: 12 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (page > 1) {
      history.push({
        search: `?page=${page}`,
      });
    } else {
      history.push({
        search: null,
      });
    }
    dispatch(ProductsByStoreCreators.getProductsByStoreRequest(filters, slug));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const onFormFiltersSubmit = values => {
    setFilters(values);
  };

  const nextPage = () => {
    if (page + 1 <= Math.ceil(productsByStoreTotal / per_page)) {
      setFilters({ ...filters, page: page + 1 });
    }
  };

  const previousPage = () => {
    if (page - 1 >= 1) {
      setFilters({ ...filters, page: page - 1 });
    }
  };

  const handlePage = selectPage => {
    setFilters({ ...filters, page: selectPage });
  };

  return (
    <CategoriesContainer>
      <HeaderStoreDetails title={storesByCode.name} />
      <BoxInfo
        display="flex"
        flex="1"
        flexDirection="row"
        flexWrap="wrap"
        style={{
          justifyContent: 'center',
        }}>
        <div>
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            style={{ marginTop: 20 }}>
            <BoxImage>
              <img
                alt=""
                style={{
                  width: '100%',
                  objectFit: 'cover',
                  opacity: storesByCode.closed ? 0.5 : 1,
                }}
                src={
                  storesByCode.image.large !== ''
                    ? storesByCode.image.large
                    : notImage
                }
              />
              {!!storesByCode.closed && (
                <div
                  style={{
                    position: 'absolute',
                    bottom: '10px',
                    left: '25%',
                    background: '#8B0000',
                    color: '#fff',
                    textAlign: 'center',
                    width: '50%',
                    height: '18px',
                    borderRadius: '16px',
                  }}>
                  FECHADO
                </div>
              )}
            </BoxImage>
            <FiltersCategories
              data={{ colors: [], sizes: [] }}
              onSubmit={onFormFiltersSubmit}
              initialValues={filters}
            />
          </Box>
        </div>
        <Box flex="1" flexDirection="column">
          {productsByStore.length > 0 && !productsByStoreLoading > 0 && (
            <ProductsListCategory
              data={productsByStore}
              total={productsByStoreTotal}
              nextPage={nextPage}
              previousPage={previousPage}
              handlePage={handlePage}
              loading={productsByStoreLoading}
              page={page}
              perPage={per_page}
            />
          )}
          {!productsByStoreLoading && productsByStore.length === 0 && (
            <Box
              flex="1"
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={styles.boxEmpty}>
              <Typography style={styles.purpleTitle}>
                Nenhum resultado encontrado
              </Typography>
            </Box>
          )}
          {productsByStoreLoading && (
            <Box
              flex="1"
              display="flex"
              justifyContent="center"
              style={{ marginTop: 20 }}>
              <CircularProgress style={{ color: appColors.PRIMARY_COLOR }} />
            </Box>
          )}
        </Box>
      </BoxInfo>
    </CategoriesContainer>
  );
};

export default ProductsByStorePage;
