import { IconButton } from '@material-ui/core';
import React from 'react';
import appColors from 'utils/appColors';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Container, Content, Titles, BoxImg, StoreImg } from './styles';
import Tabs from '@material-ui/core/Tabs';
import { Creators as CategoryListCreators } from 'store/ducks/category/list';
import appNaming from 'utils/appNaming';

const ButtonApp = withStyles(() => ({
  root: {
    color: appColors.PRIMARY_COLOR,
    background: '#F5F5F5',
    height: '50px',
    width: '50px',
    padding: '15px 8px',
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: appColors.PRIMARY_COLOR,
      color: '#fff',
    },
  },
}))(IconButton);

const BannerHome = () => {
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { categoryList } = useSelector(state => state.category.list);

  // eslint-disable-next-line no-unused-vars
  const [filters, setFilters] = React.useState({
    page: 1,
    perPage: 30,
    search: '',
    orderByColumn: '',
    orderByDirection: '',
    order: '1',
  });

  React.useEffect(() => {
    dispatch(CategoryListCreators.getCategoryListRequest(filters));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <Container>
      <Content>
        <BoxImg image="/assets/Oferta/banners/cellhome.png">&nbsp;</BoxImg>
        <Titles>
          <h1 style={{ marginLeft: 10, marginRight: 10 }}>
            Catálogos de ofertas na palma da sua mão
          </h1>
          <p style={{ marginLeft: 10, marginRight: 10 }}>
            O {appNaming.TITLE} é a extensão da experiência do Shopping em sua
            casa. Através da nossa plataforma, você pode comprar das suas lojas
            favoritas sem sair de casa, facilitando sua busca e realizando
            entregas de forma rápida, eficiente e segura. Nosso e-commerce
            permite que você realize suas compras 24 horas, todos os dias!{' '}
          </p>
          <Tabs
            value={null}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs"
            style={{ width: window.innerWidth }}>
            {categoryList.map(category => (
              // eslint-disable-next-line react/jsx-key
              <ButtonApp
                id={category.id}
                color="primary"
                href={'/categoria/' + category.slug}>
                <StoreImg image={category.image.small}>&nbsp;</StoreImg>
              </ButtonApp>
            ))}
          </Tabs>
        </Titles>
      </Content>
    </Container>
  );
};

export default BannerHome;
