import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as StoresListCreators } from 'store/ducks/stores/list';
import { Container, Content, Titles, StoreImg } from './styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const BannerStores = () => {
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { storesList } = useSelector(state => state.stores.list);

  // eslint-disable-next-line no-unused-vars
  const [filters, setFilters] = React.useState({
    page: 1,
    perPage: 30,
    search: '',
    orderByColumn: '',
    orderByDirection: '',
    order: '1',
  });

  React.useEffect(() => {
    dispatch(StoresListCreators.getStoresListRequest(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <Container>
      <Content>
        <h2>Conheça nossas lojas</h2>
        <Titles>
          <Tabs
            value={null}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs"
            style={{ height: '130px' }}>
            {storesList.map(store => (
              // eslint-disable-next-line react/jsx-key
              <Tab icon={<StoreImg image={store.image.medium} />} />
            ))}
          </Tabs>
        </Titles>
      </Content>
    </Container>
  );
};

export default BannerStores;
