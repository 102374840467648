import React from 'react';
import DefaultTable from 'components/Tables/DefaultTable';
import { useDispatch } from 'react-redux';
import { Creators as OrderCreators } from 'store/ducks/admin/order';
import HeaderComponent from 'components/HeaderComponent';
import DateRangeInput from 'components/DateRangeInput';
import { Paper } from '@material-ui/core';
import OrderActions from 'components/OrderPage/OrderCancelActions';
import AlertDialog from 'components/AlertDialog';
import { formatDate, toPrice } from 'utils/converters';
import moment from 'moment';
import appUtils from 'utils/appUtils';
import api from 'services/api';

import { Stack, Text, Box } from '@chakra-ui/react';

const OrderListPage = () => {
  const columns = ({ reloadData }) => [
    {
      title: 'Pedido',
      field: 'order_id',
      render: rowData => <span>{rowData.order_id}</span>,
    },
    {
      title: 'Valor',
      field: 'amount',
      render: rowData => <span>R$ {toPrice(rowData.amount)}</span>,
    },
    {
      title: 'Motivo',
      field: 'description',
      render: rowData => <span>{rowData.description}</span>,
    },
    {
      title: 'Cancelamento',
      field: 'status',
      render: rowData => (
        <span>{rowData.status ? 'Confirmado' : 'Pendente'}</span>
      ),
    },
    {
      title: 'Solicitado',
      field: 'created_at',
      render: rowData => <span>{formatDate(rowData.created_at)}</span>,
    },
    {
      field: 'actions',
      render: rowData => (
        <OrderActions rowData={rowData} reloadData={reloadData} />
      ),
    },
  ];

  const dispatch = useDispatch();
  const [deleteState, setDeleteState] = React.useState({
    open: false,
    item: {},
  });
  const [data, setData] = React.useState([]);
  const [summary, setSummary] = React.useState({ total_amount: 0 });

  const today = moment();
  const actualDate = moment();
  const weekdate = today.add(-7, 'day');

  const [localState, setLocalState] = React.useState({
    created_at_start: weekdate.format('YYYY-MM-DD'),
    created_at_end: actualDate.format('YYYY-MM-DD'),
    page: 1,
    per_page: 10,
  });

  const [pagination, setPagination] = React.useState({});

  const handleAlertDialogClose = () => {
    setDeleteState({ open: false, item: {} });
  };

  const getFunction = data => {
    const dates = data.dateStart
      ? { created_at_start: data.dateStart, created_at_end: data.dateEnd }
      : {};
    setLocalState(oldLocalState => ({
      ...oldLocalState,
      ...dates,
    }));
  };

  const reloadData = React.useCallback(() => {
    api.get(`/v1/admin/orders/cancel/request`, localState).then(response => {
      if (response.data.data && response.data.data.cancel_requests) {
        setData(response.data.data.cancel_requests);
        setSummary(response.data.summary);
        setPagination(response.data.data);
      }
    });
  }, [localState]);

  React.useEffect(() => {
    reloadData();
  }, [reloadData]);

  const onDeleteConfirm = () => {
    dispatch(OrderCreators.getOrderDeleteRequest(deleteState.item.id));
  };

  return (
    <Stack w="full">
      <HeaderComponent title="Cancelamentos" />
      <DateRangeInput
        getFunction={getFunction}
        initialValues={{
          search: localState.search,
          dateStart: weekdate,
          dateEnd: actualDate,
        }}
      />

      <Stack direction="row" w="full" justify="space-between" p="20px 0">
        <Box>
          <Text color="#777">Valor dos pedidos</Text>
          <Text color="#444" fontSize={['30px', '48px']} fontWeight="bold">
            {appUtils.formatPrice(summary.total_amount || 0)}
          </Text>
        </Box>
        <Box>
          <Text color="#777">Solicitações de cancelamento</Text>
          <Text color="#444" fontSize={['30px', '48px']} fontWeight="bold">
            {pagination.total || 0}
          </Text>
        </Box>
      </Stack>

      <Paper>
        <DefaultTable
          getFunction={getFunction}
          columns={columns({ reloadData })}
          data={data}
          total={pagination.total}
          isLoading={false}
          page={localState.page}
          perPage={localState.perPage}
        />
      </Paper>
      <AlertDialog
        isOpen={deleteState.open}
        isLoading={false}
        handleClose={handleAlertDialogClose}
        onConfirm={onDeleteConfirm}
        title="Excluir registro?"
        description={`Remover categoria: ${deleteState.item.name}`}
      />
    </Stack>
  );
};

export default OrderListPage;
