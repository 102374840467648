import React from 'react';
import { Typography, Box } from '@material-ui/core';
import notImage from 'images/Products/not_image.jpg';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import appUtils from 'utils/appUtils';
import { useSelector } from 'react-redux';
import styles, {
  ImageProducts,
  ContainerProducts,
  BoxProduct,
  BoxInfo,
} from './styles';
import FooterList from 'components/FooterList';

const ProductsListCategory = ({
  data,
  nextPage,
  total,
  page,
  perPage,
  previousPage,
  handlePage,
}) => {
  const { preco } = useSelector(state => state.parameters);

  return (
    <ContainerProducts>
      <Box style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        {data.map(item => (
          <BoxProduct key={item.id}>
            <Link
              to={{
                pathname: `/produto/${item.slug}`,
                params: { storeRedirect: true },
              }}
              style={{
                textDecoration: 'none',
                color: 'inherit',
                flex: 1,
                justifyContent: 'space-around',
              }}>
              <ImageProducts
                src={
                  item.images.length > 0
                    ? item.images[0].sizes.medium
                    : notImage
                }
              />
              <BoxInfo
                display="flex"
                flex="1"
                flexDirection="column"
                css={{ height: 110 }}
                justifyContent="flex-end">
                <Typography
                  style={{
                    overflow: 'hidden',
                    maxWidth: 300,
                    padding: '5px 10px',
                  }}>
                  {item.name}
                </Typography>
                <Typography style={styles.ProductPrice}>
                  {preco === 'atacado'
                    ? appUtils.formatPrice(item.price_whole)
                    : appUtils.formatPrice(item.price)}
                </Typography>
              </BoxInfo>
            </Link>
          </BoxProduct>
        ))}
      </Box>
      <FooterList
        page={page}
        total={total}
        perPage={perPage}
        previousPage={previousPage}
        nextPage={nextPage}
        handlePage={handlePage}
      />
    </ContainerProducts>
  );
};

ProductsListCategory.propTypes = {
  data: PropTypes.array.isRequired,
  nextPage: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  previousPage: PropTypes.func.isRequired,
  handlePage: PropTypes.func.isRequired,
};

export default ProductsListCategory;
