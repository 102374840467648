import { call, takeLatest, all, put } from 'redux-saga/effects';
import api from 'services/api';
import { Creators as WalletCreators } from 'store/ducks/admin/wallet';
import { Types, Creators } from 'store/ducks/admin/wallet/withdraw';
import { Creators as NotificationCreators } from 'store/ducks/app';
import { callApi } from 'store/sagas/auth';

function* getWithdraw({ payload }) {
  try {
    const { data, onCloseModal } = payload;

    const request = call(api.post, '/v1/admin/wallets/transfers', data);
    const response = yield call(callApi, request);
    if (response.status !== 200 && response.status !== 201) throw response;
    yield onCloseModal();
    yield put(
      NotificationCreators.openNotification({
        message: 'Transação Realizada com Sucesso',
        type: 'success',
      })
    );
    yield put(Creators.getWithdrawSuccess(response.data));
    yield put(WalletCreators.getWalletRequest());
  } catch (err) {
    yield put(
      NotificationCreators.openNotification({
        message: err.data.msg,
        type: 'error',
      })
    );
    yield put(Creators.getWithdrawFailure('Erro ao buscar na API'));
  }
}

// Individual exports for testing
export default function* withdrawSaga() {
  yield all([takeLatest(Types.GET_WITHDRAW_REQUEST, getWithdraw)]);
}
