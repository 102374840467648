import React from 'react';
import Butiri from './components/buriti';
import Oferta from './components/oferta';
import SimerPay from './components/simerpay';
import appTheme from 'utils/appTheme';

const headers = {
  Buriti: Butiri,
  Oferta: Oferta,
  SimerPay: SimerPay,
};

export default function InfoText(props) {
  const InfoTextDetails = headers[appTheme.name] || Oferta;
  return <InfoTextDetails {...props} />;
}
