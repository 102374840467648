import React from 'react';
import {
  Box,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import { InputContainer, InputItem } from 'components/form/StyledComponents';
import CustomTextField from 'components/form/CustomTextField';
import appColors from 'utils/appColors';
import * as Yup from 'yup';
import styles from './styles';

export const initialValues = {
  email: '',
  newletter: '1',
};

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Este email não é valido')
    .required('Este campo é obrigatório'),
});

const InitialRegister = ({ onSubmit, initialValues }) => {
  const [localState, setLocalState] = React.useState({
    newsletterCheked: true,
  });

  const { newsletterCheked } = localState;

  const handleChangeNews = () => {
    setLocalState({ ...localState, newsletterCheked: !newsletterCheked });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      validateOnBlur
      render={({ handleSubmit, setFieldValue }) => (
        <Box
          flex="1"
          display="flex"
          flexDirection="column"
          style={{ paddingLeft: 40, paddingRight: 40 }}>
          <Form onSubmit={handleSubmit}>
            <InputContainer style={{ marginBottom: 20 }}>
              <InputItem style={{ margin: 0 }}>
                <Typography>Email</Typography>
                <Field
                  name="email"
                  label="nome@email.com"
                  component={CustomTextField}
                />
              </InputItem>
            </InputContainer>
            <Button type="submit" style={styles.purpleButtonProceed}>
              Prosseguir
            </Button>

            <Box flex="1" style={{ marginBottom: 20 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newsletterCheked}
                    onChange={e => {
                      handleChangeNews();
                      setFieldValue('newletter', e.target.checked ? '1' : '0');
                    }}
                    value="checkedA"
                    style={{ color: appColors.PRIMARY_COLOR }}
                  />
                }
                label="Inscrever-se em nossa Newsletter"
              />
            </Box>
          </Form>
          <Box flex="1" style={{ marginBottom: 0 }}>
            <Typography style={{ fontSize: 12, color: '#555' }}>
              Seus dados pessoais serão usados para aprimorar a sua experiência
              em todo este site, para gerenciar o acesso a sua conta e para
              outros propósitos, como descritos em nossos termos e condições.
            </Typography>
          </Box>
          {/* <Typography>Tornar-se um fornecedor</Typography> */}
        </Box>
      )}
    />
  );
};

InitialRegister.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

InitialRegister.defaultProps = {
  initialValues,
};

export default InitialRegister;
