import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Transition from 'components/Transition';
import styles from './styles';

const PageBase = ({
  loading,
  classes,
  children,
  minHeight,
  showLoadingText,
}) => {
  const [localState, setLocalState] = React.useState({
    loading,
  });

  React.useEffect(() => {
    setLocalState({ loading });
  }, [loading]);

  const content = (
    <div style={{ minHeight, height: '100%' }}>
      {localState.loading && (
        <div>
          {showLoadingText && (
            <div className={classes.loadingText}>Carregando...</div>
          )}
        </div>
      )}
      <Transition animate>{children}</Transition>
    </div>
  );

  return <div>{content}</div>;
};

export default withStyles(
  theme => ({
    ...styles(theme),
  }),
  {
    withTheme: true,
  }
)(PageBase);
