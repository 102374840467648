import styled from 'styled-components';

const LegendContainer = styled.div`
  display: block;
  padding: 5px 10px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;

export default LegendContainer;