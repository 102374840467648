import { useDispatch } from 'react-redux';
import api from 'services/api';
import { Creators as NotificationCreators } from 'store/ducks/app';
import { useState } from 'react';

export const useResselerCompany = () => {
  const dispatch = useDispatch();
  const [resselerCompany, setResselerCompany] = useState();
  const [resselerCompanyloading, setResselerCompanyLoading] = useState(false);
  const [putResselerCompanyloading, setPutResselerCompanyLoading] =
    useState(false);

  const getCompany = async () => {
    setResselerCompanyLoading(true);
    try {
      const response = await api.get('/v1/client/enterprises');
      if (response.status !== 200) throw response;
      if (response.status === 200) {
        setResselerCompany(response?.data?.data);
        setResselerCompanyLoading(false);
      }
      setResselerCompanyLoading(false);
    } catch (error) {
      console.log(error);
      setResselerCompanyLoading(false);
      dispatch(
        NotificationCreators.openNotification({
          message: 'Erro ao atualizar',
          type: 'error',
        })
      );
    }
  };
  const updateCompany = async data => {
    setPutResselerCompanyLoading(true);
    try {
      const response = await api.put(`/v1/client/enterprises`, data);
      if (response.status !== 200) throw response;
      dispatch(
        NotificationCreators.openNotification({
          message: 'Atualizado com sucesso',
          type: 'success',
        })
      );
      setPutResselerCompanyLoading(false);
    } catch (error) {
      console.log(error);
      setPutResselerCompanyLoading(false);
      dispatch(
        NotificationCreators.openNotification({
          message: 'Erro ao atualizar',
          type: 'error',
        })
      );
    }
  };

  return {
    updateCompany,
    getCompany,
    resselerCompanyloading,
    resselerCompany,
    putResselerCompanyloading,
  };
};
