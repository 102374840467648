import { call, takeLatest, all, put } from 'redux-saga/effects';
import { Creators as NotificationCreators } from 'store/ducks/app';
import api from 'services/api';

import { Types, Creators } from 'store/ducks/admin/wallet';
import { callApi } from 'store/sagas/auth';

function* getWallet() {
  try {
    const request = call(api.get, '/v2/admin/wallet/balance', {});
    const response = yield call(callApi, request);
    if (response.status !== 200 && response.status !== 201) throw response;
    yield put(Creators.getWalletSuccess(response.data));
  } catch (err) {
    yield put(Creators.getWalletFailure('Erro ao buscar na API'));
  }
}
function* getWalletStatus() {
  try {
    const request = call(api.get, '/v2/admin/wallet/status', {});
    const response = yield call(callApi, request);
    if (response.status !== 200 && response.status !== 201) throw response;
    yield put(Creators.getWalletStatusSuccess(response.data));
  } catch (err) {
    yield put(Creators.getWalletStatusFailure('Erro ao buscar na API'));
  }
}

function* getWalletBankReseller() {
  try {
    const request = call(api.get, '/v1/client/bank', {});
    const response = yield call(callApi, request);
    if (response.status !== 200 && response.status !== 201) throw response;
    yield put(Creators.getBankResellerSuccess(response.data));
  } catch (err) {
    yield put(Creators.getWalletFailure('Erro ao buscar na API'));
  }
}

function* setWalletBankReseller({ payload }) {
  try {
    yield put(Creators.getBankResellerSuccess(payload));
  } catch (err) {
    yield put(Creators.getWalletFailure('Erro ao buscar na API'));
  }
}

function* getWalletAnticipationsCalc({ payload }) {
  try {
    const request = call(
      api.post,
      '/v1/admin/wallets/anticipations/estimates',
      payload
    );
    const response = yield call(callApi, request);
    if (response.status !== 200 && response.status !== 201) throw response;
    yield put(Creators.getWalletAnticipationsCalcSuccess(response.data));
  } catch (err) {
    yield put(Creators.getWalletFailure('Erro ao buscar na API'));
  }
}
function* getWalletDocuments({ payload }) {
  try {
    const request = call(api.get, '/v2/admin/wallet/documents', payload);
    const response = yield call(callApi, request);
    if (response.status === 200 || response.status === 201) {
      yield put(Creators.getWalletDocumentsSuccess(response.data));
    } else {
      throw response;
    }
  } catch (err) {
    yield put(Creators.getWalletDocumentsFailure('Erro ao buscar na API'));
  }
}

function* getWalletAnticipationRequest({ amount }) {
  try {
    const request = call(api.post, '/v1/admin/wallets/anticipations', {
      amount,
    });
    const response = yield call(callApi, request);
    if (response.status !== 200 && response.status !== 201) throw response;
    yield put(Creators.getAnticipationSuccess(response.data));
    yield put(
      NotificationCreators.openNotification({
        message: 'Antecipação realizada com sucesso',
        type: 'success',
      })
    );
  } catch (err) {
    yield put(Creators.getAnticipationFailure());
    yield put(
      NotificationCreators.openNotification({
        message: 'Não foi possível realizar a antecipação ',
        type: 'error',
      })
    );
  }
}

function* getWalletExtractRequest({ payload }) {
  try {
    const request = call(api.get, '/v1/admin/wallets/statements', payload);
    const response = yield call(callApi, request);
    if (response.status !== 200 && response.status !== 201) throw response;
    yield put(Creators.getWalletExtractSuccess(response.data));
  } catch (err) {
    yield put(Creators.getWalletFailure('Erro ao buscar na API'));
  }
}

// Individual exports for testing
export default function* walletSaga() {
  yield all([takeLatest(Types.GET_WALLET_REQUEST, getWallet)]);
  yield all([
    takeLatest(Types.GET_WALLET_BANK_RESELLER_REQUEST, getWalletBankReseller),
  ]);
  yield all([takeLatest(Types.GET_WALLETSTATUS_REQUEST, getWalletStatus)]);
  yield all([
    takeLatest(Types.SET_WALLET_BANK_RESELLER_REQUEST, setWalletBankReseller),
  ]);
  yield all([
    takeLatest(Types.GET_WALLET_ANTICIPATIONS_CALC, getWalletAnticipationsCalc),
  ]);
  yield all([
    takeLatest(Types.GET_WALLET_EXTRACT_REQUEST, getWalletExtractRequest),
  ]);
  yield all([
    takeLatest(
      Types.GET_WALLET_ANTICIPATION_REQUEST,
      getWalletAnticipationRequest
    ),
  ]);
  yield all([
    takeLatest(Types.GET_WALLETDOCUMENTS_REQUEST, getWalletDocuments),
  ]);
}
