/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-key */
import React from 'react';
import { Formik, Field, Form, FastField } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Card, Typography } from '@material-ui/core';
import { InputContainer, InputItem } from 'components/form/StyledComponents';
import CustomTextField from 'components/form/components/CustomTextField';
import CustomImageField from 'components/form/components/CustomImageField';
import CustomSelect from 'components/form/components/CustomSelect';
import CustomButton from 'components/form/components/CustomButton';
import FormButtons from 'components/form/components/FormButtons';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as CategoryCreators } from 'store/ducks/admin/category';
import { Creators as StoreByCodeCreators } from 'store/ducks/stores/byCode';
import { Creators as StoreCreators } from 'store/ducks/admin/stores';
import { Creators } from 'store/ducks/admin/virtualCatalog';

import ProductsList from 'components/Pages/Home/ProductsList';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ProductsByStore from 'components/ProductsByStore';
import {
  StoreBox,
  StoresContainer,
  ButtonSore,
  StoresContent,
  ContainerStores,
  ContainerSearch,
  Title,
  ProductsContainer,
  ImgLogoStore,
  ImgProduct,
  ButtonFilter,
} from './styles';
import appNaming from 'utils/appNaming';
import CustomMaskField from 'components/form/CustomMaskField';
import moment from 'moment';

const TabContainer = ({ children }) => (
  <Typography component="div" style={{ padding: 8 * 3 }}>
    {children}
  </Typography>
);

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export const formInitialValues = {
  id: '',
  name: '',
  expiration_date: '',
  categories: [],
  products: [],
  store: {},
  image: '',
  image_data: '',
  image_info: '',
};

const schema = Yup.object().shape({
  id: Yup.number(),
  name: Yup.string().required('Este campo é obrigatório'),
  expiration_date: Yup.mixed(),
  store: Yup.object().shape({
    id: Yup.number().required('Este campo é obrigatório'),
  }),
  categories: Yup.array().of(
    Yup.object().shape({
      id: Yup.number(),
    })
  ),
  products: Yup.array().of(
    Yup.object().shape({
      id: Yup.number(),
    })
  ),
});

const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop);

const VirtualCatalogForm = ({
  onSubmit,
  initialValues = formInitialValues,
  submitText,
  handleBack,
  isLoading,
}) => {
  const dispatch = useDispatch();

  const percorrer = 300;
  const unidadeStore = 100;
  const product = [];
  const refForm = React.useRef(null);
  const refBox = React.useRef(null);

  const [storeSelected, setStoreSelected] = React.useState({});
  const [storeListView, setStoreList] = React.useState([]);
  const [storeLeft, setStoreLeft] = React.useState(1);
  const [storeSearch, setStoreSearch] = React.useState('');
  const [productsSelected, setProductsSelected] = React.useState([]);
  const [viewFilter, setViewFilter] = React.useState('none');

  const { categoryList, categoryListLoading } = useSelector(
    state => state.admin.category
  );

  const { client } = useSelector(state => state.client);
  const { storeList } = useSelector(state => state.admin.store);
  const { storesByCode } = useSelector(state => state.stores.byCode);
  const { virtualCatalog, virtualCatalogGeneratedLoading } = useSelector(
    state => state.admin.virtualCatalog
  );

  React.useEffect(() => {
    dispatch(
      StoreCreators.getStoreListRequest({
        page: 1,
        perPage: 9999,
        search: '',
        orderByColumn: '',
        orderByDirection: '',
        use_catalog_rule: 1,
      })
    );
    getInitialData();
    dispatch(
      StoreByCodeCreators.getStoresByCodeRequest(
        `${appNaming.DOWN_CASE_NAMING_HIFEN}`
      )
    );
  }, []);

  React.useEffect(() => {
    if (virtualCatalog.id != null) {
      setProductsSelected(virtualCatalog.products);
    }
  }, [virtualCatalog]);

  React.useEffect(() => {
    if (storeList.length > 0) {
      setStoreList(storeList);
    } else {
      setStoreList([]);
    }
  }, [storeList]);

  React.useEffect(() => {
    if (storeSearch !== '') {
      const lista = storeList.filter(
        item => item.name.toLowerCase().indexOf(storeSearch.toLowerCase()) > -1
      );

      setStoreList(lista);
      setStoreLeft(1);
    } else {
      setStoreList(storeList);
    }
  }, [storeSearch]);

  const onDownloadCatalog = () => {
    const { id, name } = virtualCatalog;
    dispatch(Creators.getVirtualCatalogGenerateRequest({ id, name }));
  };

  const selectStore = store => {
    if (store === storeSelected) {
      setStoreSelected({});
    } else {
      setStoreSelected(store);
      dispatch(StoreByCodeCreators.getStoresByCodeRequest(store.slug));
    }
    scrollToRef(refBox);
  };

  const setPercorrer = stage => {
    if (stage) {
      if (unidadeStore * storeListView.length - percorrer > storeLeft)
        setStoreLeft(old => old + percorrer);
    } else if (storeLeft - percorrer > 0) setStoreLeft(old => old - percorrer);
    else setStoreLeft(1);
  };
  const idStore = client.store ? client.store.id : 1;

  const getInitialData = () => {
    dispatch(CategoryCreators.getCategoryListRequest({ perPage: 1000 }));
    // eslint-disable-next-line no-param-reassign
    initialValues.store.id = idStore;
    initialValues.expiration_date = moment(
      initialValues.expiration_date,
      'YYYY-MM-DD'
    ).format('DD/MM/YYYY');
  };

  const addProduct = item => {
    setProductsSelected(old => [
      ...old,
      { ...item, logo: storeSelected.image.small },
    ]);
    refForm.current.setFieldValue('store', item.store);
  };

  const remProduct = item => {
    setProductsSelected(old => [...old.filter(prod => prod.id != item.id)]);
  };

  React.useEffect(() => {
    if (refForm.current)
      refForm.current.setFieldValue('products', productsSelected);
  }, [productsSelected]);

  const toggleFilter = () => {
    if (viewFilter === 'none') setViewFilter('');
    else setViewFilter('none');
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      ref={refForm}
      validateOnBlur
      enableReinitialize
      render={({ values }) => (
        <Form style={{ position: 'relative' }}>
          <Card style={{ padding: 20 }}>
            <InputContainer>
              <InputItem>
                <Field
                  name="name"
                  placeholder="Nome"
                  component={CustomTextField}
                />
              </InputItem>
              <InputItem>
                <Field
                  name="expiration_date"
                  placeholder="Informe data de validade do catálogo"
                  mask="99/99/9999"
                  component={CustomMaskField}
                />
              </InputItem>
            </InputContainer>
            <InputContainer>
              <InputItem>
                <FastField
                  data-cy="btn_button_cap"
                  name="image"
                  label={
                    values.image_info && values.image_info.small
                      ? 'Atualizar imagem de capa'
                      : 'Adicionar Imagem de Capa'
                  }
                  aspect={1 / 1.8}
                  type="cover"
                  component={CustomImageField}
                  previewUrl={
                    values.image_info && values.image_info.small
                      ? values.image_info.small
                      : ''
                  }
                />
              </InputItem>
            </InputContainer>
            <InputContainer
              style={{ marginTop: 30, marginBottom: -20 }}
              ref={refBox}>
              <InputItem style={{ width: '50%' }}>
                <Field
                  name="categories"
                  label="Categorias"
                  options={categoryList}
                  component={CustomSelect}
                  placeholder="Categorias"
                  isMulti
                  optionLimit={5}
                  isLoading={categoryListLoading}
                />
              </InputItem>
            </InputContainer>
            <InputContainer>
              <StoresContainer>
                <ContainerSearch>
                  <SearchIcon />
                  <input
                    data-cy="search_store"
                    autoComplete="off"
                    name="buscar"
                    placeholder="Procurar Loja"
                    onChange={event => setStoreSearch(event.target.value)}
                  />
                </ContainerSearch>
                <ContainerStores>
                  <ButtonSore type="button" onClick={() => setPercorrer(false)}>
                    <ChevronLeftIcon />
                  </ButtonSore>
                  <StoresContent>
                    {storeListView.map((store, index) => (
                      <StoreBox
                        data-cy="list_stores_carousel"
                        key={index}
                        isActive={store === storeSelected}
                        onClick={() => selectStore(store)}
                        left={storeLeft}>
                        <div>
                          <img alt="" src={store.image.small} />
                        </div>
                        <span>{store.name}</span>
                      </StoreBox>
                    ))}
                    {storeListView.length === 0 && (
                      <div
                        style={{
                          width: '100%',
                          textAlign: 'center',
                          height: '150px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <b>Nenhum resultado encontrado</b>
                      </div>
                    )}
                  </StoresContent>
                  <ButtonSore type="button" onClick={() => setPercorrer(true)}>
                    <ChevronRightIcon />
                  </ButtonSore>
                </ContainerStores>
              </StoresContainer>
            </InputContainer>

            {storeSelected.id != null && (
              <>
                <Title>
                  {storeSelected.name}{' '}
                  <ButtonFilter onClick={toggleFilter}>
                    {viewFilter === 'none' ? (
                      <VisibilityIcon style={{ marginRight: '8px' }} />
                    ) : (
                      <VisibilityOffIcon style={{ marginRight: '8px' }} />
                    )}
                    {viewFilter === 'none' ? 'Ver Filtro' : 'Esconder Filtro'}
                  </ButtonFilter>
                </Title>

                <ProductsByStore
                  slug={storeSelected.slug}
                  storesByCode={storesByCode}
                  selected={productsSelected}
                  action={addProduct}
                  viewFilter={viewFilter}
                  style={{ width: '100%', minHeight: '250px' }}
                />
              </>
            )}

            {product.map(item => (
              <ProductsList
                preco={item.preco}
                key={item.code_internal}
                title={item.name}
                subtitle={item.description}
                data={item.products}
              />
            ))}

            <InputContainer>
              <Title>Produtos Selecionados</Title>
              <ProductsContainer>
                {productsSelected.map(item => (
                  <div
                    data-cy="list_products_selected"
                    style={{ position: 'relative' }}
                    onClick={() => remProduct(item)}>
                    <ImgLogoStore
                      className="img-logo"
                      src={item.logo}
                      style={{ position: 'absolute' }}
                    />
                    <ImgProduct image={item.images[0].sizes.small} />
                    <span>{item.name}</span>
                  </div>
                ))}
              </ProductsContainer>
            </InputContainer>
            <FormButtons
              isLoading={isLoading}
              handleBack={handleBack}
              submitText={submitText}
            />
            {virtualCatalog.id && (
              <CustomButton
                data-cy="btn_download_catalog_details"
                isLoading={virtualCatalogGeneratedLoading}
                label="Baixar Catálogo"
                onClick={onDownloadCatalog}
              />
            )}
          </Card>
        </Form>
      )}
    />
  );
};

VirtualCatalogForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.oneOfType([PropTypes.object]),
  submitText: PropTypes.string,
  handleBack: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isLoading: PropTypes.bool.isRequired,
};

VirtualCatalogForm.defaultProps = {
  initialValues: formInitialValues,
  submitText: 'Salvar',
  handleBack: false,
  onSubmit: () => {},
};

export default VirtualCatalogForm;
