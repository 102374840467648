export const Types = {
  // Obtem usuário logado
  GET_REQUEST_PROFILE: 'user/GET_REQUEST_PROFILE',
  GET_SUCCESS_PROFILE: 'user/GET_SUCCESS_PROFILE',
  GET_FAILURE_PROFILE: 'user/GET_FAILURE_PROFILE',

  // Obtem users
  GET_REQUEST: 'user/GET_REQUEST',
  GET_SUCCESS: 'user/GET_SUCCESS',
  GET_FAILURE: 'user/GET_FAILURE',

  // Obtem users
  GET_LIST_REQUEST: 'user/GET_LIST_REQUEST',
  GET_LIST_SUCCESS: 'user/GET_LIST_SUCCESS',
  GET_LIST_FAILURE: 'user/GET_LIST_FAILURE',

  // Insere uma user
  GET_INSERT_REQUEST: 'user/GET_INSERT_REQUEST',
  GET_INSERT_SUCCESS: 'user/GET_INSERT_SUCCESS',
  GET_INSERT_FAILURE: 'user/GET_INSERT_FAILURE',

  // Atualiza uma user
  GET_UPDATE_REQUEST: 'user/GET_UPDATE_REQUEST',
  GET_UPDATE_SUCCESS: 'user/GET_UPDATE_SUCCESS',
  GET_UPDATE_FAILURE: 'user/GET_UPDATE_FAILURE',

  // Deleta uma user
  GET_DELETE_REQUEST: 'user/GET_DELETE_REQUEST',
  GET_DELETE_SUCCESS: 'user/GET_DELETE_SUCCESS',
  GET_DELETE_FAILURE: 'user/GET_DELETE_FAILURE',

  // cliente
  GET_USER_STATUS_REQUEST: 'user/GET_USER_STATUS_REQUEST',

  GET_USER_UPDATE_EMAIL_REQUEST: 'user/GET_USER_UPDATE_EMAIL_REQUEST',
  GET_USER_UPDATE_EMAIL_SUCCESS: 'user/GET_USER_UPDATE_EMAIL_SUCCESS',
};

export const initialState = {
  // user Logado
  profile: {},
  profileLoading: false,
  profileError: null,

  // user por id
  user: {},
  userLoading: false,
  userError: null,
  // Lista de users
  userList: [],
  userListLoading: false,
  userListError: null,
  userListTotal: 0,
  // Insere uma user
  userInsertLoading: false,
  userInsertError: false,
  // Atualiza uma user
  userUpdateLoading: false,
  userUpdateError: false,
  // Deleta user
  userDeleteLoading: false,
  userDeleteError: null,

  // update email verify
};

export default (state = initialState, action) => {
  switch (action.type) {
    // user Logado
    case Types.GET_REQUEST_PROFILE:
      return {
        ...state,
        profile: {},
        profileError: null,
        profileLoading: true,
      };
    case Types.GET_SUCCESS_PROFILE:
      return {
        ...state,
        profile: action.payload.data,
        profileLoading: false,
        profileError: null,
      };
    case Types.GET_FAILURE_PROFILE:
      return {
        ...state,
        profileLoading: false,
        profileError: action.payload,
      };
    // user por id
    case Types.GET_REQUEST:
      return {
        ...state,
        user: {},
        userError: null,
        userLoading: true,
      };
    case Types.GET_SUCCESS:
      return {
        ...state,
        user: action.payload.data,
        userLoading: false,
        userError: null,
      };
    case Types.GET_FAILURE:
      return {
        ...state,
        userLoading: false,
        userError: action.payload,
      };
    // Lista de users
    case Types.GET_LIST_REQUEST:
      return { ...state, userListLoading: true };
    case Types.GET_LIST_SUCCESS:
      return {
        ...state,
        userList: action.payload.data,
        userListLoading: false,
        userListError: null,
        userListTotal: action.payload.total,
      };
    case Types.GET_LIST_FAILURE:
      return {
        ...state,
        userListLoading: false,
        userListError: action.payload,
      };
    case Types.GET_INSERT_REQUEST:
      return {
        ...state,
        userInsertLoading: true,
        userInsertError: null,
      };
    case Types.GET_INSERT_SUCCESS:
      return {
        ...state,
        userInsertLoading: false,
        userInsertError: null,
      };
    case Types.GET_INSERT_FAILURE:
      return {
        ...state,
        userInsertLoading: false,
        userInsertError: action.payload,
      };
    // Atualiza um user
    case Types.GET_UPDATE_REQUEST:
      return {
        ...state,
        userUpdateLoading: true,
        userUpdateError: null,
      };
    case Types.GET_UPDATE_SUCCESS:
      return {
        ...state,
        userUpdateLoading: false,
        userUpdateError: null,
      };
    case Types.GET_UPDATE_FAILURE:
      return {
        ...state,
        userUpdateLoading: false,
        userUpdateError: action.payload,
      };
    // Deleta uma user
    case Types.GET_DELETE_REQUEST:
      return {
        ...state,
        userDeleteLoading: true,
        userDeleteError: null,
      };
    case Types.GET_DELETE_SUCCESS:
      return {
        ...state,
        userDeleteLoading: false,
        userDeleteError: null,
      };
    case Types.GET_DELETE_FAILURE:
      return {
        ...state,
        userDeleteLoading: false,
        userDeleteError: action.payload,
      };

    case Types.GET_USER_UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        userList: state.userList.map(a =>
          action.payload.data.id === a.id ? action.payload.data : a,
        ),
      };

    default:
      return state;
  }
};

export const Creators = {
  // Busca user logado
  getProfile: data => ({
    type: Types.GET_REQUEST_PROFILE,
    payload: data,
  }),
  getProfileSucess: ({ data }) => ({
    type: Types.GET_SUCCESS_PROFILE,
    payload: { data },
  }),
  getProfileFailure: error => ({
    type: Types.GET_FAILURE_PROFILE,
    payload: error,
  }),
  // Busca uma user
  getUserRequest: ({ id }) => ({
    type: Types.GET_REQUEST,
    payload: { id },
  }),
  getUserSuccess: ({ data }) => ({
    type: Types.GET_SUCCESS,
    payload: { data },
  }),
  getUserFailure: error => ({
    type: Types.GET_FAILURE,
    payload: error,
  }),
  // Insere uma user
  getUserInsertRequest: ({
    email,
    password,
    name,
    cpf,
    genre,
    description,
    cell_phone,
    birth_date,
    store,
    group,
    address,
    image_data,
  }) => ({
    type: Types.GET_INSERT_REQUEST,
    payload: {
      email,
      password,
      name,
      cpf,
      genre,
      description,
      cell_phone,
      birth_date,
      store,
      group,
      address,
      image_data,
    },
  }),
  getUserInsertSuccess: () => ({
    type: Types.GET_INSERT_SUCCESS,
  }),
  getUserInsertFailure: error => ({
    type: Types.GET_INSERT_FAILURE,
    payload: error,
  }),
  // Busca lista de users
  getUserListRequest: ({
    page,
    perPage,
    search,
    orderByColumn,
    orderByDirection,
    group_id,
  }) => ({
    type: Types.GET_LIST_REQUEST,
    payload: {
      page,
      perPage,
      search,
      orderByColumn,
      orderByDirection,
      group_id,
    },
  }),
  getUserListSuccess: ({ data, total }) => ({
    type: Types.GET_LIST_SUCCESS,
    payload: { data, total },
  }),
  getUserListFailure: error => ({
    type: Types.GET_LIST_FAILURE,
    payload: error,
  }),
  // Atualiza uma  user
  // Insere uma user
  getUserUpdateRequest: ({
    id,
    email,
    password,
    name,
    cpf,
    genre,
    description,
    cell_phone,
    birth_date,
    store,
    group,
    address,
    image_data,
  }) => ({
    type: Types.GET_UPDATE_REQUEST,
    payload: {
      id,
      email,
      password,
      name,
      cpf,
      store,
      genre,
      description,
      cell_phone,
      birth_date,
      group,
      address,
      image_data,
    },
  }),
  getUserUpdateSuccess: () => ({
    type: Types.GET_UPDATE_SUCCESS,
  }),
  getUserUpdateFailure: error => ({
    type: Types.GET_UPDATE_FAILURE,
    payload: error,
  }),
  // Deleta uma user
  getUserDeleteRequest: id => ({
    type: Types.GET_DELETE_REQUEST,
    payload: { id },
  }),
  getUserDeleteSuccess: () => ({
    type: Types.GET_DELETE_SUCCESS,
  }),
  getUserDeleteFailure: error => ({
    type: Types.GET_DELETE_FAILURE,
    payload: error,
  }),
  getUserStatusRequest: (id, status, email) => ({
    type: Types.GET_USER_STATUS_REQUEST,
    payload: { client: id, status, email },
  }),
  getEmailVerifyRequest: data => ({
    type: Types.GET_USER_UPDATE_EMAIL_REQUEST,
    payload: data,
  }),
  getEmailVerifySuccess: data => ({
    type: Types.GET_USER_UPDATE_EMAIL_SUCCESS,
    payload: data,
  }),
};
