import React from 'react';
import styles, {
  FooterContainer,
  FooterSubContainer,
  CartContainer,
  TitleContainer,
} from './styles';
import visaFlag from 'images/CardFlags/029-visa.svg';

import amexFlag from 'images/CardFlags/amex.png';
import dinnersFlag from 'images/CardFlags/dinners.png';
import hiperFlag from 'images/CardFlags/hiper.png';
import hipercardFlag from 'images/CardFlags/hipercard.png';
import masterFlag from 'images/CardFlags/master.png';
import boleto from 'images/CardFlags/boleto.png';

import { Box, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import appColors from 'utils/appColors';

const CheckoutFooter = () => {
  const { config } = useSelector(state => state.config);
  const shopping = config.shopping;

  return (
    <CartContainer>
      <FooterContainer flex="1" flexDirection="row" flexWrap="wrap">
        <Box
          display="flex"
          flexDirection="column"
          flex="1"
          style={{
            paddingTop: 40,
            borderTop: `1px solid ${appColors.PRIMARY_COLOR}`,
          }}>
          <FooterSubContainer display="flex" flex="1">
            <TitleContainer display="flex" alignItems="center">
              <Typography style={styles.bigTitlePurple}>
                CENTRAL DE RELACIONAMENTO
              </Typography>
            </TitleContainer>
            <Box
              flex="1"
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="center"
              alignItems="flex-start"
              style={{ flexGrow: 4, marginBottom: 40 }}>
              <img alt="" src={visaFlag} style={styles.imgFlag} />
              <img alt="" src={masterFlag} style={styles.imgFlag} />
              <img alt="" src={amexFlag} style={styles.imgFlag} />
              <img alt="" src={dinnersFlag} style={styles.imgFlag} />
              <img alt="" src={hiperFlag} style={styles.imgFlag} />
              <img alt="" src={hipercardFlag} style={styles.imgFlag} />
              <img alt="" src={boleto} style={styles.imgFlag} />
            </Box>
            <Box
              flex="1"
              display="flex"
              alignItems="flex-end"
              justifyContent="flex-end"
              flexDirection="row">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column">
                <Typography>Política de Privacidade</Typography>
              </Box>
            </Box>
          </FooterSubContainer>
          <Box flex="1" style={{ marginTop: 20 }}>
            {Object.keys(config).length > 0 && (
              <Typography style={{ textAlign: 'center', fontSize: 12 }}>
                Copyright © 2019 {shopping.name}, TODOS OS DIREITOS RESERVADOS.
                Todo o conteúdo do site, todas as fotos, imagens, logotipos,
                marcas, dizeres, som, software, conjunto imagem, layout, trade
                dress, aqui veiculados são de propriedade exclusiva da{' '}
                {shopping.name} . É vedada qualquer reprodução, total ou
                parcial, de qualquer elemento de identidade, sem expressa
                autorização. A violação de qualquer direito mencionado implicará
                na responsabilização cível e criminal nos termos da Lei.{' '}
                {shopping.social_name} - CNPJ: {shopping.cnpj} Endereço: CEP{' '}
                {shopping.address.code_post}, {shopping.address.district}, N
                {shopping.address.number}, {shopping.address.complement},{' '}
                {shopping.address.city.name} - A inclusão no carrinho não
                garante o preço e/ou a disponibilidade do produto. Caso os
                produtos apresentem divergências de valores, o preço válido é o
                exibido na tela de pagamento. Vendas sujeitas a análise e
                disponibilidade de estoque.
              </Typography>
            )}
          </Box>
        </Box>
      </FooterContainer>
    </CartContainer>
  );
};

export default CheckoutFooter;
