import React from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Box, Typography, Button, Collapse } from '@material-ui/core';
import appColors from 'utils/appColors';
import PropTypes from 'prop-types';
import styles from './styles';

const ColapseFilter = ({ title, children, open, nomargin = false }) => {
  const [isOpen, setIsOpen] = React.useState(open);

  const handleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Box
        flex="1"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center">
        <Typography style={styles.smallTextPurple}>{title}</Typography>
        <Button
          variant="contained"
          onClick={handleIsOpen}
          style={{
            width: 30,
            height: 30,
            backgroundColor: 'transparent',
            boxShadow: 'none',
            minWidth: 0,
          }}>
          {isOpen ? (
            <KeyboardArrowUpIcon style={{ color: appColors.SECUNDARY_COLOR }} />
          ) : (
            <KeyboardArrowDownIcon
              style={{ color: appColors.SECUNDARY_COLOR }}
            />
          )}
        </Button>
      </Box>
      <Box
        flex="1"
        display="flex"
        flexDirection="column"
        style={styles.columnsFilter}>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <Box
            flex="1"
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="flex-start"
            style={{ paddingRight: -10 }}>
            {children}
          </Box>
        </Collapse>
      </Box>
    </>
  );
};

ColapseFilter.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

ColapseFilter.defaultProps = {
  open: true,
};

export default ColapseFilter;
