import { call, takeLatest, all, put, take } from 'redux-saga/effects';
import api from 'services/api';
import { Types, Creators } from 'store/ducks/stores/insert';
import { Types as AuthTypes, Creators as AuthCreators } from 'store/ducks/auth';
import moment from 'moment';
import { Creators as NotificationCreators } from 'store/ducks/app';

function* getStoreInsert({ payload }) {
  try {
    const data = payload;
    const response = yield call(api.post, '/v1/site/stores', {
      ...data,
      manager: {
        ...data.manager,
        birth_date: moment(data.manager.birth_date, 'DD/MM/YYYY').format(
          'YYYY-MM-DD'
        ),
      },
    });

    if (response.status !== 200 && response.status !== 201) throw response;

    yield put(Creators.getStoreInsertSuccess());
    yield put(
      AuthCreators.getAuthRequest({
        username: data.manager.email,
        password: data.manager.password,
      })
    );
    const action = yield take([AuthTypes.GET_SUCCESS, AuthTypes.GET_FAILURE]);
    if (action.type === AuthTypes.GET_FAILURE) {
      throw response;
    }
  } catch (err) {
    yield put(
      NotificationCreators.openNotification({
        message: err.data.data.Error || err.data.msg,
        type: 'error',
      })
    );
    yield put(
      Creators.getStoreInsertFailure(err.data.data.Error || err.data.msg)
    );
  }
}

// Individual exports for testing
export default function* storeSaga() {
  yield all([takeLatest(Types.GET_INSERT_REQUEST, getStoreInsert)]);
}
