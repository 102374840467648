// Obtem Lojas por id (code_internal)
export const Types = {
  GET_STORES_BY_CODE_REQUEST: 'stores/GET_STORES_BY_CODE_REQUEST',
  GET_STORES_BY_CODE_SUCCESS: 'stores/GET_STORES_BY_CODE_SUCCESS',
  GET_STORES_BY_CODE_FAILURE: 'stores/GET_STORES_BY_CODE_FAILURE',
};

export const initialState = {
  storesByCode: {},
  storesByCodeLoading: false,
  storesByCodeError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_STORES_BY_CODE_REQUEST:
      return {
        ...state,
        storesByCodeError: null,
        storesByCodeLoading: true,
      };
    case Types.GET_STORES_BY_CODE_SUCCESS:
      return {
        ...state,
        storesByCode: action.payload.data,
        storesByCodeLoading: false,
        storesByCodeError: null,
      };
    case Types.GET_STORES_BY_CODE_FAILURE:
      return {
        ...state,
        storesByCodeLoading: false,
        storesByCodeError: action.payload,
      };
    default:
      return state;
  }
};

export const Creators = {
  getStoresByCodeRequest: slug => ({
    type: Types.GET_STORES_BY_CODE_REQUEST,
    payload: { slug },
  }),
  getStoresByCodeSuccess: ({ data }) => ({
    type: Types.GET_STORES_BY_CODE_SUCCESS,
    payload: { data },
  }),
  getStoresByCodeFailure: error => ({
    type: Types.GET_STORES_BY_CODE_FAILURE,
    payload: error,
  }),
};
