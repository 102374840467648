import styled from 'styled-components';
import { Box } from '@material-ui/core';
import appColors from 'utils/appColors';


export const ImageProducts = styled(Box)`
  && {
    margin-bottom:8px;
    background: url(${props => props.src});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height:300px;

    @media all and (max-width:500px) {
      width:100%;
      min-width:100px;
      height:238px;
      margin-right: 10px;
      justify-content: center;
    }
    @media all and (min-width:501px) and (max-width:1024px){
      width: 210px;
      height:300px;
      margin: 0px 10px;
    }
    @media all and (min-width:841px) {
      justify-content: center;
    }
    @media all and (min-width:1480px) and (max-width:1740px) {
      height:300px;
    }
  }
`;

export const ContainerProducts = styled(Box)`
  && {
    justify-content: flex-start;
    margin-right:-25px;
    align-items:flex-start;
    margin-bottom:30px;
    margin-top:20px;

    @media all and (max-width:500px) {
      justify-content: space-between;
      margin-right:0px;
      padding:5px;
    }
    @media all and (min-width:501px) and (max-width:1024px) {
      justify-content: center;
    }
    @media all and (min-width:1025px) and (max-width:1700px) {
      justify-content: center;
    }
  }
`;

export const BoxProduct = styled(Box)`
  cursor: pointer;
  && {
    padding: 10px;
    width: 20%;

    @media all and (max-width:900px) {
      width:31%;
      padding: 10px;
      min-width:120px;
    }

    @media all and (min-width:900px) and (max-width:1166px) {
      width:25%;
      padding: 10px;
    }

  }
`;

export const BoxInfo = styled(Box)`
  && {
    margin-bottom:20px;
    max-width:280px;
    @media all and (max-width:500px) {
      padding-left:0px;
      max-width:100%;
    }

  }
`;


const styles = {

  purpleTitle: {
    color: appColors.SECUNDARY_COLOR,
    fontWeight: 'bold',
    fontSize: 32,
    textAlign: 'center',
  },
  ProductPrice: {
    color: appColors.SECUNDARY_COLOR,
    fontWeight: 'bold',
    fontSize: 22,
  },
  smallSubTitle: {
    marginTop: 10,
    color: '#373737',
    fontSize: 17,
    textAlign: 'center',
  },
};

export default styles;
