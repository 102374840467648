import { Box, HStack } from '@chakra-ui/react';
import { Button, Typography } from '@material-ui/core';
import styled from 'styled-components';
import appColors from 'utils/appColors';
import Color from 'color';

const bg = appColors.PRIMARY_COLOR;
const bg_pressed = new Color(bg).darken(0.2).string();

export const Container = styled(Box)``;

export const Menu = styled(HStack)`
  padding: 10px 20px;
  background-color: ${p => (p.active ? bg : '#f1f1f1')};
  color: ${p => (p.active ? 'white' : '#444')};
  margin-bottom: 12px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: ${p => (p.active ? bg_pressed : '#ddd')};
  }
`;

export const MenuMobile = styled(HStack)`
  padding: 10px 20px;
  background-color: ${p => (p.active ? bg : '#f1f1f1')};
  color: ${p => (p.active ? 'white' : '#444')};
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: ${p => (p.active ? bg_pressed : '#ddd')};
  }
`;

export const Name = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
`;

export const LogoutButton = styled(Button)`
  border-color: red;
  color: red;
  padding: 10px;
  width: 100%;
  margin-bottom: 40px;
  margin-top: 10px;
`;

export const Title = styled(Typography)`
  padding: 10px 0 5px;
  font-size: 18px;
  color: #444;
`;
