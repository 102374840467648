import React, { useState, useEffect } from 'react';
import CustomButton from 'components/form/CustomButton';
import appColors from 'utils/appColors';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Typography, Popover, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

const Actions = ({ data, onSubmit }) => {
  const {
    config: { shopping },
  } = useSelector(state => state.config);
  const [show, setShow] = useState(true);

  const { shipping_option } = shopping.shopping_global;

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(false);
  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(false);
  };
  const open = Boolean(anchorEl);

  useEffect(() => {
    // aguardando vir do back-and (pablo já fez)
    const timestart = shopping.same_day_time_food_start;
    const timeend = shopping.same_day_time_food_end;
    var dataStart = moment().isAfter(moment(timestart, 'HH:mm'));
    var dataEnd = moment().isBefore(moment(timeend, 'HH:mm'));
    // se o horario de funcionamento for diferente, ele ocuta
    if (!dataStart || !dataEnd) {
      setShow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {show && (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {data.stores.every(e => e.status < 4 || e.status === 16) && (
            <CustomButton
              data-cy="btn_change_status"
              variant="contained"
              style={{
                backgroundColor: appColors.PRIMARY_COLOR,
              }}
              label={'Aceitar pedido'}
              onClick={() => {
                onSubmit({ status: 4 }, data.id, true);
              }}></CustomButton>
          )}
          {data.stores.every(e => e.status === 4) === true &&
            data.original_delivery_name === 'Retirar no Local' && (
              <>
                <Popover
                  id="mouse-over-popover"
                  className={classes.popover}
                  classes={{
                    paper: classes.paper,
                  }}
                  open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus>
                  <Typography>
                    APÓS A ENTREGA DO PRODUTO
                    <br /> PARA O CLIENTE CLIQUE AQUI
                  </Typography>
                </Popover>
                <CustomButton
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                  variant="contained"
                  style={{
                    backgroundColor: '#2980B9',
                    marginRight: 5,
                  }}
                  label={'Entregar p/ Cliente'}
                  onClick={() => {
                    onSubmit({ status: 7 }, data.id);
                  }}></CustomButton>
              </>
            )}

          {data.stores.every(e => e.status === 4) === true &&
            data.original_delivery_name !== 'Retirar no Local' &&
            shipping_option !== 'store' && (
              <>
                <Popover
                  id="mouse-over-popover"
                  className={classes.popover}
                  classes={{
                    paper: classes.paper,
                  }}
                  open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus>
                  <Typography>
                    CASO O PEDIDO JÁ ESTEJA PREPARADO, SOLICITE <br /> A
                    RETIRADA PELO NOSSO CENTRO DE DISTRIBUIÇÃO
                  </Typography>
                </Popover>
                <CustomButton
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                  variant="contained"
                  style={{ backgroundColor: '#E67E22', marginRight: 5 }}
                  label="Chamar CD"
                  onClick={() =>
                    onSubmit({ status: 5 }, data.id)
                  }></CustomButton>
              </>
            )}
          {data.stores.every(e => e.status === 4) === true &&
            shipping_option === 'store' &&
            data.original_delivery_name !== 'Retirar no Local' && (
              <CustomButton
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                variant="contained"
                style={{ backgroundColor: '#9b59b6', marginRight: 5 }}
                label="Enviar para entrega"
                onClick={() => onSubmit({ status: 6 }, data.id)}></CustomButton>
            )}

          {data.stores.every(e => e.status === 6) === true &&
            shipping_option === 'store' &&
            data.original_delivery_name !== 'Retirar no Local' && (
              <CustomButton
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                variant="contained"
                style={{ backgroundColor: '#c78355', marginRight: 5 }}
                label="Entregue"
                onClick={() => onSubmit({ status: 7 }, data.id)}></CustomButton>
            )}
        </div>
      )}
      {!show && data.stores.every(e => e.status <= 5) && (
        <p style={{ color: '#777', width: 150, fontSize: 12 }}>
          Horário do Centro de Distribuições é de{' '}
          {moment(shopping.same_day_time_food_start, 'HH:mm').format('HH:mm')}{' '}
          até as{' '}
          {moment(shopping.same_day_time_food_end, 'HH:mm').format('HH:mm')}.
        </p>
      )}
      {!show && data.stores.every(e => e.status <= 3) && (
        <p style={{ color: '#777', width: 150, fontSize: 12 }}>
          Pedidos para Entregar em Casa só podem ser iniciados durante este
          horário.
        </p>
      )}
    </div>
  );
};

export default Actions;
