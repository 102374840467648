export const menuList = {
  // MINHA CONTA
  dashboard: {
    title: 'Minha Conta',
    show: [1, 2, 3, 4, 5, 7, 8, 35],
    name: 'Dashboard',
    icon: 'dashboard',
    data_cy: 'menu_dashboard',
    data_cy_mobile: 'menu_mobile_dashboard',
  },
  cash_express: {
    show: [3, 4],
    name: 'Transferência',
    icon: 'attach_money',
    data_cy: 'menu_attach_money',
    data_cy_mobile: 'menu_mobile_attach_money',
  },
  documents: {
    show: [3, 4],
    name: 'Documentos',
    icon: 'account_box',
    data_cy: 'menu_documents',
    data_cy_mobile: 'menu_mobile_documents',
  },
  'relatorio-vendas': {
    show: [5000000],
    name: 'Relatório de Comissões',
    icon: 'receipt',
    data_cy: 'menu_commission_report',
    data_cy_mobile: 'menu_mobile_attach_money',
  },
  // tranferencia: {
  //   show: [3, 4],
  //   name: 'Transferência',
  //   icon: 'TransferWithinAStationSharpIcon',
  //   data_cy: 'menu_tranfer',
  //   data_cy_mobile: 'menu_mobile_attach_money',
  // },
  // antecipacao: {
  //   show: [3, 4],
  //   name: 'Antecipação',
  //   icon: 'update',
  //   data_cy: 'menu_anticipation',
  //   data_cy_mobile: 'menu_mobile_attach_money',
  // },
  extrato: {
    show: [3, 4],
    name: 'Extrato',
    icon: 'subject',
    data_cy: 'menu_etract',
    data_cy_mobile: 'menu_mobile_attach_money',
  },
  ReportTransfer: {
    show: [500],
    name: 'ReportTransfer',
    icon: 'subject',
    data_cy: 'menu_etract',
    data_cy_mobile: 'menu_mobile_attach_ReportTransfer',
  },
  indicacao: {
    show: [4],
    name: 'Indicação',
    icon: 'account_tree',
    data_cy: 'menu_account_tree',
    data_cy_mobile: 'menu_mobile_attach_money',
  },
  cancel: {
    show: [3],
    name: 'Cancelamentos',
    icon: 'block',
    data_cy: 'menu_cancellations',
    data_cy_mobile: 'menu_mobile_cancellations',
  },

  // MINHA LOJA
  'pedidos-loja': {
    title: 'Minha Loja',
    show: [3, 6],
    name: 'Minhas Vendas',
    icon: 'subtitles',
    data_cy: 'menu_my_sales',
    data_cy_mobile: 'menu_my_sales',
  },
  catalogo: {
    show: [3, 4, 7],
    name: 'Catálogo Virtual',
    icon: 'ballot',
    data_cy: 'menu_virtual_catalog',
    data_cy_mobile: 'menu_mobile_virtual_catalog',
  },
  produtos: {
    show: [3, 7],
    name: 'Meus produtos',
    icon: 'storage',
    data_cy: 'menu_my_product',
    data_cy_mobile: 'menu_mobile_my_product',
  },
  loja: {
    show: [3],
    name: 'Dados da Loja',
    icon: 'store',
    data_cy: 'menu_store_dados',
    data_cy_mobile: 'menu_mobile_store_dados',
  },
  webhook: {
    show: [3, 4],
    name: 'Webhook',
    icon: 'assignment',
    data_cy: 'menu_webhook',
    data_cy_mobile: 'menu_mobile_webhook',
  },
  Clientes: {
    show: [5],
    name: 'Clientes/ Parceiros',
    icon: 'account_box',
    data_cy: 'menu_partner',
    data_cy_mobile: 'menu_mobile_partner',
  },
  ClientesDeleted: {
    show: [500],
    name: 'ClientesDeleted',
    icon: 'store',
    data_cy: 'menu_partner',
    data_cy_mobile: 'menu_mobile_partner',
  },
  ClientesNew: {
    show: [500],
    name: 'ClientesNew',
    icon: 'store',
    data_cy: 'menu_partner',
    data_cy_mobile: 'menu_mobile_partner',
  },
  'payment-mothods': {
    show: [3],
    name: 'Métodos de Pagamento',
    icon: 'payment',
    data_cy: 'payment-mothods',
    data_cy_mobile: 'menu_mobile_payment-mothods',
  },
  closed: {
    show: [3],
    name: 'Configuração de Entrega',
    icon: 'calendar_today',
    data_cy: 'menu_delivery_setting',
    data_cy_mobile: 'menu_mobile_delivery_setting',
  },

  // MEU USUÁRIO
  'meus-pedidos': {
    title: 'Meu Usuário',
    show: [4, 5],
    name: 'Minhas Compras',
    icon: 'shopping_basket',
    data_cy: 'menu_my_orders',
    data_cy_mobile: 'menu_mobile_my_orders',
  },
  conta: {
    show: [4, 3, 5, 7],
    name: 'Meu Perfil',
    icon: 'account_box',
    data_cy: 'menu_my_profile',
    data_cy_mobile: 'menu_mobile_my_profile',
  },
  companyReseler: {
    show: [4],
    name: 'Minha loja',
    icon: 'account_box',
    data_cy: 'menu_my_profile',
    data_cy_mobile: 'menu_mobile_my_profile',
  },
  endereco: {
    show: [4, 3, 5],
    name: 'Endereços',
    icon: 'location_on',
    data_cy: 'menu_address',
    data_cy_mobile: 'menu_mobile_address',
  },
  Installments: {
    show: [5],
    name: 'Financeiro',
    icon: 'attach_money',
    data_cy: 'menu_my_profile',
    data_cy_mobile: 'menu_mobile_my_profile',
  },
  InstallmentsResseler: {
    show: [3, 4],
    name: 'Listar Cobranças',
    icon: 'list',
    data_cy: 'menu_my_profile',
    data_cy_mobile: 'menu_mobile_my_profile',
  },
  sendDealerCharge: {
    show: [3, 4],
    name: 'Enviar cobrança',
    icon: 'attach_money',
    data_cy: 'menu_my_profile',
    data_cy_mobile: 'menu_mobile_my_profile',
  },
  termos: {
    show: [4, 3, 5],
    name: 'Termos',
    icon: 'assignment',
    data_cy: 'menu_terms',
    data_cy_mobile: 'menu_mobile_terms',
  },
};

export const tabsName = {
  null: 0,
  dashboard: 0,
  'pedidos-loja': 1,
  revendedor: 2,
  endereco: 2,
  conta: 3,
  companyReseler: 91,
  Installments: 44,
  InstallmentsResseler: 45,
  sendDealerCharge: 46,
  produtos: 4,
  'novo-produto': 5,
  id_pedido: 6,
  catalogo: 7,
  'novo-catalogo': 8,
  id_catalogo: 9,
  Plots_id: 47,
  id_produto: 10,
  cash_express: 11,
  documents: 110,
  tranferencia: 12,
  loja: 15,
  vendas: 18,
  'meus-pedidos': 18,
  'relatorio-vendas': 19,
  'relatorio-comissoes': 20,
  'promocoes-revenda': 22,
  'novo-cupom': 23,
  antecipacao: 24,
  extrato: 25,
  cancel: 34,
  termos: 31,
  closed: 32,
  'produtos-destaque': 33,
  'reseller-cash_express': 21,
  indicacao: 40,
  'payment-mothods': 41,
  Clientes: 70,
  ClientesNew: 71,
  ClientesDeleted: 72,
  id_collaborator: 42,
  ReportTransfer: 90,
  webhook: 400,
};
