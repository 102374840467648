/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PageBase from 'components/PageBase';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as UserCreators } from 'store/ducks/admin/user';
import HeaderComponent from 'components/HeaderComponent';
import UserForm from 'components/UserPage/UserForm';
import { push } from 'connected-react-router';
import useQuery from 'hooks/useQuery';

const UserUpdatePage = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const [localState, setLocalState] = React.useState(null);
  const { user, userLoading, userUpdateLoading } = useSelector(
    state => state.admin.user
  );

  const getInitialData = () => {
    dispatch(UserCreators.getUserRequest({ id: Number(query.get('id_user')) }));
    setLocalState(null);
  };

  React.useEffect(() => {
    getInitialData();
  }, []);

  React.useEffect(() => {
    if (
      Object.keys(user).length > 0 &&
      !userLoading &&
      user.id === Number(query.get('id_user'))
    ) {
      setLocalState({
        ...user,
        password: '',
        image_info: user.image,
        image: '',
      });
    }
  }, [user]);

  const onSubmit = data => {
    dispatch(UserCreators.getUserUpdateRequest(data));
  };

  const handleBack = () => {
    dispatch(push(`/users`));
  };

  return (
    <PageBase>
      <HeaderComponent title="Atualizar usuário" />
      {localState && (
        <UserForm
          initialValues={localState}
          onSubmit={onSubmit}
          handleBack={handleBack}
          isLoading={userUpdateLoading}
        />
      )}
    </PageBase>
  );
};

export default UserUpdatePage;
