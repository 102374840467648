import React from 'react';
import { useDispatch } from 'react-redux';
import { Creators as AuthCreators } from 'store/ducks/auth';
import { CardContainerLogin } from '../../../styles';
import BoxLogin from 'components/Pages/LoginPage/BoxLogin';
import BoxCLientRegister from 'components/Pages/LoginPage/BoxCLientRegister';

const FormLogin = ({ setData, link, openForgot }) => {
  const dispatch = useDispatch();

  const handleLogin = data => {
    dispatch(
      AuthCreators.getAuthRequest(
        data,
        typeof link != 'undefined' ? link.redirect : '/',
        setData
      )
    );
  };

  return (
    <CardContainerLogin>
      <BoxLogin openModal={openForgot} onSubmit={handleLogin} />
      <BoxCLientRegister
        link={typeof link != 'undefined' ? link.redirect : '/minha-conta'}
      />
    </CardContainerLogin>
  );
};

export default FormLogin;
