import api from 'services/api';
import { Creators as NotificationCreators } from 'store/ducks/app';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Creators as PanelTabCreators } from 'store/ducks/panelTabs';
import { useHistory } from 'react-router-dom';

export const useInvoice = () => {
  const [invoiceRate, setInvoiceRate] = useState();
  const [invoiceRateLoading, setInvoiceRateLoading] = useState(false);
  const [invoiceData, setInvoiceData] = useState();
  const [invoiceSum, setInvoiceSum] = useState();
  const [invoiceDetailData, setInvoiceDetailData] = useState();
  const [DeleteCharges, setDeleteCharges] = useState();

  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [invoiceDetailLoading, setInvoiceDetailLoading] = useState(false);
  const [sendInvoiceLoading, setSendInvoiceLoading] = useState(false);
  const [DeleteChargesLoading, setDeleteChargesLoading] = useState(false);

  const [invoiceListTotal, setInvoiceListTotal] = useState(0);
  const [invoiceDetailTotal, setInvoiceDetailTotal] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();

  const sendInvoice = async data => {
    try {
      setSendInvoiceLoading(true);
      const response = await api.post(`/v1/admin/invoices`, data);
      if (response.status !== 201) {
        throw response;
      }
      if (response.status === 201) {
        dispatch(
          NotificationCreators.openNotification({
            message: `Cobrança realizada com sucesso!!`,
            type: 'success',
          })
        );
        // window.location.reload();
      }
      setSendInvoiceLoading(false);
      history.push({
        search: `?aba=InstallmentsResseler`,
      });
      dispatch(PanelTabCreators.changeTab(45, {}));
    } catch (err) {
      console.log(err, 'err');
      dispatch(
        NotificationCreators.openNotification({
          message: `${err?.data?.msg}`,
          type: 'error',
        })
      );
      setSendInvoiceLoading(false);
    }
  };

  const GetInvoice = async localState => {
    try {
      setInvoiceLoading(true);
      const response = await api.get(`/v1/admin/invoices`, localState);
      if (response.status === 200) {
        setInvoiceData(response.data.data);
        setInvoiceSum(response.data.summary);
        setInvoiceListTotal(response.data.pagination.total);
      }
      setInvoiceLoading(false);
    } catch (error) {
      console.log(error, 'aquiii');
      setInvoiceLoading(false);
    }
  };
  const GetInvoiceRate = async () => {
    try {
      setInvoiceRateLoading(true);
      const response = await api.get(`/v1/admin/invoices/last_percentage`);
      if (response.status === 200 || response.status === 201) {
        setInvoiceRate(response.data.data);
      }
      setInvoiceRateLoading(false);
    } catch (error) {
      console.log(error);
      setInvoiceRateLoading(false);
    }
  };

  const GetInvoiceDetail = async (id, localState) => {
    try {
      setInvoiceDetailLoading(true);
      const response = await api.get(`/v1/admin/invoices/${id}`, localState);
      if (response.status === 201) {
        setInvoiceDetailData(response.data.data);
        setInvoiceDetailTotal(response.data.pagination.total);
      }
      setInvoiceDetailLoading(false);
    } catch (error) {
      console.log(error);
      setInvoiceDetailLoading(false);
    }
  };
  const DeleteChargesReq = async (payload, Plots_id) => {
    setDeleteChargesLoading(true);

    try {
      const response = await api.delete(
        `/v1/admin/invoices/${Plots_id}/installments/${payload.id}`
      );
      setDeleteChargesLoading(false);
      setDeleteCharges(response.data.data.data);
      dispatch(
        NotificationCreators.openNotification({
          type: 'success',
          message: 'Cobrança Deletada com sucesso',
        })
      );
      window.location.reload();
    } catch (err) {
      setDeleteChargesLoading(false);
      dispatch(
        NotificationCreators.openNotification({
          message: err.data.data.Error,
          type: 'error',
        })
      );
    }
  };

  return {
    sendInvoice,
    GetInvoice,
    invoiceData,
    invoiceSum,
    invoiceLoading,
    GetInvoiceRate,
    invoiceRate,
    invoiceRateLoading,
    invoiceListTotal,
    invoiceDetailData,
    invoiceDetailTotal,
    invoiceDetailLoading,
    GetInvoiceDetail,
    sendInvoiceLoading,
    DeleteChargesReq,
    DeleteCharges,
    DeleteChargesLoading,
  };
};
