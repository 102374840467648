import { useDispatch } from 'react-redux';
import api from 'services/api';
import { Creators as NotificationCreators } from 'store/ducks/app';
import { useState } from 'react';

export const useTransfer = () => {
  const dispatch = useDispatch();
  const [transationList, setTransationList] = useState();
  const [transationDetail, setTransationDetail] = useState();
  const [transationListLoading, setTransationListLoading] = useState(false);
  const [transationDetailLoading, setTransationDetailLoading] = useState(false);
  let [transationDetailModalOpen, setTransationDetailModalOpen] =
    useState(false);
  const [transationTotalList, setTransationTotalList] = useState();
  const getTransfers = async localState => {
    try {
      const { dateStart, dateEnd, perPage, page, status } = localState;
      setTransationListLoading(true);
      const response = await api.get(
        `/v1/admin/wallets/transfers?date_start=${dateStart}&date_end=${dateEnd}&page=${page}&per_page=${perPage}&status=${status}`
      );
      if (response.status === 200) {
        setTransationList(response.data.data);
        setTransationTotalList(response.data.pagination.total);
        setTransationListLoading(false);
      }
    } catch (error) {
      console.log(error);
      dispatch(
        NotificationCreators.openNotification({
          message: 'Erro ao carregar dados',
          type: 'error',
        })
      );
      setTransationListLoading(false);
    }
  };
  const getTransfersDetails = async transfer_id => {
    try {
      setTransationDetailLoading(true);
      const response = await api.get(
        `/v1/admin/wallets/transfers?transfer_id=${transfer_id}`
      );
      if (response.status === 200) {
        setTransationDetail(response.data.data);
        setTransationDetailLoading(false);
        setTransationDetailModalOpen(true);
      }
      dispatch(
        NotificationCreators.openNotification({
          message: 'Dados carregados com sucesso',
          type: 'success',
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        NotificationCreators.openNotification({
          message: 'Erro ao carregar',
          type: 'error',
        })
      );
      setTransationDetailLoading(false);
    }
  };

  return {
    getTransfers,
    transationList,
    transationTotalList,
    transationListLoading,
    getTransfersDetails,
    transationDetailLoading,
    transationDetail,
    transationDetailModalOpen,
    setTransationDetailModalOpen,
  };
};
