import { useState } from 'react';
import { useSelector } from 'react-redux';
import api from 'services/api';

function useCatalog() {
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState({});
  const [catalog, setCatalog] = useState(null);
  const [gallery, setSetGallery] = useState(null);

  const {
    client: { client },
  } = useSelector(state => state);

  const getProduct = async (id_catalog, id_product) => {
    try {
      setLoading(true);
      const response = await api.get(
        `/v1/site/catalogs/${id_catalog}/products/${id_product}`
      );
      if (response.data.status !== 'OK') {
        throw response.data;
      }
      setProduct(response.data.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const getCatalog = async id => {
    setLoading(true);
    const response = await api.get(`/v1/admin/virtual/catalogs/${id}`);
    if (response.data.status !== 'OK') {
      throw response.data;
    }
    setCatalog(response.data.data);
    setLoading(false);
    return response.data.data;
  };

  const getGallery = async id => {
    setLoading(true);
    const response = await api.get(`/v1/admin/stores/gallery/${id}`);
    if (response.data.status !== 'OK') {
      throw response.data;
    }
    setSetGallery(response.data.data);
    setLoading(false);
    return response.data.data;
  };

  const getProductByCategory = async (id, quantity) => {
    try {
      setLoading(true);
      const res = await api.get(`/v1/site/categories/${id}/products`, {
        per_page: quantity,
        page: 1,
        store_id: client.store.id,
      });
      setLoading(false);
      return res.data.data;
    } catch (error) {
      //
    }
  };

  const [bannners, setBanners] = useState([]);
  const getBanners = async per_page => {
    setLoading(true);
    const res = await api.get(`/v1/site/catalogs/banner_images`, {
      per_page,
    });
    setLoading(false);
    setBanners(res.data.data);
    return res.data.data;
  };

  return {
    getProduct,
    getCatalog,
    getProductByCategory,
    getBanners,
    getGallery,

    bannners,
    product,
    loading,
    catalog,
    gallery,
  };
}

export default useCatalog;
