// @flow
import React from 'react';
import ProductsByStorePage from 'components/Pages/ProductsByStorePage';
import { Creators as StoreByCodeCreators } from 'store/ducks/stores/byCode';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress, Box } from '@material-ui/core';
import appColors from 'utils/appColors';
import { StoreDetailContainer } from './styles';
import { Helmet } from 'react-helmet';

const StoreDetailsPage = ({ match }) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(StoreByCodeCreators.getStoresByCodeRequest(match.params.slug));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { storesByCode, storesByCodeLoading } = useSelector(
    state => state.stores.byCode
  );

  return (
    <StoreDetailContainer>
      {!storesByCodeLoading && Object.keys(storesByCode).length > 0 && (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Loja - {storesByCode.name}</title>
            <link rel="canonical" href="/" />
          </Helmet>
          <ProductsByStorePage
            slug={match.params.slug}
            storesByCode={storesByCode}
          />
        </>
      )}
      {storesByCodeLoading && (
        <Box
          flex="1"
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: 800 }}>
          <CircularProgress style={{ color: appColors.PRIMARY_COLOR }} />
        </Box>
      )}
    </StoreDetailContainer>
  );
};

export default StoreDetailsPage;
