import appColors from "utils/appColors";

const styles = () => ({
  statContainer: {
    marginTop: -22,
    boxShadow: '1px 2px 6px rgba(0,0,0,0.2)',
    marginLeft: '15px',
    padding: '10px',
    backgroundColor: appColors.LIGHT_COLOR,
  
  },
  statIconContainer: {
    paddingRight: '16px',
    paddingTop: '6px'
  },
  statIcon: {
    color: appColors.COLOR_ICON,
    fontSize: '32px',
    fontweight: 'bold',
    float: 'right'
  },
  statContent: {
    float: 1,
  },
  statDescription: {
    color: appColors.COLOR_ICON,
    fontSize: '70px',
    lineHeight: 0.7,
  },

  statTitle: {
    color: '#606060',
    paddingLeft: 30,
    paddingRight: 30,
    fontweight: '900',
    fontSize: '26px'
  },

  statSubDescription: {
    color: '#606060',
    fontSize: '18px',
    marginRight: '40px',
    marginLeft: '40px'
  },
});
export default styles;
