/* eslint-disable require-yield */
// @flow
import { type AxiosResponse } from 'axios';

const successResponseCodes = [
  '200',
  '201',
  '202',
  '203',
  '204',
  '205',
  '206',
  '207',
  '208',
];

function* interceptResponse(response: AxiosResponse, throwError = true) {
  const success = successResponseCodes.includes(String(response.status));
  if (!success) {
    if (throwError) {
      throw response;
    } else {
      return false;
    }
  }
  return true;
}

export default interceptResponse;
