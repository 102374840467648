export const Types = {
  // Obtem usuário logado
  GET_REQUEST_PROFILE: 'partner/GET_REQUEST_PROFILE',
  GET_SUCCESS_PROFILE: 'partner/GET_SUCCESS_PROFILE',
  GET_FAILURE_PROFILE: 'partner/GET_FAILURE_PROFILE',

  // Obtem users
  GET_REQUEST: 'partner/GET_REQUEST',
  GET_SUCCESS: 'partner/GET_SUCCESS',
  GET_FAILURE: 'partner/GET_FAILURE',

  // Obtem users
  GET_LIST_REQUEST: 'partner/GET_LIST_REQUEST',
  GET_LIST_SUCCESS: 'partner/GET_LIST_SUCCESS',
  GET_LIST_FAILURE: 'partner/GET_LIST_FAILURE',

  // Insere uma user
  GET_INSERT_REQUEST: 'partner/GET_INSERT_REQUEST',
  GET_INSERT_SUCCESS: 'partner/GET_INSERT_SUCCESS',
  GET_INSERT_FAILURE: 'partner/GET_INSERT_FAILURE',

  // Atualiza uma user
  GET_UPDATE_REQUEST: 'partner/GET_UPDATE_REQUEST',
  GET_UPDATE_SUCCESS: 'partner/GET_UPDATE_SUCCESS',
  GET_UPDATE_FAILURE: 'partner/GET_UPDATE_FAILURE',

  // Deleta uma user
  GET_DELETE_REQUEST: 'partner/GET_DELETE_REQUEST',
  GET_DELETE_ID_REQUEST: 'partner/GET_DELETE_ID_REQUEST',
  GET_DELETE_SUCCESS: 'partner/GET_DELETE_SUCCESS',
  GET_DELETE_FAILURE: 'partner/GET_DELETE_FAILURE',

  // cliente
  GET_USER_STATUS_REQUEST: 'partner/GET_USER_STATUS_REQUEST',
};

export const initialState = {
  // user por id
  partner: {},
  partnerLoading: false,
  partnerError: null,
  // Lista de partners
  partnerList: [],
  partnerListLoading: false,
  partnerListError: null,
  partnerListTotal: 0,
  // Insere uma partner
  partnerInsertLoading: false,
  partnerInsertError: false,
  // Atualiza uma partner
  partnerUpdateLoading: false,
  partnerUpdateError: false,
  // Deleta partner
  partnerDeleteLoading: false,
  partnerDeleteError: null,

  // update email verify
};

export default (state = initialState, action) => {
  switch (action.type) {
    // partner Logado
    case Types.GET_REQUEST_PROFILE:
      return {
        ...state,
        profile: {},
        profileError: null,
        profileLoading: true,
      };
    case Types.GET_SUCCESS_PROFILE:
      return {
        ...state,
        profile: action.payload.data,
        profileLoading: false,
        profileError: null,
      };
    case Types.GET_FAILURE_PROFILE:
      return {
        ...state,
        profileLoading: false,
        profileError: action.payload,
      };
    // partner por id
    case Types.GET_REQUEST:
      return {
        ...state,
        partner: {},
        partnerError: null,
        partnerLoading: true,
      };
    case Types.GET_SUCCESS:
      return {
        ...state,
        partner: action.payload.data,
        partnerLoading: false,
        partnerError: null,
      };
    case Types.GET_FAILURE:
      return {
        ...state,
        partnerLoading: false,
        partnerError: action.payload,
      };
    // Lista de partners
    case Types.GET_LIST_REQUEST:
      return { ...state, partnerListLoading: true };
    case Types.GET_LIST_SUCCESS:
      return {
        ...state,
        partnerList: action.payload.data,
        partnerListLoading: false,
        partnerListError: null,
        partnerListTotal: action.payload.total,
      };
    case Types.GET_LIST_FAILURE:
      return {
        ...state,
        partnerListLoading: false,
        partnerListError: action.payload,
      };
    case Types.GET_INSERT_REQUEST:
      return {
        ...state,
        partnerInsertLoading: true,
        partnerInsertError: null,
      };
    case Types.GET_INSERT_SUCCESS:
      return {
        ...state,
        partnerInsertLoading: false,
        partnerInsertError: null,
      };
    case Types.GET_INSERT_FAILURE:
      return {
        ...state,
        partnerInsertLoading: false,
        partnerInsertError: action.payload,
      };
    // Atualiza um partner
    case Types.GET_UPDATE_REQUEST:
      return {
        ...state,
        partnerUpdateLoading: true,
        partnerUpdateError: null,
      };
    case Types.GET_UPDATE_SUCCESS:
      return {
        ...state,
        partnerUpdateLoading: false,
        partnerUpdateError: null,
      };
    case Types.GET_UPDATE_FAILURE:
      return {
        ...state,
        partnerUpdateLoading: false,
        partnerUpdateError: action.payload,
      };
    // Deleta uma partner
    case Types.GET_DELETE_REQUEST:
      return {
        ...state,
        partnerDeleteLoading: true,
        partnerDeleteError: null,
      };
    case Types.GET_DELETE_ID_REQUEST:
      return {
        ...state,
        partnerDeleteLoading: true,
        partnerDeleteError: null,
      };
    case Types.GET_DELETE_SUCCESS:
      return {
        ...state,
        partnerDeleteLoading: false,
        partnerDeleteError: null,
      };
    case Types.GET_DELETE_FAILURE:
      return {
        ...state,
        partnerDeleteLoading: false,
        partnerDeleteError: action.payload,
      };

    default:
      return state;
  }
};

export const Creators = {
  // Busca user logado
  getProfile: data => ({
    type: Types.GET_REQUEST_PROFILE,
    payload: data,
  }),
  getProfileSucess: ({ data }) => ({
    type: Types.GET_SUCCESS_PROFILE,
    payload: { data },
  }),
  getProfileFailure: error => ({
    type: Types.GET_FAILURE_PROFILE,
    payload: error,
  }),
  // Busca uma user
  getPartnerRequest: ({ id }) => ({
    type: Types.GET_REQUEST,
    payload: { id },
  }),
  getPartnerSuccess: ({ data }) => ({
    type: Types.GET_SUCCESS,
    payload: { data },
  }),
  getPartnerFailure: error => ({
    type: Types.GET_FAILURE,
    payload: error,
  }),
  // Insere uma parceiro
  getPartnerInsertRequest: data => ({
    type: Types.GET_INSERT_REQUEST,
    payload: data,
  }),
  getPartnerInsertSuccess: () => ({
    type: Types.GET_INSERT_SUCCESS,
  }),
  getPartnerInsertFailure: error => ({
    type: Types.GET_INSERT_FAILURE,
    payload: error,
  }),
  // Busca lista de users
  getPartnerListRequest: (
    { page, perPage, search, orderByColumn, orderByDirection, group_id },
    callback = null
  ) => ({
    type: Types.GET_LIST_REQUEST,
    payload: {
      page,
      perPage,
      search,
      orderByColumn,
      orderByDirection,
      group_id,
      callback,
    },
  }),
  getPartnerListSuccess: ({ data, total }) => ({
    type: Types.GET_LIST_SUCCESS,
    payload: { data, total },
  }),
  getPartnerListFailure: error => ({
    type: Types.GET_LIST_FAILURE,
    payload: error,
  }),
  // Atualiza uma  user
  // Insere uma user
  getPartnerUpdateRequest: data => ({
    type: Types.GET_UPDATE_REQUEST,
    payload: data,
  }),
  getPartnerUpdateSuccess: () => ({
    type: Types.GET_UPDATE_SUCCESS,
  }),
  getPartnerUpdateFailure: error => ({
    type: Types.GET_UPDATE_FAILURE,
    payload: error,
  }),
  // Deleta uma user
  getPartnerDeleteRequest: ({ id }) => ({
    type: Types.GET_DELETE_REQUEST,
    payload: { id },
  }),

  getPartnerIdDeleteRequest: ({ id }) => ({
    type: Types.GET_DELETE_ID_REQUEST,
    payload: { id },
  }),
  getPartnerDeleteSuccess: () => ({
    type: Types.GET_DELETE_SUCCESS,
  }),
  getPartnerDeleteFailure: error => ({
    type: Types.GET_DELETE_FAILURE,
    payload: error,
  }),
  getPartnerStatusRequest: (id, status, email) => ({
    type: Types.GET_USER_STATUS_REQUEST,
    payload: { client: id, status, email },
  }),
  getEmailVerifyRequest: data => ({
    type: Types.GET_USER_UPDATE_EMAIL_REQUEST,
    payload: data,
  }),
  getEmailVerifySuccess: data => ({
    type: Types.GET_USER_UPDATE_EMAIL_SUCCESS,
    payload: data,
  }),
};
