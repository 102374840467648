import React from 'react';
import { Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import appColors from 'utils/appColors';

const StaticPanelCountUsersDashboard = ({ classes, title, description }) => (
  <Paper className={classes.statContainer}>
    <div className={classes.statContent}>
      <div className={classes.statTitle}>
        <center>{title}</center>
      </div>
      <div className={classes.statDescription}>
        <center>
          <h2>{description}</h2>
        </center>
      </div>
      <div
        style={{
          backgroundColor: appColors.PRIMARY_COLOR,
          height: 1,
          marginBottom: 15,
          marginTop: 15,
        }}
      />
      <div className={classes.statSubDescription}>
        <center>
          Todas vez que <br />o cliente compra <br />
          você ganha.
        </center>
      </div>
    </div>
  </Paper>
);

StaticPanelCountUsersDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default withStyles(theme => styles(theme))(
  StaticPanelCountUsersDashboard
);
