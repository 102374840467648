import styled from 'styled-components';
import { Box } from '@material-ui/core';
import appColors from 'utils/appColors';

export const PriceContainer = styled(Box)`
  && {
    @media all and (max-width: 500px){
      display:none;
    }
  }
`;

export const DateContainer = styled(Box)`
  && {
   
  }
`;

export const StatusContainerStyled = styled(Box)`
  && {
    @media all and (max-width: 500px){
      display:none;
    }
  }
`;

export const OrderContainer = styled(Box)`
  && {
    @media all and (max-width: 500px){
      display:none;
    }
  }
`;



const styles = {
  containerItem:{
    border:`1px solid ${appColors.PRIMARY_COLOR}`,
    marginTop:20,
    borderRadius:5,
  },
  headerPurple:{
    color: appColors.PRIMARY_COLOR
  },
  fontBold:{
    fontWeight:'bold',
  },
  status:{
    fontWeight:'bold',
    color:'white',
    fontSize: '12px'
  },
  containerStatus:{
    backgroundColor: appColors.PRIMARY_COLOR,
    padding:8,
    borderRadius:5,
    width: '160px'
  },
  iconExpand:{
    color: appColors.PRIMARY_COLOR,
    cursor:'pointer'
  }
}

export default styles;