import React from 'react';
import styles from './styles';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import ForgotForm from 'components/Pages/ForgotModalPage/ForgotForm';
import { useDispatch } from 'react-redux';
import { Creators as ClientCreators } from 'store/ducks/client';
import CustomModal from 'components/Modal';

const ForgotModalPage = ({ isOpen, closeModal }) => {
  const dispatch = useDispatch();

  const handleForgot = async data => {
    dispatch(ClientCreators.getClientForgotRequest(data, closeModal));
  };

  return (
    <CustomModal
      isOpen={isOpen}
      closeModal={closeModal}
      title="Recuperar Senha">
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        flex="1"
        style={styles.containerForm}>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          flex="1"
          alignItems="center"
          style={{ marginBottom: 20 }}>
          <Typography style={styles.purpleTitle}>
            Esqueceu sua senha?
          </Typography>
          <Typography style={styles.subTitle}>
            Informe o e-mail cadastrado de recuperação
          </Typography>
        </Box>
        <ForgotForm onSubmit={handleForgot} cancel={closeModal} />
      </Box>
    </CustomModal>
  );
};

ForgotModalPage.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ForgotModalPage;
