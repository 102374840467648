import React from 'react';
import { StyledScrollContainer, BoxThumbnail } from './styles';
import { Box, Typography } from '@material-ui/core';

const CategoriesSlide = ({ data }) => {
  return (
    <StyledScrollContainer
      className="scroll-container"
      nativeMobileScroll={true}>
      <BoxThumbnail display="flex">
        {data.map(
          (item, index) =>
            item.image.small !== '' && (
              <Box
                key={index}
                onClick={() => {
                  window.location.href = `${item.link}`;
                }}
                display="flex"
                flexDirection="column"
                alignItems="center"
                style={{ marginRight: 10, marginLeft: 10 }}>
                <img
                  alt=""
                  style={{ borderRadius: 50 }}
                  src={item.image.small}
                />
                <Box
                  display="flex"
                  style={{ textAlign: 'center', marginTop: 3 }}>
                  <Typography style={{ lineHeight: 1 }}>{item.name}</Typography>
                </Box>
              </Box>
            )
        )}
      </BoxThumbnail>
    </StyledScrollContainer>
  );
};

export default CategoriesSlide;
