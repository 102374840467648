import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useHistory, Link } from 'react-router-dom';
import appConfig from 'utils/appConfig';
import PropTypes from 'prop-types';
import PageBase from 'components/PageBase';
import CollaboratorForm from 'components/Pages/PartnerPage/CollaboratorForm';
import HeaderComponent from 'components/HeaderComponent';
import {
  Typography,
  Modal,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Button,
  Card,
  Icon,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { Tab, Tabs } from 'components/Layout/Tabs';
import { Creators as ClienteCreators } from 'store/ducks/client';
import { ModalContainer, ModalHeader, LoadingContainer } from './styles';
import { Creators as NotificationCreators } from 'store/ducks/app';

const TabContainer = ({ children }) => {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
};

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const CollaboratorPage = () => {
  const { clientInsertAll, clientInsertAllLoading } = useSelector(
    state => state.client
  );

  const history = useHistory();
  const [modal, setModal] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();

  const onSubmitForm = payload => {
    const copyData = {};
    copyData.name = payload.name;
    copyData.email = payload.email;
    copyData.phone = payload.phone;
    dispatch(ClienteCreators.getClientInsertAllRequest(copyData));
    setModal(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleBack = () => {
    dispatch(push(`/minha-conta?aba=Clientes`));
    history.push(`/minha-conta?aba=Clientes`);
  };

  const handleCopyLink = token => {
    const link = `${appConfig.SITE_WEB}/ClientALLSite/cadastrar?user_id=${token}`;
    navigator.clipboard.writeText(link);
    dispatch(
      NotificationCreators.openNotification({
        message: 'link copiado com sucesso',
        type: 'success',
      })
    );
  };
  const SendEmailClient = payload => {
    dispatch(ClienteCreators.getSendEmailAllRequest(payload));
  };

  return (
    <>
      <PageBase>
        <HeaderComponent title="Cadastrar Cliente" />
        <Card style={{ marginTop: 20 }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Cadastro" icon={<Icon>person_add</Icon>} />
            {/* <Tab label="Importar CSV" icon={<Icon>upload_file</Icon>} /> */}
          </Tabs>
          {value === 0 && (
            <TabContainer>
              <CollaboratorForm
                onSubmit={onSubmitForm}
                handleBack={handleBack}
                isLoading={clientInsertAllLoading}
              />
            </TabContainer>
          )}
          {/* 
          {value === 1 && (
            <TabContainer>
              <CollaboratorCsvForm
                onSubmit={onSubmitCsvUpload}
                handleBack={handleBack}
                isLoading={collaboratorInsertLoading}
              />
            </TabContainer>
          )} */}
        </Card>
      </PageBase>
      <Modal open={modal} onClose={() => setModal(!modal)}>
        <ModalContainer>
          <ModalHeader>
            <Link to="/minha-conta?aba=Clientes">
              <IconButton onClick={() => setModal(!modal)}>
                <Icon>close</Icon>
              </IconButton>
            </Link>
          </ModalHeader>
          {clientInsertAllLoading ? (
            <LoadingContainer>
              <CircularProgress />
            </LoadingContainer>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">#</TableCell>
                  <TableCell align="center">Nome</TableCell>
                  <TableCell align="center">E-Mail</TableCell>
                  <TableCell align="center">Telefone</TableCell>
                  <TableCell align="center">Link</TableCell>
                  <TableCell align="center">Reenviar Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={clientInsertAll.id}>
                  <TableCell align="center">{clientInsertAll.id}</TableCell>
                  <TableCell align="center">{clientInsertAll.name}</TableCell>
                  <TableCell align="center">{clientInsertAll.email}</TableCell>
                  <TableCell align="center">
                    {clientInsertAll.cell_phone}
                  </TableCell>
                  <TableCell align="center">
                    <Button onClick={() => handleCopyLink(clientInsertAll.id)}>
                      Copiar link
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    <Button onClick={() => SendEmailClient(clientInsertAll.id)}>
                      enviar Email
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </ModalContainer>
      </Modal>
    </>
  );
};

export default CollaboratorPage;
