import React from 'react';
import { Creators as PanelTabsCreators } from 'store/ducks/panelTabs';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import HeaderButton from 'components/HeaderComponent/HeaderButton';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import { Stack, Box } from '@chakra-ui/react';
import { tabsName } from 'Pages/Account/menu/constants';
import CustomTextField from 'components/form/CustomTextField';
import HeaderFab from 'components/HeaderComponent/HeaderFab';

const ProductTableHeader = ({
  getFunction,
  initialValues,
  noButton = false,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const onSubmit = values => {
    getFunction(values);
  };

  const navigateToTab = tab => () => {
    history.push({
      search: `?aba=novo-produto`,
    });
    dispatch(PanelTabsCreators.changeTab(tab));
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={() => (
        <Form>
          <Stack
            mb="20px"
            direction={['column', 'row']}
            align="flex-end"
            justify="flex-end">
            <Stack direction="row" spacing="10px" w="full">
              <Box w="full">
                <Field
                  data-cy="input_serach_orders"
                  component={CustomTextField}
                  name="search"
                  label="Buscar por nome.."
                  placeholder="Informe uma palavra-chave"
                />
              </Box>
              <Box>
                <HeaderFab
                  data-cy="btn_serach_orders"
                  icon="search"
                  type="submit"
                />
              </Box>
            </Stack>

            {!noButton && (
              <Stack w="320px" align="flex-end">
                <HeaderButton
                  data-cy="btn_add_product"
                  icon="add"
                  onClick={navigateToTab(tabsName['novo-produto'])}>
                  Adicionar Produto
                </HeaderButton>
              </Stack>
            )}
          </Stack>
        </Form>
      )}
    />
  );
};

ProductTableHeader.propTypes = {
  getFunction: PropTypes.func.isRequired,
  initialValues: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default ProductTableHeader;
