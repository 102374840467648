import React from 'react';
import { Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import styles from './styles';
import appColors from 'utils/appColors';
import CustomButton from 'components/form/CustomButton';

const StatisticItemPanelDashboard = ({
  classes,
  urlRedirect,
  labelButton,
  icon,
  title,
  description,
  descriptionButton,
}) => (
  <Paper
    className={classes.statContainer}
    style={{ background: appColors.LIGHT_COLOR }}>
    <div className={classes.statContent} style={{ padding: '0px 10px' }}>
      <div className={classes.statIconContainer}>
        <div>
          <Icon className={classes.statIcon}>{icon}</Icon>
        </div>
        <div className={classes.statTitle}>
          <div>
            <b style={{ color: appColors.SECUNDARY_COLOR }}>{title}</b>
          </div>
        </div>
        <div className={classes.statDescription}>
          <span style={{ color: '#606060', fontWeight: 'bold' }}>
            {description}
          </span>
        </div>
        <div
          style={{
            flex: 1,
            paddingTop: 14,
            width: '100%',
            display: 'flex',
            justifyItems: 'flex-end',
            alignItems: 'center',
          }}>
          <p
            style={{
              color: '#606060',
              lineHeight: 1.3,
              flex: 1,
              paddingRight: 12,
              height: '50px',
            }}>
            {descriptionButton}
          </p>
          {labelButton ? (
            <CustomButton
              label={labelButton}
              href={urlRedirect}
              style={{
                backgroundColor: appColors.PRIMARY_COLOR,
                color: 'white',
                padding: 10,
                fontWeight: '500',
              }}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  </Paper>
);

StatisticItemPanelDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default withStyles(theme => styles(theme))(StatisticItemPanelDashboard);
