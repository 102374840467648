/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import api from 'services/api';
import { useSelector, useDispatch } from 'react-redux';
import appUtils from 'utils/appUtils';
import './printStyle.css';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import { Creators } from 'store/ducks/admin/order';
import appNaming from 'utils/appNaming';

const ComponentToPrint = React.forwardRef(({ data }, ref) => (
  <div className="body" ref={ref}>
    <div className="head">
      <p>{appNaming.REGULAR_NAMING_SINGLE}</p>
    </div>
    <div>
      <p>
        Pedido: <b>{data.id}</b>
      </p>
      <p>
        Data: <b>{moment(data.created_at).format('DD/MM/YYYY')}</b>
      </p>
      {data.original_delivery_name !== 'Retirar no Local' ? (
        <p>
          Entrega prevista:{' '}
          <b>
            {data.mim_time_for_preparation
              ? data.mim_time_for_preparation + 'min'
              : '20min'}
          </b>
        </p>
      ) : (
        <p>
          Entrega: <b>Retirar no Local</b>
        </p>
      )}
    </div>

    {data.observation && (
      <div className="obs">
        <p>{data.observation}</p>
      </div>
    )}

    <table className="table">
      <tr>
        <th style={{ width: '40px' }}>QTD</th>
        <th>PRODUTOS</th>
        <th width={50} style={{ width: '50px' }}>
          PREÇO
        </th>
      </tr>
      <tbody>
        {data.products.length > 0 &&
          data.products.map(item => (
            <tr key={'prod' + item.id}>
              <td
                style={{
                  width: '40px',
                  overflow: 'hidden',
                  textAlign: 'center',
                }}>
                {item.quantity}
              </td>
              <td style={{ width: '50px', overflow: 'hidden' }}>
                <b>{item.product.store.name}: </b>
                {item.product.name}
                {item.product.variations.length > 0
                  ? ` - ${item.product.variations[0].size.name} - ${item.product.variations[0].color.name}`
                  : ''}
              </td>
              <td width={50} style={{ width: '50px' }}>
                {appUtils.formatPrice(item.price)}
              </td>
            </tr>
          ))}
      </tbody>
    </table>

    <div className="client">
      <b>Dados do cliente</b>
      <p>
        Nome:
        <b> {data.user.name}</b>
      </p>
      <p>
        Telefone:
        <b> {data.user.cell_phone}</b>
      </p>
      <p>
        Endereço:
        <b>
          {' ' + data.address.street}, {data.address.number}
        </b>
      </p>
      <p>
        Comp.:
        <b> {data.address.complement}</b>
      </p>
      <p>
        Cidade:
        <b> {data.address.city.name}</b>
      </p>
      <p>
        CEP:
        <b> {data.address.code_post}</b>
      </p>
    </div>

    <div className="table">
      <div className="tableTotal">
        <p>Subtotal:</p>
        <b>
          {appUtils.formatPrice(
            Number(data.price) - Number(data.delivery_price)
          )}
        </b>
      </div>
      <div className="tableTotal">
        <p>Entrega:</p>
        <b>{appUtils.formatPrice(data.delivery_price)}</b>
      </div>
      <div className="tableTotal">
        <p>Total:</p>
        <b>{appUtils.formatPrice(data.price)}</b>
      </div>
      <div className="clientchakc">
        {data.payment_type === 1 || data.payment_type === 2 ? (
          <p>Pedido pago online, não cobrar do cliente</p>
        ) : (
          <p>Pagamento será feito na entrega, receber do cliente</p>
        )}
      </div>
    </div>
  </div>
));

export default function PrintLoad() {
  let PrintRef = React.useRef();
  const auth = useSelector(state => state.auth);
  const { orderPrint } = useSelector(({ admin }) => admin.order);
  const token = auth.data.refresh_token;
  const [data, setData] = useState({});
  const dispatch = useDispatch();

  const mountPrint = async orderPrint => {
    try {
      const res = await api.get(`/v1/admin/orders/${orderPrint}`, {
        headers: { Authorization: 'Bearer ' + token },
      });
      setData(res.data.data);
    } catch (err) {
      dispatch(Creators.getPrintRequest(null));
    }
    return true;
  };

  const print = useReactToPrint({
    content: () => PrintRef.current,
  });

  useEffect(() => {
    if (data.id !== null) {
      print();
      dispatch(Creators.getPrintRequest(null));
    }
  }, [data]);

  useEffect(() => {
    if (orderPrint) mountPrint(orderPrint);
  }, [orderPrint]);

  return (
    <div>
      <div style={{ display: 'none' }}>
        {Object.keys(data).length > 0 && (
          <ComponentToPrint data={data} ref={PrintRef} />
        )}
      </div>
    </div>
  );
}
