import appTheme from './appTheme';

const appNaming = {
  Buriti: {
    // GENERAL
    UPER_CASE_SINGLE: 'e.BURITI',
    UPER_CASE_NAMING: 'e.BURITI SHOPPING',
    UPER_CASE_NAMING_NO_SPACE: 'e.BURITISHOPPING',
    UPER_CASE_NAMING_UNDERLINE: 'e.BURITI_SHOPPING',
    UPER_CASE_NAMING_HIFEN: 'BURITI-SHOPPING',
    REGULAR_NAMING_SINGLE: 'e.Buriti',
    REGULAR_NAMING: 'e.Buriti Shopping',
    REGULAR_NAMING_NO_SPACE: 'e.BuritiShopping',
    REGULAR_NAMING_UNDERLINE: 'e.Buriti_Shopping',
    REGULAR_NAMING_HIFEN: 'e.Buriti-Shopping',
    DOWN_CASE_SINGLE: 'e.buriti',
    DOWN_CASE_NAMING: 'e.buriti shopping',
    DOWN_CASE_NAMING_HIFEN: 'e.buriti-shopping',
    DOWN_CASE_NAMING_NO_SPACE: 'e.buritishopping',
    DOWN_CASE_NAMING_UNDERLINE: 'e.buriti_shopping',
    TITLE: 'e.Buriti Shopping - O Shopping na palma da sua mão!',
  },
  Oferta: {
    UPER_CASE_SINGLE: 'Oferta Play',
    UPER_CASE_NAMING: 'Oferta Play',
    UPER_CASE_NAMING_NO_SPACE: 'OFERTA PLAY',
    UPER_CASE_NAMING_UNDERLINE: 'OFERTA_PLAY',
    UPER_CASE_NAMING_HIFEN: 'OFERTA-PLAY',
    REGULAR_NAMING_SINGLE: 'Oferta',
    REGULAR_NAMING: 'Oferta Play',
    REGULAR_NAMING_NO_SPACE: 'OfertaPlay',
    REGULAR_NAMING_UNDERLINE: 'Oferta_Play',
    REGULAR_NAMING_HIFEN: 'Oferta-Oferta_Play',
    DOWN_CASE_SINGLE: 'oferta',
    DOWN_CASE_NAMING: 'oferta play',
    DOWN_CASE_NAMING_HIFEN: 'oferta-play',
    DOWN_CASE_NAMING_NO_SPACE: 'ofertaplay',
    DOWN_CASE_NAMING_UNDERLINE: 'oferta_play',
    TITLE: 'Oferta Play',
  },
  SimerPay: {
    UPER_CASE_SINGLE: 'SimerPay',
    UPER_CASE_NAMING: 'SimerPay',
    UPER_CASE_NAMING_NO_SPACE: 'SimerPay',
    UPER_CASE_NAMING_UNDERLINE: 'SIMARPAY',
    UPER_CASE_NAMING_HIFEN: 'SIMER-PAY',
    REGULAR_NAMING_SINGLE: 'SIMER',
    REGULAR_NAMING: 'SimerPay',
    REGULAR_NAMING_NO_SPACE: 'SimerPay',
    REGULAR_NAMING_UNDERLINE: 'Simer_Pay',
    REGULAR_NAMING_HIFEN: 'Simer-Pay',
    DOWN_CASE_SINGLE: 'simer',
    DOWN_CASE_NAMING: 'simerpay',
    DOWN_CASE_NAMING_HIFEN: 'simer-pay',
    DOWN_CASE_NAMING_NO_SPACE: 'simerpay',
    DOWN_CASE_NAMING_UNDERLINE: 'simer_pay',
    TITLE: 'SimerPay',
  },
  Sandbox: {
    UPER_CASE_SINGLE: 'Sandbox Oferta Play',
    UPER_CASE_NAMING: 'Sandbox Oferta Play',
    UPER_CASE_NAMING_NO_SPACE: 'SANDBOX OFERTA PLAY',
    UPER_CASE_NAMING_UNDERLINE: 'SANDBOX_OFERTA_PLAY',
    UPER_CASE_NAMING_HIFEN: 'SANDBOX-OFERTA-PLAY',
    REGULAR_NAMING_SINGLE: 'SandboxOferta',
    REGULAR_NAMING: 'Sandbox Oferta Play',
    REGULAR_NAMING_NO_SPACE: 'SandboxOfertaPlay',
    REGULAR_NAMING_UNDERLINE: 'Sandbox_Oferta_Play',
    REGULAR_NAMING_HIFEN: 'Sandbox-Oferta-Oferta_Play',
    DOWN_CASE_SINGLE: 'sandboxoferta',
    DOWN_CASE_NAMING: 'sandbox oferta play',
    DOWN_CASE_NAMING_HIFEN: 'sandbox-oferta-play',
    DOWN_CASE_NAMING_NO_SPACE: 'Sandboxofertaplay',
    DOWN_CASE_NAMING_UNDERLINE: 'Sandbox_oferta_play',
    TITLE: 'Sandbox Oferta Play',
  },
  OlharPix: {
    UPER_CASE_SINGLE: 'Olhar Pix',
    UPER_CASE_NAMING: 'Olhar Pix',
    UPER_CASE_NAMING_NO_SPACE: 'OLHAR PIX',
    UPER_CASE_NAMING_UNDERLINE: 'OLHAR_PIX',
    UPER_CASE_NAMING_HIFEN: 'OLHAR-PIX',
    REGULAR_NAMING_SINGLE: 'Olhar',
    REGULAR_NAMING: 'Olhar Pix',
    REGULAR_NAMING_NO_SPACE: 'OlharPix',
    REGULAR_NAMING_UNDERLINE: 'Olhar_Pix',
    REGULAR_NAMING_HIFEN: 'Olhar-Olhar_Pix',
    DOWN_CASE_SINGLE: 'olhar',
    DOWN_CASE_NAMING: 'olhar pix',
    DOWN_CASE_NAMING_HIFEN: 'olhar-pix',
    DOWN_CASE_NAMING_NO_SPACE: 'olharpix',
    DOWN_CASE_NAMING_UNDERLINE: 'olhar_pix',
    TITLE: 'Olhar Pix',
  },
}[appTheme.name];

export default appNaming;
