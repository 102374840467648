import styled from 'styled-components';
import { Box, Typography, Breadcrumbs as BreadcrumbsDefault } from '@material-ui/core';
import appColors from 'utils/appColors';

export const ContainerHeader = styled(Box)`
    && {
        display:none;
        @media all and (max-width:500px){
            display:inline;
            font-size: 12px;
        }
    }
    padding: 0px 10px;

    a {
      color: '#343434';
      text-decoration:none;
    }
`

export const Title = styled(Typography)`
  color: ${appColors.SECUNDARY_COLOR};
  font-weight: 'bold';
  font-size: 32;

  @media all and (max-width: 500px) {
    font-size: 25px
  }
`

export const Breadcrumbs = styled(BreadcrumbsDefault)`
  a {
    color: #343434;
    text-decoration: none;
  }
`

const styles = {

    purpleTitle: {
        color: appColors.SECUNDARY_COLOR,
        fontWeight: 'bold',
        fontSize: 32,
    },
}

export default styles;
