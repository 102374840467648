import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
  },

  header: {
    backgroundColor: 'white',
  },

  cover: {
    width: 450,
    height: 900,
    objectFit: 'cover',
  },

  catalog_view: {
    position: 'absolute',
    top: 40,
    left: 0,
    right: 0,
  },

  catalog_title: {
    fontWeight: '100',
    fontSize: 40,
    color: 'white',
    textAlign: 'center',
  },

  store_name: {
    position: 'absolute',
    bottom: 20,
    left: 45,
    color: 'white',
    fontSize: 20,
  },

  store_image: {
    width: 140,
    height: 140,
    borderRadius: 100,
    position: 'absolute',
    bottom: 60,
    left: 40,
    objectFit: 'cover',
    backgroundColor: '#f1f1f1',
  },

  list: {
    paddingHorizontal: 20,
  },

  product: {
    flexDirection: 'row',
    height: (900 - 220) / 3,
    paddingVertical: 10,
    paddingHorizontal: 12,
  },

  product_image: {
    width: 200,
    height: (850 - 240) / 3,
    borderRadius: 8,
    objectFit: 'cover',
    marginRight: 12,
    backgroundColor: '#f1f1f1',
  },

  new_price: {
    backgroundColor: '#e3af4c',
    padding: 6,
    paddingHorizontal: 20,
    borderRadius: 3,
    width: 150,
  },

  cart_button: {
    padding: 6,
    paddingHorizontal: 24,
    borderRadius: 4,
    alignItems: 'center',
    marginTop: 30,
    borderWidth: 1,
    borderColor: '#e3af4c',
  },

  text_button: {
    fontSize: 16,
    color: '#e3af4c',
  },

  prices: {
    marginTop: 20,
  },

  text_old_price: {
    fontSize: 14,
    color: '#e74c3c',
    textDecoration: 'line-through',
  },

  price_antigo: {
    marginBottom: 10,
  },

  desc: {
    marginVertical: 10,
    color: '#888',
    fontSize: 11,
    flexWrap: 'wrap',
    width: 200,
  },

  icon: {
    width: 22,
    height: 22,
  },

  store_image_footer: {
    width: 140,
    height: 140,
    borderRadius: 100,
    objectFit: 'cover',
    backgroundColor: '#f1f1f1',
  },

  logo_play: {
    width: 200,
    height: 120,
    objectFit: 'contain',
  },
});
