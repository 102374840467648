import React from 'react';
import PropTypes from 'prop-types';
import ActionFab from 'components/Actions/ActionFab';
import ActionMenu from 'components/Actions/ActionMenu';
import ActionMenuItem from 'components/Actions/ActionMenuItem';
import { useHistory } from 'react-router-dom';
import AlertDialog from 'components/AlertDialog';
import { Creators as OrderCreators } from 'store/ducks/admin/order';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as PanelTabCreators } from 'store/ducks/panelTabs';

const OrderActions = ({ rowData }) => {
  const [localState, setLocalState] = React.useState({
    anchorEl: null,
  });

  const {
    admin: {
      order: { orderDeleteLoading },
    },
  } = useSelector(state => state);

  const [isOpenDialog, setIsOpenDialog] = React.useState(false);

  const dispatch = useDispatch();

  const onCloseDialog = () => {
    setIsOpenDialog(false);
  };

  const handleClose = () => {
    setLocalState({ anchorEl: null });
  };

  const onOpenDialog = () => {
    handleClose();
    setIsOpenDialog(true);
  };

  const history = useHistory();

  const handleClick = event => {
    setLocalState({ anchorEl: event.currentTarget });
  };

  const onDeleteSubmit = () => {
    dispatch(OrderCreators.getOrderDeleteRequest(rowData.id));
  };

  React.useEffect(() => {
    if (!orderDeleteLoading) {
      onCloseDialog();
    }
  }, [orderDeleteLoading]);

  const handleTab = (value, tabParams = {}) => {
    window.scrollTo(0, 0);
    dispatch(PanelTabCreators.changeTab(value, tabParams));
  };

  return (
    <>
      <ActionFab
        data-cy="btn_action_orders"
        icon="more_vert"
        onClick={handleClick}
      />
      <ActionMenu
        anchorEl={localState.anchorEl}
        onClose={handleClose}
        open={Boolean(localState.anchorEl)}>
        <ActionMenuItem
          data-cy="btn_view_orders"
          onClick={() => {
            handleTab(6);
            history.push({
              search: `?id_pedido=` + rowData.id,
            });
          }}>
          Visualizar
        </ActionMenuItem>
        {/*
        <ActionMenuItem onClick={() => { }}>Gerar Etiqueta</ActionMenuItem>
        <ActionMenuItem onClick={() => { }}>Imprimir</ActionMenuItem>
        */}
        <ActionMenuItem data-cy="btn_delete_orders" onClick={onOpenDialog}>
          Remover
        </ActionMenuItem>
      </ActionMenu>
      <AlertDialog
        isOpen={isOpenDialog}
        handleClose={onCloseDialog}
        onConfirm={onDeleteSubmit}
        isLoading={orderDeleteLoading}
        description="Deseja realmente remover este pedido ?"
      />
    </>
  );
};

OrderActions.propTypes = {
  rowData: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onDeleteRequest: PropTypes.func,
};

OrderActions.defaultProps = {
  onDeleteRequest: () => {},
};

export default OrderActions;
