/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import RegisterResellerCompanyForm from 'components/Pages/RegisterReseller/registerResellerCompanyForm';
import { useDispatch } from 'react-redux';
import { CircularProgress, Box } from '@material-ui/core';
import appColors from 'utils/appColors';
import { Creators as AddressCreators } from 'store/ducks/address';
import { useResselerCompany } from 'hooks/useResselerCompany';

const RegisterResellerCompanyTab = () => {
  const {
    updateCompany,
    getCompany,
    resselerCompanyloading,
    resselerCompany,
    putResselerCompanyloading,
  } = useResselerCompany();
  const [localState, setLocalState] = React.useState(
    {
      formInitialValues: {
        name: '',
        social_name: '',
        cnpj: '',
        cell_phone: '',
        email: '',
        address: {
          code_post: '',
          street: '',
          number: '',
          district: '',
          complement: '',
          city: {
            id: '',
            name: '',
            state: {
              id: '',
              name: '',
            },
          },
        },
      },
    },
    []
  );

  const { formInitialValues } = localState;

  const dispatch = useDispatch();

  React.useEffect(() => {
    getCompany();
  }, []);

  const onSubmitCEP = values => {
    dispatch(AddressCreators.getAddressCEPRequest(values));
  };

  const onSubmit = enterpriseData => {
    updateCompany(enterpriseData);
  };

  React.useEffect(() => {
    if (resselerCompany !== null && resselerCompany !== undefined) {
      setLocalState({
        ...localState,
        formInitialValues: {
          ...resselerCompany,
        },
      });
    } else {
      setLocalState({
        ...localState,
      });
    }
  }, [resselerCompany]);

  return (
    <>
      {formInitialValues !== null && !resselerCompanyloading && (
        <>
          <RegisterResellerCompanyForm
            onSubmit={onSubmit}
            onSubmitCEP={onSubmitCEP}
            initialValues={formInitialValues}
            resellerInsertLoading={putResselerCompanyloading}
          />
        </>
      )}
      {resselerCompanyloading && (
        <Box
          display="flex"
          flex="1"
          justifyContent="center"
          alignItems="center"
          style={{ height: 300 }}>
          <CircularProgress style={{ color: appColors.PRIMARY_COLOR }} />
        </Box>
      )}
    </>
  );
};

export default RegisterResellerCompanyTab;
