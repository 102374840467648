import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import CustomTextField from 'components/form/CustomTextField';
import HeaderFab from 'components/HeaderComponent/HeaderFab';
import CustomDateRange from 'components/form/CustomDateRange';
import moment from 'moment';

import { Stack, Box } from '@chakra-ui/react';

const OrderTableHeader = ({ getFunction, initialValues }) => {
  const onSubmit = values => {
    const data = {
      dateStart: moment(values.dateStart).format('YYYY-MM-DD'),
      dateEnd: moment(values.dateEnd).format('YYYY-MM-DD'),
      search: values.search,
    };
    getFunction(data);
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={() => (
        <Form>
          <Stack direction={['column', 'row']} align="center" mb="20px">
            <Box>
              <Field name="dateStartEnd" component={CustomDateRange} />
            </Box>
            <Stack direction="row" spacing="10px">
              <Box>
                <Field
                  data-cy="input_serach_orders"
                  component={CustomTextField}
                  name="search"
                  label="Buscar por nº de pedido"
                  placeholder="Informe uma palavra-chave"
                />
              </Box>
              <Box>
                <HeaderFab
                  data-cy="btn_serach_orders"
                  icon="search"
                  type="submit"
                />
              </Box>
            </Stack>
          </Stack>
        </Form>
      )}
    />
  );
};

OrderTableHeader.propTypes = {
  getFunction: PropTypes.func.isRequired,
  initialValues: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default OrderTableHeader;
