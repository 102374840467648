import React from 'react';
import PageBase from 'components/PageBase';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as WalletCreators } from 'store/ducks/admin/wallet';
import { Creators as WithdrawCreators } from 'store/ducks/admin/wallet/withdraw';
import { Creators as StoreCreators } from 'store/ducks/admin/stores';
import HeaderComponent from 'components/HeaderComponent';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Box,
  Grid,
} from '@material-ui/core';
import CashExpressInfo from 'components/CashExpressPage/CashExpressInfo';
import CashExpressWithdraw from 'components/CashExpressPage/CashExpressWithdraw';
import BankUpdatedForm from 'components/Pages/RegisterStore/UpdateBankForm';
import RegisterBankForm from 'components/Pages/RegisterStore/RegisterBankForm';
import api from 'services/api';
import styles from './styles';
import appColors from 'utils/appColors';
import { Creators as NotificationCreators } from 'store/ducks/app';
import { useBankEdit } from 'hooks/BankEdit.js';
import { Link } from 'react-router-dom';
import useAsaas from 'hooks/useAsaas';

const InfoItem = ({ label, children }) => (
  <Grid item lg={4} sm={6} xs={12}>
    <span
      style={{
        color: appColors.COLOR_ICON,
        fontWeight: '500',
        marginRight: '5px',
      }}>
      {label}
    </span>
    {children}
  </Grid>
);

const CashExpressResellerMenu = () => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalModalEditBankOpen, setModalEditBankOpen] = React.useState(false);
  const { updateBankAccount } = useBankEdit();
  const { getFees, fees } = useAsaas();
  const {
    admin: {
      wallet: {
        wallet,
        walletLoading,
        bank_reseller,
        walletDocuments,
        walletStatus,
      },
      // store: { storeLoading },
      withdraw: { withdrawLoading },
    },
    client: { client },
  } = useSelector(state => state);

  const { store, storeLoading } = useSelector(state => state.admin.store);

  React.useEffect(() => {
    getFees();
    dispatch(WalletCreators.getWalletRequest());
    dispatch(WalletCreators.getWalletDocumentsRequest());
    dispatch(WalletCreators.getWalletStatusRequest());
    dispatch(WalletCreators.getBankReseller()); // GET BANK RESELLER
    dispatch(
      StoreCreators.getStoreRequest({
        id: client.store ? client.store.id : '',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleModalBankEditClose = () => {
    setModalEditBankOpen(false);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalBankEditOpen = () => {
    setModalEditBankOpen(true);
  };

  const onSubmit = (form, onCloseModal) => {
    dispatch(WithdrawCreators.getWithdrawRequest(form, onCloseModal));
  };

  const onSubmitEditBank = async form => {
    await updateBankAccount(form);
  };

  const handleSubmitBankForm = async payload => {
    try {
      const res = await api.put('/v1/client/bank', payload);
      console.log('res', res);
      dispatch(
        NotificationCreators.openNotification({
          message: 'Conta bancária registrada com Sucesso!',
          type: 'success',
        })
      );
      window.location.reload();
    } catch (err) {
      dispatch(
        NotificationCreators.openNotification({
          message: 'Não foi possível criar a conta bancária',
          type: 'error',
        })
      );
    }
    return true;
  };

  return (
    <PageBase>
      <HeaderComponent title="Transferência" />
      <CashExpressInfo
        wallet={wallet}
        walletLoading={walletLoading}
        walletDocuments={walletDocuments}
        walletStatus={walletStatus}
      />

      {client?.id && bank_reseller?.id !== null && (
        <>
          {' '}
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="right"
            flexWrap="wrap"
            alignItems="stretch"
            marginLeft={1}
            marginRight={1}>
            <Link to="/minha-conta?aba=ReportTransfer">
              <Button style={styles.purpleButtonVisualization}>
                visualizar Transferências
              </Button>
            </Link>
            <Button
              onClick={handleModalBankEditOpen}
              style={styles.purpleButtonEdit}>
              Editar Conta Bancária
            </Button>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            flexWrap="wrap"
            alignItems="stretch"
            marginLeft={-1}
            marginRight={-1}>
            <Box flexGrow={1} p={1}>
              <Card style={{ height: '100%' }}>
                <CardHeader title="Conta Bancária"></CardHeader>

                <CardContent style={{ lineHeight: 2.0, color: '#4e3e51' }}>
                  <Grid container spacing={2}>
                    <InfoItem label="Banco">
                      {`${bank_reseller?.bank?.code || ''} - ${
                        bank_reseller?.bank?.name || ''
                      }`}
                    </InfoItem>
                    <InfoItem label="Número da agência">
                      {bank_reseller?.agency || ''}
                    </InfoItem>
                    <InfoItem label="Dígito da agência">
                      {bank_reseller?.agency_check || ''}
                    </InfoItem>
                    <InfoItem label="Número da conta">
                      {bank_reseller?.account || ''}
                    </InfoItem>
                    <InfoItem label="Dígito da conta">
                      {bank_reseller?.account_check || ''}
                    </InfoItem>
                    <InfoItem label="Tipo de conta">
                      {bank_reseller?.type || ''}
                    </InfoItem>
                    <InfoItem label="Titular da conta">
                      {bank_reseller?.account_holder || ''}
                    </InfoItem>
                    <InfoItem label="Tipo de documento">
                      {bank_reseller?.doc_type || ''}
                    </InfoItem>
                    <InfoItem label="Número do documento">
                      {bank_reseller?.doc_number || ''}
                    </InfoItem>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Box>
          <BankUpdatedForm
            onSubmit={onSubmitEditBank}
            initialValues={{
              ...bank_reseller,
              doc_type: 'CNPJ',
              doc_number:
                client?.group?.id === 4
                  ? client?.enterprise?.cnpj
                  : store?.cnpj,
            }}
            handleClose={handleModalBankEditClose}
            isOpen={modalModalEditBankOpen}
          />
        </>
      )}

      {!storeLoading && bank_reseller?.id && (
        <>
          <Button
            wallet={wallet}
            style={styles.purpleButtonProceed}
            onClick={handleModalOpen}>
            Realizar um saque
          </Button>

          <CashExpressWithdraw
            fees={fees}
            onSubmit={onSubmit}
            handleClose={handleModalClose}
            cash={{ ...wallet, bank: bank_reseller }}
            isOpen={modalOpen}
            isLoading={withdrawLoading}
            wallet={wallet}
          />
        </>
      )}

      {client?.id && !storeLoading && !bank_reseller?.id && (
        <>
          <h4 style={{ color: appColors.COLOR_ICON, marginTop: '80px' }}>
            Cadastre uma conta para efetuar saques:{' '}
          </h4>
          <RegisterBankForm
            onBack={() => alert('back')}
            onSubmit={handleSubmitBankForm}
            initialValues={{
              doc_type: 'CNPJ',
              doc_number:
                client.group.id === 4 ? client.enterprise?.cnpj : store.cnpj,
            }}
            isLoading={false}
          />
        </>
      )}
    </PageBase>
  );
};

export default CashExpressResellerMenu;
