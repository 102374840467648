import React from 'react';
import ActionFab from 'components/Actions/ActionFab';
import ActionMenuItem from 'components/Actions/ActionMenuItem';
import ActionMenu from 'components/Actions/ActionMenu';

const ReportTransferActions = ({ rowData, onVisualizationRequest }) => {
  const [localState, setLocalState] = React.useState({
    anchorEl: null,
  });

  const handleClick = event => {
    setLocalState({ anchorEl: event.currentTarget });
  };

  const handleClose = () => {
    setLocalState({ anchorEl: null });
  };

  return (
    <div
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <ActionFab
        data-cy="btn_actions_catalog"
        icon="more_vert"
        onClick={handleClick}
      />
      <ActionMenu
        anchorEl={localState.anchorEl}
        onClose={handleClose}
        open={Boolean(localState.anchorEl)}>
        <ActionMenuItem
          data-cy="btn_actions_edit_catalog"
          onClick={() => {
            onVisualizationRequest(rowData.id);
          }}>
          Visualizar
        </ActionMenuItem>
      </ActionMenu>
    </div>
  );
};

export default ReportTransferActions;
