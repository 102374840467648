/* eslint-disable no-unused-vars */
// @flow
import React from 'react';
import { StoreDetailContainer } from './styles';
import api from 'services/api';
import { useEffect } from 'react';
import { useState } from 'react';
import HeaderInfoDetails from 'components/Pages/InfoPage/HeaderInfoDetails/components/buriti';
import { StyledBoxDetail } from 'containers/Pages/ProductDetailsPage/styles';
import InfoText from 'components/Pages/InfoPage/InfoText/components/buriti';
import { Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as ClientCreators } from 'store/ducks/client';

const InfoPage = ({ location }) => {
  const dispatch = useDispatch();
  const { client } = useSelector(state => state.client);
  const group = client?.group?.id;
  const path = window.location.pathname;
  const [data, setData] = useState();
  const [privacy_notice, setprivacy_notice] = useState(false);

  const getTermsOrPolicy = async () => {
    try {
      const res = await api.get(`/v1/client/terms/latest?group_id=${group}`);
      if (!res.ok) throw res;
      setData(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    dispatch(ClientCreators.getClientRequest());
  }, [dispatch]);
  useEffect(() => {
    getTermsOrPolicy();
    if (path === '/pagina/termos-de-privacidade') {
      setprivacy_notice(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  return (
    <StoreDetailContainer>
      <Box p="40px">
        <HeaderInfoDetails />
        {data && (
          <StyledBoxDetail display="flex" flexWrap="wrap" flexDirection="row">
            <InfoText
              data={
                privacy_notice
                  ? data[group].privacy_notice
                  : data[group].terms
                  ? data[group].terms
                  : null
              }
            />
          </StyledBoxDetail>
        )}
      </Box>
    </StoreDetailContainer>
  );
};

export default InfoPage;
