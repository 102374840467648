import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { BoxColors } from '../styles';

const CustomCheckboxGroupItem = props => {
  const {
    value,
    label,
    onClick,
    isChecked,
    item,
    field: { checked, onChange },
    form: { handleSubmit },
  } = props;

  const funcCheck = e => {
    if (typeof onClick === 'function') {
      onClick(e);
      setTimeout(handleSubmit, 0);
      return;
    }
    onChange({ currentTarget: { value, checked: !checked } });
    setTimeout(handleSubmit, 0);
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      style={{ cursor: 'pointer', paddingTop: 20 }}>
      {typeof item != 'undefined' && (
        <>
          {item.show_color === 1 ? (
            <>
              {(checked ? checked : isChecked) ? (
                <CheckBoxIcon onClick={funcCheck} style={{ color: 'ba3d7f' }} />
              ) : (
                <CheckBoxOutlineBlankIcon
                  onClick={funcCheck}
                  style={{ color: 'ba3d7f' }}
                />
              )}
              <BoxColors hexa={item.hexa} />
              <Box style={{ marginLeft: 5 }}>
                <Typography>{label}</Typography>
              </Box>
            </>
          ) : (
            <>
              {(checked ? checked : isChecked) ? (
                <CheckBoxIcon onClick={funcCheck} style={{ color: 'ba3d7f' }} />
              ) : (
                <CheckBoxOutlineBlankIcon
                  onClick={funcCheck}
                  style={{ color: 'ba3d7f' }}
                />
              )}
              <Box style={{ marginLeft: 5 }}>
                <Typography>{label}</Typography>
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};

CustomCheckboxGroupItem.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
};

export { CustomCheckboxGroupItem };
