import { applyMiddleware, compose, createStore } from 'redux';
import storage from 'redux-persist/lib/storage';
import { createBrowserHistory } from 'history';
import { persistStore, persistReducer } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';

import rootSaga from './sagas/index';
import reducers from './ducks/index';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'shoppingCart', 'checkout', 'parameters', 'cupom'],
};

const history = createBrowserHistory();

const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, routeMiddleware];
const persistedReducer = persistReducer(persistConfig, reducers(history));

const createCompose = compose(applyMiddleware(...middlewares));

export default function configureStore() {
  const store = createStore(persistedReducer, createCompose);
  const persistor = persistStore(store);
  sagaMiddleware.run(rootSaga);
  return { store, persistor };
}
export { history };
