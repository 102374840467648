import styled from 'styled-components';
import { Button } from '@material-ui/core';
import appColors from 'utils/appColors';


export const ColorButton = styled(Button)`
&& {
    margin-top: 10px;
    margin-right: 10px;
    min-width:0px;
    border: 1px solid ${appColors.SECUNDARY_COLOR};
    color: ${props => props.checked ? `white`  : appColors.SECUNDARY_COLOR};
    background-color: ${props => props.checked ? appColors.SECUNDARY_COLOR : `white` };
    &:hover {
        color: ${props => props.checked ? `white`  : appColors.SECUNDARY_COLOR};
        background-color: ${props => props.checked ? appColors.SECUNDARY_COLOR : `white` };
    }
}

`;

const styles = {

    fontButton:{
        fontSize:12
    },

}

export default styles;