import { Button, FormHelperText, Typography } from '@material-ui/core';
import { ErrorMessage, FastField } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Creators as ProductCreators } from 'store/ducks/admin/product';
import { StyledInput } from './styles';
import { Add } from '@material-ui/icons';

// LOAD IMAGE FIX ORIENTATION
import * as ReadImage from 'blueimp-load-image';
import CropImage from 'components/CropImage';
import { DialogConfirm } from 'components/DialogConfirm';
import DragAndDrop from 'components/Guides/DragAndDrop';
import AlertDialog from 'components/AlertDialog';
import { useDispatch } from 'react-redux';
import CustomLinkField from 'components/form/CustomLinkField';

function Images({ setFieldValue, values, setFieldError }) {
  const inputRef = useRef();
  const dispatch = useDispatch();
  const [images, setImages] = useState(values.images_data || []);
  const [cropActive, setCropActive] = useState([]);
  const [cropActiveNew, setCropActiveNew] = useState(false);
  const [imageErrorSize, setImageErrorSize] = useState(false);
  const [deleteState, setDeleteState] = React.useState({
    open: false,
    item: {},
  });

  useEffect(() => {
    const imagesInfo = values.images_info.map(image =>
      image.type
        ? image
        : {
            id: image.id,
            type: 'remote',
            url: image.sizes.medium,
            isPrototype: image.isPrototype,
          }
    );
    setFieldValue('images_data', imagesInfo);
    setImages(imagesInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ReadMultipleImageFiles = async (files, imagesAdd = []) => {
    setImageErrorSize(false);
    const filesArr = Array.from(files);
    const imagesArr = [];
    const currentTimestamp = new Date().getTime();

    const readFile = index => {
      if (index >= filesArr.length) {
        setImages(m => [...m, ...imagesArr, ...imagesAdd]);
        setFieldValue('images_info', [
          ...values.images_info,
          ...imagesArr,
          ...imagesAdd,
        ]);
        setCropActive(imagesArr);
        return;
      }
      const file = filesArr[index];
      var options = { canvas: true };
      ReadImage.parseMetaData(file, data => {
        if (data.exif && data.exif.get('Orientation')) {
          options.orientation = data.exif.get('Orientation');
        }
        ReadImage(
          file,
          async img => {
            setImageErrorSize(false);
            imagesArr.push({
              id: `local-${index}-${currentTimestamp}`,
              type: 'local',
              url: img.toDataURL(),
              file,
            });
            readFile(index + 1);
          },
          options
        );
      });
    };
    readFile(0);
  };

  const changeImage = file => {
    var options = { canvas: true };
    ReadImage.parseMetaData(file, data => {
      if (data.exif && data.exif.get('Orientation')) {
        options.orientation = data.exif.get('Orientation');
      }
      ReadImage(
        file,
        async img => {
          const IMG = {
            id: cropActive[0].id,
            type: 'local',
            url: img.toDataURL(),
            file,
          };
          setImages(old =>
            old.map(item => (item.id === cropActive[0].id ? IMG : item))
          );
          setFieldValue('images_data', [...values.images_data, file]);
          setFieldValue('images_info', [
            ...values.images_info.filter(item =>
              item.id === cropActive[0].id ? IMG : item
            ),
          ]);
          if (!values.featured) {
            const index = images.findIndex(
              item => item.id === cropActive[0].id
            );
            setFieldValue('featured_index', index);
            setFieldValue('featured', cropActive[0].id);
          }
          setCropActive(cropActive.filter((_, index) => index > 0));
        },
        options
      );
    });
  };

  const changeImages = value => {
    setImages(value);
  };

  const onDelete = item => {
    setDeleteState({ open: true, item });
  };

  const onDeleteConfirm = () => {
    const image = deleteState.item;
    if (image.type === 'remote') {
      dispatch(
        ProductCreators.getImageProductDeleteRequest({
          id: image.id,
          id_product: values.id || 0,
        })
      );
    }

    setImages(m =>
      m.filter(file => {
        if (file.id === image.id) {
          return false;
        }
        return true;
      })
    );

    setFieldValue('images_data', [
      ...values['images_data'].filter(file => {
        if (file === image) {
          return false;
        }
        return true;
      }),
    ]);

    setDeleteState({ open: false, item: {} });
  };

  return (
    <div>
      <div>
        {cropActive.length > 0 && (
          <DialogConfirm
            active={true}
            handleSubmit={() => {}}
            handleClose={() => {}}
            dialogContent={() => (
              <CropImage
                aspect={1 / 1}
                SavePng={png => changeImage(png)}
                saveCrop={() => {}}
                cancel={() => {
                  setCropActive(false);
                }}
                image={cropActive[0].url}
                editar={cropActiveNew}
              />
            )}
          />
        )}
      </div>

      <div style={{ margin: '20px 0px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6">Imagens</Typography>
          <Typography
            style={{ margin: 5, color: 'red', opacity: 0.7, fontSize: 12 }}>
            {imageErrorSize
              ? 'Tamanho mínimo para envio: 780px largura por 780px de altura'
              : 'Obrigatório'}
          </Typography>
        </div>
        <DragAndDrop
          onEdit={i => {
            setFieldValue(
              'images_data',
              values.images_data.filter(item => item.id !== images[i].id)
            );
            setCropActive([images[i]]);
            setCropActiveNew(true);
          }}
          featured={values.featured}
          setFieldValue={setFieldValue}
          onDelete={onDelete}
          changeImages={changeImages}
          images={images}
        />
        <Button
          id="AdicionarImagem"
          variant="contained"
          color="primary"
          onClick={() => inputRef.current.click()}>
          <Add />
          Adicionar imagem
        </Button>
      </div>

      <Typography variant="h6">Vídeo do Youtube</Typography>
      <FastField
        isClient={true}
        name="link"
        label="Link do Vídeo"
        component={CustomLinkField}
      />

      <ErrorMessage name={'images'}>
        {msg => <FormHelperText error>{msg}</FormHelperText>}
      </ErrorMessage>
      <AlertDialog
        isOpen={deleteState.open}
        handleClose={() => setDeleteState({ open: false })}
        onConfirm={onDeleteConfirm}
        title="Excluir registro?"
        description="Excluir registro de uma imagem"
      />
      <StyledInput
        ref={inputRef}
        multiple
        onChange={event => {
          const { files } = event.currentTarget;
          const filesArr = Array.from(files);
          let extensionAccepts = true;
          filesArr.forEach(item => {
            if (item.type !== 'image/jpeg' && item.type !== 'image/png')
              extensionAccepts = false;
          });
          setFieldError('images', null);
          if (extensionAccepts) {
            ReadMultipleImageFiles(files);
          } else
            setFieldError(
              'images',
              'O formato escolhido é inválido, tente enviar imagens png ou jpeg'
            );
        }}
        type="file"
        accept="image/*"
      />
    </div>
  );
}

export default Images;
