import { call, takeLatest, all, put } from 'redux-saga/effects';
import api from 'services/api';
import { callApi } from 'store/sagas/auth';
import { Types, Creators } from 'store/ducks/home';

function* getHome() {
  try {
    const request = call(api.get, `/v1/site/home`);
    const response = yield call(callApi, request);
    if (response.status !== 200) throw response;
    yield put(Creators.getHomeSuccess(response.data));
  } catch (err) {
    yield put(Creators.getHomeFailure('Erro ao buscar na API'));
  }
}

// Individual exports for testing
export default function* homeSaga() {
  yield all([
    takeLatest(Types.GET_REQUEST, getHome),
  ]);
}
