/* eslint-disable no-duplicate-case */
/*
 *
 * Order reducer
 *
 */

import { Types as AuthTypes } from 'store/ducks/auth';

export const Types = {
  // Obtem pedido por id
  GET_REQUEST: 'order/GET_REQUEST',
  GET_SUCCESS: 'order/GET_SUCCESS',
  GET_FAILURE: 'order/GET_FAILURE',

  // Obtem lista de pedidos
  GET_LIST_REQUEST: 'order/GET_LIST_REQUEST',
  GET_LIST_SUCCESS: 'order/GET_LIST_SUCCESS',
  GET_LIST_FAILURE: 'order/GET_LIST_FAILURE',

  // Obtem cotações de frete
  GET_QUOTE_REQUEST: 'order/GET_QUOTE_REQUEST',
  GET_QUOTE_SUCCESS: 'order/GET_QUOTE_SUCCESS',
  GET_QUOTE_FAILURE: 'order/GET_QUOTE_FAILURE',
  GET_QUOTE_RESET: 'order/GET_QUOTE_RESET',
  GET_QUOTE_CODE_POST: 'order/GET_QUOTE_CODE_POST',

  // Insere um pedido
  GET_INSERT_REQUEST: 'order/GET_INSERT_REQUEST',
  GET_INSERT_SUCCESS: 'order/GET_INSERT_SUCCESS',
  GET_INSERT_FAILURE: 'order/GET_INSERT_FAILURE',

  // Atualiza um pedido
  GET_UPDATE_REQUEST: 'order/GET_UPDATE_REQUEST',
  GET_UPDATE_SUCCESS: 'order/GET_UPDATE_SUCCESS',
  GET_UPDATE_FAILURE: 'order/GET_UPDATE_FAILURE',

  // Deleta um pedido
  GET_DELETE_REQUEST: 'order/GET_DELETE_REQUEST',
  GET_DELETE_SUCCESS: 'order/GET_DELETE_SUCCESS',
  GET_DELETE_FAILURE: 'order/GET_DELETE_FAILURE',

  // reseta os erros
  RESET_LIST_ERRORS: 'order/RESET_LIST_ERRORS',
};

export const initialState = {
  // Endereço por id
  order: {},
  orderLoading: false,
  orderError: null,
  // Lista Pedidos
  orderList: [],
  total: 0,
  orderListLoading: false,
  orderListError: null,
  // Obtem cotacões de frete
  quote: [],
  quoteLoading: false,
  quoteError: null,
  quoteCodePost: null,
  // Insere um pedido
  listErrors: [],
  orderInsertLoading: false,
  orderInsertError: null,
  // Atualiza um pedido
  orderListSuccess: {},
  orderUpdateLoading: false,
  orderUpdateError: null,
  // Deleta pedido
  orderDeleteLoading: false,
  orderDeleteError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Lista um  pedido
    case Types.GET_REQUEST:
      return { ...state, orderLoading: true };
    case Types.GET_SUCCESS:
      return {
        ...state,
        order: action.payload,
        orderLoading: false,
        orderError: null,
      };
    case Types.GET_FAILURE:
      return {
        ...state,
        orderLoading: false,
        orderError: action.payload,
      };
    // Lista  pedidos
    case Types.GET_LIST_REQUEST:
      return { ...state, orderListLoading: true, orderListError: false };
    case Types.GET_LIST_SUCCESS:
      return {
        ...state,
        orderList: action.payload.data,
        total: action.payload.total,
        orderListLoading: false,
        orderListError: null,
      };
    case Types.GET_LIST_FAILURE:
      return {
        ...state,
        orderListLoading: false,
        orderListError: action.payload,
      };
    // Obtem cotações de frete
    case Types.GET_QUOTE_REQUEST:
      return { ...state, quoteLoading: true, quoteError: false };
    case Types.GET_QUOTE_SUCCESS:
      return {
        ...state,
        quote: action.payload,
        quoteLoading: false,
        quoteError: null,
      };
    case Types.GET_QUOTE_FAILURE:
      return {
        ...state,
        quoteLoading: false,
        quoteError: action.payload,
      };
    case Types.GET_QUOTE_RESET:
      return {
        ...state,
        quote: [],
        quoteLoading: false,
        quoteError: null,
      };
    case Types.GET_QUOTE_CODE_POST:
      return { ...state, quoteCodePost: action.payload };
    // Inserse um pedido
    case Types.GET_INSERT_REQUEST:
      return {
        ...state,
        orderInsertLoading: true,
        orderInsertError: null,
      };
    case Types.GET_INSERT_SUCCESS:
      return {
        ...state,
        orderListSuccess: action.payload,
        orderInsertLoading: false,
        orderInsertError: null,
      };
    case Types.GET_INSERT_FAILURE:
      return {
        ...state,
        orderInsertLoading: false,
        listErrors: action.payload,
      };
    // Atualiza um pedido
    case Types.GET_UPDATE_REQUEST:
      return {
        ...state,
        orderUpdateLoading: true,
        orderUpdateError: null,
      };
    case Types.GET_UPDATE_SUCCESS:
      return {
        ...state,
        orderList: [action.payload],
        orderUpdateLoading: false,
        orderUpdateError: null,
      };
    case Types.GET_UPDATE_FAILURE:
      return {
        ...state,
        orderUpdateLoading: false,
        orderUpdateError: action.payload,
      };
    // Deleta um pedido
    case Types.GET_DELETE_REQUEST:
      return {
        ...state,
        orderDeleteLoading: true,
        orderDeleteError: null,
      };
    case Types.GET_DELETE_SUCCESS:
      return {
        ...state,
        orderDeleteLoading: false,
        orderDeleteError: null,
      };
    case Types.GET_DELETE_FAILURE:
      return {
        ...state,
        orderDeleteLoading: false,
        orderDeleteError: action.payload,
      };
    case Types.RESET_LIST_ERRORS:
      return {
        ...state,
        listErrors: [],
      };

    case AuthTypes.GET_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export const Creators = {
  // Busca um pedido
  getOrderRequest: id => ({
    type: Types.GET_REQUEST,
    payload: { id },
  }),
  getOrderSuccess: ({ data }) => ({
    type: Types.GET_SUCCESS,
    payload: data,
  }),
  getOrderFailure: error => ({
    type: Types.GET_FAILURE,
    payload: error,
  }),
  // Busca lista de pedido
  getOrderListRequest: payload => ({
    type: Types.GET_LIST_REQUEST,
    payload,
  }),
  getOrderListSuccess: ({ data, total }) => ({
    type: Types.GET_LIST_SUCCESS,
    payload: { data, total },
  }),
  getOrderListFailure: error => ({
    type: Types.GET_LIST_FAILURE,
    payload: error,
  }),
  // Obtem cotações de frete
  getQuoteRequest: (address, products) => ({
    type: Types.GET_QUOTE_REQUEST,
    payload: { address, products },
  }),
  getQuoteSuccess: ({ data }) => ({
    type: Types.GET_QUOTE_SUCCESS,
    payload: data,
  }),
  getQuoteFailure: error => ({
    type: Types.GET_QUOTE_FAILURE,
    payload: error,
  }),
  getQuoteReset: () => ({
    type: Types.GET_QUOTE_RESET,
  }),
  getQuoteCodePost: code => ({
    type: Types.GET_QUOTE_CODE_POST,
    payload: code,
  }),
  getOrderInsertRequest: payload => ({
    type: Types.GET_INSERT_REQUEST,
    payload,
  }),
  getOrderInsertSuccess: ({ data }) => ({
    type: Types.GET_INSERT_SUCCESS,
    payload: data,
  }),
  getOrderInsertFailure: ({ data }) => ({
    type: Types.GET_INSERT_FAILURE,
    payload: data,
  }),
  // Atualiza um  pedido
  getOrderUpdateRequest: payload => ({
    type: Types.GET_UPDATE_REQUEST,
    payload,
  }),
  getOrderUpdateSuccess: ({ data }) => ({
    type: Types.GET_UPDATE_SUCCESS,
    payload: data,
  }),
  getOrderUpdateFailure: error => ({
    type: Types.GET_UPDATE_FAILURE,
    payload: error,
  }),
  // Deleta um pedido
  getOrderDeleteRequest: id => ({
    type: Types.GET_DELETE_REQUEST,
    payload: { id },
  }),
  getOrderDeleteSuccess: () => ({
    type: Types.GET_DELETE_SUCCESS,
  }),
  getOrderDeleteFailure: error => ({
    type: Types.GET_DELETE_FAILURE,
    payload: error,
  }),
  getOrderFiles: id => ({
    type: Types.GET_ORDER_FILES,
    payload: id,
  }),
  getOrderFilesSucess: ({ data }) => ({
    type: Types.GET_ORDER_FILES_SUCCESS,
    payload: data,
  }),
  // reseta os erros
  resetListErrors: () => ({
    type: Types.RESET_LIST_ERRORS,
  }),
};
