import Styled, { css } from 'styled-components';
import { Box } from '@material-ui/core';
import appColors from 'utils/appColors';

export const BoxStyled = Styled(Box)`
  && {
    @media all and (max-width: 500px) {
      width: 300px;
    }
  }
`;

export const Title = Styled.h3`
  color: ${appColors.SECUNDARY_COLOR};
  font-weight: bold;
  font-size: 26px;
  padding: 1px;
  margin-top: 23px;
  margin-left: 10px;
`;

export const StoreBox = Styled.div`
  position: relative;
  height: 150px;
  min-width: 100px;
  width: 110px;
  margin: 5px 0px;
  padding: 8px;
  border: 1 solid #ccc;
  border-radius: 8px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 3px solid rgb(255, 255, 255);
  cursor: pointer;
  transition: left 1s;

  ${props =>
    props.left &&
    props.left > 0 &&
    css`
      left: ${props.left * -1}px;
    `}

  ${props =>
    props.isActive &&
    css`
      border: 3px solid rgb(254, 55, 162);
    `}


  && {
    margin-left: 8px;
  }

  > div {
    margin-top: 10px;
    min-height: 80px;
    max-height: 80px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      text-align: center;
      width: 80%;
    }
  }

  span {
    font-size: 12px;
    text-align: center;
  }

`;

export const StoresContainer = Styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 20;
  background-color: ${appColors.LIGHT_COLOR};
  border-radius: 10px;
  margin-bottom: 15px;
  margin-left: 10px;
`;

export const ContainerSearch = Styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 15px 5px 15px;

  svg{
    color: rgb(254, 55, 162);
    margin-right: 10px;
    margin-left: 50px;
  }
  input {
    height: 30px;
    font-size: 18px;
    font-weight: bold;
    color: rgb(254, 55, 162);
    border: 0;
    background: transparent;
    outline: none;
  }
`;
export const ContainerStores = Styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 0px;
  overflow: hidden;
`;

export const StoresContent = Styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
`;

export const ButtonSore = Styled.button`
  width: 60px;
  background: #fff;
  border: 0px;
  border-radius: 8px;
  height: 50px;
  cursor: pointer;
  margin: 0 15px;
`;

export const ProductsContainer = Styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	grid-gap: 10px;
  margin-bottom: 40px;

  > div {
    height: 320px;
    background-color: ${appColors.LIGHT_COLOR};
    border-radius: 6px;
    text-align: center;
    transition: opacity 0.2s;
    cursor: pointer;
    position: 'relative';

    &:hover {
      opacity: 0.8;
    }

    span {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3; // Define o número máximo de linhas
      overflow: hidden;
      padding-top: 10px;
      font-size: 13px;
      font-weight: bold;
    }
  }
`;

export const ImgLogoStore = Styled.img`
    width: 40px;
    position: 'absolute';
    top: 2px;
    left: 5px;
`;

export const ImgProduct = Styled.div`
  background-color: ${appColors.LIGHT_COLOR};
  ${props =>
    props.image &&
    css`
      background: url(${props.image});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center top;
    `}
  height: 240px;
  width: 100%;
`;

export const ButtonFilter = Styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  place-content: center;
  padding: 6px 10px;
  border: 0;
  border-radius: 8px;
  background: #3bbcd4;
  color: #fff;
  font-size: 15px;
 `;
