const styles = () => ({
  pageTitle: {
    flex: 1,
    position: 'relative',
  },
  pageTitleContent: {
    flexAlign: 'center',
  },
});

export default styles;
