import appColors from 'utils/appColors';

const styles = {

  titleInput: {
    color: '#666666',
    fontSize: 14,
    fontWeight: 'bold',
  },
  purpleButton: {
    backgroundColor: appColors.PRIMARY_COLOR,
    color: 'white',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    marginLeft: 20,
  },
  purpleButtonProceed: {
    backgroundColor: appColors.PRIMARY_COLOR,
    color: 'white',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    marginTop: 30,
    paddingBottom: 10,
    width: '100%',
  },
};

export default styles;
