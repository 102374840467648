import styled from 'styled-components';
import { Box, Typography } from '@material-ui/core';
import appColors from 'utils/appColors';


export const BoxInfoDetailProd = styled(Box)`
    && {
        min-width: 800px;
        
        @media all and (max-width:500px) {
            flex: 1;
            min-width: 0px;
            
         }

        @media all and (max-width:1270px) {
           min-width: 200px;
           display: flex;
           justify-content: center;
        }
    }
`;

export const BoxAlign = styled(Box)`
    && {
        @media all and (max-width:500px) {
           display: flex;
           justify-content: center;
           align-itens: center;
           flex-direction: column;
        }
    }
`;

export const TextCenter = styled(Typography)`
&& {
    @media all and (max-width:500px) {
        text-align: center;
    }
}
`

const styles = {
    purpleTitle: {
        color: appColors.SECUNDARY_COLOR,
        fontSize: 36,
        fontWeight: 'bold',
        paddingBottom: 40,
    },
    subTitle: {
        color: '#373737',
        fontSize: 16,
    },
    pricePurple: {
        color: appColors.SECUNDARY_COLOR,
        fontSize: 22,
        fontWeight: 'bold',
        paddingTop: 40,
        paddingBottom: 40
    },
    titleButton: {
        marginLeft: 10,
        fontSize: 16,
    },
    smallTextPurple: {
        color: appColors.SECUNDARY_COLOR,
        fontSize: 14,
        fontWeight: 'bold',
    },
    mediumText: {
        fontSize: 18,
        paddingBottom: 10,
        paddingTop: 10,
        color: '#808080',
    },
    miniBox: {
        width: 90,
        border: `1px solid ${appColors.SECUNDARY_COLOR}`,
        textAlign: 'center',
        overflow: 'hidden',
        paddingTop: 2,
        paddingBottom: 2,
        marginRight: 10,
        marginTop: 10,
    },
    titleMiniBox: {
        fontSize: 12,
        color: '#373737',
    },
    unavailableProduct: {
        backgroundColor: appColors.PRIMARY_COLOR,
        padding: 10,
        borderRadius: 10
    },
    whiteTitle: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: 22
    }
}

export default styles;