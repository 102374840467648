import appColors from 'utils/appColors';

const styles = {
  resumeContainer:{
    backgroundColor:'#DEDEDE',
    borderRadius:5,
    marginTop:20,
    padding:40,
  },
  titleBoldPurple:{
    color: appColors.PRIMARY_COLOR,
    fontWeight:'bold',
    marginBottom:5
  },
  bigFontBold:{
    fontWeight:'bold',
    fontSize:20
  },
}

export default styles;