import React from 'react';
import notImage from 'images/Products/not_image.jpg';
import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import appUtils from 'utils/appUtils';

const StyledSelect = styled(Autocomplete)`
  width: 100%;
  && {
    & > div {
      min-height: 43px;
    }
  }
  && {
    .MuiOutlinedInput-root {
      background: white;
    }
    & .MuiOutlinedInput-input {
      padding: 12px 12px;
    }
    & .MuiInputLabel-outlined[data-shrink='false'] {
      transform: translate(16px, 13px) scale(1);
    }
  }
`;

const StyledDiv = styled('div')`
  && {
    position: relative;
  }
`;

const AutoCompleteSelect = ({
  field,
  options,
  isLoading,
  search,
  getPrototipy,
  ...rest
}) => {
  const [open, setOpen] = React.useState(false);

  const onInputChange = data => {
    if (data) {
      getPrototipy(data.id);
    }
  };

  const handleSelect = event => {
    if (event.target.value && event.target.value.length >= 4) {
      search({ name: event.target.value });
    }
  };

  return (
    <StyledDiv>
      <StyledSelect
        freeSolo
        disableClearable
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onSelect={handleSelect}
        onChange={(_, v) => onInputChange(v)}
        getOptionSelected={(option, value) => option.name === value.name}
        getOptionLabel={option => option.name || ''}
        options={options}
        inputValue={field.value}
        loading={isLoading}
        renderOption={prod => (
          <Box display="flex" alignItems="center">
            <Box
              style={{
                width: 40,
                height: 70,
                overflow: 'hidden',
                marginRight: 10,
              }}>
              <img
                alt=""
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                src={prod.sizes.medium ? prod.sizes.medium : notImage}
              />
            </Box>
            <StyledDiv style={{ margin: 5 }}>
              <Typography noWrap>Nome: {prod.name}</Typography>
              <Typography noWrap>Categoria: {prod.category}</Typography>
              <Typography noWrap>
                Preço: {appUtils.formatPrice(prod.price)}
              </Typography>
            </StyledDiv>
          </Box>
        )}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              style: { height: 43, alignContent: 'center' },
            }}
            {...field}
            {...rest}
          />
        )}
      />
    </StyledDiv>
  );
};

export default AutoCompleteSelect;
