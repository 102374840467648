import appColors from 'utils/appColors';

const styles = {
    descrition:{
        marginBottom:40
    },
    bigTitlePurple:{
        color: appColors.PRIMARY_COLOR, 
        fontWeight:'bold',
        fontSize:18
    },
    purpleButton: {
        backgroundColor: appColors.PRIMARY_COLOR,
        color: 'white',
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop:10,
        paddingBottom:10,
        marginLeft:20,
    },
    fontPurple:{
        color: appColors.PRIMARY_COLOR, 
    },
    rowAddres:{
        padding:5,
    }

}

export default styles;