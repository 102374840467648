import { Creators as ProductColorCreators } from 'store/ducks/admin/productColor';
import { Creators as ProductSizeCreators } from 'store/ducks/admin/productSize';
import VariationField from 'components/Pages/MyAccountPage/PainelAccount/TabsPanel/ProductsTab/components/ProductForm/VariationField';
import { Field } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { InputView, InputItem } from './styles';
import { Typography } from '@material-ui/core';
import CustomSelect from 'components/form/CustomSelect';
import CustomTextField from 'components/form/CustomTextField';

function Variations({ values }) {
  const dispatch = useDispatch();
  const {
    admin: {
      productColor: { productColorList, productColorListLoading },
      productSize: { productSizeList, productSizeListLoading },
    },
  } = useSelector(state => state);

  useEffect(() => {
    dispatch(
      ProductColorCreators.getProductColorListRequest({ perPage: 1000 })
    );
    dispatch(ProductSizeCreators.getProductSizeListRequest({ perPage: 1000 }));
  }, [dispatch]);

  return (
    <div>
      <InputView>
        <InputItem>
          <Typography variant="h6">Controlar estoque?</Typography>
          <Field
            data-cy="select_stock_control"
            name="stock_control"
            label="Controle de estoque"
            options={[
              { name: 'Não', id: 0 },
              { name: 'Sim', id: 1 },
            ]}
            component={CustomSelect}
            placeholder="Controle de estoque"
            isLoading={false}
          />
        </InputItem>
        {values.stock_control === 1 && values.variations.length === 0 && (
          <InputItem>
            <Typography variant="h6">Estoque geral disponível</Typography>
            <Field
              name="stock"
              id="estoque"
              type="number"
              label="Quantidade"
              component={CustomTextField}
            />
          </InputItem>
        )}
      </InputView>
      <Field
        name="variations"
        stock_control={values.stock_control}
        label="Variations"
        component={VariationField}
        placeholder="Variações"
        colors={productColorList}
        sizes={productSizeList}
        isLoadingColor={productColorListLoading}
        isLoadingSize={productSizeListLoading}
      />
    </div>
  );
}

export default Variations;
