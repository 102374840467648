import React from 'react';
import Styled from 'styled-components';
import { Box } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

export const BoxStyled = Styled(Box)`
  && {
    @media all and (max-width: 500px) {
      width: 300px;
    }
  }
`;

export const SearchBox = Styled.div`
  flex: 1;
  position: relative;
  background: #f3f3f3;
  height: 25px;
`;

export const Search = Styled.input`
  padding: 0px 10px;
  height: 0px;
  font-size: 16px;
  color: #666;
`;

const AlertBox = Styled.div`
  width: 100%;
  padding: 8px 16px;
  font-weight: 600;
  color: rgb(102, 60, 0);
  background: rgb(255, 244, 229);
  display: flex;
  align-items: center;
  font-size: 13px;
  position: relative;

  svg {
    margin-right: 16px;
    color: #ff9800;
  }

  &+& {
    margin-top: 5px;
  }
`;

export const Alert = ({children}) => <AlertBox><WarningIcon/>{children}</AlertBox>
