export const unicVariation = ({
  code_internal,
  price,
  stock_control,
  stock,
  price_whole,
}) => [
  {
    id: 0,
    code_internal: code_internal,
    price_whole,
    code_ean: '',
    name: 'Único ',
    slug: 'Único',
    price,
    stock: stock_control === 1 ? stock : 10000000000,
    color: {
      id: 180,
      name: 'ÚNICA',
      status: 1,
    },
    size: {
      id: 1,
      name: 'ÚNICO',
    },
  },
];
