import styled from 'styled-components'
import { Box } from '@material-ui/core';
import appColors from 'utils/appColors';

export const LinkSimulator = styled.span`
&& {
    color: ${appColors.PRIMARY_COLOR};
    &:hover {
        color:blue;
        text-decoration: underline;
        cursor:pointer;
    }
}
`;

export const BoxLoginContainer = styled(Box)`
  && {
    padding-left: 40px;
    padding-right: 40px; 
    margin: 50px 0 50px 0;
  }

`;

export const BoxTitle = styled(Box)`
  && {
    @media all and (max-width:580px) {
      margin-top: 40px;
    }
    @media all and (min-width:581px) and (max-width: 920px) {
      margin-top: 40px;
    }
  }

`;

const styles = {
  purpleTitle: {
    color: appColors.SECUNDARY_COLOR,
    fontSize: 22,
  },
};

export default styles;
