import { call, takeLatest, all, put } from 'redux-saga/effects';
import api from 'services/api';
import { Types, Creators } from 'store/ducks/admin/closed';
import { callApi } from 'store/sagas/auth';
import Notifications from 'react-notification-system-redux';

function* getStoreClosedDaysRequest({ payload }) {
  try {
    const { id } = payload;
    const request = call(api.get, `/v1/admin/stores/closed/${id}`);
    const response = yield call(callApi, request);
    yield put(Creators.getStoreClosedListSuccess(response.data.data || []));
  } catch (err) {
    yield put(Creators.getStoreFailure('Erro ao buscar na API'));
  }
}

function* getStoreClosedDaysInsert({ payload }) {
  try {
    const request = call(api.post, `/v1/admin/stores/closed`, {
      ...payload,
      type: 'store',
    });
    const response = yield call(callApi, request);
    yield put(Creators.getStoreClosedInsertSuccess(response.data.data));
    yield put(Notifications.success({ title: 'Adicionado com sucesso!' }));
  } catch (err) {
    yield put(Creators.getStoreFailure('Erro ao buscar na API'));
  }
}
function* getStoreClosedDaysUpdate({ payload }) {
  try {
    const { id } = payload;
    const request = call(api.put, `/v1/admin/stores/closed/${id}`, {
      ...payload,
    });
    const response = yield call(callApi, request);
    console.log(response);
    yield put(Creators.getStoreClosedUpdateSuccess(payload));
    yield put(Notifications.success({ title: 'Atualizado com sucesso!' }));
  } catch (err) {
    yield put(Creators.getStoreFailure('Erro ao buscar na API'));
  }
}

function* getStoreClosedDaysDelete({ payload }) {
  try {
    const { id } = payload;
    const request = call(api.delete, `/v1/admin/stores/closed/${id}`);
    const response = yield call(callApi, request);
    console.log(response);
    yield put(Creators.getStoreClosedDeleteSuccess(payload.id));
    yield put(Notifications.success({ title: 'Removido com sucesso!' }));
  } catch (err) {
    yield put(Creators.getStoreFailure('Erro ao buscar na API'));
  }
}
// Individual exports for testing
export default function* storeListSaga() {
  yield all([
    takeLatest(Types.GET_LIST_REQUEST, getStoreClosedDaysRequest),
    takeLatest(Types.GET_INSERT_REQUEST, getStoreClosedDaysInsert),
    takeLatest(Types.GET_UPDATE_REQUEST, getStoreClosedDaysUpdate),
    takeLatest(Types.GET_DELETE_REQUEST, getStoreClosedDaysDelete),
    // takeLatest(Types.GET_LIST_REQUEST, getStoreListRequest),
  ]);
}
