import React from 'react';
import { Typography, Breadcrumbs } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from 'react-router-dom';
import styles from './styles';

import { Box } from '@chakra-ui/react';

const HeaderPages = ({ title, currentPage }) => {
  return (
    <>
      <Box textAlign="center">
        <Typography style={styles.purpleTitle}>{title}</Typography>
      </Box>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb">
        <Link to="/">Início</Link>
        <Typography color="textPrimary">{currentPage || 'Carrinho'}</Typography>
      </Breadcrumbs>
    </>
  );
};

export default HeaderPages;
