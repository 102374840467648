export const Types = {
  // Insere uma collaborator
  GET_INSERT_REQUEST: 'collaborator/GET_INSERT_REQUEST',
  GET_INSERT_SUCCESS: 'collaborator/GET_INSERT_SUCCESS',
  GET_INSERT_FAILURE: 'collaborator/GET_INSERT_FAILURE',
  // Obtem collaborators
  GET_LIST_REQUEST: 'collaborator/GET_LIST_REQUEST',
  GET_LIST_SUCCESS: 'collaborator/GET_LIST_SUCCESS',
  GET_LIST_FAILURE: 'collaborator/GET_LIST_FAILURE',
  // Obtem collaborators
  GET_COLLABORATOR_REQUEST: 'collaborator/GET_COLLABORATOR_REQUEST',
  GET_COLLABORATOR_SUCCESS: 'collaborator/GET_COLLABORATOR_SUCCESS',
  GET_COLLABORATOR_FAILURE: 'collaborator/GET_COLLABORATOR_FAILURE',
  // Obtem collaborators by id
  GET_COLLABORATORBYID_REQUEST: 'collaborator/GET_COLLABORATORBYID_REQUEST',
  GET_COLLABORATORBYID_SUCCESS: 'collaborator/GET_COLLABORATORBYID_SUCCESS',
  GET_COLLABORATORBYID_FAILURE: 'collaborator/GET_COLLABORATORBYID_FAILURE',
  // Obtem collaborators deletados
  GET_LIST_DELETED_REQUEST: 'collaborator/GET_LIST_DELETED_REQUEST',
  GET_LIST_DELETED_SUCCESS: 'collaborator/GET_LIST_DELETED_SUCCESS',
  GET_LIST_DELETED_FAILURE: 'collaborator/GET_LIST_DELETED_FAILURE',
  // Atualiza uma collaborator
  GET_UPDATE_REQUEST: 'collaborator/GET_UPDATE_REQUEST',
  GET_UPDATE_SUCCESS: 'collaborator/GET_UPDATE_SUCCESS',
  GET_UPDATE_FAILURE: 'collaborator/GET_UPDATE_FAILURE',
  // Insere uma collaborator csv
  GET_INSERT_CSV_REQUEST: 'collaboratorCsv/GET_INSERT_REQUEST',
  GET_INSERT_CSV_SUCCESS: 'collaboratorCsv/GET_INSERT_SUCCESS',
  GET_INSERT_CSV_FAILURE: 'collaboratorCsv/GET_INSERT_FAILURE',
  // Deleta uma collaborator
  GET_DELETE_REQUEST: 'collaborator/GET_DELETE_REQUEST',
  GET_DELETE_SUCCESS: 'collaborator/GET_DELETE_SUCCESS',
  GET_DELETE_FAILURE: 'collaborator/GET_DELETE_FAILURE',
  // Reativa um collaborator
  GET_RESTORE_REQUEST: 'collaborator/GET_RESTORE_REQUEST',
  GET_RESTORE_SUCCESS: 'collaborator/GET_RESTORE_SUCCESS',
  GET_RESTORE_FAILURE: 'collaborator/GET_RESTORE_FAILURE',

  // Obtem catergorias
  GET_REQUEST: 'collaborator/GET_REQUEST',
  GET_SUCCESS: 'collaborator/GET_SUCCESS',
  GET_FAILURE: 'collaborator/GET_FAILURE',
};

export const initialState = {
  // Categoria por id
  collaborator: {},
  collaboratorLoading: false,
  collaboratorError: null,
  // insere collaborator
  collaboratorInsert: {},
  collaboratorInsertLoading: false,
  collaboratorInsertError: false,
  // collaborator por id
  collaboratorById: {},
  collaboratorByIdLoading: false,
  collaboratorByIdError: false,
  // Lista de collaborators
  collaboratorList: [],
  collaboratorListLoading: false,
  collaboratorListError: null,
  collaboratorListTotal: 0,
  // Lista de collaborators deletados
  collaboratorListDeleted: [],
  collaboratorListDeletedLoading: false,
  collaboratorListDeletedError: null,
  collaboratorListDeletedTotal: 0,
  // Atualiza uma collaborator
  collaboratorUpdateLoading: false,
  collaboratorUpdateError: false,
  // Deleta collaborator
  collaboratorDeleteLoading: false,
  collaboratorDeleteError: null,
  // Reativa collaborator
  collaboratorRestoreLoading: false,
  collaboratorRestoreError: null,

  collaboratorCsv: [],
  collaboratorCsvInsertLoading: false,
  collaboratorCsvInsertError: false,
  // Categoria por id
  cashbackCollaborator: {},
  cashbackCollaboratorLoading: false,
  cashbackCollaboratorError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_COLLABORATOR_REQUEST:
      return { ...state, collaboratorLoading: true };
    case Types.GET_COLLABORATOR_SUCCESS:
      return {
        ...state,
        collaborator: action.payload,
        collaboratorLoading: false,
        collaboratorError: null,
      };
    case Types.GET_COLLABORATOR_FAILURE:
      return {
        ...state,
        collaboratorLoading: false,
        collaboratorError: action.payload,
      };
    //collaborator by id
    case Types.GET_COLLABORATORBYID_REQUEST:
      return { ...state, collaboratorByIdLoading: true };
    case Types.GET_COLLABORATORBYID_SUCCESS:
      return {
        ...state,
        collaboratorById: action.payload,
        collaboratorByIdLoading: false,
        collaboratorByIdError: null,
      };
    case Types.GET_COLLABORATORBYID_FAILURE:
      return {
        ...state,
        collaboratorByIdLoading: false,
        collaboratorByIdError: action.payload,
      };
    // collaborator Logado
    case Types.GET_INSERT_REQUEST:
      return {
        ...state,
        collaboratorInsertError: null,
        collaboratorInsertLoading: true,
      };
    case Types.GET_INSERT_SUCCESS:
      return {
        ...state,
        collaboratorInsert: action.payload,
        collaboratorInsertLoading: false,
        collaboratorInsertError: null,
      };
    case Types.GET_INSERT_FAILURE:
      return {
        ...state,
        collaboratorInsertLoading: false,
        collaboratorInsertError: action.payload,
      };
    // Atualiza um collaborator
    case Types.GET_UPDATE_REQUEST:
      return {
        ...state,
        collaboratorUpdateLoading: true,
        collaboratorUpdateError: null,
      };
    case Types.GET_UPDATE_SUCCESS:
      return {
        ...state,
        collaboratorUpdateLoading: false,
        collaboratorUpdateError: null,
      };
    case Types.GET_UPDATE_FAILURE:
      return {
        ...state,
        collaboratorUpdateLoading: false,
        collaboratorUpdateError: action.payload,
      };
    // Deleta uma collaborator
    case Types.GET_DELETE_REQUEST:
      return {
        ...state,
        collaboratorDeleteLoading: true,
        collaboratorDeleteError: null,
      };
    case Types.GET_DELETE_SUCCESS:
      return {
        ...state,
        collaboratorDeleteLoading: false,
        collaboratorDeleteError: null,
      };
    case Types.GET_DELETE_FAILURE:
      return {
        ...state,
        collaboratorDeleteLoading: false,
        collaboratorDeleteError: action.payload,
      };

    case Types.GET_collaborator_UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        collaboratorList: state.collaboratorList.map(a =>
          action.payload.data.id === a.id ? action.payload.data : a
        ),
      };

    // Reativa uma collaborator
    case Types.GET_RESTORE_REQUEST:
      return {
        ...state,
        collaboratorRestoreLoading: true,
        collaboratorRestoreError: null,
      };
    case Types.GET_RESTORE_SUCCESS:
      return {
        ...state,
        collaboratorRestoreLoading: false,
        collaboratorRestoreError: null,
      };
    case Types.GET_RESTORE_FAILURE:
      return {
        ...state,
        collaboratorRestoreLoading: false,
        collaboratorRestoreError: action.payload,
      };

    // Lista de collaborators
    case Types.GET_LIST_REQUEST:
      return { ...state, collaboratorListLoading: true };
    case Types.GET_LIST_SUCCESS:
      return {
        ...state,
        collaboratorList: action.payload.data,
        collaboratorListLoading: false,
        collaboratorListError: null,
        collaboratorListTotal: action.payload.total,
      };
    case Types.GET_LIST_FAILURE:
      return {
        ...state,
        collaboratorListLoading: false,
        collaboratorListError: action.payload,
      };

    case Types.GET_INSERT_CSV_REQUEST:
      return {
        ...state,
        collaboratorInsertError: null,
        collaboratorInsertLoading: true,
      };
    case Types.GET_INSERT_CSV_SUCCESS:
      return {
        ...state,
        collaborator: action.payload,
        collaboratorInsertLoading: false,
        collaboratorInsertError: null,
      };
    case Types.GET_INSERT_CSV_FAILURE:
      return {
        ...state,
        collaboratorInsertLoading: false,
        collaboratorInsertError: action.payload,
      };
    // Lista de collaborators deletados
    case Types.GET_LIST_DELETED_REQUEST:
      return { ...state, collaboratorListDeletedLoading: true };
    case Types.GET_LIST_DELETED_SUCCESS:
      return {
        ...state,
        collaboratorListDeleted: action.payload.data,
        collaboratorListDeletedLoading: false,
        collaboratorListDeletedError: null,
        collaboratorListDeletedTotal: action.payload.total,
      };
    case Types.GET_LIST_DELETED_FAILURE:
      return {
        ...state,
        collaboratorListDeletedLoading: false,
        collaboratorListDeletedError: action.payload,
      };

    // Categoria por id
    case Types.GET_REQUEST:
      return {
        ...state,
        cashbackCollaborator: action.payload,
        cashbackCollaboratorError: null,
        cashbackCollaboratorLoading: true,
      };
    case Types.GET_SUCCESS:
      return {
        ...state,
        cashbackCollaborator: action.payload.data,
        cashbackCollaboratorLoading: false,
        cashbackCollaboratorError: null,
      };
    case Types.GET_FAILURE:
      return {
        ...state,
        cashbackCollaboratorLoading: false,
        cashbackCollaboratorError: action.payload,
      };

    default:
      return state;
  }
};

export const Creators = {
  // Busca um colaborador
  getCollaboratorRequest: ({ cell_phone, access_token }) => ({
    type: Types.GET_COLLABORATOR_REQUEST,
    payload: { cell_phone, access_token },
  }),
  getCollaboratorSuccess: ({ data }) => ({
    type: Types.GET_COLLABORATOR_SUCCESS,
    payload: data,
  }),
  getCollaboratorFailure: error => ({
    type: Types.GET_COLLABORATOR_FAILURE,
    payload: error,
  }),
  // Insere uma parceiro
  getColllaboratorInsertRequest: data => ({
    type: Types.GET_INSERT_REQUEST,
    payload: data,
  }),
  getColllaboratorInsertSuccess: data => ({
    type: Types.GET_INSERT_SUCCESS,
    payload: data,
  }),
  getColllaboratorInsertFailure: error => ({
    type: Types.GET_INSERT_FAILURE,
    payload: error,
  }),
  // busca de collaborator by id
  getColllaboratorByIDRequest: ({ id }) => ({
    type: Types.GET_COLLABORATORBYID_REQUEST,
    payload: { id },
  }),
  getColllaboratorByIDSuccess: data => ({
    type: Types.GET_COLLABORATORBYID_SUCCESS,
    payload: data,
  }),
  getColllaboratorByIDFailure: error => ({
    type: Types.GET_COLLABORATORBYID_FAILURE,
    payload: error,
  }),
  // Atualiza uma  Collaborator

  getCollaboratorUpdateRequest: ({
    id,
    address,
    birth_date,
    cpf,
    email,
    genre,
    name,
    phone,
    role,
    status,
  }) => ({
    type: Types.GET_UPDATE_REQUEST,
    payload: {
      id,
      address,
      birth_date,
      cpf,
      email,
      genre,
      name,
      phone,
      role,
      status,
    },
  }),
  getCollaboratorUpdateSuccess: () => ({
    type: Types.GET_UPDATE_SUCCESS,
  }),
  getCollaboratorUpdateFailure: error => ({
    type: Types.GET_UPDATE_FAILURE,
    payload: error,
  }),
  // Busca lista de collaborator
  getCollaboratorListRequest: ({
    page,
    perPage,
    search,
    orderByColumn,
    orderByDirection,
    group_id,
  }) => ({
    type: Types.GET_LIST_REQUEST,
    payload: {
      page,
      perPage,
      search,
      orderByColumn,
      orderByDirection,
      group_id,
    },
  }),
  getCollaboratorListSuccess: ({ data, total }) => ({
    type: Types.GET_LIST_SUCCESS,
    payload: { data, total },
  }),
  getCollaboratorListFailure: error => ({
    type: Types.GET_LIST_FAILURE,
    payload: error,
  }),
  // Busca lista de collaborator deleted
  getCollaboratorDeletedListRequest: ({
    page,
    perPage,
    search,
    orderByColumn,
    orderByDirection,
    group_id,
  }) => ({
    type: Types.GET_LIST_DELETED_REQUEST,
    payload: {
      page,
      perPage,
      search,
      orderByColumn,
      orderByDirection,
      group_id,
    },
  }),
  getCollaboratorDeletedListSuccess: ({ data, total }) => ({
    type: Types.GET_LIST_DELETED_SUCCESS,
    payload: { data, total },
  }),
  getCollaboratorDeletedListFailure: error => ({
    type: Types.GET_LIST_DELETED_FAILURE,
    payload: error,
  }),
  // CSV
  getColllaboratorCsvInsertRequest: data => ({
    type: Types.GET_INSERT_CSV_REQUEST,
    payload: data,
  }),
  getColllaboratorCsvInsertSuccess: data => ({
    type: Types.GET_INSERT_CSV_SUCCESS,
    payload: data,
  }),
  getColllaboratorCsvInsertFailure: error => ({
    type: Types.GET_INSERT_CSV_FAILURE,
    payload: error,
  }),
  getCollaboratorCSVUpdateRequest: ({
    id,
    email,
    password,
    name,
    cpf,
    genre,
    description,
    cell_phone,
    birth_date,
    store,
    group,
    address,
    image_data,
  }) => ({
    type: Types.GET_UPDATE_REQUEST,
    payload: {
      id,
      email,
      password,
      name,
      cpf,
      store,
      genre,
      description,
      cell_phone,
      birth_date,
      group,
      address,
      image_data,
    },
  }),
  getCollaboratorCSVUpdateSuccess: () => ({
    type: Types.GET_UPDATE_SUCCESS,
  }),
  getCollaboratorCSVUpdateFailure: error => ({
    type: Types.GET_UPDATE_FAILURE,
    payload: error,
  }),
  // Deleta uma Collaborator
  getCollaboratorDeleteRequest: id => ({
    type: Types.GET_DELETE_REQUEST,
    payload: { id },
  }),
  getCollaboratorDeleteSuccess: () => ({
    type: Types.GET_DELETE_SUCCESS,
  }),
  getCollaboratorDeleteFailure: error => ({
    type: Types.GET_DELETE_FAILURE,
    payload: error,
  }),
  getCollaboratorStatusRequest: (id, status, email) => ({
    type: Types.GET_Collaborator_STATUS_REQUEST,
    payload: { client: id, status, email },
  }),
  getEmailVerifyRequest: data => ({
    type: Types.GET_Collaborator_UPDATE_EMAIL_REQUEST,
    payload: data,
  }),
  getEmailVerifySuccess: data => ({
    type: Types.GET_Collaborator_UPDATE_EMAIL_SUCCESS,
    payload: data,
  }),
  // Reativa uma Collaborator
  getCollaboratorRestoreRequest: id => ({
    type: Types.GET_RESTORE_REQUEST,
    payload: { id },
  }),
  getCollaboratorRestoreSuccess: () => ({
    type: Types.GET_RESTORE_SUCCESS,
  }),
  getCollaboratorRestoreFailure: error => ({
    type: Types.GET_RESTORE_FAILURE,
    payload: error,
  }),

  // Busca uma categoria
  getCashbackCollaboratorRequest: filters => {
    return {
      type: Types.GET_REQUEST,
      payload: filters,
    };
  },
  getCashbackCollaboratorSuccess: ({ data }) => ({
    type: Types.GET_SUCCESS,
    payload: { data },
  }),
  getCashbackCollaboratorFailure: error => ({
    type: Types.GET_FAILURE,
    payload: error,
  }),
};
