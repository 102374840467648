import React from 'react';
import PropTypes from 'prop-types';
import { FaBarcode } from 'react-icons/fa';
import { MdAttachMoney } from 'react-icons/md';
import { Icon, Box } from '@material-ui/core';

const OrderPaymentType = ({ rowData }) => {
  const paymentType = Number(rowData.payment_type);
  return (
    <Box style={{ marginLeft: 30, marginRight: 30 }}>
      {paymentType === 1 && (
        <Icon
          title="Cartão de crédito"
          style={{ verticalAlign: 'bottom', paddingTop: 2 }}>
          credit_card
        </Icon>
      )}
      {paymentType === 2 && (
        <FaBarcode
          title="Boleto"
          fontSize={20}
          style={{ paddingLeft: 3, verticalAlign: 'bottom', paddingTop: 0 }}
        />
      )}
      {paymentType === 3 && (
        <MdAttachMoney
          title="Pagamento na Entrega"
          fontSize={20}
          style={{ paddingLeft: 3, verticalAlign: 'bottom', paddingTop: 0 }}
        />
      )}
    </Box>
  );
};

OrderPaymentType.propTypes = {
  rowData: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default OrderPaymentType;
