import React from 'react';
import { Typography } from '@material-ui/core';
import RegisterClientForm from 'components/Pages/RegisterClient/registerClientForm';
import HeaderPages from 'components/HeaderPages';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as ClientCreators } from 'store/ducks/client';
import { Creators as AddressCreators } from 'store/ducks/address';
import CustomStepper from 'components/Stepper2';
import RegisterAddress from 'components/Pages/RegisterClient/RegisterAddress';
import styles from './styles';
import RegisterSucess from 'components/Pages/RegisterClient/RegisterSucess';
import appColors from 'utils/appColors';

import { Stack, Box } from '@chakra-ui/react';

const RegisterClientPage = props => {
  const dispatch = useDispatch();

  const [localState, setLocalState] = React.useState({
    activeStep: 0,
    personalData: {
      name: '',
      email:
        typeof props.location.params !== 'undefined'
          ? props.location.params.data.email
          : '',
      newsletter:
        typeof props.location.params !== 'undefined'
          ? props.location.params.data.newsletter
          : '',
      cpf: '',
      birth_date: '',
      cell_phone: '',
      genre: '',
      password: '',
      confirmPassword: '',
      terms: null,
    },
  });

  const { activeStep, personalData } = localState;

  const { cep, cepLoading, cepError } = useSelector(state => state.address);

  const getStepContent = [
    'Dados Pessoais',
    'Dados de Endereço',
    'Cadastro Realizado com Sucesso!',
  ];

  const handleBack = () => {
    window.scrollTo(0, 0);
    setLocalState({ ...localState, activeStep: 0 });
  };

  const onSubmitCEP = values => {
    dispatch(AddressCreators.getAddressCEPRequest(values));
  };

  const onSubmitPersonal = values => {
    window.scrollTo(0, 0);
    setLocalState({ ...localState, personalData: values, activeStep: 1 });
  };

  const onSubmitRegister = () => {
    window.scrollTo(0, 0);
    setLocalState({ ...localState, activeStep: 2 });
  };

  const { clientInsertLoading } = useSelector(state => state.client);
  const handleSubmit = addressData => {
    const copyData = {};

    copyData.birth_date = personalData.birth_date.split('');
    copyData.birth_date = copyData.birth_date.join('');
    const dateInverse = copyData.birth_date.split('/');
    copyData.birth_date = `${dateInverse[2]}-${dateInverse[1]}-${dateInverse[0]}`;

    copyData.name = personalData.name;
    copyData.email = personalData.email;
    copyData.cpf = personalData.cpf;
    copyData.genre = personalData.genre;
    copyData.cell_phone = personalData.cell_phone;
    copyData.password = personalData.password;

    dispatch(
      ClientCreators.getClientInsertRequest(
        copyData,
        addressData,
        onSubmitRegister
      )
    );
  };

  return (
    <Stack alignItems="center" py="100px">
      <HeaderPages
        currentPage="Cadastro de Cliente"
        title="Quero me tornar cliente"
      />
      <Stack w={['90%', '80%']}>
        <Box
          style={{
            borderBottom: `1px solid ${appColors.PRIMARY_COLOR}`,
            marginBottom: 20,
          }}>
          <CustomStepper steps={getStepContent} activeStep={activeStep} />
        </Box>
        {activeStep === 0 && (
          <Box>
            <Typography style={styles.purpleTitle}>Dados Pessoais</Typography>
            <RegisterClientForm
              onSubmit={onSubmitPersonal}
              initialValues={personalData}
              isLoading={clientInsertLoading}
            />
          </Box>
        )}
        {activeStep === 1 && (
          <Box>
            <Typography style={styles.purpleTitle}>
              Dados de Endereço
            </Typography>
            <RegisterAddress
              onBack={handleBack}
              onSubmit={handleSubmit}
              address={cep}
              onSubmitCEP={onSubmitCEP}
              addressLoading={cepLoading}
              addressError={cepError}
            />
          </Box>
        )}
        {activeStep === 2 && (
          <Box>
            <Typography style={styles.purpleTitle}>
              Finalização de Cadastro
            </Typography>
            <RegisterSucess onBack={handleBack} />
          </Box>
        )}
      </Stack>
    </Stack>
  );
};

export default RegisterClientPage;
