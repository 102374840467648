// @flow
/*
 *
 * checkout reducer
 *
 */
export const Types = {
  // Guarda budget_hash carrinho
  ADD_BUDGET_HASH_TO_CART_REQUEST: 'checkout/ADD_BUDGET_HASH_TO_CART_REQUEST',
  ADD_BUDGET_HASH_TO_CART_SUCCESS: 'checkout/ADD_BUDGET_HASH_TO_CART_SUCCESS',
  // Adiciona manpower ao carrinho
  ADD_MANPOWER_TO_CART_REQUEST: 'checkout/ADD_MANPOWER_TO_CART_REQUEST',
  ADD_MANPOWER_TO_CART_SUCCESS: 'checkout/ADD_MANPOWER_TO_CART_SUCCESS',
  // Adiciona ao carrinho
  ADD_ITEM_TO_CART_REQUEST: 'checkout/ADD_ITEM_TO_CART_REQUEST',
  ADD_ITEM_TO_CART_SUCCESS: 'checkout/ADD_ITEM_TO_CART_SUCCESS',
  // remove do carrinho
  REMOVE_ITEM_TO_CART_REQUEST: 'checkout/REMOVE_ITEM_TO_CART_REQUEST',
  REMOVE_ITEM_TO_CART_SUCCESS: 'checkout/REMOVE_ITEM_TO_CART_SUCCESS',
  // remove um grupo de items
  REMOVE_GROUP_TO_CART_REQUEST: 'checkout/REMOVE_GROUP_TO_CART_REQUEST',
  REMOVE_GROUP_TO_CART_SUCCESS: 'checkout/REMOVE_GROUP_TO_CART_SUCCESS',
  // altera o valor dos itens de uma variação
  UPDATE_PRICE_REQUEST: 'checkout/UPDATE_PRICE_REQUEST',
  UPDATE_PRICE_SUCCESS: 'checkout/UPDATE_PRICE_SUCCESS',
  //reseta manpower do carrinho
  RESET_MANPOWER_CART_REQUEST: 'checkout/RESET_MANPOWER_CART_REQUEST',
  //reseta budget_hash do carrinho
  RESET_BUDGET_HASH_REQUEST: 'checkout/RESET_BUDGET_HASH_REQUEST',
  //reseta carrinho
  RESET_CART_REQUEST: 'checkout/RESET_CART_REQUEST',
};

export type CheckoutCartType = {
  list: ProductInCart[],
};

export const initialState: CheckoutCartType = {
  // Carrinho
  budget_hash: null,
  list: [],
  manpower: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Adiciona budget_hash no carrinho
    case Types.ADD_BUDGET_HASH_TO_CART_SUCCESS:
      return {
        ...state,
        budget_hash: action.payload,
      };
    // Adiciona manpower no carrinho
    case Types.ADD_MANPOWER_TO_CART_SUCCESS:
      return {
        ...state,
        manpower: action.payload,
      };
    // Adiciona no carrinho
    case Types.ADD_ITEM_TO_CART_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };
    // Remove no carrinho
    case Types.REMOVE_ITEM_TO_CART_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };
    // Remove grupo de items do carrinho
    case Types.REMOVE_GROUP_TO_CART_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };
    // altera o valor dos itens de uma variação
    case Types.UPDATE_PRICE_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };
    //reseta carrinho
    case Types.RESET_CART_REQUEST:
      return {
        ...state,
        list: [],
        manpower: [],
      };
    case Types.RESET_MANPOWER_CART_REQUEST:
      return {
        ...state,
        manpower: [],
      };
    case Types.RESET_BUDGET_HASH_REQUEST:
      return {
        ...state,
        budget_hash: null,
      };
    default:
      return state;
  }
};

export const Creators = {
  // Adiciona manpower ao carrinho
  addManpowerToCart: (item: ManpowerInCart) => ({
    type: Types.ADD_MANPOWER_TO_CART_REQUEST,
    payload: { item },
  }),
  addManpowerToCartSucess: (items: ManpowerInCart[]) => ({
    type: Types.ADD_MANPOWER_TO_CART_SUCCESS,
    payload: items,
  }),
  // Adiciona item ao carrinho
  addItemToCart: (item: ProductInCart) => ({
    type: Types.ADD_ITEM_TO_CART_REQUEST,
    payload: { item },
  }),
  addItemToCartSucess: (items: ProductInCart[]) => ({
    type: Types.ADD_ITEM_TO_CART_SUCCESS,
    payload: items,
  }),
  // Remove item do carrinho
  removeItemFromCart: (item: ProductInCart) => ({
    type: Types.REMOVE_ITEM_TO_CART_REQUEST,
    payload: { item },
  }),
  removeItemFromCartSuccess: (items: ProductInCart[]) => ({
    type: Types.REMOVE_ITEM_TO_CART_SUCCESS,
    payload: items,
  }),
  // Remove grupo de items do carrinho
  removeGroupFromCart: (item: ProductInCart) => ({
    type: Types.REMOVE_GROUP_TO_CART_REQUEST,
    payload: { item },
  }),
  removeGroupFromCartSuccess: (item: ProductInCart) => ({
    type: Types.REMOVE_GROUP_TO_CART_SUCCESS,
    payload: item,
  }),
  // altera o valor dos itens de uma variação
  updatePriceRequest: ({ price_actual, code_internal }) => ({
    type: Types.UPDATE_PRICE_REQUEST,
    price_actual,
    code_internal,
  }),
  updatePriceSuccess: data => ({
    type: Types.UPDATE_PRICE_SUCCESS,
    payload: data,
  }),
  addBudgetHashInCart: budgetHash => ({
    type: Types.ADD_BUDGET_HASH_TO_CART_SUCCESS,
    payload: budgetHash,
  }),
  resetCart: () => ({
    type: Types.RESET_CART_REQUEST,
  }),
  resetManpowerCart: () => ({
    type: Types.RESET_MANPOWER_CART_REQUEST,
  }),
  resetBudgetHashCart: () => ({
    type: Types.RESET_BUDGET_HASH_REQUEST,
  }),
};
