/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
import React from 'react';
import PageBase from 'components/PageBase';
import DefaultTable from 'components/Tables/DefaultTable';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as OrderCreators } from 'store/ducks/admin/order';
import { Creators as ReportCreators } from 'store/ducks/admin/report';
import HeaderComponent from 'components/HeaderComponent';
import SaleTableHeader from 'components/SalePage/SaleTableHeader';
import { Paper } from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';
import { formatDate2, formatStatus, toPrice } from 'utils/converters';
import OrderPaymentType from 'components/OrderPage/OrderPaymentType';
import SaleInfo from 'components/SalePage/SaleInfo';

const SalePage = () => {
  const columns = () => [
    { title: 'Pedido', field: 'id', type: 'numeric' },
    { title: 'Cliente', field: 'user.name' },
    {
      title: 'Status',
      field: 'status',
      render: rowData => <span>{formatStatus(rowData.status)}</span>,
    },
    {
      title: 'Pagamento',
      field: 'payment_type',
      render: rowData => <OrderPaymentType rowData={rowData} />,
    },
    {
      title: 'Preço',
      field: 'price',
      render: rowData => <span>{`R$ ${toPrice(rowData.price)}`}</span>,
    },
    {
      title: 'Realizado',
      field: 'created_at',
      render: rowData => <span>{formatDate2(rowData.created_at)}</span>,
    },
    {
      title: 'Atualizado',
      field: 'updated_at',
      render: rowData => <span>{formatDate2(rowData.updated_at)}</span>,
    },
  ];

  const dispatch = useDispatch();
  const [deleteState, setDeleteState] = React.useState({
    open: false,
    item: {},
  });

  const [localState, setLocalState] = React.useState({
    search: '',
    orderByColumn: '',
    orderByDirection: '',
    page: 1,
    perPage: 10,
    dateStart: '',
    dateEnd: '',
    store_id: '',
    client_id: '',
    payment_type: '',
    status: '',
  });

  const { orderList, orderListLoading, orderListTotal, orderDeleteLoading } =
    useSelector(state => state.order);

  const { reportOrder, reportOrderLoading } = useSelector(
    state => state.admin.report
  );

  React.useEffect(() => {
    dispatch(OrderCreators.getOrderListRequest(localState));
    dispatch(ReportCreators.getReportOrderRequest(localState));
  }, []);

  const handleAlertDialogClose = () => {
    setDeleteState({ open: false, item: {} });
  };

  React.useEffect(() => {
    if (orderDeleteLoading === false && deleteState.open) {
      handleAlertDialogClose();
    }
  }, [orderDeleteLoading]);

  const getFunction = data => {
    setLocalState(oldLocalState => ({ ...oldLocalState, ...data }));
  };

  React.useEffect(() => {
    dispatch(OrderCreators.getOrderListRequest(localState));
    dispatch(ReportCreators.getReportOrderRequest(localState));
  }, [localState]);

  const onDeleteRequest = item => {
    setDeleteState({ open: true, item });
  };

  const onDeleteConfirm = () => {
    dispatch(OrderCreators.getOrderDeleteRequest(deleteState.item.id));
    dispatch(ReportCreators.getReportOrderRequest(localState));
  };

  return (
    <PageBase style={{ marginTop: 50 }}>
      <HeaderComponent>
        <SaleTableHeader
          getFunction={getFunction}
          initialValues={{
            search: localState.search,
            client_id: localState.client_id,
            store_id: localState.store_id,
            payment_type: localState.payment_type,
            status: localState.status,
            dateStart: null,
            dateEnd: null,
          }}
        />
      </HeaderComponent>
      <SaleInfo
        reportOrder={reportOrder}
        reportOrderLoading={reportOrderLoading}
      />
      <Paper>
        <DefaultTable
          getFunction={getFunction}
          columns={columns({ onDeleteRequest })}
          data={orderList}
          total={orderListTotal}
          isLoading={orderListLoading}
          page={localState.page}
          perPage={localState.perPage}
        />
      </Paper>
      <AlertDialog
        isOpen={deleteState.open}
        isLoading={orderListLoading}
        handleClose={handleAlertDialogClose}
        onConfirm={onDeleteConfirm}
        title="Excluir registro?"
        description={`Remover pedido: ${deleteState.item.name}`}
      />
    </PageBase>
  );
};

export default SalePage;
