import React from 'react';
import CarrosselImages from 'components/CarrosselImages';
import appColors from 'utils/appColors';
import { Image, Box, Stack } from '@chakra-ui/react';
import ScrollContainer from 'react-indiana-drag-scroll';

const ImageProductSlide = ({ images }) => {
  const [localState, setLocalState] = React.useState({ thumbSelect: 0 });
  const { thumbSelect } = localState;

  const handleImage = index => {
    setLocalState({ ...localState, thumbSelect: index });
  };

  const ListImages = () => (
    <ScrollContainer className="scroll-container" nativeMobileScroll={true}>
      <Stack direction={['row', 'column']} ml="10px">
        {images.map((item, index) => (
          <Image
            w={['70px', '120px']}
            src={item.sizes.small}
            key={item.id}
            onClick={() => {
              handleImage(index);
            }}
            border={
              index === thumbSelect
                ? '3px solid ' + appColors.PRIMARY_COLOR
                : null
            }
          />
        ))}
      </Stack>
    </ScrollContainer>
  );

  return (
    <Stack flex="1" direction={['column', 'row']} align="flex-start">
      <Box display={['none', 'initial']}>
        <ListImages />
      </Box>
      <Stack w="full" p="0 20px">
        <CarrosselImages
          handleImage={handleImage}
          thumbSelect={thumbSelect}
          images={images.length > 0 ? images : undefined}
          style={{ background: 'red' }}
        />
      </Stack>
      <Box display={['initial', 'none']}>
        <ListImages />
      </Box>
    </Stack>
  );
};
export default ImageProductSlide;
