import appColors from 'utils/appColors';

const styles = {
    helloClient:{
        marginBottom:20,
    },
    linkSimulatorRed:{
        color:'red', 
        textDecoration:'underline', 
        cursor:'pointer', 
        fontWeight:'bold'
    },
    instructions:{
        marginBottom:20
    },
    purpleTitle:{
        color: appColors.PRIMARY_COLOR,
        fontSize:18,
        marginBottom:5
    },
    linkSimulatorBlue:{
        color:'blue', 
        textDecoration:'underline', 
        cursor:'pointer',
        marginTop:40,
        marginBottom:20
    }
}

export default styles