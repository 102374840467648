import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SubtitlesIcon from '@material-ui/icons/Subtitles';
import StoreIcon from '@material-ui/icons/Store';
import DashboardIcon from '@material-ui/icons/Dashboard';
import StorageIcon from '@material-ui/icons/Storage';
import InputIcon from '@material-ui/icons/Input';
import { Typography, Box } from '@material-ui/core';
import { Creators as AuthCreators } from 'store/ducks/auth';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import appColors from 'utils/appColors';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: `${appColors.LIGHT_COLOR}`,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function ShopToogleMenu({ anchorEl, handleClose }) {
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(AuthCreators.getLogoutRequest());
    handleClose();
  };

  return (
    <div>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <Link
          to="/minha-conta?aba=dashboard"
          onClick={handleClose}
          style={{ textDecoration: 'none', color: 'inherit' }}>
          <StyledMenuItem>
            <Box color="#666666" display="flex" alignItems="center">
              <DashboardIcon
                name="Dashboard"
                style={{ marginRight: 5 }}
                fontSize="small"
              />
              <Typography>Dashboard</Typography>
            </Box>
          </StyledMenuItem>
        </Link>
        <Link
          to="minha-conta?aba=pedidos-loja"
          onClick={handleClose}
          style={{ textDecoration: 'none', color: 'inherit' }}>
          <StyledMenuItem>
            <Box color="#666666" display="flex" alignItems="center">
              <SubtitlesIcon style={{ marginRight: 5 }} fontSize="small" />
              <Typography>Minhas Vendas</Typography>
            </Box>
          </StyledMenuItem>
        </Link>
        <Link
          to="/minha-conta?aba=produtos"
          onClick={handleClose}
          style={{ textDecoration: 'none', color: 'inherit' }}>
          <StyledMenuItem>
            <Box color="#666666" display="flex" alignItems="center">
              <StorageIcon style={{ marginRight: 5 }} fontSize="small" />
              <Typography>Meus Produtos</Typography>
            </Box>
          </StyledMenuItem>
        </Link>
        <Link
          to="/minha-conta?aba=loja"
          onClick={handleClose}
          style={{ textDecoration: 'none', color: 'inherit' }}>
          <StyledMenuItem>
            <Box color="#666666" display="flex" alignItems="center">
              <StoreIcon style={{ marginRight: 5 }} fontSize="small" />
              <Typography>Minha Loja</Typography>
            </Box>
          </StyledMenuItem>
        </Link>
        <StyledMenuItem onClick={logout}>
          <Box color="#666666" display="flex" alignItems="center">
            <InputIcon style={{ marginRight: 5 }} fontSize="small" />
            <Typography>Sair</Typography>
          </Box>
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
