import React from 'react';
import { FormHelperText, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ErrorMessage } from 'formik';
import InputMask from 'react-input-mask';

const StyledTextField = styled(TextField)`
  && {
    .MuiOutlinedInput-root {
      background: white;
    }
    & .MuiOutlinedInput-input {
      padding: 12px 12px;
    }
    & .MuiInputLabel-outlined[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }
`;

const CepMaskField = ({ mask, field, label, form, onSubmitCEP }) => {
  const handleChange = event => {
    form.setFieldValue(field.name, event.target.value);
    if (event.target.value.replace(/_/g, '').replace(/-/g, '').length === 8) {
      onSubmitCEP({ code_post: event.target.value });
    }
  };
  return (
    <>
      <InputMask
        {...field}
        onChange={handleChange}
        value={field.value}
        name={field.name}
        id="inputEnderecoCEP"
        fullWidth
        variant="outlined"
        mask={mask}
        error={Boolean(form.touched[field.name] && form.errors[field.name])}>
        {inputProps => <StyledTextField placeholder={label} {...inputProps} />}
      </InputMask>
      <ErrorMessage name={field.name}>
        {msg => <FormHelperText error>{msg}</FormHelperText>}
      </ErrorMessage>
    </>
  );
};

CepMaskField.propTypes = {
  field: PropTypes.oneOfType([PropTypes.object]).isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  label: PropTypes.string.isRequired,
  mask: PropTypes.string,
};

CepMaskField.defaultProps = {
  mask: '',
};

export default CepMaskField;
