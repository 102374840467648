import React from 'react';
import HeaderStores from 'components/Pages/Stores/HeaderStores';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as StoresListCreators } from 'store/ducks/stores/list';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import appColors from 'utils/appColors';
import StoreListBox from 'components/Pages/Stores/StoreListBox';
import styles, { CategoriesContainer } from './styles';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import useQuery from 'hooks/useQuery';
import appNaming from 'utils/appNaming';

const StoresPage = () => {
  const { storesList, storesListTotal, storesLoading } = useSelector(
    state => state.stores.list
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();

  const [filters, setFilters] = React.useState({
    page: query.get('page') !== null ? Number(query.get('page')) : 1,
    perPage: 12,
    search: '',
    orderByColumn: '',
    orderByDirection: '',
    order: '1',
  });
  const { page, perPage } = filters;

  React.useEffect(() => {
    dispatch(StoresListCreators.getStoresListRequest(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const nextPage = () => {
    if (page + 1 <= Math.ceil(storesListTotal / perPage)) {
      setFilters({ ...filters, page: page + 1 });
    }
  };

  const previousPage = () => {
    if (page - 1 >= 1) {
      setFilters({ ...filters, page: page - 1 });
    }
  };

  const handlePage = selectPage => {
    setFilters({ ...filters, page: selectPage });
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (page > 1) {
      history.push({
        search: `?page=${page}`,
      });
    } else {
      history.push({
        search: null,
      });
    }
    dispatch(StoresListCreators.getStoresListRequest(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    // A PAGINA /lojas é aqui
    <CategoriesContainer flex="1">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Lojas do {appNaming.REGULAR_NAMING}</title>
        <link rel="canonical" href="/" />
      </Helmet>
      <Box
        p={10}
        display="flex"
        flex="1"
        flexDirection="row"
        flexWrap="wrap"
        style={{ marginTop: 0 }}>
        <Box flex="1" flexDirection="column">
          <HeaderStores title="Lojas" />
          {storesLoading && (
            <Box
              flex="1"
              display="flex"
              justifyContent="center"
              style={{ marginTop: 20, minWidth: 200, height: 600 }}>
              <CircularProgress style={{ color: appColors.PRIMARY_COLOR }} />
            </Box>
          )}

          {!storesLoading && !storesLoading && storesList.length > 0 && (
            <StoreListBox
              flexWrap="wrap"
              data={storesList}
              total={storesListTotal}
              nextPage={nextPage}
              previousPage={previousPage}
              handlePage={handlePage}
              loading={storesLoading}
              page={page}
              perPage={perPage}
            />
          )}
          {!storesLoading && storesList.length === 0 && (
            <Box
              flex="1"
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={styles.boxEmpty}>
              <Typography style={styles.purpleTitle}>
                Nenhum resultado encontrado
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </CategoriesContainer>
  );
};

export default StoresPage;
