import appColors from 'utils/appColors';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import CustomButton from 'components/form/CustomButton';

export const StyledCustomButtonModal = styled(CustomButton)`
  && {
    margin: 15px 0px;
    margin-left: 6px;
    padding: 10px 16px;
    background-color: ${appColors.PRIMARY_COLOR};

    &:hover {
      background-color: ${appColors.LIGHT_COLOR2};
    }
  }
`;

export const MapContainer = styled.div`
  && {
    display: flex;
    width: 500px;
  }
`;

export const StyledTextField = styled(TextField)`
  && {
    .MuiOutlinedInput-root {
      background: white;
    }
    & .MuiOutlinedInput-input {
      padding: 12px 12px;
    }
    & .MuiInputLabel-outlined[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }
`;

const styles = {
  titleInput: {
    color: '#666666',
    fontSize: 14,
    fontWeight: 'bold',
  },
  purpleButton: {
    backgroundColor: appColors.PRIMARY_COLOR,
    color: 'white',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    marginLeft: 20,
  },
  purpleButtonProceed: {
    backgroundColor: appColors.PRIMARY_COLOR,
    color: 'white',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    width: '100%',
  },
  purpleTitle: {
    color: appColors.SECUNDARY_COLOR,
    fontWeight: 'bold',
    fontSize: 24,
  },
};

export const MapBox = styled.div`
  height: 100%;
  flex: 1;
  width: 100%;
`;

export const MapContent = styled.div`
  height: 100%;
  background: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    font-size: 60px;
    color: #666;
  }
`;

export default styles;
