import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 40vh;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.image &&
    css`
      background-image: url(${props.image});
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    `}

  @media (max-width: 500px) {
    height: 55vh;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1100px;
`;

export const Buttons = styled.div`
  display: flex;
  margin-top: 50px;

  svg {
    margin-right: 10px;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    margin-top: 30px;

    button {
      margin-bottom: 10px;
    }
  }
`;

export const Titles = styled.div`
  height: 300px;

  max-width: 530px;
  color: #fff;

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  @media (max-width: 500px) {
    max-width: 270px;
  }
`;
