import { call, takeLatest, all, put } from 'redux-saga/effects';
import api from 'services/api';
import { Types, Creators } from 'store/ducks/account/validateAccount';
import { Creators as NotificationCreators } from 'store/ducks/app';
import interceptResponse from 'utils/request/interceptResponse';
import interceptError from 'utils/request/interceptError';

function* getValidate({ payload }) {
  try {
    const { hash } = payload;

    const response = yield call(api.put, '/v1/client/confirm', {
      hash,
    });

    yield interceptResponse(response);
    yield put(Creators.getValidateAccountSuccess(response.data));
    yield put(
      NotificationCreators.openNotification({
        message: response.data.msg,
        type: 'success',
      })
    );
  } catch (err) {
    const error = yield interceptError(Creators.getValidateAccountFailure, err);
    console.log(error);
  }
}

export default function* ValidateSaga() {
  yield all([takeLatest(Types.GET_VALIDATE_REQUEST, getValidate)]);
}
