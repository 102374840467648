import { call, takeLatest, all, put } from 'redux-saga/effects';
import api from 'services/api';
import { Types, Creators } from 'store/ducks/stores/installment';

function* getMaxInstallment({ payload }) {
  try {
    const response = yield call(
      api.post,
      'v1/site/stores/max_installment',
      payload
    );
    yield put(Creators.getMaxInstallmentSuccess(response.data.data));
  } catch (error) {
    yield put(Creators.getStoresListFailure('Erro ao buscar na API'));
  }
}

export default function* sagas() {
  yield all([takeLatest(Types.GET_REQUEST, getMaxInstallment)]);
}
