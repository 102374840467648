// @flow
import React from 'react';
import { Box, FormControl, Typography, Button } from '@material-ui/core';
import { Field, Formik, Form } from 'formik';
import {
  CustomRadioGroupItem,
  CustomRadioGroup,
} from 'components/form/CustomRadioGroup';
import * as Yup from 'yup';
import LockIcon from '@material-ui/icons/Lock';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import QntInput from '../QntInput';
import styles, { BoxColors } from './styles';
import { Creators as NotificationCreators } from 'store/ducks/app';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import useQuery from 'hooks/useQuery';
import { useHistoryPage } from 'hooks/history';
import { NONE } from 'apisauce';
const BuyForm = ({
  variations,
  product,
  onSubmit,
  handlePrice,
  quantity_whole,
  isWhole,
  setVariationSelected,
  variationSelected,
}: {
  variations: Product,
}) => {
  const history = useHistory();
  const query = useQuery();
  const { makeHistory } = useHistoryPage();

  const { isAuth } = useSelector(state => state.auth);

  const getColors = variations?.reduce((results, org) => {
    (results[org.color.id] = results[org.color.id] || []).push(org);
    return results;
  }, {});
  const colors = Object.keys(getColors).map(key => getColors[key]);
  console.log(colors);
  const getSizes = color =>
    variations.filter(element => element.color.id === Number(color));

  const handleChange = (value, setFieldValue, values) => {
    if (!isWhole) {
      setFieldValue('quantity', 1);
    }
    if (value.target.name === 'id_size') {
      handlePrice(
        variations.find(
          element =>
            element.size.id === Number(value.target.value) &&
            element.color.id === Number(values.id_color)
        ).price
      );
      setVariationSelected(
        variations.find(
          element =>
            element.size.id === Number(value.target.value) &&
            element.color.id === Number(values.id_color)
        )
      );
    }
    if (value.target.name === 'id_color') {
      setFieldValue('id_size', String(getSizes(value.target.value)[0].size.id));
      handlePrice(
        variations.find(
          element =>
            element.size.id ===
              Number(getSizes(value.target.value)[0].size.id) &&
            element.color.id === Number(value.target.value)
        ).price
      );
      setVariationSelected(
        variations.find(
          element =>
            element.size.id ===
              Number(getSizes(value.target.value)[0].size.id) &&
            element.color.id === Number(value.target.value)
        )
      );
    }
  };

  const schema = Yup.object().shape({
    color: Yup.number(),
  });

  const dispatch = useDispatch();

  const handleSubmitForm = async form => {
    const variationSelected: Variation = variations.find(
      element =>
        element.size.id === Number(form.id_size) &&
        element.color.id === Number(form.id_color)
    );

    if (form.quantity > variationSelected.stock) {
      dispatch(
        NotificationCreators.openNotification({
          message: 'Quantidade indisponível nesta variação',
          type: 'error',
        })
      );
    } else {
      await onSubmit(
        variationSelected,
        query.get('code_partner'),
        form.quantity
      );

      if (!isAuth) {
        makeHistory(
          JSON.stringify({
            pathname: '/carrinho',
            search: '?code_partner=' + query.get('code_partner'),
            back: true,
          })
        );
        history.push('/minha-conta');
      }
    }
  };

  // DISABLE BUTTON PURCHASE
  const {
    config: { shopping },
  } = useSelector(state => state.config);
  const [timeStart, setTimeStart] = React.useState(true);
  const [timeEnd, setTimeEnd] = React.useState(true);
  const [date, setDate] = React.useState({ start: '', end: '' });
  // verifica se é food ou good
  React.useEffect(() => {
    const verify = product.categories.find(a => a.name === 'Alimentação');
    if (verify) {
      const timestart = shopping.same_day_time_food_start;
      const timeend = shopping.same_day_time_food_end;
      var dataStart = moment().isAfter(moment(timestart, 'HH:mm'));
      var dataEnd = moment().isBefore(moment(timeend, 'HH:mm'));
      setTimeStart(dataStart);
      setTimeEnd(dataEnd);
      setDate({ start: timestart, end: timeend });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik
      validationSchema={schema}
      initialValues={{
        id_size: String(getSizes(colors[0][0].color.id)[0].size.id),
        id_color: null,
        quantity: 1,
      }}
      onSubmit={handleSubmitForm}
      validateOnBlur
      render={({ handleSubmit, values, setFieldValue }) => (
        <Form
          onSubmit={handleSubmit}
          onChange={e => {
            handleChange(e, setFieldValue, values);
          }}>
          <Box flex="1" display="flex" flexDirection="column">
            <Box flex="1" display="flex" flexDirection="row" flexWrap="wrap">
              <Box
                flex="1"
                display="flex"
                flexDirection="row"
                style={{ minWidth: 300 }}>
                <FormControl component="fieldset">
                  <Typography style={styles.smallTextPurple}>
                    Variações
                  </Typography>
                  <Field name="id_color" component={CustomRadioGroup} row>
                    {colors.map(item => (
                      <CustomRadioGroupItem
                        key={Math.random() * colors.length * 10}
                        label={
                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center">
                            {item[0].color.hexa !== '' && (
                              <BoxColors hexa={item[0].color.hexa} />
                            )}
                            <Typography>{item[0].color.name}</Typography>
                          </Box>
                        }
                        value={String(item[0].color.id)}
                      />
                    ))}
                  </Field>
                </FormControl>
              </Box>
              <Box
                flex="1"
                display="flex"
                flexDirection="row"
                style={{ minWidth: 300 }}>
                <FormControl component="fieldset">
                  {values.id_color ? (
                    <Typography style={styles.smallTextPurple}>
                      Tamanho
                    </Typography>
                  ) : (
                    NONE
                  )}

                  <Field name="id_size" component={CustomRadioGroup} row>
                    {getSizes(values.id_color).map(item => (
                      <CustomRadioGroupItem
                        key={
                          Math.random() * getSizes(values.id_color).length * 10
                        }
                        label={item.size.name}
                        value={String(item.size.id)}
                      />
                    ))}
                  </Field>
                </FormControl>
              </Box>
            </Box>
            <Box
              flex="1"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              style={{ marginTop: 20 }}>
              {values.id_size && values.id_color ? (
                <Field
                  name="quantity"
                  variationSelected={variationSelected}
                  component={QntInput}
                  quantity_whole={quantity_whole}
                  isWhole={isWhole}
                  data={product}
                  row
                />
              ) : (
                NONE
              )}

              {product && product.store && product.store.closed ? (
                <Button style={styles.dangerButton}>
                  <LockIcon style={{ marginRigth: '8px' }} /> LOJA FECHADA
                </Button>
              ) : (
                <>
                  {!timeStart ||
                    (!timeEnd && (
                      <p style={{ color: '#888', marginRight: 8 }}>
                        Loja aberta das{' '}
                        {moment(date.start, 'HH:mm').format('HH:mm')}h às{' '}
                        {moment(date.end, 'HH:mm').format('HH:mm')}h
                      </p>
                    ))}
                  {values.id_size && values.id_color ? (
                    <Button
                      data-cy="btn_purchase_product"
                      disabled={!timeStart || !timeEnd}
                      type="submit"
                      style={
                        !timeStart || !timeEnd
                          ? styles.disabledButton
                          : styles.purpleButton
                      }>
                      COMPRAR
                    </Button>
                  ) : (
                    NONE
                  )}
                </>
              )}
            </Box>
          </Box>
        </Form>
      )}
    />
  );
};

BuyForm.propTypes = {
  variations: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handlePrice: PropTypes.func.isRequired,
  quantity_whole: PropTypes.number.isRequired,
  isWhole: PropTypes.bool.isRequired,
};

export default BuyForm;
