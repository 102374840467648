import styled from 'styled-components';
import { Box } from '@material-ui/core';

const TabPanelContainer = styled(Box)`
  && {
    margin-top: -0;
    padding-left: 0px;
    flex: 1;
    min-width: 400px;
    @media (max-width: 1366px) {
      flex: 1;
    }
    @media (max-width: 900px) {
      flex: 1;
      margin-top: 40px;
      padding-left: 0;
    }
  }
`;

export default TabPanelContainer;
