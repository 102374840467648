import { useDispatch } from 'react-redux';
import api from 'services/api';
import { Creators as NotificationCreators } from 'store/ducks/app';

export const useBankEdit = () => {
  const dispatch = useDispatch();

  const updateBankAccount = async data => {
    try {
      console.log(data);
      const response = await api.put(`/v1/client/bank`, data);
      if (response.status !== 200) throw response;
      dispatch(
        NotificationCreators.openNotification({
          message: 'Atualizado com sucesso',
          type: 'success',
        })
      );
      window.location.reload();
    } catch (error) {
      dispatch(
        NotificationCreators.openNotification({
          message: 'Erro ao atualizar',
          type: 'error',
        })
      );
    }
  };

  return { updateBankAccount };
};
