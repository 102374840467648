/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-key */
import React from 'react';
import {
  Box,
  Typography,
  Slider,
  Collapse,
  ListItem,
  ListItemText,
  List,
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { CustomCheckboxGroup } from 'components/form/CustomCheckboxGroup';
import { CustomButtonChecked } from 'components/form/CustomButtonChecked';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Creators as ProductCreators } from 'store/ducks/product';
import styles, { BoxFilters, FilterColorButton } from './styles';
import ColapseFilter from '../ColapseFilter';
import { CustomCheckboxGroupItem } from './CustomCheckboxGroup';
import { useSelector } from 'react-redux';
import appUtils from 'utils/appUtils';
import appColors from 'utils/appColors';
import {
  FilterCheckboxGroup,
  FilterCheckboxGroupItem,
} from './FilterRadioGroup';
import { useDispatch } from 'react-redux';
import { ArrowForwardIos } from '@material-ui/icons';

const schema = Yup.object().shape({
  price: Yup.array().of(Yup.number(), Yup.number()),
  size: Yup.array().of(Yup.number()),
  color: Yup.array().of(Yup.number()),
  order: Yup.string(),
});

const MainFiltersCategories = ({ onSubmit, data, initialValues }) => {
  const [sliderValue, setSliderValue] = React.useState([0, 200000]);
  const { category } = useSelector(state => state.category.category);

  const dispatch = useDispatch();
  const [selected, setSelected] = React.useState('');
  const [filters] = React.useState({
    page: 1,
    per_page: 12,
    search: '',
    orderByColumn: '',
    orderByDirection: '',
    price_min: 0,
    price_max: 5000,
    size: [],
    color: [],
    order: '1',
    store: '',
  });

  const getSubCategory = child => {
    dispatch(ProductCreators.getProductsByCategoryRequest(filters, child));
    setSelected(child);
  };

  function ListItemLink(props) {
    const { text, child, children } = props;
    return (
      <li>
        <ListItem
          style={{
            paddingLeft: children ? 25 : 10,
          }}
          button
          onClick={() => {
            getSubCategory(child);
          }}>
          {children ? (
            <ArrowForwardIos
              style={{ fontSize: 12, marginRight: 10, color: '#999' }}
            />
          ) : (
            false
          )}
          <ListItemText
            style={{
              color: selected === child ? appColors.SECUNDARY_COLOR : '#555',
              fontWeight: selected ? 'bold' : 'normal',
            }}>
            {text}
          </ListItemText>
        </ListItem>
      </li>
    );
  }

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur
      render={({ handleSubmit, setFieldValue, values }) => (
        <Form
          onChange={() => {
            onSubmit(values);
          }}>
          <BoxFilters flex="1" display="flex" flexDirection="column">
            <Typography style={styles.smallTextPurple}>
              Faixa de Preço
            </Typography>
            <Box
              flex="1"
              display="flex"
              flexDirection="column"
              style={styles.columnsFilter}>
              <Box
                flex="1"
                display="flex"
                flexDirection="row"
                justifyContent="space-between">
                <Typography style={styles.priceSlider}>
                  {appUtils.formatPrice(sliderValue[0])}
                </Typography>
                <Typography style={styles.priceSlider}>
                  {appUtils.formatPrice(sliderValue[1])}
                </Typography>
              </Box>
              <Slider
                value={sliderValue}
                onChange={(e, v) => {
                  setSliderValue([v[0], v[1]]);
                }}
                onChangeCommitted={(e, v) => {
                  setFieldValue('price_min', v[0]);
                  setFieldValue('price_max', v[1]);
                  setTimeout(handleSubmit, 0);
                }}
                aria-labelledby="range-slider"
                valueLabelDisplay="off"
                step={1}
                max={800}
                min={0}
              />
            </Box>

            <ColapseFilter title="Subcategorias">
              <Box
                display="flex"
                flex="1"
                flexDirection="column"
                style={{ overflow: 'auto', maxHeight: 200 }}>
                {category.category_child &&
                  category.category_child.map((item, index) => (
                    <List disablePadding index={index}>
                      <ListItemLink text={item.name} child={item.slug} />
                      <List disablePadding>
                        {item.category_child.map((item, i) => (
                          <ListItemLink
                            key={i}
                            children={true}
                            child={item.slug}
                            text={item.name}
                          />
                        ))}
                      </List>
                    </List>
                  ))}
              </Box>
            </ColapseFilter>

            {data.sizes.length > 0 && (
              <ColapseFilter title="Tamanho">
                <Box flex="1" style={{ overflow: 'auto', maxHeight: 200 }}>
                  <Field name="size" row={true} component={CustomCheckboxGroup}>
                    <Field
                      component={CustomButtonChecked}
                      isChecked={values.size.length === data.sizes.length}
                      label="Todos"
                      onClick={() => {
                        setFieldValue(
                          'size',
                          values.size.length === data.sizes.length
                            ? []
                            : [...data.sizes.map(item => item.id)]
                        );
                      }}
                    />
                    <Typography style={styles.fontButton}></Typography>
                    {data.sizes.map(item => (
                      <Field
                        key={item.id}
                        component={CustomButtonChecked}
                        label={item.name}
                        value={item.id}
                      />
                    ))}
                  </Field>
                </Box>
              </ColapseFilter>
            )}
            {data.colors.length > 0 && (
              <ColapseFilter title="Variações" open={true}>
                <Box flex="1" style={{ overflow: 'auto', maxHeight: 200 }}>
                  <Field name="color" component={CustomCheckboxGroup}>
                    <Field
                      component={CustomCheckboxGroupItem}
                      isChecked={values.color.length === data.colors.length}
                      label="Todos"
                      onClick={() => {
                        setFieldValue(
                          'color',
                          values.color.length === data.colors.length
                            ? []
                            : [...data.colors.map(item => item.id)]
                        );
                      }}
                    />
                    {data.colors.map(item => (
                      <Field
                        key={item.id}
                        component={CustomCheckboxGroupItem}
                        item={item}
                        label={item.name}
                        value={item.id}
                      />
                    ))}
                  </Field>
                </Box>
              </ColapseFilter>
            )}
            <ColapseFilter title="Ordenação" nomargin={true}>
              <Field name="order" component={FilterCheckboxGroup} row={false}>
                <Field
                  component={FilterCheckboxGroupItem}
                  label="Por mais recente"
                  value="1"
                />
                <Field
                  component={FilterCheckboxGroupItem}
                  label="Por preço: menor para maior"
                  value="2"
                />
                <Field
                  component={FilterCheckboxGroupItem}
                  label="Por preço: maior para menor"
                  value="3"
                />
              </Field>
            </ColapseFilter>
          </BoxFilters>
        </Form>
      )}
    />
  );
};

const FiltersCategories = ({
  onSubmit,
  data,
  father,
  section,
  initialValues,
}) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [isOpen, setIsOpen] = React.useState(false);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };
  const handleIsOpen = () => {
    setIsOpen(!isOpen);
  };
  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {width > 500 ? (
        <MainFiltersCategories
          section={section}
          initialValues={initialValues}
          father={father}
          onSubmit={onSubmit}
          data={data}
        />
      ) : (
        <>
          <FilterColorButton onClick={handleIsOpen}>
            <Typography style={styles.fontFilterButton}>Filtros</Typography>
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </FilterColorButton>
          <Collapse
            in={isOpen}
            timeout="auto"
            unmountOnExit
            style={{
              flex: 1,
              minWidth: 290,
              display: 'flex',
              flexDirection: 'column',
              margin: '0px 10px',
            }}>
            <MainFiltersCategories
              section={section}
              initialValues={initialValues}
              onSubmit={onSubmit}
              father={father}
              data={data}
            />
          </Collapse>
        </>
      )}
    </>
  );
};

FiltersCategories.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FiltersCategories;
