/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as TrendCreators } from 'store/ducks/trend';
import { Creators as TrendProductCreators } from 'store/ducks/trend/product';
import TrendProducts from 'components/Pages/TrendDetails/TrendProducts';
import { TrendsContainer } from './styles';

const TrendDetailsPage = ({ match }) => {
  const dispatch = useDispatch();

  const { trend } = useSelector(state => state.trend.trend);
  const { trendProduct } = useSelector(state => state.trend.product);

  React.useEffect(() => {
    dispatch(TrendCreators.getTrendRequest(match.params.slug));
  }, []);

  React.useEffect(() => {
    if (trend.id) {
      dispatch(TrendProductCreators.getTrendProductRequest(trend.id));
    }
  }, [trend]);

  return (
    <>
      <TrendsContainer flex="1" flexDirection="column" display="flex">
        {Object.keys(trend).length > 0 && trendProduct.length > 0 && (
          <>
            <TrendProducts
              data={trendProduct}
              trendRoute={trend.route_web}
              title={trend.name}
            />
          </>
        )}
      </TrendsContainer>
    </>
  );
};

export default TrendDetailsPage;
