import React from 'react';
import SearchInput from 'components/HeaderComponent/SearchInput';
import HeaderButton from 'components/HeaderComponent/HeaderButton';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import { Link } from 'react-router-dom';
import HeaderBoxContainer from 'components/HeaderComponent/HeaderBoxContainer';
import HeaderBoxItem from 'components/HeaderComponent/HeaderBoxItem';

const CollaboratorDeletedTableHeader = ({ getFunction, initialValues }) => {
  const onSubmit = values => {
    getFunction(values);
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={() => (
        <Form>
          <HeaderBoxContainer>
            <HeaderBoxItem style={{ paddingTop: 12, paddingRight: 6 }}>
              Busca
            </HeaderBoxItem>
            <HeaderBoxItem style={{ paddingLeft: 6, paddingRight: 6 }}>
              <Field
                component={SearchInput}
                name="search"
                placeholder="Informe uma palavra-chave"
              />
            </HeaderBoxItem>
            <HeaderBoxItem style={{ paddingLeft: 6 }}>
              <Link to="/minha-conta?aba=ClientesNew">
                <HeaderButton icon="add">Adicionar</HeaderButton>
              </Link>
            </HeaderBoxItem>
            <HeaderBoxItem justify="center" style={{ paddingLeft: 15 }}>
              <Link to="/minha-conta?aba=Clientes">
                <HeaderButton icon="undo">Voltar</HeaderButton>
              </Link>
            </HeaderBoxItem>
            {/*
            <HeaderBoxItem style={{ paddingLeft: 6 }}>
              <HeaderButton icon="save_alt">Exportar</HeaderButton>
            </HeaderBoxItem>
            */}
          </HeaderBoxContainer>
        </Form>
      )}
    />
  );
};

CollaboratorDeletedTableHeader.propTypes = {
  getFunction: PropTypes.func.isRequired,
  initialValues: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default CollaboratorDeletedTableHeader;
