/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { InputContainer, InputItem } from 'components/form/StyledComponents';
import CustomTextField from 'components/form/CustomTextField';
import * as Yup from 'yup';
import CustomSelect from 'components/form/CustomSelect';
import CustomInputDate from 'components/form/CustomInputDate';
import styles from './styles';
import moment from 'moment';
import CustomCurrencyField from 'components/form/components/CustomCurrencyField';
import CustomPercentField from 'components/form/components/CustomPercentField';
import { useSelector } from 'react-redux';
import { useInvoice } from 'hooks/invoice';

const BillingForm = ({ onSubmit, sendInvoiceLoading }) => {
  const schema = Yup.object().shape({
    client_email: Yup.string().required('Este campo é obrigatório'),
    due_date: Yup.mixed()
      .test('valid-date', 'Por Favor, insira uma data válida', val =>
        moment(val, 'YYYY-MM-DD').isValid()
      )
      .test('valid-length', 'Por Favor, insira uma data válida', val =>
        val ? val?.replace(/[/_-]/g, '')?.length === 8 : false
      )
      .test(
        'is-of-year',
        'É necessário ser maior que o ano atual',
        val => moment(val, 'YYYY-MM-DD').year() >= moment().year()
      )
      .test(
        'is-of-month',
        'É necessário ser maior que o mês atual',
        val => moment(val, 'YYYY-MM-DD').month() >= moment().month()
      ),
    installment_count: Yup.string().required('Escolha Uma parcela'),
    total_value: Yup.string().required('Campo Obrigatório'),
    value_percentage: Yup.string(),
    value_fixed: Yup.string().test(
      'valid_value_fixed',
      'O valor fixo do split precisa ser menor que o valor total da cobrança',
      function (value) {
        return this.parent.total_value
          ? Number(this.parent.total_value) > Number(value ?? 0)
          : !!value;
      }
    ),
    description: Yup.string(),
  });

  const [radioRate, setRadioRate] = React.useState(null);
  const [radio, setRadio] = React.useState(null);
  const {
    config: { config },
  } = useSelector(state => state);

  const { GetInvoiceRate, invoiceRate } = useInvoice();

  React.useEffect(() => {
    GetInvoiceRate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [initialValues, setInitialValues] = React.useState({
    value_percentage: 0,
    value_fixed: 0,
  });

  React.useEffect(() => {
    if (invoiceRate) {
      setInitialValues({
        value_percentage:
          radioRate === 'new' && radio !== 'shopping'
            ? 0
            : radioRate === 'new' && radio === 'shopping'
            ? config.shopping?.shopping_global?.rate_shopping
            : typeof invoiceRate?.value_percentage !== 'undefined'
            ? invoiceRate?.value_percentage
            : 0,
        value_fixed:
          typeof invoiceRate?.value_fixed !== 'undefined'
            ? invoiceRate?.value_fixed
            : 0,
      });
    }
  }, [
    invoiceRate,
    radioRate,
    radio,
    config,
    invoiceRate?.value_fixed,
    invoiceRate?.value_percentage,
  ]);
  console.log(
    radioRate === 'new' && radio !== 'shopping'
      ? 0
      : radioRate === 'new' && radio === 'shopping'
      ? config.shopping?.shopping_global?.rate_shopping
      : invoiceRate?.value_percentage
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      render={({ handleSubmit, setFieldValue, errors }) => (
        <Box flex="1" display="flex" flexDirection="column">
          {console.log(errors, 'errors')}
          <form autoComplete="off" onSubmit={handleSubmit}>
            <InputContainer style={{ marginBottom: 35 }}>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Email
                  <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  autoComplete="off"
                  id="client_email"
                  name="client_email"
                  label="Informe de quem será cobrado Ex: nomedaloja@email.com"
                  component={CustomTextField}
                />
              </InputItem>
            </InputContainer>
            <RadioGroup
              style={{ display: 'flex', flexDirection: 'row' }}
              name="radioRate"
              value={radioRate}
              onChange={e => {
                setRadioRate(e.target.value);
                if (radioRate === 'new') {
                  setRadio('shopping');
                  setFieldValue(
                    'value_percentage',
                    config?.shopping?.shopping_global?.rate_shopping
                  );
                }
                if (e.target.value === 'shopping') {
                  return setFieldValue(
                    'value_percentage',
                    config?.shopping?.shopping_global?.rate_shopping
                  );
                }
                if (e.target.value === 'default') {
                  return setFieldValue(
                    'value_percentage',
                    invoiceRate?.value_percentage !== null &&
                      invoiceRate?.value_percentage
                  );
                }
              }}>
              <FormControlLabel
                value={'default'}
                control={<Radio color="primary" />}
                label="Manter split Atual"
              />
              <FormControlLabel
                value={'new'}
                control={<Radio color="primary" />}
                label="Solicitar novo split"
              />
              {radioRate === null && (
                <div style={{ color: 'red', marginTop: 10 }}>
                  Escolha uma opção de split
                </div>
              )}
            </RadioGroup>

            {radioRate === 'default' && (
              <InputContainer style={{ marginBottom: 25 }}>
                <InputItem>
                  <Typography style={styles.titleInput}>
                    {invoiceRate?.value_fixed !== null
                      ? 'Valor fixo do split'
                      : 'Porcentagem do split'}
                    <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <Field
                    name={
                      invoiceRate?.value_fixed !== null
                        ? 'value_fixed'
                        : 'value_percentage'
                    }
                    isDisabled={invoiceRate?.value_fixed === null}
                    id={
                      invoiceRate?.value_fixed !== null
                        ? 'value_fixed'
                        : 'value_percentage'
                    }
                    placeholder={
                      invoiceRate?.value_fixed !== null
                        ? 'Informe o valor fixo do split'
                        : 'Informe a porcentagem do split'
                    }
                    component={
                      invoiceRate?.value_fixed !== null
                        ? CustomCurrencyField
                        : CustomPercentField
                    }
                  />
                </InputItem>
              </InputContainer>
            )}
            {radioRate === 'new' && (
              <RadioGroup
                style={{ display: 'flex', flexDirection: 'row' }}
                name="radio"
                value={radio}
                onChange={e => {
                  setRadio(e.target.value);

                  if (e.target.value === 'shopping') {
                    return setFieldValue(
                      'value_percentage',
                      config?.shopping?.shopping_global?.rate_shopping
                    );
                  }
                  if (e.target.value === 'percent') {
                    return setFieldValue('value_fixed', 0);
                  }
                  if (e.target.value === 'price') {
                    return setFieldValue('value_percentage', 0);
                  }
                }}>
                <FormControlLabel
                  value="shopping"
                  control={<Radio color="primary" />}
                  label="Usar split Marketplace"
                />
                <FormControlLabel
                  value="percent"
                  control={<Radio color="primary" />}
                  label="Usar novo split percentual"
                />
                <FormControlLabel
                  value="price"
                  control={<Radio color="primary" />}
                  label="Usar novo split valor fixo"
                />
                {radio === null && (
                  <div style={{ color: 'red', marginTop: 10 }}>
                    Escolha uma opção de split
                  </div>
                )}
              </RadioGroup>
            )}
            {radioRate === 'new' && radio === 'shopping' && (
              <InputContainer style={{ marginBottom: 25 }}>
                <InputItem>
                  <Typography style={styles.titleInput}>
                    Porcentagem do split
                    <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <Field
                    isDisabled={true}
                    name="value_percentage"
                    id="value_percentage"
                    label="Informe a porcentagem do split"
                    component={props => (
                      <CustomPercentField
                        {...props}
                        defaultValue={
                          config?.shopping?.shopping_global?.rate_shopping
                        } // Passa o valor padrão aqui
                      />
                    )}
                  />
                </InputItem>
              </InputContainer>
            )}
            {radio === 'percent' && (
              <InputContainer style={{ marginBottom: 25 }}>
                <InputItem>
                  <Typography style={styles.titleInput}>
                    Porcentagem do split
                    <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <Field
                    name="value_percentage"
                    id="value_percentage"
                    label="Informe a porcentagem do split"
                    component={CustomPercentField}
                  />
                </InputItem>
              </InputContainer>
            )}
            {radio === 'price' && (
              <InputContainer style={{ marginBottom: 25 }}>
                <InputItem>
                  <Typography style={styles.titleInput}>
                    Valor fixo do split
                    <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <Field
                    name="value_fixed"
                    id="value_fixed"
                    placeholder="Informe o valor fixo do split"
                    component={CustomCurrencyField}
                  />
                </InputItem>
              </InputContainer>
            )}
            <InputContainer style={{ marginBottom: 25 }}>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Valor Total
                  <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="total_value"
                  id="total_value"
                  label="Informe o valor total"
                  component={CustomCurrencyField}
                />
              </InputItem>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Quantidade de Parcelas
                  <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="installment_count"
                  options={[
                    { id: 1, name: '1 Parcela' },
                    { id: 2, name: '2 Parcelas' },
                    { id: 3, name: '3 Parcelas' },
                    { id: 4, name: '4 Parcelas' },
                    { id: 5, name: '5 Parcelas' },
                    { id: 6, name: '6 Parcelas' },
                    { id: 7, name: '7 Parcelas' },
                    { id: 8, name: '8 Parcelas' },
                    { id: 9, name: '9 Parcelas' },
                    { id: 10, name: '10 Parcelas' },
                    { id: 11, name: '11 Parcelas' },
                    { id: 12, name: '12 Parcelas' },
                  ]}
                  placeholder="Informe a quantidade de parcelas"
                  isLoading={false}
                  component={CustomSelect}
                />
              </InputItem>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Data de Vencimento
                  <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="due_date"
                  label="Informe a data de vencimento"
                  component={CustomInputDate}
                />
              </InputItem>
            </InputContainer>
            <InputContainer style={{ marginBottom: 35 }}>
              <InputItem>
                <Typography style={styles.titleInput}>Descrição</Typography>
                <Field
                  name="description"
                  label="Informe a descrição da cobrança"
                  component={CustomTextField}
                />
              </InputItem>
            </InputContainer>
            <Button
              type="submit"
              disabled={radioRate === null}
              data-cy="btn_save_profile"
              style={styles.purpleButtonProceed}>
              Enviar Cobranças
              {sendInvoiceLoading && (
                <CircularProgress
                  size={15}
                  style={{ color: 'white', marginLeft: 10 }}
                />
              )}
            </Button>
          </form>
        </Box>
      )}
    />
  );
};

BillingForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default BillingForm;
