import styled, {css} from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 60vh;
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content:center;

    ${props => props.image && css`
        background-image: url(${props.image});
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    `}
`

export const Content = styled.div`
    width:100%;
    max-width: 1100px;
`

export const Buttons = styled.div`
    display: flex;
    margin-top: 50px;

    svg {
        margin-right: 10px;
    }
`

export const Titles = styled.div`
   height: 300px;
   padding: 0px 30px;
   max-width: 530px;

   h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;  
    color: #505255;
   }

   p {       
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
   }

    button {
        margin-right: 30px;
    }

    @media (max-width: 500px){       
        max-width: 260px;

        h1 {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 30px;  
            color: #505255;
        }

        .MuiButton-root {
            font-size: 0.575rem;
        }

        button {
            margin-right: 10px;
        }
   }
`
