import React from 'react';
import { Box, Button } from '@material-ui/core';
import OkImage from 'images/logos/order-ok.png';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles, {
  CartContainer,
  BigTextBlack,
  BigTitlePurple,
  BoxContainerText,
  BoxContainer,
  BoxContainerOkImg,
} from './styles';

const ConfimationEmailRegister = () => {
  const { image } = useSelector(state => state.config.config.shopping);

  return (
    <CartContainer flex="1" flexDirection="row" flexWrap="wrap">
      <BoxContainer flex="1" display="flex" flexDirection="row" flexWrap="wrap">
        <BoxContainerOkImg display="flex" alignItems="center">
          <img alt="" src={OkImage} />
        </BoxContainerOkImg>
        <BoxContainerText
          flex="1"
          display="flex"
          justifyContent="center"
          flexDirection="column">
          <Box>
            <BigTextBlack>
              CADASTRO REALIZADO COM SUCESSO!
              <br />
              verifique sua caixa de e-mail e spam para confirmar sua conta.
            </BigTextBlack>
          </Box>
          <Box style={{ marginTop: 30 }}>
            <BigTitlePurple>AGRADECEMOS A PREFERÊNCIA!</BigTitlePurple>
          </Box>
        </BoxContainerText>
        <Box
          flex="1"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center">
          <img alt="" src={image.small} width={200} />
          <Box
            flex="1"
            display="flex"
            alignItems="center"
            justifyContent="center">
            <Link
              to="/minha-conta"
              style={{ textDecoration: 'none', color: 'inherit' }}>
              <Button id="minhaContaFinalizarCadastro"  style={styles.purpleButton}>Minha conta</Button>
            </Link>
          </Box>
        </Box>
      </BoxContainer>
    </CartContainer>
  );
};

export default ConfimationEmailRegister;
