/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from 'react';
import { Formik, Field, Form, FastField } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Card, TextField, Typography } from '@material-ui/core';
import { InputContainer, InputItem } from 'components/form/StyledComponents';
import CustomTextField from 'components/form/components/CustomTextField';
import CustomImageField from 'components/form/components/CustomImageField';
import CustomSelect from 'components/form/components/CustomSelect';
import FormButtons from 'components/form/components/FormButtons';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as CategoryCreators } from 'store/ducks/admin/category';
import { Creators as ProductCreators } from 'store/ducks/admin/product';
import VirtualCatalogPreview from 'components/Pages/VirtualCatalogPage/VirtualCatalogForm/VirtualCatalogPreview';
import ProductSelect from 'components/form/components/CustomSearchSelect/ProductSelect';
import CustomMaskField from 'components/form/CustomMaskField';
import useCatalog from 'hooks/useCatalogs';
import ActionFab from 'components/Actions/ActionFab';

const TabContainer = ({ children }) => {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
};

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export const formInitialValues = {
  id: '',
  name: '',
  expiration_date: '',
  categories: [],
  products: [],
  store: {},
  image: '',
  image_data: '',
  image_info: '',
};

const schema = Yup.object().shape({
  id: Yup.number(),
  name: Yup.string().required('Este campo é obrigatório'),
  expiration_date: Yup.mixed().required('Este campo é obrigatório'),
  store: Yup.object().shape({
    id: Yup.number().required('Este campo é obrigatório'),
  }),
  categories: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number(),
      })
    )
    .required('Este campo é obrigatório'),
  products: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number(),
      })
    )
    .required('Este campo é obrigatório'),
});

const VirtualCatalogForm = ({
  onSubmit,
  initialValues = formInitialValues,
  submitText,
  handleBack,
  isLoading,
  isUpdate,
}) => {
  const dispatch = useDispatch();
  const { categoryList, categoryListLoading } = useSelector(
    state => state.admin.category
  );

  const [categoryListAdd, setCategoryList] = useState(categoryList);

  const { productList, productListLoading } = useSelector(
    state => state.admin.product
  );

  const { client } = useSelector(state => state.client);

  const { getProductByCategory } = useCatalog();

  const getInitialData = () => {
    dispatch(CategoryCreators.getCategoryListRequest({ perPage: 1000 }));
    dispatch(ProductCreators.getProductListRequest({ perPage: 50 }));
    initialValues.store.id = client?.store?.id;
  };

  const searchProduct = searchInfo => {
    dispatch(
      ProductCreators.getProductListRequest({ perPage: 50, ...searchInfo })
    );
  };

  const formRef = useRef(null);
  const [load, setLoad] = useState(false);
  const [quantity, setQuantity] = useState(3);

  const handleAddProducts = async data => {
    if (typeof data === 'object') {
      const array = [];
      setLoad(true);
      await Promise.all(
        data.map(async item => {
          const res = await getProductByCategory(item.id, quantity);
          array.push(...res);
        })
      );

      if (formRef.current) {
        formRef.current.setFieldValue('products', array);
        setLoad(false);
      }
    }
  };

  React.useEffect(() => {
    getInitialData();
  }, []);

  return (
    <Formik
      ref={formRef}
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      validateOnBlur
      enableReinitialize
      render={({ values }) => (
        <Form>
          <Card style={{ padding: 20 }}>
            <InputContainer>
              <InputItem>
                <Field name="name" label="Nome" component={CustomTextField} />
              </InputItem>
              <InputItem>
                <Field
                  name="expiration_date"
                  label="Informe data de validade do catálogo"
                  mask="99/99/9999"
                  component={CustomMaskField}
                />
              </InputItem>
            </InputContainer>
            <InputContainer>
              <InputItem>
                <FastField
                  data-cy="btn_button_cap"
                  name="image"
                  label={
                    values.image_info && values.image_info.small
                      ? 'Atualizar imagem de capa'
                      : 'Adicionar Imagem de Capa'
                  }
                  aspect={1 / 1.8}
                  type="cover"
                  component={CustomImageField}
                  previewUrl={
                    values.image_info && values.image_info.small
                      ? values.image_info.small
                      : ''
                  }
                />
              </InputItem>
            </InputContainer>
            <InputContainer>
              <InputItem>
                <TextField
                  style={{ width: '50%', marginTop: 25 }}
                  onChange={e => setQuantity(Number(e.target.value))}
                  value={quantity}
                  label="Quantidade de produtos por categoria"
                  variant="outlined"
                  size="small"
                  data-cy="qtd_product_category"
                />
                {(typeof quantity !== 'number' || quantity > 20) && (
                  <p style={{ color: 'red', fontSize: 12 }}>
                    Precisa ser um número de 1 a 20
                  </p>
                )}
              </InputItem>
            </InputContainer>
            <InputContainer style={{ marginTop: 30 }}>
              <InputItem style={{ width: '50%' }}>
                <Field
                  name="categories"
                  label="Categorias"
                  options={categoryList}
                  component={CustomSelect}
                  placeholder="Categorias"
                  onChangeField={handleAddProducts}
                  isMulti
                  optionLimit={5}
                  isLoading={categoryListLoading}
                />
              </InputItem>
              <InputItem style={{ width: 150 }}>
                {!!isUpdate && (
                  <div
                    style={{
                      padding: '0 10px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <ActionFab
                      icon="refresh"
                      onClick={() => handleAddProducts(values.categories)}
                      isLoading={load}
                    />
                    <p
                      style={{
                        fontSize: 12,
                        color: '#777',
                        flex: 1,
                        marginLeft: 10,
                      }}>
                      Atualizar lista de produtos com base nas categorias
                    </p>
                  </div>
                )}
              </InputItem>
            </InputContainer>

            <InputContainer>
              <InputItem style={{ width: '50%' }}>
                <Field
                  name="products"
                  label="Produtos"
                  options={categoryListAdd.filter(item => item.status === 1)}
                  component={ProductSelect}
                  placeholder="Produtos"
                  isMulti
                  search={searchProduct}
                  isLoading={productListLoading}
                />
              </InputItem>
            </InputContainer>
            <InputContainer>
              <InputItem>
                <Field
                  name="products_preview"
                  component={VirtualCatalogPreview}
                  setCategoryList={setCategoryList}
                  items={productList}
                  isLoading={productListLoading}
                />
              </InputItem>
            </InputContainer>
            <FormButtons
              isLoading={isLoading}
              handleBack={handleBack}
              submitText={submitText}
            />
          </Card>
        </Form>
      )}
    />
  );
};

VirtualCatalogForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.oneOfType([PropTypes.object]),
  submitText: PropTypes.string,
  handleBack: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isLoading: PropTypes.bool.isRequired,
};

VirtualCatalogForm.defaultProps = {
  initialValues: formInitialValues,
  submitText: 'Salvar',
  handleBack: false,
  onSubmit: () => {},
};

export default VirtualCatalogForm;
