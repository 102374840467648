/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Box, Button, Typography, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import { InputContainer, InputItem } from 'components/form/StyledComponents';
import CustomTextField from 'components/form/CustomTextField';
import * as Yup from 'yup';
import validators from 'utils/validators';
import CustomMaskField from 'components/form/CustomMaskField';
import appColors from 'utils/appColors';
import styles from './styles';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import CepMaskField from './CepMaskField';
import { Creators as CepCreators } from 'store/ducks/address';
import CustomSelect from 'components/form/CustomSelect';

const RegisterManagerForm = ({
  onSubmit,
  initialValues,
  onBack,
  onSubmitCEP,
  isNext,
  isLoading,
}) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(CepCreators.getClearCepRequest());
    window.scrollTo(0, 0);
  }, []);

  const validateCEP = value => {
    let error;
    if (value.replace(/[()_-]/g, '').replace(/ /g, '').length < 8) {
      error = 'CEP inválido';
    }
    return error;
  };

  const { cep, cepError, cepLoading } = useSelector(state => state.address);

  const formikRef = React.useRef(null);
  const setFieldValue = (name, value) => {
    formikRef.current.setFieldValue(name, value);
  };

  React.useEffect(() => {
    if (Object.keys(cep).length > 0) {
      setFieldValue('address.street', cep.street);
      setFieldValue('address.number', cep.number);
      setFieldValue('address.district', cep.district);
      setFieldValue('address.complement', cep.complement);
      setFieldValue('address.city.name', cep.city.name);
      setFieldValue('address.city.id', cep.city.id);
      setFieldValue('address.city.state.id', cep.city.state.id);
      setFieldValue('address.city.state.name', cep.city.state.name);
      dispatch(CepCreators.getClearCepRequest());
    }
  }, [cep]);

  const schema = Yup.object().shape({
    name: Yup.string()
      .test(
        'match',
        'Por favor, insira nome e sobrenome com as iniciais maiúsculas',
        val => {
          let error;
          if (
            !!val &&
            val !==
              val.replace(
                /[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]* [A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]*/
              )
          ) {
            error =
              'Por favor, insira nome e sobrenome com as iniciais maiúsculas';
          }
          return error;
        }
      )
      .required('Este campo é obrigatório'),
    birth_date: Yup.mixed()
      .test('valid-date', 'Por Favor, insira uma data válida', val =>
        moment(val, 'DD/MM/YYYY').isValid()
      )
      .test('valid-length', 'Por Favor, insira uma data válida', val => {
        return val ? val.replace(/[/_-]/g, '').length === 8 : false;
      })
      .test('is-of-age', 'É necessário ser maior de 18 anos', val => {
        return moment().diff(moment(val, 'DD/MM/YYYY'), 'year') >= 18;
      }),
    genre: Yup.string().required('Este campo é obrigatório'),
    cell_phone: Yup.string().required('Este campo é obrigatório'),
    cpf: Yup.string()
      .test(...validators.cpfInvalid('CPF inválido'))
      .required('Este campo é obrigatório'),
    email: Yup.string()
      .email('Este email não é valido')
      .required('Este campo é obrigatório'),
    password: Yup.string()
      .required('Este campo é obrigatório')
      .min(6, 'A senha deve ter no mínimo 6 dígitos'),
    confirmPassword: Yup.string().test(
      'passwords-match',
      'Senhas não correspondem',
      function (value) {
        return this.parent.password === value;
      }
    ),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      ref={formikRef}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
      render={({ handleSubmit, values }) => (
        <Box flex="1" display="flex" flexDirection="column">
          <Form onSubmit={handleSubmit}>
            <InputContainer>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Nome Completo <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="name"
                  id="nomeResponsavel"
                  label="Informe o nome do responsável"
                  component={CustomTextField}
                />
              </InputItem>
            </InputContainer>
            <InputContainer>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Gênero <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  id="genre"
                  name="genre"
                  options={[
                    { id: 'M', name: 'Masculino' },
                    { id: 'F', name: 'Feminino' },
                    { id: 'O', name: 'Outro' },
                  ]}
                  placeholder="Informe o gênero"
                  component={CustomSelect}
                  isLoading={false}
                />
              </InputItem>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Data de Nascimento <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="birth_date"
                  label="Informe sua data de nascimento"
                  mask="99/99/9999"
                  component={CustomMaskField}
                />
              </InputItem>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Telefone <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="cell_phone"
                  label="Informe o telefone da empresa"
                  mask={
                    values.cell_phone?.[5] === '9'
                      ? '(99) 9 9999-9999'
                      : '(99) 9999-9999'
                  }
                  component={CustomMaskField}
                />
              </InputItem>
            </InputContainer>
            <InputContainer>
              <InputItem>
                <Typography style={styles.titleInput}>
                  CPF <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="cpf"
                  label="Informe seu CPF"
                  mask="999.999.999-99"
                  component={CustomMaskField}
                />
              </InputItem>
            </InputContainer>
            <InputContainer style={{ marginTop: 35 }}>
              <Typography style={styles.purpleTitle}>
                Dados de Acesso a Plataforma
              </Typography>
            </InputContainer>

            <InputContainer>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Email do Responsável <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="email"
                  id="emailResponsavel"
                  label="Informe seu email. Ex: nomedaloja@email.com"
                  component={CustomTextField}
                />
              </InputItem>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Senha <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  autoComplete="new-password"
                  name="password"
                  type="password"
                  id="senha"
                  label="Informe a sua senha"
                  component={CustomTextField}
                />
              </InputItem>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Confirmação de senha <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="confirmPassword"
                  type="password"
                  id="confirmarSenha"
                  label="Confirme a sua senha"
                  component={CustomTextField}
                />
              </InputItem>
            </InputContainer>

            <Box
              style={{
                borderBottom: `1px solid ${appColors.PRIMARY_COLOR}`,
                marginTop: 60,
                marginBottom: 40,
              }}
            />
            <Typography style={styles.purpleTitle}>
              Endereço do Responsável
            </Typography>
            {cepError && (
              <Box
                style={{
                  paddingTop: 5,
                  marginLeft: 10,
                  marginTop: 10,
                  color: 'red',
                }}>
                <Typography>{cepError}</Typography>
              </Box>
            )}
            <InputContainer>
              <InputItem>
                <Typography style={styles.titleInput}>
                  CEP <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="address.code_post"
                  label="Informe o CEP da loja"
                  id="inputEnderecoCEP"
                  mask="99999-999"
                  validate={validateCEP}
                  onSubmitCEP={onSubmitCEP}
                  component={CepMaskField}
                />
              </InputItem>
            </InputContainer>
            {((Object.keys(cep).length > 0 && !cepLoading) ||
              values.address.city.id !== '') && (
              <Box>
                <InputContainer>
                  <InputItem style={{ flexGrow: 10 }}>
                    <Typography>Rua/Avenida</Typography>
                    <Field
                      name="address.street"
                      id="ruaResponsavel"
                      label="Infome o nome da rua ou avenida"
                      component={CustomTextField}
                    />
                  </InputItem>
                </InputContainer>
                <InputContainer>
                  <InputItem>
                    <Typography>Número</Typography>
                    <Field
                      name="address.number"
                      id="numeroResponsavel"
                      label="Infome o número do local"
                      component={CustomTextField}
                    />
                  </InputItem>
                  <InputItem>
                    <Typography>Bairro</Typography>
                    <Field
                      name="address.district"
                      id="bairroResponsavel"
                      label="Informe o bairro"
                      component={CustomTextField}
                    />
                  </InputItem>
                </InputContainer>
                <InputContainer>
                  <InputItem>
                    <Typography>Cidade</Typography>
                    <Field
                      disabled
                      name="address.city.name"
                      label="Infome o número do local"
                      component={CustomTextField}
                    />
                  </InputItem>
                  <InputItem>
                    <Typography>Estado</Typography>
                    <Field
                      disabled
                      name="address.city.state.name"
                      id="estadoResponsavel"
                      label="Infome o número do local"
                      component={CustomTextField}
                    />
                  </InputItem>
                </InputContainer>
                <InputContainer>
                  <InputItem>
                    <Typography>Complemento</Typography>
                    <Field
                      name="address.complement"
                      id="complementoResponsavel"
                      label="Adicione um complemento (Opcional)"
                      component={CustomTextField}
                    />
                  </InputItem>
                </InputContainer>
              </Box>
            )}
            {cepLoading && (
              <Box
                flex="1"
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ marginTop: 10 }}>
                <CircularProgress style={{ color: appColors.PRIMARY_COLOR }} />
              </Box>
            )}
            <Box style={{ padding: 10, marginTop: 40 }}>
              <InputItem
                style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  onClick={() => {
                    onBack(0, values);
                  }}
                  id="btnVoltar"
                  style={styles.purpleButton}>
                  Voltar
                </Button>
                <Button
                  type="submit"
                  disabled={isLoading}
                  id="btnProsseguir"
                  style={styles.purpleButton}>
                  {isLoading && (
                    <CircularProgress
                      size={15}
                      style={{ color: 'white', marginRight: 10 }}
                    />
                  )}
                  {isNext ? 'Prosseguir' : 'Criar conta'}
                </Button>
              </InputItem>
            </Box>
          </Form>
        </Box>
      )}
    />
  );
};

RegisterManagerForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default RegisterManagerForm;
