import React from 'react';
import CustomButton from 'components/form/CustomButton';

const ActionsCD = ({ data, onSubmit }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {data.stores.every(e => e.status === 5) === true &&
        data.original_delivery_name !== 'Retirar no Local' && (
          <div>
            <CustomButton
              variant="contained"
              style={{
                backgroundColor: '#607d8b',
              }}
              label="Chamar Boy"
              onClick={() => onSubmit({ status: 9 }, data.id)}
            />
          </div>
        )}
    </div>
  );
};

export default ActionsCD;
