import styled from 'styled-components';
import { Box, Typography, Avatar } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import appColors from 'utils/appColors';

export const StyledBox = styled(Box)`
  && {
    padding-bottom: 20px;
    @media (max-width: 900px) {
      margin-right: 10px;
    }
  }
`;

export const ProfilePic = styled(Box)`
  && {
    border-radius: 50%;
    background-color: ${appColors.LIGHT_COLOR};
    width: 200px;
    height: 200px;
    align-items: center;
    margin-bottom: 40px;
  }
`;

export const ProfilePicImage = styled('img')`
  && {
    border-radius: 50%;
    object-fit: cover;
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
  }
`;

export const ProfileAvatarImage = styled(Avatar)`
  && {
    border-radius: 50%;
    object-fit: cover;
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
  }
`;

export const StyledPersonIcon = styled(PersonIcon)`
  && {
    font-size: 12rem;
    color: ${appColors.SECUNDARY_COLOR};
    position: absolute;
  }
`;

export const HoverImageUpdate = styled.div`
  position: absolute;
  margin: 0px;
  background-color: rgba(0, 0, 0, 0.4);
  width: 200px;
  height: 200px;
  border-radius: 100px;
  opacity: 0;
  align-self: center;
  justify-items: center;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 1;
  }
`;

export const TextInput = styled(Typography)`
  position: absolute;
  width: 100%;
  font-weight: bold !important;
  color: #fff;
  text-align: center;
  padding: 85px 20px;
  font-size: 20px !important;
`;

export const StyledInput = styled.input`
  && {
    opacity: 0;
    width: 100%;
    height: 100%;
    display: block;
    cursor: pointer;
  }
`;
