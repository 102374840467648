import React from 'react';
import { Typography, Box } from '@material-ui/core';
import notImage from 'images/Products/not_image.jpg';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles, {
  ImageProducts,
  ContainerProducts,
  BoxProduct,
  BoxGrid,
} from './styles';
import FooterList from 'components/FooterList';

const StoreListBox = ({
  data,
  nextPage,
  total,
  page,
  perPage,
  previousPage,
  handlePage,
}) => {
  const history = useHistory();

  return (
    <Box display="flex" flexDirection="column" flex="1">
      <ContainerProducts
        display="flex"
        flexDirection="column"
        flexWrap="wrap"
        flex="1">
        <BoxGrid>
          {data.map(item => (
            <BoxProduct
              style={{ cursor: 'pointer' }}
              onClick={() => {
                history.push({
                  pathname: `/lojas/${item.slug}`,
                });
              }}
              display="flex"
              key={item.id}
              flexDirection="row">
              <ImageProducts
                src={
                  Object.keys(item.image).length > 0
                    ? item.image.original
                    : notImage
                }
              />
              <Typography style={styles.ProductPrice}>{item.name}</Typography>
            </BoxProduct>
          ))}
        </BoxGrid>
        <Box flex="1" justifyContent="center" alignItems="center">
          <FooterList
            page={page}
            total={total}
            perPage={perPage}
            previousPage={previousPage}
            nextPage={nextPage}
            handlePage={handlePage}
          />
        </Box>
      </ContainerProducts>
    </Box>
  );
};
StoreListBox.propTypes = {
  data: PropTypes.array.isRequired,
};

export default StoreListBox;
