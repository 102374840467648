/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import HeaderCategories from 'components/Pages/Categories/HeaderCategories';
import { useSelector, useDispatch } from 'react-redux';
import FiltersCategories from 'components/Pages/Categories/FiltersCategories';
import { Creators as ProductCreators } from 'store/ducks/product';
import { Creators as CategoryCreators } from 'store/ducks/category';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import HeaderCategoriesMobi from 'components/Pages/Categories/HeaderCategoriesMobi';
import appColors from 'utils/appColors';
import ProductsListCategory from 'components/Pages/Categories/ProductsListCategory';
import styles, { CategoriesContainer, BoxInfo } from './styles';
import { Helmet } from 'react-helmet';
import useQuery from 'hooks/useQuery';
import { useHistory } from 'react-router-dom';
import Page404 from '../404Page';
import appNaming from 'utils/appNaming';
import { ReactSVG } from 'react-svg';

var NotFound = require('assets/notfound.svg');

const CategoriesPage = ({ match }) => {
  const {
    productByCategory,
    productByCategoryTotal,
    productByCategoryLoading,
  } = useSelector(state => state.product.product);
  const { category, categoryLoading } = useSelector(
    state => state.category.category
  );

  const dispatch = useDispatch();

  const [, /* localState */ setLocalState] = React.useState({
    categoryName: '',
  });

  const query = useQuery();
  const history = useHistory();

  const [filters, setFilters] = React.useState({
    page: query.get('page') !== null ? Number(query.get('page')) : 1,
    per_page: 12,
    search: '',
    orderByColumn: '',
    orderByDirection: '',
    price_min: 0,
    price_max: 5000,
    size: [],
    color: [],
    order: '1',
    section: match.params.name,
    store: query.get('store') ? query.get('store') : '',
  });
  const { page, per_page, section } = filters;

  const nextPage = () => {
    if (page + 1 <= Math.ceil(productByCategoryTotal / per_page)) {
      setFilters({ ...filters, page: page + 1 });
    }
  };

  const previousPage = () => {
    if (page - 1 >= 1) {
      setFilters({ ...filters, page: page - 1 });
    }
  };

  const handlePage = selectPage => {
    setFilters({ ...filters, page: selectPage });
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (page > 1) {
      if (query.get('store')) {
        history.push({
          search: `?section=${section}&page=${page}&store=${query.get(
            'store'
          )}`,
        });
      } else {
        history.push({
          search: `?section=${section}&page=${page}`,
        });
      }
    } else {
      if (query.get('store')) {
        history.push({
          search: `?section=${section}&store=${query.get('store')}`,
        });
      } else {
        // if(match.params.name ) history.push({
        //   search: `?section=${section}`,
        // });
      }
    }

    dispatch(
      ProductCreators.getProductsByCategoryRequest(filters, match.params.name)
    );
  }, [filters]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      ProductCreators.getProductsByCategoryRequest(filters, match.params.name)
    );

    dispatch(CategoryCreators.getCategoryRequest(match.params.name));
    setFilters({
      page: query.get('page') !== null ? Number(query.get('page')) : 1,
      per_page: 12,
      search: '',
      orderByColumn: '',
      orderByDirection: '',
      price_min: 0,
      price_max: 5000,
      size: [],
      color: [],
      order: '1',
      //section: match.params.name,
      store: query.get('store') ? query.get('store') : '',
    });
  }, [match.params.name, history.location.search]);

  React.useEffect(() => {
    setLocalState({
      categoryName: category.name,
    });
  }, [setLocalState]);

  const onFormFiltersSubmit = values => {
    setFilters(values);
  };

  return (
    <CategoriesContainer flex="1" display="flex" flexDirection="column">
      {categoryLoading && (
        <Box
          flex="1"
          display="flex"
          justifyContent="center"
          style={{ marginTop: 20, minWidth: 200 }}>
          <CircularProgress style={{ color: appColors.PRIMARY_COLOR }} />
        </Box>
      )}
      {Object.keys(category).length > 0 && (
        <>
          <Helmet>
            <meta name="kdt:page" content="category" />
            <title>
              {category.name} - {appNaming.REGULAR_NAMING}
            </title>
            <meta name="description" content={category.description}></meta>
          </Helmet>
          <HeaderCategoriesMobi title={category.name} />
          <BoxInfo
            display="flex"
            flex="1"
            flexDirection="row"
            flexWrap="wrap"
            style={{ margin: '10px 10px' }}>
            <FiltersCategories
              data={{ colors: category.colors, sizes: category.sizes }}
              onSubmit={onFormFiltersSubmit}
              section={query.get('section')}
              initialValues={filters}
            />
            <Box flex="1" flexDirection="column">
              <HeaderCategories title={category.name} />
              {productByCategoryLoading && (
                <Box
                  flex="1"
                  display="flex"
                  justifyContent="center"
                  style={{ marginTop: 20, minWidth: 200 }}>
                  <CircularProgress
                    style={{ color: appColors.PRIMARY_COLOR }}
                  />
                </Box>
              )}
              {productByCategory.length > 0 && (
                <ProductsListCategory
                  data={productByCategory}
                  total={productByCategoryTotal}
                  nextPage={nextPage}
                  previousPage={previousPage}
                  handlePage={handlePage}
                  loading={productByCategoryLoading}
                  page={page}
                  perPage={per_page}
                />
              )}
              {productByCategory.length === 0 && !productByCategoryLoading && (
                <Box
                  flex="1"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={styles.boxEmpty}>
                  <div style={{ width: 500, height: 400 }}>
                    <Typography style={styles.purpleTitle}>
                      Nenhum resultado encontrado
                    </Typography>
                    <ReactSVG src={NotFound} />
                  </div>
                </Box>
              )}
            </Box>
          </BoxInfo>
        </>
      )}
      {Object.keys(category).length === 0 && !categoryLoading && <Page404 />}
    </CategoriesContainer>
  );
};

export default CategoriesPage;
