import { call, takeLatest, all, put } from 'redux-saga/effects';
import api from 'services/api';
import {
  Types,
  Creators as StockCreators,
} from 'store/ducks/admin/product/productStockUpdate';
import { Creators as NotificationCreators } from 'store/ducks/app';
import { callApi } from 'store/sagas/auth';

function* getStockProductUpdate({ payload }) {
  try {
    const { stock, code_internal } = payload;

    const request = call(api.put, `/v1/admin/products/stock/${code_internal}`, {
      stock,
    });

    const response = yield call(callApi, request);

    if (response.status !== 200 && response.status !== 201) throw response;

    yield put(
      NotificationCreators.openNotification({
        message: 'Status Alterado com sucesso',
        type: 'success',
      })
    );
    // notificacao aqui
    yield put(StockCreators.getProductUpdateSuccess());
  } catch (err) {
    yield put(
      NotificationCreators.openNotification({
        message: 'Falha na requisição',
        type: 'error',
      })
    );
    yield put(StockCreators.getProductUpdateFailure('Erro ao buscar na API'));
  }
}

// Individual exports for testing
export default function* productUpdateSaga() {
  yield all([
    takeLatest(Types.GET_STOCK_UPDATE_REQUEST, getStockProductUpdate),
  ]);
}
