import React from 'react';
import FooterButiri from './components/buriti';
import FooterOferta from './components/oferta';
import appTheme from 'utils/appTheme';

const footers = {
  Buriti: FooterButiri,
  Oferta: FooterOferta,
};

export default function HomeScreen(props) {
  const Footer = footers[appTheme.name] || FooterOferta;
  return <Footer {...props} />;
}
