import React, { useRef } from 'react';
import { Formik, FastField, Form } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Typography, FormControlLabel } from '@material-ui/core';
import { InputContainer, InputItem } from 'components/form/StyledComponents';
import CustomTextField from 'components/form/components/CustomTextField';
import CustomSelect from 'components/form/components/CustomSelect';
import FormButtons from 'components/form/components/FormButtons';
import Switch from '@material-ui/core/Switch';
import CustomCurrencyField from 'components/form/components/CustomCurrencyField';

const TabContainer = ({ children }) => {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
};

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export const formInitialValues = {
  enable_automatic_withdrawal: '',
  transfer_interval: '',
  transfer_day: '',
  minimum_transfer_value: '',
};

const WithdrawForm = ({
  onSubmit,
  store,
  initialValues = formInitialValues,
  submitText,
  isLoading,
}) => {
  const [formikForm, setFormikForm] = React.useState({});

  const formikRef = useRef();

  React.useEffect(() => {
    setFormikForm(formikRef.current);
  }, [formikRef]);

  const [isAutomatic, setIsAutomatic] = React.useState(
    store.enable_automatic_withdrawal === true
  );

  const handleSetAutomatic = status => {
    formikForm.setFieldValue('enable_automatic_withdrawal', status);
    setIsAutomatic(status);
  };

  const schema = Yup.object().shape({
    enable_automatic_withdrawal: Yup.boolean(),
    transfer_interval: Yup.string().nullable(),
    minimum_transfer_value: Yup.number()
      .test(
        'minimum_transfer_value',
        'O valor deve ser maior que R$ 1,00',
        val => val >= 1
      )
      .nullable(),
    transfer_day: Yup.number().nullable(),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      enableReinitialize
      ref={formikRef}
      render={({ values }) => (
        <Form>
          <InputContainer>
            <InputItem>
              <FormControlLabel
                control={
                  <Switch
                    checked={!!isAutomatic}
                    onChange={() => handleSetAutomatic(!isAutomatic)}
                    name="enable_automatic_withdrawal"
                    color="primary"
                  />
                }
                label="Saque automático?"
              />
              <section style={{ color: '#666', marginTop: '5px' }}>
                <strong style={{ width: '100%' }}>Observações: </strong>
                Esta função programa o saque automático na conta bancária, caso
                queira configurar os intervalos, clique no botão salvar também!
              </section>
            </InputItem>
          </InputContainer>
          {isAutomatic && (
            <InputContainer>
              <InputItem>
                <FastField
                  name="transfer_interval"
                  label="Frequência de Transferência"
                  options={[
                    { id: 'daily', name: 'Todos os dias' },
                    { id: 'weekly', name: 'Toda semana' },
                    { id: 'monthly', name: 'Uma vez ao mês' },
                  ]}
                  placeholder="Intervalo de Transferência"
                  component={CustomSelect}
                  isLoading={false}
                />
              </InputItem>
              <InputItem>
                <FastField
                  name="minimum_transfer_value"
                  label="Valor Mínimo"
                  component={CustomCurrencyField}
                />
              </InputItem>
              {values.transfer_interval === 'monthly' && (
                <InputItem>
                  <FastField
                    name="transfer_day"
                    label="Dia do mês para transferência"
                    type="number"
                    component={CustomTextField}
                  />
                </InputItem>
              )}
            </InputContainer>
          )}
          <div style={{ marginTop: 20 }}>
            <FormButtons
              isLoading={isLoading}
              submitText="Salvar saque automático"
            />
          </div>
        </Form>
      )}
    />
  );
};

WithdrawForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.oneOfType([PropTypes.object]),
  submitText: PropTypes.string,
  handleBack: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isLoading: PropTypes.bool.isRequired,
};

WithdrawForm.defaultProps = {
  initialValues: formInitialValues,
  submitText: 'Salvar',
  handleBack: false,
  onSubmit: () => {},
};

export default WithdrawForm;
