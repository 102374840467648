import appColors from 'utils/appColors';

const styles = () => ({
  statContainer: {
    boxShadow: '1px 2px 6px rgba(0,0,0,0.2)',
    padding: '14px',
    backgroundColor: appColors.LIGHT_COLOR,
    width: '100%',
  },
  statIconContainer: {
    paddingRight: '0px',
    paddingTop: '6px',
  },
  statIcon: {
    color: appColors.COLOR_ICON,
    fontSize: '32px',
    fontweight: 'bold',
    float: 'right',
  },
  statContent: {
    float: 1,
  },
  statDescription: {
    color: '#000000',
    alignContent: 'center',
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 18,
  },

  statTitle: {
    fontSize: 20,
    color: appColors.COLOR_ICON,
  },
  purpleButton: {
    backgroundColor: appColors.PRIMARY_COLOR,
    float: 'right',
    color: 'white',
    paddingLeft: 20,
    paddingRight: 20,
    fontweight: 'bold',
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: 23,
    width: '100%',
  },
});
export default styles;
