import React, {
  createContext,
  useCallback,
  useState,
  useEffect,
  useContext,
} from 'react';

const LocationContext = createContext({});

const LocationProvider = ({ children }) => {
  const [data, setData] = useState({});
  const [coords, setCoords] = useState({});

  useEffect(() => {
    verifyCoords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (coords.lat && coords.long)
      localStorage.setItem('@SensiMkt-myPosition', JSON.stringify(coords));
  }, [coords]);

  const verifyCoords = useCallback(() => {
    const jsonTempPosition =
      localStorage.getItem('@SensiMkt-myPosition') || '{}';
    const tempPosition = JSON.parse(jsonTempPosition);
    if (tempPosition.lat) {
      setCoords(tempPosition);
      return true;
    }
    return false;
  }, []);

  const confirmPosition = async data => {
    setCoords(data);
  };

  return (
    <LocationContext.Provider
      value={{
        data,
        setData,
        coords,
        setCoords,
        confirmPosition,
        verifyCoords,
      }}>
      {children}
    </LocationContext.Provider>
  );
};

function useLocation() {
  const context = useContext(LocationContext);
  if (!context) {
    throw new Error('useLocation must be used within an LocationProvider');
  }
  return context;
}

export { LocationProvider, useLocation };
