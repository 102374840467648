/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import notImage from 'images/Products/not_image.jpg';
import { Title, Image, Gallery, GridView } from './styles';

import { useParams } from 'react-router';
import useBusiness from 'hooks/business_partner';

function PartnerPage() {
  const { id } = useParams();
  const { getBusiness, business } = useBusiness();

  useEffect(() => {
    getBusiness(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (business === null || typeof business.data.content !== 'object') {
    return <div />;
  }

  return (
    <Grid container alignContent="center" style={{ padding: '20px 10%' }}>
      <div>
        <Title>Parceiros</Title>

        <GridView>
          {business.data.content.map((item, idx) => {
            if (item.type === 'banner') {
              if (item.content.medium)
                return (
                  <Gallery key={idx}>
                    <Image src={item.content.medium || notImage} />
                  </Gallery>
                );
            }

            if (item.type === 'business_details') {
              const product = item.content.length > 0 && item.content[0];
              if (!product) return <div />;
              return (
                <Gallery key={idx}>
                  <Image src={product.image.medium || notImage} />
                </Gallery>
              );
            }
          })}
        </GridView>
      </div>
    </Grid>
  );
}

export default PartnerPage;
