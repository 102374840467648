import React from 'react';
import { CardContainerLogin } from '../../styles';
import { ContainerRequest, ContainerImage } from './styles';
import Button from '@material-ui/core/Button';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ListIcon from '@material-ui/icons/List';

var timer;

const PermissionRequest = ({ nextStagePermision }) => {
  const [myLocalization, setLocalization] = React.useState(false);

  React.useEffect(() => {
    if (myLocalization) {
      navigator.geolocation.getCurrentPosition(function (position) {
        timer = setTimeout(
          () =>
            nextStagePermision(
              position.coords.latitude,
              position.coords.longitude
            ),
          1500
        );
      });
    }

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myLocalization]);

  return (
    <CardContainerLogin>
      <ContainerRequest>
        <div>
          <h3>Olá, Jhemerson!</h3>
          <span>
            Para prosseguir precisamos que você permitar que o navegador obtenha
            sua localização, ao confirmar as melhores ofertas ao seu redor serão
            listadas.
          </span>
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setLocalization(true)}>
          <LocationOnIcon /> Minha localização
        </Button>
        <Button
          variant="contained"
          color="light"
          style={{ margin: '0px 10px' }}>
          <ListIcon /> Escolher endereços
        </Button>
      </ContainerRequest>
      <ContainerImage>
        <img alt="" src="assets/popup.png" width="300" />
      </ContainerImage>
    </CardContainerLogin>
  );
};

export default PermissionRequest;
