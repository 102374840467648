import styled from 'styled-components';
import { Box } from '@material-ui/core';

export const ContainerHeader = styled(Box)`
  margin-top: 20px;

  a {
    text-decoration: none;
  }

  && {
    @media all and (max-width: 500px) {
      display: none;
    }
  }
`;

const styles = {
  purpleTitle: {
    marginTop: 50,
    color: '#505255',
    fontWeight: 'bold',
    fontSize: '36px',
    lineHeight: '44px',
  },
};

export default styles;
