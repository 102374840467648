import styled from 'styled-components';
import { Box, Card, Typography } from '@material-ui/core';
import appColors from 'utils/appColors';

export const CartContainer = styled(Box)`
  && {
    display: flex;
    flex: 1;
    margin-top: 10px;
    width: 90%;
    margin-bottom: 20px;

    @media all and (max-width: 500px) {
      margin-top: 20px;
    }
  }
`;

export const FooterContainer = styled(Box)`
  && {
    display: flex;
    flex: 1;
    margin-top: 60px;
    width: 90%;
    margin-bottom: 40px;
  }
`;

export const CardShopCart = styled(Card)`
  && {
    flex: 1;
    display: flex;
    padding: 40px;
    flex-direction: row;
    margin-top: 20px;
    flex-wrap: wrap;
    margin-bottom: 20px;

    @media all and (max-width: 500px) {
      padding: 10px;
    }
  }
`;

export const BoxContainer = styled(Box)`
  && {
    padding: 80px;
    background-color: #eeeaef;
    border-radius: 10px;
    margin-top: 10px;

    @media all and (min-width: 375px) and (max-width: 800px) {
      margin-top: 20px;
      padding: 10px;
      width: 110%;
      display: flex;
      flexdirection: row;
      alignitems: center;
      justifycontent: center;
    }
  }
`;

export const BoxContainerText = styled(Box)`
  && {
    padding-left: 60px;

    @media all and (min-width: 375px) and (max-width: 800px) {
      padding-left: 0px;
      width: 27em;
    }
  }
`;

export const BoxContainerBarcode = styled(Box)`
  && {
    @media all and (min-width: 375px) and (max-width: 800px) {
      margin-left: 50px;
    }
  }
`;

export const BoxContainerOkImg = styled(Box)`
  && {
    @media all and (min-width: 501px) and (max-width: 1024px) {
      flex: 1;
      justify-content: center;
    }
    @media all and (min-width: 375px) and (max-width: 800px) {
      margin-left: 50px;
    }
  }
`;

export const BigTextBlack = styled(Typography)`
  && {
    color: #373737;
    font-size: 32px;
    text-transform: uppercase;

    @media all and (max-width: 500px) {
      font-size: 22px;
      text-align: center;
    }

    @media all and (min-width: 501px) and (max-width: 768px) {
      margin-top: 10px;
      text-align: center;
    }
  }
`;

export const BigTitlePurple = styled(Typography)`
  && {
    color: ${appColors.PRIMARY_COLOR};
    font-weight: bold;
    font-size: 35px;

    @media all and (max-width: 500px) {
      font-size: 22px;
      text-align: center;
    }

    @media all and (min-width: 501px) and (max-width: 768px) {
      text-align: center;
    }
  }
`;

const styles = {
  purpleButton: {
    backgroundColor: appColors.PRIMARY_COLOR,
    color: 'white',
    paddingLeft: 40,
    paddingRight: 40,
    fontSize: 20,
    paddingTop: 15,
    paddingBottom: 15,
  },
  greenButton: {
    backgroundColor: '#2bc91a',
    color: 'white',
    paddingLeft: 40,
    paddingRight: 40,
    fontSize: 20,
    paddingTop: 15,
    paddingBottom: 15,
  },
};

export default styles;
