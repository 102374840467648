import { Types as AuthTypes } from 'store/ducks/auth';
// Lista todas as Lojas
export const Types = {
  GET_LIST_REQUEST: 'favorites/GET_LIST_REQUEST',
  GET_LIST_SUCCESS: 'favorites/GET_LIST_SUCCESS',
  GET_LIST_FAILURE: 'favorites/GET_LIST_FAILURE',
  CLEAR_FAVORITES_LIST: 'favorites/CLEAR_FAVORITES_LIST',
  CLEAR_FAVORITES_LIST_SUCCESS: 'favorites/CLEAR_FAVORITES_LIST_SUCCESS',
};

export const initialState = {
  favoritesList: [],
  favoritesLoading: false,
  favoritesListTotal: 0,
  favoritesError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_LIST_REQUEST:
      return {
        ...state,
        favoritesList: [],
        favoritesError: null,
        favoritesLoading: true,
      };
    case Types.GET_LIST_SUCCESS:
      return {
        ...state,
        favoritesList: action.payload.data,
        favoritesLoading: false,
        favoritesError: null,
        favoritesListTotal: action.payload.total,
      };
    case Types.GET_LIST_FAILURE:
      return {
        ...state,
        favoritesLoading: false,
        favoritesError: action.payload,
      };

    case AuthTypes.GET_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export const Creators = {
  getFavoritesListRequest: ({
    page,
    perPage,
    search,
    orderByColumn,
    orderByDirection,
  }) => ({
    type: Types.GET_LIST_REQUEST,
    payload: {
      page,
      perPage,
      search,
      orderByColumn,
      orderByDirection,
    },
  }),
  getFavoritesListSuccess: ({ data, total }) => ({
    type: Types.GET_LIST_SUCCESS,
    payload: { data, total },
  }),
  getFavoritesListFailure: error => ({
    type: Types.GET_LIST_FAILURE,
    payload: error,
  }),
};
