import React from 'react';
import Butiri from './components/buriti';
import Oferta from './components/oferta';
import appTheme from 'utils/appTheme';

import { Stack } from '@chakra-ui/react';

const headers = {
  Buriti: Butiri,
  Oferta: Oferta,
};

export default function HeaderInfo(props) {
  const HeaderInfoDetails = headers[appTheme.name] || Oferta;
  return (
    <Stack p={['0 20px', '20px 100px']}>
      <HeaderInfoDetails {...props} />
    </Stack>
  );
}
