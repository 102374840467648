// Lista todas as Lojas
export const Types = {
  GET_INSERT_REQUEST: 'favorite/GET_INSERT_REQUEST',
  GET_INSERT_SUCCESS: 'favorite/GET_INSERT_SUCCESS',
  GET_INSERT_FAILURE: 'favorite/GET_INSERT_FAILURE',
};

export const initialState = {

  favorite: {},
  favoriteLoading: false,
  favoriteError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_INSERT_REQUEST:
      return {
        ...state,
        favorite: {},
        favoriteError: null,
        favoriteLoading: true,
      };
    case Types.GET_INSERT_SUCCESS:
      return {
        ...state,
        favoriteLoading: false,
        favoriteError: null,
      };
    case Types.GET_INSERT_FAILURE:
      return {
        ...state,
        favoriteLoading: false,
        favoriteError: action.payload,
      };
    default:
      return state;
  }
};

export const Creators = {
  getFavoriteInsertRequest: (id) => ({
    type: Types.GET_INSERT_REQUEST,
    payload: { id },

  }),
  getFavoriteInsertSuccess: ({ data, total }) => ({
    type: Types.GET_INSERT_SUCCESS,
    payload: { data, total },
  }),
  getFavoriteInsertFailure: error => ({
    type: Types.GET_INSERT_FAILURE,
    payload: error,
  }),
};
