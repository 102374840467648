import React from 'react';
import PropTypes from 'prop-types';
import HeaderItem from './HeaderItem';

const OrderItem = ({ data, open }) => {
  const [localState, setLocalState] = React.useState({
    isOpen: open,
  });

  const { isOpen } = localState;

  const handleOpen = () => {
    setLocalState({ ...localState, isOpen: !isOpen });
  };

  return (
    <>
      <HeaderItem data={data} isOpen={isOpen} handleOpen={handleOpen} />
    </>
  );
};

OrderItem.propTypes = {
  data: PropTypes.object.isRequired,
  open: PropTypes.bool,
};

OrderItem.defaultProps = {
  open: false,
};

export default OrderItem;
