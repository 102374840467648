import styled from 'styled-components';
import appColors from 'utils/appColors';

export const Grid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px 0px;
`;

export const Segment = styled.div`
  border-radius: 100px;
  background-color: ${props =>
    props.selected ? appColors.PRIMARY_COLOR : '#f5f5f5'};
  color: ${props => (props.selected ? 'white' : '#222')};
  padding: 5px 14px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  flex-direction: row;
  display: flex;
  align-items: center;
  font-weight: bold;
`;

export const Logo = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  margin-right: 10px;
`;
