/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useTransition } from 'react-spring';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import PropTypes from 'prop-types';
import { Container, Content } from './styles';
import { useToast } from '../../hooks/toast';

const icons = {
  info: <InfoIcon size={20} />,
  success: <CheckCircleIcon size={20} />,
  error: <ErrorIcon size={20} />,
};

const ToastContainer = ({ messages }) => {
  const messagesWithTransitions = useTransition(
    messages,
    message => message.id,
    {
      from: { right: '-120%', opacity: 0 },
      enter: { right: '0%', opacity: 1 },
      leave: { right: '-120%', opacity: 0 },
    },
  );

  return (
    <Container className="toast-container">
      {messagesWithTransitions.map(({ item, key, props }) => (
        <Toast
          className="toast-default"
          key={key}
          message={item}
          style={props}
        />
      ))}
    </Container>
  );
};

const Toast = ({ message, style }) => {
  const { removeToast } = useToast();
  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id);
    }, 2500);

    return () => {
      clearTimeout(timer);
    };
  }, [message.id, removeToast]);

  return (
    <Content
      className="toast-component"
      type={message.type}
      $hasDescription={!!message.description}
      style={style}
    >
      {icons[message.type || 'info']}
      <div>
        <strong>{message.title}</strong>
        {message.description && <p>{message.description}</p>}
      </div>

      <button type="button" onClick={() => removeToast(message.id)}>
        <CloseIcon size={18} />
      </button>
    </Content>
  );
};

ToastContainer.propTypes = {
  messages: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

export default ToastContainer;
