import React from 'react';
import ConfirmationEmailAutorized from 'components/Pages/ConfirmationEmailAutorized';
import { useDispatch } from 'react-redux';
import { Creators as ValidateAccountCreators } from 'store/ducks/account/validateAccount';
import useQuery from 'hooks/useQuery';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import appColors from 'utils/appColors';

const MailConfirmPage = () => {
  const dispatch = useDispatch();
  const query = useQuery();

  const { validateAccountLoading, validateAccountError } = useSelector(
    state => state.account.validateAccount
  );

  React.useEffect(() => {
    dispatch(
      ValidateAccountCreators.getValidateAccountRequest(query.get('hash'))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box flex="1">
      {!validateAccountLoading && validateAccountError === null && (
        <ConfirmationEmailAutorized />
      )}

      {validateAccountLoading && validateAccountError === null && (
        <Box
          flex="1"
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ height: 300 }}>
          <CircularProgress style={{ color: appColors.PRIMARY_COLOR }} />
        </Box>
      )}

      {!validateAccountLoading && validateAccountError !== null && (
        <Box
          flex="1"
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ height: 300 }}>
          <Typography
            style={{
              color: appColors.PRIMARY_COLOR,
              fontWeight: 'bold',
              fontSize: 20,
            }}>
            {validateAccountError}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default MailConfirmPage;
