import React from 'react';
import Example from './example';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

const DragAndDrop = ({
  featured,
  setFieldValue,
  onDelete,
  images,
  changeImages,
  onEdit,
}) => {
  return (
    <div className="App">
      <DndProvider backend={HTML5Backend}>
        <Example
          images={images}
          featured={featured}
          setFieldValue={setFieldValue}
          changeImages={changeImages}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      </DndProvider>
    </div>
  );
};

export default DragAndDrop;
