import { call, takeLatest, all, put } from 'redux-saga/effects';
import api from 'services/api';

import { Types, Creators } from 'store/ducks/trend';

function* getTrend({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(api.get, `/v1/site/trends/${id}`);
    if (response.status !== 200) throw response;
    yield put(Creators.getTrendSuccess(response.data));
  } catch (err) {
    yield put(Creators.getTrendFailure('Erro ao buscar na API'));
  }
}

// Individual exports for testing
export default function* trendSaga() {
  yield all([
    takeLatest(Types.GET_REQUEST, getTrend),
  ]);
}
