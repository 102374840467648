import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import appUtils from 'utils/appUtils';
import styles from './styles';
import BuyForm from '../BuyForm';
import ReactMarkdown from 'react-markdown/with-html';
import { unicVariation } from './constants';
import { useState } from 'react';
import { useEffect } from 'react';
import { Stack, Box, Text } from '@chakra-ui/react';

const InfoProduct = ({ children, data, addToCart, isWhole }) => {
  const [variationSelected, setVariationSelected] = useState([]);
  const sholdCatalog = !!data.catalog_product_id;
  const variations_catalog =
    !!sholdCatalog &&
    data.variations.length > 0 &&
    data.variations.map(item => ({
      ...item,
      price:
        item.price -
        item.price * (data.catalog_product_id.price_discount / 100),
    }));

  const variations =
    variations_catalog || data.variations.length > 0
      ? data.variations
      : unicVariation(data);
  console.log(data);
  const [localState, setlocalState] = React.useState({
    price: data?.catalog_product_id?.price_discount
      ? data.catalog_product_id.price
      : variations.length > 0
      ? variations[0].price
      : data.price,
  });

  const { price } = localState;

  useEffect(() => {
    if (variations) {
      setVariationSelected(variations[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePrice = value => {
    if (!isWhole) {
      setlocalState({ price: value });
    }
  };

  return (
    <Stack w={['full', '400px']} p={['20px', 0]}>
      <Box>
        <Stack>
          <Text fontSize="34px" color="#222" fontWeight="bold">
            {data.name}
          </Text>

          {data.description && (
            <ReactMarkdown style={styles.subTitle} source={data.description} />
          )}

          <Box pb="20px">
            {variations[0].id === 'unic' ? (
              <Text fontSize="26px" style={styles.price} fontWeight="bold">
                {appUtils.formatPrice(data.price_whole)}
              </Text>
            ) : (
              <Text fontSize="26px" style={styles.price} fontWeight="bold">
                {appUtils.formatPrice(price)}
              </Text>
            )}
          </Box>

          {data.quantity_min_whole > 0 && (
            <Typography style={styles.priceWhole}>
              A partir de {data.quantity_min_whole}{' '}
              {data.quantity_min_whole > 1 ? 'unidades' : 'unidade'}
              {!isWhole && <>: R$ {appUtils.formatPrice(data.price_whole)}</>}
            </Typography>
          )}

          {variations.length > 0 &&
            variations?.reduce(
              (accumulator, item) => accumulator + item.stock,
              0
            ) > 0 && (
              <BuyForm
                setVariationSelected={setVariationSelected}
                variationSelected={variationSelected}
                variations={variations}
                original_variations={data.variations}
                product={data}
                quantity_whole={data.quantity_min_whole}
                isWhole={isWhole}
                handlePrice={handlePrice}
                onSubmit={addToCart}
              />
            )}

          {((data.stock_control !== 0 &&
            data.stock === 0 &&
            variations.length === 0) ||
            (variations.length > 0 &&
              variations?.reduce(
                (accumulator, item) => accumulator + item.stock,
                0
              ) === 0)) && (
            <Stack justify="center" style={styles.unavailableProduct}>
              <Typography style={styles.whiteTitle}>
                Produto Indisponível
              </Typography>
            </Stack>
          )}
        </Stack>
      </Box>
      {children}
    </Stack>
  );
};

InfoProduct.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
  addToCart: PropTypes.func.isRequired,
  isWhole: PropTypes.bool.isRequired,
};

export default InfoProduct;
