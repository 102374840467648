import appTheme from './appTheme';

const appColors = {
  Buriti: {
    // GENERAL
    PRIMARY_COLOR: '#6dc03c',
    SECUNDARY_COLOR: '#378808',
    DANGER: '#CE3C3E',
    LIGHT_COLOR: 'rgba(81, 139, 129, 0.4)',
    LIGHT_COLOR2: 'rgba(81, 139, 129, 0.2)',
    HEADER_COLOR: '#6dc03c',
    COLOR_BUTTON: '#fff',
    COLOR_ICON: 'rgba(90, 157, 106, 1)',
    COLOR_BUTTON_MENU: '#fff',
    MENU_COLOR: 'rgba(81, 139, 129, 0.4)',
    COLOR_FONT_MENU: '#fff',
    SEARCHBAR_BACKGROUD: '#378808',
    TOPBAR_BACKGROUND: '#fff',
    TOPBAR_COLOR_FONT: '#6dc03c',
  },
  Oferta: {
    // GENERAL
    PRIMARY_COLOR: '#FA8F47',
    SECUNDARY_COLOR: '#FA8F47',
    DANGER: '#CE3C3E',
    LIGHT_COLOR: 'rgba(231, 99, 8, 0.08)',
    LIGHT_COLOR2: 'rgba(231, 99, 8, 0.6)',
    HEADER_COLOR: '#fff',
    COLOR_BUTTON: '#fff',
    COLOR_BUTTON_MENU: '#343434',
    MENU_COLOR: '#f1f1f1',
    COLOR_ICON: '#FA8F47',
    COLOR_FONT_MENU: '#FA8F47',
    SEARCHBAR_BACKGROUD: '#ccc',
    TOPBAR_BACKGROUND: 'rgba(231, 99, 8, 0.8)',
    TOPBAR_COLOR_FONT: '#fff',
    GRAY: '#868686',
  },
  SimerPay: {
    // GENERAL
    PRIMARY_COLOR: '#FA8F47',
    SECUNDARY_COLOR: '#FA8F47',
    DANGER: '#CE3C3E',
    LIGHT_COLOR: 'rgba(231, 99, 8, 0.08)',
    LIGHT_COLOR2: 'rgba(231, 99, 8, 0.6)',
    HEADER_COLOR: '#fff',
    COLOR_BUTTON: '#fff',
    COLOR_BUTTON_MENU: '#343434',
    MENU_COLOR: '#f1f1f1',
    COLOR_ICON: '#FA8F47',
    COLOR_FONT_MENU: '#FA8F47',
    SEARCHBAR_BACKGROUD: '#ccc',
    TOPBAR_BACKGROUND: 'rgba(231, 99, 8, 0.8)',
    TOPBAR_COLOR_FONT: '#fff',
    GRAY: '#868686',
  },
  Sandbox: {
    // GENERAL
    PRIMARY_COLOR: '#FA8F47',
    SECUNDARY_COLOR: '#FA8F47',
    DANGER: '#CE3C3E',
    LIGHT_COLOR: 'rgba(231, 99, 8, 0.2)',
    LIGHT_COLOR2: 'rgba(231, 99, 8, 0.8)',
    HEADER_COLOR: '#fff',
    COLOR_BUTTON: '#fff',
    COLOR_BUTTON_MENU: '#343434',
    MENU_COLOR: '#f1f1f1',
    COLOR_ICON: '#FA8F47',
    COLOR_FONT_MENU: '#FA8F47',
    SEARCHBAR_BACKGROUD: '#ccc',
    TOPBAR_BACKGROUND: 'rgba(231, 99, 8, 0.8)',
    TOPBAR_COLOR_FONT: '#fff',
    GRAY: '#868686',
  },
  OlharPix: {
    // GENERAL
    PRIMARY_COLOR: '#FA8F47',
    SECUNDARY_COLOR: '#FA8F47',
    DANGER: '#CE3C3E',
    LIGHT_COLOR: 'rgba(231, 99, 8, 0.2)',
    LIGHT_COLOR2: 'rgba(231, 99, 8, 0.8)',
    HEADER_COLOR: '#fff',
    COLOR_BUTTON: '#fff',
    COLOR_BUTTON_MENU: '#343434',
    MENU_COLOR: '#f1f1f1',
    COLOR_ICON: '#FA8F47',
    COLOR_FONT_MENU: '#FA8F47',
    SEARCHBAR_BACKGROUD: '#ccc',
    TOPBAR_BACKGROUND: 'rgba(231, 99, 8, 0.8)',
    TOPBAR_COLOR_FONT: '#fff',
    GRAY: '#868686',
  },
}[appTheme.name];

export default appColors;
