import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import HeaderBoxContainer from 'components/HeaderComponent/HeaderBoxContainer';

const InstallmentsTableHeader = ({ getFunction, initialValues }) => {
  const onSubmit = values => {
    getFunction(values);
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={() => (
        <Form>
          <HeaderBoxContainer>
            {/* <HeaderBoxItem style={{ paddingLeft: 6, paddingRight: 6 }}>
              <Field
                component={SearchInput}
                name="search"
                placeholder="Informe uma palavra-chave"
              />
            </HeaderBoxItem> */}
            {/* <HeaderBoxItem style={{ paddingLeft: 6 }}>
              <Link to="/minha-conta?aba=ClientesNew">
                <HeaderButton icon="add">Adicionar</HeaderButton>
              </Link>
            </HeaderBoxItem>
            <HeaderBoxItem justify="center" style={{ paddingLeft: 15 }}>
              <Link to="/minha-conta?aba=ClientesDeleted">
                <HeaderButton icon="delete">Listar Deletados</HeaderButton>
              </Link> 
            </HeaderBoxItem> */}
            {/*
            <HeaderBoxItem style={{ paddingLeft: 6 }}>
              <HeaderButton icon="save_alt">Exportar</HeaderButton>
            </HeaderBoxItem>
            */}
          </HeaderBoxContainer>
        </Form>
      )}
    />
  );
};

InstallmentsTableHeader.propTypes = {
  getFunction: PropTypes.func.isRequired,
  initialValues: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default InstallmentsTableHeader;
