/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import ItemTypes from './ItemTypes';
import { Grid, Fab } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import EditIcon from '@material-ui/icons/Crop';
import styled from 'styled-components';
import appColors from 'utils/appColors';

const StyledGridImg = styled.img`
  && {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
  }
`;

const StyledFab = styled(Fab)`
  && {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    background: ${appColors.COLOR_ICON};
    color: white;
    width: 32px;
    height: 32px;
    margin: 0 2px;
    min-height: 0;
    &:hover {
      background: ${appColors.PRIMARY_COLOR};
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.4);
    }
    &:active {
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.4);
    }
    position: absolute;
    right: 10px;
    top: 10px;
  }
`;

const Card = ({
  id,
  index,
  image,
  moveCard,
  featured,
  onDelete,
  setFeature,
  onEdit,
}) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.CARD, id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <Grid
      item
      ref={ref}
      xs={6}
      sm={3}
      key={image.id}
      style={{ position: 'relative' }}>
      <StyledFab
        onClick={() => {
          onDelete(image);
        }}>
        <DeleteIcon />
      </StyledFab>

      <StyledFab
        style={{ right: 50 }}
        onClick={() => {
          setFeature(image);
        }}>
        {featured === image.id || image.featured === 1 ? (
          <StarIcon />
        ) : (
          <StarBorderIcon />
        )}
      </StyledFab>

      {image.type === 'local' && (
        <StyledFab
          style={{ right: 90 }}
          onClick={() => {
            onEdit(index);
          }}>
          <EditIcon />
        </StyledFab>
      )}
      <StyledGridImg src={image.url} alt="" />
    </Grid>
  );
};
export default Card;
