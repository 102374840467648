import appColors from 'utils/appColors';

const styles = {
  purpleTitle: {
    color: appColors.SECUNDARY_COLOR,
    fontWeight: 'bold',
    fontSize: 32,
  },

  iconsAddRemove: {
    marginLeft: 10,
    marginRight: 10,
    color: appColors.SECUNDARY_COLOR,
    cursor: 'pointer',
  },
  titleProduct: {
    color: appColors.SECUNDARY_COLOR,
    fontWeight: 'bold',
    textAlign: 'left',
  },
  fontshipping: {
    color: appColors.SECUNDARY_COLOR,
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  resumeText: {
    borderBottom: '2px solid #FA8F47',
    fontWeight: 'bold',
  },
  totalText: {
    borderBottom: '2px solid #FA8F47',
    fontWeight: 'bold',
    marginLeft: 20,
  },
  smallTextBlackBold: {
    color: '#373737',
    fontWeight: 'bold',
  },
  smallTextBlack: {
    color: '#373737',
    textAlign: 'left',
  },
  bigBoldTextBlack: {
    color: '#373737',
    fontWeight: 'bold',
    fontSize: 22,
  },
  purpleButton: {
    backgroundColor: appColors.PRIMARY_COLOR,
    color: 'white',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    marginRight: 10,
    marginTop: 23,
  },
};

export default styles;
