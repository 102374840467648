/* eslint-disable react/display-name */
import React from 'react';
import PageBase from 'components/PageBase';
import DefaultTable from 'components/Tables/DefaultTable';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as ProductCreators } from 'store/ducks/admin/product';
import { Paper, Button } from '@material-ui/core';
import HeaderComponent from 'components/HeaderComponent';
import ProductTableHeader from 'components/Pages/ProductDetails/TableHeader';
import ProductStatus from 'components/Pages/ProductDetails/Status';
import ProductFooter from 'components/Pages/ProductDetails/Footer';
import ProductImage from 'components/Pages/ProductDetails/Image';
import appUtils from 'utils/appUtils';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import AddIcon from '@material-ui/icons/Add';

const columns = ({ handleRemoveProduct }) => [
  {
    title: 'Referência',
    field: 'code_internal',
    sorting: false,
  },
  {
    title: 'Imagem',
    field: 'images',
    sorting: false,
    render: rowData => <ProductImage rowData={rowData} />,
  },
  {
    title: 'Nome',
    field: 'name',
    sorting: false,
  },
  {
    title: 'Dimensões',
    field: 'type',
    sorting: false,
    render: rowData => (
      <span>
        {rowData.width}x{rowData.height}x{rowData.length} cm
      </span>
    ),
  },
  {
    title: 'Preço',
    field: 'price',
    sorting: false,
    render: rowData => <span>{appUtils.formatPrice(rowData.price)}</span>,
  },
  {
    title: 'Loja',
    field: 'store.name',
    sorting: false,
  },
  {
    title: 'Status',
    sorting: false,
    render: rowData => <ProductStatus rowData={rowData} />,
  },
  {
    title: 'Ações',
    render: rowData => (
      <Button onClick={() => handleRemoveProduct(rowData)}>Remover</Button>
    ),
  },
];

const columnsAdd = ({ handleChooseProduct }) => [
  {
    title: 'Referência',
    field: 'code_internal',
    sorting: false,
  },
  {
    title: 'Imagem',
    field: 'images',
    sorting: false,
    render: rowData => <ProductImage rowData={rowData} />,
  },
  {
    title: 'Nome',
    field: 'name',
    sorting: false,
  },
  {
    title: 'Dimensões',
    field: 'type',
    sorting: false,
    render: rowData => (
      <span>
        {rowData.width}x{rowData.height}x{rowData.length} cm
      </span>
    ),
  },
  {
    title: 'Preço',
    field: 'price',
    sorting: false,
    render: rowData => <span>{appUtils.formatPrice(rowData.price)}</span>,
  },
  {
    title: 'Status',
    sorting: false,
    render: rowData => <ProductStatus rowData={rowData} />,
  },
  {
    title: 'Ações',
    render: rowData => (
      <Button onClick={() => handleChooseProduct(rowData)}>Incluir</Button>
    ),
  },
];

const ProductListPage = ({ handleTab }) => {
  const dispatch = useDispatch();

  const [localState, setLocalState] = React.useState({
    search: '',
    orderByColumn: '',
    orderByDirection: '',
    page: 1,
    perPage: 10,
  });

  const [featured, setFeatured] = React.useState([]);

  const [open, setOpen] = React.useState(true);

  const {
    admin: {
      product: { productList, productListLoading, productListTotal },
    },
  } = useSelector(state => state);

  const handleChooseProduct = product => {
    setFeatured([...featured.filter(item => item.id !== product.id), product]);
  };
  const handleRemoveProduct = product => {
    setFeatured(featured.filter(item => item.id !== product.id));
  };

  const getFunction = data => {
    setLocalState(oldLocalState => ({ ...oldLocalState, ...data }));
  };

  React.useEffect(() => {
    dispatch(ProductCreators.getProductListRequest(localState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState]);

  return (
    <PageBase>
      <HeaderComponent title="Produtos em Destaque">
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}>
          <AddIcon /> Incluir produtos
        </Button>
      </HeaderComponent>

      <Paper>
        <DefaultTable
          getFunction={getFunction}
          columns={columns({ handleRemoveProduct })}
          data={featured}
          total={productListTotal}
          isLoading={productListLoading}
          page={localState.page}
          perPage={localState.perPage}
          footer={<ProductFooter />}
        />
      </Paper>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="md">
        <DialogContent>
          <HeaderComponent title="Produtos disponíveis">
            <ProductTableHeader
              getFunction={getFunction}
              initialValues={{ search: localState.search }}
              handleTab={handleTab}
              noButton
            />
          </HeaderComponent>

          <Paper>
            <DefaultTable
              getFunction={getFunction}
              columns={columnsAdd({ handleChooseProduct })}
              data={productList.filter(
                item =>
                  featured.findIndex(_product => _product.id === item.id) < 0
              )}
              total={productListTotal}
              isLoading={productListLoading}
              page={localState.page}
              perPage={localState.perPage}
              footer={<ProductFooter />}
            />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </PageBase>
  );
};

export default ProductListPage;
