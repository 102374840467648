import { call, put, all, takeLatest, select, take } from 'redux-saga/effects';
import { Creators as AuthCreators, Types as AuthTypes } from 'store/ducks/auth';
import { goBack, push } from 'connected-react-router';
import { REHYDRATE } from 'redux-persist';
import { Creators as NotificationCreators } from 'store/ducks/app';
import api from 'services/api';

function* getLogin({ payload: { username, password }, redirect }) {
  try {
    yield call(
      api.setHeader,
      'Authorization',
      'b2ZlcnRhcGxheXVzZXI6b2ZlcnRhcGxheXBhc3N3b3Jk'
    );
    const response = yield call(api.post, '/oauth/token', {
      username,
      password,
      grant_type: 'password',
    });
    if (response.status !== 200) {
      yield put(
        NotificationCreators.openNotification({
          message: response.data.msg,
          type: 'error',
        })
      );

      throw response;
    }
    yield call(
      api.setHeader,
      'Authorization',
      `Bearer ${response.data.access_token}`
    );

    yield put(AuthCreators.getAuthSuccess(response.data));

    if (redirect) {
      const redirect_url = JSON.parse(redirect);
      if (redirect_url.back) {
        yield put(goBack());
      } else {
        yield put(push(redirect_url));
      }
      yield put(localStorage.removeItem('@history'));
    }

    yield put(push('/minha-conta'));
    yield put(
      NotificationCreators.openNotification({
        message: 'Login realizado com sucesso',
        type: 'success',
      })
    );
  } catch (err) {
    // yield put(LoginCreators.getLoginFailure(err.data.msg));
  }
}

function* getRefreshToken() {
  const {
    auth: {
      data: { refresh_token },
    },
  } = yield select();
  try {
    yield call(api.setHeader, 'Authorization', `Bearer ${refresh_token}`);
    const response = yield call(api.post, '/oauth/refresh');
    if (response.status !== 200) throw response;
    yield call(
      api.setHeader,
      'Authorization',
      `Bearer ${response.data.access_token}`
    );
    yield call(
      api.setHeader,
      'Authorization',
      `Bearer ${response.data.access_token}`
    );
    yield put(AuthCreators.getLoginRefreshTokenSuccess(response.data));
  } catch (e) {
    if (e.status === 401) {
      yield put(AuthCreators.getLoginRefreshTokenFailure());
      yield call(getLogout);
    }
  }
}

function* getLogout() {
  yield put(AuthCreators.getLogoutSuccess());
  yield put(push('/minha-conta'));
  yield put(
    NotificationCreators.openNotification({
      message: 'Logout realizado com sucesso',
      type: 'success',
    })
  );
}

export function* setApiToken() {
  const { auth } = yield select();
  if (auth.data !== undefined) {
    yield call(
      api.setHeader,
      'Authorization',
      `Bearer ${auth.data.access_token}`
    );
  }
}

export function* callApi(apiCall) {
  const response = yield apiCall;
  if (response.status === 201 || response.status === 200) {
    return response;
  }
  if (response.status === 401) {
    yield put(AuthCreators.getLoginRefreshTokenRequest());
    const action = yield take([
      AuthTypes.GET_REFRESH_TOKEN_SUCCESS,
      AuthTypes.GET_REFRESH_TOKEN_FAILURE,
    ]);
    if (action.type === AuthTypes.GET_REFRESH_TOKEN_FAILURE) {
      throw response;
    }
    const responseTakeTwo = yield apiCall;
    return responseTakeTwo;
  }
  if (response.status === 500) {
    yield put(
      NotificationCreators.openNotification({
        message: response.data.msg,
        type: 'error',
      })
    );
  }
  throw response;
}

export default function* AuthSagas() {
  yield takeLatest(REHYDRATE, setApiToken);
  yield all([
    takeLatest(AuthTypes.GET_REQUEST, getLogin),
    takeLatest(AuthTypes.GET_REFRESH_TOKEN_REQUEST, getRefreshToken),
    takeLatest(AuthTypes.GET_LOGOUT_REQUEST, getLogout),
  ]);
}
