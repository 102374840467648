import { call, takeLatest, all, put } from 'redux-saga/effects';
import api from 'services/api';

import { Types, Creators } from 'store/ducks/admin/reportStore';
import { callApi } from 'store/sagas/auth';

function* getReportSaleStore({ payload }) {
  try {
    const {
      page,
      perPage,
      search,
      orderByColumn,
      orderByDirection,
      dateStart,
      dateEnd,
    } = payload;
    let getters = {
      page,
      search,
      per_page: perPage,
      order: orderByColumn,
      order_by: orderByDirection,
    };
    if (dateStart && dateEnd) {
      getters = {
        ...getters,
        date_start: dateStart,
        date_end: dateEnd,
      };
    }
    const request = call(api.get, '/v1/admin/reports/stores', getters);
    const response = yield call(callApi, request);
    yield put(Creators.getReportSaleStoreSuccess(response.data));
  } catch (err) {
    yield put(Creators.getReportSaleStoreFailure('Erro ao buscar na API'));
  }
}

// Individual exports for testing
export default function* reportSaga() {
  yield all([takeLatest(Types.GET_STORE_REQUEST, getReportSaleStore)]);
}
