import styled from 'styled-components';
import ScrollContainer from 'react-indiana-drag-scroll';
import { Box } from '@material-ui/core';

export const StyledScrollContainer = styled(ScrollContainer)`
  && {
    height: 100%;
    width: 80px;
    margin-top: 10px;
    display: none;

    @media all and (max-width: 500px) {
      display: flex;
      height: 140px;
      width: 96%;
      margin-right: auto;
      margin-left: auto;
    }
  }
`;

export const BoxThumbnail = styled(Box)`
  && {
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    @media all and (max-width: 500px) {
      flex-direction: row;
    }
  }

  img {
    height: 85px;

    @media all and (max-width: 500px) {
      height: 45px;
    }
  }
`;
