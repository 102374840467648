import React from 'react';
import { Box, Button, Typography, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import { InputContainer, InputItem } from 'components/form/StyledComponents';
import * as Yup from 'yup';
import CustomMaskField from 'components/form/CustomMaskField';
import styles from './styles';

export const FormInitialValues = {
  code_post: '',
};

const ConsultCepForm = ({
  onSubmit,
  initialValues,
  addressLoading,
  cepError,
}) => {
  const schema = Yup.object().shape({
    code_post: Yup.string().required('Este campo é obrigatório'),
  });

  const validateCEP = value => {
    let error;
    if (value.replace(/_/g, '').replace(/-/g, '').length < 8) {
      error = 'Complete o CEP';
    }
    return error;
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      validateOnBlur
      render={({ handleSubmit }) => (
        <Box flex="1" display="flex" flexDirection="column">
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <InputContainer>
              <InputItem style={{ margin: 0 }}>
                <Typography style={styles.titleInput}>
                  CEP <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="code_post"
                  label="Informe o CEP"
                  mask="99999-999"
                  validate={validateCEP}
                  component={CustomMaskField}
                />
              </InputItem>
            </InputContainer>
            {cepError && (
              <Box style={{ paddingTop: 5, color: 'red' }}>
                <Typography>{cepError}</Typography>
              </Box>
            )}
            <Button
              data-cy="btn_search_CEP"
              type="submit"
              style={styles.purpleButtonProceed}>
              Consultar CEP
              {addressLoading && (
                <CircularProgress
                  size={15}
                  style={{ color: 'white', marginLeft: 10 }}
                />
              )}
            </Button>
          </Form>
        </Box>
      )}
    />
  );
};

ConsultCepForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  addressLoading: PropTypes.bool.isRequired,
  cepError: PropTypes.any,
};

ConsultCepForm.defaultProps = {
  initialValues: FormInitialValues,
};

export default ConsultCepForm;
