import React from 'react';
import { Typography, Box } from '@material-ui/core';
import notImage from 'images/Products/not_image.jpg';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles, {
  ImageProducts,
  ContainerProducts,
  BoxProduct,
  BoxInfo,
  BoxGrid,
} from './styles';
import FooterList from 'components/FooterList';

const StoreListBox = ({
  data,
  nextPage,
  total,
  page,
  perPage,
  previousPage,
  handlePage,
}) => (
  <Box display="flex" flexDirection="column" flex="1">
    <ContainerProducts
      display="flex"
      flexDirection="column"
      flexWrap="wrap"
      flex="1">
      <BoxGrid>
        {data.map(item => (
          <BoxProduct display="flex" key={item.id} flexDirection="column">
            <Link
              to={`/lojas/${item.slug}`}
              style={{ textDecoration: 'none', color: 'inherit' }}>
              <ImageProducts
                src={
                  Object.keys(item.image).length > 0
                    ? item.image.original
                    : notImage
                }
              />
              <BoxInfo
                display="flex"
                flex="1"
                flexDirection="column"
                alignItems="center"
                justifyContent="center">
                <Typography style={styles.ProductPrice}>{item.name}</Typography>
              </BoxInfo>
            </Link>
          </BoxProduct>
        ))}
      </BoxGrid>
      <Box flex="1" justifyContent="center" alignItems="center">
        <FooterList
          page={page}
          total={total}
          perPage={perPage}
          previousPage={previousPage}
          nextPage={nextPage}
          handlePage={handlePage}
        />
      </Box>
    </ContainerProducts>
  </Box>
);

StoreListBox.propTypes = {
  data: PropTypes.array.isRequired,
};

export default StoreListBox;
