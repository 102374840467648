import { call, takeLatest, all, put, select, take } from 'redux-saga/effects';
import api from 'services/api';
import { Types, Creators } from 'store/ducks/client';
import { Types as AuthTypes, Creators as AuthCreators } from 'store/ducks/auth';
import { callApi } from 'store/sagas/auth';
import { Creators as NotificationCreators } from 'store/ducks/app';
import { setKondutoCustomerId } from 'services/konduto';
import { Creators as ToastCreators } from 'store/ducks/toast';

function* getClient() {
  try {
    const request = call(api.get, '/v1/client');
    const response = yield call(callApi, request);
    yield put(Creators.getClientSuccess(response.data));
    setKondutoCustomerId(response.data.data.id);
  } catch (err) {
    yield put(Creators.getClientFailure('Erro ao buscar na API'));
  }
}

function* getClientImageUpload({ payload }) {
  try {
    const { id, image } = payload;
    const data = new FormData();
    data.append('image', image);
    yield call(api.post, `/v1/client/${id}/images`, data);
    yield put(
      NotificationCreators.openNotification({
        message: 'Imagem alterada com succeso',
        type: 'success',
      })
    );
  } catch (err) {
    yield put(
      NotificationCreators.openNotification({
        message: 'Erro ao atualizar a imagem',
        type: 'error',
      })
    );
  }
}

function* getClientInsert({ payload, func }) {
  try {
    const { personalData, addressData } = payload;
    const response = yield call(api.post, '/v1/client', {
      ...personalData,
      address: addressData,
    });
    if (response.status !== 200 && response.status !== 201) throw response;

    yield put(
      AuthCreators.getAuthRequest({
        username: personalData.email,
        password: personalData.password,
      })
    );

    yield put(Creators.getClientInsertSuccess());
    const action = yield take([AuthTypes.GET_SUCCESS, AuthTypes.GET_FAILURE]);
    if (action.type === AuthTypes.GET_FAILURE) {
      throw response;
    }
    yield call(func);
  } catch (err) {
    if (err.status === 409) {
      yield put(
        NotificationCreators.openNotification({
          message: 'CPF ou email já cadastrado no sistema',
          type: 'error',
        })
      );
    } else
      yield put(
        NotificationCreators.openNotification({
          message: err.data.data.Error || err.data.msg,
          type: 'error',
        })
      );

    yield put(Creators.getClientInsertFailure(err.data.msg));
  }
}

function* getClientUpdate({ payload }) {
  try {
    const { email, password, name, cpf, genre, cell_phone, birth_date } =
      payload;
    const response = yield call(api.put, '/v1/client', {
      email,
      password,
      name,
      cpf,
      genre,
      cell_phone,
      birth_date,
    });
    yield put(Creators.getClientUpdateSuccess(response.data));
    yield put(
      NotificationCreators.openNotification({
        message: 'Edição concluida com sucesso',
        type: 'success',
      })
    );
    document.location.reload(true);
  } catch (err) {
    yield put(Creators.getClientUpdateFailure('Erro ao buscar na API'));
  }
}

function* getClientDelete({ payload }) {
  try {
    const { id } = payload;
    yield call(api.delete, `/v1/clients/${id}`);
    yield put(Creators.getClientDeleteSuccess());
    // Remove a categoria deletada da lista
    const { clientList, clientListTotal } = yield select(state => state.client);

    yield put(
      Creators.getClientListSuccess({
        data: clientList.filter(doc => doc.id !== id),
        total: clientListTotal - 1,
      })
    );
  } catch (err) {
    yield put(Creators.getClientDeleteFailure('Erro ao buscar na API'));
  }
}

function* getClientList({ payload }) {
  try {
    const { page, perPage, search, orderByColumn, orderByDirection } = payload;
    const request = call(api.get, '/v1/client/AllClientesSite', {
      page,
      search,
      per_page: perPage,
      order: orderByColumn,
      order_by: orderByDirection,
    });

    const response = yield call(callApi, request);
    console.log(response.data.data);
    yield put(Creators.getClientListAllSuccess(response.data.data));
  } catch (err) {
    yield put(Creators.getClientListAllFailure('Erro ao buscar na API'));
  }
}
function* getClienteInsertAll({ payload }) {
  try {
    const { name, phone, email } = payload;
    const response = yield call(api.post, '/v1/client/AllClientesSite', {
      name,
      phone,
      email,
    });

    if (response.status !== 201) {
      const errorMessage = response.data.msg?.Error || 'Algo deu errado!';
      throw new Error(errorMessage);
    }

    yield put(Creators.getClientInsertAllSuccess(response.data.data));
    yield put(
      NotificationCreators.openNotification({
        message: 'Parceiro Criado com succeso',
        type: 'success',
      })
    );
  } catch (err) {
    yield put(
      Creators.getClientInsertAllFailure('Erro ao cadastrar colaborador')
    );
    console.log(err);
    yield put(
      NotificationCreators.openNotification({
        message: 'Já existe um usuário com o email ou telefone cadastrado',
        type: 'error',
      })
    );
  }
}

function* getClientForgot({ payload, handleClose }) {
  try {
    const { email } = payload;
    const response = yield call(api.post, '/v1/client/recover', {
      email,
    });
    if (response.status !== 200) throw response;
    yield put(Creators.getClientUpdateSuccess(response.data));
    yield put(
      NotificationCreators.openNotification({
        message: 'Um Link foi enviado para o email',
        type: 'success',
      })
    );
    handleClose();
  } catch (err) {
    yield put(
      NotificationCreators.openNotification({
        message: err.data?.data?.Error || err.data.msg,
        type: 'error',
      })
    );
    yield put(Creators.getClientForgotFailure('Erro ao buscar na API'));
  }
}
function* getClientALLDelete({ payload }) {
  try {
    const { id } = payload;
    const request = call(api.delete, `/v1/client/AllClientesSite/delete/${id}`);
    yield call(callApi, request);
    yield put(Creators.getClientDeleteALLSuccess());
    // Remove a categoria deletada da lista
    const { clientAll, clientAllTotal } = yield select(state => state.client);
    console.log(clientAll.filter(doc => doc.id !== id));
    console.log(clientAllTotal - 1);
    yield put(
      Creators.getClientListAllSuccess({
        data: clientAll.filter(doc => doc.id !== id),
        total: clientAllTotal - 1,
      })
    );
  } catch (err) {
    yield put(Creators.getClientDeleteALLFailure('Erro ao buscar na API'));
  }
}
function* getSendEmailAll({ payload }) {
  try {
    const { id } = payload;
    const request = call(api.post, `/v1/client/SendEmailClienteSite/${id}`);
    yield call(callApi, request);
    yield put(Creators.getSendEmailAllSuccess());
  } catch (err) {
    yield put(Creators.getSendEmailAllFailure('Erro ao buscar na API'));
  }
}
function* getClientALLUpdate({ payload }) {
  try {
    const {
      id,
      address,
      birth_date,
      cpf,
      email,
      genre,
      name,
      cell_phone,
      password,
    } = payload;
    const response = yield call(api.put, `/v1/client/AllClientesSite/${id}`, {
      id,
      address,
      birth_date,
      cpf,
      email,
      genre,
      name,
      cell_phone,
      password,
    });
    if (response.status !== 200) throw response;

    if (response.status === 200) {
      yield put(Creators.getClientALLUpdateSuccess());
      yield put(
        NotificationCreators.openNotification({
          message: 'Colaborador alterado com sucesso',
          type: 'success',
        })
      );
    }
  } catch (err) {
    yield put(Creators.getClientALLUpdateFailure('Erro ao buscar na API'));
    yield put(
      NotificationCreators.openNotification({
        message: 'Erro ao atualizar o colaborador',
        type: 'error',
      })
    );
  }
}
function* getClientALLRestore({ payload }) {
  try {
    const { id } = payload;
    const request = call(api.put, `/v1/client/AllClientesSite/${id}/activate`);
    yield call(callApi, request);
    yield put(Creators.getClientRestoreALLSuccess());
    // Reativa a categoria deletada da lista
    const { clientListDeletedALL, clientListDeletedALLTotal } = yield select(
      state => state.client
    );

    yield put(
      Creators.getClienteDeletedListSuccess({
        data: clientListDeletedALL.filter(doc => doc.id !== id),
        total: clientListDeletedALLTotal - 1,
      })
    );
    yield put(
      ToastCreators.getOpenToast({
        type: 'success',
        title: 'Ativação concluida com sucesso',
        description: '',
      })
    );
  } catch (err) {
    yield put(Creators.getClientRestoreALLFailure('Erro ao buscar na API'));
  }
}
function* getClienteDeletedList({ payload }) {
  try {
    const { page, perPage, search, orderByColumn, orderByDirection, group_id } =
      payload;
    const request = call(api.get, '/v1/client/getDeleteAllClientesSite', {
      page,
      search,
      per_page: perPage,
      order: orderByDirection,
      order_by: orderByColumn,
      group_id,
    });

    const response = yield call(callApi, request);
    if (response.status !== 200 && response.status !== 201) throw response;
    yield put(Creators.getClienteDeletedListSuccess(response.data));
  } catch (err) {
    if (err.status === 404) {
      yield put(
        Creators.getClienteDeletedListSuccess({
          total: 0,
          data: [],
        })
      );
    } else {
      yield put(Creators.getClienteListFailure('Erro ao buscar na API'));
    }
    yield put(
      ToastCreators.getOpenToast({
        type: 'error',
        title: 'Teste',
        description: '',
      })
    );
  }
}
function* getClientById({ payload }) {
  try {
    const { access_token } = payload;
    const response = yield call(
      api.get,
      `/v1/client/AllClientesSite/${access_token}`
    );

    if (response.status !== 200) throw response;

    yield put(Creators.getclientByIDSuccess(response.data));
  } catch (err) {
    yield put(Creators.getclientByIDFailure('Erro ao buscar na API'));
    console.log(err.data.data.Error || err.data.msg);
  }
}

// Individual exports for testing
export default function* clientSaga() {
  yield all([
    takeLatest(Types.GET_ClIENTALLBYID_REQUEST, getClientById),
    takeLatest(Types.GET_LIST_REQUEST, getClient),
    takeLatest(Types.GET_INSERT_REQUEST, getClientInsert),
    takeLatest(Types.GET_UPDATE_REQUEST, getClientUpdate),
    takeLatest(Types.GET_DELETE_REQUEST, getClientDelete),
    takeLatest(Types.GET_DELETEALL_REQUEST, getClientALLDelete),
    takeLatest(Types.GET_SENDEMAILALL_REQUEST, getSendEmailAll),
    takeLatest(Types.GET_LISTALL_REQUEST, getClientList),
    takeLatest(Types.GET_INSERTUSERALL_REQUEST, getClienteInsertAll),
    takeLatest(Types.GET_FORGOT_REQUEST, getClientForgot),
    takeLatest(Types.GET_UPDATEALL_REQUEST, getClientALLUpdate),
    takeLatest(Types.GET_RESTOREALL_REQUEST, getClientALLRestore),
    takeLatest(Types.GET_UPLOAD_PROFILE_REQUEST, getClientImageUpload),
    takeLatest(Types.GET_LIST_DELETEDALL_REQUEST, getClienteDeletedList),
  ]);
}
