import styled from 'styled-components';
import { Box } from '@material-ui/core';
import appColors from 'utils/appColors';

export const BoxCountDown = styled(Box)`
  display: flex;
  flex-direction: row;
  margin-right: 20px;
`;

export const BoxPrinter = styled(Box)`
  display: flex;
  justify-content: flex-start;
  padding: 10px;
`;

export const PriceContainer = styled(Box)`
  padding: 8px 0px;
  && {
    @media all and (max-width: 500px) {
      display: none;
    }
  }
`;

export const DateContainer = styled(Box)`
  && {
  }
`;

export const StatusContainerStyled = styled(Box)`
  && {
    @media all and (max-width: 500px) {
      display: none;
    }
  }
`;

export const OrderContainer = styled(Box)`
  && {
    @media all and (max-width: 500px) {
      display: none;
    }
  }
`;

const styles = {
  containerItem: {
    borderRadius: 10,
    boxShadow: '0px 2px 10px rgba(0,0,0,0.25)',
  },
  headerPurple: {
    color: '#777',
    textTransform: 'uppercase',
    fontSize: 13,
  },
  fontBold: {
    fontWeight: 'bold',
    marginLeft: 8,
    color: appColors.PRIMARY_COLOR,
  },
  status: {
    fontWeight: 'bold',
    color: 'white',
  },
  containerStatus: {
    backgroundColor: appColors.PRIMARY_COLOR,
    padding: 8,
    borderRadius: 5,
  },
  iconExpand: {
    color: appColors.PRIMARY_COLOR,
    cursor: 'pointer',
  },
  purpleButton: {
    backgroundColor: appColors.PRIMARY_COLOR,
    color: 'white',
    marginLeft: 10,
  },
};

export default styles;
