/* eslint-disable no-duplicate-case */
/*
 *
 * Home reducer
 *
 */

export const Types = {
  // Obtem dados página home
  GET_REQUEST: 'home/GET_REQUEST',
  GET_SUCCESS: 'home/GET_SUCCESS',
  GET_FAILURE: 'home/GET_FAILURE',

};

export const initialState = {

  // Lista página home banner e categorias
  home: {},
  homeListLoading: false,
  homeListError: null,

};

export default (state = initialState, action) => {
  switch (action.type) {
    // Lista página home banner e categorias
    case Types.GET_REQUEST:
      return { ...state, homeLoading: true };
    case Types.GET_SUCCESS:
      return {
        ...state,
        home: action.payload,
        homeLoading: false,
        homeError: null,
      };
    case Types.GET_FAILURE:
      return {
        ...state,
        homeLoading: false,
        homeError: action.payload,
      };
    default:
      return state;
  }
};

export const Creators = {
  // Lista página home banner e categorias
  getHomeRequest: () => ({
    type: Types.GET_REQUEST,
  }),
  getHomeSuccess: ({ data }) => ({
    type: Types.GET_SUCCESS,
    payload: data,
  }),
  getHomeFailure: error => ({
    type: Types.GET_FAILURE,
    payload: error,
  }),
};
