import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import appColors from 'utils/appColors';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  step: {
    '&$completed': {
      color: '#37ce23',
    },
    '&$active': {
      color: appColors.PRIMARY_COLOR,
    },
    '&$selected': {
      color: appColors.PRIMARY_COLOR,
    },
  },
  active: {},
  completed: {},
  disabled: {},
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const CustomStepper = ({ steps, activeStep, onSelect = () => {} }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label} onClick={() => onSelect(index)}>
            <StepLabel
              StepIconProps={{
                classes: {
                  root: classes.step,
                  active: classes.active,
                  completed: classes.completed,
                },
              }}>
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default CustomStepper;
