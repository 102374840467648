import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as WalletCreators } from 'store/ducks/admin/wallet';
import { Creators as DashboardCreators } from 'store/ducks/admin/dashboard';
import { Creators as ClientCreators } from 'store/ducks/client';
import OrderListPageClient from 'components/Pages/ListPageClient';
import ListPageReseller from 'components/Pages/ListPageReseller';
import { Grid, Typography } from '@material-ui/core';
import DashboardPage from 'components/DashboardPage';
import appColors from 'utils/appColors';
import StaticPanelCountUsersDashboard from 'components/StaticPanelCountUsersDashboard';
import { Box } from '@chakra-ui/react';

const ShopperMenuPage = ({ handleTab }) => {
  const dispatch = useDispatch();

  const { wallet, walletLoading } = useSelector(state => state.admin.wallet);
  const { dashboard, dashboardLoading } = useSelector(
    state => state.admin.dashboard
  );
  const { client } = useSelector(state => state.client);

  React.useEffect(() => {
    dispatch(WalletCreators.getWalletRequest());
    dispatch(DashboardCreators.getDashboardRequest());
    dispatch(ClientCreators.getClientRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Typography
        style={{
          fontSize: 30,
          marginLeft: 10,
          color: '#606060',
          fontWeight: 'bold',
        }}>
        Olá,{' '}
        <span
          data-cy="name_user_logged_top"
          style={{ color: appColors.PRIMARY_COLOR }}>
          {client.name}
        </span>
      </Typography>
      <DashboardPage
        dashboard={dashboard}
        client={client}
        handleTab={handleTab}
        dashboardLoading={dashboardLoading}
        wallet={wallet}
        walletLoading={walletLoading}
      />

      {client.group.id === 3 && <OrderListPageClient handleTab={handleTab} />}
      {client.group.id === 4 && (
        <Grid style={{ marginTop: 30, marginBottom: 30 }} container>
          <Grid
            item
            sm={dashboard && dashboard.clients ? 8 : 12}
            style={{
              marginBottom: 20,
              display: 'flex',
              flexDirection: 'column',
            }}>
            <ListPageReseller handleTab={handleTab} />
          </Grid>
          {dashboard && dashboard.clients && (
            <Grid
              item
              xs={12}
              sm={4}
              style={{ marginBottom: 30, marginTop: 82 }}>
              <StaticPanelCountUsersDashboard
                title="Clientes Cadastrados"
                description={`${dashboard.clients}`}
                Link="/minha-conta?aba=cash_express"
              />
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  );
};

export default ShopperMenuPage;
