import React from 'react';
import { Box } from '@material-ui/core';
import ConfimationEmailRegister from 'components/Pages/ConfirmationEmailRegister';

const RegisterSucess = () => {
  return (
    <Box display="flex" justifyContent="center" flexDirection="column" flex="1">
      <ConfimationEmailRegister />
    </Box>
  );
};

export default RegisterSucess;
