/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-useless-concat */
import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as WalletCreators } from 'store/ducks/admin/wallet';
import StatisticItemNoIcon from 'components/StaticItemNoIcon';
import StatisticItemPanelDashboard from 'components/StatisticItemPanelDashboard';
import StatisticItemPanelDashboardNoIcon from 'components/StatisticItemPanelDashboardNoIcon';
import PropTypes from 'prop-types';
import { toPrice } from 'utils/converters';
import appColors from 'utils/appColors';

import { Stack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const SaleInfo = ({
  dashboard,
  dashboardLoading,
  wallet,
  client,
  handleTab,
}) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(WalletCreators.getWalletDocumentsRequest());
  }, []);
  const {
    admin: {
      wallet: { walletDocuments },
    },
  } = useSelector(state => state);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (
      (typeof walletDocuments[0]?.status !== 'undefined' &&
        walletDocuments[0]?.status !== 'APPROVED') ||
      (typeof walletDocuments[1]?.status !== 'undefined' &&
        walletDocuments[1]?.status !== 'APPROVED')
    ) {
      setOpen(true);
    }
  }, []);

  return (
    <Stack pt="30px">
      {open && (
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
              Sua loja tem documentos pendentes
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Envie os documentos restantes para que possa ser aprovada e seus
                produtos e funcionalidades estejam funcionando!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Fechar
              </Button>
              <Link to="/minha-conta?aba=documents">
                <Button color="primary" autoFocus>
                  Ir para documentos
                </Button>
              </Link>
            </DialogActions>
          </Dialog>
        </div>
      )}

      {!dashboardLoading && dashboard && wallet ? (
        <>
          {client.group.id === 3 && (
            <Stack spacing="20px" pb="30px">
              <StatisticItemNoIcon
                client={client}
                title="RESUMO DO DIA"
                descriptionOne={
                  `${dashboard?.today !== null ? dashboard?.today?.sales : 0}` +
                  `${dashboard?.today?.sales > 1 ? ' PEDIDOS' : ' PEDIDO'}`
                }
                descriptionTwo={`R$ ${toPrice(
                  dashboard?.cash !== null ? dashboard?.today?.cash : 0
                )}`}
              />
              <Stack direction={['column', 'row']} spacing="20px">
                <StatisticItemPanelDashboard
                  icon="attach_money"
                  title="SALDO"
                  description={
                    Object.keys(wallet).length > 0
                      ? `R$ ${toPrice(wallet?.current)}`
                      : `R$ 0`
                  }
                  urlRedirect={'/minha-conta?aba=cash_express'}
                  descriptionButton="Transferir saldo para sua conta bancária"
                  labelButton="Conta Bancária"
                />

                {/* <StatisticItemPanelDashboard
                  icon="monetization_on"
                  title="PRÓXIMAS LIBERAÇÕES"
                  description={`R$ ${toPrice(
                    dashboard?.cash != null ? dashboard?.cash?.future : 0
                  )}`}
                  urlRedirect={() => handleTab(24)}
                  descriptionButton="Faça agora a sua antecipação"
                  labelButton=""
                /> */}
              </Stack>
            </Stack>
          )}

          {client.group.id === 4 && (
            <Stack spacing="20px">
              <Stack direction={['column', 'row']} spacing="20px">
                <StatisticItemNoIcon
                  client={client}
                  title="Resumo do Dia"
                  descriptionOne={`${dashboard?.today?.sales}` + ' pedido(s)'}
                  descriptionTwo={`R$ ${toPrice(dashboard?.today?.cash)}`}
                />

                <StatisticItemPanelDashboardNoIcon
                  title="Meu Código"
                  description={`#${dashboard.code_promo}`}
                />
              </Stack>

              <Stack direction={['column', 'row']} spacing="20px">
                <StatisticItemPanelDashboard
                  icon="attach_money"
                  title="SALDO"
                  description={`R$ ${toPrice(dashboard?.cash?.avaliable)}`}
                  urlRedirect={() => {
                    handleTab(21);
                  }}
                  descriptionButton="Transferir saldo para sua"
                  labelButton="Conta Bancária"
                />

                {/* <StatisticItemPanelDashboard
                  icon="monetization_on"
                  title="PRÓXIMAS LIBERAÇÕES"
                  description={`R$ ${toPrice(dashboard?.cash?.future)}`}
                  urlRedirect={() => {
                    handleTab(24);
                  }}
                  descriptionButton="Faça sua antecipação"
                  labelButton="Antecipação"
                /> */}
              </Stack>
            </Stack>
          )}
        </>
      ) : (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px',
          }}>
          <CircularProgress style={{ color: appColors.PRIMARY_COLOR }} />
        </div>
      )}
    </Stack>
  );
};

SaleInfo.propTypes = {
  dashboard: PropTypes.oneOfType([PropTypes.object]).isRequired,
  dashboardLoading: PropTypes.bool.isRequired,
};

export default SaleInfo;
