const MUIDatatablesTranslations = {
  pagination: {
    labelDisplayedRows: '{from}-{to} de {count}',
    labelRowsSelect: 'linhas',
    labelRowsPerPage: 'Linhas por página:',
    firstTooltip: 'Primeira Página',
    previousTooltip: 'Página Anterior',
    nextTooltip: 'Próxima Página',
    lastTooltip: 'Última página',
    firstAriaLabel: 'Primeira página',
    lastAriaLabel: 'Ultima página',
    nextAriaLabel: 'Próxima página',
    previousAriaLabel: 'Página Anterior',
    displayRows: 'de',
  },
  toolbar: {
    nRowsSelected: '{0} linha(s) selecionada(s)',
    searchTooltip: 'Buscar',
    searchPlaceholder: 'Informe uma palavra-chave',
    downloadCsv: 'Download CSV',
    print: 'Imprimir',
    viewColumns: 'Visualizar Colunas',
    filterTable: 'Filtrar Tabela',
  },
  header: {
    actions: 'Ações',
  },
  body: {
    emptyDataSourceMessage: 'Não registros para exibir',
    toolTip: 'Ordenar',
    filterRow: {
      filterTooltip: 'Filtrar',
    },
  },
  filter: {
    all: 'Todos',
    title: 'FILTROS',
    reset: 'LIMPAR',
  },
  viewColumns: {
    title: 'Mostrar Colunas',
    titleAria: 'Mostrar/Ocultar Colunas da Tabela',
  },
  selectedRows: {
    text: 'linhas(s) selecionada',
    delete: 'Remover',
    deleteAria: 'Remover linhas selecionadas',
  },
};

export { MUIDatatablesTranslations };
