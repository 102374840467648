/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React from 'react';
import PageBase from 'components/PageBase';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as CollaboratorCreators } from 'store/ducks/collaborator';
import { Creators as UserCreators } from 'store/ducks/admin/user';
import HeaderComponent from 'components/HeaderComponent';
import CollaboratorForm from 'components/Pages/PartnerPage/CollaboratorForm';
import { push } from 'connected-react-router';
import { Card, Typography } from '@material-ui/core';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import HeaderBoxItem from 'components/HeaderComponent/HeaderBoxItem';
import { Link } from 'react-router-dom';
import HeaderButton from 'components/HeaderComponent/HeaderButton';
import useQuery from 'hooks/useQuery';
import { Creators as AddressCreators } from 'store/ducks/address';

const TabContainer = ({ children }) => {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
};

const CollaboratorUpdatePage = () => {
  const { user, userLoading, userUpdateLoading } = useSelector(
    state => state.admin.user
  );
  const { collaboratorById } = useSelector(state => state.collaborator);

  const query = useQuery();
  const dispatch = useDispatch();
  const isUpdate = query.get('id_collaborator') !== null;
  const params = query.get('id_collaborator');
  const [localState, setLocalState] = React.useState(null);

  const onSubmitCEP = values => {
    dispatch(AddressCreators.getAddressCEPRequest(values));
  };

  React.useEffect(() => {
    if (isUpdate) {
      dispatch(
        UserCreators.getUserRequest({ id: query.get('id_collaborator') })
      );

      setLocalState(null);
    }
  }, [dispatch, params]);

  React.useEffect(() => {
    dispatch(
      CollaboratorCreators.getColllaboratorByIDRequest({
        id: query.get('id_collaborator'),
      })
    );
  }, [dispatch]);

  React.useEffect(() => {
    if (
      Object.keys(user).length > 0 &&
      !userLoading &&
      user.id === Number(params) &&
      Object.keys(collaboratorById).length > 0
    ) {
      let dateInverse = 'indefinido';

      dateInverse = user?.birth_date?.replace(/[^\d]+/g, '-');
      dateInverse = dateInverse.split('-');
      dateInverse =
        dateInverse[2] + '/' + dateInverse[1] + '/' + dateInverse[0];

      setLocalState({
        id: user.id,
        address: user.address,
        name: user.name,
        phone: user.cell_phone,
        email: user.email,
        status: user.status,
        genre: user.genre,
        birth_date: dateInverse,
        cpf: user.cpf,
        role: collaboratorById?.data?.role,
        partner_id: collaboratorById?.data?.partner_id,
      });
    }
  }, [dispatch, user, params, userLoading]);

  const onSubmit = data => {
    const copyData = {};

    copyData.birth_date = data.birth_date.split('');
    copyData.birth_date = copyData.birth_date.join('');
    const dateInverse = copyData.birth_date.split('/');
    copyData.birth_date = `${dateInverse[2]}-${dateInverse[1]}-${dateInverse[0]}`;

    copyData.id = data.id;
    copyData.name = data.name;
    copyData.address = data.address;
    copyData.email = data.email;
    copyData.cpf = data.cpf;
    copyData.genre = data.genre;
    copyData.phone = data.phone;
    copyData.password = data.password;
    copyData.role = data.role;
    copyData.status = data.status;

    dispatch(CollaboratorCreators.getCollaboratorUpdateRequest(copyData));
  };
  const handleBack = () => {
    dispatch(push(`/minha-conta?aba=Clientes`));
  };

  return (
    <PageBase>
      <HeaderComponent title="Atualizar Colaborador">
        <HeaderBoxItem justify="center" style={{ paddingLeft: 15 }}>
          <Link to="/minha-conta?aba=Clientes">
            <HeaderButton icon="list">Listar Colaboradores</HeaderButton>
          </Link>
        </HeaderBoxItem>
      </HeaderComponent>
      <Card style={{ marginTop: 20 }}>
        <TabContainer>
          {localState && (
            <CollaboratorForm
              initialValues={localState}
              onSubmit={onSubmit}
              onSubmitCEP={onSubmitCEP}
              handleBack={handleBack}
              isLoading={userUpdateLoading}
              update
            />
          )}
        </TabContainer>
      </Card>
    </PageBase>
  );
};

CollaboratorUpdatePage.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withRouter(CollaboratorUpdatePage);
