/* eslint-disable no-undef */
// @flow
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Typography, Box, Button } from '@material-ui/core';
// import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
// import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
// import CancelIcon from '@material-ui/icons/Cancel';
import appUtils from 'utils/appUtils';
import styles, {
  StyleServicesGroup,
  StyleServicesGroupMobile,
  StyleServicesGroupMobile2,
} from './styles';
import ReactMarkdown from 'react-markdown/with-html';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ServicesGroup = () => {
  const [openDescriptions, setOpenDescriptions] = useState(new Set());
  const { manpower: manpowerList }: { list: ManpowerInCart[] } = useSelector(
    state => state.checkout.cart
  );
  const toggleDescription = serviceId => {
    const newOpenDescriptions = new Set(openDescriptions);
    if (newOpenDescriptions.has(serviceId)) {
      newOpenDescriptions.delete(serviceId);
    } else {
      newOpenDescriptions.add(serviceId);
    }
    setOpenDescriptions(newOpenDescriptions);
  };

  return (
    <>
      {manpowerList &&
        manpowerList?.map((service, index) => (
          <>
            <StyleServicesGroup
              display="flex"
              flex="1"
              flexDirection="row"
              last={index === Number(manpowerList.length - 1)}>
              <Link
                // to={`produto/${productValues.product.slug}`}
                style={{ textDecoration: 'none', color: 'inherit' }}>
                <Box>
                  <img
                    alt=""
                    src={
                      'https://icones.pro/wp-content/uploads/2022/02/icone-de-service-orange.png'
                    }
                    width="70"
                    style={{ marginRight: 20 }}
                  />
                </Box>
              </Link>
              <Box
                display="flex"
                flex="1"
                alignItems="flex-start"
                flexDirection="column"
                style={{ minWidth: 200 }}>
                <Link
                  // to={`produto/${productValues.product.slug}`}
                  style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Typography
                    data-cy="name_service_badge"
                    style={styles.titleProduct}>
                    {service.title}
                  </Typography>
                </Link>

                <Box style={{ minWidth: '500px' }}>
                  {openDescriptions.has(service.id) && (
                    <Box style={{ minWidth: '150px', maxWidth: '500px' }}>
                      <p>
                        <span style={{ fontWeight: 'bold' }}>Descrição:</span>
                        <ReactMarkdown
                          style={styles.subTitle}
                          source={service.description}
                        />
                      </p>
                    </Box>
                  )}
                </Box>

                <Button
                  style={styles.descriptionButton}
                  variant="contained"
                  onClick={() => toggleDescription(service.id)}>
                  {openDescriptions.has(service.id) ? 'Ver menos' : 'Ver mais'}
                </Button>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column">
                <Box display="flex" flexDirection="row" alignItems="center">
                  <b>{appUtils.formatPrice(service?.price)}</b>
                </Box>
              </Box>

              {/* <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-end">
                <CancelIcon
                  style={styles.iconsAddRemove}
                  // onClick={onClickRemoveGoup}
                  fontSize="large"
                />
              </Box> */}
            </StyleServicesGroup>

            <StyleServicesGroupMobile
              display="flex"
              flex="1"
              flexDirection="column"
              last={index === Number(manpowerList.length - 1)}>
              <Box
                flex="1"
                display="flex"
                justifyContent="flex-start"
                style={{ marginBottom: 20 }}>
                <Box>
                  <img
                    alt=""
                    src={
                      'https://icones.pro/wp-content/uploads/2022/02/icone-de-service-orange.png'
                    }
                    width="70"
                    style={{ marginRight: 20 }}
                  />
                </Box>
                <Box
                  display="flex"
                  flex="1"
                  alignItems="flex-start"
                  flexDirection="column"
                  style={{ minWidth: 100 }}>
                  <Typography style={styles.titleProduct}>
                    {service.title}
                  </Typography>

                  {openDescriptions.has(service.id) && (
                    <StyleServicesGroupMobile2>
                      <Box style={{ minWidth: '150px' }}>
                        <p>
                          Descrição:
                          <ReactMarkdown
                            style={styles.subTitle}
                            source={service.description}
                          />
                        </p>
                      </Box>
                    </StyleServicesGroupMobile2>
                  )}

                  <Box
                    width="90%"
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end">
                    <Button
                      style={styles.descriptionButton}
                      variant="contained"
                      onClick={() => toggleDescription(service.id)}>
                      {openDescriptions.has(service.id)
                        ? 'Ver menos'
                        : 'Ver mais'}
                    </Button>
                  </Box>

                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end">
                    {/* <RemoveCircleOutlineIcon
                        // onClick={onClickRemove}
                        style={styles.iconsAddRemove}
                        fontSize="large"
                      />
                      <Typography>{1}</Typography>
                      <AddCircleOutlineIcon
                        // onClick={onClickAdd}
                        style={styles.iconsAddRemove}
                        fontSize="large"
                      /> */}
                    <Typography>
                      <b style={{ color: 'grey', fontSize: 18 }}>
                        {appUtils.formatPrice(service?.price)}
                      </b>
                      <br />
                    </Typography>
                  </Box>
                </Box>
                {/* <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="flex-end">
                  <CancelIcon
                    // onClick={onClickRemoveGoup}
                    style={styles.iconsAddRemove}
                    fontSize="large"
                  />
                </Box> */}
              </Box>
            </StyleServicesGroupMobile>
          </>
        ))}
    </>
  );
};

export default ServicesGroup;
