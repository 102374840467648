import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import appColors from 'utils/appColors';
import { Link } from 'react-router-dom';
import CustomStepper from 'components/Stepper';
import { BoxContainer } from './styles';
import { useSelector } from 'react-redux';

const HeaderMenu = ({ headerStep }) => {
  const { image } = useSelector(state => state.config.config.shopping || {});
  const steps = ['Identificação', 'Pagamento', 'Confirmação'];

  return (
    <AppBar position="static" style={{ flex: 1 }}>
      <Toolbar
        style={{ display: 'flex', backgroundColor: appColors.PRIMARY_COLOR }}>
        <BoxContainer>
          {image && image.small && (
            <div
              style={{
                backgroundColor: 'white',
                padding: '8px 20px',
                borderRadius: 5,
              }}>
              <Link to="/">
                <img alt="" src={image.small} height={25} />
              </Link>
            </div>
          )}
          <CustomStepper steps={steps} activeStep={headerStep} />
        </BoxContainer>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderMenu;
