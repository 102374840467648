/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-case-declarations */
import { Types as AuthTypes } from 'store/ducks/auth';

export const Types = {
  // Obtem catergorias
  GET_REQUEST: 'order-admin/GET_REQUEST',
  GET_SUCCESS: 'order-admin/GET_SUCCESS',
  GET_FAILURE: 'order-admin/GET_FAILURE',

  // WEBSOCKET
  GET_WS_ORDER_REQUEST: 'admin/GET_WS_ORDER_REQUEST',
  GET_WEBSOCKET_ORDER_SUCCESS: 'admin/GET_WEBSOCKET_ORDER_SUCCESS',
  GET_WEBSOCKET_ORDER_UPDATE: 'admin/GET_WEBSOCKET_ORDER_UPDATE',

  // atualiza status do pedido
  GET_UPDATE_STATUS_REQUEST: 'order-admin/GET_UPDATE_STATUS_REQUEST',
  GET_UPDATE_STATUS_SUCCESS: 'order-admin/GET_UPDATE_STATUS_SUCCESS',
  GET_UPDATE_STATUS_FAILURE: 'order-admin/GET_UPDATE_STATUS_FAILURE',

  // Obtem catergorias
  GET_LIST_REQUEST: 'order-admin/GET_LIST_REQUEST',
  GET_LIST_SUCCESS: 'order-admin/GET_LIST_SUCCESS',
  GET_LIST_FAILURE: 'order-admin/GET_LIST_FAILURE',
  GET_LIST_REQUEST_WITHOUT_LOADING:
    'order-admin/GET_LIST_REQUEST_WITHOUT_LOADING',

  // Insere uma catergoria
  GET_INSERT_REQUEST: 'order-admin/GET_INSERT_REQUEST',
  GET_INSERT_SUCCESS: 'order-admin/GET_INSERT_SUCCESS',
  GET_INSERT_FAILURE: 'order-admin/GET_INSERT_FAILURE',

  // Atualiza uma catergoria
  GET_UPDATE_REQUEST: 'order-admin/GET_UPDATE_REQUEST',
  GET_UPDATE_SUCCESS: 'order-admin/GET_UPDATE_SUCCESS',
  GET_UPDATE_FAILURE: 'order-admin/GET_UPDATE_FAILURE',

  // Deleta uma catergoria
  GET_DELETE_REQUEST: 'order-admin/GET_DELETE_REQUEST',
  GET_DELETE_SUCCESS: 'order-admin/GET_DELETE_SUCCESS',
  GET_DELETE_FAILURE: 'order-admin/GET_DELETE_FAILURE',

  // Imprimir pedido
  GET_PRINT_REQUEST: 'order-admin/GET_PRINT_REQUEST',

  // altera somente status
  GET_WEBSOCKET_STATUS_UPDATE: 'admin/GET_WEBSOCKET_STATUS_UPDATE',
};

export const initialState = {
  // Categoria por id
  order: {},
  orderLoading: false,
  orderError: null,
  orderId: null,
  // Lista de pedidos
  orderList: [],
  orderListLoading: false,
  orderListError: null,
  orderListTotal: 0,
  orderSummary: { total: 0, price: 0 },
  // Insere uma pedido
  orderInsertLoading: false,
  orderInsertError: false,
  // Atualiza uma pedido
  orderUpdateLoading: false,
  orderUpdateError: false,
  // Atualiza o status de um pedido
  orderStatusUpdateLoading: false,
  orderStatusUpdateError: false,
  // Deleta pedido
  orderDeleteLoading: false,
  orderDeleteError: null,
  // Imprimir pedido
  orderPrint: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Categoria por id
    case Types.GET_REQUEST:
      return {
        ...state,
        order: {},
        orderError: null,
        orderLoading: true,
        orderId: action.payload,
      };
    case Types.GET_SUCCESS:
      return {
        ...state,
        order: action.payload.data,
        orderLoading: false,
        orderError: null,
      };
    case Types.GET_FAILURE:
      return {
        ...state,
        orderLoading: false,
        orderError: action.payload,
      };
    // atualiza o status de um pedido
    case Types.GET_UPDATE_STATUS_REQUEST:
      return {
        ...state,
        orderStatusUpdateLoading: true,
        orderStatusUpdateError: action.payload,
      };
    case Types.GET_UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        order: action.payload.data,
        orderList: state.orderList.map(item =>
          item.id === action.payload.data.id ? action.payload.data : item
        ),
        orderStatusUpdateLoading: false,
        orderStatusUpdateError: null,
      };
    case Types.GET_UPDATE_STATUS_FAILURE:
      return {
        ...state,
        orderStatusUpdateLoading: false,
        orderStatusUpdateError: action.payload,
      };
    // Lista de pedidos
    case Types.GET_LIST_REQUEST:
      return { ...state, orderListLoading: true };

    case Types.GET_LIST_REQUEST_WITHOUT_LOADING:
      return { ...state, orderListLoading: false };

    case Types.GET_LIST_SUCCESS:
      return (
        {
          orderList: [],
        },
        {
          ...state,
          orderList: action.payload.data,
          orderSummary: action.payload.summary,
          orderListLoading: false,
          orderListError: null,
          orderListTotal: action.payload.total,
        }
      );

    case Types.GET_WEBSOCKET_ORDER_SUCCESS:
      const data = [...state.orderList, action.payload];
      //var data = _.orderBy(newData, 'name', 'desc');

      return {
        ...state,
        orderList: data,
        orderListTotal: state.orderListTotal + 1,
        orderSummary: state.orderSummary + action.payload.price,
      };

    // shopper order list
    case Types.GET_WEBSOCKET_ORDER_UPDATE:
      const orderListWS = state.orderList.map(item =>
        item.id === action.payload.id ? action.payload : item
      );
      return {
        ...state,
        orderList: orderListWS,
      };

    case Types.GET_WEBSOCKET_STATUS_UPDATE:
      return {
        ...state,
        orderList: !!action.payload.price
          ? [
              action.payload,
              ...state.orderList.filter(item => item.id !== action.payload.id),
            ]
          : state.orderList.map(item => {
              if (item.id === action.payload.id) {
                const newItem = item;
                newItem.status = action.payload.status;
                newItem.stores[0].status = action.payload.status;
                return newItem;
              }
              return item;
            }),
      };

    case Types.GET_LIST_FAILURE:
      return {
        ...state,
        orderListLoading: false,
        orderListError: action.payload,
      };
    case Types.GET_INSERT_REQUEST:
      return {
        ...state,
        orderInsertLoading: true,
        orderInsertError: null,
      };
    case Types.GET_INSERT_SUCCESS:
      return {
        ...state,
        orderInsertLoading: false,
        orderInsertError: null,
      };
    case Types.GET_INSERT_FAILURE:
      return {
        ...state,
        orderInsertLoading: false,
        orderInsertError: action.payload,
      };
    // Atualiza um pedido
    case Types.GET_UPDATE_REQUEST:
      return {
        ...state,
        orderUpdateLoading: true,
        orderUpdateError: null,
      };
    case Types.GET_UPDATE_SUCCESS:
      return {
        ...state,
        orderUpdateLoading: false,
        orderUpdateError: null,
      };
    case Types.GET_UPDATE_FAILURE:
      return {
        ...state,
        orderUpdateLoading: false,
        orderUpdateError: action.payload,
      };
    // Deleta uma pedido
    case Types.GET_DELETE_REQUEST:
      return {
        ...state,
        orderDeleteLoading: true,
        orderDeleteError: null,
      };
    case Types.GET_DELETE_SUCCESS:
      return {
        ...state,
        orderDeleteLoading: false,
        orderDeleteError: null,
      };
    case Types.GET_DELETE_FAILURE:
      return {
        ...state,
        orderDeleteLoading: false,
        orderDeleteError: action.payload,
      };

    case AuthTypes.GET_LOGOUT_SUCCESS:
      return initialState;

    case Types.GET_PRINT_REQUEST:
      return {
        ...state,
        orderPrint: action.payload,
      };
    default:
      return state;
  }
};

export const Creators = {
  // Busca uma pedido
  getOrderRequest: ({ id }) => ({
    type: Types.GET_REQUEST,
    payload: { id },
  }),
  getOrderSuccess: ({ data }) => ({
    type: Types.GET_SUCCESS,
    payload: { data },
  }),
  getOrderFailure: error => ({
    type: Types.GET_FAILURE,
    payload: error,
  }),
  getUpdateStatusRequest: ({ id, status, hive_number, print = null }) => ({
    type: Types.GET_UPDATE_STATUS_REQUEST,
    payload: { id, status, hive_number, print },
  }),
  getUpdateStatusSuccess: ({ data }) => ({
    type: Types.GET_UPDATE_STATUS_SUCCESS,
    payload: { data },
  }),
  getUpdateStatusFailure: error => ({
    type: Types.GET_UPDATE_STATUS_FAILURE,
    payload: error,
  }),

  // Insere uma pedido
  getOrderInsertRequest: ({
    order_father,
    description,
    name,
    order_position,
  }) => ({
    type: Types.GET_INSERT_REQUEST,
    payload: { order_father, description, name, order_position },
  }),
  getOrderInsertSuccess: () => ({
    type: Types.GET_INSERT_SUCCESS,
  }),
  getOrderInsertFailure: error => ({
    type: Types.GET_INSERT_FAILURE,
    payload: error,
  }),
  // Busca lista de pedidos
  getOrderListRequest: data => ({
    type: Types.GET_LIST_REQUEST,
    payload: data,
  }),

  getOrderListRequestWithOutLoading: data => ({
    type: Types.GET_LIST_REQUEST_WITHOUT_LOADING,
    payload: data,
  }),

  getOrderListSuccess: ({ data, total, summary }) => ({
    type: Types.GET_LIST_SUCCESS,
    payload: { data, total, summary },
  }),
  getOrderListFailure: error => ({
    type: Types.GET_LIST_FAILURE,
    payload: error,
  }),
  // Atualiza uma  Categoria
  // Insere uma pedido
  getOrderUpdateRequest: ({
    id,
    order_father,
    description,
    name,
    order_position,
  }) => ({
    type: Types.GET_UPDATE_REQUEST,
    payload: { id, order_father, description, name, order_position },
  }),
  getOrderUpdateSuccess: () => ({
    type: Types.GET_UPDATE_SUCCESS,
  }),
  getOrderUpdateFailure: error => ({
    type: Types.GET_UPDATE_FAILURE,
    payload: error,
  }),
  // Deleta uma pedido
  getOrderDeleteRequest: id => ({
    type: Types.GET_DELETE_REQUEST,
    payload: { id },
  }),
  getOrderDeleteSuccess: () => ({
    type: Types.GET_DELETE_SUCCESS,
  }),
  getOrderDeleteFailure: error => ({
    type: Types.GET_DELETE_FAILURE,
    payload: error,
  }),
  getOrderListWSSuccess: payload => ({
    type: Types.GET_WEBSOCKET_ORDER_SUCCESS,
    payload,
  }),
  getUpdateListSuccess: payload => ({
    type: Types.GET_WEBSOCKET_ORDER_UPDATE,
    payload,
  }),
  getPrintRequest: payload => ({
    type: Types.GET_PRINT_REQUEST,
    payload,
  }),
  getOrderWSStatusUpdated: payload => ({
    type: Types.GET_WEBSOCKET_STATUS_UPDATE,
    payload,
  }),
};
