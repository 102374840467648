import styled from 'styled-components';
import { Box } from '@material-ui/core';
import appColors from 'utils/appColors';

export const StyleProductsGroup = styled(Box)`
  && {
    border-bottom: ${props => (props.last ? 'none' : '2px solid #80808070')};
    //max-height: 100px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: -2px;
    max-width: 950px;

    @media all and (max-width: 799px) {
      display: none;
    }
  }
`;

export const StyleProductsGroupMobile = styled(Box)`
  && {
    border-bottom: ${props => (props.last ? 'none' : '2px solid #c9c9c9')};
    /* padding-top: 20px; */
    /* padding-bottom: 20px; */
    padding-left: 10px;
    margin-top: -2px;

    @media all and (min-width: 800px) {
      display: none;
    }
  }
`;

export const StyleProductsGroupMobile2 = styled(Box)`
  && {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    margin-top: -2px;

    @media all and (min-width: 800px) {
      display: none;
    }
  }
`;

export const BoxColors = styled(Box)`
  width: 14px;
  height: 14px;
  margin-right: 5px;
  margin-left: 5px;

  border-radius: 10px;
  border: 1px solid #dedede;
  background-color: ${props => props.hexa};
`;

export const DiscoutDisplay = styled('p')`
  color: ${appColors.PRIMARY_COLOR};
  font-weight: 600;
  margin: 0;
  font-size: 12px;
`;
export const DescriptionDisplay = styled('p')`
  font-weight: 600;
  margin: 0;
  font-size: 12px;
  @media all and (max-width: 500px) {
    font-weight: 600;
    margin: 0;
    font-size: 11px;
  }
`;

const styles = {
  iconsAddRemove: {
    marginLeft: 10,
    marginRight: 10,
    color: appColors.SECUNDARY_COLOR,
    cursor: 'pointer',
  },
  iconsDelete: {
    marginLeft: 10,
    marginRight: 10,
    color: 'grey',
    opacity: 0.4,
    cursor: 'pointer',
  },
  titleProduct: {
    color: appColors.SECUNDARY_COLOR,
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'left',
    textTransform: 'uppercase',
  },
  descriptionButton: {
    color: '#FFF',
    padding: '5px 10px',
    backgroundColor: '#c9c9c9',
    borderRadius: '50px',
    fontWeight: '400',
    marginTop: '15px',
    marginBottom: '15px',
    textTransform: 'none',
  },
};

export default styles;
