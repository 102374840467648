import styled from 'styled-components';
import { Card } from '@material-ui/core';
import appColors from 'utils/appColors';

export const ModalContainer = styled(Card)`
  && {
    width: 800px;
    @media all and (max-width: 500px) {
      position: relative;
      width: 300px;
      max-height: 400px;
      overflow: scroll;
      display: flex;
      flex-direction: column;
    }
  }
`;

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 0,
  },
  headerModal: {
    backgroundColor: appColors.PRIMARY_COLOR,
    padding: 20,
    color: 'white',
    alignItems: 'center',
  },
  containerForm: {
    padding: 20,
  },
  purpleTitle: {
    fontSize: 22,
    color: appColors.SECUNDARY_COLOR,
  },
  subTitle: {
    color: '#393939',
    fontSize: 12,
  },
  headerTitle: {
    fontSize: 20,
  },
};

export default styles;
