import React from 'react';
import PropTypes from 'prop-types';
import appUtils from 'utils/appUtils';
import styles from './styles';
import InvoiceForm from 'components/OrderPage/OrderView/InvoiceForm';
import { Card, CardContent, Box, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Creators as OrderCreators } from 'store/ducks/order';

const ResumeOrder = ({ data }) => {
  const dispatch = useDispatch();

  const onSubmit = form => {
    dispatch(
      OrderCreators.getUpdateStatusRequest({
        id: data.id,
        status: form.status,
        code_tracking: form.code_tracking,
      })
    );
  };

  const totalServices = manPowerData => {
    return (
      manPowerData?.reduce(
        (accumulator, service) => accumulator + service.price,
        0
      ) ?? 0
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex="1"
      flexWrap="wrap"
      style={styles.resumeContainer}>
      <Box
        display="flex"
        flexDirection="row"
        flex="1"
        flexWrap="wrap"
        justifyContent="space-around">
        <Box flex="1" style={{ padding: 10 }}>
          <Typography style={styles.titleBoldPurple}>Total Pago</Typography>
          <Typography>
            <b data-cy="subtotal_details_order" style={{ padidngRight: 6 }}>
              Subtotal:
            </b>
            {appUtils.formatPrice(
              data?.price -
                data?.delivery_price -
                totalServices(data?.budget?.manpower)
            )}
          </Typography>
          <Typography>
            <b data-cy="delivery_details_order" style={{ padidngRight: 6 }}>
              Frete:{' '}
            </b>
            {appUtils.formatPrice(data.delivery_price)}
          </Typography>
          {data?.budget?.manpower && data?.budget?.manpower?.length > 0 && (
            <Typography>
              <b data-cy="delivery_details_order" style={{ padidngRight: 6 }}>
                Mão de Obra:{' '}
              </b>

              {appUtils.formatPrice(totalServices(data?.budget?.manpower))}
            </Typography>
          )}
          <Typography>
            <b data-cy="total_details_order" style={{ padidngRight: 6 }}>
              Total:{' '}
            </b>
            {appUtils.formatPrice(Number(data?.price))}
          </Typography>

          <br />
          <Typography style={styles.titleBoldPurple}>
            Taxas de comissão e cashback
          </Typography>
          <Typography>
            <b data-cy="total_fees_order" style={{ padidngRight: 6 }}>
              Taxa orçamentista:
            </b>
            {appUtils.formatPrice(Number(data.commissions.budgetary))}
          </Typography>
          <Typography>
            <b data-cy="total_fees_order" style={{ padidngRight: 6 }}>
              Cashback:
            </b>
            {appUtils.formatPrice(Number(data.commissions.cashback))}
          </Typography>
        </Box>

        <Box flex="1" style={{ padding: 10 }}>
          <Typography style={styles.titleBoldPurple}>Endereço</Typography>

          <Typography>
            {data.address.street}, {data.address.number}{' '}
            {data.address.complement}
          </Typography>
          <Typography>
            <b>Bairro:</b>
            {data.address.district}
          </Typography>
          <Typography>
            <b>Cidade:</b>
            {data.address.city.name}
          </Typography>
          <Typography>
            <b>CEP:</b> {data.address.code_post}
          </Typography>
        </Box>

        <Box flex="1" style={{ padding: 10 }}>
          <Typography style={styles.titleBoldPurple}>Transportadora</Typography>
          <Box display="flex" flexDirection="column" flexWrap="wrap">
            <Typography data-cy="delivery_name">
              <b>Nome:</b> {data.delivery_name}
            </Typography>
            <Typography data-cy="delivery_carrier">
              <b>Transportadora:</b> {data.delivery_carrier}
            </Typography>
            <Typography data-cy="delivery_price">
              <b>Frete: </b> {appUtils.formatPrice(Number(data.delivery_price))}
            </Typography>
          </Box>
        </Box>

        <Box flex="1" style={{ padding: 10 }}>
          <Typography style={styles.titleBoldPurple}>
            Dados do cliente
          </Typography>
          <Box display="flex" flexDirection="column" flexWrap="wrap">
            <Typography data-cy="order_user_name">
              <b>Cliente:</b> {data.user.name}
            </Typography>
            <Typography data-cy="order_user_phone">
              <b>Telefone Principal:</b> {data.user.cell_phone}
            </Typography>
            <Typography data-cy="order_user_CPF">
              <b>CPF:</b> {data.user.cpf}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        flex="1"
        flexWrap="wrap"
        style={{ padding: 10 }}>
        <Typography style={styles.titleBoldPurple}>Observações</Typography>
        <Typography data-cy="order_observation" style={{ padding: 0 }}>
          {data.observation || <i>Nada informado</i>}
        </Typography>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        flex="1"
        flexWrap="wrap"
        style={{ padding: 10 }}>
        <Typography style={styles.titleBoldPurple}>Nota Fiscal</Typography>
        <Card style={{ height: '100%' }}>
          <CardContent
            data-cy="box_file"
            style={{ textAlign: 'center', color: '#4e3e51' }}>
            <InvoiceForm
              initialValues={{
                status: data.status,
                code_tracking: data.code_tracking,
              }}
              onSubmit={onSubmit}
            />
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

ResumeOrder.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ResumeOrder;
