/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable linebreak-style */
import React from 'react';
import { Box, Typography, CircularProgress } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import styles from './styles';
import CustomButton from 'components/form/CustomButton';
import HeaderComponent from 'components/HeaderComponent';
import NewAddressModal from '../../NewAddressModal';
import EditAddressModal from '../../EditAddressModal';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as AddressCreators } from 'store/ducks/address';
import appColors from 'utils/appColors';
import { Helmet } from 'react-helmet';

const AddressTab = () => {
  const [localState, setLocalState] = React.useState({
    AddressModalOpen: false,
    EditAddressModalOpen: false,
    AddressId: null,
  });

  const dispatch = useDispatch();

  const {
    addressList,
    addressListLoading,
    cep,
    cepLoading,
    addressUpdateLoading,
    cepError,
  } = useSelector(state => state.address);

  const onSubmitUpdate = values => {
    dispatch(
      AddressCreators.getAddressUpdateRequest(values, localState.AddressId)
    );
  };

  React.useEffect(() => {
    const filters = {
      page: 1,
      perPage: 5,
      search: '',
      orderByColumn: '',
      orderByDirection: '',
    };
    dispatch(AddressCreators.getAddressListRequest(filters));
  }, [dispatch]);

  const { AddressModalOpen, EditAddressModalOpen } = localState;

  const onSubmitCEP = values => {
    dispatch(AddressCreators.getAddressCEPRequest(values));
  };

  const openFormAddress = () => {
    setLocalState({ ...localState, AddressModalOpen: true });
  };

  const closeFormAddress = () => {
    dispatch(AddressCreators.getClearCepRequest());
    setLocalState({ ...localState, AddressModalOpen: false });
  };

  const openFormEditAddress = id => {
    setLocalState({ ...localState, EditAddressModalOpen: true, AddressId: id });
  };

  const closeFormEditAddress = () => {
    dispatch(AddressCreators.getClearCepRequest());
    setLocalState({ ...localState, EditAddressModalOpen: false });
  };

  return (
    <Box flex="1">
      <HeaderComponent title="Endereços" />

      <Typography style={styles.descrition}>
        Os endereços a seguir serão usados na página de finalizar pedido como
        endereços padrões, mas é possível modificá-los durante a finalização do
        pedido.
      </Typography>
      <Box
        flex="1"
        style={{ border: '1px solid #A2A2A2', borderRadius: 5, padding: 10 }}>
        {/* INTEGRAÇÃO KDT = TELA DE FINALIZAÇÃO DE PEDIDO*/}
        <Helmet>
          <meta name="kdt:page" content="account" />
        </Helmet>
        {addressList.map((a, i) => (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              style={{
                backgroundColor: appColors.LIGHT_COLOR,
                padding: 20,
                borderRadius: 5,
              }}>
              <Typography style={styles.bigTitlePurple}>
                Endereço {i === 0 ? 'Principal' : `Adicional ${i}`}
              </Typography>
              {addressList.length > 0 && !addressListLoading && (
                <Box
                  data-cy="btn_edit_address"
                  onClick={() => openFormEditAddress(a.id)}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  style={{ color: appColors.PRIMARY_COLOR, cursor: 'pointer' }}>
                  <EditIcon fontSize="small" />
                  <Typography>Editar</Typography>
                </Box>
              )}
            </Box>
            <Box
              display="flex"
              flex="1"
              justifyContent="center"
              flexDirection="column"
              style={{ padding: 20 }}>
              {addressList.length === 0 && !addressListLoading && (
                <Box
                  display="flex"
                  flex="1"
                  justifyContent="center"
                  flexDirection="column"
                  alignItems="center">
                  <Typography
                    style={{
                      color: '#757575',
                      fontStyle: 'italic',
                      marginBottom: 20,
                    }}>
                    Você não possui nenhum endereço cadastrado
                  </Typography>
                  <CustomButton
                    onClick={openFormAddress}
                    type="submit"
                    label="Adicionar um endereço"
                    style={styles.purpleButton}
                  />
                </Box>
              )}
              <Box
                display="flex"
                flex="1"
                flexDirection="row"
                justifyContent="space-between"
                flexWrap="wrap"
                style={styles.rowAddres}>
                <Box
                  display="flex"
                  flexDirection="row"
                  flexWrap="wrap"
                  style={{ marginBottom: 10 }}>
                  <Typography>
                    <b style={styles.fontPurple}>Rua/Avenida</b> :
                  </Typography>
                  <Typography data-cy="street">{a.street}</Typography>
                </Box>
                <Box display="flex" flexDirection="row" flexWrap="wrap">
                  <Typography>
                    <b style={styles.fontPurple}>Número</b> :
                  </Typography>
                  <Typography data-cy="number">{a.number}</Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                flex="1"
                flexDirection="row"
                justifyContent="space-between"
                flexWrap="wrap"
                style={styles.rowAddres}>
                <Box
                  display="flex"
                  flexDirection="row"
                  flexWrap="wrap"
                  style={{ marginBottom: 10 }}>
                  <Typography>
                    <b style={styles.fontPurple}>Bairro</b> :
                  </Typography>
                  <Typography data-cy="district">{a.district}</Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  style={{ marginBottom: 10 }}>
                  <Typography>
                    <b style={styles.fontPurple}>Estado</b> :
                  </Typography>
                  <Typography data-cy="state">{a.city.state.name}</Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                flex="1"
                flexDirection="row"
                justifyContent="space-between"
                flexWrap="wrap"
                style={styles.rowAddres}>
                <Box
                  display="flex"
                  flexDirection="row"
                  style={{ marginBottom: 10 }}>
                  <Typography>
                    <b style={styles.fontPurple}>Cidade</b> :
                  </Typography>
                  <Typography data-cy="city">{a.city.name}</Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  flexWrap="wrap"
                  style={{ marginBottom: 10 }}>
                  <Typography>
                    <b style={styles.fontPurple}>Complemento</b> :
                  </Typography>
                  <Typography data-cy="complement">{a.complement}</Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                flex="1"
                flexDirection="row"
                justifyContent="space-between"
                style={styles.rowAddres}>
                <Box
                  display="flex"
                  flexDirection="row"
                  flexWrap="wrap"
                  style={{ marginBottom: 10 }}>
                  <Typography>
                    <b style={styles.fontPurple}>CEP</b> :
                  </Typography>
                  <Typography data-cy="CEP">{a.code_post}</Typography>
                </Box>
              </Box>
              {addressList.length === 0 && addressListLoading && (
                <Box
                  display="flex"
                  flex="1"
                  justifyContent="center"
                  alignItems="center">
                  <CircularProgress
                    style={{ color: appColors.PRIMARY_COLOR }}
                  />
                </Box>
              )}
            </Box>
          </>
        ))}

        <Box
          display="flex"
          justifyContent="space-between"
          style={{
            backgroundColor: appColors.LIGHT_COLOR,
            padding: 20,
            borderRadius: 5,
          }}>
          <Typography style={styles.bigTitlePurple}>
            Adicionar Endereço
          </Typography>
          <Box
            data-cy="btn_new_address"
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ cursor: 'pointer' }}
            onClick={openFormAddress}>
            <AddIcon />
          </Box>
        </Box>
      </Box>
      <NewAddressModal
        closeModal={closeFormAddress}
        isOpen={AddressModalOpen}
        address={cep}
        onSubmitCEP={onSubmitCEP}
        addressLoading={cepLoading}
        addressError={cepError}
      />
      <EditAddressModal
        closeModal={closeFormEditAddress}
        isOpen={EditAddressModalOpen}
        onSubmit={onSubmitUpdate}
        address={cep}
        onSubmitCEP={onSubmitCEP}
        updateLoading={addressUpdateLoading}
        addressLoading={cepLoading}
        addressError={cepError}
      />
    </Box>
  );
};

export default AddressTab;
