/* eslint-disable react/no-unescaped-entities */
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@material-ui/core';
import CustomTextField from 'components/form/CustomTextField';
import CustomSelect from 'components/CustomSelect';
import { Creators as NotificationCreators } from 'store/ducks/app';
import { FastField, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import api from 'services/api';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import appColors from 'utils/appColors';

const style_inupt = { margin: '5px 0px' };

const optionsT = [
  { id: 0, name: '00:00:00' },
  { id: 1, name: '01:00:00' },
  { id: 2, name: '02:00:00' },
  { id: 3, name: '03:00:00' },
  { id: 4, name: '04:00:00' },
  { id: 5, name: '05:00:00' },
  { id: 6, name: '06:00:00' },
  { id: 7, name: '07:00:00' },
  { id: 8, name: '08:00:00' },
  { id: 9, name: '09:00:00' },
  { id: 10, name: '10:00:00' },
  { id: 11, name: '11:00:00' },
  { id: 12, name: '12:00:00' },
  { id: 13, name: '13:00:00' },
  { id: 14, name: '14:00:00' },
  { id: 15, name: '15:00:00' },
  { id: 16, name: '16:00:00' },
  { id: 17, name: '17:00:00' },
  { id: 18, name: '18:00:00' },
  { id: 19, name: '19:00:00' },
  { id: 20, name: '20:00:00' },
  { id: 21, name: '21:00:00' },
  { id: 22, name: '22:00:00' },
  { id: 23, name: '23:00:00' },
];

const DeliveryCharges = ({ store }) => {
  const dispatch = useDispatch();

  const getDaysOfWeek = () => {
    const labels = [
      'Segunda',
      'Terça',
      'Quarta',
      'Quinta',
      'Sexta',
      'Sábado',
      'Domingo',
    ];
    return labels.map((label, index) => {
      const isChecked = store.order_schedule?.filter(
        schedule => schedule.day_of_week === index
      ).length;

      return {
        id: index,
        label: label,
        checked: isChecked,
        disabled: false,
      };
    });
  };

  const [loading, setLoading] = useState(false);
  const [daysOfWeek, setDaysOfWeek] = useState(getDaysOfWeek());
  const [scheduleOrder, setScheduleOrder] = useState([]);
  const [scheduleEnablePeriods, setScheduleEnablePeriods] = useState([]);

  useEffect(() => {
    api.get(`/v1/site/stores/getschedule/${store.id}`).then(response => {
      setScheduleOrder(response.data.data.periods);
      setScheduleEnablePeriods(response.data.data.enabled);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inititalValues = {
    schedule_initial_time: parseInt(scheduleOrder[0]?.start.split(':')),
    schedule_final_time: parseInt(scheduleOrder[0]?.end.split(':')),
    max_deliveries: scheduleOrder[0]?.max_deliveries,
    scheduleDisabled: scheduleEnablePeriods,
  };

  const schema = Yup.object().shape({
    schedule_initial_time: Yup.number().required('Este campo é obrigatório'),
    schedule_final_time: Yup.number().required('Este campo é obrigatório'),
    max_deliveries: Yup.number().required('Este campo é obrigatório'),
    // handleChangeCheckboxDayOfWeek: Yup.object().required('Este campo é obrigatório'),
  });

  const onSubmit = ({
    schedule_initial_time,
    schedule_final_time,
    max_deliveries,
    scheduleDisabled: enabled,
  }) => {
    setLoading(true);

    const schedule_initial = optionsT[schedule_initial_time];
    const schedule_final = optionsT[schedule_final_time];
    const periods = daysOfWeek
      .filter(day => day.checked)
      .map(({ id }) => ({
        start: schedule_initial.name,
        end: schedule_final.name,
        max_deliveries,
        day_of_week: id,
      }));

    const store_data = {
      enabled,
      periods,
    };

    api
      .post(`/v1/site/stores/schedule/${store.id}`, store_data)
      .then(() =>
        dispatch(
          NotificationCreators.openNotification({
            message: 'Agendamento salvo com sucesso!',
            type: 'success',
          })
        )
      )
      .catch(error =>
        dispatch(
          NotificationCreators.openNotification({
            message: JSON.stringify(error),
            type: 'error',
          })
        )
      )
      .finally(() => setLoading(false));
  };

  const handleChangeCheckboxDayOfWeek = ({ target: { value } }) => {
    setDaysOfWeek(
      daysOfWeek.map(day => {
        if (day.id === value) {
          return { ...day, checked: !day.checked };
        }

        return day;
      })
    );
  };

  return (
    <Box>
      <Formik
        initialValues={inititalValues}
        validationSchema={schema}
        onSubmit={onSubmit}
        enableReinitialize>
        {({ handleSubmit, setFieldValue, values }) => (
          <form onSubmit={handleSubmit}>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.scheduleDisabled}
                    name="scheduleDisabled"
                    style={{ color: appColors.PRIMARY_COLOR }}
                    onChange={() => {
                      setFieldValue(
                        'scheduleDisabled',
                        !values.scheduleDisabled
                      );
                    }}
                  />
                }
                label="Deseja habilitar a entrega agendada?"
              />
              {!values.scheduleDisabled || (
                <Box>
                  <Box>
                    <Typography
                      style={{
                        fontSize: 20,
                        fontWeight: 'bold',
                        marginTop: 20,
                        color: '#444',
                      }}>
                      Quantidade de Agendamentos
                    </Typography>

                    <FastField
                      name="max_deliveries"
                      placeholder="Quantidade de agendamento"
                      type="number"
                      component={CustomTextField}
                    />
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}>
                    <Box style={{ flex: 1, marginRight: 6 }}>
                      <Typography
                        style={{
                          fontSize: 20,
                          fontWeight: 'bold',
                          marginTop: 20,
                          color: '#444',
                        }}>
                        Hora Inicial
                      </Typography>

                      <FastField
                        name="schedule_initial_time"
                        label="Hora inicial de entrega"
                        style={style_inupt}
                        options={optionsT}
                        component={CustomSelect}
                        isLoading={loading}
                      />
                    </Box>

                    <Box style={{ flex: 1, marginLeft: 6 }}>
                      <Typography
                        style={{
                          fontSize: 20,
                          fontWeight: 'bold',
                          marginTop: 20,
                          color: '#444',
                        }}>
                        Hora Final
                      </Typography>

                      <FastField
                        name="schedule_final_time"
                        label="Hora final de entrega"
                        style={style_inupt}
                        options={optionsT}
                        isLoading={loading}
                        component={CustomSelect}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Box style={{ flex: 1, marginLeft: 6 }}>
                      <Typography
                        style={{
                          fontSize: 20,
                          fontWeight: 'bold',
                          marginTop: 20,
                          color: '#444',
                        }}>
                        Dias da Semana
                      </Typography>
                      {daysOfWeek.map(day => (
                        // eslint-disable-next-line react/jsx-key
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={day.id}
                              style={{ color: appColors.PRIMARY_COLOR }}
                              checked={day.checked}
                              disabled={day.disabled}
                              onChange={handleChangeCheckboxDayOfWeek}
                            />
                          }
                          label={day.label}
                        />
                      ))}
                    </Box>
                  </Box>
                </Box>
              )}
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: 20,
                }}>
                <Button
                  data-cy="btn_submit"
                  onClick={handleSubmit}
                  style={{ width: 200, padding: 10 }}
                  variant="contained"
                  disabled={!!loading}
                  color="primary">
                  {!!loading && (
                    <CircularProgress
                      size={12}
                      style={{ marginRight: 10, color: '#fff' }}
                    />
                  )}
                  Salvar
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default DeliveryCharges;
