import React from 'react';
import { Box, Typography } from '@material-ui/core';
import styles, {
  PriceContainer,
  DateContainer,
  StatusContainerStyled,
  OrderContainer,
} from './styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import PropTypes from 'prop-types';
import appUtils from 'utils/appUtils';
import BodyItem from '../BodyItem';
import orderStatus from 'utils/order/status';
import moment from 'moment';

const HeaderItem = ({ data, isOpen, handleOpen }) => {
  const date = new Date(data.content ? data.created_at : data.created_at);
  const id = data.content ? data.id : data.id;
  const price = data.content ? data.price : data.price;
  const status = data.content ? data.status : data.status;
  const getSchedule = () => {
    const schedule = data.schedule;

    if (!schedule.length) {
      return false;
    }

    const { time: _time, date: _date, interval: _interval } = schedule[0];

    const date = moment(_date).format('DD/MM/YYYY');
    const time = moment(_time, 'HH:mm');

    const start = time.format('HH:mm');
    const end = time.add(moment.duration(_interval, 'HH:mm')).format('HH:mm');

    return {
      date,
      start,
      end,
    };
  };

  const schedule = getSchedule();

  return (
    <Box flex="1" style={styles.containerItem}>
      <Box
        data-cy="row_order"
        display="flex"
        justifyContent="space-between"
        flex="1"
        alignItems="center"
        style={{ padding: 20, cursor: 'pointer' }}
        onClick={handleOpen}>
        <OrderContainer display="flex" flexDirection="row" flexWrap="wrap">
          <Typography style={styles.headerPurple}>Pedido:</Typography>
          <Typography data-cy="id_order" style={styles.fontBold}>
            {id}
          </Typography>
        </OrderContainer>
        {schedule && (
          <OrderContainer display="flex" flexDirection="row" flexWrap="wrap">
            <Typography style={styles.headerPurple}>Agendado:</Typography>
            {schedule && (
              <Typography data-cy="schedule" style={styles.fontBold}>
                {schedule.date} {schedule.start} - {schedule.end}
              </Typography>
            )}
          </OrderContainer>
        )}

        <PriceContainer display="flex" flexDirection="row" flexWrap="wrap">
          <Typography style={styles.headerPurple}>Valor Total:</Typography>
          <Typography data-cy="price_order" style={styles.fontBold}>
            {appUtils.formatPrice(price)}
          </Typography>
        </PriceContainer>
        <DateContainer display="flex" flexDirection="row" flexWrap="wrap">
          <Typography style={styles.headerPurple}>Data:</Typography>
          <Typography data-cy="date_order" style={styles.fontBold}>{`${
            date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
          }/${
            date.getMonth() + 1 < 10
              ? '0' + (date.getMonth() + 1)
              : date.getMonth() + 1
          }/${date.getFullYear()} às ${
            date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
          }:${
            date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
          }`}</Typography>
        </DateContainer>
        <StatusContainerStyled
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          alignItems="center">
          <Typography style={styles.headerPurple}>Status:</Typography>
          <Box style={styles.containerStatus}>
            <Typography data-cy="status_order" style={styles.status}>
              {orderStatus(status)}
            </Typography>
          </Box>
        </StatusContainerStyled>
        <Box
          data-cy="btn_details_product"
          display="flex"
          flexDirection="row"
          flexWrap="wrap">
          {isOpen ? (
            <ExpandLessIcon fontSize="default" style={styles.iconExpand} />
          ) : (
            <ExpandMoreIcon fontSize="default" style={styles.iconExpand} />
          )}
        </Box>
      </Box>
      <BodyItem data={data} isOpen={isOpen} />
    </Box>
  );
};

HeaderItem.propTypes = {
  data: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  handleOpen: PropTypes.func.isRequired,
};

export default HeaderItem;
