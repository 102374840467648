/* eslint-disable camelcase */
import { call, takeLatest, all, put, select } from 'redux-saga/effects';
import api from 'services/api';
import { Creators as OrderCreators } from 'store/ducks/order';
import { Types, Creators } from 'store/ducks/order';
import { Creators as CartCreators } from 'store/ducks/checkout/cart';
import { callApi } from 'store/sagas/auth';

import { push, goBack } from 'connected-react-router';
import { Creators as NotificationCreators } from 'store/ducks/app';
import interceptResponse from 'utils/request/interceptResponse';
import moment from 'moment';

// Teste

function* getOrder({ payload }) {
  try {
    const { id } = payload;
    const request = call(api.get, `/v1/order/${id}`);
    const response = yield call(callApi, request);
    if (response.status !== 200) throw response;
    yield put(Creators.getOrderSuccess(response.data));
  } catch (err) {
    yield put(Creators.getOrderFailure('Erro ao buscar na API'));
  }
}

function* getQuote({ payload }) {
  try {
    const { address, products } = payload;
    let budget_id;
    const find = products.find(a => !!a.budget_id);
    if (find) {
      budget_id = find.budget_id;
    }
    const response = yield call(api.post, '/v1/client/cart/quote', {
      address,
      products,
      budget_id,
    });
    if (response.status !== 200) throw response;
    yield put(Creators.getQuoteSuccess(response.data));
    yield put(Creators.getQuoteCodePost(address.code_post));
  } catch (err) {
    if (
      err.data.msg ===
      'Marketplace delivery option store does not allow purchase in different stores'
    ) {
      yield put(
        NotificationCreators.openNotification({
          message:
            'O Marketplace não permite compra de produtos de lojas diferentes',
          type: 'error',
        })
      );
      yield put(goBack());
    } else {
      yield put(Creators.getQuoteFailure(err.data.data.Error || err.data.msg));
      yield put(Creators.getQuoteReset());
      yield put(
        NotificationCreators.openNotification({
          message: err.data.data.Error || err.data.msg,
          type: 'error',
        })
      );
    }
  }
}

function* getOrderInsert({ payload }) {
  try {
    const {
      address,
      code_partner,
      payment,
      products,
      quote_hash,
      observation = '',
      visitorID,
      cash_back,
      schedule,
      budget_hash,
    } = payload;
    const { discount_coupon } = yield select(state => state.cupom);
    const request = call(api.post, '/v1/client/orders', {
      code_promo: discount_coupon,
      address,
      payment: {
        ...payment,
        birth_date: moment(payment.birth_date, 'DD/MM/YYYY').format(
          'YYYY-MM-DD'
        ),
      },
      budget_hash,
      products,
      quote_hash,
      observation,
      visitorID,
      code_partner,
      cashback: cash_back,
      schedule,
    });
    const response = yield call(callApi, request);
    if (response.status !== 200 && response.status !== 201) throw response;
    if (response.data.error !== true) {
      yield put(Creators.getOrderInsertSuccess(response.data));
      yield put(Creators.resetListErrors());
      yield put(CartCreators.resetCart());
      yield put(CartCreators.resetBudgetHashCart());
      yield put(CartCreators.resetManpowerCart());
      yield put(OrderCreators.getQuoteReset());
      if (response.data.data.payment_type === '2') {
        yield put(push('/emitir-boleto'));
      } else {
        yield put(push('/compra-finalizada'));
      }
    } else {
      yield put(Creators.getOrderInsertFailure(response));
      yield put(push('/carrinho'));
    }
  } catch (err) {
    yield put(Creators.getOrderInsertFailure('Erro ao tentar inserir na API'));
    yield put(
      NotificationCreators.openNotification({
        message: err.data.data.Error || err.data.msg,
        type: 'error',
      })
    );
  }
}

function* getOrderUpdate({ payload, id }) {
  try {
    const { code_post, street, number, district, complement, city, state } =
      payload;
    const request = call(api.put, `/v1/client/order/${id}`, {
      code_post,
      street,
      number,
      district,
      complement,
      city,
      state,
    });
    const response = yield call(callApi, request);
    yield put(Creators.getOrderUpdateSuccess(response.data));
    yield put(
      NotificationCreators.openNotification({
        message: 'Edição concluida com sucesso',
        type: 'success',
      })
    );
  } catch (err) {
    yield put(Creators.getOrderUpdateFailure('Erro ao buscar na API'));
  }
}

function* getOrderDelete({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(api.delete, `/v1/order/${id}`);
    yield interceptResponse(response);
    yield put(Creators.getOrderDeleteSuccess());
    // Remove a categoria deletada da lista
    const { orderList, orderListTotal } = yield select(state => state.order);

    yield put(
      Creators.getOrderListSuccess({
        data: orderList.filter(doc => doc.id !== id),
        total: orderListTotal - 1,
      })
    );
  } catch (err) {
    yield put(Creators.getOrderDeleteFailure('Erro ao buscar na API'));
  }
}

function* getOrderList({ payload }) {
  try {
    const { page, perPage, search, orderByColumn, orderByDirection } = payload;
    const request = call(api.get, '/v1/client/orders', {
      page,
      search,
      per_page: perPage,
      order: orderByColumn,
      order_by: orderByDirection,
    });
    const response = yield call(callApi, request);
    yield put(Creators.getOrderListSuccess(response.data));
  } catch (err) {
    yield put(Creators.getOrderListFailure('Erro ao buscar na API'));
  }
}

// Individual exports for testing
export default function* orderSaga() {
  yield all([
    takeLatest(Types.GET_REQUEST, getOrder),
    takeLatest(Types.GET_INSERT_REQUEST, getOrderInsert),
    takeLatest(Types.GET_UPDATE_REQUEST, getOrderUpdate),
    takeLatest(Types.GET_DELETE_REQUEST, getOrderDelete),
    takeLatest(Types.GET_LIST_REQUEST, getOrderList),
    takeLatest(Types.GET_QUOTE_REQUEST, getQuote),
  ]);
}
