import {
  call, takeLatest, all, put
} from 'redux-saga/effects';
import api from 'services/api';

import { Types, Creators } from 'store/ducks/stores/producstByStore';

function* getProductsByStoreRequest({ payload, slug }) {
  try {
    const { page, per_page, search, orderByDirection, order, price_min, price_max } = payload;
    const response = yield call(api.get, `v1/site/stores/${slug}/products`, {
      page,
      search,
      per_page,
      order_by: orderByDirection,
      order,
      price_min,
      price_max,
    });
    if (response.status !== 200) throw response;
    yield put(Creators.getProductsByStoreSuccess(response.data));
  } catch (err) {
    yield put(Creators.getProductsByStoreFailure('Erro ao buscar na API'));
  }
}

// Individual exports for testing
export default function* productsByStoreSaga() {
  yield all([
    takeLatest(Types.GET_PRODUCTS_BY_STORE_REQUEST, getProductsByStoreRequest),
  ]);
}
