import React from 'react';
import { FormHelperText, TextField } from '@material-ui/core';
import styled from 'styled-components';
import { ErrorMessage } from 'formik';
import NumberFormat from 'react-number-format';

const StyledTextField = styled(TextField)`
  && {
    .MuiOutlinedInput-root {
      background: white;
    }
    & .MuiOutlinedInput-input {
      padding: 12px 12px;
    }
    & .MuiInputLabel-outlined[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }
`;

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={({ floatValue }) => {
        if (typeof floatValue === 'undefined') {
          onChange('');
        } else {
          onChange(floatValue);
        }
      }}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      fixedDecimalScale
      prefix="R$ "
    />
  );
}

const CustomCurrencyField = ({ field, label, form, style = {} }) => (
  <React.Fragment>
    <StyledTextField
      data-cy="input_amount"
      style={style}
      fullWidth
      {...field}
      label={label}
      onChange={val => {
        form.setFieldValue(field.name, val);
      }}
      variant="outlined"
      error={Boolean(form.touched[field.name] && form.errors[field.name])}
      InputProps={{
        inputComponent: NumberFormatCustom,
      }}
    />
    <ErrorMessage name={field.name}>
      {msg => (
        <FormHelperText error id="component-error-text">
          {msg}
        </FormHelperText>
      )}
    </ErrorMessage>
  </React.Fragment>
);

export default CustomCurrencyField;
