import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as FavoriteListCreators } from 'store/ducks/favorite/list';
import {
  Box,
  TablePagination,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import ProductsList from 'components/Pages/Home/ProductsList';
import HeaderWish from 'components/Pages/WishPage/HeaderWish';
import appColors from 'utils/appColors';
import styles, { TrendsContainer } from './styles';

const WishPage = () => {
  const dispatch = useDispatch();
  const { isAuth } = useSelector(state => state.auth);
  const { favoritesLoading } = useSelector(state => state.favorite.list);
  let { favoritesList, favoritesListTotal } = useSelector(
    state => state.favorite.list
  );

  if (!isAuth) {
    favoritesList = [];
    favoritesListTotal = 0;
  }

  const [filters, setFilters] = React.useState({
    page: 1,
    perPage: 12,
    search: '',
    orderByColumn: '',
    orderByDirection: '',
  });

  const { page, perPage } = filters;

  const onChangePage = (event, requestPage) => {
    window.scrollTo(0, 0);
    setFilters({ ...filters, page: requestPage + 1 });
  };

  const onChangeRowsPerPage = event => {
    window.scrollTo(0, 0);
    const { value } = event.target;
    setFilters({ ...filters, page: 1, per_page: value });
  };

  React.useEffect(() => {
    dispatch(FavoriteListCreators.getFavoritesListRequest(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <>
      <TrendsContainer flex="1" flexDirection="column" display="flex">
        <HeaderWish />
        {favoritesList.length > 0 && !favoritesLoading && (
          <ProductsList key="" title="" subtitle="" data={favoritesList} />
        )}
        {favoritesLoading && (
          <Box
            flex="1"
            display="flex"
            justifyContent="center"
            style={{ marginTop: 20 }}>
            <CircularProgress style={{ color: appColors.PRIMARY_COLOR }} />
          </Box>
        )}
        {favoritesList.length > 0 && (
          <Box display="flex" justifyContent="flex-end" flex={1}>
            <TablePagination
              labelRowsPerPage="Por Página:"
              rowsPerPageOptions={[12, 24, 36, 48]}
              page={page - 1}
              style={{ color: appColors.PRIMARY_COLOR, flexDirection: 'row' }}
              rowsPerPage={perPage}
              count={favoritesListTotal}
              onChangePage={onChangePage}
              component="div"
              onChangeRowsPerPage={onChangeRowsPerPage}
            />
          </Box>
        )}
        {favoritesList.length === 0 && !favoritesLoading && (
          <Box
            flex="1"
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{
              backgroundColor: appColors.LIGHT_COLOR,
              borderRadius: 5,
              marginTop: 20,
              marginBottom: 80,
              padding: 50,
            }}>
            <Typography style={styles.purpleTitle}>
              Sua lista está vazia!
            </Typography>
          </Box>
        )}
      </TrendsContainer>
    </>
  );
};

export default WishPage;
