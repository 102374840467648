import React from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import { Grid, Icon, Typography } from '@material-ui/core';
import appColors from 'utils/appColors';
import { Formik, Form, FastField } from 'formik';
import CustomCurrencyField from 'components/form/components/CustomCurrencyField';
import * as Yup from 'yup';
import { toPrice } from 'utils/converters';
import { InputContainer } from 'components/form/StyledComponents';
import CustomButton from 'components/form/CustomButton';
import CashConfirmModal from '../CashConfirmModal';

const ModalContainer = styled(Paper)`
  && {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    @media (max-width: 968px) {
      width: 70%;
    }
    @media (max-width: 768px) {
      width: 90%;
    }
    padding: 30px;
  }
`;

const WithDrawInfoItemLabel = styled('div')`
  && {
    color: ${appColors.PRIMARY_COLOR};
  }
`;

const initialValues = {
  amount: '',
  password: '',
};

const WithDrawInfoItem = ({ label, children }) => (
  <Grid item lg={4} sm={6} xs={12}>
    <WithDrawInfoItemLabel>{label}</WithDrawInfoItemLabel>
    {children}
  </Grid>
);

const PaymentContainer = styled(Grid)`
  && {
    margin-top: 30px;
  }
`;

WithDrawInfoItem.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const CashExpressWithdraw = ({
  fees,
  isOpen,
  handleClose,
  cash: { bank },
  onSubmit,
  isLoading,
  wallet,
}) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const schema = Yup.object().shape({
    amount: Yup.string()
      .required('Campo obrigatório')
      .test(
        'amount',
        'Valor disponível é insuficiente.',
        val =>
          val !== undefined &&
          Number(wallet.current) - Number(fees?.transfer?.pix?.feeValue ?? 0) >=
            Number(val)
      ),
    password: Yup.string().required('Confirme a senha'),
  });

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const onAllClose = () => {
    handleClose();
    handleModalClose();
  };

  const cashSubmit = values => {
    onSubmit(values, onAllClose);
  };

  return (
    <>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalContainer>
          <Grid container spacing={2}>
            <WithDrawInfoItem label="Banco">
              {`${bank.bank.code} - ${bank.bank.name}`}
            </WithDrawInfoItem>
            <WithDrawInfoItem label="Número da agência">
              {bank.agency || ''}
            </WithDrawInfoItem>
            <WithDrawInfoItem label="Dígito da agência">
              {bank.agency_check || ''}
            </WithDrawInfoItem>
            <WithDrawInfoItem label="Número da conta">
              {bank.account || ''}
            </WithDrawInfoItem>
            <WithDrawInfoItem label="Dígito da conta">
              {bank.account_check || ''}
            </WithDrawInfoItem>
            <WithDrawInfoItem label="Tipo da conta">
              {bank.type || ''}
            </WithDrawInfoItem>
            <WithDrawInfoItem label="Titular da conta">
              {bank.account_holder || ''}
            </WithDrawInfoItem>
            <WithDrawInfoItem label="Tipo de documento">
              {bank.doc_type || ''}
            </WithDrawInfoItem>
            <WithDrawInfoItem label="Número do documento">
              {bank.doc_number || ''}
            </WithDrawInfoItem>
          </Grid>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={cashSubmit}
            validateOnBlur
            enableReinitialize
            render={({ handleSubmit }) => (
              <Form>
                <PaymentContainer container spacing={2}>
                  <Grid sm={6} xs={12}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        border: `1px solid ${appColors.PRIMARY_COLOR}`,
                        borderRadius: '10px',
                        padding: '5px',
                        marginBottom: '10px',
                        position: 'relative',
                      }}>
                      <div style={{ position: 'absolute', top: 5, right: 5 }}>
                        <IconButton
                          id={id}
                          onClick={handleClick}
                          style={{ padding: 0, marginLeft: '15px' }}>
                          <Icon>info_outline</Icon>
                        </IconButton>
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClosePopover}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                          }}>
                          <div
                            style={{
                              maxWidth: '200px',
                              borderRadius: '10px',
                              padding: '5px',
                              border: '1px solid black',
                            }}>
                            <Typography>
                              A transferência de saldo é feita através do método
                              Pix para a conta bancária registrada
                            </Typography>
                          </div>
                        </Popover>
                      </div>
                      <div style={{ display: 'flex', gap: '5px' }}>
                        <Icon style={{ color: '#797d8070' }}>
                          account_balance_wallet
                        </Icon>
                        <Typography>
                          Saldo:
                          {toPrice(wallet.current)}
                        </Typography>
                      </div>

                      <div style={{ display: 'flex', gap: '5px' }}>
                        <Icon style={{ color: '#797d8070' }}>money_off</Icon>
                        <span style={{ color: 'red' }}>
                          <b id="Taxa">
                            Taxa de transferência PIX:
                            {toPrice(fees?.transfer?.pix?.feeValue)}
                          </b>
                        </span>
                      </div>

                      <div style={{ display: 'flex', gap: '5px' }}>
                        <Icon style={{ color: '#797d8070' }}>attach_money</Icon>
                        <Typography style={{ color: 'green' }}>
                          Disponível para saque:
                          {toPrice(
                            Number(wallet.current) -
                              Number(fees?.transfer?.pix?.feeValue ?? 0),
                            true
                          )}
                        </Typography>
                      </div>
                    </div>

                    <WithDrawInfoItemLabel style={{ marginBottom: 10 }}>
                      Deseja sacar qual valor para a conta?
                    </WithDrawInfoItemLabel>

                    <FastField
                      name="amount"
                      label="Valor"
                      component={CustomCurrencyField}
                    />
                  </Grid>
                  <Grid
                    sm={6}
                    xs={12}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingTop: 42,
                    }}
                    justifyContent="flex-end">
                    <InputContainer
                      style={{
                        justifyContent: 'flex-end',
                        marginLeft: 20,
                      }}>
                      <CustomButton
                        style={{
                          width: 'auto',
                          marginRight: 5,
                          paddingRight: 20,
                          paddingLeft: 20,
                          backgroundColor: '#797d80',
                        }}
                        onClick={handleClose}
                        variant="contained"
                        label="VOLTAR"
                        color="primary"
                        Icon={false}
                      />
                      <CustomButton
                        style={{
                          width: 'auto',
                          paddingRight: 20,
                          paddingLeft: 20,
                          backgroundColor: appColors.COLOR_ICON,
                        }}
                        label="Confirmar"
                        Icon={false}
                        onClick={handleModalOpen}
                      />
                    </InputContainer>
                  </Grid>
                </PaymentContainer>
                <CashConfirmModal
                  handleClose={handleModalClose}
                  isOpen={modalOpen}
                  isLoading={isLoading}
                  onSubmit={handleSubmit}
                />
              </Form>
            )}
          />
        </ModalContainer>
      </Modal>
    </>
  );
};

CashExpressWithdraw.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  store: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default CashExpressWithdraw;
