import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import styles, { CustomQntInput } from './styles';
import PropTypes from 'prop-types';
import appColors from 'utils/appColors';
import { useDispatch } from 'react-redux';
import { Creators as NotificationCreators } from 'store/ducks/app';

const ColorButton = withStyles(() => ({
  root: {
    color: appColors.SECUNDARY_COLOR,
    backgroundColor: 'white',
    height: 40,
    width: 40,
    padding: 0,
    minWidth: 0,
    borderRadius: 30,
    border: `1px solid ${appColors.SECUNDARY_COLOR}`,
    '&:hover': {
      color: 'white',
      backgroundColor: appColors.SECUNDARY_COLOR,
    },
  },
}))(Button);

const QntInput = ({
  field: { value, name },
  form,
  data,
  quantity_whole,
  isWhole,
  variationSelected,
}) => {
  const dispatch = useDispatch();

  const add = () => {
    let v = value;
    // SE O CONTROLE DE STOCK TIVER HABILITADO E TIVER VARIAÇÃO
    if (
      !!variationSelected &&
      value < variationSelected.stock &&
      !!data.stock_control
    ) {
      value++;
    }

    // SE O CONTROLE DE STOCK TIVER DESABILITADO E TIVER VARIAÇÃO
    if (!!variationSelected && !data.stock_control) {
      value++;
    }

    // SE O CONTROLE DE STOCK TIVER HABILITADO E NÃO TIVER VARIAÇÃO
    if (value < data.stock && !!data.stock_control && !variationSelected) {
      value++;
    }

    // SE O CONTROLE DE STOCK TIVER DESABILITADO E NÃO TIVER VARIAÇÃO
    if (data.stock_control === 0 && !variationSelected) {
      value++;
    }

    if (v === value) {
      dispatch(
        NotificationCreators.openNotification({
          message: 'Não temos mais estoque disponível para esse item',
          type: 'error',
        })
      );
    }

    form.setFieldValue(name, value);
  };

  const removeQnt = () => {
    if (isWhole && value === quantity_whole) {
      return null;
    }
    if (value > 1) {
      form.setFieldValue(name, value - 1);
    }
  };

  return (
    <CustomQntInput justifyContent="space-between">
      <ColorButton onClick={removeQnt} variant="contained">
        <RemoveIcon />
      </ColorButton>
      <Typography style={styles.textMediumPurple}>{value}</Typography>
      <ColorButton onClick={add} variant="contained">
        <AddIcon />
      </ColorButton>
    </CustomQntInput>
  );
};

QntInput.propTypes = {
  field: PropTypes.oneOfType([PropTypes.object]).isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default QntInput;
