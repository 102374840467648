import { Typography } from '@material-ui/core';
import React, { useEffect } from 'react';

import { Segment, Grid } from './styles';

function Segments({ segments, form, field, label }) {
  const { setFieldValue } = form;
  const { name, value } = field;
  const list = value.map(item =>
    typeof item.segment_id === 'number' ? item.segment_id : item
  );

  useEffect(() => {
    setFieldValue(name, list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = id => {
    if (list.includes(id)) {
      if (list.length > 1)
        setFieldValue(
          name,
          list.filter(ids => ids !== id)
        );
    } else {
      list.push(id);
      setFieldValue(name, list);
    }
  };

  return (
    <>
      <Typography style={{ margin: '10px 0', fontSize: 20, fontWeight: 500 }}>
        {label || 'Segmentos'}
      </Typography>
      <Grid>
        {segments.map((item, index) => (
          <Segment
            data-cy="name_segment"
            key={String(index)}
            selected={list.includes(item.id)}
            onClick={() => onChange(item.id)}>
            {item.name}
          </Segment>
        ))}
      </Grid>
    </>
  );
}

export default Segments;
