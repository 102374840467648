/* eslint-disable react/display-name */
import React from 'react';
import PageBase from 'components/PageBase';
import DefaultTable from 'components/Tables/DefaultTable';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as VirtualCatalogCreators } from 'store/ducks/admin/virtualCatalog';
import { Paper } from '@material-ui/core';
import HeaderComponent from 'components/HeaderComponent';
import VirtualCatalogTableHeader from 'components/Pages/VirtualCatalogPage/VirtualCatalogTableHeader';
import VirtualCatalogActions from 'components/Pages/VirtualCatalogPage/VirtualCatalogActions';
import AlertDialog from 'components/AlertDialog';
import VirtualCatalogImage from 'components/Pages/VirtualCatalogPage/VirtualCatalogImage';
import moment from 'moment';

const VirtualCatalogList = () => {
  const [clickedId, setClickedId] = React.useState(false);
  const { client } = useSelector(state => state.client);

  const columns = ({ onDeleteRequest }) => {
    return [
      {
        title: 'Referência',
        field: 'id',
        sorting: false,
      },
      {
        title: 'Capa',
        field: 'image',
        sorting: false,
        render: rowData => <VirtualCatalogImage rowData={rowData} />,
      },
      {
        title: 'Nome',
        field: 'name',
        sorting: false,
      },
      {
        title: 'Válido até',
        field: 'expiration_date',
        sorting: false,
        render: data =>
          data.expiration_date
            ? moment(data.expiration_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
            : 'Tempo indeterminado',
      },
      {
        title: 'Ações',
        render: rowData => (
          <VirtualCatalogActions
            rowData={rowData}
            onDeleteRequest={onDeleteRequest}
            clickedId={clickedId}
            setClickedId={setClickedId}
          />
        ),
      },
    ];
  };
  const dispatch = useDispatch();
  const [deleteState, setDeleteState] = React.useState({
    open: false,
    item: {},
  });

  const [localState, setLocalState] = React.useState({
    search: '',
    orderByColumn: '',
    orderByDirection: '',
    page: 1,
    perPage: 10,
    store_id: client.group.id === 3 ? client.store.id : '',
    user_id: client.group.id !== 3 ? client.id : '',
    show_expired: 0,
  });

  const virtualCatalogList = useSelector(
    state => state.admin.virtualCatalog.virtualCatalogList
  );
  const virtualCatalogListLoading = useSelector(
    state => state.admin.virtualCatalog.virtualCatalogListLoading
  );
  const virtualCatalogListTotal = useSelector(
    state => state.admin.virtualCatalog.virtualCatalogListTotal
  );
  const virtualCatalogDeleteLoading = useSelector(
    state => state.admin.virtualCatalog.virtualCatalogDeleteLoading
  );

  React.useEffect(() => {
    dispatch(VirtualCatalogCreators.getVirtualCatalogListRequest(localState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAlertDialogClose = () => {
    setDeleteState({ open: false, item: {} });
  };

  React.useEffect(() => {
    if (virtualCatalogDeleteLoading === false && deleteState.open) {
      handleAlertDialogClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [virtualCatalogDeleteLoading]);

  const getFunction = data => {
    setLocalState(oldLocalState => ({
      ...oldLocalState,
      ...data,
      show_expired: !data.show_expired,
    }));
  };

  React.useEffect(() => {
    dispatch(VirtualCatalogCreators.getVirtualCatalogListRequest(localState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState]);

  const onDeleteRequest = item => {
    setDeleteState({ open: true, item });
  };

  const onDeleteConfirm = () => {
    dispatch(
      VirtualCatalogCreators.getVirtualCatalogDeleteRequest(deleteState.item.id)
    );
  };

  return (
    <PageBase>
      <HeaderComponent title="Catálogo Virtual"></HeaderComponent>
      <VirtualCatalogTableHeader
        getFunction={getFunction}
        initialValues={{
          search: localState.search,
          show_expired: localState.show_expired,
        }}
      />
      <Paper>
        <DefaultTable
          getFunction={getFunction}
          columns={columns({ onDeleteRequest })}
          data={virtualCatalogList}
          total={virtualCatalogListTotal}
          isLoading={virtualCatalogListLoading}
          page={localState.page}
          perPage={localState.perPage}
        />
      </Paper>
      <AlertDialog
        isOpen={deleteState.open}
        isLoading={virtualCatalogDeleteLoading}
        handleClose={handleAlertDialogClose}
        onConfirm={onDeleteConfirm}
        title="Excluir registro?"
        description={`Remover categoria: ${deleteState.item.name}`}
      />
    </PageBase>
  );
};

export default VirtualCatalogList;
