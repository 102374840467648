import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import appColors from 'utils/appColors';

export const Container = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  // background: #edecec;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  @media screen and (max-width: 500px) {
    height: 100px;
  }
`;

export const Content = styled.div`
  width: 100%;
  align-items: center;
  padding: 0px 7%;
  display: flex;
  justify-content: space-between;
`;

export const GroupButtons = styled.div`
  max-width: 350px;
  display: flex;
  justify-content: flex-end;
`;

export const ImgLogo = styled.img`
  height: 75px;
  margin-right: 15px;
  cursor: pointer;
  @media screen and (max-width: 500px) {
    height: 62px;
    cursor: pointer;
  }
`;

export const Title = styled(Typography)`
  color: ${appColors.SECUNDARY_COLOR};
  font-weight: 'bold';
  align-content: 'center';
  font-size: 22px;
  padding: 1;
  vertical-align: 'middle';
  margin-top: 6;

  @media screen and (max-width: 500px) {
    display: none;
  }
`;

export const ImgLogoApp = styled.img`
  width: 160px;
  margin-right: 15px;
  cursor: pointer;
  @media screen and (max-width: 500px) {
    width: 120px;
    cursor: pointer;
  }
`;

const styles = {
  fontSize: 25,
  color: appColors.COLOR_FONT_MENU,
};

export default styles;
