import React from 'react';
import { Box } from '@material-ui/core';

import StoreClosed from 'components/StorePage/StoreClosed';

const ClosedPage = () => {
  return (
    <Box flex="1" display="flex" flexDirection="column">
      <StoreClosed />
    </Box>
  );
};

export default ClosedPage;
