import styled from 'styled-components';
import { Paper, Grid } from '@material-ui/core';
import appColors from 'utils/appColors';

export const Title = styled.div`
	font-size: 22px;
	color: #444;
	padding: 2px 10px;
	border-left: 5px solid ${appColors.COLOR_ICON};
	font-weight: bold;
	margin-top: 20px;
`;

export const Forms = styled.div`
	margin-top: 20px;
	padding: 10px 0px;
	flex: 1;
`;

export const LinkHref = styled.a`
	color: ${appColors.COLOR_ICON};
	font-weight: bold;
	margin: 0px 5px;
	text-decoration: underline;
	cursor: pointer;
`;

export const BoxSuperior = styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  background-color: ${appColors.LIGHT_COLOR} !important;
  padding: 17px 20px;
  border-color: none;
  height: 130px;

  h3 {
    margin-top: 0px;
    font-size: 1em;
    line-height:20px;
    color: ${appColors.PRIMARY_COLOR};
  }

  strong {
    font-size: 30px;
    font-weight: bold;
    line-height:20px;
  }

  span {
    margin-top: 12px;
    color: #444;
  }

  svg {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 40px;
    color: ${appColors.PRIMARY_COLOR};
  }
`;

export const BoxValue = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;


  #taxas_antecipacao {
    padding: 10px 15px;
    text-align: right;

    section {
      & + section {
        margin-top: 10px;
      }

      > span {
        color: #666;
      }

      > p {
        font-size: 20px;
        font-weight: 500;
        margin: 0;
      }
    }
  }
`;

export const BoxParcelas = styled(Grid)`
  padding: 30px 10px;

  > div {
    border-left: 4px solid ${appColors.COLOR_ICON};
    padding: 0px 15px;
    height: 100%;

    table {
      list-style-type: none;
      width: 100%;
      border-collapse: collapse;

      thead{
        background: #f1f1f1;
      }
      th {
        font-size: 12px;
        padding: 6px;
        border: .6px solid #ddd;
      }
      tr {
        padding: 0px 15px;
        border-radius: 8px;
        background: #fff;
        align-items: center;
        justify-content: center;
        td {
          padding: 6px 8px;
          font-size: 14px;
          color: #444;
          align-items: center;
          justify-content: center;
          text-align: center;
          border: .6px solid #ddd;
        }
      }
    }
  }
`;

export const ResultContainer = styled.div`
	align-content: center;
	align-items: center;
	margin-top: 10px;
	flex: 1;
	display: flex;
	flex-direction: row;
	justify-items: center;
	justify-content: center;
	padding: 10px 0px;
`;

export const ResultBox = styled.div`
	flex: 1;
	padding: 10px 15px;
	border-radius: 8px;
	align-items: center;
	justify-content: center;
	justify-items: center;
	align-self: center;
	background-color: ${props => (props.proposed ? '#53a93f' : '#f1f1f1')};
	margin: 4px;
	box-shadow: 1px 3px 4px #ccc;
`;

export const BoxTitle = styled.span`
	font-weight: bold;
	font-size: 10px;
	color: ${props => (props.proposed ? '#fff' : '#666')};
	margin: 0;
	text-align: center;
`;

export const BoxItem = styled.p`
	margin: 0;
	font-size: 20px;
	font-weight: bold;
	text-align: center;
	color: ${props => (props.proposed ? '#fff' : '#444')};
`;

/// FEES
/// TAXAS
export const FeesView = styled.div`
	margin-top: 5px;
	display: flex;
	flex-direction: row;
`;

export const FeesItem = styled.div`
	padding: 10px 20px;
	flex: 1;
	border-width: .6px;
	border-color: #ddd;
`;

export const FeesTitle = styled.p`
	margin: 0;
	font-size: 13px;
	color: #666;
	font-weight: bold;
`;

export const FeesPrice = styled.p`
	margin: 0;
	font-size: 20px;
	color: #333;
	font-weight: bold;
`;
