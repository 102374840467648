// @flow
import { takeLatest, all, put, select } from 'redux-saga/effects';
import { Types, Creators } from 'store/ducks/checkout/sharedAddress';
import { Creators as NotificationCreators } from 'store/ducks/app';

function* addAddressToCart({ payload }) {
  const { address } = payload;
  const sharedAddress = yield select(state => state.checkout.sharedAddress);
  const existsInCart = sharedAddress.addresses.find(
    x => x.code_post === address.code_post
  );
  let updatedAddress = [];
  if (existsInCart) {
    updatedAddress = sharedAddress.addresses.map(
      x => x.code_post === address.code_post
    );
  } else {
    updatedAddress = [...sharedAddress.addresses, address];
  }
  yield put(Creators.addAddressToCartSucess(updatedAddress));
  yield put(
    NotificationCreators.openNotification({
      message: 'Endereço adicionado ao carrinho',
      type: 'success',
    })
  );
}

function* removeAddressFromCart({ payload }) {
  const { address } = payload;
  // const sharedAddress = yield select(
  //   state => state.checkout.sharedAddress
  // );
  yield put(Creators.removeAddressFromCartSuccess(address));
  yield put(
    NotificationCreators.openNotification({
      message: 'Endereço removido do carrinho',
      type: 'success',
    })
  );
}

function* removeGroupItensFromCart({ payload }) {
  const { address } = payload;
  yield put(Creators.removeGroupFromCartSuccess(address));
}

function* updatePriceAddress({ price_actual, code_post }) {
  const { addresses } = yield select(state => state.checkout.sharedAddress);
  const newState = [...addresses];
  const result = newState.checkout.map(address => {
    if (address.code_post === code_post) {
      address.price = price_actual;
    }
    return address;
  });

  yield put(Creators.updatePriceSuccess(result));
}

// Individual exports for testing
export default function* checkoutAddressSaga() {
  yield all([
    takeLatest(Types.ADD_ADDRESS_TO_CART_REQUEST, addAddressToCart),
    takeLatest(Types.REMOVE_ADDRESS_TO_CART_REQUEST, removeAddressFromCart),
    takeLatest(
      Types.REMOVE_GROUP_ADDRESS_TO_CART_REQUEST,
      removeGroupItensFromCart
    ),
    takeLatest(Types.UPDATE_ADDRESS_REQUEST, updatePriceAddress),
  ]);
}
