import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import appColors from 'utils/appColors';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DialogConfirm = ({
  active = false,
  title = '',
  description = '',
  buttonCancel = '',
  ButtonSubmit = '',
  handleSubmit,
  handleClose = () => {},
  dialogContent = () => {},
}) => {
  const Content = dialogContent();

  return (
    <div>
      <Dialog
        open={active}
        TransitionComponent={Transition}
        keepMounted
        onClose={Content ? null : handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <div>
          {Content ? (
            <div>{Content}</div>
          ) : (
            <div>
              <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  {description}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  style={{
                    color: '#444',
                  }}>
                  {buttonCancel}
                </Button>
                <Button
                  onClick={handleSubmit}
                  style={{
                    backgroundColor: appColors.PRIMARY_COLOR,
                    color: '#fff',
                  }}>
                  {ButtonSubmit}
                </Button>
              </DialogActions>
            </div>
          )}
        </div>
      </Dialog>
    </div>
  );
};
