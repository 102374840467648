import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 70vh;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.image &&
    css`
      background-image: url(${props.image});
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    `}

  @media (max-width: 500px) {
    min-height: 800px;
  }
`;

export const BoxImg = styled.div`
  flex: 5;
  height: 100%;

  ${props =>
    props.image &&
    css`
      background-image: url(${props.image});
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
    `}

  @media (max-width: 500px) {
    width: 300px;
    height: 300px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1100px;
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
  align-items: center;
  padding: 40px 20px;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const Buttons = styled.div`
  display: flex;
  margin-top: 50px;

  button {
    margin-right: 30px;
  }

  @media (max-width: 500px) {
    button {
      width: 50px;
      height: 50px;
      margin-right: 15px;
    }
  }
`;

export const Titles = styled.div`
  height: 300px;
  flex: 7;
  display: flex;
  justify-content: center;
  flex-direction: column;

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    color: #505255;
    max-width: 450px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    max-width: 450px;
    margin-bottom: 60px;
  }

  @media (max-width: 500px) {
    h1 {
      font-style: normal;
      font-weight: 500;
      font-size: 25px;
      line-height: 36px;
      color: #505255;
      max-width: 450px;
    }
  }
`;
export const StoreImg = styled.div`
  border-radius: 50%;
  background: #fafafa;
  width: 120px;
  height: 120px;

  ${props =>
    props.image &&
    css`
      background: url(${props.image});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    `}
`;
