import React from 'react';
import { Box, Typography, Breadcrumbs } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from "react-router-dom";
import styles, { ContainerHeader } from './styles';

const HeaderStores = ({ title }) => {

  return (
    <ContainerHeader>
      <Box display='flex' justifyContent='flex-start'>
        <Typography style={styles.purpleTitle}>{title}</Typography>
      </Box>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Link to="/">
          Início
                </Link>
        <Typography color="textPrimary">{title}</Typography>
      </Breadcrumbs>
    </ContainerHeader>
  )
}

export default HeaderStores;
