import {
  call, takeLatest, all, put,
} from 'redux-saga/effects';
import api from 'services/api';

import { Types, Creators } from 'store/ducks/infoPage';

function* getInfoPageRequest({ payload }) {
  try {
    const { route } = payload;
    const response = yield call(api.get, `v1/site/pages/${route}`);
    if (response.status !== 200) throw response;
    yield put(Creators.getInfoPageSuccess(response.data));
  } catch (err) {
    yield put(Creators.getInfoPageFailure('Erro ao buscar na API'));
  }
}

// Individual exports for testing
export default function* infoPageSaga() {
  yield all([
    takeLatest(Types.GET_INFO_PAGE_REQUEST, getInfoPageRequest),
  ]);
}

