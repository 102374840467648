import PanelClientTab from 'components/Pages/MyAccountPage/PainelAccount/TabsPanel/PanelClientTab';
import webhookTab from 'components/Pages/MyAccountPage/PainelAccount/TabsPanel/webhookTab';
import OrderListPage from 'components/Pages/ListPage';
import AddressTab from 'components/Pages/MyAccountPage/PainelAccount/TabsPanel/AddressTab';
import AccountDetailsTab from 'components/Pages/MyAccountPage/PainelAccount/TabsPanel/AccountDetailsTab';
import sendDealerChargeTab from 'components/Pages/MyAccountPage/PainelAccount/TabsPanel/sendDealerChargeTab';
import FinancialTab from 'components/Pages/MyAccountPage/PainelAccount/TabsPanel/FinancialTab';
import FinancialResellerTab from 'components/Pages/MyAccountPage/PainelAccount/TabsPanel/FinancialResellerTab';
import SetofPlotsTab from 'components/Pages/MyAccountPage/PainelAccount/TabsPanel/SetofPlotsTab';
import NewProduct from 'Pages/Tabs/NewProduct';
import OrdersTabShop from 'components/OrderPage/OrdersTabShop';
import ProductsTab from 'components/Pages/MyAccountPage/PainelAccount/TabsPanel/ProductsTab';
import VirtualCatalogList from 'components/Pages/MyAccountPage/PainelAccount/TabsPanel/VirtualCatalogTab/VirtualCatalogList';
import VirtualCatalogUpdate from 'components/Pages/MyAccountPage/PainelAccount/TabsPanel/VirtualCatalogTab/VirtualCatalogUpdate';
import VirtualCatalogInsert from 'components/Pages/MyAccountPage/PainelAccount/TabsPanel/VirtualCatalogTab/VirtualCatalogInsert';
import UpdateStorePage from 'components/Pages/MyAccountPage/PainelAccount/TabsPanel/UpdateStorePage';
import CashExpressResellerMenu from 'components/Pages/MyAccountPage/PainelAccount/TabsPanel/CashExpressResellerMenu';
import Documents from 'components/Pages/MyAccountPage/PainelAccount/TabsPanel/Documents';
import CashTranferMenu from 'components/Pages/MyAccountPage/PainelAccount/TabsPanel/CashTranferMenu';
import UserInsertPage from 'components/UserMenu/InsertPage';
import UserUpdatePage from 'components/UserMenu/UpdatePage';
import TermsPage from 'components/Pages/MyAccountPage/PainelAccount/TabsPanel/TermsPage';
import FeaturedProduct from 'components/Pages/MyAccountPage/PainelAccount/TabsPanel/ProductsTab/Featured';
import OrdersTab from 'components/Pages/MyAccountPage/PainelAccount/TabsPanel/OrdersTab';
import SalePage from 'containers/Pages/ReportMenu/SalePage';
import SaleCommissionPage from 'containers/Pages/ReportMenu/SaleCommissionPage';
import CouponListPage from 'containers/Pages/PromotionMenu/CouponListPage';
import CouponInsertPage from 'containers/Pages/PromotionMenu/CouponInsertPage';
import CashAnticipation from 'components/Pages/CashAnticipation';
import ExtractPage from 'components/Pages/ExtractPage';
import RecommendationPageReseller from 'Pages/Revendedor/RecommendationReseller';
import ClosedPage from 'components/Pages/MyAccountPage/PainelAccount/TabsPanel/ClosedPage';
import CancelPage from 'components/Pages/CancelPage';
import PaymentMethods from './PaymentMethods';
import ListCollaborator from 'containers/Pages/PartnerMenu/ListPageCollaborator';
import CollaboratorPage from 'containers/Pages/PartnerMenu/CollaboratorPage';
import CollaboratorDeletedPage from 'containers/Pages/PartnerMenu/ListPageCollaboratorDeleted';
import CollaboratorUpdatedPage from 'containers/Pages/PartnerMenu/CollaboratorUpdatePage';
import ReportTransfer from 'components/Pages/ReportTransferPage';
import RegisterResellerCompanyPage from '../../../containers/Pages/RegisterResellerCompanyPage';

export const PageTabs = {
  0: PanelClientTab,
  1: OrderListPage,
  2: AddressTab,
  3: AccountDetailsTab,
  4: ProductsTab,
  5: NewProduct,
  6: OrdersTabShop,
  7: VirtualCatalogList,
  8: VirtualCatalogInsert,
  9: VirtualCatalogUpdate,
  10: NewProduct,
  11: CashExpressResellerMenu,
  12: CashTranferMenu,
  15: UpdateStorePage,
  16: UserInsertPage,
  17: UserUpdatePage,
  18: OrdersTab,
  19: SalePage,
  20: SaleCommissionPage,
  21: CashExpressResellerMenu,
  22: CouponListPage.apply,
  23: CouponInsertPage,
  24: CashAnticipation,
  25: ExtractPage,
  31: TermsPage,
  32: ClosedPage,
  33: FeaturedProduct,
  34: CancelPage,
  40: RecommendationPageReseller,
  41: PaymentMethods,
  110: Documents,
  70: ListCollaborator,
  71: CollaboratorPage,
  72: CollaboratorDeletedPage,
  42: CollaboratorUpdatedPage,
  44: FinancialTab,
  45: FinancialResellerTab,
  46: sendDealerChargeTab,
  47: SetofPlotsTab,
  90: ReportTransfer,
  91: RegisterResellerCompanyPage,
  400: webhookTab,
};
