/* eslint-disable no-undef */
import React, { useState, useCallback, useRef, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { DialogContentText } from '@material-ui/core';
import appColors from 'utils/appColors';

const pixelRatio = 4;
export default function CropImage({
  image,
  saveCrop = () => {},
  SavePng = () => {},
  editar = false,
  cancel,
  aspect = 1 / 1,
  circularCrop,
  type,
}) {
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect });
  const [completedCrop, setCompletedCrop] = useState(null);

  const onLoad = useCallback(img => {
    imgRef.current = img;
  }, []);

  // TRANFORMA EM PNG
  const transformToPng = () => {
    var image = previewCanvasRef.current.toDataURL('image/png');
    var arr = image.split(',');
    var mime = arr[0].match(/:(.*?);/)[1];
    var bstr = atob(arr[1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const file = new File([u8arr], `${new Date().getTime() * 8 + '.png'}`, {
      type: mime,
    });
    SavePng(file);
  };

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingEnabled = false;

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  return (
    <div
      className="App"
      style={{ padding: 20, minWidth: '50%', alignItems: 'center' }}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div>
          <DialogContentText>Editar imagem</DialogContentText>
          <ReactCrop
            src={image}
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
            }}
            onImageLoaded={onLoad}
            crop={{ ...crop }}
            onChange={c => setCrop(c)}
            onComplete={c => setCompletedCrop(c)}
          />
        </div>
        <div style={{ marginLeft: 20 }}>
          <DialogContentText>Visualização</DialogContentText>
          <div style={{ borderWidth: 3, borderColor: '#ccc' }}>
            <canvas
              ref={previewCanvasRef}
              style={{
                maxWidth: '100%',
                minHeight: 100,
                maxHeight: aspect === 1 / 1 ? 100 : 180,
                minWidth: aspect === 1 / 1 ? 100 : 100,
                border: '3px solid #ccc',
                borderRadius: type === 'cover' ? 2 : circularCrop ? 100 : 10,
              }}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alingItems: 'flex-end',
          marginTop: 50,
        }}>
        {<Button onClick={cancel}>Cancelar</Button>}

        <Button
          id="cortar"
          onClick={() => {
            saveCrop(previewCanvasRef.current);
            transformToPng();
          }}
          style={{ backgroundColor: appColors.PRIMARY_COLOR, color: '#fff' }}>
          {editar ? 'Cortar e Atualizar' : 'Cortar'}
        </Button>
      </div>
    </div>
  );
}
