import { useDispatch } from 'react-redux';
import api from 'services/api';
import { Creators as StoreCreators } from 'store/ducks/admin/stores';
import { Creators as NotificationCreators } from 'store/ducks/app';

export const useStore = () => {
  const dispatch = useDispatch();

  const updatePayment = async (id, data) => {
    try {
      const response = await api.put(`/v1/site/stores/${id}`, data);
      if (response.status !== 200) throw response;
      dispatch(
        NotificationCreators.openNotification({
          message: 'Atualizado com sucesso',
          type: 'success',
        })
      );
      dispatch(StoreCreators.getStoreRequest({ id }));
    } catch (error) {
      console.log(error);
      dispatch(
        NotificationCreators.openNotification({
          message: 'Erro ao atualizar',
          type: 'error',
        })
      );
    }
  };

  return { updatePayment };
};
