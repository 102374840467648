import styled from 'styled-components';
import { Box } from '@material-ui/core';
import appColors from 'utils/appColors';

export const ImageProducts = styled(Box)`
  && {
    margin-bottom: 8px;
    background: url(${props => props.src});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    padding: 20px;

    @media all and (max-width: 500px) {
      width: 100%;
      min-width: 100px;
      height: 200px;
      margin-right: 10px;
      justify-content: center;
    }

    /* @media all and (min-width: 501px) and (max-width: 1024px) {
      width: 210px;
      height: 210px;
    } */

    @media all and (min-width: 841px) {
      justify-content: center;
    }
  }
`;

export const ContainerProducts = styled(Box)`
  && {
    margin-right: -25px;
    margin-bottom: 30px;
    margin-top: 20px;

    @media all and (max-width: 500px) {
      margin-right: 0px;
      padding: 5px;
    }
  }
`;

export const BoxProduct = styled(Box)`
  padding: 10px 10px 10px 20px;
  border: 1px solid #E9E9E9;
  border-radius: 8px;
  background: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media all and (max-width: 500px) {
    width: 100%;
    margin-right: 0px;
    min-width: 120px;
  }
`;

export const BoxGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  margin-bottom: 40px;

  @media (max-width: 900px){
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 500px){
    grid-template-columns: 1fr 1fr ;
  }
`


export const BoxInfo = styled(Box)`
  && {
    margin-bottom: 20px;
    max-width: 300px;
    overflow: hidden;
    align-self: center;

    @media all and (max-width: 500px) {
      padding-left: 0px;
    }

    @media all and (min-width: 501px) and (max-width: 1024px) {
      max-width: 200px;
      overflow: hidden;
    }
  }
`;

const styles = {
  purpleTitle: {
    color: appColors.SECUNDARY_COLOR,
    fontWeight: 'bold',
    fontSize: 32,
    textAlign: 'center',
  },
  ProductPrice: {
    color: '#505255',
    fontSize: 18,
    fontWeight: 'normal',
    lineHeight: '22px',
    marginLeft: 16
  },
  smallSubTitle: {
    color: '#373737',
  },
};

export default styles;
