/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import ActionFab from 'components/Actions/ActionFab';
import ActionMenu from 'components/Actions/ActionMenu';
import ActionMenuItem from 'components/Actions/ActionMenuItem';
import { useHistory } from 'react-router-dom';

import { Creators as PanelTabCreators } from 'store/ducks/panelTabs';
import { useDispatch } from 'react-redux';

const ProductActions = ({ rowData, onDeleteRequest }) => {
  const dispatch = useDispatch();
  const [localState, setLocalState] = React.useState({
    anchorEl: null,
  });

  const handleTab = (value, tabParams = {}) => {
    window.scrollTo(0, 0);
    dispatch(PanelTabCreators.changeTab(value, tabParams));
  };

  const handleClick = event => {
    setLocalState({ anchorEl: event.currentTarget });
  };

  const handleClose = () => {
    setLocalState({ anchorEl: null });
  };

  const onDelete = () => {
    onDeleteRequest(rowData);
  };

  const history = useHistory();

  return (
    <>
      <ActionFab
        data-cy="btn_product_action"
        icon="more_vert"
        onClick={handleClick}
      />
      <ActionMenu
        anchorEl={localState.anchorEl}
        onClose={handleClose}
        open={Boolean(localState.anchorEl)}>
        <ActionMenuItem
          data-cy="btn_product_edit"
          onClick={() => {
            handleTab(10);
            history.push({
              search: `?id_produto=` + rowData.id,
            });
          }}>
          <a>Editar</a>
        </ActionMenuItem>
        <ActionMenuItem
          data-cy="btn_see_product"
          onClick={() => {
            history.push(`produto/${rowData.slug}`);
          }}>
          <a>Ver Produto</a>
        </ActionMenuItem>
        <ActionMenuItem data-cy="btn_product_delete" onClick={onDelete}>
          Remover
        </ActionMenuItem>
      </ActionMenu>
    </>
  );
};

ProductActions.propTypes = {
  rowData: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onDeleteRequest: PropTypes.func,
};

ProductActions.defaultProps = {
  onDeleteRequest: () => {},
};

export default ProductActions;
