import React from 'react';
import PropTypes from 'prop-types';

const NewOrderStatus = ({ rowData }) =>
  rowData.view && (
    <div
      style={{
        width: 10,
        height: 10,
        backgroundColor: '#19eaff',
        borderRadius: 30,
        marginRight: 6,
      }}
    />
  );

NewOrderStatus.propTypes = {
  rowData: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default NewOrderStatus;
