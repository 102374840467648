/* eslint-disable no-undef */
import { create } from 'apisauce';
import appConfig from 'utils/appConfig';

const apiUrl = {
  hml: 'http://localhost:4010',
  prod: 'https://api.simerpay.com.br',
};

const apiSocketUrl = {
  hml: 'http://localhost:4000',
  prod: 'https://socket.api.sensiconnect.io/',
};

export const baseSocket =
  process.env.NODE_ENV === 'production' ? apiSocketUrl.prod : apiSocketUrl.hml;

export const getDefaultAPI =
  process.env.NODE_ENV === 'production'
    ? appConfig.SITE_WEB
    : 'http://localhost:3000';

const api = create({
  baseURL: process.env.NODE_ENV === 'production' ? apiUrl.prod : apiUrl.hml,
  headers: {
    Authorization: '',
  },
});

export default api;
