import appColors from 'utils/appColors';
const styles = {
  purpleTitle: {
    color: appColors.SECUNDARY_COLOR,
    fontWeight: 'bold',
    fontSize: 32,
  },
};

export default styles;
