import React from 'react';
import { Box, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from 'react-router-dom';
import { ContainerHeader, Title, Breadcrumbs } from './styles';

const HeaderCategoriesMobi = ({ title }) => {
  return (
    <ContainerHeader>
      <Box display="flex" justifyContent="flex-start">
        <Title>{title}</Title>
      </Box>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb">
        <Link to="/">Início</Link>
        <Typography color="textPrimary">{title}</Typography>
      </Breadcrumbs>
    </ContainerHeader>
  );
};

export default HeaderCategoriesMobi;
