import React from 'react';
import {
  Box,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import { InputContainer, InputItem } from 'components/form/StyledComponents';
import CustomTextField from 'components/form/CustomTextField';
import * as Yup from 'yup';
import CustomSelect from 'components/form/CustomSelect';
import validators from 'utils/validators';
import CustomMaskField from 'components/form/CustomMaskField';
import appColors from 'utils/appColors';
import styles from './styles';
import moment from 'moment';
import appNaming from 'utils/appNaming';
import { Helmet } from 'react-helmet';

const RegisterClientForm = ({ onSubmit, initialValues, isLoading }) => {
  const schema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Nome inválido')
      .test('name-complete', 'Preencha seu nome completo', val => {
        const test = val?.split(' ');
        if (test && test?.length < 2) return false;
        if (test && test?.[1] === '') return false;
        return true;
      })
      .required('Este campo é obrigatório'),
    email: Yup.string()
      .email('Este email não é valido')
      .required('Este campo é obrigatório'),
    cpf: Yup.string()
      .test('valid-length', 'Preencha o campo com CPF/CNPJ válido', val => {
        return val?.length > 14
          ? validators.cnpjInvalid('CNPJ inválido')
          : validators.cpfInvalid('CPF inválido');
      })
      .required('Este campo é obrigatório'),
    birth_date: Yup.mixed()
      .test('valid-date', 'Por Favor, insira uma data válida', val =>
        moment(val, 'DD/MM/YYYY').isValid()
      )
      .test('valid-length', 'Por Favor, insira uma data válida', val => {
        return val ? val.replace(/[/_-]/g, '')?.length === 8 : false;
      })
      .test('is-of-age', 'É necessário ser maior de 18 anos', val => {
        return moment().diff(moment(val, 'DD/MM/YYYY'), 'year') >= 18;
      }),
    cell_phone: Yup.string().required('Este campo é obrigatório'),
    genre: Yup.string().required('Escolha o gênero'),
    password: Yup.string()
      .required('Este campo é obrigatório')
      .min(6, 'A senha deve ter no mínimo 6 dígitos'),
    confirmPassword: Yup.string().test(
      'passwords-match',
      'Senhas não correspondem',
      function (value) {
        return this.parent.password === value;
      }
    ),
    accept_terms: Yup.mixed().required(
      'É necessário aceitar os termos para continuar.'
    ),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      validateOnBlur
      onSubmit={onSubmit}
      render={({ handleSubmit, setFieldValue, errors, values }) => (
        <Box flex="1" display="flex" flexDirection="column">
          <Helmet>
            <meta name="kdt:page" content="account" />
          </Helmet>
          <Form onSubmit={handleSubmit}>
            <InputContainer style={{ marginBottom: 35 }}>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Nome <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  data-cy="input_name_cliente"
                  name="name"
                  placeholder="Informe seu nome"
                  component={CustomTextField}
                />
              </InputItem>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Email <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  data-cy="input_email_cliente"
                  name="email"
                  placeholder="Informe seu email. Ex: seuemail@email.com"
                  component={CustomTextField}
                />
              </InputItem>
            </InputContainer>
            <InputContainer style={{ marginBottom: 25 }}>
              <InputItem>
                <Typography style={styles.titleInput}>
                  CPF/CNPJ<span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="cpf"
                  placeholder="Informe seu CPF/CNPJ"
                  mask={
                    values.cpf.length && values.cpf.length > 14
                      ? '99.999.999/9999-99'
                      : '999.999.999-999'
                  }
                  component={CustomMaskField}
                />
              </InputItem>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Data de Nascimento <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="birth_date"
                  placeholder="Informe sua data de nascimento"
                  mask="99/99/9999"
                  component={CustomMaskField}
                />
              </InputItem>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Gênero <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="genre"
                  options={[
                    { id: 'F', name: 'Feminino' },
                    { id: 'M', name: 'Masculino' },
                    { id: 'O', name: 'Outro' },
                  ]}
                  placeholder="Informe o seu sexo"
                  isLoading={false}
                  component={CustomSelect}
                />
              </InputItem>
            </InputContainer>
            <InputContainer style={{ marginBottom: 35 }}>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Celular <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  data-cy="input_cellphone_cliente"
                  name="cell_phone"
                  placeholder="Informe um número para contato"
                  mask="(99) 9 9999-9999"
                  component={CustomMaskField}
                />
              </InputItem>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Senha <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  data-cy="input_pwd_cliente"
                  autoComplete="new-password"
                  name="password"
                  type="password"
                  label="Informe a sua senha"
                  component={CustomTextField}
                />
              </InputItem>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Confirmação de senha <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  data-cy="input_confirm_pwd_cliente"
                  name="confirmPassword"
                  type="password"
                  label="Confirme a sua senha"
                  component={CustomTextField}
                />
              </InputItem>
            </InputContainer>
            <Box
              flex="1"
              style={{ marginBottom: 40, maxWidth: 600, marginTop: 20 }}>
              <Typography style={styles.titleInput}>
                Políticas e Termos <span style={{ color: 'red' }}>*</span>
              </Typography>
              <FormControlLabel
                style={{ alignItems: 'flex-start', marginTop: 10 }}
                control={
                  <Checkbox
                    checked={!!values.accept_terms}
                    onChange={() => {
                      if (values.accept_terms) {
                        setFieldValue('accept_terms', null);
                        return;
                      }
                      setFieldValue('accept_terms', true);
                    }}
                    data-cy="check_accept_terms"
                    style={{ color: appColors.PRIMARY_COLOR, paddingTop: 0 }}
                  />
                }
                label={
                  <Typography style={{ color: '#444' }}>
                    {`Aceito os `}
                    <a
                      style={{
                        fontWeight: 'bold',
                        color: appColors.PRIMARY_COLOR,
                      }}
                      href="/pagina/termos-de-uso?client=true&terms=true"
                      target="_blank">
                      Termos e condições
                    </a>
                    {` e autorizo o uso dos meus dados de acordo com a `}
                    <a
                      style={{
                        fontWeight: 'bold',
                        color: appColors.PRIMARY_COLOR,
                      }}
                      href="/pagina/termos-de-uso?client=true&policy=true"
                      target="_blank">
                      Política de privacidade
                    </a>
                    {` do ${appNaming.REGULAR_NAMING}.`}
                  </Typography>
                }
              />
              {typeof errors.accept_terms !== 'undefined' && (
                <Typography style={{ color: 'red', marginTop: 10 }}>
                  {errors.accept_terms}
                </Typography>
              )}
            </Box>
            <Button
              data-cy="btn_register_client_part1"
              type="submit"
              disabled={isLoading}
              style={styles.purpleButtonProceed}>
              Prosseguir
              {isLoading && (
                <CircularProgress
                  data-cy="loading_register_cliente"
                  size={15}
                  style={{ color: 'white', marginLeft: 10 }}
                />
              )}
            </Button>
          </Form>
        </Box>
      )}
    />
  );
};

export default RegisterClientForm;
