// @flow
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import appUtils from 'utils/appUtils';
import styles, { BoxColors, Img } from './styles';
import { type ProccessCartReturn } from 'utils/checkoutUtils';
import { Helmet } from 'react-helmet';
import appColors from 'utils/appColors';

type ItemsCardProps = {
  proccessCart: ProccessCartReturn,
};

const ItemsCard = ({ proccessCart }: ItemsCardProps) => (
  <>
    <Box
      display="flex"
      flex="1"
      flexDirection="row"
      style={{ backgroundColor: '#e8e8e8', padding: 20, marginTop: 20 }}>
      <Box style={{ flexGrow: '4' }}>
        <Typography style={styles.smallTextBlackBold}>
          Itens do pedido
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" style={{ flexGrow: '2' }}>
        <Typography style={styles.smallTextBlackBold}>Qtde</Typography>
      </Box>
      <Box display="flex" justifyContent="center" style={{ minWidth: 70 }}>
        <Typography style={styles.smallTextBlackBold}>Preço</Typography>
      </Box>
    </Box>
    <Box display="flex" flexDirection="column">
      {Object.values(proccessCart.productsAgruppedByStore)
        .flatMap(x => x)
        .map(product => {
          const productValues = proccessCart.getValuesForProduct(
            product.store.id,
            product.id,
            product
          );
          return (
            <Box
              key={product.code_internal}
              display="flex"
              flex="1"
              flexDirection="row"
              style={{
                padding: 20,
                borderBottom: '1px solid ' + appColors.PRIMARY_COLOR,
              }}>
              {/* INTEGRAÇÃO KDT = TELA DE FINALIZAÇÃO DE PEDIDO*/}
              <Helmet>
                <meta name="kdt:page" content="checkout" />
                <meta
                  name="kdt:checkout"
                  content={`sku:${product.id}, name=${product.name}`}
                  charSet="utf-8"
                />
              </Helmet>
              <Box
                data-cy="details_order_itens_image"
                display="flex"
                flexDirection="row"
                style={{ flexGrow: '4' }}>
                <Img alt="imagem" src={product.image} />
                <Box
                  data-cy="details_order_itens_name"
                  style={{ paddingLeft: 10 }}>
                  <Typography>{product.name}</Typography>
                  <Box
                    data-cy="details_order_itens_variation"
                    style={{ display: 'flex', alignItems: 'center' }}>
                    Variação:
                    {typeof product.color.hexa !== 'undefined' && (
                      <BoxColors hexa={product.color.hexa} />
                    )}
                    {product.color.name}
                  </Box>
                  <Typography>Tamanho: {product.size.name}</Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                style={{ flexGrow: '2' }}>
                <Typography>{product.quantity}x </Typography>
              </Box>
              <Box
                data-cy="details_order_itens_price_product"
                display="flex"
                flexDirection="column">
                {productValues.temDesconto && (
                  <Typography component="div" style={styles.smallTextBlackBold}>
                    {appUtils.formatPrice(
                      productValues.valorPorProdutoComDesconto
                    )}
                  </Typography>
                )}
                <Typography
                  component="div"
                  style={{
                    ...styles.smallTextBlackBold,
                    ...(productValues.temDesconto && {
                      textDecoration: 'line-through',
                      fontSize: 12,
                    }),
                  }}>
                  {appUtils.formatPrice(productValues.price)}
                </Typography>
              </Box>
            </Box>
          );
        })}
    </Box>
  </>
);

export default ItemsCard;
