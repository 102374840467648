/* eslint-disable react/jsx-key */
import React from 'react';
import PageBase from 'components/PageBase';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as WalletCreators } from 'store/ducks/admin/wallet';
import { Creators as NotificationCreators } from 'store/ducks/app';
import HeaderComponent from 'components/HeaderComponent';
import { CircularProgress } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { Formik, Form, FastField } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import appColors from 'utils/appColors';
import CustomCurrencyField from 'components/form/components/CustomCurrencyField';
import CustomButton from 'components/form/CustomButton';
import appUtils from 'utils/appUtils';

import {
  BoxSuperior,
  BoxValue,
  BoxParcelas,
  ResultContainer,
  ResultBox,
  Title,
  Forms,
  LinkHref,
  BoxItem,
  BoxTitle,
  FeesView,
  FeesItem,
  FeesPrice,
  FeesTitle,
} from './styles';
import appNaming from 'utils/appNaming';

const initialValues = {
  amount: '',
  password: '',
};

const schema = Yup.object().shape({
  amount: Yup.string().required('Campo obrigatório'),
  password: Yup.string().required('Confirme a senha'),
});

const CashAnticipation = () => {
  const { dashboard, dashboardLoading } = useSelector(
    state => state.admin.dashboard
  );
  const { calc_anticipation, calcLoading } = useSelector(
    state => state.admin.wallet.calc_anticipation
  );
  const formRef = React.useRef(null);
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = React.useState(false);
  const [openTable, setOpenTable] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [policy, setPolicy] = React.useState(false);

  const cashSubmit = () => {
    //
  };

  const getAnticipation = () => {
    var amount = Number(calc_anticipation.summary.amount.request).toFixed(2);
    dispatch(WalletCreators.getAnticipationRequest(amount));
    // SE DER TUDO OK, ELE MOSTRA A NOTIFICAÇÃO DE SUCCESS/ERROR
  };

  const getCalculate = () => {
    setOpenTable(false);
    setIsError(false);
    if (formRef.current) {
      if (formRef.current.state.values.amount === '') {
        dispatch(
          NotificationCreators.openNotification({
            message: 'Informe o valor.',
            type: 'error',
          })
        );
      } else if (!policy) {
        setIsError(true);
        dispatch(
          NotificationCreators.openNotification({
            message: 'Aceite as políticas de antecipação.',
            type: 'error',
          })
        );
      } else if (
        parseFloat(formRef.current.state.values.amount) >
        parseFloat(dashboard.cash.future)
      ) {
        dispatch(
          NotificationCreators.openNotification({
            message: 'Quantidade solicitada excede ao permitido.',
            type: 'error',
          })
        );
      } else {
        dispatch(
          WalletCreators.getWalletAnticipationsCalc(
            formRef.current.state.values.amount
          )
        );
      }
    }
  };

  if (dashboardLoading)
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '20px',
        }}>
        <CircularProgress style={{ color: appColors.PRIMARY_COLOR }} />
      </div>
    );

  return (
    <PageBase>
      <HeaderComponent title="Antecipação" />
      {typeof dashboard.cash === 'object' && dashboard.cash.future !== 0 ? (
        <>
          <Grid container spacing={3}>
            <Grid data-cy="box_next_antecipation" item xs={12} md={6}>
              <BoxSuperior>
                <h3>PRÓXIMAS LIBERAÇÕES</h3>
                <strong>{appUtils.formatPrice(dashboard.cash.future)}</strong>
                <span>Faça sua antecipação</span>
                <MonetizationOnIcon />
              </BoxSuperior>
            </Grid>
            <Grid item xs={12} md={6}>
              <BoxSuperior
                style={{
                  textAlign: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                O valor da sua antecipação não pode ser maior que o saldo das
                próximas liberações. Taxa de serviço podem ser cobradas.
              </BoxSuperior>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Title>SIMULE UMA ANTECIPAÇÃO</Title>

              <BoxValue item xs={12} sm={12}>
                <Forms>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={schema}
                    onSubmit={cashSubmit}
                    validateOnBlur
                    enableReinitialize
                    ref={formRef}
                    render={() => (
                      <Form>
                        <FastField
                          name="amount"
                          label="Valor"
                          component={CustomCurrencyField}
                        />
                      </Form>
                    )}
                  />

                  <div style={{ marginTop: '10px' }}>
                    <input
                      type="checkbox"
                      value={policy}
                      onChange={() => setPolicy(!policy)}
                    />
                    <span style={{ color: isError ? '#f00' : '#343434' }}>
                      Li e estou de acordo com as
                    </span>
                    <LinkHref onClick={() => setOpenModal(true)}>
                      políticas da antecipação.
                    </LinkHref>
                  </div>

                  <CustomButton
                    style={{
                      width: '100%',
                      paddingRight: 20,
                      paddingLeft: 20,
                      marginTop: '20px',
                      backgroundColor: appColors.COLOR_ICON,
                    }}
                    data-cy="btn_simulate_anticipation"
                    autocomplete="off"
                    label="Simular Antecipação"
                    Icon={false}
                    onClick={getCalculate}
                  />
                </Forms>
              </BoxValue>

              {calcLoading && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '20px',
                  }}>
                  <CircularProgress
                    style={{ color: appColors.PRIMARY_COLOR }}
                  />
                </div>
              )}
              {!calcLoading && calc_anticipation.summary && (
                <ResultContainer>
                  <ResultBox>
                    <BoxTitle>VALOR SOLICITADO</BoxTitle>
                    <BoxItem>
                      {appUtils.formatPrice(
                        calc_anticipation.summary.amount.request
                      )}
                    </BoxItem>
                  </ResultBox>

                  <ResultBox proposed>
                    <BoxTitle proposed>VALOR PROPOSTO</BoxTitle>
                    <BoxItem proposed>
                      {appUtils.formatPrice(
                        calc_anticipation.summary.amount.released
                      )}
                    </BoxItem>
                  </ResultBox>

                  <ResultBox>
                    <BoxTitle>VALOR A RECEBER</BoxTitle>
                    <BoxItem>
                      {appUtils.formatPrice(
                        calc_anticipation.summary.amount.liquid
                      )}
                    </BoxItem>
                  </ResultBox>
                </ResultContainer>
              )}

              <div id="taxas_antecipacao">
                {calcLoading ? (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '20px',
                    }}>
                    <CircularProgress
                      style={{ color: appColors.PRIMARY_COLOR }}
                    />
                  </div>
                ) : (
                  calc_anticipation.summary && (
                    <React.Fragment>
                      <FeesView>
                        <FeesItem>
                          <FeesTitle>TAXA TRANSACIONAL</FeesTitle>
                          <FeesPrice>
                            {calc_anticipation.summary.amount.liquid === 0
                              ? '---'
                              : (
                                  (calc_anticipation.summary.rate * 100) /
                                  calc_anticipation.summary.amount.released
                                ).toFixed(2)}
                            %
                          </FeesPrice>
                        </FeesItem>
                        <FeesItem>
                          <FeesTitle>TAXA FIXA</FeesTitle>
                          <FeesPrice>
                            {appUtils.formatPrice(
                              calc_anticipation.summary.rate
                            )}
                          </FeesPrice>
                        </FeesItem>
                      </FeesView>

                      <CustomButton
                        style={{
                          width: '100%',
                          paddingRight: 20,
                          paddingLeft: 20,
                          marginTop: '20px',
                          backgroundColor: appColors.COLOR_ICON,
                        }}
                        autocomplete="off"
                        label="Veja a Proposta"
                        Icon={SearchIcon}
                        onClick={() => setOpenTable(true)}
                      />
                    </React.Fragment>
                  )
                )}
              </div>

              <section style={{ color: '#666', marginTop: '30px' }}>
                <strong style={{ width: '100%' }}>Observações: </strong>
                Importante: esta calculadora exemplifica a forma que a{' '}
                {appNaming.REGULAR_NAMING} realiza a cobrança de taxas de
                antecipação e taxa transacional, caso você esteja utilizando a{' '}
                {appNaming.REGULAR_NAMING} em uma plataforma, confira com nosso
                suporte como o cálculo é realizado.
              </section>
            </Grid>

            <BoxParcelas
              item
              xs={12}
              md={6}
              style={{ display: openTable ? '' : 'none' }}>
              <div>
                <h3>VALORES E TAXAS</h3>
                <table>
                  <thead>
                    <th>DATA</th>
                    <th>VALOR</th>
                    <th>TAXA</th>
                    <th>%</th>
                    <th>LÍQUIDO</th>
                  </thead>
                  {calc_anticipation.result !== undefined &&
                    calc_anticipation.result.map(result => (
                      <tr>
                        <td>{moment(result.date).format('DD/MM/YYYY')}</td>
                        <td>{appUtils.formatPrice(result.amount.released)}</td>
                        <td>{appUtils.formatPrice(result.rate)}</td>
                        <td>
                          {(
                            (result.rate / result.amount.released) *
                            100
                          ).toFixed(2)}
                        </td>
                        <td>{appUtils.formatPrice(result.amount.liquid)}</td>
                      </tr>
                    ))}
                </table>

                <CustomButton
                  onClick={getAnticipation}
                  style={{
                    width: '100%',
                    paddingRight: 20,
                    paddingLeft: 20,
                    marginTop: '20px',
                    backgroundColor: appColors.COLOR_ICON,
                  }}
                  label="Solicitar Antecipação"
                  Icon={false}
                />
              </div>
            </BoxParcelas>
          </Grid>

          <Dialog
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
              {'Política de antecipação'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                style={{ textAlign: 'justify' }}>
                Valor efetivo da proposta pode variar do valor proposto na
                simulação devido a chargebacks e reembolsos durante o processo.
                A antecipação é um processo de adiantamento dos recebíveis,
                cobrando pelo valor do dinheiro no tempo e não deve ser
                confundido com um empréstimo. A taxa aplicada é cobrada usando
                modelo pro rata. Não é possível cancelar uma antecipação após
                sua solicitação. O {appNaming.REGULAR_NAMING} se dá ao direito
                de cortar acesso ao produto e revisar os limites quando quiser
                sem necessidade de aviso prévio.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setOpenModal(false)}
                color="primary"
                autoFocus>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <Grid container spacing={3}>
          <Grid data-cy="box_text_insufficient_funds" item xs={12} md={6}>
            <BoxSuperior>
              <h3>NÃO HÁ SALDO SUFICIENTE PARA LIBERAÇÃO</h3>
              <span>
                Aguarde seu saldo ficar positivo para realizar sua antecipação
              </span>
              <MonetizationOnIcon />
            </BoxSuperior>
          </Grid>
          <Grid item xs={12} md={6}>
            <BoxSuperior
              style={{
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              O valor da sua antecipação não pode ser maior que o saldo das
              próximas liberações. Taxa de serviço podem ser cobradas.
            </BoxSuperior>
          </Grid>
        </Grid>
      )}
    </PageBase>
  );
};

export default CashAnticipation;
