import { useState } from 'react';
import api from 'services/api';

const useBusiness = () => {
  const [business, setBusiness] = useState({
    data: [],
    total: 0,
    loading: false,
    error: null,
  });

  const getBusiness = async id => {
    setBusiness({ loading: true, error: null, data: [] });
    try {
      const response = await api.get(`/v1/site/business/partner/${id}`);
      if (response.status !== 200) {
        throw response;
      }
      setBusiness({
        data: response.data.data,
        loading: false,
        error: null,
        total: response.data.total,
      });
    } catch (err) {
      setBusiness({
        loading: true,
        error: 'Não foi possivel encontrar',
        data: [],
      });
    }
  };

  const [search, setSearch] = useState(null);
  const Search = async (text, id) => {
    if (text) {
      try {
        const response = await api.get(
          `/v1/site/business/partner/${id}/search`,
          {
            search: text,
          }
        );
        if (response.status !== 200) {
          throw response;
        }
        setSearch(response.data.data);
      } catch (error) {
        setSearch('404');
      }
    } else {
      setSearch(null);
    }
  };

  return {
    business,
    getBusiness,
    search,
    Search,
  };
};

export default useBusiness;
