/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PageBase from 'components/PageBase';
import DefaultTable from 'components/Tables/DefaultTable';
import { Paper } from '@material-ui/core';
import HeaderComponent from 'components/HeaderComponent';
import CollaboratorTableHeader from './InstallmentsTableHeader';
import CollaboratorActions from './InstallmentsActions';
import { toPrice, formatDate2 } from 'utils/converters';
import { useInvoice } from 'hooks/invoice';

function renderSwitch(param) {
  switch (param) {
    case 'WAITING_APPROVAL':
      return 'Aguardando Aprovação';
    case 'PENDING':
      return 'Pendente';
    case 'BANK_PROCESSING':
      return 'Em processamento bancário';
    case 'DONE':
      return 'Efetivada';
    case 'CANCELLED':
      return 'Cancelada';
    case 'RECEIVED':
      return 'Recebida';
    case 'FAILED':
      return 'Falha na transferência. Normalmente ocorre em caso de dados bancários inválidos';
    default:
      return 'Cancelada';
  }
}
const columns = () => [
  {
    title: 'ID',
    field: 'id',
    render: rowData => <span>{rowData.id}</span>,
  },
  {
    title: 'Cliente',
    field: 'client',
    render: rowData => <span>{rowData.client.name}</span>,
  },
  {
    title: 'Status',
    field: 'status',
    render: rowData => <span>{renderSwitch(rowData.status)}</span>,
  },
  {
    title: 'Preço Total',
    field: 'total_value',
    render: rowData => <span>R$ {toPrice(rowData.total_value)}</span>,
  },
  {
    title: 'Parcelas',
    field: 'installment_count',
    render: rowData => <span>{rowData.installment_count}</span>,
  },
  {
    title: 'Descrição',
    field: 'description',
    render: rowData => <span>{rowData.description}</span>,
  },
  {
    title: 'Vencimento',
    field: 'dueDate',
    render: rowData => <span>{formatDate2(rowData.due_date)}</span>,
  },
  {
    title: 'Boletos',
    // eslint-disable-next-line react/display-name
    render: rowData => <CollaboratorActions rowData={rowData} />,
  },
];

const InstallmentsListPage = () => {
  const { invoiceData, invoiceLoading, GetInvoice, invoiceListTotal } =
    useInvoice();

  const [tranferState, setTranferState] = React.useState({
    open: false,
    item: {},
  });
  const [localState, setLocalState] = React.useState({
    page: 1,
    perPage: 10,
  });

  React.useEffect(() => {
    GetInvoice(localState);
  }, [localState]);

  const getFunction = data => {
    setLocalState(oldLocalState => ({ ...oldLocalState, ...data }));
  };

  const onTranferRequest = item => {
    console.log(tranferState);
    setTranferState({ open: true, item });
  };

  return (
    <PageBase>
      <HeaderComponent title="Listar Cobranças">
        <CollaboratorTableHeader
          getFunction={getFunction}
          initialValues={{ search: localState.search }}
        />
      </HeaderComponent>
      <Paper>
        <DefaultTable
          getFunction={getFunction}
          columns={columns({ onTranferRequest, invoiceData })}
          data={invoiceData}
          total={invoiceListTotal}
          isLoading={invoiceLoading}
          page={localState.page}
          perPage={localState.perPage}
        />
      </Paper>
    </PageBase>
  );
};

export default InstallmentsListPage;
