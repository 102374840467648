import styled from 'styled-components';
import { Box } from '@material-ui/core';
import appColors from 'utils/appColors';

export const CustomQntInput = styled(Box)`
    && {
        background-color:${appColors.LIGHT_COLOR2};
        display:flex;
        align-items:center;
        flex:1;
        max-width:200px;
        border-radius:50px;
        margin-right:20px;

    }
`;


const styles = {
    purpleButton: {
        backgroundColor: appColors.PRIMARY_COLOR,
        color: 'white',
        paddingLeft: 20,
        paddingRight: 20,
    },
    smallTextPurple: {
        color:appColors.SECUNDARY_COLOR,
        fontSize:14,
        fontWeight:'bold',
    },
    textMediumPurple: {
        color: '#fff',
        fontSize:16,
        fontWeight:'bold',
    },
}

export default styles;