/* eslint-disable react/jsx-key */
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import visaFlag from 'images/CardFlags/029-visa.svg';
import amexFlag from 'images/CardFlags/amex.png';
import dinnersFlag from 'images/CardFlags/dinners.png';
import hiperFlag from 'images/CardFlags/hiper.png';
import hipercardFlag from 'images/CardFlags/hipercard.png';
import masterFlag from 'images/CardFlags/master.png';
import boleto from 'images/CardFlags/boleto.png';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles, { SecondaryFooter, BoxContainer } from './styles';

const Footer = () => {
  const { config, configLoading } = useSelector(state => state.config);
  const { client } = useSelector(state => state.client);
  const { isAuth } = useSelector(state => state.auth);

  const address = config.shopping.address;
  const shopping = config.shopping;

  const terms_verify = terms => {
    if (isAuth && client.group !== undefined) {
      if (terms.has_store === true && client.group.id === 3) return true;
      if (terms.has_user === true && client.group.id === 5) return true;
      if (terms.has_partner === true && client.group.id !== 3) return true;
    }
    if (!terms.has_partner && !terms.has_store && !terms.has_user) return true;
    return false;
  };

  return (
    <>
      <SecondaryFooter flex="1" display="flex" justifyContent="center">
        <BoxContainer
          display="flex"
          flexDirection="row"
          justifyContent="center"
          flexWrap="wrap"
          flex="1">
          <Box display="flex" flex="1">
            <Box flexDirection="column" style={styles.columns}>
              <Typography style={styles.mainTitle}>{shopping.name}</Typography>
              {!configLoading &&
                Object.keys(config).length > 0 &&
                config.menu_footer1.map(
                  pages =>
                    terms_verify(pages) === true && (
                      <Link
                        key={pages.link}
                        to={`${pages.route_web}`}
                        style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Typography style={styles.fontSmall}>
                          {pages.name}
                        </Typography>
                      </Link>
                    )
                )}
            </Box>
          </Box>
          <Box display="flex" flex="1">
            <Box flexDirection="column" style={styles.columns}>
              <Typography style={styles.mainTitle}>Extras</Typography>
              {!configLoading &&
                Object.keys(config).length > 0 &&
                config.menu_footer2.map(sites => (
                  <Link
                    key={sites.link}
                    to={`${sites.route_web}`}
                    style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography style={styles.fontSmall}>
                      {sites.name}
                    </Typography>
                  </Link>
                ))}
            </Box>
          </Box>
          <Box display="flex" flex="1">
            <Box flexDirection="column" style={styles.columns}>
              <Typography style={styles.mainTitle}>
                Atendimento ao Cliente
              </Typography>
              <Typography style={styles.fontSmall}>
                {shopping.business_hours}
              </Typography>
              <Typography style={styles.fontSmall}>
                {address.street}, {address.district}, N {address.number}.
                <br />
                {address.code_post} - {address.city.name}
              </Typography>
              <Typography style={styles.fontSmall}>
                {shopping.cell_phone}
              </Typography>
              <Typography style={styles.fontSmall}>
                {shopping.shopping_global.email_name}
              </Typography>
            </Box>
          </Box>
          <Box flexDirection="column" style={styles.columns}>
            <Typography style={styles.mainTitle}>
              Formas de pagamento
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              flex="1"
              style={{ marginTop: 20 }}>
              {config.payments.map(item => (
                <Box flexDirection="column" flex="1">
                  <Typography
                    style={
                      ([styles.fontSmaller],
                      { fontWeight: 'bold', fontSize: 14 })
                    }>
                    {item.name}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              style={{ maxWidth: 300, marginTop: 20 }}>
              <img alt="" src={visaFlag} style={styles.imgFlag} />
              <img alt="" src={masterFlag} style={styles.imgFlag} />
              <img alt="" src={amexFlag} style={styles.imgFlag} />
              <img alt="" src={dinnersFlag} style={styles.imgFlag} />
              <img alt="" src={hiperFlag} style={styles.imgFlag} />
              <img alt="" src={hipercardFlag} style={styles.imgFlag} />
              <img alt="" src={boleto} style={styles.imgFlag} />
            </Box>

            <Box
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              flex="1"
              style={{ marginTop: 20 }}>
              <Typography style={styles.fontSmaller}>
                {shopping.cnpj}
              </Typography>
            </Box>
          </Box>
        </BoxContainer>
      </SecondaryFooter>
    </>
  );
};

export default Footer;
