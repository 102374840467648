/* eslint-disable react/jsx-key */
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import appColors from 'utils/appColors';

const DescriptionParsed = ({ descriptionTec }) => {
  const descriptionTecSplited = descriptionTec.split('\n');
  return (
    <>
      {descriptionTecSplited.map((description, i) => (
        <div key={i}>
          {description.split('**').map((desc, index) => {
            if (description.indexOf(`**${desc}**`) >= 0) {
              return <b key={index}>{desc}</b>;
            }
            return <span key={index}>{desc}</span>;
          })}
          <br />
        </div>
      ))}
    </>
  );
};

DescriptionParsed.propTypes = {
  descriptionTec: PropTypes.string.isRequired,
};

const DescTecProduct = ({ data }) => (
  <>
    {data.description_tec.length > 0 && (
      <Box>
        <Typography
          variant="h4"
          style={{ color: appColors.SECUNDARY_COLOR, marginBottom: 20 }}>
          Descrição técnica
        </Typography>
        <DescriptionParsed descriptionTec={data.description_tec} />
      </Box>
    )}
  </>
);

DescTecProduct.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default DescTecProduct;
