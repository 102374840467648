import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import styles from './styles';
import { InputContainer, InputItem } from 'components/form/StyledComponents';
import CustomTextField from 'components/form/CustomTextField';
import * as Yup from 'yup';

export const initialValues = {
  username: '',
  password: '',
};

const schema = Yup.object().shape({
  username: Yup.string()
    .email('Este email não é valido')
    .required('Este campo é obrigatório'),
  password: Yup.string()
    .required('Este campo é obrigatório'),
});

const LoginForm = ({ onSubmit, initialValues }) => {

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      validateOnBlur
      render={({ handleSubmit }) => (
        <Box flex='1' display='flex' flexDirection='column' style={{ minWidth: 200 }}>
          <Form onSubmit={handleSubmit} style={{ marginBottom: 20 }}>
            <InputContainer>
              <InputItem style={{ margin: 0, }}>
                <Typography>E-mail</Typography>
                <Field
                  name="username"
                  id="emailUsuario"
                  label="Informe seu E-mail"
                  component={CustomTextField}
                />
              </InputItem>
            </InputContainer>
            <InputContainer>
              <InputItem style={{ margin: 0, }}>
                <Typography>Senha</Typography>
                <Field
                  name="password"
                  id="senhaUsuario"
                  type="password"
                  label="Informe sua senha"
                  component={CustomTextField}
                />
              </InputItem>
                <Button id="btnAcessarConta" type='submit' style={styles.purpleButton}>Acessar Conta</Button>
            </InputContainer>
          </Form>
        </Box>
      )} />

  );
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

LoginForm.defaultProps = {
  initialValues,
}

export default LoginForm;




