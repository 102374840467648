// @flow
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import styles, { Img } from './styles';
import appColors from 'utils/appColors';
import appUtils from 'utils/appUtils';
import ReactMarkdown from 'react-markdown';

const ServicesCard = ({ services }) => (
  <>
    <Box
      display="flex"
      flex="1"
      flexDirection="row"
      style={{ backgroundColor: '#e8e8e8', padding: 20, marginTop: 20 }}>
      <Box style={{ flexGrow: '4' }}>
        <Typography style={styles.smallTextBlackBold}>Mão de obra</Typography>
      </Box>
      <Box display="flex" justifyContent="center" style={{ flexGrow: '2' }}>
        <Typography style={styles.smallTextBlackBold}>Qtde</Typography>
      </Box>
      <Box display="flex" justifyContent="center" style={{ minWidth: 70 }}>
        <Typography style={styles.smallTextBlackBold}>Preço</Typography>
      </Box>
    </Box>
    <Box display="flex" flexDirection="column">
      {services?.map(service => {
        return (
          <Box
            key={service.code_internal}
            display="flex"
            flex="1"
            flexDirection="row"
            style={{
              padding: 20,
              borderBottom: '1px solid ' + appColors.PRIMARY_COLOR,
            }}>
            <Box
              data-cy="details_order_itens_image"
              display="flex"
              flexDirection="row"
              style={{ flexGrow: '4' }}>
              <Img
                alt="imagem"
                src={
                  'https://icones.pro/wp-content/uploads/2022/02/icone-de-service-orange.png'
                }
              />
              <Box
                data-cy="details_order_itens_name"
                style={{ paddingLeft: 10 }}>
                <Typography style={{ fontWeight: 'bold' }}>
                  {service.title}
                </Typography>

                <Typography
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: '10rem',
                  }}>
                  {' '}
                  <p>
                    Descrição:
                    <ReactMarkdown
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '10rem',
                      }}
                      source={service.description}
                    />
                  </p>
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              style={{ flexGrow: '2' }}>
              <Typography>1x </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              style={{ flexGrow: '2' }}>
              <Typography>{appUtils.formatPrice(service.price)}</Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  </>
);

export default ServicesCard;
