import React from 'react';
import HomeButiri from './components/buriti';
import HomePageSimerPay from './components/oferta';
import appTheme from 'utils/appTheme';

const homes = {
  Buriti: HomeButiri,
  SimerPay: HomePageSimerPay,
};

export default function HomeScreen(props) {
  const Home = homes[appTheme.name] || HomePageSimerPay;
  return <Home {...props} />;
}
