import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 90px 0px;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f3f3f3;
  ${props =>
    props.image &&
    css`
      background-image: url(${props.image});
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    `}
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 50px;
  strong {
    font-size: 18px;
    line-height: 26px;
    font-weight: normal;
    margin-top: 40px;
    width: 192px;
    text-align: center;
  }
  svg {
    font-size: 50px;
  }
  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    strong {
      font-size: 12px;
      line-height: 13px;
      font-weight: normal;
      margin-top: 20px;
      width: 85px;
      text-align: center;
    }
    svg {
      font-size: 19px;
    }
  }
`;

export const Titles = styled.div`
  height: 300px;
  width: 100%;
  flex: 1;
  padding: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 500px) {
    grid-template-columns: 1fr 1fr;
    padding: 0px;
  }
  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    color: #505255;
    max-width: 450px;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    max-width: 450px;
  }
`;
