/* eslint-disable no-undef */
import React, { useState } from 'react';
import {
  Document,
  Page,
  Text,
  View,
  Image,
  Link,
  PDFDownloadLink,
} from '@react-pdf/renderer';
import ActionFab from 'components/Actions/ActionFab';
import useCatalog from 'hooks/useCatalogs';
import { styles } from './styles';
import { useSelector } from 'react-redux';
import appUtils from 'utils/appUtils';

import icon_app from 'assets/shopping-cart.png';
import banner from 'assets/banner.png';
import logo_play from 'assets/logo_play.png';
import notImage from 'images/Products/not_image.jpg';

import moment from 'moment';
import appConfig from 'utils/appConfig';

const getDefaultAPI =
  process.env.NODE_ENV === 'production'
    ? appConfig.SITE_WEB
    : 'https://simerpay.site.sensiconnect.io';

export const MyPDF = ({ catalog, pages, bannners, logo = logo_play }) => {
  if (!catalog || !pages) return <div />;

  return (
    <Document>
      <Page size={[450, 900]} style={styles.page} wrap>
        <View style={styles.header}>
          {catalog.image.medium ? (
            <Image
              src={{
                uri: catalog.image.medium + '?origin=' + window.location.host,
              }}
              style={styles.cover}
            />
          ) : (
            <View style={{ width: 450, height: 900 }} />
          )}
          <View style={styles.catalog_view}>
            <Text style={styles.catalog_title}>CATÁLOGO</Text>
          </View>
          <Link
            src={`${getDefaultAPI}/lojas/${catalog.store.id}`}
            style={styles.store_image}>
            <Image
              source={{
                uri:
                  catalog.store.image.medium +
                  '?origin=' +
                  window.location.host,
              }}
              style={{
                borderRadius: 100,
                objectFit: 'cover',
              }}
            />
          </Link>
        </View>

        {pages.map((page, key) => {
          let route;

          if (bannners[key] !== undefined) {
            const [name, params] = bannners[key].images[0].route.split('@');

            if (name === 'product') {
              route = `${getDefaultAPI}/produto/${params}`;
            }

            if (name === 'segment') {
              route = `${getDefaultAPI}/segment/${params}`;
            }

            if (name === 'store') {
              route = `${getDefaultAPI}/lojas/${params}`;
            }

            if (name === 'business_partner') {
              route = `${getDefaultAPI}/partner/${params}`;
            }

            if (name === 'gallery') {
              route = `${getDefaultAPI}/gallery/${params}`;
            }

            if (name === 'catalog') {
              route = `${getDefaultAPI}/catalog/${params}`;
            }
            if (
              typeof bannners[key].images[0].route.split('@')[1] !== 'string'
            ) {
              route = bannners[key].images[0].route;
            }
          }

          return (
            <View
              key={key}
              style={{
                height: 900,
                justifyContent: 'space-between',
                display: 'flex',
                flexDirection: 'column',
              }}>
              <View>
                {page.products.map((product, i) => {
                  const url = `${getDefaultAPI}/produto/${
                    product.slug
                  }?code_partner=${
                    catalog.user.code_partner || catalog.user.user_id
                  }&catalog_code_internal=${
                    product.catalog_code_internal
                  }&catalog_product_id=${product.catalog_product_id}`;

                  return (
                    <View style={styles.product} key={i}>
                      {product.images.length > 0 ? (
                        <Image
                          src={{
                            uri:
                              product.images[0].sizes.medium +
                              '?origin=' +
                              window.location.host,
                          }}
                          style={styles.product_image}
                        />
                      ) : (
                        <Image src={notImage} style={styles.product_image} />
                      )}
                      <View>
                        <Link
                          style={{
                            color: '#a8969f',
                            width: 200,
                          }}
                          src={url}>
                          {String(product.name).length > 37
                            ? String(product.name).substr(0, 37) + '...'
                            : String(product.name)}
                        </Link>
                        <View style={styles.prices}>
                          {product.catalog_price_discount ? (
                            <View>
                              <View style={styles.price_antigo}>
                                <Text style={styles.text_old_price}>
                                  {appUtils.formatPrice(product.price_whole)}
                                </Text>
                              </View>
                              <View style={styles.new_price}>
                                <Text
                                  style={{
                                    color: 'white',
                                    textAlign: 'center',
                                  }}>
                                  {appUtils.formatPrice(product.price)}
                                </Text>
                              </View>
                            </View>
                          ) : (
                            <View style={styles.new_price}>
                              <Text
                                style={{
                                  color: 'white',
                                  textAlign: 'center',
                                }}>
                                {appUtils.formatPrice(product.price)}
                              </Text>
                            </View>
                          )}
                        </View>
                        <Link
                          onPress={() => {
                            // Redirecionar para o URL ao clicar no link
                            window.open(url, '_blank');
                          }}
                          onClick={() => {
                            // Redirecionar para o URL ao clicar no link
                            window.open(url, '_blank');
                          }}
                          src={url}
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}>
                          <Image
                            source={icon_app}
                            style={{
                              width: 24,
                              height: 24,
                              marginRight: 8,
                              marginTop: 24,
                            }}
                          />
                          <View style={styles.cart_button}>
                            <Text style={styles.text_button}>Comprar</Text>
                          </View>
                        </Link>
                      </View>
                    </View>
                  );
                })}
              </View>
              <View>
                <Link src={route}>
                  <Image
                    style={{ height: 210, marginTop: 10, objectFit: 'cover' }}
                    src={
                      page.banner !== undefined
                        ? page.banner.images[0].sizes.original +
                          '?origin=' +
                          window.location.host
                        : banner
                    }
                  />
                </Link>
              </View>
            </View>
          );
        })}

        <View
          wrap={false}
          style={{
            width: 450,
            height: 900,
            alignItems: 'center',
            paddingVertical: 30,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}>
          <Link src={`${getDefaultAPI}/lojas/${catalog.store.id}`}>
            <Image
              source={{
                uri:
                  catalog.store.image.medium +
                  '?origin=' +
                  window.location.host,
              }}
              style={styles.store_image_footer}
            />
          </Link>
          {catalog.categories.map((item, idx) => (
            <View
              key={String(idx)}
              style={{
                marginTop: 20,
                width: 360,
                alignItems: 'center',
                paddingVertical: 8,
                backgroundColor: '#FA8F47',
                borderRadius: 7,
              }}>
              <Link src={`${getDefaultAPI}/categoria/${item.slug}`}>
                <Text style={{ color: 'white', fontSize: 20 }}>
                  {item.name}
                </Text>
              </Link>
            </View>
          ))}
          <View style={{ alignItems: 'center' }}>
            <Image source={logo.medium} style={styles.logo_play} />
            <Text style={{ fontSize: 14, color: '#555', margin: 12 }}>
              É fácil comprar...
            </Text>

            {moment(catalog.expiration_date, 'YYYY-MM-DD').isValid() && (
              <Text style={{ color: '#777', fontSize: 12, marginTop: 20 }}>
                Catálogo válido até:{' '}
                {moment(catalog.expiration_date, 'YYYY-MM-DD').format(
                  'DD/MM/YYYY'
                )}
              </Text>
            )}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export const DownloadCatalog = ({ id }) => {
  const { getCatalog, catalog, getBanners, bannners } = useCatalog();
  const { image } = useSelector(state => state.config.config.shopping);
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState([]);

  const download = async () => {
    setLoading(true);
    const catalog = await getCatalog(id);
    if (catalog) {
      let p = [];
      const numberOfPages = Math.ceil(catalog.products.length / 3);
      const banners = await getBanners(numberOfPages);
      let key = 0;
      for (let index = 0; index < numberOfPages; index++) {
        const q = banners.length;
        await p.push({
          pageNumber: index + 1,
          products: catalog.products.slice(0, 3),
          banner: banners[key],
        });
        catalog.products = catalog.products.slice(3);
        if (key === q) key = 0;
        else key++;
      }
      await setPages(p);
      setLoading(false);
    }
  };

  const document = (
    <MyPDF catalog={catalog} pages={pages} bannners={bannners} logo={image} />
  );

  return (
    <div>
      {pages.length > 0 && (
        <PDFDownloadLink
          document={document}
          fileName={
            catalog !== null
              ? String(catalog.name).replace(' ', '-').toLowerCase() + '.pdf'
              : 'catalogo.pdf'
          }>
          {() =>
            !loading ? (
              <ActionFab
                data-cy="btn_actions_download_catalog"
                icon="get_app"
                style={{ backgroundColor: '#2ec342' }}
                onClick={download}
                isLoading={loading}
              />
            ) : (
              <ActionFab
                data-cy="btn_actions_download_catalog"
                icon="get_app"
                onClick={download}
                isLoading={true}
              />
            )
          }
        </PDFDownloadLink>
      )}
      {pages.length === 0 && (
        <ActionFab
          data-cy="btn_actions_download_catalog"
          icon="settings"
          onClick={download}
          isLoading={loading}
        />
      )}
    </div>
  );
};
