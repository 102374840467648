export const Types = {
  GET_REQUEST: 'installment/GET_REQUEST',
  GET_SUCCESS: 'installment/GET_SUCCESS',
  GET_FAILURE: 'installment/GET_FAILURE',
};

const initialState = {
  max_installment_number: 1,
  loading: true,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_REQUEST:
      return { ...state, loading: true };

    case Types.GET_SUCCESS:
      return { ...action.payload, loading: false, error: false };

    case Types.GET_FAILURE:
      return { loading: false, error: true };

    default:
      return state;
  }
};

export const Creators = {
  getMaxInstallmentRequest: payload => ({
    type: Types.GET_REQUEST,
    payload,
  }),
  getMaxInstallmentSuccess: payload => ({
    type: Types.GET_SUCCESS,
    payload,
  }),
  getMaxInstallmentFailure: () => ({
    type: Types.GET_FAILURE,
  }),
};
