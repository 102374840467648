/*
 *
 * Category reducer
 *
 */

export const Types = {
  // Obtem catergoria por id
  GET_REQUEST: 'category/GET_REQUEST',
  GET_SUCCESS: 'category/GET_SUCCESS',
  GET_FAILURE: 'category/GET_FAILURE',
};

export const initialState = {
  // Categoria por id
  category: {},
  categoryLoading: false,
  categoryError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Categoria por id
    case Types.GET_REQUEST:
      return {
        ...state,
        category: {},
        categoryError: null,
        categoryLoading: true,
      };
    case Types.GET_SUCCESS:
      return {
        ...state,
        category: action.payload.data,
        categoryLoading: false,
        categoryError: null,
      };
    case Types.GET_FAILURE:
      return {
        ...state,
        categoryLoading: false,
        categoryError: action.payload,
      };
    default:
      return state;
  }
};

export const Creators = {
  // Busca uma categoria
  getCategoryRequest: (id) => ({
    type: Types.GET_REQUEST,
    payload: { id },
  }),
  getCategorySuccess: ({ data }) => ({
    type: Types.GET_SUCCESS,
    payload: { data },
  }),
  getCategoryFailure: error => ({
    type: Types.GET_FAILURE,
    payload: error,
  }),
};
