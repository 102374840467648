import React from 'react';
import { TrendsContainer } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as TrendListCreators } from 'store/ducks/trend/list';
import HeaderTrends from 'components/Pages/Trends/HeaderTrends';
import TrendsList from 'components/Pages/Trends/TrendsList';
import { Box, TablePagination, TableCell } from '@material-ui/core';
import appColors from 'utils/appColors';

const TrendsPage = () => {
  const dispatch = useDispatch();
  const { TrendList, TrendListTotal } = useSelector(state => state.trend.list);

  const [filters, setFilters] = React.useState({
    page: 1,
    perPage: 12,
    search: '',
    orderByColumn: '',
    orderByDirection: '',
  });

  const { page, perPage } = filters;

  const onChangePage = (event, requestPage) => {
    window.scrollTo(0, 0);
    setFilters({ ...filters, page: requestPage + 1 });
  };

  const onChangeRowsPerPage = event => {
    window.scrollTo(0, 0);
    const { value } = event.target;
    setFilters({ ...filters, page: 1, per_page: value });
  };

  React.useEffect(() => {
    dispatch(TrendListCreators.getTrendListRequest(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <TrendsContainer flex="1" flexDirection="column" display="flex">
      <HeaderTrends />
      <TrendsList data={TrendList} />
      <Box display="flex" justifyContent="flex-end" flex={1}>
        <TablePagination
          labelRowsPerPage={'Por Página:'}
          rowsPerPageOptions={[12, 24, 36, 48]}
          page={page - 1}
          style={{ color: appColors.PRIMARY_COLOR, flexDirection: 'row' }}
          rowsPerPage={perPage}
          count={TrendListTotal}
          onChangePage={onChangePage}
          component={TableCell}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </Box>
    </TrendsContainer>
  );
};

export default TrendsPage;
