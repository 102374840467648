import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';

import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Grid,
} from '@material-ui/core';
import CustomTextField from 'components/form/CustomTextField';
import { Formik, Field, Form } from 'formik';
import { InputContainer, InputItem } from 'components/form/StyledComponents';
import styles from './styles';

import CustomSelect from 'components/form/CustomSelect';
import validators from 'utils/validators';
import CustomMaskField from 'components/form/CustomMaskField';
import appColors from 'utils/appColors';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as BankCreators } from 'store/ducks/bank/list';

const ModalContainer = styled(Paper)`
  && {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    @media (max-width: 968px) {
      width: 70%;
    }
    @media (max-width: 768px) {
      width: 90%;
    }
    padding: 30px;
  }
`;

const WithDrawInfoItemLabel = styled('div')`
  && {
    color: ${appColors.PRIMARY_COLOR};
  }
`;

const schema = Yup.object().shape({
  bank: Yup.object().shape({
    id: Yup.string()
      .test(...validators.numberNotRequired())
      .required('Campo Obrigatório'),
  }),
  agency: Yup.string()
    .test(...validators.numberNotRequired())
    .required('Campo Obrigatório'),
  agency_check: Yup.string()
    .test(...validators.numberNotRequired('Apenas numeros'))
    .required('Campo Obrigatório'),
  account: Yup.string()
    .test(...validators.numberNotRequired())
    .required('Campo Obrigatório'),
  account_check: Yup.string().test(...validators.numberNotRequired()),
  type: Yup.string().required('Campo Obrigatório'),
  doc_type: Yup.string().required('Campo Obrigatório'),
  doc_number: Yup.string().when('doc_type', {
    is: 'CNPJ',
    then: Yup.string()
      .test(...validators.cnpjInvalid('CNPJ inválido'))
      .required('Campo Obrigatório'),
    otherwise: Yup.string()
      .test(...validators.cpfInvalid('CPF inválido'))
      .required('Campo Obrigatório'),
  }),
  account_holder: Yup.string().required('Campo Obrigatório'),
});

const WithDrawInfoItem = ({ label, children }) => (
  <Grid item lg={4} sm={6} xs={12}>
    <WithDrawInfoItemLabel>{label}</WithDrawInfoItemLabel>
    {children}
  </Grid>
);

WithDrawInfoItem.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const BankUpdatedForm = ({ isOpen, handleClose, initialValues, onSubmit }) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  console.log(modalOpen);
  const dispatch = useDispatch();

  const [filters] = React.useState({
    page: 1,
    perPage: 999,
    search: '',
    orderByColumn: '',
    orderByDirection: '',
    order: '',
  });
  const {
    bank: { bankList, bankListLoading },
  } = useSelector(state => state);
  React.useEffect(() => {
    dispatch(BankCreators.getBankListRequest(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const onAllClose = () => {
    handleClose();
    handleModalClose();
  };

  return (
    <>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalContainer>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            validateOnBlur
            onSubmit={onSubmit}
            render={({ handleSubmit, isSubmitting }) => (
              <Box flex="1" display="flex" flexDirection="column">
                <Form onSubmit={handleSubmit}>
                  <InputContainer style={{ marginBottom: 35 }}>
                    <InputItem>
                      <Typography style={styles.titleInput}>
                        Banco <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Field
                        name="bank.id"
                        options={bankList}
                        component={CustomSelect}
                        placeholder="Banco"
                        isLoading={bankListLoading}
                      />
                    </InputItem>
                    <InputItem>
                      <Typography style={styles.titleInput}>
                        Número da Agência{' '}
                        <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Field
                        name="agency"
                        placeholder="Informe o número da agência"
                        component={CustomTextField}
                      />
                    </InputItem>
                    <InputItem>
                      <Typography style={styles.titleInput}>
                        Dígito da Agência{' '}
                        <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Field
                        name="agency_check"
                        placeholder="Informe o dígito da agência"
                        component={CustomTextField}
                      />
                    </InputItem>
                  </InputContainer>
                  <InputContainer>
                    <InputItem>
                      <Typography style={styles.titleInput}>
                        Número da conta <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Field
                        name="account"
                        placeholder="Informe o número da conta"
                        component={CustomTextField}
                      />
                    </InputItem>
                    <InputItem>
                      <Typography style={styles.titleInput}>
                        Dígito da conta
                      </Typography>
                      <Field
                        name="account_check"
                        placeholder="Informe o dígito da conta"
                        component={CustomTextField}
                      />
                    </InputItem>
                    <InputItem>
                      <Typography style={styles.titleInput}>
                        Tipo de conta <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Field
                        name="type"
                        label="Tipo de Conta"
                        options={[
                          { id: 'Corrente', name: 'Corrente' },
                          { id: 'Poupança', name: 'Poupança' },
                        ]}
                        component={CustomSelect}
                        placeholder="Informe tipo de conta"
                        isLoading={false}
                      />
                    </InputItem>
                  </InputContainer>
                  <InputContainer>
                    <InputItem>
                      <Typography style={styles.titleInput}>
                        Títular da conta <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Field
                        name="account_holder"
                        placeholder="Informe o titular da conta"
                        component={CustomTextField}
                      />
                    </InputItem>
                  </InputContainer>

                  <InputContainer>
                    <InputItem>
                      <Typography style={styles.titleInput}>
                        Tipo de Documento{' '}
                        <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Field
                        name="doc_type"
                        label="Tipo de Documento"
                        options={[
                          { id: 'CPF', name: 'CPF' },
                          { id: 'CNPJ', name: 'CNPJ' },
                        ]}
                        isDisabled
                        component={CustomSelect}
                        placeholder="Informe tipo de documento"
                        isLoading={false}
                      />
                    </InputItem>
                    <InputItem>
                      <Typography style={styles.titleInput}>
                        Número do Documento{' '}
                        <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Field
                        name="doc_number"
                        placeholder="Número do documento"
                        disabled
                        component={CustomMaskField}
                        mask={
                          '99.999.999/9999-99'
                          // values.doc_type === 'CPF'
                          //   ? '999.999.999-99'
                          //   : '99.999.999/9999-99'
                        }
                      />
                    </InputItem>
                  </InputContainer>

                  <Box style={{ padding: 10, marginTop: 40 }}>
                    <InputItem
                      style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        onClick={() => {
                          onAllClose();
                        }}
                        style={styles.purpleButton}>
                        Voltar
                      </Button>
                      <Button
                        type="submit"
                        style={styles.purpleButton}
                        disabled={isSubmitting}>
                        {isSubmitting && (
                          <CircularProgress
                            size={15}
                            style={{ color: 'white', marginRight: 10 }}
                          />
                        )}
                        Finalizar
                      </Button>
                    </InputItem>
                  </Box>
                </Form>
              </Box>
            )}
          />
        </ModalContainer>
      </Modal>
    </>
  );
};

BankUpdatedForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  store: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default BankUpdatedForm;
