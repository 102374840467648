/* eslint-disable react/display-name */
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomTextField from 'components/form/components/CustomTextField';
import { Typography, Paper } from '@material-ui/core';
import DefaultTable from 'components/Tables/DefaultTable';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { Creators } from 'store/ducks/admin/closed';
import { InputContainer, InputItem } from 'components/form/StyledComponents';
import moment from 'moment';
import ActionsList from './ActionsList';
import appColors from 'utils/appColors';
import { Popover, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

const schema = Yup.object().shape({
  date: Yup.string().required('Campo obrigatório'),
  description: Yup.string().required('Campo obrigatório'),
});

const StoreClosed = () => {
  const [open, setOpen] = React.useState(false);
  const openInfo = true;

  const dispatch = useDispatch();
  const { days } = useSelector(({ admin }) => admin.closed);
  const { store } = useSelector(({ client }) => client.client);
  const [initialValues, setInitialValues] = React.useState({ description: '' });

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(false);
  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(false);
  };

  const onDeleteRequest = data => {
    dispatch(Creators.getStoreClosedDeleteRequest(data));
  };

  const handleEditClosedDay = data => {
    setInitialValues(data);
    setOpen(true);
  };

  const handleCancel = () => {
    setInitialValues({});
    setOpen(false);
  };

  const columns = () => [
    {
      title: 'Data',
      field: 'date',
      render: rowData => (
        <span>{moment(rowData.date).format('DD/MM/YYYY')}</span>
      ),
    },
    {
      title: 'Motivo',
      field: 'description',
      render: rowData => <span>{rowData.description}</span>,
    },
    {
      field: 'actions',
      render: rowData => (
        <ActionsList
          rowData={rowData}
          onDeleteRequest={onDeleteRequest}
          edit={handleEditClosedDay}
        />
      ),
    },
  ];

  React.useEffect(() => {
    dispatch(Creators.getStoreClosedListRequest(store));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = formData => {
    if (formData.id)
      dispatch(Creators.getStoreClosedUpdateRequest({ ...formData, store }));
    else dispatch(Creators.getStoreClosedInsertRequest({ ...formData, store }));
  };
  return (
    <div
      style={{
        transition: 'height 1s',
        height: openInfo ? 'auto' : '0px',
        overflow: 'hidden',
        padding: openInfo ? '20px 25px' : '0px',
        width: '100%',
      }}>
      <Typography variant="body2">
        informe os dias que a loja não terá funcionamento, a plataforma
        informará que a loja estará fechada neste dias.
      </Typography>

      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus>
        <Typography>
          Mensagem exibida para o cliente no dia que sua loja não estiver
          funcionando, <br />
          deixe em branco para não exibir nenhuma mensagem.
        </Typography>
      </Popover>

      <Button
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        style={{
          backgroundColor: appColors.PRIMARY_COLOR,
          color: '#fff',
          maxWidth: '200px',
          margin: '16px 0px',
        }}
        onClick={() => setOpen(true)}>
        Adicionar
      </Button>

      <Paper style={{ marginTop: '16px' }}>
        <DefaultTable
          getFunction={() => alert('functrion')}
          columns={columns()}
          data={days}
          total={1}
          isLoading={false}
          page={1}
          perPage={10}
        />
      </Paper>

      <Dialog
        open={open}
        onClose={() => handleCancel()}
        aria-labelledby="form-dialog-title">
        <DialogTitle>
          <span style={{ fontSize: '20px' }}>Data sem funcionamento</span>
        </DialogTitle>
        <Formik
          initialValues={initialValues}
          onSubmit={values => {
            submit(values);
            handleCancel();
          }}
          validationSchema={schema}>
          {() => (
            <Form>
              <DialogContent style={{ paddingTop: 0 }}>
                <DialogContentText>
                  Informe a data em que a loja estará fechada
                </DialogContentText>
                <InputContainer>
                  <InputItem>
                    <Field
                      name="description"
                      autoFocus
                      placeholder="Descrição"
                      label="Descrição"
                      component={CustomTextField}
                      style={{ width: 400 }}
                    />
                  </InputItem>
                </InputContainer>
                <InputContainer>
                  <InputItem>
                    <Field
                      name="date"
                      placeholder="Data sem funcionamento"
                      type="date"
                      component={CustomTextField}
                      style={{ width: 400 }}
                    />
                  </InputItem>
                </InputContainer>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleCancel()} color="primary">
                  Cancelar
                </Button>
                <Button type="submit" color="primary">
                  Salvar
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default StoreClosed;
