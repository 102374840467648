import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components';
import { Button } from '@chakra-ui/react';
import Paper from '@material-ui/core/Paper';
import { Grid } from '@material-ui/core';
import appColors from 'utils/appColors';
import { formatDate, toPrice } from 'utils/converters';
import Icon from '@material-ui/core/Icon';
import moment from 'moment';
import 'moment/locale/pt-br';

const ModalContainer = styled(Paper)`
  && {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    @media (max-width: 968px) {
      width: 70%;
    }
    @media (max-width: 768px) {
      width: 90%;
    }
    padding: 30px;
  }
`;
export const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const WithDrawInfoItemLabel = styled('div')`
  && {
    color: ${appColors.PRIMARY_COLOR};
  }
`;

const WithDrawInfoItem = ({ label, children }) => (
  <Grid item lg={4} sm={6} xs={12}>
    <WithDrawInfoItemLabel>{label}</WithDrawInfoItemLabel>
    {children}
  </Grid>
);

WithDrawInfoItem.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const ReportDetailsModal = ({ isOpen, handleClose, data }) => {
  console.log(data);
  const formatEfective = date => {
    moment.locale('pt-br');
    return moment(date).format('DD/MM/YYYY');
  };
  function renderSwitch(param) {
    switch (param) {
      case 'PENDING':
        return 'Pendente';
      case 'BANK_PROCESSING':
        return 'Em processamento bancário';
      case 'DONE':
        return 'Efetivada';
      case 'CANCELLED':
        return 'Cancelada';
      case 'FAILED':
        return 'Falha na transferência. Normalmente ocorre em caso de dados bancários inválidos';
      default:
        return 'foo';
    }
  }

  return (
    <>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalContainer>
          <ModalHeader>
            <Button onClick={handleClose} variant="ghost">
              <Icon style={{ marginRight: 5 }} fontSize="medium">
                close
              </Icon>
            </Button>
          </ModalHeader>
          <Grid container spacing={2}>
            <WithDrawInfoItem label="Nome do banco">
              {data.account_bank_name}
            </WithDrawInfoItem>
            <WithDrawInfoItem label="Número da agência">
              {data.account_agency}
            </WithDrawInfoItem>
            <WithDrawInfoItem label="Número da conta">
              {data.account_number}
            </WithDrawInfoItem>
            <WithDrawInfoItem label="Titular da conta">
              {data.account_owner_name}
            </WithDrawInfoItem>
            <WithDrawInfoItem label="CPF/CNPJ do titular">
              {data.account_owner_taxpayer}
            </WithDrawInfoItem>

            <WithDrawInfoItem label="ID da Transferência">
              {data.asaas_transfer_id}
            </WithDrawInfoItem>
            <WithDrawInfoItem label="Nome solicitante do saque">
              {data.user.name}
            </WithDrawInfoItem>
            <WithDrawInfoItem label="CPF/CNPJ do solicitante">
              {data.user.cpf}
            </WithDrawInfoItem>
            <WithDrawInfoItem label="Data do pedido">
              {formatDate(data.created_at)}
            </WithDrawInfoItem>
            <WithDrawInfoItem label="Data de efetivação">
              {data.date_effective
                ? formatEfective(data.date_effective)
                : 'Aguardando efetivação'}
            </WithDrawInfoItem>
            <WithDrawInfoItem label="Tipo de operação">
              {data.operation_type}
            </WithDrawInfoItem>
            <WithDrawInfoItem label="Status">
              {renderSwitch(data.status)}
            </WithDrawInfoItem>
            <WithDrawInfoItem label="Valor bruto">
              {`R$ ${toPrice(data.value)}`}
            </WithDrawInfoItem>
            <WithDrawInfoItem label="Valor líquido">
              {`R$ ${toPrice(data.net_value)}`}
            </WithDrawInfoItem>
          </Grid>
        </ModalContainer>
      </Modal>
    </>
  );
};

ReportDetailsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  store: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default ReportDetailsModal;
