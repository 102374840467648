import styled from 'styled-components';
import { Box } from '@material-ui/core';
import appColors from 'utils/appColors';

export const BoxColors = styled(Box)`
  width: 20px;
  margin-right: 5px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #dedede;
  background-color: ${props => props.hexa};
`;

const styles = {
  purpleButton: {
    backgroundColor: appColors.PRIMARY_COLOR,
    color: 'white',
    paddingLeft: 40,
    paddingRight: 40,
  },
  dangerButton: {
    backgroundColor: '#8B0000',
    color: 'white',
    paddingLeft: 40,
    paddingRight: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  disabledButton: {
    backgroundColor: '#ccc',
    color: '#888',
    paddingLeft: 40,
    paddingRight: 40,
  },
  smallTextPurple: {
    color: '#222',
    fontSize: 14,
    fontWeight: 'bold',
  },
  textMediumPurple: {
    color: appColors.SECUNDARY_COLOR,
    fontSize: 16,
    fontWeight: 'bold',
  },
};

export default styles;
