import appColors from "utils/appColors";
import styled from 'styled-components';
import { Typography } from "@material-ui/core";

export const ItemText = styled(Typography)`
  &&{
    color: #4e3e51;
    cursor: pointer;
    line-height:1.5;
    margin-top:10px;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const styles = {
  containerForm: {
    padding: 20
  },
  rowCategories: {
    padding:25
  },
  titleCategory: {
    marginBottom:20,
    fontWeight: 'bold',
    color: appColors.PRIMARY_COLOR,
    fontSize: 22,
    cursor: 'pointer',
  },
  itemCategory: {

  },
}

export default styles;
