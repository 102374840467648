import styled from 'styled-components';
import { Box, Button } from '@material-ui/core';
import appColors from 'utils/appColors';

export const CategoriesContainer = styled(Box)`
  && {
    padding-left: 7.5%;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
    justify-content: center; /* Centraliza horizontalmente */

    /* Adicione a classe "page-center" aqui */
    &.page-center {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      margin: 0;
    }

    @media all and (max-width: 500px) {
      /* margin-top: 80px; */
      padding: 5px;
    }
  }
`;

export const ColorButton = styled(Button)`
  && {
    display: flex;
    justify-content: space-between;
    flex: 1;
    min-width: 0px;
    border: 1px solid ${appColors.SECUNDARY_COLOR};
    color: ${appColors.SECUNDARY_COLOR};
    background-color: white;
    margin-bottom: 20px;
  }
`;

export const BoxInfo = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center; /* Centraliza horizontalmente */

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const BoxImage = styled.div`
  width: 305px;
  height: 305px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  @media all and (max-width: 500px) {
    margin-top: -45px;
    height: 305px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
`;

const styles = {
  fontFilterButton: {
    fontSize: 12,
  },
  boxEmpty: {
    /*backgroundColor: appColors.LIGHT_COLOR,*/
    borderRadius: 5,
    marginTop: 20,
    marginBottom: 80,
    padding: 50,
  },
  purpleTitle: {
    color: appColors.SECUNDARY_COLOR,
    fontWeight: 'bold',
    fontSize: 28,
  },
};

export default styles;
