import styled from 'styled-components';
import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { GlassMagnifier } from 'react-image-magnifiers';

export const CardCarrossel = styled(Box)`
  && {
    margin-bottom: 20px;
    height: 90%;
    display: block;
    overflow: hidden;
    @media all and (max-width: 500px) {
      max-height: 480px;
    }
  }
`;

export const StyleGlassMagnifier = styled(GlassMagnifier)`
  && {
    & > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    @media all and (max-width: 500px) {
      display: none;
    }
  }
`;

export const ImgMobile = styled.img`
  && {
    display: none;
    width: 100%;
    object-fit: cover;

    @media all and (max-width: 500px) {
      display: flex;
    }
  }
`;

export const CarrosselContainer = styled(Box)`
  && {
    flex: 1;
    position: relative;
    width: 100%;
    margin-top: 0px;
  }
`;

export const SkeletonCarousel = styled(Skeleton)`
  && {
    height: 740px;

    @media all and (max-width: 500px) {
      height: 380px;
      margin-top: 55px;
    }
  }
`;

const styles = {
  fab: {
    backgroundColor: '#F48A2A',
    color: 'white',
  },
};

export default styles;
