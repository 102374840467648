import React from 'react';
import ProductsListCategory from 'components/Pages/Categories/ProductsListCategory';
import { useSelector, useDispatch } from 'react-redux';
import FiltersCategories from 'components/Pages/Categories/FiltersCategories';
import { Creators as ProductCreators } from 'store/ducks/product';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import appColors from 'utils/appColors';
import styles, { CategoriesContainer } from './styles';
import useQuery from 'hooks/useQuery';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import appNaming from 'utils/appNaming';

const SearchProductPage = ({ match }) => {
  const { productList, productListTotal, productListLoading } = useSelector(
    state => state.product.product
  );

  const dispatch = useDispatch();

  const query = useQuery();

  const history = useHistory();

  const [filters, setFilters] = React.useState({
    page: query.get('page') !== null ? Number(query.get('page')) : 1,
    perPage: 12,
    search: '',
    orderByColumn: '',
    orderByDirection: '',
    price_min: 0,
    price_max: 5000,
    sizes: [],
    colors: [],
    order: '1',
  });

  const { page, perPage } = filters;

  const onFormFiltersSubmit = values => {
    setFilters(values);
  };

  const nextPage = () => {
    if (page + 1 <= Math.ceil(productListTotal / perPage)) {
      setFilters({ ...filters, page: page + 1 });
    }
  };

  const previousPage = () => {
    if (page - 1 >= 1) {
      setFilters({ ...filters, page: page - 1 });
    }
  };

  const handlePage = selectPage => {
    setFilters({ ...filters, page: selectPage });
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (page > 1) {
      history.push({
        search: `?page=${page}`,
      });
    } else {
      history.push({
        search: null,
      });
    }
    dispatch(
      ProductCreators.getProductListRequest({
        ...filters,
        search: match.params.search,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  React.useEffect(() => {
    if (!query.get('page')) {
      setFilters({ ...filters, search: match.params.search, page: 1 });
      dispatch(
        ProductCreators.getProductListRequest({
          ...filters,
          page: 1,
          search: match.params.search,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.search]);

  return (
    <>
      {/* INTEGRAÇÃO KDT = SEARCH */}
      <Helmet>
        <meta name="kdt:page" content="search" charSet="utf-8" />
        <title>
          {appNaming.REGULAR_NAMING}- O Shopping na palma da sua mão!
        </title>
      </Helmet>
      <CategoriesContainer flex="1">
        <Box
          display="flex"
          flex="1"
          flexDirection="row"
          flexWrap="wrap"
          style={{ marginTop: 40 }}>
          <FiltersCategories
            data={{ colors: [], sizes: [] }}
            father={/*category.category_child.length > 0 ? true : */ false}
            onSubmit={onFormFiltersSubmit}
            section={null}
            initialValues={filters}
          />
          <Box flex="1" flexDirection="column">
            {productListLoading && (
              <Box
                flex="1"
                display="flex"
                justifyContent="center"
                alignItems="flex-start"
                style={{ minWidth: 200 }}>
                <CircularProgress style={{ color: appColors.PRIMARY_COLOR }} />
              </Box>
            )}
            {productList.length > 0 && (
              <ProductsListCategory
                data={productList}
                total={productListTotal}
                nextPage={nextPage}
                previousPage={previousPage}
                handlePage={handlePage}
                loading={productListLoading}
                page={page}
                perPage={perPage}
              />
            )}
            {!productListLoading && productList.length === 0 && (
              <Box
                flex="1"
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={styles.boxEmpty}>
                <Typography style={styles.purpleTitle}>
                  Nenhum resultado encontrado
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </CategoriesContainer>
    </>
  );
};

export default SearchProductPage;
