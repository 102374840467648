import styled from 'styled-components';
import { Box, Button } from '@material-ui/core';
import appColors from 'utils/appColors';

export const SectionContainer = styled(Box)`
&&{
  width:1266px;
  margin-top:10px;

  @media all and (max-width: 500px) {
    margin-top:80px;
    width:100%;
    padding:5px;
  }
  @media all and (min-width:501px) and (max-width: 920px) {
    width:96%;
  }
  @media all and (min-width:921px) and (max-width:1740px) {
    width:90%;
  }

}
`;

export const ColorButton = styled(Button)`
&& {
    display:flex;
    justify-content:space-between;
    flex:1;
    min-width:0px;
    border: 1px solid ${appColors.SECUNDARY_COLOR};
    color: ${appColors.SECUNDARY_COLOR};
    background-color: white;
    margin-bottom:20px;
}

`;


const styles = {
  fontFilterButton: {
      fontSize:12,
  }
}

export default styles;