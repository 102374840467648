import React from 'react';
import PropTypes from 'prop-types';
import ActionFab from 'components/Actions/ActionFab';
import ActionMenuItem from 'components/Actions/ActionMenuItem';
import ActionMenu from 'components/Actions/ActionMenu';

const CollaboratorActions = ({ rowData, onDeleteRequest }) => {
  const [localState, setLocalState] = React.useState({
    anchorEl: null,
  });

  const handleClick = event => {
    setLocalState({ anchorEl: event.currentTarget });
  };

  const handleClose = () => {
    setLocalState({ anchorEl: null });
  };

  const onDelete = () => {
    onDeleteRequest(rowData);
  };

  return (
    <>
      <ActionFab icon="more_vert" onClick={handleClick} />
      <ActionMenu
        anchorEl={localState.anchorEl}
        onClose={handleClose}
        open={Boolean(localState.anchorEl)}>
        {/* <ActionMenuItem
          data-cy="btn_collaborator_edit"
          onClick={() => {
            handleTab(42);
            history.push({
              search: `?id_collaborator=` + rowData.id,
            });
          }}>
          <a>Editar</a>
        </ActionMenuItem> */}
        <ActionMenuItem onClick={onDelete}>Remover</ActionMenuItem>
        {/* {email_verified === '0' ? (
          <ActionMenuItem
            onClick={() => validateEmail({ id, email_verified: '1' })}
          >
            Validar email
          </ActionMenuItem>
        ) : (
          <ActionMenuItem
            onClick={() => validateEmail({ id, email_verified: '0' })}
          >
            Invalidar email
          </ActionMenuItem>
        )} */}
      </ActionMenu>
    </>
  );
};

CollaboratorActions.propTypes = {
  onDeleteRequest: PropTypes.func,
  rowData: PropTypes.oneOfType([PropTypes.object]).isRequired,
  validateEmail: PropTypes.func,
};

CollaboratorActions.defaultProps = {
  onDeleteRequest: () => {},
  validateEmail: () => {},
};

export default CollaboratorActions;
