import { call, takeLatest, all, put } from 'redux-saga/effects';
import api from 'services/api';
import { Types, Creators } from 'store/ducks/product';
import { callApi } from 'store/sagas/auth';
import { Creators as NotificationCreators } from 'store/ducks/app';

function* getProduct({ payload }) {
  try {
    const { id, catalog_code_internal, catalog_product_id } = payload;
    const response = yield call(api.get, `/v1/site/products/${id}`, {
      catalog_code_internal,
      catalog_product_id,
    });
    if (response.status !== 200) throw response;
    yield put(Creators.getProductSuccess(response.data));
  } catch ({ data: { data } }) {
    yield put(Creators.getProductFailure('Erro ao buscar na API'));
    yield put(
      NotificationCreators.openNotification({
        message: data.Error,
        type: 'error',
      })
    );
  }
}

function* getProductList({ payload }) {
  try {
    const request = call(api.get, '/v1/site/products', payload);
    const response = yield call(callApi, request);
    if (response.status !== 200) throw response;
    yield put(Creators.getProductListSuccess(response.data));
  } catch (err) {
    yield put(Creators.getProductListFailure('Erro ao buscar na API'));
  }
}

function* getProductByCategory({ payload }) {
  try {
    const { slug, filters } = payload;
    const response = yield call(
      api.get,
      `/v1/site/categories/${slug}/products`,
      filters
    );
    if (response.status !== 200) throw response;
    yield put(Creators.getProductsByCategorySuccess(response.data));
  } catch (err) {
    yield put(Creators.getProductsByCategoryFailure('Erro ao buscar na API'));
  }
}
// LISTAGEM POR CATEGORIA HOMEPAGE
// LISTAGEM DE CATEGORIA SELECTED
function* getCategoryListSelected({ payload }) {
  try {
    const { page, perPage, search, orderByColumn, orderByDirection } = payload;
    const response = yield call(api.get, `/v1/site/categories/${5}/products`, {
      page,
      search,
      per_page: perPage,
      order: orderByColumn,
      order_by: orderByDirection,
    });
    if (response.status !== 200) throw response;
    yield put(Creators.getCategoryListSelectedSuccess(response.data));
  } catch (err) {
    yield put(Creators.getCategoryListSelectedFailure('Erro ao buscar na API'));
  }
}
// LISTAGEM DE CATEGORIA NEWS
function* getCategoryListNews({ payload }) {
  try {
    const { page, perPage, search, orderByColumn, orderByDirection } = payload;
    const response = yield call(api.get, `/v1/site/categories/${6}/products`, {
      page,
      search,
      per_page: perPage,
      order: orderByColumn,
      order_by: orderByDirection,
    });
    if (response.status !== 200) throw response;
    yield put(Creators.getCategoryListNewsSuccess(response.data));
  } catch (err) {
    yield put(Creators.getCategoryListNewsFailure('Erro ao buscar na API'));
  }
}
// LISTAGEM DE CATEGORIA SALES
function* getCategoryListSales({ payload }) {
  try {
    const { page, perPage, search, orderByColumn, orderByDirection } = payload;
    const response = yield call(api.get, `/v1/site/categories/${7}/products`, {
      page,
      search,
      per_page: perPage,
      order: orderByColumn,
      order_by: orderByDirection,
    });
    if (response.status !== 200) throw response;
    yield put(Creators.getCategoryListSalesSuccess(response.data));
  } catch (err) {
    yield put(Creators.getCategoryListSalesFailure('Erro ao buscar na API'));
  }
}

// Individual exports for testing
export default function* productSaga() {
  yield all([
    takeLatest(Types.GET_REQUEST, getProduct),
    takeLatest(Types.GET_LIST_REQUEST, getProductList),
    takeLatest(Types.GET_BY_CATEGORY_REQUEST, getProductByCategory),
    takeLatest(Types.GET_SELECTED_REQUEST, getCategoryListSelected),
    takeLatest(Types.GET_NEWS_REQUEST, getCategoryListNews),
    takeLatest(Types.GET_SALES_REQUEST, getCategoryListSales),
  ]);
}
