import React from 'react';
import PropTypes from 'prop-types';
import ActionFab from 'components/Actions/ActionFab';
import ActionMenu from 'components/Actions/ActionMenu';
import ActionMenuItem from 'components/Actions/ActionMenuItem';
import AlertDialog from 'components/AlertDialog';
import { Creators } from 'store/ducks/admin/closed';
import { useDispatch, useSelector } from 'react-redux';

const ActionsList = ({ rowData, edit }) => {
  const [localState, setLocalState] = React.useState({
    anchorEl: null,
  });

  const {
    admin: {
      order: { orderDeleteLoading },
    },
  } = useSelector(state => state);

  const [isOpenDialog, setIsOpenDialog] = React.useState(false);

  const dispatch = useDispatch();

  const onCloseDialog = () => {
    setIsOpenDialog(false);
  };

  const handleClose = () => {
    setLocalState({ anchorEl: null });
  };

  const onOpenDialog = () => {
    handleClose();
    setIsOpenDialog(true);
  };

  const handleClick = event => {
    setLocalState({ anchorEl: event.currentTarget });
  };

  const onDeleteSubmit = () => {
    dispatch(Creators.getStoreClosedDeleteRequest(rowData));
  };

  React.useEffect(() => {
    if (!orderDeleteLoading) {
      onCloseDialog();
    }
  }, [orderDeleteLoading]);

  return (
    <>
      <ActionFab icon="more_vert" onClick={handleClick} />
      <ActionMenu
        anchorEl={localState.anchorEl}
        onClose={handleClose}
        open={Boolean(localState.anchorEl)}>
        <ActionMenuItem onClick={() => edit(rowData)}>Editar</ActionMenuItem>
        <ActionMenuItem onClick={onOpenDialog}>Remover</ActionMenuItem>
      </ActionMenu>
      <AlertDialog
        isOpen={isOpenDialog}
        handleClose={onCloseDialog}
        onConfirm={onDeleteSubmit}
        isLoading={orderDeleteLoading}
        description="Deseja realmente remover este item?"
      />
    </>
  );
};

ActionsList.propTypes = {
  rowData: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onDeleteRequest: PropTypes.func,
};

ActionsList.defaultProps = {
  onDeleteRequest: () => {},
};

export default ActionsList;
