/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useState } from 'react';
import api from 'services/api';
import { useDispatch } from 'react-redux';
import { Creators as NotificationCreators } from 'store/ducks/app';
import { useHistory } from 'react-router';

const useProduct = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [prototipy, setPrototipy] = useState(null);
  const [prototipyList, setPrototipyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMultiCatalogBanner, setLoadingMultiCatalogBanner] =
    useState(false);
  const [product, setProduct] = useState(null);
  const [multiCatalogBanner, setMultiCatalogBanner] = useState(null);
  const [multiCatalogBannerTotal, setMultiCatalogBannerTotal] = useState();

  const toggleCashback = async payload => {
    const res = await api.get(`/v1/site/products/${payload.id}`);
    await api.put(`/v1/admin/products/${payload.id}`, {
      ...res.data.data,
      ...payload,
    });
  };
  const getproductMultiCatalogBanner = async id => {
    setLoadingMultiCatalogBanner(true);
    try {
      const response = await api.get(`/v1/admin/products/multiCatalog/${id}`);
      if (response.status !== 200) {
        throw response;
      }
      setMultiCatalogBanner(response.data.data);
      console.log(response.data);
      setMultiCatalogBannerTotal(response.data.total);
      setLoadingMultiCatalogBanner(false);
    } catch (err) {
      setLoadingMultiCatalogBanner(false);
    }
  };

  const updateProduct = async payload => {
    try {
      const response = await api.put(`/v1/admin/products/${payload.id}`, {
        ...payload,
        description: payload.description.toString('markdown'),
        description_tec: payload.description_tec.toString('markdown'),
      });

      await getProductImagesUpload({
        id: payload.id,
        images_data: payload.images_data,
        product_prototype_id: payload.product_prototype_id,
      });

      if (response.status !== 200 && response.status !== 201) throw response;
      dispatch(
        NotificationCreators.openNotification({
          message: 'Produto alterado com sucesso',
          type: 'success',
        })
      );
      push(`/minha-conta?aba=produtos`);
    } catch (err) {
      if (err.status === 409) {
        dispatch(
          NotificationCreators.openNotification({
            message: 'Já existe um produto com esse código de barras',
            type: 'error',
          })
        );
      } else
        dispatch(
          NotificationCreators.openNotification({
            message: 'Não foi possivel atualizar esse produto',
            type: 'error',
          })
        );
    }
  };

  const createProduct = async data => {
    try {
      setLoading(true);
      const { description, description_tec } = data;
      const response = await api.post('/v1/admin/products', {
        ...data,
        description: description.toString('markdown'),
        description_tec: description_tec.toString('markdown'),
      });

      if (response.status !== 200 && response.status !== 201) throw response;
      const { id } = response.data.data;

      await getProductImagesUpload({
        ...data,
        id,
      });

      dispatch(
        NotificationCreators.openNotification({
          message: 'Produto cadastrado com sucesso',
          type: 'success',
        })
      );
      push(`/minha-conta?aba=produtos`);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.status === 409)
        dispatch(
          NotificationCreators.openNotification({
            message: 'Já existe um produto com esse código de barras',
            type: 'error',
          })
        );
      else
        dispatch(
          NotificationCreators.openNotification({
            message:
              'Não foi possivel cadastrar esse produto, verifique as informações',
            type: 'error',
          })
        );
    }
  };

  const getProductImagesUpload = async payload => {
    try {
      const {
        id,
        images_data,
        featured,
        featured_index,
        product_prototype_id,
      } = payload;

      await Promise.all(
        images_data.map((image, index) => {
          const isFeatured =
            featured_index === index ||
            featured === image.id ||
            featured_index === image.id;

          if (image.isPrototype) {
            if (product_prototype_id) {
              api.post(`/v1/admin/products/${id}/images`, {
                featured: isFeatured,
                product_prototype_id: product_prototype_id,
              });
            }
          } else {
            let data = new FormData();
            data.append('image', image);
            data.append('featured', isFeatured);
            return api.post(`/v1/admin/products/${id}/images`, data);
          }
        })
      );
    } catch (err) {
      //
    }
  };

  const getAdminProduct = async id => {
    try {
      await setLoading(true);
      const response = await api.get(`/v1/admin/products/${id}`);
      if (response.status !== 200) {
        throw response;
      }
      setProduct(response.data.data);
      setLoading(false);
      return true;
    } catch (err) {
      setLoading(false);
    }
  };

  const getProduct = async id => {
    try {
      await setLoading(true);
      const response = await api.get(`/v1/site/products/${id}`);
      if (response.status !== 200) {
        throw response;
      }
      setProduct(response.data.data);
      setLoading(false);
      return true;
    } catch (err) {
      setLoading(false);
    }
  };

  const getPrototipy = async id => {
    try {
      const response = await api.get(`/v1/admin/products/prototype/${id}`);
      if (response.status !== 200) {
        throw response.data;
      }
      setPrototipy(response.data.data);
    } catch (error) {
      setPrototipy(null);
    }
  };

  const getsearchPrototipy = async params => {
    setLoading(true);
    const response = await api.get('/v1/admin/products/prototype', params);
    if (response.status !== 200) {
      throw response.data;
    }
    setLoading(false);
    setPrototipyList(response.data.data);
  };

  function clearPrototipy() {
    setPrototipy(null);
  }

  return {
    getPrototipy,
    getsearchPrototipy,
    clearPrototipy,
    createProduct,
    updateProduct,
    getProduct,
    getAdminProduct,
    prototipy,
    prototipyList,
    loading,
    product,
    toggleCashback,
    loadingMultiCatalogBanner,
    multiCatalogBanner,
    getproductMultiCatalogBanner,
    multiCatalogBannerTotal,
  };
};

export default useProduct;
