// @flow
import React from 'react';
import { useHistory } from 'react-router-dom';

const RecommendationPage = ({ match }) => {
  React.useEffect(
    () => () => {
      window.scrollTo(0, 0);
    },
    []
  );

  const { code = null } = match.params;

  const history = useHistory();
  // DIALOGS

  React.useEffect(() => {
    if (code) {
      localStorage.setItem('@sensi-marketplace#reseller', code);
      history.push({ pathname: '/revendedor/cadastrar' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return (
    <div
      style={{
        height: '50vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}>
      Carregando cadastro... {code}
    </div>
  );
};

export default RecommendationPage;
