import * as Yup from 'yup';

export const getErrorStep = (errors, index, steps) => {
  const listErrors = {
    name: 0,
    description: 4,
    description_tec: 4,
    price: -1,
    price_whole: 0,
    quantity_min_whole: 0,
    height: 0,
    width: 0,
    length: 0,
    weight: 0,
    status: 0,
    categories: 3,
    images_data: 2,
    variations: 1,
  };
  const errorStepTemp = [...Object.values(steps).map(() => false)];
  Object.keys(errors).forEach(key => {
    const i = listErrors[key];
    errorStepTemp[i] = true;
  });
  if (errorStepTemp[index]) return true;
  return false;
};

export const initialValues = {
  id: '',
  link: '',
  featured: null,
  code_integration: '',
  genres: [],
  code_ncm: '',
  code_ean: '',
  name: '',
  description: '',
  description_tec: '',
  stock_control: 1,
  stock: '',
  price: '',
  price_discount: '',
  quantity_max: '',
  price_whole: '',
  quantity_min_whole: '',
  quantity_max_whole: '',
  height: '',
  width: '',
  length: '',
  weight: '',
  status: 1,
  store: { id: '' },
  categories: [],
  images: '',
  images_data: [],
  images_info: [],
  variations: [],
  related: [],
  featured_index: null,
  product_prototype_id: '',
  enable_cashback: true,
};

export const schema = Yup.object().shape({
  featured: Yup.string(),
  link: Yup.string(),
  id: Yup.number(),
  code_integration: Yup.string(),
  code_ncm: Yup.string(),
  price_whole: Yup.string().required('Campo obrigatório'),
  code_ean: Yup.string(),
  name: Yup.string().required('Campo obrigatório'),
  description: Yup.string(),
  description_tec: Yup.string(),
  stock_control: Yup.string().required('Campo obrigatório'),
  stock: Yup.number(),
  price: Yup.string().required(),
  price_discount: Yup.string(),
  quantity_min_whole: Yup.number(),
  height: Yup.string(),
  width: Yup.string(),
  length: Yup.string(),
  weight: Yup.string(),
  variations: Yup.array().of(
    Yup.object().shape({
      color: Yup.object().shape({
        id: Yup.mixed().required(),
        name: Yup.string().required(),
      }),
      size: Yup.object().shape({
        id: Yup.mixed().required(),
        name: Yup.string().required(),
      }),
      price_whole: Yup.string().required(),
    })
  ),
  status: Yup.number().required('Campo obrigatório'),
  images_data: Yup.array().required('Campo obrigatório'),
  store: Yup.object().shape({
    id: Yup.number(),
  }),
  categories: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number(),
      })
    )
    .required('Selecione uma categoria'),
});
