import appColors from 'utils/appColors';
import styled from 'styled-components';

export const ContainerHeader = styled.div`
  a {
    text-decoration: none;
  }
`;

const styles = {

  purpleTitle: {
    color: appColors.SECUNDARY_COLOR,
    fontWeight: 'bold',
    fontSize: 32,
    marginTop: 10,
    
  },
  link: {
    textDecoration: 'none'
  }
};

export default styles;
