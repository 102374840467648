import React, { useEffect, useState } from 'react';
import * as S from './styles';
import { Creators as AuthCreators } from 'store/ducks/auth';
import { Creators as PanelTabCreators } from 'store/ducks/panelTabs';
import Icon from '@material-ui/core/Icon';
import { useDispatch, useSelector } from 'react-redux';
import { menuList, tabsName } from './constants';
import { Link } from 'react-router-dom';
import { Stack, Box } from '@chakra-ui/react';
import useQuery from 'hooks/useQuery';

function MenuComponent() {
  const [open, setOpen] = useState();
  const query = useQuery();
  const { tab } = useSelector(state => state.panelTabs);
  const aba_query = query.get('aba');
  const aba = aba_query ?? tab;
  const { client } = useSelector(state => state.client);
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(AuthCreators.getLogoutRequest());
  };

  useEffect(() => {
    if (query.get('id_produto')) {
      handleTab(10);
      return;
    }
    if (query.get('id_collaborator')) {
      handleTab(42);
      return;
    }
    if (query.get('id_pedido')) {
      handleTab(6);
      return;
    }
    if (query.get('id_catalogo')) {
      handleTab(9);
      return;
    }
    if (query.get('Plots_id')) {
      handleTab(47);
      return;
    }

    handleTab(tabsName[aba_query]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aba_query]);

  const handleTab = (value, tabParams = {}) => {
    window.scrollTo(0, 0);
    dispatch(PanelTabCreators.changeTab(value, tabParams));
  };

  if (typeof client.group !== 'object') return <div />;

  const list = Object.keys(menuList)
    .filter(name => {
      const item = menuList[name];
      return item.show.includes(client.group.id);
    })
    .map(name => ({ data: menuList[name], id: tabsName[name], name }));

  const current = list.find(a => aba === a.name || aba === a.id);

  return (
    <Stack>
      <Box display={['none', 'block']} minW="18vw">
        <S.Container>
          {list.map(({ data, id, name }, index) => {
            return [
              data.title && <S.Title>{data.title}</S.Title>,
              <Link
                data-cy={data.data_cy}
                to={`minha-conta?aba=${name}`}
                key={String(index)}>
                <S.Menu
                  onClick={() => handleTab(id)}
                  active={name === aba || id === aba}>
                  <Icon style={{ marginRight: 5 }} fontSize="small">
                    {data.icon}
                  </Icon>
                  <S.Name>{data.name}</S.Name>
                </S.Menu>
              </Link>,
            ];
          })}

          <S.LogoutButton
            id="btn_logout"
            data-cy="btn_logout"
            onClick={logout}
            variant="outlined">
            Sair da minha conta
          </S.LogoutButton>
        </S.Container>
      </Box>

      <Box display={['block', 'none']}>
        <Stack>
          <S.MenuMobile onClick={() => setOpen(m => !m)}>
            {current ? (
              <Stack
                data-cy={current.data.name}
                p="8px"
                w="full"
                direction="row"
                align="center">
                <Icon style={{ marginRight: 5 }} fontSize="small">
                  {current.data.icon}
                </Icon>
                <S.Name>{current.data.name}</S.Name>
              </Stack>
            ) : (
              <Stack p="8px" w="full" direction="row" align="center">
                <Icon style={{ marginRight: 5 }} fontSize="small">
                  {list[0].data.icon}
                </Icon>
                <S.Name>{list[0].data.name}</S.Name>
              </Stack>
            )}
          </S.MenuMobile>

          {open && (
            <Box p={4} bg="#f1f1f1">
              {list.map(({ data, id, name }, index) => {
                return [
                  data.title && <S.Title>{data.title}</S.Title>,
                  <Link to={`minha-conta?aba=${name}`} key={String(index)}>
                    <S.Menu
                      data-cy={data.data_cy_mobile}
                      onClick={() => {
                        setOpen(false);
                        handleTab(id);
                      }}
                      active={name === aba || id === aba}>
                      <Icon style={{ marginRight: 5 }} fontSize="small">
                        {data.icon}
                      </Icon>
                      <S.Name>{data.name}</S.Name>
                    </S.Menu>
                  </Link>,
                ];
              })}
              <S.LogoutButton
                data-cy="btn_logout"
                onClick={logout}
                variant="outlined">
                Sair da minha conta
              </S.LogoutButton>
            </Box>
          )}
        </Stack>
      </Box>
    </Stack>
  );
}

export default MenuComponent;
