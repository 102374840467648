import { Grid, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import notImage from 'images/Products/not_image.jpg';
import { Image, View, GridView, TitlePage, StoreImage, Title } from './styles';
import { useParams } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { getDefaultAPI } from 'services/api';
import useSegments from 'hooks/segments';
import { CoverView } from 'Pages/Catalog/styles';

function SegmentPage() {
  const { id } = useParams();
  const { push } = useHistory();
  const { getStoresBySegment, getAll, segments, stores, loading } =
    useSegments();

  useEffect(() => {
    getStoresBySegment(id);
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading && segments.length === 0) {
    return <div />;
  }

  const segment = segments.find(item => String(item.id) === String(id));

  return (
    <Grid
      container
      alignContent="center"
      justify="center"
      style={{ marginTop: 20 }}>
      {!!segment && (
        <CoverView item>
          <StoreImage src={segment.image.medium || notImage} />
          <Typography
            style={{
              marginTop: 20,
              lineHeight: 0,
              color: '#777',
              fontSize: 12,
              marginBottom: -10,
            }}>
            Segmento
          </Typography>
          <Title>{segment.name}</Title>
        </CoverView>
      )}

      <Grid item xs>
        <TitlePage style={{ fontSize: 36, marginBottom: 14, fontWeight: 300 }}>
          Lojas
        </TitlePage>
        <GridView>
          {stores.map((item, idx) => {
            return (
              <div
                onClick={() => push('/lojas/' + item.id)}
                style={{
                  marginRight: 25,
                  marginBottom: 40,
                  width: 300,
                  cursor: 'pointer',
                }}
                key={idx}>
                <View>
                  <Image
                    style={{ height: 300 }}
                    src={item.image.medium || notImage}
                  />
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignContent: 'center',
                      width: 300,
                    }}>
                    <Link
                      style={{
                        color: '#222',
                        textDecoration: 'none',
                        textAlign: 'left',
                        fontSize: 22,
                        fontWeight: 'bold',
                      }}
                      onClick={() =>
                        window.open(`${getDefaultAPI}/produto/${item.slug}`)
                      }>
                      {item.name.length > 25
                        ? item.name.slice(0, 25) + '...'
                        : item.name}
                    </Link>
                  </View>
                </View>
              </div>
            );
          })}
        </GridView>
      </Grid>
    </Grid>
  );
}

export default SegmentPage;
