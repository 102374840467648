import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PersonIcon from '@material-ui/icons/Person';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import InputIcon from '@material-ui/icons/Input';
import { Typography, Box } from '@material-ui/core';
import { Creators as AuthCreators } from 'store/ducks/auth';
import { Creators as CartCreators } from 'store/ducks/checkout/cart';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import appColors from 'utils/appColors';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: `${appColors.LIGHT_COLOR}`,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function ClientToogleMenu({ anchorEl, handleClose }) {
  const dispatch = useDispatch();

  const { client } = useSelector(state => state.client);

  const logout = () => {
    dispatch(CartCreators.resetCart());
    dispatch(CartCreators.resetBudgetHashCart());
    dispatch(CartCreators.resetManpowerCart());
    dispatch(AuthCreators.getLogoutRequest());
    handleClose();
  };

  return (
    <div>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <Link
          to={
            client.group.id === 6 || client.group.id === 7
              ? '/minha-conta?aba=pedidos-loja'
              : '/minha-conta?aba=dashboard'
          }
          onClick={handleClose}
          style={{ textDecoration: 'none', color: 'inherit' }}>
          <StyledMenuItem>
            <Box color="#666666" display="flex" alignItems="center">
              <DashboardIcon style={{ marginRight: 5 }} fontSize="small" />
              <Typography>Meu Painel</Typography>
            </Box>
          </StyledMenuItem>
        </Link>
        {client.group.id !== 6 && client.group.id !== 7 && (
          <>
            <Link
              to="/minha-conta?aba=pedidos"
              onClick={handleClose}
              style={{ textDecoration: 'none', color: 'inherit' }}>
              <StyledMenuItem>
                <Box color="#666666" display="flex" alignItems="center">
                  <ShoppingBasketIcon
                    style={{ marginRight: 5 }}
                    fontSize="small"
                  />
                  <Typography>Pedidos</Typography>
                </Box>
              </StyledMenuItem>
            </Link>
          </>
        )}

        {client.group.id !== 6 && client.group.id !== 7 && (
          <Link
            to="/minha-conta?aba=revendedor"
            onClick={handleClose}
            style={{ textDecoration: 'none', color: 'inherit' }}>
            <StyledMenuItem>
              <Box color="#666666" display="flex" alignItems="center">
                <AttachMoneyIcon style={{ marginRight: 5 }} fontSize="small" />
                <Typography>Ganhe dinheiro</Typography>
              </Box>
            </StyledMenuItem>
          </Link>
        )}

        <Link
          to="/minha-conta?aba=conta"
          onClick={handleClose}
          style={{ textDecoration: 'none', color: 'inherit' }}>
          <StyledMenuItem>
            <Box color="#666666" display="flex" alignItems="center">
              <PersonIcon style={{ marginRight: 5 }} fontSize="small" />
              <Typography>Meu Perfil</Typography>
            </Box>
          </StyledMenuItem>
        </Link>
        <Box onClick={logout}>
          <StyledMenuItem>
            <Box color="#666666" display="flex" alignItems="center">
              <InputIcon style={{ marginRight: 5 }} fontSize="small" />
              <Typography>Sair</Typography>
            </Box>
          </StyledMenuItem>
        </Box>
      </StyledMenu>
    </div>
  );
}
