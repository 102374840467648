// @flow
import { put } from 'redux-saga/effects';
import { type AxiosResponse } from 'axios';

function* interceptError(creator, response: AxiosResponse): string {
  const defaultErrorMessage = 'Houve um erro no servidor. Tente novamente em instantes';
  if (response && response.data && response.data.msg) {
    yield put(creator(response.data.msg)); // dispatch ação específica de error
    return response.data.msg;
  }

  yield put(creator(defaultErrorMessage));
  return defaultErrorMessage;
}

export default interceptError;
