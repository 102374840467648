/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as ProductCreators } from 'store/ducks/product';
import { Creators as StoresListCreators } from 'store/ducks/stores/list';
import { CircularProgress, Typography, Box } from '@material-ui/core';
import ProductsList from 'components/Pages/Home/ProductsList';
import Carrossel from 'components/Carrossel';
import { Creators as HomeCreators } from 'store/ducks/home';
import CategoriesSlide from 'components/Pages/Home/CategoriesSlide';
import { SkeletonCarousel } from 'components/Carrossel/styles';
import StoresSlide from 'components/Pages/Home/StoresSlide';
import InfoBeneficios from 'components/Pages/Home/InfoBeneficios';
import ConfigMessage from 'components/Pages/Home/ConfigMessage';
import InfoExtra from 'components/Pages/Home/InfoExtra';
import appColors from 'utils/appColors';
import styles, { BoxContainer, AddressBar, Container } from './styles';
import { Helmet } from 'react-helmet';
import appNaming from 'utils/appNaming';
import BannerHome from './components/BannerHome';
import BannerCatalog from './components/BannerCatalog';
import BannerFeatures from './components/BannerFeatures';
import BannerStores from './components/BannerStores';
import BannerDownload from './components/BannerDownload';

const HomePageSimerPay = ({ match }) => {
  // Verifica largura da tela
  const [width, setWidth] = React.useState(window.innerWidth);

  return (
    <>
      <Helmet>
        <meta name="kdt:page" content="home" charSet="utf-8" />
        <meta name="kdt:home" content="home" charSet="utf-8" />
        <title>{appNaming.TITLE}</title>
        <link rel="canonical" href="/" />
      </Helmet>
      <BannerHome />
      <BannerCatalog />
      <BannerFeatures />
      <BannerStores />
      <BannerDownload />
      <Container></Container>
    </>
  );
};

export default HomePageSimerPay;
