import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import * as S from './styles';

const MapModal = ({ open, handleClose, handleConfirm, children }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <S.Container>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle style={{ marginLeft: 10 }} id="responsive-dialog-title">
          Adicionar localização
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary" id="cancelar">
            cancelar
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus id="BtnConfirmar">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </S.Container>
  );
};

export default MapModal;
