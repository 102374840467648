import React from 'react';
import { Box, Button, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { InputContainer, InputItem } from 'components/form/StyledComponents';
import CustomTextField from 'components/form/CustomTextField';
import styles, { CouponContainer, ContainerBottonCoupon } from './styles';

const CouponForm = ({ onSubmit, onSubmitCupom, cupomLoading }) => {
	return (
		<Box flex="1" display="flex">
			<Formik
				initialValues={{ discount_coupon: '' }}
				onSubmit={onSubmit}
				validateOnBlur
				render={({ handleSubmit }) => (
					<form style={{ flex: '1', marginLeft: 15 }} onSubmit={handleSubmit}>
						<CouponContainer flex="1" display="flex">
							<ContainerBottonCoupon flex="1" display="flex" justifyContent="flex-end">
								<InputContainer flex="1" style={{ marginTop: -10 }}>
									<InputItem style={{ margin: 0 }}>
										<Field
											name="discount_coupon"
											label="Insira aqui o cupom de desconto"
											component={CustomTextField}
										/>
									</InputItem>
								</InputContainer>

								<Button size="medium" type="submit" style={styles.purpleButton}>
									Validar
									{cupomLoading && (
										<CircularProgress size={15} style={{ color: 'white', marginLeft: 10 }} />
									)}
								</Button>
							</ContainerBottonCoupon>
						</CouponContainer>
					</form>
				)}
			/>
		</Box>
	);
};

CouponForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	onSubmitCupom: PropTypes.func.isRequired
};

export default CouponForm;
