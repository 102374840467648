import React from 'react';
import { Typography, Box } from '@material-ui/core';
import RegisterResellerForm from 'components/Pages/RegisterReseller/registerResellerForm';
import RegisterResellerEnterprise from 'components/Pages/RegisterReseller/registerResellerEnterprise';
import { useDispatch } from 'react-redux';
import { Creators as AddressCreators } from 'store/ducks/address';
import CustomStepper from 'components/Stepper2';
import styles from './styles';
import RegisterSucess from 'components/Pages/RegisterReseller/RegisterSucess';
import { Creators as ResellerCreators } from 'store/ducks/reseller';
import appColors from 'utils/appColors';

import { Stack } from '@chakra-ui/react';
import HeaderPages from 'components/HeaderPages';

const RegisterResellerPage = props => {
  const dispatch = useDispatch();

  const [localState, setLocalState] = React.useState({
    activeStep: 0,
    enterpriseData: {
      name: '',
      social_name: '',
      cnpj: '',
      cell_phone: '',
      email: '',
      address: {
        code_post: '',
        street: '',
        number: '',
        district: '',
        complement: '',
        city: {
          id: '',
        },
      },
    },
    resellerData: {
      enterprise: {
        name: '',
        social_name: '',
        cnpj: '',
        cell_phone: '',
        email: '',
        address: {
          code_post: '',
          street: '',
          number: '',
          district: '',
          complement: '',
          city: {
            id: '',
          },
        },
      },
      name: '',
      store: {
        id:
          typeof props.location.params !== 'undefined'
            ? props.location.params.data.store.id
            : '1',
      },
      email:
        typeof props.location.params !== 'undefined'
          ? props.location.params.data.email
          : '',
      newsletter:
        typeof props.location.params !== 'undefined'
          ? props.location.params.data.newsletter
          : '',
      cpf: '',
      birth_date: '',
      cell_phone: '',
      genre: '',
      password: '',
      confirmPassword: '',
      terms: null,
      address: {
        code_post: '',
        street: '',
        number: '',
        district: '',
        complement: '',
        city: {
          id: '',
        },
        state: {
          id: '',
        },
      },
    },
  });

  const { activeStep, enterpriseData, resellerData } = localState;

  const getStepContent = [
    'Dados de Revendedor(a)',
    'Dados da empresa de Revendedor(a)',
    'Cadastro Realizado com Sucesso!',
  ];

  const handleBack = (value = 0) => {
    window.scrollTo(0, 0);
    setLocalState({ ...localState, activeStep: value });
  };

  const onSubmitCEP = values => {
    dispatch(AddressCreators.getAddressCEPRequest(values));
  };

  const onSubmitReseller = values => {
    window.scrollTo(0, 0);
    setLocalState({ ...localState, resellerData: values, activeStep: 1 });
  };

  const onSubmitManager = values => {
    window.scrollTo(0, 0);
    setLocalState({
      ...localState,
      enterpriseData: values,
    });
  };

  const onSubmitRegister = () => {
    window.scrollTo(0, 0);
    setLocalState({ ...localState, activeStep: 3 });
  };

  const handleSubmit = enterpriseData => {
    const copyData = {};
    onSubmitManager(enterpriseData);

    copyData.birth_date = resellerData.birth_date.split('');
    copyData.birth_date = copyData.birth_date.join('');
    const dateInverse = copyData.birth_date.split('/');
    copyData.birth_date = `${dateInverse[2]}-${dateInverse[1]}-${dateInverse[0]}`;
    resellerData.birth_date = copyData.birth_date;

    dispatch(
      ResellerCreators.getResellerInsertRequest(
        { ...enterpriseData, ...copyData },
        resellerData,
        onSubmitRegister
      )
    );
  };

  return (
    <Stack alignItems="center" py="100px">
      <HeaderPages
        title="Quero me tornar um Revendedor(a)"
        currentPage="Cadastro de Revendedor"
      />

      <Stack w={['90%', '80%']}>
        <Box
          style={{
            borderBottom: `1px solid ${appColors.PRIMARY_COLOR}`,
            marginBottom: 20,
          }}>
          <CustomStepper steps={getStepContent} activeStep={activeStep} />
        </Box>
        {activeStep === 0 && (
          <>
            <Typography style={styles.purpleTitle}>
              Dados do Responsável
            </Typography>
            <RegisterResellerForm
              onSubmit={onSubmitReseller}
              onSubmitCEP={onSubmitCEP}
              initialValues={resellerData}
            />
          </>
        )}
        {activeStep === 1 && (
          <>
            <Typography style={styles.purpleTitle}>
              Dados da Empresa (MEI)
            </Typography>
            <RegisterResellerEnterprise
              onSubmit={handleSubmit}
              onBack={handleBack}
              onSubmitCEP={onSubmitCEP}
              initialValues={enterpriseData}
            />
          </>
        )}
        {activeStep === 3 && (
          <>
            <Typography style={styles.purpleTitle}>
              Finalização de Cadastro
            </Typography>
            <RegisterSucess onBack={handleBack} onSubmit={onSubmitRegister} />
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default RegisterResellerPage;
