/* eslint-disable react/display-name */
import React from 'react';
import { LoginContainer } from './styles';
import FormLogin from './components/FormLogin';
import PermissionRequest from './components/PermissionRequest';
import Map from './components/Map';
import ForgotModalPage from 'containers/Pages/ForgotModalPage';
import { useAuth } from 'hooks/auth';
import appTheme from 'utils/appTheme';
import { Stack } from '@chakra-ui/react';

const LoginPage = ({ link }) => {
  const { setData, data, setCoords, coords, confirmPosition } = useAuth();

  const [stage, setStage] = React.useState(0);
  const [localState, setLocalState] = React.useState({
    ForgotModalOpen: false,
  });

  const { ForgotModalOpen } = localState;
  const openForgot = () => {
    setLocalState({ ...localState, ForgotModalOpen: true });
  };

  const closeForgot = () => {
    setLocalState({ ...localState, ForgotModalOpen: false });
  };

  const nextStagePermision = (latitude, longitude) => {
    setCoords({ latitude, longitude });
    console.log(latitude, longitude);
    setStage(2);
  };

  const handleConfirmPosition = async () => {
    await confirmPosition();
  };

  React.useEffect(() => {
    if (stage === 0 && data.access_token) {
      handleConfirmPosition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stage, data, appTheme]);

  const ComponentLogin = {
    0: () => (
      <FormLogin setData={setData} link={link} openForgot={openForgot} />
    ),
    1: () => <PermissionRequest nextStagePermision={nextStagePermision} />,
    2: () => (
      <Map coords={coords} handleConfirmPosition={handleConfirmPosition} />
    ),
  }[stage];

  return (
    <Stack alignItems="center">
      <LoginContainer>
        <ComponentLogin />
      </LoginContainer>

      {ForgotModalOpen && (
        <ForgotModalPage isOpen={ForgotModalOpen} closeModal={closeForgot} />
      )}
    </Stack>
  );
};

export default LoginPage;
