import styled from 'styled-components';
import { Box } from '@material-ui/core';
import appColors from 'utils/appColors';

export const ContainerHeader = styled(Box)`
    && {
        @media all and (max-width:500px){
            display:none;
        }
    }
`

const styles = {

    purpleTitle: {
        color: appColors.SECUNDARY_COLOR,
        fontWeight: 'bold',
        fontSize: 32,
    },
}

export default styles;