import React from 'react';
import { emphasize, withStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';
import HomeIcon from '@material-ui/icons/Home';
import CategoryIcon from '@material-ui/icons/Loyalty';

const StyledBreadcrumb = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);

const HeaderCategories = ({ title }) => {
  function handleClick(event) {
    event.preventDefault();
    window.location.href = '/';
  }
  return (
    <Breadcrumbs
      separator="›"
      aria-label="breadcrumb"
      style={{ marginTop: 10 }}>
      <StyledBreadcrumb
        style={{ padding: '5px 10px' }}
        component="a"
        href="#"
        label="Início"
        icon={<HomeIcon fontSize="small" />}
        onClick={handleClick}
      />
      <StyledBreadcrumb
        style={{ padding: '5px 10px' }}
        component="a"
        href="#"
        label="Categorias"
        icon={<CategoryIcon fontSize="small" />}
      />
      <StyledBreadcrumb
        style={{ padding: '5px 10px' }}
        component="a"
        href="#"
        label={title}
      />
    </Breadcrumbs>
  );
};

export default HeaderCategories;
