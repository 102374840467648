import React from 'react';
import { useSelector } from 'react-redux';
import {
  Container,
  Content,
  ImgLogo,
  ImgLogoApp,
  GroupButtons,
  Title,
} from './styles';
import Button from '@material-ui/core/Button';
import { useHistory, useRouteMatch } from 'react-router-dom';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import { Badge } from '@material-ui/core';
import useQuery from 'hooks/useQuery';
import notImage from 'images/Products/not_image.jpg';
import DashboardIcon from '@material-ui/icons/Dashboard';

const HeaderOferta = () => {
  const history = useHistory();
  let match = useRouteMatch('/catalogs/:catalog');
  const { image } = useSelector(state => state.config.config.shopping);
  const { product: product_item, productLoading: p_loading } = useSelector(
    state => state.product.product
  );

  const isCatalog = match;

  const {
    data: { access_token },
  } = useSelector(state => state.auth);

  const handleGoToLogin = () => {
    history.push('/minha-conta');
  };

  const handleGoToHome = () => {
    history.push('/');
  };

  const handleGoToStore = () => {
    history.push(`/lojas/${product_item.store.slug}`);
  };

  const query = useQuery();
  const checkoutCartStore = useSelector(state => state.checkout.cart);
  const totalItems = checkoutCartStore?.list?.reduce(
    (accumulator, item) => accumulator + item.quantity,
    0
  );

  return (
    <Container>
      <Content>
        {query.get('code_partner') && product_item.length > 0 && !p_loading ? (
          <>
            <ImgLogo
              src={product_item.store.image.sizes.small || notImage}
              onClick={handleGoToStore}
            />
            <Title>{product_item.store.name}</Title>
          </>
        ) : (
          <ImgLogoApp
            data-cy="logo-home"
            src={image.small}
            onClick={handleGoToHome}
          />
        )}

        {access_token === '' && (
          <>
            {window.innerWidth > 500 ? (
              <GroupButtons>
                <Button
                  id="cadastrar"
                  variant="outlined"
                  color="primary"
                  onClick={handleGoToLogin}>
                  Cadastrar
                </Button>
                <Button
                  id="fazerLogin"
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: '20px' }}
                  onClick={handleGoToLogin}>
                  Fazer login
                </Button>
                {!!isCatalog && (
                  <Button
                    style={{ marginLeft: '20px', backgroundColor: '#f4f4f4' }}
                    onClick={() => history.push('/carrinho')}
                    variant="contained"
                    color="primary">
                    <Badge badgeContent={totalItems} color="primary">
                      <ShoppingCart style={{ color: '#333' }} />
                    </Badge>
                  </Button>
                )}
              </GroupButtons>
            ) : (
              <GroupButtons>
                <Button
                  id="cadastrar"
                  variant="outlined"
                  color="primary"
                  style={{ fontSize: 8 }}
                  onClick={handleGoToLogin}>
                  Cadastrar
                </Button>
                <Button
                  id="fazerLogin"
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: '7px', fontSize: 8 }}
                  onClick={handleGoToLogin}>
                  Login
                </Button>
                {!!isCatalog && (
                  <Button
                    style={{ marginLeft: '20px', backgroundColor: '#f4f4f4' }}
                    onClick={() => history.push('/carrinho')}
                    variant="contained"
                    color="primary">
                    <Badge badgeContent={totalItems} color="primary">
                      <ShoppingCart style={{ color: '#333' }} />
                    </Badge>
                  </Button>
                )}
              </GroupButtons>
            )}
          </>
        )}

        {access_token !== '' && (
          <GroupButtons>
            {window.innerWidth > 500 ? (
              <Button
                id="minhaConta"
                variant="contained"
                color="primary"
                style={{
                  marginLeft: '30px',
                  height: 45,
                }}
                onClick={handleGoToLogin}>
                Minha Conta
              </Button>
            ) : (
              <Button
                id="minhaConta"
                variant="contained"
                color="primary"
                style={{ marginLeft: '10px', height: 45 }}
                onClick={handleGoToLogin}>
                <DashboardIcon
                  name="Dashboard"
                  style={{ marginRight: 5 }}
                  fontSize="small"
                />
              </Button>
            )}

            <Button
              style={{
                marginLeft: '20px',
                backgroundColor: '#f4f4f4',
                height: 45,
              }}
              data-cy="btn_bdge"
              onClick={() => history.push('/carrinho')}
              variant="contained"
              color="primary">
              <Badge
                data-cy="total_itens_badge"
                badgeContent={totalItems}
                color="primary">
                <ShoppingCart style={{ color: '#333' }} />
              </Badge>
            </Button>
          </GroupButtons>
        )}
      </Content>
    </Container>
  );
};
export default HeaderOferta;
