/*
 *
 * Store reducer
 *
 */

export const Types = {
  // Obtem usuario
  GET_LIST_REQUEST: 'closed/GET_LIST_REQUEST',
  GET_LIST_SUCCESS: 'closed/GET_LIST_SUCCESS',
  GET_LIST_FAILURE: 'closed/GET_LIST_FAILURE',

  // Insere um usuario
  GET_INSERT_REQUEST: 'closed/GET_INSERT_REQUEST',
  GET_INSERT_SUCCESS: 'closed/GET_INSERT_SUCCESS',
  GET_INSERT_FAILURE: 'closed/GET_INSERT_FAILURE',

  // Atualiza um usuario
  GET_UPDATE_REQUEST: 'closed/GET_UPDATE_REQUEST',
  GET_UPDATE_SUCCESS: 'closed/GET_UPDATE_SUCCESS',
  GET_UPDATE_FAILURE: 'closed/GET_UPDATE_FAILURE',

  // Deleta um usuario
  GET_DELETE_REQUEST: 'closed/GET_DELETE_REQUEST',
  GET_DELETE_SUCCESS: 'closed/GET_DELETE_SUCCESS',
  GET_DELETE_FAILURE: 'closed/GET_DELETE_FAILURE',
};

export const initialState = {
  // Insere uma loja
  days: [
    { id: 1, date: '2020-08-02', description: 'Teste 1' },
    { id: 2, date: '2020-08-05', description: 'Teste 1B' },
  ],
  storeClosedLoading: false,
  storeClosedError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // insere store
    case Types.GET_LIST_REQUEST:
      return {
        ...state,
        storeClosedLoading: true,
        storeClosedError: null,
      };
    case Types.GET_LIST_SUCCESS:
      return {
        ...state,
        days: action.payload,
        storeClosedLoading: false,
        storeClosedError: null,
      };
    case Types.GET_LIST_FAILURE:
      return {
        ...state,
        storeClosedLoading: false,
        storeClosedError: action.payload,
      };
    case Types.GET_INSERT_REQUEST:
      return {
        ...state,
        storeClosedLoading: true,
        storeClosedError: null,
      };
    case Types.GET_INSERT_SUCCESS:
      return {
        ...state,
        storeClosedLoading: false,
        storeClosedError: null,
        days: [action.payload, ...state.days],
      };
    case Types.GET_INSERT_FAILURE:
      return {
        ...state,
        storeClosedLoading: false,
        storeClosedError: action.payload,
      };
    case Types.GET_UPDATE_REQUEST:
      return {
        ...state,
        storeClosedLoading: true,
        storeClosedError: null,
      };
    case Types.GET_UPDATE_SUCCESS:
      return {
        ...state,
        storeClosedLoading: false,
        storeClosedError: null,
        days: state.days.map(item =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    case Types.GET_UPDATE_FAILURE:
      return {
        ...state,
        storeClosedLoading: false,
        storeClosedError: action.payload,
      };
    case Types.GET_DELETE_SUCCESS:
      return {
        ...state,
        storeClosedLoading: false,
        storeClosedError: false,
        days: state.days.filter(item => item.id !== action.payload),
      };
    default:
      return state;
  }
};

export const Creators = {
  getStoreClosedListRequest: data => ({
    type: Types.GET_LIST_REQUEST,
    payload: data,
  }),
  getStoreClosedListSuccess: data => ({
    type: Types.GET_LIST_SUCCESS,
    payload: data,
  }),
  getStoreClosedListFailure: error => ({
    type: Types.GET_LIST_FAILURE,
    payload: error,
  }),
  getStoreClosedInsertRequest: data => ({
    type: Types.GET_INSERT_REQUEST,
    payload: data,
  }),
  getStoreClosedInsertSuccess: (data = {}) => ({
    type: Types.GET_INSERT_SUCCESS,
    payload: data,
  }),
  getStoreClosedInsertFailure: error => ({
    type: Types.GET_INSERT_FAILURE,
    payload: error,
  }),
  getStoreClosedUpdateRequest: data => ({
    type: Types.GET_UPDATE_REQUEST,
    payload: data,
  }),
  getStoreClosedUpdateSuccess: (data = {}) => ({
    type: Types.GET_UPDATE_SUCCESS,
    payload: data,
  }),
  getStoreClosedUpdateFailure: error => ({
    type: Types.GET_UPDATE_FAILURE,
    payload: error,
  }),
  getStoreClosedDeleteRequest: data => ({
    type: Types.GET_DELETE_REQUEST,
    payload: data,
  }),
  getStoreClosedDeleteSuccess: id => ({
    type: Types.GET_DELETE_SUCCESS,
    payload: id,
  }),
};
