// @flow
import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import StepConnector from '@material-ui/core/StepConnector';

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: 'white',
    },
  },
  line: {
    borderColor: '#afa6a6',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#afa6a6',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: 'white',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: 'white',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}>
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

const styles = {
  root: {
    display: 'flex',
    flex: 1,
    backgroundColor: 'transparent',
  },
  label: {
    color: 'white!important',
  },
};

function CustomStepper({
  steps,
  activeStep,
  classes,
}: {
  steps: string[],
  activeStep: number,
  classes: object,
}) {
  return (
    <Stepper
      className={classes.root}
      activeStep={activeStep}
      connector={<QontoConnector />}
      style={{ padding: 0, paddingBottom: 30, paddingTop: 30 }}
      alternativeLabel>
      {steps.map((label, index) => (
        <Step key={index}>
          <StepLabel StepIconComponent={QontoStepIcon}>
            <span style={{ color: 'white' }}>{label}</span>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

export default withStyles(styles)(CustomStepper);
