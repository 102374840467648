export const Types = {
  // Obtem categorias
  GET_LIST_REQUEST: 'category/GET_LIST_REQUEST',
  GET_LIST_SUCCESS: 'category/GET_LIST_SUCCESS',
  GET_LIST_FAILURE: 'category/GET_LIST_FAILURE',
};

export const initialState = {
  // Lista de categorias
  categoryList: [],
  categoryListLoading: false,
  categoryListError: null,
  categoryListTotal: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Lista de categorias
    case Types.GET_LIST_REQUEST:
      return { ...state, categoryListLoading: true };
    case Types.GET_LIST_SUCCESS:
      return {
        ...state,
        categoryList: action.payload.data,
        categoryListLoading: false,
        categoryListError: null,
        categoryListTotal: action.payload.total,
      };
    case Types.GET_LIST_FAILURE:
      return {
        ...state,
        categoryListLoading: false,
        categoryListError: action.payload,
      };
    default:
      return state;
  }
};

export const Creators = {
  // Busca lista de categorias
  getCategoryListRequest: payload => ({
    type: Types.GET_LIST_REQUEST,
    payload,
  }),
  getCategoryListSuccess: ({ data, total }) => ({
    type: Types.GET_LIST_SUCCESS,
    payload: { data, total },
  }),
  getCategoryListFailure: error => ({
    type: Types.GET_LIST_FAILURE,
    payload: error,
  }),
};
