import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const styles = {
  button_pay: {
    backgroundColor: '#FA8F47',
    width: '100%',
    color: 'white',
    padding: 10,
  },
};

export const Text = styled(Typography)``;

export const View = styled.div``;

export const TitlePage = styled(Typography)`
  @media (max-width: 650px) {
    margin: 30px;
  }
`;

export const Title = styled.h2`
  font-size: 30px;
  color: #444;
  margin-top: 12px;
  font-weight: 300;
`;

export const Image = styled.img`
  width: 100%;
  height: 500px;
  object-fit: cover;
`;

export const StoreImage = styled.img`
  height: 200px;
  object-fit: cover;
  border-radius: 120px;
  margin-top: -110px;
`;

export const StoreCover = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const GridView = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  @media (max-width: 650px) {
    justify-content: center;
  }
`;

export const CoverView = styled.div`
  width: 300px;
  margin-left: 10%;

  @media (max-width: 650px) {
    justify-content: center;
    margin-left: 20px;
    width: 90%;
  }
`;
