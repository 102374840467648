/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import React from 'react';
import DefaultTable from 'components/Tables/DefaultTable';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as OrderCreators } from 'store/ducks/admin/order';
import HeaderComponent from 'components/HeaderComponent';
import { Paper } from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';
import OrderActions from 'components/OrderPage/OrderActions';
import { formatDate, toPrice } from 'utils/converters';
import OrderPaymentType from 'components/OrderPage/OrderPaymentType';
import { Creators as NotificationCreators } from 'store/ducks/app';
import moment from 'moment';
import StatusColorOrderForm from 'components/OrderPage/OrderView/StatusColorOrderForm';
import io from 'socket.io-client';
import useSound from 'use-sound';
import sound from 'assets/sound.mp3';
import NewOrderStatus from 'components/OrderPage/OrderView/StatusColorOrderForm/statusNewOrder';
import { Creators as DashboardCreators } from 'store/ducks/admin/dashboard';
import { baseSocket } from 'services/api';
import { useEffect } from 'react';
import PrintLoad from '../PrintLoad';
const _ = require('lodash');

const OrderListPageClient = ({ handleTab }) => {
  const [storesState, setStoresState] = React.useState({
    list: [
      { name: 'Aprovado/Pago', id: 0 },
      { name: 'Não autorizado/Não pago', id: 1 },
    ],
    selectedStore: 1,
    isLoading: false,
  });

  const store = useSelector(state => state.client.client.store);
  const dispatch = useDispatch();

  const onChange = newValue => {
    setStoresState(oldState => ({ ...oldState, selectedStore: newValue }));
  };

  const replaceSelect = {
    setFieldValue: (event, newValue) => {
      onChange(newValue);
    },
  };

  const { list, isLoading, selectedStore } = storesState;

  const columns = ({ onDeleteRequest }) => [
    {
      title: 'Pedido',
      field: 'id',
      render: rowData =>
        rowData.view ? (
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}>
            <NewOrderStatus rowData={rowData} />
            <span> {rowData.id}</span>
          </div>
        ) : (
          <span>{rowData.id}</span>
        ),
    },
    { title: 'Cliente', field: 'user.name' },
    {
      title: 'Loja',
      field: 'store.name',
      render: rowData =>
        rowData.stores.map(item => (
          <>
            <p key={item.id}>{item.store.name}</p>
          </>
        )),
    },
    {
      title: 'Status',
      field: 'status',
      render: rowData =>
        rowData.stores.map((item, key) => (
          <StatusColorOrderForm item={item} key={key} />
        )),
    },
    {
      title: 'Pagamento',
      field: 'payment_type',
      render: rowData => <OrderPaymentType rowData={rowData} />,
    },
    {
      title: 'R$',
      field: 'payment_type',
      render: rowData => <span>R$ {toPrice(rowData.price)}</span>,
    },
    {
      title: 'Data',
      field: 'created_at',
      render: rowData => <span>{formatDate(rowData.created_at)}</span>,
    },
    {
      field: 'actions',
      render: rowData => (
        <OrderActions
          handleTab={handleTab}
          rowData={rowData}
          onDeleteRequest={onDeleteRequest}
        />
      ),
    },
    {
      field: 'print',
      render: rowData => <PrintLoad id={rowData.id} />,
    },
  ];

  const [deleteState, setDeleteState] = React.useState({
    open: false,
    item: {},
  });

  const today = moment();
  const actualDate = moment();
  const weekdate = today.add(-7, 'day');

  const [localState, setLocalState] = React.useState({
    search: '',
    orderByColumn: '',
    orderByDirection: '',
    page: 1,
    perPage: 5,
    dateStart: weekdate.format('YYYY-MM-DD'),
    dateEnd: actualDate.format('YYYY-MM-DD'),
  });

  const { orderList, orderListLoading, orderListTotal, orderDeleteLoading } =
    useSelector(state => state.admin.order);

  const handleAlertDialogClose = () => {
    setDeleteState({ open: false, item: {} });
  };

  React.useEffect(() => {
    if (orderDeleteLoading === false && deleteState.open) {
      handleAlertDialogClose();
    }
  }, [orderDeleteLoading]);

  const getFunction = data => {
    setLocalState(oldLocalState => ({
      ...oldLocalState,
      ...data,
      search: data.search,
    }));
  };

  React.useEffect(() => {
    dispatch(OrderCreators.getOrderListRequest(localState));
  }, [localState]);

  const onDeleteRequest = item => {
    setDeleteState({ open: true, item });
  };

  const onDeleteConfirm = () => {
    dispatch(OrderCreators.getOrderDeleteRequest(deleteState.item.id));
  };

  return (
    <div style={{ marginLeft: 10 }}>
      <HeaderComponent title="Últimos Pedidos" />

      <Paper>
        <DefaultTable
          getFunction={getFunction}
          columns={columns({ onDeleteRequest })}
          data={orderList}
          total={orderListTotal}
          isLoading={orderListLoading}
          page={localState.page}
          perPage={localState.perPage}
        />
      </Paper>
      <AlertDialog
        isOpen={deleteState.open}
        isLoading={orderListLoading}
        handleClose={handleAlertDialogClose}
        onConfirm={onDeleteConfirm}
        title="Excluir registro?"
        description={`Remover categoria: ${deleteState.item.name}`}
      />
    </div>
  );
};

export default OrderListPageClient;
