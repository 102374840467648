import React from 'react';
import { useDispatch } from 'react-redux';
import { Creators as AuthCreators } from 'store/ducks/auth';
import { useHistory } from 'react-router-dom';
import BoxLogin from 'components/Pages/LoginPage/BoxLogin';
import { Typography, Button } from '@material-ui/core';
import styles from './styles';
import { useHistoryPage } from 'hooks/history';
import { Stack, Box } from '@chakra-ui/react';

const FormLogin = ({ setData, link, openForgot }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { history: link_history } = useHistoryPage();

  const handleLogin = data => {
    dispatch(
      AuthCreators.getAuthRequest(
        data,
        typeof link_history != 'undefined' ? link_history : link,
        setData
      )
    );
  };

  const handleRegisterStore = () => history.push('/lojas/cadastrar');
  const handleRegisterReseller = () => history.push('/revendedor/cadastrar');
  const handleRegisterClient = () => history.push('/cadastro-cliente');

  return (
    <Stack direction={['column', 'row']}>
      <BoxLogin openModal={openForgot} onSubmit={handleLogin} />

      <Box w={['full', '500px']} pt={['60px', 0]}>
        <Typography style={styles.purpleTitle}>
          Ainda não tem cadastro?
        </Typography>
        <div style={styles.boxRegister}>
          <Button
            data-cy="btn_register_client"
            style={styles.purpleButtonProceed}
            onClick={handleRegisterClient}>
            Criar conta Cliente
          </Button>
          <Button
            id="btnCriarLojista"
            style={styles.secondaryButtonProceed}
            onClick={handleRegisterStore}>
            Criar conta Lojista
          </Button>
          <Button
            id="btnCriarRevendedor"
            style={styles.secondaryButtonProceed}
            onClick={handleRegisterReseller}>
            Criar conta Revendedor
          </Button>
        </div>
      </Box>
    </Stack>
  );
};

export default FormLogin;
