/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/display-name */
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Creators as NotificationCreators } from 'store/ducks/app';
import { Creators as StoreCreators } from 'store/ducks/admin/stores';
import { useDispatch } from 'react-redux';
import {
  Typography,
  Paper,
  Box,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import DefaultTable from 'components/Tables/DefaultTable';
import { InputContainer, InputItem } from 'components/form/StyledComponents';
import appColors from 'utils/appColors';
import { Popover, withStyles, makeStyles } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import { key as googleMapsKey } from 'services/maps';
import './styles.css';
import api from 'services/api';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    width: 300 + theme.spacing(3) * 2,
  },
  margin: {
    height: theme.spacing(3),
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={`aaa ${value} metros`}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const marks = [
  {
    value: 5000,
    label: '5 km',
  },
  {
    value: 10000,
    label: '10 km',
  },
  {
    value: 20000,
    label: '20 km',
  },
  {
    value: 30000,
    label: '30 km',
  },
  {
    value: 40000,
    label: '40 km',
  },
  {
    value: 50000,
    label: '50 km',
  },
];

const IOSSlider = withStyles({
  root: {
    color: '#3880ff',
    height: 2,
    padding: '15px 0',
    margin: '50px 0px',
  },
  thumb: {
    height: 28,
    width: 28,
    backgroundColor: '#fff',
    boxShadow: iOSBoxShadow,
    marginTop: -14,
    marginLeft: -14,
    '&:focus, &:hover, &$active': {
      boxShadow:
        '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 12px)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
})(Slider);

var circle;

const AnyReactComponent = ({ text }) => (
  <div style={{ fontWeight: 'bold', color: 'red', fontSize: '14px' }}>
    {text}
  </div>
);
const DeliveryStore = ({ store }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [isLocal, setIsLocal] = React.useState(store.delivery_rule === 'local');
  const [openInfo] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [erroSave, setErrorSave] = React.useState(false);
  const [range, setRange] = React.useState({
    radius: store.delivery_radius ? store.delivery_radius : 500,
    lat: parseFloat(store.lat),
    lng: parseFloat(store.long),
  });
  const [mapData, setMapData] = React.useState(null);

  const [states, setStates] = React.useState([]);
  const [statesList, setStatesList] = React.useState([]);

  const history = useHistory();

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(false);
  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  React.useEffect(() => {
    if (store.lat && store.long)
      setRange(old => ({
        ...old,
        lat: parseFloat(store.lat),
        lng: parseFloat(store.long),
      }));
  }, []);

  const handlePopoverClose = () => {
    setAnchorEl(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const apiIsLoaded = (map, maps, center) => {
    circle = new maps.Circle({
      strokeColor: appColors.PRIMARY_COLOR,
      strokeOpacity: 0.5,
      strokeWeight: 2,
      fillColor: appColors.LIGHT_COLOR2,
      fillOpacity: 0.3,
      map,
      center: center,
      radius: center.radius,
    });
    setMapData({ maps, map, circle });
  };

  React.useEffect(() => {
    if (!mapData || range.radius < 600) return;
    circle.setMap(null);
    circle = new mapData.maps.Circle({
      strokeColor: appColors.PRIMARY_COLOR,
      strokeOpacity: 0.5,
      strokeWeight: 2,
      fillColor: appColors.LIGHT_COLOR2,
      fillOpacity: 0.3,
      map: mapData.map,
      center: range,
      radius: range.radius / 2,
    });
    handleSetRadius(range.radius);
  }, [range]);

  const columns = () => [
    {
      title: 'UF',
      field: 'state.uf',
    },
    {
      title: 'Estado',
      field: 'state.name',
    },
    {
      title: 'Remover',
      field: 'actions',
      render: rowData => (
        <Button onClick={() => handleRemoveState(rowData)}>
          <DeleteIcon />
        </Button>
      ),
    },
  ];

  React.useEffect(() => {
    if (!isLocal) {
      api.get('/v1/admin/states').then(response => {
        setStatesList(response.data.data);
      });
      api.get(`/v1/site/stores/blocked_regions`).then(response => {
        setStates(response.data.data);
      });
    }
  }, [isLocal]);

  const handleSetLocal = status => {
    setLoading(true);
    api
      .put(`/v1/admin/stores/${store.id}`, {
        delivery_rule: status ? 'local' : 'national',
      })
      .then(() => {
        setLoading(false);
        setRange(old => ({ ...old, radius: 500 }));
        setIsLocal(status);
        dispatch(StoreCreators.getStoreRequest({ id: store.id }));
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleSetState = stateTemp => {
    setLoading(true);
    api
      .post(`/v1/site/stores/blocked_regions`, {
        delivery_blocked: [stateTemp.id],
      })
      .then(response => {
        setLoading(false);
        setStates(response.data.data);
        setOpen(false);
        dispatch(StoreCreators.getStoreRequest({ id: store.id }));
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleRemoveState = stateTemp => {
    setLoading(true);
    api
      .delete(`/v1/site/stores/blocked_regions/${stateTemp.state.id}`)
      .then(() => {
        setLoading(false);
        setStates(states.filter(item => item.id !== stateTemp.id));
        dispatch(StoreCreators.getStoreRequest({ id: store.id }));
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onSubmit = async () => {
    await handleSetRadius(range.radius);
    dispatch(StoreCreators.getStoreRequest({ id: store.id }));
    dispatch(
      NotificationCreators.openNotification({
        message: 'Alterações salvas com sucesso',
        type: 'success',
      })
    );
  };

  const handleSetRadius = radius => {
    setLoading(true);
    api
      .put(`/v1/admin/stores/${store.id}`, {
        delivery_radius: radius,
      })
      .then(() => {
        setLoading(false);
        setErrorSave(false);
        dispatch(StoreCreators.getStoreRequest({ id: store.id }));
      })
      .catch(() => {
        setLoading(false);
        setErrorSave(true);
      });
  };

  if (isLocal && (!range.lat || !range.lng))
    return (
      <div>
        Para acessar esta área, atualize os dados de latitude e longitude da
        loja.
        <br />
        <br />
        <button
          style={{
            color: 'blue',
            cursor: 'pointer',
            fontWeight: 'bold',
            background: 'transparent',
            border: '0px',
          }}
          onClick={() => history.push('/minha-conta?aba=loja')}>
          Para alterar os dados, clique aqui
        </button>
      </div>
    );

  return (
    <>
      <div
        style={{
          transition: 'height 1s',
          height: openInfo ? 'auto' : '0px',
          overflow: 'hidden',
          padding: openInfo ? '20px 25px' : '0px',
          width: '100%',
        }}>
        <Typography style={{ fontSize: 20, marginTop: 30, fontWeight: 'bold' }}>
          Entrega nacional ou local
        </Typography>
        <Typography style={{ fontSize: 17 }}>
          Sua loja pode atuar com venda <b>local</b> em um raio específico e/ou
          poderá atender todo o território <b>nacional</b>.
        </Typography>

        <FormControlLabel
          control={
            <Switch
              checked={!isLocal}
              onChange={() => handleSetLocal(!isLocal)}
              name="checkedB"
              color="primary"
            />
          }
          label="Entregar nacionalmente?"
        />

        {loading && (
          <CircularProgress size={12} color={appColors.PRIMARY_COLOR} />
        )}

        {erroSave && (
          <div>
            Não foi possível salvar as alterações, para tentar novamente{' '}
            <a onClick={() => handleSetRadius(range.radius)}>
              <b>clique aqui</b>
            </a>
          </div>
        )}

        <Typography style={{ fontSize: 20, marginTop: 40, fontWeight: 'bold' }}>
          Raio de entrega local
        </Typography>
        {!isLocal && (
          <Typography style={{ fontSize: 17 }}>
            Fora do raio de entrega local, as taxas de envio serão definidas por
            correios ou transportadoras, de acordo com a escolha do cliente
            durante a compra
          </Typography>
        )}

        <IOSSlider
          aria-label="ios slider"
          defaultValue={range.radius}
          marks={marks}
          valueLabelDisplay="on"
          min={500}
          max={50000}
          onChangeCommitted={(e, value) =>
            setRange({ ...range, radius: parseInt(value) })
          }
        />

        <div style={{ width: '100%', height: '600px' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: googleMapsKey }}
            defaultCenter={range}
            onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, range)}
            defaultZoom={16}>
            <AnyReactComponent
              lat={store.lat}
              lng={store.long}
              text={store.name}
            />
          </GoogleMapReact>
        </div>

        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus>
          <Typography>
            Adicione locais onde sua loja não realizará entregas
          </Typography>
        </Popover>

        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Typography
            variant="h5"
            style={{
              fontSize: '20px',
              color: '#666',
              fontWeight: 'bold',
            }}>
            Estados bloqueados para venda
          </Typography>
          <Button
            data-cy="btn_add_state_block"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            style={{
              backgroundColor: appColors.PRIMARY_COLOR,
              color: '#fff',
              maxWidth: '200px',
              margin: '16px 0px',
            }}
            onClick={() => setOpen(true)}>
            Adicionar bloqueio
          </Button>
        </Box>

        <Paper style={{ marginTop: '16px' }}>
          <DefaultTable
            getFunction={() => alert('functrion')}
            columns={columns()}
            data={states}
            total={states.length}
            isLoading={false}
            page={1}
            perPage={states.length}
          />
        </Paper>

        <Button
          data-cy="btn_submit"
          onClick={onSubmit}
          style={{ width: 200, padding: 10, marginTop: 30 }}
          variant="contained"
          color="primary">
          Salvar
        </Button>

        <Dialog
          open={open}
          onClose={() => handleCancel()}
          aria-labelledby="form-dialog-title">
          <DialogTitle>
            <span style={{ fontSize: '20px' }}>Adicionar bloqueio</span>
          </DialogTitle>
          <DialogContent style={{ paddingTop: 0 }}>
            <DialogContentText>
              Informe o Estado onde sua loja não realizará entregas
            </DialogContentText>
            <InputContainer>
              <InputItem>
                <table data-cy="list_state" className="table-states-blocked">
                  {statesList
                    .filter(
                      item =>
                        states.findIndex(_item => _item.state.id === item.id) <
                        0
                    )
                    .map((item, idx) => (
                      <tr key={String(idx)}>
                        <td>
                          <b>{item.uf}</b> - {item.name}
                        </td>
                        <td
                          data-cy="btn_add_state"
                          className="txt-center pointer"
                          onClick={() => handleSetState(item)}>
                          <AddCircleIcon />
                        </td>
                      </tr>
                    ))}
                </table>
              </InputItem>
            </InputContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleCancel()} color="primary">
              Cancelar
            </Button>
            <Button data-cy="btn_submit " type="submit" color="primary">
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default DeliveryStore;
