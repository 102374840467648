import { call, takeLatest, all, put, select } from 'redux-saga/effects';
import api from 'services/api';

import { Types, Creators } from 'store/ducks/admin/stores';
import { callApi } from 'store/sagas/auth';

import { push } from 'connected-react-router';
import Notifications from 'react-notification-system-redux';
import { Creators as NotificationCreators } from 'store/ducks/app';

function* getStore({ payload }) {
  try {
    const { id } = payload;
    const request = call(api.get, `/v1/admin/stores/${id}`);
    const response = yield call(callApi, request);
    yield put(Creators.getStoreSuccess(response.data));
  } catch (err) {
    yield put(Creators.getStoreFailure('Erro ao buscar na API'));
  }
}

function* getStoreImageUpload(payload) {
  try {
    const { id, image_data } = payload;
    const data = new FormData();
    data.append('image', image_data);
    yield call(api.post, `/v1/admin/stores/${id}/images`, data);
    return true;
  } catch (err) {
    return false;
  }
}

function* getStoreInsert({ payload }) {
  try {
    const {
      quantity_min_whole,
      name,
      email,
      enable_automatic_withdrawal,
      description,
      cnpj,
      social_name,
      cell_phone,
      website,
      status,
      address,
      mim_time_for_preparation,
      manager,
      bank,
      image_data,
    } = payload;
    const request = call(api.post, '/v1/admin/stores', {
      quantity_min_whole,
      name,
      email,
      enable_automatic_withdrawal,
      description: description.toString('markdown'),
      cnpj,
      social_name,
      cell_phone,
      website,
      status,
      address,
      mim_time_for_preparation,
      manager,
      bank,
    });
    const response = yield call(callApi, request);
    const { id } = response.data.data;
    if (typeof image_data === 'object' && image_data instanceof File) {
      yield getStoreImageUpload({ id, image_data });
    }
    yield put(Creators.getStoreInsertSuccess());
    yield put(
      Notifications.success({ title: 'Cadastro concluido com sucesso' })
    );
    yield put(push(`/stores/update/${id}`));
  } catch (err) {
    yield put(Creators.getStoreInsertFailure('Erro ao buscar na API'));
  }
}

function* getStoreUpdate({ payload }) {
  try {
    const {
      id,
      enable_automatic_withdrawal,
      transfer_day,
      transfer_interval,
      minimum_transfer_value,
      description,
      image_data,
    } = payload;
    const request = call(api.put, `/v1/site/stores/${id}`, {
      ...payload,
      enable_automatic_withdrawal,
      transfer_interval: enable_automatic_withdrawal
        ? transfer_interval
        : 'weekly',
      transfer_day: enable_automatic_withdrawal ? transfer_day : 2,
      minimum_transfer_value: enable_automatic_withdrawal
        ? minimum_transfer_value
        : 2,
      description: description.toString('markdown'),
    });
    yield call(callApi, request);
    if (typeof image_data === 'object' && image_data instanceof File) {
      yield getStoreImageUpload({ id, image_data });
    }
    yield put(Creators.getStoreUpdateSuccess());
    yield put(
      NotificationCreators.openNotification({
        message: 'Loja alterada com sucesso',
        type: 'success',
      })
    );
    document.location.reload(true);
  } catch (err) {
    yield put(
      NotificationCreators.openNotification({
        message: 'Erro ao atualizar loja',
        type: 'error',
      })
    );
    yield put(Creators.getStoreUpdateFailure('Erro ao buscar na API'));
  }
}

function* getStoreDelete({ payload }) {
  try {
    const { id } = payload;
    const request = call(api.delete, `/v1/admin/stores/${id}`);
    yield call(callApi, request);
    yield put(Creators.getStoreDeleteSuccess());
    // Remove a categoria deletada da lista
    const { storeList, storeListTotal } = yield select(state => state.store);

    yield put(
      Creators.getStoreListSuccess({
        data: storeList.filter(doc => doc.id !== id),
        total: storeListTotal - 1,
      })
    );
  } catch (err) {
    yield put(Creators.getStoreDeleteFailure('Erro ao buscar na API'));
  }
}

function* getStoreListRequest({ payload }) {
  try {
    const { page, perPage, search, orderByColumn, orderByDirection } = payload;
    const request = call(api.get, '/v1/site/stores', {
      ...payload,
      page,
      search,
      per_page: perPage,
      order: orderByColumn,
      order_by: orderByDirection,
    });

    const response = yield call(callApi, request);
    yield put(Creators.getStoreListSuccess(response.data));
  } catch (err) {
    yield put(Creators.getStoreListFailure('Erro ao buscar na API'));
  }
}

// Individual exports for testing
export default function* storeListSaga() {
  yield all([
    takeLatest(Types.GET_REQUEST, getStore),
    takeLatest(Types.GET_INSERT_REQUEST, getStoreInsert),
    takeLatest(Types.GET_UPDATE_REQUEST, getStoreUpdate),
    takeLatest(Types.GET_DELETE_REQUEST, getStoreDelete),
    takeLatest(Types.GET_LIST_REQUEST, getStoreListRequest),
  ]);
}
