import { Button } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import appColors from 'utils/appColors';

export const Grid = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${props => props.justify};
`;

export const View = styled.div`
  padding: 10px 30px;
  margin-right: 15px;
  margin-bottom: 15px;
  background-color: #f6f6f6;
  border: ${props =>
    props.selected
      ? `3px solid ${appColors.PRIMARY_COLOR}`
      : '3px solid transparent'};
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  min-width: 150px;
`;

export const Title = styled(Typography)`
  font-size: 18px;
  color: #333;
  font-weight: 400;
  margin-top: 8px;
`;

export const Logo = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 50px;
  object-fit: cover;
  border: 3px solid #eee;
`;

export const Prosseguir = styled(Button)`
  background-color: ${appColors.PRIMARY_COLOR};
  color: white;
  padding: 8px 25px;
`;
