import { Button } from '@material-ui/core';
import React from 'react';
import appColors from 'utils/appColors';
import { withStyles } from '@material-ui/core/styles';
import { Container, Content, Titles, Buttons } from './styles';
import AppleIcon from '@material-ui/icons/Apple';
import AndroidIcon from '@material-ui/icons/Android';
import appNaming from 'utils/appNaming';
import appConfig from 'utils/appConfig';

const ButtonApp = withStyles(() => ({
  root: {
    color: appColors.PRIMARY_COLOR,
    background: '#fff',
    borderRadius: '15px',
    padding: '10px 20px',
    marginRight: '30px',
    '&:hover': {
      backgroundColor: appColors.PRIMARY_COLOR,
      color: '#fff',
    },
  },
}))(Button);

const BannerHome = () => {
  return (
    <Container image="/assets/Oferta/banners/b2.png">
      <Content>
        <Titles>
          <h1>Compre sem sair de casa</h1>
          <p>Baixe agora o {appNaming.UPER_CASE_SINGLE}</p>

          <Buttons>
            <ButtonApp
              variant="outlined"
              color="primary"
              onClick={() => (window.location.href = appConfig.APP_LINK_IOS)}>
              <AppleIcon /> Iphone
            </ButtonApp>
            <ButtonApp
              variant="outlined"
              color="primary"
              onClick={() =>
                (window.location.href = appConfig.APP_LINK_ANDROID)
              }>
              <AndroidIcon /> Android
            </ButtonApp>
          </Buttons>
        </Titles>
      </Content>
    </Container>
  );
};

export default BannerHome;
