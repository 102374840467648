/* eslint-disable no-const-assign */
/* eslint-disable react/display-name */
import React from 'react';
import DefaultTable from 'components/Tables/DefaultTable';
import HeaderComponent from 'components/HeaderComponent';
import DateRangeInput from './DateRangeInput';
import { Paper } from '@material-ui/core';
import { formatDate, toPrice } from 'utils/converters';
import moment from 'moment';
import { useTransfer } from 'hooks/transfers.js';
import { Stack } from '@chakra-ui/react';
import ReportTransferActions from './ReportTransferActions';
import ReportDetailsModal from './ReportDetailsModal';

const ReportTransferPage = () => {
  function renderSwitch(param) {
    switch (param) {
      case 'WAITING_APPROVAL':
        return 'Aguardando Aprovação';
      case 'PENDING':
        return 'Pendente';
      case 'BANK_PROCESSING':
        return 'Em processamento bancário';
      case 'DONE':
        return 'Efetivada';
      case 'CANCELLED':
        return 'Cancelada';
      case 'FAILED':
        return 'Falha na transferência. Normalmente ocorre em caso de dados bancários inválidos';
      default:
        return 'Cancelada';
    }
  }
  const columns = () => [
    {
      title: 'Id',
      field: 'asaas_transfer_id',
      render: rowData => <span>{rowData.id}</span>,
    },
    {
      title: 'Valor',
      field: 'value',
      render: rowData => <span>R$ {toPrice(rowData.value)}</span>,
    },
    {
      title: 'Status',
      field: 'status',
      render: rowData => <span>{renderSwitch(rowData.status)}</span>,
    },
    {
      title: 'Data da transação',
      field: 'created_at',
      render: rowData => <span>{formatDate(rowData.created_at)}</span>,
    },
    {
      title: 'Ações',
      render: rowData => (
        <ReportTransferActions
          rowData={rowData}
          onVisualizationRequest={onVisualizationRequest}
        />
      ),
    },
  ];
  const {
    getTransfers,
    transationList,
    transationTotalList,
    transationListLoading,
    getTransfersDetails,
    transationDetailLoading,
    transationDetailModalOpen,
    transationDetail,
    setTransationDetailModalOpen,
  } = useTransfer();

  const today = moment();
  const actualDate = moment();
  const weekdate = today.add(7, 'day');

  const [localState, setLocalState] = React.useState({
    dateStart: actualDate.format('YYYY-MM-DD'),
    dateEnd: weekdate.format('YYYY-MM-DD'),
    page: 1,
    per_page: 10,
    status: '',
  });

  const getFunction = data => {
    setLocalState(oldLocalState => ({
      ...oldLocalState,
      ...data,
    }));
  };

  const onVisualizationRequest = data => {
    getTransfersDetails(data);
  };
  const handleModalClose = () => {
    setTransationDetailModalOpen(false);
  };

  React.useEffect(() => {
    getTransfers(localState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState]);

  return (
    <Stack w="full">
      <HeaderComponent title="Relatório de saques" />
      <DateRangeInput
        getFunction={getFunction}
        initialValues={{
          search: localState.search,
          status: localState.status,
          dateStart: actualDate,
          dateEnd: weekdate,
          page: localState.page,
          per_page: localState.per_page,
        }}
      />
      <Paper>
        <DefaultTable
          getFunction={getFunction}
          columns={columns({ getTransfers })}
          data={transationList}
          total={transationTotalList}
          isLoading={transationListLoading}
          page={localState.page}
          perPage={localState.per_page}
        />
      </Paper>
      {!transationDetailLoading && transationDetail && (
        <>
          <ReportDetailsModal
            handleClose={handleModalClose}
            isOpen={transationDetailModalOpen}
            isLoading={transationDetailLoading}
            data={transationDetail}
          />
        </>
      )}
    </Stack>
  );
};

export default ReportTransferPage;
