import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import notImage from 'images/Products/not_image.jpg';

const StyledImg = styled('img')`
  && {
    max-height: 180px;
    max-width: 90px;
  }
`;

const VirtualCatalogImage = ({ rowData }) => (
  <div>
    {rowData.image.small !== '' && (
      <StyledImg
        src={rowData.image.small}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = notImage;
        }}
      />
    )}
  </div>
);

VirtualCatalogImage.propTypes = {
  rowData: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default VirtualCatalogImage;
