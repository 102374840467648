import React from 'react';
import PropTypes from 'prop-types';
import RichTextEditor from 'react-rte';
import { Typography } from '@material-ui/core';

const CustomRichText = ({ field, label, form }) => {
  const onChange = value => {
    form.setFieldValue(field.name, value);
    //form.setFieldValue(field.name, value.target.value);
  };
  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: [],
    INLINE_STYLE_BUTTONS: [],
    BLOCK_TYPE_DROPDOWN: [],
    BLOCK_TYPE_BUTTONS: [],
  };
  return (
    <>
      <Typography
        variant="h6"
        style={{
          color: '#282829',
          marginTop: 10,
          marginBottom: 14,
          marginLeft: -1,
        }}>
        {label}
      </Typography>
      {/* <TextField
        id="standard-multiline-static"
        label={label}
        multiline
        variant="outlined"
        value={field.value}
        onChange={onChange}
        rows={6}
        defaultValue="Default Value"
        style={{ width: '100%' }}
      /> */}
      <RichTextEditor
        toolbarConfig={toolbarConfig}
        value={field.value || RichTextEditor.createEmptyValue()}
        onChange={onChange}
        style={{ minHeight: '300px' }}
      />
    </>
  );
};

CustomRichText.propTypes = {
  field: PropTypes.oneOfType([PropTypes.object]).isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  label: PropTypes.string.isRequired,
};

export default CustomRichText;
