import React from 'react';
import VirtualCatalogForm from 'components/Pages/VirtualCatalogPage/VirtualCatalogForm';
import VirtualCatalogFormNew from 'components/Pages/VirtualCatalogPageNew/VirtualCatalogForm';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from 'store/ducks/admin/virtualCatalog';
import { appTabs } from 'components/Pages/MyAccountPage/PainelAccount/TabPanelShopkeeper';
import { Creators as PanelTabsCreators } from 'store/ducks/panelTabs';
import { useHistory } from 'react-router-dom';
import { Stack } from '@chakra-ui/react';

const VirtualCatalogNewPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = data => {
    dispatch(Creators.getVirtualCatalogInsertRequest(data));
  };

  const handleBack = () => {
    history.push({
      search: `?aba=catalogo`,
    });
    dispatch(PanelTabsCreators.changeTab(appTabs.virtualCatalogList));
  };

  const { virtualCatalogInsertLoading } = useSelector(
    state => state.admin.virtualCatalog
  );

  const { client, clientLoading } = useSelector(state => state.client);

  const getVirtualForm = () => {
    if (typeof client.group === 'object')
      if (!clientLoading && client.group.id === 4) {
        return (
          <VirtualCatalogFormNew
            onSubmit={onSubmit}
            handleBack={handleBack}
            isLoading={virtualCatalogInsertLoading}
          />
        );
      } else {
        return (
          <VirtualCatalogForm
            onSubmit={onSubmit}
            handleBack={handleBack}
            isLoading={virtualCatalogInsertLoading}
          />
        );
      }
  };

  return (
    <Stack w={['full', '850px']}>
      <Stack>{getVirtualForm()}</Stack>
    </Stack>
  );
};

export default VirtualCatalogNewPage;
