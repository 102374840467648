import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;

  .MuiButton-textPrimary {
    color: blue;
  }

  .MuiButton-label {
    color: blue;
  }
  .MuiButtonBase-root {
    color: blue;
  }
`;
