import React from 'react';
import { Box, Typography, Breadcrumbs } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from 'react-router-dom';
import styles from './styles';

const HeaderInfoDetails = () => (
  <>
    <Box display="flex" justifyContent="flex-start">
      <Typography style={styles.purpleTitle}>Informações</Typography>
    </Box>
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb">
      <Link to="/">Início</Link>
      <Typography color="textPrimary">Especificações</Typography>
    </Breadcrumbs>
  </>
);

export default HeaderInfoDetails;
