/* eslint-disable react/display-name */
import React from 'react';
import PageBase from 'components/PageBase';
import DefaultTable from 'components/Tables/DefaultTable';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as OrderCreators } from 'store/ducks/admin/order';
import { Creators as ReportCommissionCreators } from 'store/ducks/admin/reportCommission';
import HeaderComponent from 'components/HeaderComponent';
import SaleTableHeader from 'components/SalePage/SaleTableHeader';
import { Paper } from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';
import { formatDate2, toPrice } from 'utils/converters';
import SaleCommissionInfo from 'components/Pages/SaleCommissionPage/SaleCommissionInfo';

const SaleCommissionPage = () => {
  const columns = () => [
    { title: 'Pedido', field: 'id', type: 'numeric' },
    {
      title: 'Valor',
      field: 'price',
      render: rowData => <span>{`R$ ${toPrice(rowData.price)}`}</span>,
    },
    {
      title: 'Comissão',
      field: 'commissions.total',
      render: rowData => (
        <span>{`R$ ${toPrice(rowData.commissions.total)}`}</span>
      ),
    },
    {
      title: 'C.Revendedor',
      field: 'commissions.reseller',
      render: rowData => (
        <span>{`R$ ${toPrice(rowData.commissions.reseller)}`}</span>
      ),
    },
    {
      title: 'Instituição Financeira',
      field: 'commissions.financial',
      render: rowData => (
        <span>{`R$ ${toPrice(rowData.commissions.financial)}`}</span>
      ),
    },
    {
      title: 'Realizado',
      field: 'created_at',
      render: rowData => <span>{formatDate2(rowData.created_at)}</span>,
    },
  ];

  const dispatch = useDispatch();
  const [deleteState, setDeleteState] = React.useState({
    open: false,
    item: {},
  });

  const { client } = useSelector(state => state.client);

  const [localState, setLocalState] = React.useState({
    search: '',
    orderByColumn: '',
    orderByDirection: '',
    page: 1,
    perPage: 10,
    dateStart: '',
    dateEnd: '',
    store_id: client.store ? client.store.id : '',
    client_id: '',
    payment_type: '',
    status: '',
  });

  const { orderList, orderListLoading, orderListTotal, orderDeleteLoading } =
    useSelector(state => state.admin.order);

  const { reportCommission, reportCommissionLoading } = useSelector(
    state => state.admin.reportCommission
  );

  React.useEffect(() => {
    dispatch(OrderCreators.getOrderListRequest(localState));
    dispatch(ReportCommissionCreators.getReportCommissionRequest(localState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAlertDialogClose = () => {
    setDeleteState({ open: false, item: {} });
  };

  React.useEffect(() => {
    if (orderDeleteLoading === false && deleteState.open) {
      handleAlertDialogClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDeleteLoading]);

  const getFunction = data => {
    setLocalState(oldLocalState => ({ ...oldLocalState, ...data }));
  };

  React.useEffect(() => {
    dispatch(OrderCreators.getOrderListRequest(localState));
    dispatch(ReportCommissionCreators.getReportCommissionRequest(localState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState]);

  const onDeleteRequest = item => {
    setDeleteState({ open: true, item });
  };

  const onDeleteConfirm = () => {
    dispatch(OrderCreators.getOrderDeleteRequest(deleteState.item.id));
    dispatch(ReportCommissionCreators.getReportCommissionRequest(localState));
  };

  return (
    <PageBase>
      <HeaderComponent title="Relatório de Comissões" centralized>
        <SaleTableHeader
          getFunction={getFunction}
          initialValues={{
            search: localState.search,
            dateStart: null,
            dateEnd: null,
            store_id: localState.store_id,
            client_id: localState.client_id,
            payment_type: localState.payment_type,
            status: localState.status,
          }}
        />
      </HeaderComponent>
      <SaleCommissionInfo
        reportCommission={reportCommission}
        reportCommissionLoading={reportCommissionLoading}
      />
      <Paper>
        <DefaultTable
          getFunction={getFunction}
          columns={columns({ onDeleteRequest })}
          data={orderList}
          total={orderListTotal}
          isLoading={orderListLoading}
          page={localState.page}
          perPage={localState.perPage}
          simplified
        />
      </Paper>
      <AlertDialog
        isOpen={deleteState.open}
        isLoading={orderListLoading}
        handleClose={handleAlertDialogClose}
        onConfirm={onDeleteConfirm}
        title="Excluir registro?"
        description={`Remover pedido: ${deleteState.item.name}`}
      />
    </PageBase>
  );
};

export default SaleCommissionPage;
