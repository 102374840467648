import {
  Button as ButtonBase,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import appColors from 'utils/appColors';

export const GridView = styled(Grid)`
  && {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: ${props => props.justify};
  }
`;

export const PaymentCard = styled(Paper)`
  && {
    cursor: pointer;
    padding: 20px;
    background-color: #eeee;
    width: 350px;
    min-height: 180px;
    margin-right: 20px;
    margin-bottom: 10px;
    border: ${p =>
      p.active
        ? `3px solid ${appColors.PRIMARY_COLOR}`
        : '3px solid transparent'};
  }
`;

export const Title = styled(Typography)`
  && {
    font-size: 24px;
    color: #333;
    font-weight: 400;
    margin-top: 8px;
  }
`;

export const Text = styled(Typography)`
  && {
    font-size: ${p => p.fontSize || '16px'};
    color: ${p => p.color || '#777'};
    font-weight: 400;
    margin-top: 8px;
  }
`;

export const Button = styled(ButtonBase)`
  && {
    margin-top: 30px;
    background-color: ${p =>
      p.disabled ? '#f1f1f1' : appColors.PRIMARY_COLOR};
    color: ${p => (p.disabled ? '#999' : 'white')};
    padding: 10px 60px;
    &:hover {
      background-color: ${appColors.PRIMARY_COLOR};
    }
  }
`;
