/* eslint-disable no-duplicate-case */
/*
 *
 * Config reducer
 *
 */

export const Types = {
  // Obtem dados página config
  GET_REQUEST: 'config/GET_REQUEST',
  GET_SUCCESS: 'config/GET_SUCCESS',
  GET_FAILURE: 'config/GET_FAILURE',
};

export const initialState = {
  // Lista página config banner e categorias
  config: {},
  configLoading: false,
  configError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Lista página config banner e categorias
    case Types.GET_REQUEST:
      return { ...state, configLoading: true };
    case Types.GET_SUCCESS:
      return {
        ...state,
        config: action.payload,
        configLoading: false,
        configError: null,
      };
    case Types.GET_FAILURE:
      return {
        ...state,
        configLoading: false,
        configError: action.payload,
      };
    default:
      return state;
  }
};

export const Creators = {
  // Lista página config banner e categorias
  getConfigRequest: () => ({
    type: Types.GET_REQUEST,
  }),
  getConfigSuccess: ({ data }) => ({
    type: Types.GET_SUCCESS,
    payload: data,
  }),
  getConfigFailure: error => ({
    type: Types.GET_FAILURE,
    payload: error,
  }),
};
