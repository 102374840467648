import * as Yup from 'yup';
import { validateBr } from 'js-brasil';
import moment from 'moment';

export const validationSchema = Yup.object().shape({
  card: Yup.object().shape({
    number: Yup.string().required('Insira o número do cartão'),
    validate: Yup.mixed().test(
      'test-credit-card-expiration-date',
      'Insira uma data válida',
      expire => {
        if (!expire) {
          return false;
        }
        const today = new Date();
        const year = Number(today.getFullYear());
        const [mes, ano] = expire.split('/');
        if (Number(ano) > year && mes) {
          return true;
        }
        return false;
      }
    ),
    cvc: Yup.string().required('Insira o código de segurança do cartão'),
    installment: Yup.string(),
    holder: Yup.object().shape({
      name: Yup.string().required('Insira o nome do titular do cartão'),
      birth_date: Yup.mixed()
        .test('valid-date', 'Por Favor, insira uma data válida', val =>
          moment(val, 'DD/MM/YYYY').isValid()
        )
        .test('valid-length', 'Por Favor, insira uma data válida', val =>
          val ? val.replace(/[/_-]/g, '').length === 8 : false
        )
        .test(
          'is-of-age',
          'É necessário ser maior de 18 anos',
          val => moment().diff(moment(val, 'DD/MM/YYYY'), 'years') >= 18
        ),
      cpf: Yup.string()
        .required('Insira o CPF do titular do cartão')
        .test('cpf', 'cpf não é válido', val => validateBr.cpf(val)),
    }),
  }),
});

export const cardInitialValues = {
  card: {
    number: '',
    validate: '',
    cvc: '',
    holder: {
      name: '',
      birth_date: '',
      cpf: '',
      observation: '',
    },
  },
};
