import React from 'react';
import { Box, Typography, CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as OrderCreators } from 'store/ducks/order';
import HeaderComponent from 'components/HeaderComponent';
import appColors from 'utils/appColors';
import OrderItem from './OrderItem';

const OrdersTab = () => {
  const [filters] = React.useState({
    page: 1,
    perPage: 10,
    search: '',
    orderByColumn: '',
    orderByDirection: '',
    order: '',
  });

  const dispatch = useDispatch();
  const { orderList, orderListLoading } = useSelector(state => state.order);

  React.useEffect(() => {
    dispatch(OrderCreators.getOrderListRequest(filters));
  }, [dispatch, filters]);

  return (
    <Box flex="1" display="flex" flexDirection="column">
      <HeaderComponent title="Minhas Compras" />
      {orderList.length === 0 && !orderListLoading && (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
            style={{
              backgroundColor: '#FFF8D2',
              padding: 20,
              borderRadius: 5,
            }}>
            <Typography style={{ color: '#757575', fontStyle: 'italic' }}>
              Nenhum pedido foi feito ainda
            </Typography>
            <Link to="/" style={{ color: '#9E6C17' }}>
              <Typography style={{ fontWeight: 'bold' }}>
                Ir às compras
              </Typography>
            </Link>
          </Box>
        </>
      )}

      {orderList.length > 0 && !orderListLoading && (
        <>
          {orderList.map(
            item =>
              item.content &&
              item.content.map(data => (
                <OrderItem key={data.id} open={false} data={data} />
              ))
          )}
        </>
      )}

      {orderList.length === 0 && orderListLoading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ height: 300 }}>
          <CircularProgress
            data-cy="loading_screen_my_orders"
            style={{ color: appColors.PRIMARY_COLOR }}
          />
        </Box>
      )}
    </Box>
  );
};

export default OrdersTab;
