/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-key */
// @flow
import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import { InputContainer, InputItem } from 'components/form/StyledComponents';
import CustomTextField from 'components/form/CustomTextField';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AttachMoney from '@material-ui/icons/AttachMoney';
import * as Yup from 'yup';
import {
  CustomRadioGroupItem,
  CustomRadioGroup,
} from 'components/form/CustomRadioGroup';
// import styles from './styles';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import CustomMaskField from 'components/form/CustomMaskField';
import { FaBarcode } from 'react-icons/fa';
import CustomSelect from 'components/form/CustomSelect';
import validators from 'utils/validators';
import appColors from 'utils/appColors';
import { Creators as ConfigCreators } from 'store/ducks/config';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import styles, { ContainerForm, TicketContainer } from './styles';
import appUtils from 'utils/appUtils';
import CustomTextArea from 'components/form/components/CustomTextArea';
import api from 'services/api';
import { getKondutoVisitorId } from '../../../../services/konduto';
import CustomMaskField2 from 'components/form/components/CustomMaskField2';
import { useControllableState } from '@chakra-ui/hooks';
import { put } from 'redux-saga/effects';
import { Creators as NotificationCreators } from 'store/ducks/app';
import { useLocation } from 'react-router-dom';

const schema = Yup.object().shape({
  quote_hash: Yup.string(),
  observation: Yup.string(),
  schedule: Yup.object().shape({
    time: Yup.string(),
    date: Yup.string(),
  }),
  payment: Yup.object().shape({
    type: Yup.string(),
    card: Yup.object().when('type', {
      is: '1',
      then: Yup.object().shape({
        number: Yup.string().required('Insira o número do cartão'),
        validate: Yup.string()
          .typeError('Data inválida')
          .required('Insira a data de validade corretamente MM/AAAA'),
        cvc: Yup.string().required('Insira o código de segurança do cartão'),
        installment: Yup.string(),
        holder: Yup.object().shape({
          name: Yup.string().required('Insira o nome do titular do cartão'),
          birth_date: Yup.mixed()
            .test('valid-date', 'Por Favor, insira uma data válida', val =>
              moment(val, 'DD/MM/YYYY').isValid()
            )
            .test('valid-length', 'Por Favor, insira uma data válida', val =>
              val ? val.replace(/[/_-]/g, '').length === 8 : false
            )
            .test(
              'is-of-age',
              'É necessário ser maior de 18 anos',
              val => moment().diff(moment(val, 'DD/MM/YYYY'), 'year') >= 18
            ),
          cpf: Yup.lazy(value => {
            if (value.length <= 14)
              return Yup.string()
                .required('Insira o CPF do titular do cartão')
                .test(...validators.cpfInvalid('CPF inválido'));

            return Yup.string().required('Insira o CNPJ do titular do cartão');
          }),
        }),
      }),
      otherwise: Yup.object().shape({}),
    }),
  }),
});

const FinalizationForm = ({
  onSubmit,
  quote,
  onChange,
  total,
  orderInsertLoading,
  maxInstallment,
  address,
  budget_hash,
}: {
  onSubmit: () => void,
  quote: [],
}) => {
  const location = useLocation();
  const budgetHash = new URLSearchParams(location.search).get('budget_hash');

  const initialValues = {
    quote_hash: quote[0].quote_hash,
    buget_hash: budget_hash,
    visitorID: getKondutoVisitorId(),
    schedule: {
      time: '',
      date: '',
    },
    payment: {
      observation: '',
      type: 0,
      card: {
        number: '',
        validate: '',
        cvc: '',
        installment: maxInstallment,
        holder: {
          name: '',
          birth_date: '',
          cpf: '',
          observation: '',
        },
      },
    },
  };
  const hours = [
    '00:00:00',
    '01:00:00',
    '02:00:00',
    '03:00:00',
    '04:00:00',
    '05:00:00',
    '06:00:00',
    '07:00:00',
    '08:00:00',
    '09:00:00',
    '10:00:00',
    '11:00:00',
    '12:00:00',
    '13:00:00',
    '14:00:00',
    '15:00:00',
    '16:00:00',
    '17:00:00',
    '18:00:00',
    '19:00:00',
    '20:00:00',
    '21:00:00',
    '22:00:00',
    '23:00:00',
  ];
  const { configLoading } = useSelector(state => state.config);
  const installments_data = [
    { id: 1, name: '1x Parcela' },
    { id: 2, name: '2x Parcelas' },
    { id: 3, name: '3x Parcelas' },
    { id: 4, name: '4x Parcelas' },
    { id: 5, name: '5x Parcelas' },
    { id: 6, name: '6x Parcelas' },
    { id: 7, name: '7x Parcelas' },
    { id: 8, name: '8x Parcelas' },
    { id: 9, name: '9x Parcelas' },
    { id: 10, name: '10x Parcelas' },
    { id: 11, name: '11x Parcelas' },
    { id: 12, name: '12x Parcelas' },
  ];
  const filter_installment = installments_data.filter(
    (x, i) => i + 1 <= maxInstallment
  );
  const dispatch = useDispatch();
  const [cardValidate, setCardValidate] = React.useState(false);
  const [daysWeek, setDaysWeek] = useState([
    'Dom',
    'Seg',
    'Ter',
    'Qua',
    'Qui',
    'Sex',
    'Sáb',
  ]);
  const [scheduleData, setScheduleData] = React.useState([]);
  const [scheduleEnable, setScheduleEnable] = useState(false);
  const [periods, setPeriods] = useState();
  const [today, setToday] = useState();
  const [h, setH] = useState([]);
  const [selectedCard, setSelectedCard] = useState();
  const [scheduleOrder, setScheduleOrder] = useState();

  const validateDateValid = value => {
    let error;
    if (value.replace(/[^\d]+/g, '').length < 6) {
      error = 'Digite a data de validade corretamente';
    }
    return error;
  };
  const handleIsValid = (type, isValid) => {
    setCardValidate(isValid);
  };

  const createHoursInterval = data => {
    let initial = hours.indexOf(data.periods[0]?.start);
    let final = hours.indexOf(data.periods[0]?.end);
    let final_position = hours.length - 1;
    let hour = hours.splice(final + 1, final_position);
    hour = hours.splice(0, initial);
    setH(hours);
  };

  const [scheduleTime, setScheduleTime] = useState([]);

  const handleHoursBetweenTime = (start, end, interval, values) => {
    if (start <= end) {
      return handleHoursBetweenTime(
        start + interval,
        end,
        interval,
        values.concat(start)
      );
    }

    if (values.length <= 1) {
      return values.concat(end);
    }

    const indexLastHour = values.length - 1;

    values[indexLastHour] =
      values[indexLastHour] + (end - values[indexLastHour]); // add up the minutes out of the range of the interval

    return values;
  };

  const handleHour = (start, end, interval = '01:00:00') => {
    const startObj = moment.duration(start, 'HH:mm');

    const endObj = moment.duration(end, 'HH:mm');
    const intervalObj = moment.duration(interval, 'HH:mm');

    return handleHoursBetweenTime(
      startObj.asMinutes(),
      endObj.asMinutes(),
      intervalObj.asMinutes(),
      []
    );
  };

  const handleSetOfHoursToStr = (hours, day_of_week, startObj) => {
    return hours
      .map((start, index) => {
        const end = hours[index + 1];
        const now = moment
          .duration(moment().format('HH:mm'), 'HH:mm')
          .asMinutes();

        if (!end || startObj) {
          return undefined;
        }

        return {
          start: moment().startOf('day').add(start, 'minutes').format('HH:mm'),
          end: moment().startOf('day').add(end, 'minutes').format('HH:mm'),
        };
      })
      .filter(Boolean);
  };

  const getScheduleTime = () => {
    if (!periods) {
      return;
    }

    const times = periods?.flatMap(({ start, end, day_of_week }) => {
      const hours = handleHour(start, end);

      const collect = handleSetOfHoursToStr(hours, day_of_week);

      return collect.map(({ start, end }) => {
        const id = Math.random().toString(16).slice(2);
        const day = day_of_week == today ? 'Hoje' : daysWeek[day_of_week];
        console.log(start);

        return {
          id,
          start,
          end,
          day_of_week,
          day,
        };
      });
    });

    setScheduleTime(times);
  };

  React.useEffect(() => {
    dispatch(ConfigCreators.getConfigRequest());
    let d = new Date();
    let wk = d.getDay();
    setToday(wk);
  }, [dispatch]);

  useEffect(() => {
    if (quote[0].enable_scheduled_deliveries) {
      const data = {
        enabled: quote[0].enable_scheduled_deliveries,
        periods: quote[0].available_delivery_periods,
      };

      createHoursInterval(data);
      setScheduleData(data);
      setScheduleEnable(quote[0].enable_scheduled_deliveries);
      setPeriods(quote[0].available_delivery_periods);
    }
  }, [quote]);

  useEffect(() => {
    getScheduleTime();
  }, [periods]);

  const validateCard = () => {
    let error;
    if (!cardValidate) {
      error = 'Número de cartão inválido';
    }
    return error;
  };

  let btnRef = React.useRef();
  const [showAddresModal, setShowAddresModal] = useState(false);

  function handleSelectedCard(d, schedule) {
    const date = moment(d.getTime()).format('YYYY-MM-DD');
    const time = schedule.start;

    d.setDate(d.getDate() + ((schedule.day_week + (7 - d.getDay())) % 7));

    setScheduleOrder({
      ...scheduleOrder,
      time,
      date,
    });
    console.log(date);
    setSelectedCard({ schedule_id: schedule.id });
  }

  const handleScheduleTimeFormat = (beginTime, endTime) => {
    const [beginHours, beginMinutes] = beginTime.split(':');
    const [endHours, endMinutes] = endTime.split(':');

    return `${beginHours}:${beginMinutes} - ${endHours}:${endMinutes}`;
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(data, { setSubmitting }) => {
        try {
          const schedule = scheduleEnable
            ? {
                date: scheduleOrder?.date,
                time: scheduleOrder?.time,
              }
            : null;

          // Adicionando budget_hash diretamente ao objeto data
          const modifiedData = {
            ...data,
            budget_hash: budgetHash,
            schedule,
            payment: {
              ...data.payment,
              type: Number(data.payment.type),
            },
          };

          onSubmit(modifiedData);
        } catch (error) {
          dispatch(
            NotificationCreators.openNotification({
              message: 'Não foi possível realizar o pagamento',
              type: 'error',
            })
          );
        } finally {
          setSubmitting(false);
        }
      }}
      validateOnBlur
      render={({ handleSubmit, values }) => (
        <Box>
          <Form
            onSubmit={handleSubmit}
            onChange={onChange}
            style={{ marginBottom: 20 }}>
            <Box>
              <Typography
                style={{
                  backgroundColor: appColors.PRIMARY_COLOR,
                  color: 'white',
                  textTransform: 'uppercase',
                  padding: 10,
                  fontWeight: 'bold',
                }}>
                Frete
              </Typography>
              <InputContainer>
                <InputItem style={{ flexGrow: 10 }}>
                  <Field name="quote_hash" row component={CustomRadioGroup}>
                    {quote.map(item => (
                      <Box key={item.quote_hash} style={styles.quoteContainer}>
                        <Field
                          key={item.quote_hash}
                          component={CustomRadioGroupItem}
                          label={
                            <Typography
                              data-cy="select-delivery-type"
                              style={styles.purpleTextBold}>
                              {item.name}
                            </Typography>
                          }
                          value={item.quote_hash}
                        />
                        <Box style={{ marginLeft: 30 }}>
                          <Typography>
                            <span style={styles.purpleTextBold}>Tipo</span>:{' '}
                            {item.name}
                          </Typography>
                          <Typography>
                            <span style={styles.purpleTextBold}>Via</span>:{' '}
                            {item.carrier}
                          </Typography>
                          {item.name == 'Retirar no Local' && (
                            <Typography>
                              <span style={styles.purpleTextBold}>Valor</span>:
                              R$ 00,00
                            </Typography>
                          )}
                          {item.name !== 'Retirar no Local' && (
                            <Typography>
                              <span style={styles.purpleTextBold}>Valor</span>:{' '}
                              {appUtils.formatPrice(item.price)}
                            </Typography>
                          )}
                          <Typography>{item.description}</Typography>
                        </Box>
                      </Box>
                    ))}
                  </Field>
                </InputItem>
              </InputContainer>
            </Box>
            {scheduleEnable && !!scheduleTime.length && (
              <Accordion>
                <AccordionSummary
                  style={{ backgroundColor: appColors.PRIMARY_COLOR }}>
                  <Typography
                    style={{
                      color: 'white',
                      textTransform: 'uppercase',
                      fontWeight: 'bold',
                    }}>
                    Agendamento
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box style={styles.quoteContainerSchedule}>
                    <Box
                      style={{
                        marginLeft: 30,
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                      }}>
                      {scheduleTime?.map(schedule => (
                        <Box
                          style={
                            selectedCard?.schedule_id === schedule.id
                              ? styles.cardScheduleCk
                              : styles.cardSchedule
                          }
                          onClick={() => {
                            handleSelectedCard(new Date(), schedule);
                          }}>
                          <Typography
                            data-cy="select-delivery-type"
                            style={
                              selectedCard?.schedule_id === schedule.id
                                ? styles.purpleTextBoldCk
                                : styles.purpleTextBold
                            }>
                            Entrega agendada
                          </Typography>
                          <Typography
                            style={
                              selectedCard?.schedule_id === schedule.id
                                ? styles.textScheduleCardCk
                                : styles.textScheduleCard
                            }>
                            {schedule['day']},{' '}
                            {handleScheduleTimeFormat(
                              schedule.start,
                              schedule.end
                            )}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            )}
            <Box>
              <Typography
                style={{
                  backgroundColor: appColors.PRIMARY_COLOR,
                  color: 'white',
                  textTransform: 'uppercase',
                  padding: 10,
                  marginTop: 15,
                  fontWeight: 'bold',
                }}>
                DADOS DO PAGAMENTO
              </Typography>
              <InputContainer style={{ marginTop: 10 }}>
                <InputItem style={{ flexGrow: 10, marginTop: 0 }}>
                  <Field name="payment.type" row component={CustomRadioGroup}>
                    {typeof values.quote_hash !== 'undefined' &&
                      values.quote_hash !== null &&
                      typeof values.quote_hash === 'string' &&
                      quote != null &&
                      quote[0].accepted_payment_types.map(item => (
                        <InputItem
                          key={item.name}
                          style={{ flexGrow: 10, marginTop: 0 }}>
                          <Field
                            component={CustomRadioGroupItem}
                            label={
                              <Typography
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  fontWeight: 'bold',
                                  color: appColors.PRIMARY_COLOR,
                                  fontSize: 22,
                                }}>
                                {item.payment_type === 3 && (
                                  <AttachMoney
                                    fontSize="large"
                                    style={{ marginRight: 20 }}
                                  />
                                )}

                                {item.payment_type === 2 && (
                                  <FaBarcode
                                    fontSize="large"
                                    style={{ marginRight: 20 }}
                                  />
                                )}

                                {item.payment_type === 1 && (
                                  <CreditCardIcon
                                    fontSize="large"
                                    style={{ marginRight: 20 }}
                                  />
                                )}
                                {item.name}
                              </Typography>
                            }
                            value={String(item.payment_type)}
                          />
                        </InputItem>
                      ))}
                  </Field>
                </InputItem>
              </InputContainer>
              {/* Cartao */}
              {values.payment.type == 1 && (
                <Box>
                  <Box
                    display="flex"
                    flex="1"
                    flexDirection="row"
                    alignItems="center"
                    flexWrap="wrap"
                    justifyContent="center"
                    style={{ marginTop: 60 }}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      style={{ marginBottom: 30, marginRight: 20 }}>
                      <Cards
                        cvc={values.payment.card.cvc}
                        expiry={values.payment.card.validate}
                        focus="name"
                        name={values.payment.card.holder.name}
                        number={values.payment.card.number}
                        acceptedCards={['visa', 'mastercard', 'elo']}
                        locale={{ valid: 'data de val.' }}
                        placeholders={{
                          name: 'NOME DO TITULAR',
                        }}
                        callback={handleIsValid}
                      />
                    </Box>
                    <ContainerForm>
                      <InputContainer style={{ marginTop: 0 }}>
                        <InputItem style={{ marginTop: 0 }}>
                          <Field
                            name="payment.card.number"
                            label="Numero do cartao"
                            validate={validateCard}
                            mask="9999 9999 9999 9999"
                            component={CustomMaskField}
                          />
                        </InputItem>
                      </InputContainer>
                      <InputContainer style={{ marginTop: 0 }}>
                        <InputItem style={{ marginTop: 0 }}>
                          <Field
                            name="payment.card.holder.email"
                            label="email"
                            type="email"
                            component={CustomMaskField}
                          />
                        </InputItem>
                      </InputContainer>
                      <InputContainer style={{ marginTop: 0 }}>
                        <InputItem style={{ marginTop: 0 }}>
                          <Field
                            name="payment.card.holder.phone"
                            label="Telefone"
                            mask="(99) 99999-9999"
                            component={CustomMaskField}
                          />
                        </InputItem>
                      </InputContainer>
                      <InputContainer style={{ marginTop: 0 }}>
                        <InputItem style={{ marginTop: 0 }}>
                          <Field
                            name="payment.card.holder.postalcode"
                            label="Cep"
                            mask="99999-999"
                            component={CustomMaskField}
                          />
                        </InputItem>
                      </InputContainer>
                      <InputContainer style={{ marginTop: 0 }}>
                        <InputItem style={{ marginTop: 0 }}>
                          <Field
                            name="payment.card.holder.addressNumber"
                            label="Número Residencial"
                            type="number"
                            component={CustomTextField}
                          />
                        </InputItem>
                      </InputContainer>
                      <InputContainer>
                        <InputItem>
                          <Field
                            name="payment.card.validate"
                            validate={validateDateValid}
                            label="Data de Validade"
                            mask="99/9999"
                            component={CustomMaskField}
                          />
                        </InputItem>
                        <InputItem>
                          <Field
                            name="payment.card.cvc"
                            label="CVC"
                            type="text"
                            component={CustomTextField}
                          />
                        </InputItem>
                        <InputItem>
                          <Field
                            name="payment.card.installment"
                            options={filter_installment}
                            placeholder="Quantidade de Parcelas"
                            isLoading={configLoading}
                            component={CustomSelect}
                          />
                        </InputItem>
                      </InputContainer>
                      <InputContainer>
                        <InputItem style={{ flexGrow: 3 }}>
                          <Field
                            name="payment.card.holder.name"
                            label="Nome/Razão Social Titular"
                            component={CustomTextField}
                          />
                        </InputItem>
                      </InputContainer>
                      <InputContainer>
                        <InputItem>
                          <Field
                            name="payment.card.holder.birth_date"
                            label="Data de nascimento do titular"
                            mask="99/99/9999"
                            component={CustomMaskField2}
                          />
                        </InputItem>
                        {values.payment.card.holder.cpf.length <= 14 && (
                          <InputItem>
                            <Field
                              name="payment.card.holder.cpf"
                              label="CPF/CNPJ do titular"
                              mask="999.999.999-999"
                              component={CustomMaskField2}
                            />
                          </InputItem>
                        )}
                        {values.payment.card.holder.cpf.length > 14 && (
                          <InputItem>
                            <Field
                              name="payment.card.holder.cpf"
                              label="CPF/CNPJ do titular"
                              mask="99.999.999/9999-99"
                              component={CustomMaskField2}
                            />
                          </InputItem>
                        )}
                      </InputContainer>
                      {/* Cartao */}
                    </ContainerForm>
                  </Box>
                  <Typography
                    data-cy="text_payment_card"
                    style={{ marginTop: 30 }}>
                    <b>Importante:</b> Em caso de pagamento parcelado o valor
                    total da compra não pode exceder o limite de seu cartão.
                    Para sua segurança, as informações de crédito poderão passar
                    por uma análise interna, sujeita à confirmação via contato
                    telefônico ou e-mail.
                  </Typography>
                  <InputContainer>
                    <InputItem style={{ width: '50%' }}>
                      <Field
                        name="observation"
                        label="Observações: "
                        component={CustomTextArea}
                      />
                    </InputItem>
                  </InputContainer>
                  <Box
                    flex="1"
                    display="flex"
                    alignItems="center"
                    justifyContent="center">
                    <Button
                      ref={btnRef}
                      onClick={() => setShowAddresModal(true)}
                      data-cy="btn_pay_with_card"
                      onDoubleClick={() => {}}
                      style={styles.purpleButton}
                      disabled={orderInsertLoading}>
                      <CreditCardIcon
                        fontSize="large"
                        style={{ marginRight: 20 }}
                      />
                      {orderInsertLoading
                        ? 'Processando Pagamento'
                        : 'Pagar com cartão'}
                      {orderInsertLoading && (
                        <CircularProgress
                          data-cy="icon_loading_payment"
                          size={15}
                          style={{ color: 'white', marginLeft: 10 }}
                        />
                      )}
                    </Button>
                  </Box>
                </Box>
              )}

              {values.payment.type == 3 && (
                <TicketContainer>
                  <Box
                    style={{
                      flexDirection: 'row',
                      borderBottom: `1px solid ${appColors.PRIMARY_COLOR}`,
                      marginTop: 30,
                    }}>
                    <Typography style={styles.billetPrice}>
                      {appUtils.formatPrice(total)}
                    </Typography>
                  </Box>

                  <InputContainer>
                    <InputItem style={{ width: '50%' }}>
                      <Field
                        name="observation"
                        label="Observações: "
                        component={CustomTextArea}
                      />
                    </InputItem>
                  </InputContainer>
                  <Box
                    flex="1"
                    display="flex"
                    alignItems="center"
                    justifyContent="center">
                    <Button
                      data-cy="btn_pay_with_money"
                      onClick={() => setShowAddresModal(true)}
                      style={styles.purpleButton}>
                      <AttachMoney
                        fontSize="large"
                        style={{ marginRight: 20 }}
                      />
                      Pagamento na entrega
                      {orderInsertLoading && (
                        <CircularProgress
                          size={15}
                          style={{ color: 'white', marginLeft: 10 }}
                        />
                      )}
                    </Button>
                  </Box>
                </TicketContainer>
              )}

              {values.payment.type == 2 && (
                <TicketContainer>
                  <Box
                    style={{
                      flexDirection: 'row',
                      borderBottom: `1px solid ${appColors.PRIMARY_COLOR}`,
                      marginTop: 30,
                    }}>
                    <Typography style={styles.billetPrice}>
                      {appUtils.formatPrice(total)}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    style={{
                      flexDirection: 'row',
                      borderBottom: `1px solid ${appColors.PRIMARY_COLOR}`,
                      paddingBottom: 30,
                      marginTop: 30,
                    }}>
                    <Box flex="1" style={{ marginTop: 10, paddingRight: 70 }}>
                      <Typography
                        style={{ fontWeight: 'bold', marginBottom: 30 }}>
                        IMPORTANTE: Confira os dados antes de pagá-lo.
                      </Typography>
                      <Typography style={{ fontWeight: 'bold' }}>
                        Imprima o boleto e pague no banco ou pague pela internet
                        utilizando o código de barras do boleto
                      </Typography>
                    </Box>
                    <Box
                      flex="1"
                      display="flex"
                      alignItems="center"
                      style={{ marginTop: 10 }}>
                      <Typography>
                        Você poderá visualizar ou imprimir após a finalização do
                        pedido. A data de vencimento é de 2 dias corridos após a
                        conclusão do pedido. Após esta data, ele perderá a
                        validade.
                      </Typography>
                    </Box>
                  </Box>
                  <Box style={{ marginTop: 30, textAlign: 'justify' }}>
                    No período de 30 dias, se houver cinco ou mais boletos
                    bancários de um mesmo cliente, não pagos, poderá ocorrer
                    bloqueio temporário desta forma de quitação, por até 30
                    dias. Nesse período, novas compras poderão ser feitas no
                    site por meio de outras formas de pagamento ou com boleto,
                    pelo Televendas. Caso o seu computador tenha um programa
                    anti pop-up, será preciso desativá-lo antes de finalizar sua
                    compra e imprimir o boleto ou pagar pelo internet banking.
                    Não faça depósito ou transferência entre contas. O boleto
                    não é enviado pelos Correios. Imprima-o e pague-o no banco
                    ou pela internet; Se o boleto não for pago até a data de
                    vencimento, o pedido será automaticamente cancelado; O prazo
                    de entrega dos pedidos pagos com boleto bancário começa a
                    contar três dias depois do pagamento do boleto, tempo
                    necessário para que a instituição bancária confirme o
                    pagamento.
                  </Box>
                  <InputContainer>
                    <InputItem style={{ width: '50%' }}>
                      <Field
                        name="observation"
                        label="Observações: "
                        component={CustomTextArea}
                      />
                    </InputItem>
                  </InputContainer>
                  <Box
                    flex="1"
                    display="flex"
                    alignItems="center"
                    justifyContent="center">
                    <Button
                      id="btnPagamento"
                      onClick={() => setShowAddresModal(true)}
                      style={styles.purpleButton}>
                      <FaBarcode fontSize={30} style={{ marginRight: 20 }} />
                      Pagar com boleto
                      {orderInsertLoading && (
                        <CircularProgress
                          size={15}
                          style={{ color: 'white', marginLeft: 10 }}
                        />
                      )}
                    </Button>
                  </Box>
                </TicketContainer>
              )}
            </Box>
            <Dialog
              fullWidth
              open={showAddresModal}
              onClose={() => setShowAddresModal(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">
                Você confirma o pedido?
              </DialogTitle>
              <DialogActions>
                <Button
                  onClick={() => setShowAddresModal(false)}
                  color="primary">
                  Voltar
                </Button>
                <Button
                  data-cy="btn_confirm_address"
                  onClick={() => {
                    setShowAddresModal(false);
                    handleSubmit();
                  }}
                  color="primary"
                  autoFocus>
                  Confirmar compra
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        </Box>
      )}
    />
  );
};

FinalizationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FinalizationForm;
