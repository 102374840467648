import { Button } from '@material-ui/core';
import { shade } from 'polished'
import styled, {css} from 'styled-components';
import appColors from 'utils/appColors';

const ButtonDefault = styled(Button)`
  && {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    background: ${props => props.background ? props.background : appColors.PRIMARY_COLOR};
    padding: 10px;
    color: white;
    
    ${props => props.minHeight && css `
      min-height: ${props.minHeight}px;
    `}

    &:hover {
      background: ${props => shade(0.2, props.background ? props.background : appColors.PRIMARY_COLOR)};
    }
  }
`;

export default ButtonDefault;
