// renovação de senha
export const Types = {
  GET_RECOVERY_REQUEST: 'passwordRecovery/GET_RECOVERY_REQUEST',
  GET_RECOVERY_SUCCESS: 'passwordRecovery/GET_RECOVERY_SUCCESS',
  GET_RECOVERY_FAILURE: 'passwordRecovery/GET_RECOVERY_FAILURE',
};

export const initialState = {

  passwordRecoveryLoading: false,
  passwordRecoveryError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_RECOVERY_REQUEST:
      return {
        ...state,
        passwordRecoveryError: null,
        passwordRecoveryLoading: true,
      };
    case Types.GET_RECOVERY_SUCCESS:
      return {
        ...state,
        passwordRecoveryLoading: false,
        passwordRecoveryError: null,
      };
    case Types.GET_RECOVERY_FAILURE:
      return {
        ...state,
        passwordRecoveryLoading: false,
        passwordRecoveryError: action.payload,
      };
    default:
      return state;
  }
};

export const Creators = {
  getPasswordRecoveryRequest: (password, hash) => ({
    type: Types.GET_RECOVERY_REQUEST,
    payload: { password, hash },

  }),
  getPasswordRecoverySuccess: () => ({
    type: Types.GET_RECOVERY_SUCCESS,
  }),
  getPasswordRecoveryFailure: error => ({
    type: Types.GET_RECOVERY_FAILURE,
    payload: error,
  }),
};
