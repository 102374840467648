/* eslint-disable react/display-name */
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import Sound from 'react-sound';
import sound from 'assets/sound.mp3';
import PropTypes from 'prop-types';

function SoundComponent({ loop }, ref) {
  const [play, setPlay] = useState(false);

  useImperativeHandle(ref, () => ({
    play: () => {
      setPlay(true);
    },
    stop: () => {
      setPlay(false);
    },
  }));

  return play ? (
    <Sound playStatus={Sound.status.PLAYING} url={sound} loop={loop} />
  ) : (
    false
  );
}

SoundComponent.propTypes = {
  loop: PropTypes.string,
};

SoundComponent.defaultProps = {
  loop: true,
};

export default forwardRef(SoundComponent);
