import appColors from 'utils/appColors';

const styles = {
  containerItem:{
    borderTop:`1px solid ${appColors.PRIMARY_COLOR}`,
    padding:20,
  },
  headerPurple:{
    color: appColors.PRIMARY_COLOR
  },
  fontBold:{
    fontWeight:'bold',
  },
  status:{
    fontWeight:'bold',
    color:'white',
  },
  containerStatus:{
    backgroundColor: appColors.PRIMARY_COLOR,
    padding:8,
    borderRadius:5
  },
  iconExpand:{
    color: appColors.PRIMARY_COLOR,
    cursor:'pointer'
  }
}

export default styles;