/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from 'react';
import { Formik, Field, FastField, Form } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Card, Typography } from '@material-ui/core';
import { Tab, Tabs } from 'components/Layout/Tabs';
import { InputContainer, InputItem } from 'components/form/StyledComponents';
import CustomTextField from 'components/form/CustomTextField';
import CustomSelect from 'components/form/CustomSelect';
import FormButtons from 'components/form/components/FormButtons';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as GroupCreators } from 'store/ducks/admin/group';
import { Creators as StoreCreators } from 'store/ducks/admin/stores';
import { Creators as AddressCreators } from 'store/ducks/admin/address';
import { Creators as PanelTabsCreators } from 'store/ducks/panelTabs';
import CustomMaskField from 'components/form/CustomMaskField';
import validators from 'utils/validators';
import CustomImageField from 'components/form/components/CustomImageField';
import { formatCityName } from 'utils/converters';
import { BoxStyled } from 'components/Pages/MyAccountPage/PainelAccount/TabsPanel/ProductsTab/components/ProductForm/styles';
import appColors from 'utils/appColors';
import { appTabs } from 'components/Pages/MyAccountPage/PainelAccount/TabPanelShopkeeper';
import moment from 'moment';

const TabContainer = ({ children }) => {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
};

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export const formInitialValues = {
  id: '',
  email: '',
  password: '',
  password_confirm: '',
  name: '',
  cpf: '',
  genre: '',
  description: '',
  cell_phone: '',
  birth_date: '',
  group: {
    id: '',
  },
  store: {
    id: '',
  },
  address: {
    code_post: '',
    street: '',
    number: '',
    district: '',
    complement: '',
    city: {
      id: '',
    },
  },
  image: '',
  image_data: '',
  image_info: '',
};

const schema = Yup.object().shape({
  email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
  password: Yup.string(),
  password_confirm: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Senhas não iguais'
  ),
  name: Yup.string().required('Campo obrigatório'),
  cpf: Yup.string()
    .test(...validators.cpfInvalid('CPF inválido'))
    .required('Obrigatório'),
  genre: Yup.string(),
  description: Yup.string(),
  cell_phone: Yup.string(),
  birth_date: Yup.mixed()
    .test('valid-date', 'Por Favor, insira uma data válida', val =>
      moment(val, 'DD/MM/YYYY').isValid()
    )
    .test('valid-length', 'Por Favor, insira uma data válida', val => {
      return val ? val.replace(/[/_-]/g, '').length === 8 : false;
    }),
  group: Yup.object().shape({
    id: Yup.string(),
  }),
  store: Yup.object().shape({
    id: Yup.string(),
  }),
  address: Yup.object().shape({
    code_post: Yup.string(),
    street: Yup.string(),
    number: Yup.string().test(...validators.numberNotRequired()),
    district: Yup.string(),
    complement: Yup.string(),
    city: Yup.object().shape({
      id: Yup.string().test(...validators.numberNotRequired()),
    }),
  }),
});

const UserForm = ({
  onSubmit,
  initialValues = formInitialValues,
  submitText,
  //handleBack,
  isLoading,
}) => {
  const newInitialValues = {
    ...initialValues,
    birth_date: moment(initialValues.birth_date, 'YYYY-MM-DD').format(
      'DD/MM/YYYY'
    ),
  };
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const { groupList, groupListLoading } = useSelector(
    state => state.admin.group
  );

  const [cityInfo, setCityInfo] = React.useState({
    cityList: [],
    cityListLoading: true,
  });

  const formikRef = useRef();

  const { cityList, cityListLoading } = cityInfo;

  const { storeList, storeListLoading } = useSelector(
    state => state.admin.store
  );
  const { addressValidate, addressValidateLoading } = useSelector(
    state => state.admin.address
  );

  const [formikForm, setFormikForm] = React.useState({});

  const getInitialData = () => {
    dispatch(GroupCreators.getGroupListRequest({ perPage: 1000 }));
    dispatch(StoreCreators.getStoreListRequest({ perPage: 1000 }));
    if (newInitialValues.address.code_post) {
      dispatch(
        AddressCreators.getAddressValidateRequest({
          code_post: newInitialValues.address.code_post,
        })
      );
    } else {
      setCityInfo({
        cityList: [],
        cityListLoading: false,
      });
    }
  };

  React.useEffect(() => {
    getInitialData();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    setFormikForm(formikRef.current);
  }, [formikRef]);

  React.useEffect(() => {
    if (
      addressValidateLoading === false &&
      formikForm.setFieldValue &&
      addressValidate
    ) {
      formikForm.setFieldValue('address.street', addressValidate.street);
      formikForm.setFieldValue('address.district', addressValidate.district);
      formikForm.setFieldValue('address.city.id', addressValidate.city.id);
      setCityInfo({
        cityList: [
          {
            ...addressValidate.city,
          },
        ],
        cityListLoading: false,
      });
    } else if (
      addressValidateLoading === false &&
      formikForm.setFieldValue &&
      typeof addressValidate === 'undefined'
    ) {
      formikForm.setFieldValue('address.street', '');
      formikForm.setFieldValue('address.district', '');
      formikForm.setFieldValue('address.city.id', '');
      setCityInfo({
        cityList: [],
        cityListLoading: false,
      });
    }
  }, [addressValidate]);

  const onCepChange = event => {
    const fieldValue = event.target.value;
    if (fieldValue.indexOf('_') < 0 && fieldValue.length === 9) {
      setCityInfo({
        cityList: [],
        cityListLoading: true,
      });
      dispatch(
        AddressCreators.getAddressValidateRequest({ code_post: fieldValue })
      );
    }
  };
  const handleBack = () => {
    dispatch(PanelTabsCreators.changeTab(appTabs.storeList));
  };

  return (
    <Formik
      initialValues={newInitialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      validateOnBlur
      ref={formikRef}
      enableReinitialize
      render={({ values }) => (
        <Form>
          <Card style={{ marginTop: 20 }}>
            <InputContainer>
              <InputItem>
                <BoxStyled
                  style={{
                    marginBottom: 20,
                    backgroundColor: appColors.LIGHT_COLOR,
                    borderRadius: 10,
                  }}>
                  <Tabs value={value} onChange={handleChange}>
                    <Tab label="INFORMAÇÕES BÁSICAS" />
                    <Tab label="ENDEREÇO" />
                  </Tabs>
                </BoxStyled>
                {value === 0 && (
                  <TabContainer>
                    <InputItem>
                      <Typography variant="h6">Informações Básicas</Typography>
                    </InputItem>
                    <InputContainer>
                      <InputItem>
                        <FastField
                          name="name"
                          label="Nome"
                          component={CustomTextField}
                        />
                      </InputItem>
                      <InputItem>
                        <Field
                          name="email"
                          label="E-mail"
                          component={CustomTextField}
                        />
                      </InputItem>
                    </InputContainer>
                    <InputContainer>
                      <InputItem>
                        <FastField
                          name="genre"
                          label="Gênero"
                          options={[
                            { id: 'M', name: 'Masculino' },
                            { id: 'F', name: 'Feminino' },
                            { id: 'O', name: 'Outro' },
                          ]}
                          component={CustomSelect}
                          placeholder="Gênero"
                          isLoading={false}
                        />
                      </InputItem>
                      <InputItem>
                        <FastField
                          name="cell_phone"
                          label="Telefone"
                          component={CustomMaskField}
                          mask="(99) 99999-9999"
                        />
                      </InputItem>
                    </InputContainer>
                    <InputContainer>
                      <InputItem>
                        <Field
                          name="birth_date"
                          label="Informe sua data de nascimento"
                          mask={'99/99/9999'}
                          component={CustomMaskField}
                        />
                      </InputItem>
                      <InputItem>
                        <FastField
                          name="cpf"
                          label="CPF"
                          component={CustomMaskField}
                          mask="999.999.999-99"
                        />
                      </InputItem>
                    </InputContainer>
                    <InputContainer>
                      <InputItem>
                        <Field
                          name="store.id"
                          label="Loja"
                          options={storeList}
                          component={CustomSelect}
                          placeholder="Loja"
                          isLoading={storeListLoading}
                        />
                      </InputItem>
                      <InputItem>
                        <Field
                          name="group.id"
                          label="Grupo"
                          options={groupList}
                          component={CustomSelect}
                          placeholder="Grupo"
                          isLoading={groupListLoading}
                        />
                      </InputItem>
                    </InputContainer>
                    <InputItem>
                      <Typography variant="h6">Segurança</Typography>
                    </InputItem>
                    <InputContainer>
                      <InputItem>
                        <FastField
                          name="password"
                          label="Senha"
                          type="password"
                          component={CustomTextField}
                        />
                      </InputItem>
                      <InputItem>
                        <FastField
                          name="password_confirm"
                          label="Confirmação da senha"
                          type="password"
                          component={CustomTextField}
                        />
                      </InputItem>
                    </InputContainer>
                    <InputItem>
                      <FastField
                        name="image"
                        label="Foto de perfil"
                        component={CustomImageField}
                        previewUrl={
                          values.image_info && values.image_info.small
                            ? values.image_info.small
                            : ''
                        }
                      />
                    </InputItem>
                  </TabContainer>
                )}
                {value === 1 && (
                  <TabContainer>
                    <InputContainer>
                      <InputItem>
                        <FastField
                          name="address.code_post"
                          label="CEP"
                          component={CustomMaskField}
                          mask="99999-999"
                          onKeyUp={onCepChange}
                        />
                      </InputItem>
                      <InputItem>
                        <FastField
                          name="address.street"
                          label="Logradouro"
                          component={CustomTextField}
                        />
                      </InputItem>
                    </InputContainer>
                    <InputContainer>
                      <InputItem>
                        <FastField
                          name="address.number"
                          label="Número"
                          type="number"
                          component={CustomTextField}
                        />
                      </InputItem>
                      <InputItem>
                        <FastField
                          name="address.complement"
                          label="Complemento"
                          component={CustomTextField}
                        />
                      </InputItem>
                    </InputContainer>
                    <InputContainer>
                      <InputItem>
                        <FastField
                          name="address.district"
                          label="Bairro"
                          component={CustomTextField}
                        />
                      </InputItem>
                      <InputItem style={{ pointerEvents: 'none' }}>
                        <Field
                          name="address.city.id"
                          label="Cidade"
                          options={formatCityName(cityList)}
                          component={CustomSelect}
                          placeholder="Cidade"
                          isLoading={cityListLoading}
                        />
                      </InputItem>
                    </InputContainer>
                  </TabContainer>
                )}
              </InputItem>
            </InputContainer>
            <FormButtons
              handleBack={handleBack}
              isLoading={isLoading}
              submitText={submitText}
            />
          </Card>
        </Form>
      )}
    />
  );
};

UserForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.oneOfType([PropTypes.object]),
  submitText: PropTypes.string,
  handleBack: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isLoading: PropTypes.bool.isRequired,
};

UserForm.defaultProps = {
  initialValues: formInitialValues,
  submitText: 'Salvar',
  handleBack: false,
  onSubmit: () => {},
};

export default UserForm;
