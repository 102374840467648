/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { Box, Button } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { TextDesk } from './style';
import appColors from 'utils/appColors';

const FooterList = ({
  page,
  total,
  perPage,
  previousPage,
  nextPage,
  handlePage,
}) => {
  const totalPages = Math.ceil(total / perPage);

  return (
    <>
      {totalPages > 1 && (
        <Box
          flex="1"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center">
          <Box>
            {page > 1 && (
              <Button
                style={{ padding: 10, textTransform: 'none', color: '#6d6b6b' }}
                onClick={previousPage}>
                <ArrowBackIosIcon style={{ fontSize: 15 }} />
                <TextDesk>{'Anteriores'}</TextDesk>
              </Button>
            )}
          </Box>
          <Box style={{ maxWidth: 300, overflow: 'hidden' }}>
            <Box display="flex" flexDirection="row" alignItems="center">
              {[...Array(totalPages)].map((_, i) => (
                <Button
                  key={i}
                  onClick={() => handlePage(i + 1)}
                  variant="outlined"
                  style={{
                    borderRadius: 100,
                    width: 40,
                    height: 40,
                    margin: 5,
                    color: page === i + 1 ? 'White' : appColors.PRIMARY_COLOR,
                    backgroundColor:
                      page === i + 1 ? appColors.PRIMARY_COLOR : 'transparent',
                    fontWeight: page === i + 1 ? 'bold' : 'normal',
                    minWidth: 0,
                    borderColor: appColors.PRIMARY_COLOR,
                  }}>
                  {i + 1}
                </Button>
              ))}
            </Box>
          </Box>
          <Box>
            {page < totalPages && (
              <Button
                style={{ padding: 10, textTransform: 'none', color: '#6d6b6b' }}
                onClick={nextPage}>
                <TextDesk>{'Próximas'}</TextDesk>
                <ArrowForwardIosIcon style={{ fontSize: 15 }} />
              </Button>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default FooterList;
