import React from 'react';
import ActionFab from 'components/Actions/ActionFab';
import { useHistory } from 'react-router-dom';
import ActionMenuItem from 'components/Actions/ActionMenuItem';
import ActionMenu from 'components/Actions/ActionMenu';
import { Creators as PanelTabsCreators } from 'store/ducks/panelTabs';
import { useDispatch, useSelector } from 'react-redux';
import { appTabs } from 'components/Pages/MyAccountPage/PainelAccount/TabPanelShopkeeper';
import { DownloadCatalog } from 'components/CreateCatalog';

const VirtualCatalogActions = ({ rowData, onDeleteRequest, setClickedId }) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { virtualCatalogGeneratedLoading, virtualCatalogGenerated } =
    useSelector(state => state.admin.virtualCatalog);

  const [localState, setLocalState] = React.useState({
    anchorEl: null,
  });

  const handleClick = event => {
    setLocalState({ anchorEl: event.currentTarget });
  };

  const handleClose = () => {
    setLocalState({ anchorEl: null });
  };

  const onDelete = () => {
    onDeleteRequest(rowData);
  };

  const navigateToTab = (tab, params = {}) => {
    dispatch(PanelTabsCreators.changeTab(tab, params));
  };

  React.useEffect(() => {
    if (
      virtualCatalogGenerated === rowData.id &&
      !virtualCatalogGeneratedLoading
    ) {
      setClickedId(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [virtualCatalogGenerated, virtualCatalogGeneratedLoading]);

  return (
    <div
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <DownloadCatalog id={rowData.id} />
      <ActionFab
        data-cy="btn_actions_catalog"
        icon="more_vert"
        onClick={handleClick}
      />
      <ActionMenu
        anchorEl={localState.anchorEl}
        onClose={handleClose}
        open={Boolean(localState.anchorEl)}>
        <ActionMenuItem
          data-cy="btn_actions_edit_catalog"
          onClick={() => {
            navigateToTab(appTabs.virtualCatalogUpdate, { id: rowData.id });
            history.push({
              search: `?id_catalogo=` + rowData.id,
            });
          }}>
          Editar
        </ActionMenuItem>
        <ActionMenuItem data-cy="btn_actions_delete_catalog" onClick={onDelete}>
          Remover
        </ActionMenuItem>
      </ActionMenu>
    </div>
  );
};

export default VirtualCatalogActions;
