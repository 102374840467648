/* eslint-disable no-self-assign */
// @flow

export type GroupByStore = {
  [key: string]: ProductInCart[],
};

const groupByStore = (cartList: ProductInCart[]): GroupByStore => {
  return cartList?.reduce((accumulator, product) => {
    const storeId = product.store?.id;
    if (storeId) {
      accumulator[storeId] = [...(accumulator[storeId] || []), product];
    }
    return accumulator;
  }, {});
};

// Retorna o numero de produtos no carrinho de uma unica loja
const getQuantityByStore = group => storeId => {
  return group[storeId]?.reduce((acc, product) => {
    return acc + product.quantity;
  }, 0);
};

// Retona o numero de items minimos para considerar atacado pela loja
const storeQuantityToWhole =
  group =>
  (storeId: Number): Number => {
    return group[storeId][0].store.quantity_min_whole;
  };

// Retorna a quantidade minima para se considerar atacado pelo produto
const productQuantityToWhole = group => (storeId, productId) => {
  return group[storeId].find(product => product.id === productId)
    .quantity_min_whole;
};

type GetValuesForProduct = {
  price: Number,
  price_whole: Number,
  quantity: Number,
  subtotal: Number,
  descontos: Number,
  total: Number,
  ehAtacado: Boolean,
  product: ProductInCart,
  descontoPorProduto: Number,
  valorPorProdutoComDesconto: Number,
  temDesconto: Boolean,
};

const getValuesForProduct =
  (group: GroupByStore, cupomData: ProductInCart[]) =>
  (
    storeId,
    productId,
    products,
    fromFuncApplyDiscount = false
  ): GetValuesForProduct => {
    const product = products;

    // Pega a quantidade de produtos de uma loja, e ve se é atacado por numero de produtos da loja
    // product?.catalog_product_id?.price_discount
    //   ? (product.price = product.catalog_product_id.price)
    //   : (product.price = product.price);
    const isStoreWhole =
      getQuantityByStore(group)(storeId) >=
        storeQuantityToWhole(group)(storeId) &&
      product?.store?.quantity_min_whole > 0;
    const isProductWhole =
      product?.quantity >= productQuantityToWhole(group)(storeId, productId) &&
      product?.quantity_min_whole > 0;
    // Verifica se ha desconto de atacado da loja ou do produto
    const ehAtacado = isStoreWhole || isProductWhole;

    const subtotal = products?.variation
      ? products?.variation.price * product?.quantity
      : product?.price * product?.quantity;
    const descontos = products?.variation
      ? ehAtacado
        ? subtotal - products?.variation.price_whole * product?.quantity
        : 0
      : ehAtacado
      ? subtotal - product?.price_whole * product?.quantity
      : 0;
    const descontoPorProduto = products?.variation
      ? ehAtacado
        ? products?.variation.price - products?.variation.price_whole
        : 0
      : ehAtacado
      ? product?.price - product?.price_whole
      : 0;

    const searchForDiscount = cupomData.find(x => x.id === productId);
    const temDesconto = searchForDiscount && searchForDiscount.discount;

    if (temDesconto && !fromFuncApplyDiscount) {
      return applyDiscountPrice(group, cupomData)(
        storeId,
        productId,
        products,
        searchForDiscount.discount
      );
    }

    return {
      product,
      price: products?.variation ? products?.variation.price : product?.price,
      price_whole: products?.variation
        ? products?.variation.price_whole
        : product?.price_whole,
      quantity: product?.quantity,
      subtotal,
      descontos,
      total: subtotal - descontos,
      ehAtacado,
      temDesconto: ehAtacado,
      ehCupom: false,
      descontoPorProduto,
      valorPorProdutoComDesconto: products?.variation
        ? products?.variation.price - descontoPorProduto
        : product?.price - descontoPorProduto,
    };
  };

const applyDiscountPrice =
  (group, cupomData) =>
  (storeId, productId, products, discount = 0) => {
    const currentValues = getValuesForProduct(group, cupomData)(
      storeId,
      productId,
      products,
      true
    );
    if (!discount || discount === 0) {
      return { ...currentValues, ehCupom: false };
    }
    const descontoPorProduto = currentValues.descontoPorProduto + discount;
    const descontos = descontoPorProduto * currentValues.quantity;
    const response = {
      ...currentValues,
      descontos: descontos,
      total: currentValues.subtotal - descontos,
      valorDoProdutoComDesconto: currentValues.price - discount,
      ehAtacado: currentValues.ehAtacado,
      ehCupom: true,
      temDesconto: true,
      descontoPorProduto,
      valorPorProdutoComDesconto: currentValues.price - descontoPorProduto,
    };
    return response;
  };

type GetValuesOfAllProducts = {
  subtotal: Number,
  descontos: Number,
  total: Number,
};

const getValuesOfAllProducts =
  (group: GroupByStore, cupomData: ProductInCart[]) =>
  (): GetValuesOfAllProducts => {
    return Object.values(group)?.reduce(
      (accStores, stores) => {
        const storeProd = stores?.reduce(
          (acc, product) => {
            const values = getValuesForProduct(group, cupomData)(
              product.store.id,
              product.id,
              product
            );
            return {
              subtotal: acc.subtotal + values.subtotal,
              descontos: acc.descontos + values.descontos,
              total: acc.total + values.total,
            };
          },
          {
            subtotal: 0,
            descontos: 0,
            total: 0,
          }
        );
        return {
          subtotal: accStores.subtotal + storeProd.subtotal,
          descontos: accStores.descontos + storeProd.descontos,
          total: accStores.total + storeProd.total,
        };
      },
      {
        subtotal: 0,
        descontos: 0,
        total: 0,
      }
    );
  };

export type ProccessCartReturn = {
  productsAgruppedByStore: GroupByStore,
  getValuesOfAllProducts: () => GetValuesOfAllProducts,
  storeQuantityToWhole: Number,
  getValuesForProduct: (
    storeId: Number,
    productId: Number,
    products: Array,
    fromFuncApplyDiscount?: boolean
  ) => GetValuesForProduct,
};

const proccessCart = (
  cartList: ProductInCart[],
  cupomData: ProductInCart[] = []
): ProccessCartReturn => {
  const group = groupByStore(cartList);
  return {
    productsAgruppedByStore: group,
    getQuantityByStore: getQuantityByStore(group),
    getValuesOfAllProducts: getValuesOfAllProducts(group, cupomData),
    storeQuantityToWhole: storeQuantityToWhole(group),
    productQuantityToWhole: productQuantityToWhole(group),
    getValuesForProduct: getValuesForProduct(group, cupomData),
  };
};

const checkoutUtils = {
  proccessCart,
};

export default checkoutUtils;
