// renovação de senha
export const Types = {
  GET_VALIDATE_REQUEST: 'validate/GET_VALIDATE_REQUEST',
  GET_VALIDATE_SUCCESS: 'validate/GET_VALIDATE_SUCCESS',
  GET_VALIDATE_FAILURE: 'validate/GET_VALIDATE_FAILURE',
};

export const initialState = {
  hash: null,
  validateLoading: false,
  validateError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_VALIDATE_REQUEST:
      return {
        ...state,
        validateError: null,
        validateLoading: true,
      };
    case Types.GET_VALIDATE_SUCCESS:
      return {
        ...state,
        hash: action.payload,
        validateLoading: false,
        validateError: null,
      };
    case Types.GET_VALIDATE_FAILURE:
      return {
        ...state,
        validateLoading: false,
        validateError: action.payload,
      };
    default:
      return state;
  }
};

export const Creators = {
  getValidateRequest: hash => ({
    type: Types.GET_VALIDATE_REQUEST,
    payload: { hash },
  }),
  getValidateSuccess: ({ data }) => ({
    type: Types.GET_VALIDATE_SUCCESS,
    payload: data.hash,
  }),
  getValidateFailure: error => ({
    type: Types.GET_VALIDATE_FAILURE,
    payload: error,
  }),
};
