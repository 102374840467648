import React from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styles, { BoxButtons, BoxTags, BoxMoreInfo } from './styles';
import { Link } from 'react-router-dom';
import { Creators as NotificationCreators } from 'store/ducks/app';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import appConfig from 'utils/appConfig';
import appColors from 'utils/appColors';

const MoreInfoProduct = ({ data }) => {
  const dispatch = useDispatch();

  const handleCopy = () => {
    dispatch(
      NotificationCreators.openNotification({
        message: 'Link copiado para a área de transferência',
        type: 'success',
      })
    );
  };

  return (
    <BoxMoreInfo flex="1">
      <BoxButtons
        flex="1"
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        flexWrap="wrap">
        <CopyToClipboard
          text={`${appConfig.SITE_WEB}/produto/${data.id}/${data.slug}`}
          onCopy={handleCopy}>
          <Button
            data-cy="btn_share_product_link"
            style={{
              width: '100%',
              border: `1px solid ${appColors.PRIMARY_COLOR}`,
              color: appColors.PRIMARY_COLOR,
              marginTop: 30,
              backgroundColor: 'transparent',
            }}>
            Compartilhar
          </Button>
        </CopyToClipboard>
      </BoxButtons>
      <BoxTags
        display="flex"
        flex="1"
        flexDirection="row"
        flexWrap="wrap"
        style={{
          marginTop: 30,
          paddingTop: 30,
          borderTop: '1px solid #9E9E9E',
        }}>
        <Box flex="1" display="flex" flexDirection="column">
          <Typography style={styles.smallTextPurple}>Categorias</Typography>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            flexWrap="wrap"
            flex="1"
            style={{ marginRight: -10, marginBottom: 20, minWidth: 300 }}>
            {data.categories.map(item => (
              <Box
                style={styles.miniBox}
                key={Math.random() * (data.categories.length * 10)}>
                <Link
                  style={{ textDecoration: 'none' }}
                  to={`/categoria/${item.name}`}>
                  <Typography style={styles.titleMiniBox}>
                    {item.name}
                  </Typography>
                </Link>
              </Box>
            ))}
          </Box>
        </Box>
      </BoxTags>
    </BoxMoreInfo>
  );
};

MoreInfoProduct.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MoreInfoProduct;
