import React from 'react';
import {
  Box,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import { InputContainer, InputItem } from 'components/form/StyledComponents';
import CustomTextField from 'components/form/CustomTextField';
import * as Yup from 'yup';
import validators from 'utils/validators';
import CustomMaskField from 'components/form/CustomMaskField';
import appColors from 'utils/appColors';
import CepMaskField from './CepMaskField';
import styles from './styles';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as CepCreators } from 'store/ducks/address';
import appNaming from 'utils/appNaming';
import CustomSelect from 'components/form/CustomSelect';

const RegisterResellerForm = ({
  onSubmit,
  initialValues,
  onSubmitCEP,
  onBack,
}) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(CepCreators.getClearCepRequest());
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateCEP = value => {
    let error;
    if (value.replace(/[()_-]/g, '').replace(/ /g, '').length < 8) {
      error = 'CEP inválido';
    }
    return error;
  };

  const { cep, cepError, cepLoading } = useSelector(state => state.address);
  const { resellerInsertLoading } = useSelector(state => state.reseller);

  const formikRef = React.useRef(null);
  const setFieldValue = (name, value) => {
    formikRef.current.setFieldValue(name, value);
  };

  React.useEffect(() => {
    if (Object.keys(cep).length > 0) {
      setFieldValue('address.code_post', cep.code_post);
      setFieldValue('address.street', cep.street);
      setFieldValue('address.number', cep.number);
      setFieldValue('address.district', cep.district);
      setFieldValue('address.complement', cep.complement);
      setFieldValue('address.city.name', cep.city.name);
      setFieldValue('address.city.id', cep.city.id);
      setFieldValue('address.city.state.id', cep.city.state.id);
      setFieldValue('address.city.state.name', cep.city.state.name);
      dispatch(CepCreators.getClearCepRequest());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cep]);

  const schema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Nome inválido')
      .required('Este campo é obrigatório'),
    social_name: Yup.string()
      .min(3, 'Razão social inválida')
      .required('Este campo é obrigatório'),
    cnpj: Yup.string()
      .test(...validators.cnpjInvalid('CNPJ inválido'))
      .required('Este campo é obrigatório'),
    cell_phone: Yup.string().required('Este campo é obrigatório'),
    company_type: Yup.string().required('Escolha o Tipo da empresa'),
    email: Yup.string()
      .email('Este email não é valido')
      .required('Este campo é obrigatório'),
    accept_terms: Yup.mixed().required(
      'É necessário aceitar os termos para continuar.'
    ),
    address: Yup.object().shape({
      code_post: Yup.string().required('Informe o CEP'),
      street: Yup.string().required('Infome a Rua ou Avenida'),
      number: Yup.string().required('Informe o numero do local'),
      district: Yup.string().required('Informe o bairro'),
      complement: Yup.string(),
    }),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      ref={formikRef}
      validateOnBlur
      onSubmit={onSubmit}
      render={({ handleSubmit, setFieldValue, errors, values }) => (
        <Box flex="1" display="flex" flexDirection="column">
          <Form onSubmit={handleSubmit}>
            <InputContainer style={{ marginBottom: 35 }}>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Nome da Empresa <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="name"
                  id="nomeEmpresa"
                  placeholder="Informe o nome da empresa"
                  component={CustomTextField}
                />
              </InputItem>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Razão Social <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="social_name"
                  id="razaoSocial"
                  placeholder="Informe a razão social da empresa"
                  component={CustomTextField}
                />
              </InputItem>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Tipo da Empresa <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="company_type"
                  options={[
                    { id: 'MEI', name: 'Micro Empreendedor Individual' },
                    { id: 'LIMITED', name: 'Empresa Limitada' },
                    { id: 'INDIVIDUAL', name: 'Empresa Individual' },
                    { id: 'ASSOCIATION', name: 'Associação' },
                  ]}
                  placeholder="Informe o tipo da Compania"
                  component={CustomSelect}
                  isLoading={false}
                />
              </InputItem>
            </InputContainer>
            <InputContainer>
              <InputItem>
                <Typography style={styles.titleInput}>
                  CNPJ <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="cnpj"
                  placeholder="Informe o CNPJ da empresa"
                  mask="99.999.999/9999-99"
                  component={CustomMaskField}
                />
              </InputItem>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Telefone <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="cell_phone"
                  placeholder="Informe o telefone da empresa"
                  mask={
                    values.cell_phone?.[5] === 9
                      ? '(99) 9 9999-9999'
                      : '(99) 9999-9999'
                  }
                  component={CustomMaskField}
                />
              </InputItem>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Email <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="email"
                  id="emailEmpresa"
                  placeholder="Informe seu email. Ex: nomedaempresa@email.com"
                  component={CustomTextField}
                />
              </InputItem>
            </InputContainer>
            <Box
              style={{
                borderBottom: '1px solid #FE37A2',
                marginTop: 60,
                marginBottom: 40,
              }}
            />
            <Typography style={styles.purpleTitle}>
              Endereço da empresa
            </Typography>
            {cepError && (
              <Box
                style={{
                  paddingTop: 5,
                  marginLeft: 10,
                  marginTop: 10,
                  color: 'red',
                }}>
                <Typography>{cepError}</Typography>
              </Box>
            )}
            <InputContainer>
              <InputItem>
                <Typography style={styles.titleInput}>
                  CEP <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="address.code_post"
                  placeholder="Informe o CEP da empresa"
                  mask="99999-999"
                  validate={validateCEP}
                  onSubmitCEP={onSubmitCEP}
                  component={CepMaskField}
                />
              </InputItem>
            </InputContainer>
            {((Object.keys(cep).length > 0 && !cepLoading) ||
              values.address.city.id !== '') && (
              <>
                <Box>
                  <InputContainer>
                    <InputItem style={{ flexGrow: 10 }}>
                      <Typography>Rua/Avenida</Typography>
                      <Field
                        name="address.street"
                        id="rua"
                        placeholder="Infome o nome da rua ou avenida"
                        component={CustomTextField}
                      />
                    </InputItem>
                  </InputContainer>
                  <InputContainer>
                    <InputItem>
                      <Typography>Número</Typography>
                      <Field
                        name="address.number"
                        id="numero"
                        placeholder="Infome o número do local"
                        component={CustomTextField}
                      />
                    </InputItem>
                    <InputItem>
                      <Typography>Bairro</Typography>
                      <Field
                        name="address.district"
                        id="bairro"
                        placeholder="Informe o bairro"
                        component={CustomTextField}
                      />
                    </InputItem>
                  </InputContainer>
                  <InputContainer>
                    <InputItem>
                      <Typography>Cidade</Typography>
                      <Field
                        disabled
                        name="address.city.name"
                        id="cidade"
                        placeholder="Infome o número do local"
                        component={CustomTextField}
                      />
                    </InputItem>
                    <InputItem>
                      <Typography>Estado</Typography>
                      <Field
                        disabled
                        name="address.city.state.name"
                        id="estado"
                        placeholder="Infome o número do local"
                        component={CustomTextField}
                      />
                    </InputItem>
                  </InputContainer>
                  <InputContainer>
                    <InputItem>
                      <Typography>Complemento</Typography>
                      <Field
                        name="address.complement"
                        id="complemento"
                        placeholder="Adicione um complemento (Opcional)"
                        component={CustomTextField}
                      />
                    </InputItem>
                  </InputContainer>
                </Box>
              </>
            )}
            {cepLoading && (
              <Box
                flex="1"
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ marginTop: 10 }}>
                <CircularProgress style={{ color: appColors.PRIMARY_COLOR }} />
              </Box>
            )}
            <Box
              flex="1"
              style={{ marginBottom: 40, maxWidth: 600, marginTop: 20 }}>
              <Typography style={styles.titleInput}>
                Políticas e Termos <span style={{ color: 'red' }}>*</span>
              </Typography>
              <FormControlLabel
                style={{ alignItems: 'flex-start', marginTop: 10 }}
                control={
                  <Checkbox
                    checked={!!values.accept_terms}
                    onChange={() => {
                      if (values.accept_terms) {
                        setFieldValue('accept_terms', null);
                        return;
                      }
                      setFieldValue('accept_terms', true);
                    }}
                    data-cy="check_accept_terms"
                    style={{ color: appColors.PRIMARY_COLOR, paddingTop: 0 }}
                  />
                }
                label={
                  <Typography style={{ color: '#444' }}>
                    {`Aceito os `}
                    <a
                      style={{
                        fontWeight: 'bold',
                        color: appColors.PRIMARY_COLOR,
                      }}
                      href="/pagina/termos-de-uso?partner=true&terms=true"
                      target="_blank">
                      Termos e condições
                    </a>
                    {` e autorizo o uso dos meus dados de acordo com a `}
                    <a
                      style={{
                        fontWeight: 'bold',
                        color: appColors.PRIMARY_COLOR,
                      }}
                      href="/pagina/termos-de-uso?partner=true&policy=true"
                      target="_blank">
                      Política de privacidade
                    </a>
                    {` do ${appNaming.REGULAR_NAMING}.`}
                  </Typography>
                }
              />
              {typeof errors.accept_terms !== 'undefined' && (
                <Typography style={{ color: 'red', marginTop: 10 }}>
                  {errors.accept_terms}
                </Typography>
              )}
            </Box>
            <Box style={{ padding: 10 }}>
              <InputItem
                style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  onClick={() => {
                    onBack(0);
                  }}
                  id="btnVoltar"
                  style={styles.purpleButton}>
                  Voltar
                </Button>
                <Button
                  disabled={resellerInsertLoading}
                  type="submit"
                  id="btnProsseguir"
                  style={styles.purpleButton}>
                  {resellerInsertLoading && (
                    <CircularProgress
                      size={15}
                      style={{ color: 'white', marginLeft: 10 }}
                    />
                  )}
                  Prosseguir
                </Button>
              </InputItem>
            </Box>
          </Form>
        </Box>
      )}
    />
  );
};

export default RegisterResellerForm;
