import React from 'react';
import { Box, Typography } from '@material-ui/core';
import styles, { LinkSimulator, BoxLoginContainer } from './styles';
import LoginForm from '../LoginForm';
import PropTypes from 'prop-types';

const BoxLogin = ({ onSubmit, openModal }) => {
  return (
    <>
      <BoxLoginContainer flex="1" display="flex" flexDirection="column">
        <Box>
          <Box
            flex="1"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center">
            <Typography style={styles.purpleTitle}>
              Já tenho uma conta
            </Typography>
          </Box>
          <Box flex="1">
            <LoginForm onSubmit={onSubmit} openModal={openModal} />
          </Box>
          <Box>
            <Typography onClick={openModal}>
              <LinkSimulator>Esqueci minha senha</LinkSimulator>
            </Typography>
          </Box>
        </Box>
      </BoxLoginContainer>
    </>
  );
};

BoxLogin.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
};

export default BoxLogin;
