import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import notImage from 'images/Products/not_image.jpg';
import {
  styles,
  Title,
  Image,
  StoreImage,
  StoreCover,
  Text,
  View,
  GridView,
  CoverView,
  TitlePage,
} from './styles';

import useCatalog from 'hooks/useCatalogs';
import { useParams } from 'react-router';
import appUtils from 'utils/appUtils';
import { Link } from 'react-router-dom';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { Button } from '@material-ui/core';
import { getDefaultAPI } from 'services/api';

function CatalogPage() {
  const { id } = useParams();
  const { getCatalog, catalog } = useCatalog();

  useEffect(() => {
    getCatalog(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (catalog === null) {
    return <div />;
  }

  return (
    <Grid
      container
      alignContent="center"
      justify="center"
      style={{ marginTop: 20 }}>
      <CoverView item>
        <Paper style={{ marginRight: 20 }}>
          <StoreCover>
            <Image src={catalog.image.medium || notImage} />
            <StoreImage src={catalog.store.image.medium} />
            <Title>{catalog.store.name}</Title>
          </StoreCover>
        </Paper>
      </CoverView>

      <Grid item xs>
        <TitlePage style={{ fontSize: 36, marginBottom: 14, fontWeight: 300 }}>
          Catálogo
        </TitlePage>
        <GridView>
          {catalog.products.map((item, idx) => {
            return (
              <div
                style={{ marginRight: 25, marginBottom: 40, width: 300 }}
                key={idx}>
                <View>
                  {item.images.length > 0 ? (
                    <Image
                      style={{ height: 300 }}
                      src={item.images[0].sizes.medium}
                    />
                  ) : (
                    <Image src={notImage} />
                  )}
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignContent: 'center',
                      width: 300,
                    }}>
                    <Link
                      style={{
                        color: '#222',
                        textDecoration: 'none',
                        textAlign: 'left',
                        fontSize: 22,
                        fontWeight: 'bold',
                      }}
                      onClick={() =>
                        window.open(`${getDefaultAPI}/produto/${item.slug}`)
                      }>
                      {item.name.length > 25
                        ? item.name.slice(0, 25) + '...'
                        : item.name}
                    </Link>
                    <Text
                      style={{
                        height: 50,
                        margin: '5px 0',
                        color: '#777',
                      }}>
                      {item.description.length > 60
                        ? item.description.slice(0, 60) + '...'
                        : item.description}
                    </Text>
                    <View style={{ margin: '10px 0' }}>
                      {item.new_price ? (
                        <View
                          style={{
                            flexDirection: 'row',
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                          <View>
                            <Text
                              style={{
                                marginRight: 10,
                                color: '#777',
                                textDecoration: 'line-through',
                              }}>
                              {appUtils.formatPrice(item.price)}
                            </Text>
                          </View>
                          <View>
                            <Text
                              style={{
                                fontWeight: 'bold',
                                fontSize: 26,
                              }}>
                              {appUtils.formatPrice(item.new_price)}
                            </Text>
                          </View>
                        </View>
                      ) : (
                        <View>
                          <Text
                            style={{
                              fontWeight: 'bold',
                              fontSize: 26,
                            }}>
                            {appUtils.formatPrice(item.price)}
                          </Text>
                        </View>
                      )}
                    </View>

                    <View
                      style={{
                        display: 'flex',
                        flex: 1,
                        width: '100%',
                      }}>
                      <Button
                        style={styles.button_pay}
                        onClick={() =>
                          window.open(`${getDefaultAPI}/produto/${item.slug}`)
                        }>
                        <ShoppingCartIcon
                          style={{
                            marginRight: 8,
                            color: 'white',
                          }}
                        />
                        COMPRAR
                      </Button>
                    </View>
                  </View>
                </View>
              </div>
            );
          })}
        </GridView>
      </Grid>
    </Grid>
  );
}

export default CatalogPage;
