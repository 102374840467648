/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as OrderCreators } from 'store/ducks/admin/order';

function RefreshOrder({ id, stores }) {
  const dispatch = useDispatch();
  const { orderLoading, orderId } = useSelector(state => state.admin.order);
  var timer;
  const getUnicOrder = () => {
    dispatch(OrderCreators.getOrderRequest({ id }));
  };

  useEffect(() => {
    // se todos os status for igual a 6 (enviado), ele faz o refresh
    if (stores.every(x => x.status === 6)) {
      timer = setInterval(() => {
        getUnicOrder();
      }, 60 * 1000 * 2);
    }
    return () => clearInterval(timer);
  }, []);

  return (
    <div>
      {orderLoading && orderId.id === id ? (
        <CircularProgress size={24} />
      ) : (
        <a onClick={getUnicOrder} style={{ fontSize: 25, cursor: 'pointer' }}>
          ↻
        </a>
      )}
    </div>
  );
}

export default RefreshOrder;
