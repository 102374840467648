import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import PropTypes from 'prop-types';
import appColors from 'utils/appColors';
import { autoPlay } from 'react-swipeable-views-utils';
import styles, { CardCarrossel, CarrosselContainer } from './styles';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Carrossel = ({ images }) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [totalLoad, setTotalLoad] = React.useState(0);
  const [isLoad, setIsLoad] = React.useState(false);
  console.log(isLoad);
  React.useEffect(() => {
    if (totalLoad === images.length && images.length > 0) {
      setIsLoad(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalLoad]);

  const maxSteps = images.length;

  function handleNext() {
    if (activeStep < maxSteps - 1) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  }

  function handleBack() {
    if (activeStep > 0) {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    }
  }

  function handleStepChange(step) {
    setActiveStep(step);
  }

  return (
    <CarrosselContainer>
      <div
        style={{
          position: 'absolute',
          zIndex: 10,
          left: 0,
          height: '100%',
          padding: 10,
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          outlineWidth: 0,
        }}
        onClick={handleBack}>
        <KeyboardArrowLeftIcon
          fontSize="large"
          style={{ color: appColors.SECUNDARY_COLOR }}
        />
      </div>
      <div
        style={{
          position: 'absolute',
          zIndex: 10,
          right: 0,
          padding: 10,
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={handleNext}>
        <KeyboardArrowRightIcon
          fontSize="large"
          style={{ color: appColors.SECUNDARY_COLOR }}
        />
      </div>
      <AutoPlaySwipeableViews
        interval={8000}
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents>
        {images.map((step, index) => (
          <div key={step.id}>
            {Math.abs(activeStep - index) <= 2 ? (
              <>
                {step.link !== null && (
                  <a href={step.link}>
                    <CardCarrossel key={step.label} style={styles.img}>
                      <img
                        alt=""
                        style={{ width: '100%', objectFit: 'cover' }}
                        onLoad={() => {
                          setTotalLoad(totalLoad + 1);
                        }}
                        src={step.sizes.original}
                      />
                    </CardCarrossel>
                  </a>
                )}
                {step.link === null && (
                  <CardCarrossel
                    key={step.label}
                    style={styles.img}
                    source={step.sizes.original}
                  />
                )}
              </>
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
    </CarrosselContainer>
  );
};

Carrossel.propTypes = {
  images: PropTypes.array.isRequired,
};

export default Carrossel;
