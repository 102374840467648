import { useAuth } from 'hooks/auth';
import { useState } from 'react';
import { useToast } from 'hooks/toast';

import api from 'services/api';

export const useWebhookEdit = () => {
  const { callApi } = useAuth();
  const { addToast } = useToast();

  const [webhookStatus, setWebhookStatus] = useState([]);
  const [ReRegisterwebhookStatus, setReRegisterwebhookStatus] = useState([]);
  const [webhookExtrato, setwebhookExtrato] = useState();
  const [webhookExtratoSummary, setWebhookExtratoSummary] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingExtrato, setLoadingExtrato] = useState(false);
  const [extratoPages, setExtratoPages] = useState();

  const getwebhookExtrato = async localState => {
    try {
      setLoadingExtrato(true);
      console.log(localState);

      const { page, perPage, dateStart, dateEnd } = localState;
      const response = await api.get(
        `/v1/admin/asaas/extract?page=${page}&perPage=${perPage}&date_start=${dateStart}&date_end=${dateEnd}`
      );
      setwebhookExtrato(response.data.data);
      setWebhookExtratoSummary(response.data.summary);
      setExtratoPages(response.data.pagination.total_items);
      setLoadingExtrato(false);
    } catch (err) {
      setLoadingExtrato(false);
    }
  };
  const getwebhookStatus = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/v1/admin/asaas/webhook`);
      setWebhookStatus(response.data.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const ReRegisterwebhook = async () => {
    try {
      setLoading(true);

      const response = await callApi(
        api.post,
        '/v1/admin/asaas/webhook/register',
        {}
      );

      setReRegisterwebhookStatus(response.data.data);
      if (response.status === 201) {
        addToast({
          type: 'success',
          title: 'Dados atualizados',
          description: '',
        });
        window.location.reload();
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return {
    webhookStatus,
    loading,
    getwebhookStatus,
    ReRegisterwebhook,
    ReRegisterwebhookStatus,
    getwebhookExtrato,
    webhookExtrato,
    webhookExtratoSummary,
    loadingExtrato,
    extratoPages,
  };
};
