import React from 'react';
import { Grid } from '@material-ui/core';
import StatisticItem from 'components/StatisticItem';
import PropTypes from 'prop-types';
import { toPrice } from 'utils/converters';

const SaleInfo = ({ wallet, walletLoading }) => (
  <Grid style={{ marginTop: 30, marginBottom: 30 }} container>
    {!walletLoading && wallet && wallet.cash && (
      <>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <StatisticItem
            icon="attach_money"
            title="Em Conta"
            description={`R$ ${toPrice(
              wallet.cash.current ? wallet.cash.current : 0
            )}`}
          />
        </Grid>
      </>
    )}
  </Grid>
);

SaleInfo.propTypes = {
  wallet: PropTypes.oneOfType([PropTypes.object]).isRequired,
  walletLoading: PropTypes.bool.isRequired,
};

export default SaleInfo;
