import React from 'react';
import PropTypes from 'prop-types';

const dropdownStates = {
  closed: 'closed',
  opened: 'opened',
  openingStart: 'openingStart',
  opening: 'opening',
  closingStart: 'closingStart',
  closing: 'closing',
};

const styles = (divHeight = 0) => ({
  closed: {
    display: 'none',
  },
  openingStart: {
    display: 'block',
    height: 0,
    overflow: 'hidden',
    transition: 'all 0.5s ease',
    opacity: '0.0',
  },
  opening: {
    display: 'block',
    height: divHeight,
    overflow: 'hidden',
    transition: 'all 0.5s ease',
    opacity: '1.0',
  },
  closingStart: {
    display: 'block',
    height: divHeight,
    overflow: 'hidden',
    transition: 'all 0.5s ease',
    opacity: '1.0',
  },
  closing: {
    display: 'block',
    height: 0,
    overflow: 'hidden',
    transition: 'all 0.5s ease',
    opacity: '0.0',
  },
  opened: {
    display: 'block',
  },
});

const Dropdown = ({ dropdownState, style, children }) => {
  const [localDropdownState, setLocalDropdownState] = React.useState(
    dropdownStates.closed
  );
  const [divHeight, setDivHeight] = React.useState(0);

  const divRef = React.useRef();
  if (divRef && divRef.current) {
    const currentDivHeight = divRef.current.clientHeight;
    if (divHeight === 0 && currentDivHeight > 0) {
      setDivHeight(currentDivHeight);
    }
  }

  const dropDownStyles = { ...style, ...styles(divHeight)[localDropdownState] };

  React.useEffect(() => {
    if (dropdownState === dropdownStates.closed) {
      setLocalDropdownState(dropdownStates.opened);
      setTimeout(() => {
        setLocalDropdownState(dropdownStates.closed);
      }, 20);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (divHeight > 0) {
      if (
        (localDropdownState === dropdownStates.closed ||
          localDropdownState === dropdownStates.closingStart ||
          localDropdownState === dropdownStates.closing) &&
        dropdownState === dropdownStates.opened
      ) {
        setLocalDropdownState(dropdownStates.openingStart);
        setTimeout(() => {
          setLocalDropdownState(dropdownStates.opening);
          setTimeout(() => {
            setLocalDropdownState(dropdownStates.opened);
          }, 510);
        }, 10);
      }
      if (
        (localDropdownState === dropdownStates.opened ||
          localDropdownState === dropdownStates.openingStart ||
          localDropdownState === dropdownStates.opening) &&
        dropdownState === dropdownStates.closed
      ) {
        setLocalDropdownState(dropdownStates.closingStart);
        setTimeout(() => {
          setLocalDropdownState(dropdownStates.closing);
          setTimeout(() => {
            setLocalDropdownState(dropdownStates.closed);
          }, 510);
        }, 10);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownState]);

  return (
    <div style={dropDownStyles} ref={divRef}>
      {children}
    </div>
  );
};

Dropdown.propTypes = {
  dropdownState: PropTypes.string.isRequired,
  children: PropTypes.oneOf([PropTypes.any]).isRequired,
  style: PropTypes.oneOf([PropTypes.object]),
};

Dropdown.defaultProps = {
  style: {},
};

export default Dropdown;
