import React from 'react';
import PageBase from 'components/PageBase';
import DefaultTable from 'components/Tables/DefaultTable';
import { useSelector } from 'react-redux';
import HeaderComponent from 'components/HeaderComponent';
import { Paper } from '@material-ui/core';

import appColors from 'utils/appColors';
import CustomButton from 'components/form/CustomButton';
import appUtils from 'utils/appUtils';
import appConfig from 'utils/appConfig';

import { useRecomendations } from 'hooks/recomendations';

const RecommendationPageReseller = () => {
  const {
    copyToClipboard,
    recomentation,
    localState,
    getRecomendations,
    getFunction,
  } = useRecomendations();

  const columns = () => [
    {
      title: 'Nome do usuário',
      field: 'name',
    },
    {
      title: 'Telefone',
      field: 'cellphone',
    },
    {
      title: 'E-mail',
      field: 'email',
    },
    {
      title: 'Valor de vendas',
      field: 'sold_amount',
      // eslint-disable-next-line react/display-name
      render: rowData => (
        <span>{appUtils.formatPrice(rowData.sold_amount)}</span>
      ),
    },
  ];

  const { orderListLoading } = useSelector(state => state.admin.order);
  const { dashboard } = useSelector(state => state.admin.dashboard);
  const code = `https://${appConfig.SITE_WEB}/seja-um-parceiro/${dashboard.code_promo}`;

  React.useEffect(() => {
    getRecomendations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState]);

  return (
    <PageBase>
      <HeaderComponent title="Indicações" style={{ marginBottom: '30px' }}>
        <CustomButton
          label=" Copiar Link de Indicação"
          onClick={() => copyToClipboard(code)}
          style={{
            backgroundColor: appColors.PRIMARY_COLOR,
            color: 'white',
            padding: 10,
            fontWeight: '500',
          }}
        />
      </HeaderComponent>
      <Paper>
        <DefaultTable
          getFunction={getFunction}
          columns={columns()}
          data={recomentation.linked_resellers}
          total={recomentation.pagination.total || 0}
          isLoading={orderListLoading}
          page={localState.page}
          perPage={localState.per_page}
        />
      </Paper>
    </PageBase>
  );
};

export default RecommendationPageReseller;
