import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import appUtils from 'utils/appUtils';
import notImage from 'images/Products/not_image.jpg';
import styles, { TextFlex, NoMobile, OnlyModile } from './styles';

const ProductItem = ({ data }) => {
  return (
    <Box display="flex" flexDirection="column" flex="1">
      {data.products.length > 0 && (
        <>
          {data.products.map(item => (
            <Box
              display="flex"
              flex="1"
              flexDirection="row"
              flexWrap="wrap"
              alignItems="center"
              key={item.product.id}
              style={{ marginTop: 20 }}>
              <Box
                style={{
                  width: 70,
                  height: 100,
                  overflow: 'hidden',
                  marginRight: 10,
                }}>
                <img
                  alt=""
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  src={
                    item.product.images.length > 0
                      ? item.product.images[0].sizes.small
                      : notImage
                  }
                />
              </Box>
              <Box
                flex="1"
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="space-between">
                <Box
                  data-cy="name_product_details_order"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  style={{ minWidth: 120 }}>
                  <Typography>{item.product.name}</Typography>
                </Box>
                <NoMobile>
                  <TextFlex>
                    <span
                      data-cy="variation_product_details_order"
                      style={styles.purpleBold}>
                      Variação:
                    </span>
                    {item.product.variations.length > 0
                      ? item.product.variations[0].color.name
                      : 'Única'}
                  </TextFlex>
                </NoMobile>
                <NoMobile>
                  <TextFlex>
                    <span
                      data-cy="size_product_details_order"
                      style={styles.purpleBold}>
                      Tamanho:
                    </span>
                    {item.product.variations.length > 0
                      ? item.product.variations[0].size.name
                      : 'Único'}
                  </TextFlex>
                </NoMobile>
                <OnlyModile>
                  <TextFlex>
                    <span
                      data-cy="features_product_details_order"
                      style={styles.purpleBold}>
                      Características:
                    </span>
                    {item.product.variations.length > 0
                      ? item.product.variations[0].color.name
                      : 'Única'}
                    {' - '}
                    {item.product.variations.length > 0
                      ? item.product.variations[0].size.name
                      : 'Único'}
                  </TextFlex>
                </OnlyModile>
                <Box style={{ minWidth: 120 }}>
                  <TextFlex>
                    <span
                      data-cy="quantity_product_details_order"
                      style={styles.purpleBold}>
                      Quantidade:
                    </span>
                    {item.quantity}
                  </TextFlex>
                </Box>
                <Box style={{ minWidth: 120 }}>
                  <TextFlex>
                    <span
                      data-cy="price_product_details_order"
                      style={styles.purpleBold}>
                      Preço:
                    </span>
                    {appUtils.formatPrice(item.price)}
                  </TextFlex>
                </Box>
                <NoMobile>
                  <TextFlex>
                    <span
                      data-cy="total_order_details_order"
                      style={styles.purpleBold}>
                      Total:
                    </span>
                    {appUtils.formatPrice(item.price * item.quantity)}
                  </TextFlex>
                </NoMobile>
              </Box>
            </Box>
          ))}
        </>
      )}
    </Box>
  );
};

ProductItem.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ProductItem;
