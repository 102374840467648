import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as CategoryListCreators } from 'store/ducks/category/list';
import ShoppingCart from 'containers/Pages/ShoppingCartPage';
import SharedCart from 'containers/Pages/SharedCartPage';
import ProductDetailsPage from 'containers/Pages/ProductDetailsPage';
import SearchProductPage from 'containers/Pages/SearchProductPage';
import CategoriesPage from 'containers/Pages/CategoriesPage';
import MyAccountPage from 'containers/Pages/MyAccountPage';
import RegisterClientPage from 'containers/Pages/RegisterClientPage';
import FinalizationOrderPage from 'containers/Pages/FinalizationOrderPage';
import StoresPage from 'containers/Pages/StoresPage';
import StoreDetailsPage from 'containers/Pages/StoreDetailsPage';
import InfoPage from 'containers/Pages/InfoPage';
import { CssBaseline } from '@material-ui/core';
import ConfimationPaymentPage from 'containers/Pages/ConfimationPaymentPage';
import SnackbarNotfication from 'components/SnackbarNotfication';
import { Creators as AppCreators } from 'store/ducks/app';
import { Creators as ParametersCreators } from 'store/ducks/parameters';
import { Creators as ConfigCreators } from 'store/ducks/config';
import IssueTicketPage from 'containers/Pages/IssueTicketPage';
import TrendsPage from 'containers/Pages/TrendsPage';
import RecoveryPassPage from 'containers/Pages/RecoveryPassPage';
import WishPage from 'containers/Pages/WishPage';
import TrendDetailsPage from 'containers/Pages/TrendDetailsPage';
import MailConfirmPage from 'containers/Pages/MailConfirmPage';
import useQuery from 'hooks/useQuery';
import ReactGA from 'react-ga';
import Page404 from 'containers/Pages/404Page';
import RegisterCollaboratorPage from 'containers/Pages/RegisterCollaboratorPage';
import RegisterClientePage from 'containers/Pages/RegisterClientSitePage';
import RegisterStorePage from 'containers/Pages/RegisterStorePage';
import RegisterResellerPage from 'containers/Pages/RegisterResellerPage';
import SectionPage from 'containers/Pages/SectionPage';
import ContactPage from 'containers/Pages/ContactPage';
import { Creators as CartCreators } from 'store/ducks/checkout/cart';
import Helmet from 'react-helmet';
import Footer from '../Layout/Footer';
import HeaderMenu from '../Layout/HeaderMenu';
import CheckoutHeaderMenu from '../Layout/CheckoutHeaderMenu';
import HomePage from '../Pages/HomePage';
import appNaming from 'utils/appNaming';
import appConfig from 'utils/appConfig';
import useKonduto from 'hooks/konduto/collector';
import useKondutoVisitor from 'hooks/konduto/visitor';
import RecommendationPage from 'containers/Pages/RecommendationVinculatePage';
import ProductsMultiCatalogPage from 'containers/Pages/ProductsMultiCatalogPage';
import CatalogPage from 'Pages/Catalog';
import GalleryPage from 'Pages/Gallery';
import PartnerPage from 'Pages/Partner';
import SegmentPage from 'Pages/Segment';

const AppRoutes = () => {
  const {
    config: { config, configLoading },
    checkout: {
      cart: { list },
    },
  } = useSelector(state => state);

  const dispatch = useDispatch();

  const query = useQuery();

  React.useEffect(() => {
    const filters = {
      page: '',
      perPage: '',
      search: '',
      orderByColumn: '',
      orderByDirection: '',
    };
    dispatch(CategoryListCreators.getCategoryListRequest(filters));
    dispatch(ConfigCreators.getConfigRequest());
    if (query.get('code_promo') !== null) {
      dispatch(ParametersCreators.codePromoAdd(query.get('code_promo')));
    }
    if (query.get('preco') !== null) {
      dispatch(ParametersCreators.precoAdd(query.get('preco')));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const { pathname } = useLocation();

  React.useEffect(() => {
    ReactGA.initialize('UA-151378256-1');
    ReactGA.ga('send', 'pageview', pathname);
    window.scrollTo(0, 0);
  }, [pathname]);

  React.useEffect(() => {
    if (list.length > 0) {
      if (list.filter(item => typeof item.store === 'undefined').length > 0) {
        dispatch(CartCreators.resetCart());
        dispatch(CartCreators.resetBudgetHashCart());
        dispatch(CartCreators.resetManpowerCart());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet
        title={appNaming.REGULAR_NAMING}
        meta={[
          { name: 'twitter:title', content: `${appNaming.REGULAR_NAMING}` },
          { name: 'twitter:image', content: '%PUBLIC_URL%/favicon.ico' },

          { property: 'og:title', content: `${appNaming.REGULAR_NAMING}` },
          {
            property: 'og:description',
            content: `Seja bem-vindo ao ${appConfig.SITE_WEB}`,
          },
          { property: 'og:image', content: '%PUBLIC_URL%/favicon.ico' },

          {
            name: 'description',
            content: `Seja bem-vindo ao ${appConfig.SITE_WEB}`,
          },
        ]}
      />

      {!configLoading && Object.keys(config).length > 0 && (
        <>
          <HeaderMenu
            categoryList={config.menu_all}
            pagesList={config.menu_principal}
          />
          <>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/carrinho" component={ShoppingCart} />
              <Route exact path="/csd/:id" component={SharedCart} />
              <Route exact path="/csd/:id/:service" component={SharedCart} />
              <Route
                exact
                path="/seja-um-parceiro/:code"
                component={RecommendationPage}
              />
              <Route
                exact
                path="/produto/:id/:slug"
                component={ProductDetailsPage}
              />
              <Route exact path="/catalog/:id" component={CatalogPage} />
              <Route exact path="/segment/:id" component={SegmentPage} />
              <Route exact path="/gallery/:id" component={GalleryPage} />
              <Route exact path="/partner/:id" component={PartnerPage} />
              <Route exact path="/produto/:id" component={ProductDetailsPage} />
              <Route
                exact
                path="/produtos/:id"
                component={ProductsMultiCatalogPage}
              />
              <Route
                exact
                path="/catalogs/:catalog/produto/:id"
                component={ProductDetailsPage}
              />
              <Route exact path="/categoria/:name" component={CategoriesPage} />
              <Route
                exact
                path="/categoria/:father/:name"
                component={CategoriesPage}
              />
              <Route
                exact
                path="/pesquisa/:search"
                component={SearchProductPage}
              />
              <Route exact path="/minha-conta" component={MyAccountPage} />
              <Route
                exact
                path="/cadastro-cliente"
                component={RegisterClientPage}
              />
              <Route exact path="/lojas" component={StoresPage} />
              <Route
                exact
                path="/lojas/cadastrar"
                component={RegisterStorePage}
              />
              <Route
                exact
                path="/revendedor/cadastrar"
                component={RegisterResellerPage}
              />
              <Route
                exact
                path="/colaborador/cadastrar"
                component={RegisterCollaboratorPage}
              />
              <Route
                exact
                path="/ClientALLSite/cadastrar"
                component={RegisterClientePage}
              />
              <Route exact path="/lojas/:slug" component={StoreDetailsPage} />
              <Route exact path="/tendencias" component={TrendsPage} />
              <Route
                exact
                path="/tendencias/:slug"
                component={TrendDetailsPage}
              />
              <Route exact path="/pagina/:route" component={InfoPage} />
              <Route exact path="/meus-desejos" component={WishPage} />
              <Route
                exact
                path="/minha-conta/resetar-senha"
                component={RecoveryPassPage}
              />
              <Route
                exact
                path="/minha-conta/confirmar"
                component={MailConfirmPage}
              />
              <Route exact path="/secoes/:slug" component={SectionPage} />
              <Route exact path="/contato" component={ContactPage} />
              <Route component={Page404} />
            </Switch>
          </>
          <Footer />
        </>
      )}
    </>
  );
};

const CheckoutRoutes = () => {
  const [headerStep, setHeaderStep] = React.useState(1);

  const handleStep = value => {
    setHeaderStep(value);
  };

  return (
    <>
      <CheckoutHeaderMenu headerStep={headerStep} />
      <>
        <Switch>
          <Route path="/finalizar-compra" component={FinalizationOrderPage} />
          <Route
            exact
            path="/compra-finalizada"
            component={() => <ConfimationPaymentPage handleStep={handleStep} />}
          />
          <Route
            exact
            path="/emitir-boleto"
            component={() => <IssueTicketPage handleStep={handleStep} />}
          />
        </Switch>
      </>
      {/* <Footer /> */}
    </>
  );
};

const App = () => {
  const { openNotification, messageNotification, typeNotification } =
    useSelector(state => state.app);
  const dispatch = useDispatch();

  useKonduto();
  useKondutoVisitor();

  const closeNotification = () => {
    dispatch(AppCreators.closeNotification());
  };

  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <main>
      <CssBaseline />
      <SnackbarNotfication
        data-cy="notification"
        open={openNotification}
        message={messageNotification}
        close={closeNotification}
        type={typeNotification}
      />
      {/* <Notifications notifications={notifications} /> */}
      <Switch>
        <Route exact path="/finalizar-compra" component={CheckoutRoutes} />
        <Route path="/compra-finalizada" component={CheckoutRoutes} />
        <Route path="/emitir-boleto" component={CheckoutRoutes} />
        <Route path="/" component={AppRoutes} />
      </Switch>
    </main>
  );
};
export default App;
