import { call, takeLatest, all, put } from 'redux-saga/effects';
import api from 'services/api';
import { Types, Creators } from 'store/ducks/product/productsBySection';

function* getProductsBySection({ payload }) {
  try {
    const {
      filters: {
        page,
        search,
        per_page,
        order,
        price_min,
        price_max,
        orderByDirection,
      },
      slug,
    } = payload;
    const response = yield call(api.get, `/v1/site/sections/${slug}/products`, {
      page,
      search,
      per_page,
      order,
      price_min,
      price_max,
      order_by: orderByDirection,
    });
    if (response.status !== 200) throw response;
    yield put(Creators.getProductsBySectionSuccess(response.data));
  } catch (err) {
    yield put(Creators.getProductsBySectionFailure('Erro ao buscar na API'));
  }
}

export default function* productsBySectionSaga() {
  yield all([takeLatest(Types.GET_LIST_REQUEST, getProductsBySection)]);
}
