import React from 'react';
import styles, { Img } from './styles';
import { Box, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import notImage from 'images/Products/not_image.jpg';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Slider from 'infinite-react-carousel';

const Wrapper = styled.div`
  padding: 10px;
  width: 100%;
`;

const StoresSlide = ({ title, subtitle, data }) => {
  const history = useHistory();

  const [totalLoad] = React.useState(0);
  const [setIsLoad] = React.useState(false);
  const [width, setWidth] = React.useState(window.innerWidth);
  const handleResize = () => {
    setWidth(window.innerWidth);
  };
  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  React.useEffect(() => {
    if (totalLoad === data.length && data.length > 0) {
      setIsLoad(true);
    }
  }, [totalLoad, data.length, setIsLoad]);

  let slidesToShow;
  if (width <= 800) slidesToShow = 2;
  if (width <= 1024 && width >= 800) slidesToShow = 3;
  if (width >= 1024) slidesToShow = 5;

  const settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: false,
    slidesToShow,
    swipe: true,
  };

  const SimpleSlider = () => (
    <Slider {...settings} style={{ width: '100%' }}>
      {data.length > 0 ? (
        data.map(
          item =>
            item.image.original !== '' && (
              <div
                key={item.slug}
                onClick={() => {
                  history.push({
                    pathname: `/lojas/${item.slug}`,
                  });
                }}
                style={{
                  padding: 4,
                  width: '200px',
                }}>
                <Img
                  alt=""
                  src={
                    Object.keys(item.image).length > 0
                      ? item.image.original
                      : notImage
                  }
                  style={{
                    display: 'block',
                    margin: '0 auto',
                    backgroundColor: 'white',
                    opacity: item.closed ? 0.5 : 1,
                  }}
                />
                {!!item.closed && (
                  <div
                    style={{
                      position: 'absolute',
                      bottom: '10px',
                      left: '25%',
                      background: '#8B0000',
                      color: '#fff',
                      textAlign: 'center',
                      width: '50%',
                      height: '18px',
                      borderRadius: '16px',
                    }}>
                    FECHADO
                  </div>
                )}
              </div>
            )
        )
      ) : (
        <div />
      )}
    </Slider>
  );

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        flex="1"
        alignItems="center"
        style={{ cursor: 'pointer' }}>
        <Typography style={styles.purpleTitle}>{title}</Typography>
        <Typography style={styles.smallSubTitle}>{subtitle}</Typography>

        <Wrapper>
          <SimpleSlider />
        </Wrapper>
      </Box>
    </>
  );
};

StoresSlide.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};

export default StoresSlide;
