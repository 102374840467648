import React, { useState } from 'react';
import HeaderComponent from 'components/HeaderComponent';
import PageBase from 'components/PageBase';
import { GridView, PaymentCard, Title, Text, Button } from './styles';
import { useSelector } from 'react-redux';
import { useStore } from 'hooks/store';

function PaymentMethods() {
  const { updatePayment } = useStore();
  const {
    config: { shopping },
  } = useSelector(state => state.config);
  const { store } = useSelector(state => state.admin.store);
  const [selects, setSelects] = useState([]);
  const [enables, setEnables] = useState([]);

  const handleAdd = value => {
    if (selects.includes(value)) {
      setSelects(selects.filter(a => a !== value));
    } else {
      setSelects(m => [...m, value]);
    }
  };

  React.useEffect(() => {
    if (shopping.payment) {
      setEnables(shopping.payment.map(a => a.payment_type) || []);
    }

    if (store.payment) {
      setSelects(store.payment.map(a => a.payment_type) || []);
    }
  }, [shopping, store]);

  const onSubmit = () => {
    updatePayment(store.id, {
      cnpj: store.cnpj,
      email: store.email,
      payment: {
        payment_type: selects,
      },
    });
  };

  return (
    <PageBase>
      <HeaderComponent title="Métodos de pagamento" />

      <Text>Cique para selecionar os métodos que você deseja.</Text>
      <br />
      <GridView direction="row" spacing={2}>
        {enables.includes(1) && (
          <PaymentCard
            data-cy="card_payment_credit_card"
            active={selects.includes(1)}
            onClick={() => handleAdd(1)}>
            <Title>Cartão de Crédito</Title>
            <Text data-cy="text_payment_credit_card">
              O marketplace disponibiliza o uso do cartão de crédito para
              compras à vista ou parcelada.
            </Text>
          </PaymentCard>
        )}

        {enables.includes(2) && (
          <PaymentCard
            data-cy="card_payment_ticket"
            active={selects.includes(2)}
            onClick={() => handleAdd(2)}>
            <Title>Boleto</Title>
            <Text data-cy="text_payment_ticket">
              O marketplace disponibiliza o uso de pagamento com boleto para
              compras à vista.
            </Text>
          </PaymentCard>
        )}

        {enables.includes(3) && (
          <PaymentCard
            data-cy="card_payment_delivery"
            active={selects.includes(3)}
            onClick={() => handleAdd(3)}>
            <Title>Pagamento na entrega</Title>
            <Text data-cy="text_payment_delivery">
              O marketplace disponibiliza a loja que receba o pagamento na
              entrega
            </Text>
          </PaymentCard>
        )}
      </GridView>

      <Button
        data-cy="btn_submit"
        disabled={selects.length === 0}
        onClick={onSubmit}>
        Salvar
      </Button>
    </PageBase>
  );
}

export default PaymentMethods;
