import styled from 'styled-components';
import { Box } from '@material-ui/core';
import appColors from 'utils/appColors';

export const CartContainer = styled(Box)`
&&{
  display: flex;
  flex: 1;
  margin-top:50px;
  width: 90%;
  margin-bottom:20px;
  margin-left: auto; 
  margin-right: auto;
}
`;

export const FooterContainer = styled(Box)`
&&{
  display: flex;
  flex: 1;
  margin-top:60px;
  width: 90%;
  margin-bottom:40px;
}
`;
export const FooterSubContainer = styled(Box)`
  && {
    flex-direction: row; 
    justify-content: space-between;

    @media all and (max-width:500px){
      flex-direction: column;
      justify-content: center; 
      align-items:center;
    }
  }
`

export const TitleContainer = styled(Box)`
  && {
    max-width: 200px; 
    margin-bottom:20px;

    @media all and (max-width:500px){
      text-align:center;
      align-items:center;
      justify-content:center;
    }
  }
`




const styles = {

  purpleButton: {
    backgroundColor: appColors.PRIMARY_COLOR,
    color: 'white',
    paddingLeft: 40,
    paddingRight: 40,
  },
  bigBoldTextBlack: {
    color: '#373737',
    fontWeight: 'bold',
    fontSize: 18,
    textTransform: 'uppercase',
  },

  mediumBoldTextBlack: {
    color: '#373737',
    fontWeight: 'bold',
    fontSize: 16,
  },
  smallTextBlackBold: {
    color: '#373737',
    fontWeight: 'bold',
  },
  imgFlag: {
    width: 50,
    marginRight: 10,
    marginTop: 10
  },
  bigTitlePurple:{
    color: appColors.PRIMARY_COLOR,
    fontWeight:'bold',
    fontSize:25,
  }
};

export default styles;
