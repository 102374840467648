/* eslint-disable react/display-name */
import React from 'react';
import PageBase from 'components/PageBase';
import DefaultTable from 'components/Tables/DefaultTable';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as OrderCreators } from 'store/ducks/admin/order';
import HeaderComponent from 'components/HeaderComponent';
import OrderTableHeader from 'components/OrderPage/OrderTableHeader';
import { Paper, Typography, Box } from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';
import OrderActions from 'components/OrderPage/OrderActions';
import { formatDate, toPrice } from 'utils/converters';
import moment from 'moment';
import appColors from 'utils/appColors';
import StatusColorOrderForm from 'components/OrderPage/OrderView/StatusColorOrderForm';
import appUtils from 'utils/appUtils';

const OrderListPageReseller = ({ handleTab }) => {
  const columns = ({ onDeleteRequest }) => [
    {
      title: 'Pedido',
      field: 'id',
      render: rowData => <span>{rowData.id}</span>,
    },
    {
      title: 'R$',
      field: 'payment_type',
      render: rowData => <span>R$ {toPrice(rowData.price)}</span>,
    },
    {
      title: 'Status',
      field: 'status',
      render: rowData => <StatusColorOrderForm rowData={rowData} />,
    },
    {
      title: 'Data',
      field: 'created_at',
      render: rowData => <span>{formatDate(rowData.created_at)}</span>,
    },
    {
      field: 'actions',
      render: rowData => (
        <OrderActions
          handleTab={handleTab}
          rowData={rowData}
          onDeleteRequest={onDeleteRequest}
        />
      ),
    },
  ];

  const dispatch = useDispatch();
  const [deleteState, setDeleteState] = React.useState({
    open: false,
    item: {},
  });

  const today = moment();
  const actualDate = moment();
  const weekdate = today.add(-7, 'day');

  const [localState, setLocalState] = React.useState({
    search: '',
    orderByColumn: '',
    orderByDirection: '',
    page: 1,
    perPage: 10,
    dateStart: weekdate.format('YYYY-MM-DD'),
    dateEnd: actualDate.format('YYYY-MM-DD'),
  });

  const { orderList, orderListLoading, orderListTotal, orderDeleteLoading } =
    useSelector(state => state.admin.order);

  const handleAlertDialogClose = () => {
    setDeleteState({ open: false, item: {} });
  };

  React.useEffect(() => {
    if (orderDeleteLoading === false && deleteState.open) {
      handleAlertDialogClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDeleteLoading]);

  const getFunction = data => {
    setLocalState(oldLocalState => ({ ...oldLocalState, search: data.search }));
  };

  React.useEffect(() => {
    dispatch(OrderCreators.getOrderListRequest(localState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState]);

  const onDeleteRequest = item => {
    setDeleteState({ open: true, item });
  };

  const onDeleteConfirm = () => {
    dispatch(OrderCreators.getOrderDeleteRequest(deleteState.item.id));
  };
  const { dashboard } = useSelector(state => state.admin.dashboard);

  return (
    <PageBase>
      <HeaderComponent title="Extrato de pedidos" />
      <OrderTableHeader
        getFunction={getFunction}
        initialValues={{
          search: localState.search,
          dateStart: weekdate,
          dateEnd: actualDate,
        }}
      />
      <Box style={{ marginTop: 15, marginBottom: 15 }}>
        <Typography
          style={{ fontWeight: 'bold', color: appColors.PRIMARY_COLOR }}>
          RELATÓRIO DA BUSCA
        </Typography>
      </Box>
      <Box
        flex="wrap"
        style={{
          display: 'flex',
          marginTop: 15,
          marginBottom: 15,
          flexDirection: 'row',
        }}>
        <Box style={{ marginRight: 200 }}>
          <Typography style={{ fontWeight: 'bolder' }}>PEDIDOS</Typography>
          <Typography
            style={{ fontWeight: 'bold', fontSize: 45, color: 'gray' }}>
            {dashboard.sales_quantity}
            <span style={{ color: appColors.PRIMARY_COLOR }}>º</span>
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bolder' }}>VALOR TOTAL</Typography>
          <Typography
            style={{ fontWeight: 'bold', fontSize: 45, color: 'gray' }}>
            <span style={{ fontSize: 30, color: appColors.PRIMARY_COLOR }}>
              R$
            </span>{' '}
            {appUtils.formatPrice(dashboard.billing)}
          </Typography>
        </Box>
      </Box>
      <Paper>
        <DefaultTable
          getFunction={getFunction}
          columns={columns({ onDeleteRequest })}
          data={orderList}
          total={orderListTotal}
          isLoading={orderListLoading}
          page={localState.page}
          perPage={localState.perPage}
        />
      </Paper>
      <AlertDialog
        isOpen={deleteState.open}
        isLoading={orderListLoading}
        handleClose={handleAlertDialogClose}
        onConfirm={onDeleteConfirm}
        title="Excluir registro?"
        description={`Remover categoria: ${deleteState.item.name}`}
      />
    </PageBase>
  );
};

export default OrderListPageReseller;
