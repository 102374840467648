import React from 'react';
import { Box, Typography, CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as RecoveryCreators } from 'store/ducks/account/passwordRecovery';
import { Creators as ValidateCreators } from 'store/ducks/account/validate';
import useQuery from 'hooks/useQuery';
import styles, { LinkSimulator, BoxLoginContainer, BoxTitle } from './styles';
import RecoveryForm from './RecoveryForm';
import appColors from 'utils/appColors';
import { Helmet } from 'react-helmet';

const RecoveryPassPage = () => {
  const dispatch = useDispatch();

  const { hash, validateLoading } = useSelector(
    state => state.account.validateHash
  );
  const { passwordRecoveryLoading } = useSelector(
    state => state.account.passwordRecovery
  );

  const query = useQuery();

  React.useEffect(() => {
    dispatch(ValidateCreators.getValidateRequest(query.get('hash')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = values => {
    dispatch(
      RecoveryCreators.getPasswordRecoveryRequest(values.newPassword, hash)
    );
  };

  return (
    <>
      {/* INTEGRAÇÃO KDT = TELA RECUPERAR SENHA */}
      <Helmet>
        <meta name="kdt:page" content="password_reset" />
      </Helmet>
      <BoxLoginContainer flex="1" display="flex" flexDirection="column">
        {hash !== null && (
          <Box>
            <BoxTitle
              flex="1"
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center">
              <Typography style={styles.purpleTitle}>
                Recuperação de Senha
              </Typography>
            </BoxTitle>
            <Box flex="1">
              <RecoveryForm
                onSubmit={onSubmit}
                passwordRecoveryLoading={passwordRecoveryLoading}
              />
            </Box>
            <Box style={{ marginBottom: 100 }}>
              <Typography>
                <LinkSimulator>Voltar</LinkSimulator>
              </Typography>
            </Box>
          </Box>
        )}
        {hash === null && !validateLoading && (
          <Box flex="1" style={{ height: 300 }}>
            <Typography style={styles.purpleTitle}>
              Hash de Recuperação de senha Expirado.
            </Typography>
          </Box>
        )}
        {validateLoading && (
          <Box
            flex="1"
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: 300 }}>
            <CircularProgress style={{ color: appColors.PRIMARY_COLOR }} />
          </Box>
        )}
      </BoxLoginContainer>
    </>
  );
};

export default RecoveryPassPage;
