import {
  call, takeLatest, all, put, select,
} from 'redux-saga/effects';
import api from 'services/api';
import { Types, Creators } from 'store/ducks/stores/list';

function* getStoresList({ payload }) {
  try {
    const { page, perPage, search, orderByColumn, orderByDirection } = payload;
    const response = yield call(api.get, 'v1/site/stores', {
      page,
      search,
      per_page: perPage,
      order: orderByColumn,
      order_by: orderByDirection,
    });
    const { storesList } = yield select(state => state.stores.list);
    const data = { data:[...storesList, ...response.data.data], total: response.data.total };
    if (response.status !== 200) throw response;
    yield put(Creators.getStoresListSuccess(data));
  } catch (err) {
    yield put(Creators.getStoresListFailure('Erro ao buscar na API'));
  }
}

// Individual exports for testing
export default function* storesListSaga() {
  yield all([
    takeLatest(Types.GET_STORES_LIST_REQUEST, getStoresList),
  ]);
}

