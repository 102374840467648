import React from 'react';

import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import HeaderBoxContainer from 'components/HeaderComponent/HeaderBoxContainer';
import HeaderBoxItem from 'components/HeaderComponent/HeaderBoxItem';
import HeaderFab from 'components/HeaderComponent/HeaderFab';
import CustomDateRange from 'components/form/CustomDateRange';

const DateRangeInput = ({ getFunction, initialValues }) => {
  const onSubmit = values => {
    getFunction(values);
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={() => (
        <Form>
          <HeaderBoxContainer style={{ justifyContent: 'flex-end' }}>
            <HeaderBoxItem style={{ paddingRight: 8 }}>
              <Field name="dateStartEnd" component={CustomDateRange} />
            </HeaderBoxItem>
            <HeaderBoxItem style={{ paddingLeft: 6, paddingRight: 0 }}>
              <HeaderFab icon="search" type="submit" />
            </HeaderBoxItem>
            {/*
            <HeaderBoxItem style={{ paddingLeft: 6, paddingRight: 0 }}>
              <HeaderButton icon="search">Busca Avançada</HeaderButton>
            </HeaderBoxItem>
            <HeaderBoxItem style={{ paddingLeft: 6 }}>
              <HeaderButton icon="get_app">Exportar</HeaderButton>
            </HeaderBoxItem>
            */}
          </HeaderBoxContainer>
        </Form>
      )}
    />
  );
};

DateRangeInput.propTypes = {
  getFunction: PropTypes.func.isRequired,
  initialValues: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default DateRangeInput;