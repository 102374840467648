import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { useSpring, animated } from 'react-spring';
import styles, { ModalContainer } from './styles';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const Fade = React.forwardRef(function Fade(props, ref) {

  const { in: open, children, onEnter, onExited, ...other } = props;

  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

const CustomModal = ({ isOpen, closeModal, title, children }) => {

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        style={styles.modal}
        open={isOpen}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <ModalContainer>
            <Box display='flex' justifyContent='space-between' flexDirection='row' style={styles.headerModal} >
              <Typography style={styles.headerTitle}>{title}</Typography>
              <CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
            </Box>
            {children}
          </ModalContainer>
        </Fade>
      </Modal>
    </div>
  );
}

CustomModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default CustomModal;