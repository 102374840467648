import React from 'react';
import { Box, Button } from '@material-ui/core';
import OkImage from 'images/logos/order-ok.png';
import appConfig from 'utils/appConfig';
import { Link } from 'react-router-dom';
import styles, {
  CartContainer,
  BigTextBlack,
  BigTitlePurple,
  BoxContainerText,
  BoxContainer,
  BoxContainerOkImg,
} from './styles';

import { Stack } from '@chakra-ui/react';

const ConfimationPaymentPage = ({ handleStep }) => {
  React.useEffect(() => {
    handleStep(3);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack alignItems="center">
      <CartContainer flex="1" flexDirection="row" flexWrap="wrap">
        <BoxContainer
          flex="1"
          display="flex"
          flexDirection="row"
          flexWrap="wrap">
          <BoxContainerOkImg display="flex" alignItems="center">
            <img alt="" src={OkImage} />
          </BoxContainerOkImg>
          <BoxContainerText
            data-cy="box_confirm_order"
            flex="1"
            display="flex"
            justifyContent="center"
            flexDirection="column">
            <Box>
              <BigTextBlack>
                RECEBEMOS SEU PEDIDO!
                <br />
                ESTAMOS AGUARDANDO APROVAÇÃO
              </BigTextBlack>
            </Box>
            <Box style={{ marginTop: 30 }}>
              <BigTitlePurple>AGRADECEMOS A PREFERÊNCIA!</BigTitlePurple>
            </Box>
          </BoxContainerText>
          <Box
            flex="1"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center">
            <img alt="" src={appConfig.LOGO_VERTICAL} width={250} />
            <Box
              flex="1"
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{ marginTop: 20 }}>
              <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Button data-cy="btn_keep_buying" style={styles.purpleButton}>
                  continuar comprando
                </Button>
              </Link>
            </Box>
          </Box>
        </BoxContainer>
      </CartContainer>
    </Stack>
  );
};

export default ConfimationPaymentPage;
