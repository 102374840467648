/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import appNaming from 'utils/appNaming';
import {
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import { InputContainer, InputItem } from 'components/form/StyledComponents';
import CustomTextField from 'components/form/CustomTextField';
import * as Yup from 'yup';
import validators from 'utils/validators';
import CustomMaskField from 'components/form/CustomMaskField';
import CepMaskField from './CepMaskField';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as CepCreators } from 'store/ducks/address';
import { Helmet } from 'react-helmet';
import styles, {
  MapContainer,
  StyledCustomButtonModal,
  StyledTextField,
} from './styles';
import Geocode from 'react-geocode';
import appTheme from 'utils/appTheme';
import appColors from 'utils/appColors';
import { key as googleMapskey } from 'services/maps';
import { Box } from '@chakra-ui/react';
import { ButtonIcon } from 'containers/Layout/HeaderMenu/components/buriti/styles';
import { StyledIcon } from 'containers/Pages/HomePage/components/oferta/components/styles';
import MapModal from './MapModal';
import MapComponent from './MapModal/map';
import CustomSelect from 'components/form/CustomSelect';

const schema = Yup.object().shape({
  long: Yup.string().required('Este campo é obrigatório'),
  lat: Yup.string().required('Este campo é obrigatório'),
  name: Yup.string()
    .min(3, 'Nome inválido')
    .required('Este campo é obrigatório'),
  social_name: Yup.string()
    .min(3, 'Razão social inválida')
    .required('Este campo é obrigatório'),
  cnpj: Yup.string()
    .test(...validators.cnpjInvalid('CNPJ inválido'))
    .required('Este campo é obrigatório'),
  cell_phone: Yup.string().required('Este campo é obrigatório'),
  company_type: Yup.string().required('Escolha o Tipo da empresa'),
  email: Yup.string()
    .email('Este email não é valido')
    .required('Este campo é obrigatório'),
  accept_terms: Yup.mixed().required(
    'É necessário aceitar os termos para continuar.'
  ),
  address: Yup.object().shape({
    code_post: Yup.string().required('Informe o CEP'),
    street: Yup.string().required('Infome a Rua ou Avenida'),
    number: Yup.string().required('Informe o numero do local'),
    district: Yup.string().required('Informe o bairro'),
    complement: Yup.string(),
  }),
});

const RegisterStoreForm = ({ onSubmit, initialValues, onSubmitCEP }) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(CepCreators.getClearCepRequest());
    window.scrollTo(0, 0);
  }, []);

  const validateCEP = value => {
    let error;
    if (value.replace(/[()_-]/g, '').replace(/ /g, '').length < 8) {
      error = 'CEP inválido';
    }
    return error;
  };

  const { cep, cepError, cepLoading } = useSelector(state => state.address);

  const formikRef = React.useRef(null);
  const setFieldValue = (name, value) => {
    formikRef.current.setFieldValue(name, value);
  };

  React.useEffect(() => {
    if (Object.keys(cep).length > 0) {
      setFieldValue('address.code_post', cep.code_post);
      setFieldValue('address.street', cep.street);
      setFieldValue('address.number', cep.number);
      setFieldValue('address.district', cep.district);
      setFieldValue('address.complement', cep.complement);
      setFieldValue('address.city.name', cep.city.name);
      setFieldValue('address.city.id', cep.city.id);
      setFieldValue('address.city.state.id', cep.city.state.id);
      setFieldValue('address.city.state.name', cep.city.state.name);
      dispatch(CepCreators.getClearCepRequest());
    }
  }, [cep]);

  const [address, setAddress] = React.useState('');
  const [openModal, setOpenModal] = React.useState(false);
  const [coordinates, setCoordinates] = React.useState({});
  const [errorMessage, setErroMessage] = React.useState('');

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setErroMessage('');
  };

  const handleSaveLocalization = () => {
    if (address === '') return setErroMessage('Informe o endereço');
    setFieldValue('lat', coordinates.lat);
    setFieldValue('long', coordinates.lng);
    handleCloseModal();
  };

  const handleLocalization = () => {
    if (address === '') return setErroMessage('Informe o endereço');
    Geocode.setApiKey(googleMapskey);
    Geocode.enableDebug();
    Geocode.setLanguage('br');
    Geocode.setRegion('br');
    Geocode.fromAddress(address).then(
      response => {
        const { location } = response.results[0].geometry;
        setErroMessage('');
        setCoordinates({});
        setTimeout(
          () => setCoordinates(old => ({ ...old, ...location })),
          1000
        );
        setErroMessage('');
      },
      () => {
        setErroMessage(
          'Não foi possível encontrar o endereço, verifique e tente novamente'
        );
      }
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      innerRef={formikRef}
      ref={formikRef}
      validateOnBlur
      onSubmit={onSubmit}
      render={({ handleSubmit, setFieldValue, values, errors }) => (
        <Box flex="1" display="flex" flexDirection="column">
          <Helmet>
            <meta name="kdt:page" content="account" />
          </Helmet>
          <Form onSubmit={handleSubmit}>
            <InputContainer style={{ marginBottom: 35 }}>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Nome da Loja <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="name"
                  id="inputNomeLoja"
                  placeholder="Informe o nome da loja"
                  component={CustomTextField}
                />
              </InputItem>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Razão Social <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="social_name"
                  id="inputRazaoSocial"
                  placeholder="Informe a razão social da loja"
                  component={CustomTextField}
                />
              </InputItem>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Tipo da Empresa <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="company_type"
                  options={[
                    { id: 'MEI', name: 'Micro Empreendedor Individual' },
                    { id: 'LIMITED', name: 'Empresa Limitada' },
                    { id: 'INDIVIDUAL', name: 'Empresa Individual' },
                    { id: 'ASSOCIATION', name: 'Associação' },
                  ]}
                  placeholder="Informe o tipo da Compania"
                  component={CustomSelect}
                  isLoading={false}
                />
              </InputItem>
            </InputContainer>
            <InputContainer>
              <InputItem>
                <Typography style={styles.titleInput}>
                  CNPJ <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="cnpj"
                  placeholder="Informe o CNPJ da loja"
                  mask="99.999.999/9999-99"
                  component={CustomMaskField}
                />
              </InputItem>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Telefone <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="cell_phone"
                  placeholder="Informe o telefone da empresa"
                  mask={
                    values.cell_phone &&
                    values.cell_phone > 5 &&
                    values.cell_phone[5] === '9'
                      ? '(99) 9999-9999'
                      : '(99) 9 9999-9999'
                  }
                  component={CustomMaskField}
                />
              </InputItem>
              <InputItem>
                <Typography style={styles.titleInput}>
                  Email da Loja <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Field
                  name="email"
                  id="inputEmailLojista"
                  placeholder="Informe seu email. Ex: nomedaloja@email.com"
                  component={CustomTextField}
                />
              </InputItem>
            </InputContainer>
            <Box
              style={{
                borderBottom: `1px solid ${appColors.PRIMARY_COLOR}`,
                marginTop: 60,
                marginBottom: 40,
              }}
            />
            <div style={{ display: appTheme.name === 'Buriti' ? 'none' : '' }}>
              <Typography style={styles.purpleTitle}>
                Endereço da Loja
              </Typography>
              {cepError && (
                <Box
                  style={{
                    paddingTop: 5,
                    marginLeft: 10,
                    marginTop: 10,
                    color: 'red',
                  }}>
                  <Typography>{cepError}</Typography>
                </Box>
              )}
              <InputContainer>
                <InputItem>
                  <Typography style={styles.titleInput}>
                    CEP <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <Field
                    name="address.code_post"
                    id="inputEnderecoCEP"
                    label="Informe o CEP da loja"
                    mask="99999-999"
                    validate={validateCEP}
                    onSubmitCEP={onSubmitCEP}
                    component={CepMaskField}
                  />
                </InputItem>
              </InputContainer>
              {((Object.keys(cep).length > 7 && !cepLoading) ||
                values.address.city.id !== '') && (
                <>
                  <Box>
                    <InputContainer>
                      <InputItem>
                        <InputContainer>
                          <InputItem style={{ flexGrow: 10 }}>
                            <Typography>Rua/Avenida</Typography>
                            <Field
                              name="address.street"
                              id="inputEnderecoRua"
                              label="Infome o nome da rua ou avenida"
                              component={CustomTextField}
                            />
                          </InputItem>
                        </InputContainer>
                        <InputContainer>
                          <InputItem>
                            <Typography>Número</Typography>
                            <Field
                              name="address.number"
                              id="inputEnderecoNumero"
                              label="Infome o número do local"
                              component={CustomTextField}
                            />
                          </InputItem>
                          <InputItem>
                            <Typography>Bairro</Typography>
                            <Field
                              name="address.district"
                              id="inputEnderecoBairro"
                              label="Informe o bairro"
                              component={CustomTextField}
                            />
                          </InputItem>
                        </InputContainer>
                        <InputContainer>
                          <InputItem>
                            <Typography>Cidade</Typography>
                            <Field
                              disabled
                              name="address.city.name"
                              id="inputEnderecoCidade"
                              placeholder="Infome o número do local"
                              component={CustomTextField}
                            />
                          </InputItem>
                        </InputContainer>
                        <InputContainer>
                          <InputItem>
                            <Typography>Estado</Typography>
                            <Field
                              disabled
                              name="address.city.state.name"
                              id="inputEnderecoEstado"
                              placeholder="Infome o número do local"
                              component={CustomTextField}
                            />
                          </InputItem>
                        </InputContainer>
                        <InputContainer>
                          <InputItem>
                            <Typography>Complemento</Typography>
                            <Field
                              name="address.complement"
                              id="inputEnderecoComplemento"
                              label="Adicione um complemento (Opcional)"
                              component={CustomTextField}
                            />
                          </InputItem>
                        </InputContainer>

                        <InputContainer>
                          <InputItem>
                            <Field
                              id="latitude"
                              name="lat"
                              label="Latitude"
                              component={CustomTextField}
                            />
                          </InputItem>
                          <InputItem>
                            <Field
                              id="lontitude"
                              name="long"
                              label="Longitude"
                              component={CustomTextField}
                            />
                          </InputItem>
                          <Box>
                            <Tooltip
                              placement="top-start"
                              title="Click aqui para abrir o mapa">
                              <ButtonIcon
                                id="BtnMapa"
                                style={{
                                  padding: '14px 30px',
                                  color: appColors.PRIMARY_COLOR,
                                  marginTop: '15px',
                                }}
                                onClick={handleOpenModal}
                                variant="contained"
                                size="large">
                                <StyledIcon style={{ color: 'white' }}>
                                  room
                                </StyledIcon>
                              </ButtonIcon>
                            </Tooltip>
                          </Box>
                        </InputContainer>
                      </InputItem>
                    </InputContainer>
                  </Box>
                </>
              )}
              {cepLoading && (
                <Box
                  flex="1"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={{ marginTop: 10 }}>
                  <CircularProgress
                    style={{ color: appColors.PRIMARY_COLOR }}
                  />
                </Box>
              )}
            </div>
            <Box style={{ padding: 10 }}>
              <Box
                flex="1"
                style={{ marginBottom: 40, maxWidth: 600, marginTop: 20 }}>
                <Typography style={styles.titleInput}>
                  Políticas e Termos <span style={{ color: 'red' }}>*</span>
                </Typography>
                <FormControlLabel
                  style={{ alignItems: 'flex-start', marginTop: 10 }}
                  control={
                    <Checkbox
                      checked={!!values.accept_terms}
                      onChange={() => {
                        if (values.accept_terms) {
                          setFieldValue('accept_terms', null);
                          return;
                        }
                        setFieldValue('accept_terms', true);
                      }}
                      data-cy="check_accept_terms"
                      style={{ color: appColors.PRIMARY_COLOR, paddingTop: 0 }}
                    />
                  }
                  label={
                    <Typography style={{ color: '#444' }}>
                      {`Aceito os `}
                      <a
                        style={{
                          fontWeight: 'bold',
                          color: appColors.PRIMARY_COLOR,
                        }}
                        href="/pagina/termos-de-uso?store=true&terms=true"
                        target="_blank">
                        Termos e condições
                      </a>
                      {` e autorizo o uso dos meus dados de acordo com a `}
                      <a
                        style={{
                          fontWeight: 'bold',
                          color: appColors.PRIMARY_COLOR,
                        }}
                        href="/pagina/termos-de-uso?store=true&policy=true"
                        target="_blank">
                        Política de privacidade
                      </a>
                      {` do ${appNaming.REGULAR_NAMING}.`}
                    </Typography>
                  }
                />
                {typeof errors.accept_terms !== 'undefined' && (
                  <Typography style={{ color: 'red', marginTop: 10 }}>
                    {errors.accept_terms}
                  </Typography>
                )}
              </Box>
              <Button
                id="btnProsseguir"
                type="submit"
                style={styles.purpleButtonProceed}>
                Prosseguir
              </Button>
            </Box>
          </Form>
          <MapModal
            handleConfirm={handleSaveLocalization}
            open={openModal}
            handleClose={handleCloseModal}>
            <InputContainer style={{ margin: '20px 0 30px' }}>
              <InputItem>
                <StyledTextField
                  id="InputEndereco"
                  value={address}
                  onChange={e => setAddress(e.target.value)}
                  fullWidth
                  error={!!errorMessage}
                  helperText={errorMessage}
                  variant="outlined"
                  label="Endereço"
                />
              </InputItem>
              <StyledCustomButtonModal
                id="BtnPesquisar"
                onClick={handleLocalization}
                label="Pesquisar"
              />
            </InputContainer>

            <MapContainer id="mapaform">
              <MapComponent
                coordinates={coordinates}
                handleClick={setCoordinates}
              />
            </MapContainer>
          </MapModal>
        </Box>
      )}
    />
  );
};

export default RegisterStoreForm;
