/* eslint-disable no-await-in-loop */
/* eslint-disable react/prop-types */
/* eslint-disable eqeqeq */
/* eslint-disable radix */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import api from 'services/api';
import { Creators as NotificationCreators } from 'store/ducks/app';

const useUser = () => {
  const dispatch = useDispatch();

  const [PayParttheTicket, setPayParttheTicket] = useState({});

  const [UserBalenceSite, setUserBalenceSite] = useState({});

  const PayParttheTicketRec = async payload => {
    try {
      const response = await api.post(
        'v1/client/orders/PayParttheTicket',
        payload
      );
      if (response.status !== 200 && response.status !== 201) throw response;

      setPayParttheTicket(response.data.data);

      window.location.reload(true);
    } catch (err) {
      console.log(err);

      dispatch(
        NotificationCreators.openNotification({
          message: err.data.data.Error,
          type: 'error',
        })
      );
    }
  };
  const UserBalenceSiteRec = async () => {
    try {
      const response = await api.get('/v1/client/orders/getUserBalance');
      if (response.status !== 200 && response.status !== 201) throw response;

      setUserBalenceSite(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  return {
    PayParttheTicketRec,
    PayParttheTicket,
    UserBalenceSite,
    UserBalenceSiteRec,
  };
};
export default useUser;
