import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import notImage from 'images/Products/not_image.jpg';
import { Title, Image, Gallery, GridView } from './styles';

import useCatalog from 'hooks/useCatalogs';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { getDefaultAPI } from 'services/api';

function GalleryPage() {
  const { id } = useParams();
  const { getGallery, gallery } = useCatalog();

  useEffect(() => {
    getGallery(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (gallery === null) {
    return <div />;
  }

  return (
    <Grid container alignContent="center" style={{ padding: '20px 10%' }}>
      <div>
        <Title>Galeria</Title>

        <GridView>
          {gallery.map((item, idx) => {
            return (
              <Link
                style={{ textDecoration: 'none' }}
                key={idx}
                onClick={() =>
                  window.open(`${getDefaultAPI}/lojas/${item.id}`)
                }>
                <Gallery>
                  <Image src={item.image.medium || notImage} />
                  <Title style={{ fontSize: 22 }}>{item.name}</Title>
                </Gallery>
              </Link>
            );
          })}
        </GridView>
      </div>
    </Grid>
  );
}

export default GalleryPage;
