import React from 'react';
import BillingForm from './billingForm';
import { useInvoice } from 'hooks/invoice';
import HeaderComponent from 'components/HeaderComponent';

const AccountDetailsTab = () => {
  const { sendInvoice, sendInvoiceLoading } = useInvoice();

  const onSubmit = async values => {
    await sendInvoice(values);
  };

  return (
    <>
      <HeaderComponent title="Efetuar Cobrança"></HeaderComponent>
      <BillingForm
        onSubmit={onSubmit}
        sendInvoiceLoading={sendInvoiceLoading}
      />
    </>
  );
};

export default AccountDetailsTab;
