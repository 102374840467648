/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
import React from 'react';
import CountDown from 'components/CountDown';
import DefaultTable from 'components/Tables/DefaultTable';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as OrderCreators } from 'store/ducks/admin/order';
import HeaderComponent from 'components/HeaderComponent';
import OrderTableHeader from 'components/OrderPage/OrderTableHeader';
import { Paper, Typography } from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';
import OrderActions from 'components/OrderPage/OrderActions';
import { formatDate, toPrice } from 'utils/converters';
import moment from 'moment';
import appColors from 'utils/appColors';
import StatusColorOrderForm from 'components/OrderPage/OrderView/StatusColorOrderForm';
import StatusPaymentOrder from 'components/OrderPage/OrderView/StatusPaymentOrder';
import appUtils from 'utils/appUtils';
import { Creators as NotificationCreators } from 'store/ducks/app';
import io from 'socket.io-client';
import { baseSocket } from 'services/api';
import NewOrderStatus from 'components/OrderPage/OrderView/StatusColorOrderForm/statusNewOrder';
import PrintLoad from '../PrintLoad';
import SoundComponent from 'components/Sound';
import Actions from './components/Actions';
import { Stack, Box } from '@chakra-ui/react';

const _ = require('lodash');

const OrderListPage = ({ handleTab }) => {
  const columns = ({ onDeleteRequest }) => [
    {
      title: 'Pedido',
      field: 'id',
      render: rowData =>
        rowData.view ? (
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}>
            <NewOrderStatus rowData={rowData} />
            <span> {rowData.id}</span>
          </div>
        ) : (
          <span>{rowData.id}</span>
        ),
    },

    {
      title: 'Status do pedido',
      field: 'status',
      render: rowData =>
        rowData.stores.map((item, index) => (
          <StatusColorOrderForm
            key={String(index)}
            item={item}
            delivery={rowData.original_delivery_name}
          />
        )),
    },
    {
      title: 'R$',
      field: 'payment_type',
      render: rowData => (
        <span data-cy="list_value_order">R$ {toPrice(rowData.price)}</span>
      ),
    },
    {
      title: 'Data',
      field: 'created_at',
      render: rowData => (
        <span data-cy="list_date_order">{formatDate(rowData.created_at)}</span>
      ),
    },

    {
      title: 'Tempo',
      field: 'created_at',
      render: rowData =>
        rowData.stores[0].status <= 5 && (
          <CountDown
            accepted={rowData.stores[0].order_accepted_at}
            preparation={rowData.mim_time_for_preparation}
            status={rowData.stores[0].status}
          />
        ),
    },
    {
      title: 'Status do Pagamento',
      field: 'payment_status',
      render: rowData => (
        <StatusPaymentOrder
          item={rowData}
          delivery={rowData.original_delivery_name}
        />
      ),
    },
    {
      title: 'ALTERAR STATUS',
      field: 'actions',
      render: rowData => <Actions data={rowData} onSubmit={onSubmit} />,
    },
    {
      field: 'actions',
      render: rowData => (
        <OrderActions
          handleTab={handleTab}
          rowData={rowData}
          onDeleteRequest={onDeleteRequest}
        />
      ),
    },
  ];

  const dispatch = useDispatch();
  const SoundRef = React.useRef();
  const [deleteState, setDeleteState] = React.useState({
    open: false,
    item: {},
  });

  const today = moment();
  const actualDate = moment();
  const weekdate = today.add(-7, 'day');

  const [localState, setLocalState] = React.useState({
    search: '',
    orderByColumn: '',
    orderByDirection: '',
    page: 1,
    perPage: 10,
    dateStart: weekdate.format('YYYY-MM-DD'),
    dateEnd: actualDate.format('YYYY-MM-DD'),
  });

  const {
    orderList,
    orderListLoading,
    orderListTotal,
    orderDeleteLoading,
    orderSummary,
  } = useSelector(state => state.admin.order);

  const { store } = useSelector(({ client }) => client.client);

  const onSubmit = (data, id, print = null) => {
    SoundRef.current.stop();
    dispatch(
      OrderCreators.getUpdateStatusRequest({
        id: id,
        status: data.status,
        print,
      })
    );
  };

  const handleAlertDialogClose = () => {
    setDeleteState({ open: false, item: {} });
  };

  React.useEffect(() => {
    if (orderDeleteLoading == false && deleteState.open) {
      handleAlertDialogClose();
    }
  }, [orderDeleteLoading]);

  const getFunction = data => {
    setLocalState(oldLocalState => ({
      ...oldLocalState,
      ...data,
      statusNotIn: [98],
      search: data.search,
    }));
  };

  React.useEffect(() => {
    dispatch(OrderCreators.getOrderListRequest(localState));
  }, [localState]);

  const onDeleteRequest = item => {
    setDeleteState({ open: true, item });
  };

  const onDeleteConfirm = () => {
    dispatch(OrderCreators.getOrderDeleteRequest(deleteState.item.id));
  };

  React.useEffect(() => {
    const socket = io(baseSocket);
    socket.on('connect', () => {});
    socket.on(`store:${store && store.code_internal}:new_order`, res => {
      dispatch(OrderCreators.getOrderListWSSuccess({ ...res, view: true }));
      if (SoundRef.current) {
        SoundRef.current.play();
      }
      dispatch(
        NotificationCreators.openNotification({
          message: `Novo pedido recebido (id: ${res.id})`,
          type: 'info',
        })
      );
    });
    if (store && store.id) {
      SoundRef.current.stop();
      socket.on(`store:${store.code_internal}:order:changed`, res => {
        dispatch(OrderCreators.getOrderWSStatusUpdated(res));
      });
    }
  }, [store]);

  const dataPrev = _.orderBy(orderList, ['id'], ['desc']);
  const newData = _.uniqBy(dataPrev, 'id');

  return (
    <Stack>
      <HeaderComponent title="Minhas Vendas" />
      <OrderTableHeader
        getFunction={getFunction}
        initialValues={{
          search: localState.search,
          dateStart: weekdate,
          dateEnd: actualDate,
        }}
      />

      <Box style={{ marginTop: 15, marginBottom: 15 }}>
        <Typography
          style={{ fontWeight: 'bold', color: appColors.PRIMARY_COLOR }}>
          RELATÓRIO DA BUSCA
        </Typography>
      </Box>

      <Stack direction="row" align="center" justify="space-between">
        <Box>
          <Typography style={{ fontWeight: 'bolder' }}>PEDIDOS</Typography>
          <Typography
            data-cy="box_total_orders"
            style={{ fontWeight: 'bold', fontSize: 45, color: 'gray' }}>
            {orderSummary.total == undefined ? 0 : orderSummary.total}
            <span style={{ color: appColors.PRIMARY_COLOR }}></span>
          </Typography>
        </Box>

        <Box>
          <Typography style={{ fontWeight: 'bolder' }}>VALOR TOTAL</Typography>
          <Typography
            data-cy="box_value_total_orders"
            style={{ fontWeight: 'bold', fontSize: 45, color: 'gray' }}>
            <span
              style={{
                fontSize: 30,
                color: appColors.PRIMARY_COLOR,
              }}></span>
            {orderSummary.price == undefined
              ? appUtils.formatPrice('000')
              : appUtils.formatPrice(orderSummary.price)}
          </Typography>
        </Box>
      </Stack>

      <Paper>
        <DefaultTable
          getFunction={getFunction}
          columns={columns({ onDeleteRequest })}
          data={newData}
          total={orderListTotal}
          isLoading={orderListLoading}
          page={localState.page}
          perPage={localState.perPage}
        />
      </Paper>
      <AlertDialog
        isOpen={deleteState.open}
        isLoading={orderListLoading}
        handleClose={handleAlertDialogClose}
        onConfirm={onDeleteConfirm}
        title="Excluir registro?"
        description={`Remover categoria: ${deleteState.item.name}`}
      />
      <SoundComponent ref={SoundRef} loop={true} />
      <PrintLoad />
    </Stack>
  );
};

export default OrderListPage;
