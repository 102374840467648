import React, { useState } from 'react';
import { Creators as CheckoutCartCreators } from 'store/ducks/checkout/cart';
import { Creators as CheckoutAddressCreators } from 'store/ducks/checkout/sharedAddress';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import api from 'services/api';
import { Button } from '@material-ui/core';
import appColors from 'utils/appColors';

const ShoppingCart = ({ match }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id = null } = match.params;
  const history = useHistory();
  const linkRef = React.useRef(null);
  const dispatch = useDispatch();

  React.useEffect(() => {
    api
      .get(`/v1/client/budget/${id}`)
      .then(response => {
        if (
          !response.data ||
          !response.data.data ||
          !response.data.data.products
        ) {
          console.error('Código expirou ou não existe');
          setError(true);
          return;
        }

        const { products, manpower } = response.data.data;
        const tempManPower = manpower.map(item => ({
          ...item,
          budget_hash: id,
          budget_id: response.data.data.id,
        }));
        const tempCart = products.map(item => ({
          ...item,
          budget_hash: id,
          budget_id: response.data.data.id,
          color: item.variation.color,
          size: item.variation.size,
          image: item.images.length > 0 ? item.images[0].sizes.medium : '',
        }));

        dispatch(CheckoutCartCreators.addItemToCartSucess(tempCart));
        dispatch(
          CheckoutCartCreators.addBudgetHashInCart(
            response?.data?.data?.budget_hash
          )
        );
        dispatch(CheckoutCartCreators.addManpowerToCartSucess(tempManPower));

        if (response.data.data.addresses !== undefined) {
          dispatch(
            CheckoutAddressCreators.addAddressToCartSucess(
              response.data.data.addresses
            )
          );
        }

        setLoading(false);
        history.push({
          state: { shared: id, budget_hash: response?.data?.data?.budget_hash },
          pathname: '/carrinho',
        });
      })
      .catch(error => {
        console.error('Erro ao carregar carrinho', error);
        setError(true);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkRef]);

  return (
    <div
      style={{
        height: '50vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}>
      {loading && !error && <p>Carregando carrinho...</p>}
      {error && (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <p>Erro ao carregar carrinho. Código expirou ou não existe.</p>
          <Button
            style={{
              backgroundColor: appColors.PRIMARY_COLOR,
              color: 'white',
              padding: '10px 20px',
              marginTop: '10px',
            }}
            onClick={() => history.push('/')}>
            Voltar para Home
          </Button>
        </div>
      )}
    </div>
  );
};

export default ShoppingCart;
