import appColors from "utils/appColors";

const styles = {
  container: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: appColors.LIGHT_COLOR2,
    borderRadius: 10,
    shadowColor: '#000',
    alignItems: 'center',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 5,
    shadowRadius: 5,
    elevation: 5,
  },
  icon: {
    flex: 1,
    fontSize: 80,
    color: appColors.PRIMARY_COLOR,
  },
  texto: {
    flex: 1,
    marginLeft: 20,
    marginVertical: 5,
  },
};

export default styles;
