import React from 'react';
import Butiri from './components/buriti';
import Oferta from './components/oferta';
import appTheme from 'utils/appTheme';

const headers = {
  Buriti: Butiri,
  Oferta: Oferta,
};

export default function InfoPage(props) {
  const InfoPageDetails = headers[appTheme.name] || Oferta;
  return (
    <InfoPageDetails {...props} />
  );
}

