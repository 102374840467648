import styled from 'styled-components'
import { Box } from '@material-ui/core';
import appColors from 'utils/appColors';

export const LinkSimulator = styled.span`
&& {
    color: ${appColors.PRIMARY_COLOR};
    &:hover {
        color:blue;
        text-decoration: underline;
        cursor:pointer;
    }
}
`;

export const BoxLoginContainer = styled(Box)`
  && {
    padding-left: 40px;
    padding-right: 40px; 
    

  }

`;

const styles = {
  purpleTitle: {
    color:appColors.SECUNDARY_COLOR,
    fontSize:22,
  }

}

export default styles;