import styled from 'styled-components';
import { Box } from '@material-ui/core';
import appColors from 'utils/appColors';

export const StoreDetailContainer = styled(Box)`
  && {
    display: flex;
    width: 100%;
    margin-top: 10px;
    margin: 0 auto; /* Centraliza horizontalmente */
    align-items: center; /* Centraliza verticalmente */

    @media all and (max-width: 1250px) {
      width: 90%;
    }

    @media all and (max-width: 500px) {
      width: 100%;
    }
  }
`;

export const BoxImage = styled(Box)`
  && {
    max-height: 500px;

    @media all and (max-width: 500px) {
      height: 400px;
      display: flex;
      justify-content: center; /* Centraliza horizontalmente */
      align-items: center; /* Centraliza verticalmente */
      flex-direction: column;
    }
  }
`;

export const StyledBoxDetail = styled(Box)`
  && {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid ${appColors.PRIMARY_COLOR};
    justify-content: center; /* Centraliza horizontalmente */

    @media all and (max-width: 500px) {
      margin-left: -5%;
      margin-right: -5%;
      display: flex;
      justify-content: center; /* Centraliza horizontalmente */
      align-items: center; /* Centraliza verticalmente */
      flex-direction: column;
    }
  }
`;

export const LineSeparator = styled(Box)`
  && {
    border-top: 1px solid ${appColors.PRIMARY_COLOR};
    margin-top: 20px;
    padding-bottom: 20px;
  }
`;
