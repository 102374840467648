/*
 *
 * Report reducer
 *
 */

export const Types = {
  // Transferir um valor
  GET_TRANSFER_REQUEST: 'transfer/GET_TRANSFER_REQUEST',
  GET_TRANSFER_SUCCESS: 'transfer/GET_TRANSFER_SUCCESS',
  GET_TRANSFER_FAILURE: 'transfer/GET_TRANSFER_FAILURE',
};

export const initialState = {
  // Transferir um valor
  transfer: {},
  transferLoading: false,
  transferError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Transferir um valor
    case Types.GET_TRANSFER_REQUEST:
      return {
        ...state,
        transfer: {},
        transferLoading: true,
        transferError: null,
      };
    case Types.GET_TRANSFER_SUCCESS:
      return {
        ...state,
        transfer: action.payload.data,
        transferLoading: false,
        transferError: null,
      };
    case Types.GET_TRANSFER_FAILURE:
      return {
        ...state,
        transferLoading: false,
        transferError: action.payload,
      };
    default:
      return state;
  }
};

export const Creators = {
  // Transferir um valor
  getTRANSFERRequest: (data, onCloseModal) => ({
    type: Types.GET_TRANSFER_REQUEST,
    payload: { data, onCloseModal },
  }),
  getTRANSFERSuccess: ({ data }) => ({
    type: Types.GET_TRANSFER_SUCCESS,
    payload: { data },
  }),
  getTRANSFERFailure: error => ({
    type: Types.GET_TRANSFER_FAILURE,
    payload: error,
  }),
};
