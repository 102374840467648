import { call, takeLatest, all, put, select } from 'redux-saga/effects';
import api from 'services/api';
import { Types, Creators } from 'store/ducks/address';
import { callApi, setApiToken } from 'store/sagas/auth';
import { Creators as NotificationCreators } from 'store/ducks/app';
import { REHYDRATE } from 'redux-persist';

// Teste

function* getAddress() {
  try {
    const request = call(api.get, `/v1/address`);
    const response = yield call(callApi, request);
    yield put(Creators.getAddressSuccess(response.data));
  } catch (err) {
    yield put(Creators.getAddressFailure('Erro ao buscar na API'));
  }
}

function* getCep({ payload }) {
  try {
    const { code_post } = payload;
    const request = call(api.get, `/v1/client/address/validate`, {
      code_post,
    });
    const response = yield call(callApi, request);
    if (response.status !== 200 && response.status !== 201) throw response;
    yield put(Creators.getAddressCEPSuccess(response.data));
  } catch (err) {
    yield put(Creators.getAddressCEPFailure(err.data.msg));
  }
}

function* getAddressInsert({ payload, changeModal }) {
  try {
    const { code_post, street, number, district, complement, city, state } =
      payload;
    const request = call(api.post, '/v1/client/address', {
      code_post,
      street,
      number,
      district,
      complement,
      city,
      state,
    });
    const response = yield call(callApi, request);
    yield put(Creators.getAddressInsertSuccess(response.data));
    changeModal();
    yield put(
      NotificationCreators.openNotification({
        message: 'Cadastro concluido com sucesso',
        type: 'success',
      })
    );
  } catch (err) {
    yield put(
      Creators.getAddressInsertFailure('Erro ao tentar inserir na API')
    );
  }
}

function* getAddressUpdate({ payload, id }) {
  try {
    const { code_post, street, number, district, complement, city, state } =
      payload;
    const request = call(api.put, `/v1/client/address/${id}`, {
      code_post,
      street,
      number,
      district,
      complement,
      city,
      state,
    });
    const response = yield call(callApi, request);
    yield put(Creators.getAddressUpdateSuccess(response.data));
    yield put(
      NotificationCreators.openNotification({
        message: 'Edição concluida com sucesso',
        type: 'success',
      })
    );
  } catch (err) {
    yield put(Creators.getAddressUpdateFailure('Erro ao buscar na API'));
  }
}

function* getAddressDelete({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(api.delete, `/v1/address/${id}`);
    yield put(Creators.getAddressDeleteSuccess());
    console.log(response);
    // Remove a categoria deletada da lista
    const { addressList, addressListTotal } = yield select(
      state => state.address
    );

    yield put(
      Creators.getAddressListSuccess({
        data: addressList.filter(doc => doc.id !== id),
        total: addressListTotal - 1,
      })
    );
  } catch (err) {
    yield put(Creators.getAddressDeleteFailure('Erro ao buscar na API'));
  }
}

function* getAddressList({ payload }) {
  try {
    const { page, perPage, search, orderByColumn, orderByDirection } = payload;
    const request = call(api.get, '/v1/client/address', {
      page,
      search,
      per_page: perPage,
      order: orderByColumn,
      order_by: orderByDirection,
    });
    const response = yield call(callApi, request);
    yield put(Creators.getAddressListSuccess(response.data));
  } catch (err) {
    yield put(Creators.getAddressListFailure('Erro ao buscar na API'));
  }
}

// Individual exports for testing
export default function* addressSaga() {
  yield takeLatest(REHYDRATE, setApiToken);
  yield all([
    takeLatest(Types.GET_REQUEST, getAddress),
    takeLatest(Types.GET_INSERT_REQUEST, getAddressInsert),
    takeLatest(Types.GET_UPDATE_REQUEST, getAddressUpdate),
    takeLatest(Types.GET_DELETE_REQUEST, getAddressDelete),
    takeLatest(Types.GET_LIST_REQUEST, getAddressList),
    takeLatest(Types.GET_CEP_REQUEST, getCep),
  ]);
}
