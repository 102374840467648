export function useHistoryPage() {
  const makeHistory = url => {
    localStorage.setItem('@history', url);
  };

  const clearHistory = () => {
    localStorage.removeItem('@history');
  };

  return {
    makeHistory,
    history: localStorage.getItem('@history'),
    clearHistory,
  };
}
