/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from 'react';
import { Formik, FastField, Form, Field } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Typography, Icon, FormControlLabel } from '@material-ui/core';
import { Tab, Tabs } from 'components/Layout/Tabs';
import { InputContainer, InputItem } from 'components/form/StyledComponents';
import CustomTextField from 'components/form/components/CustomTextField';
import CustomSelect from 'components/form/components/CustomSelect';
import FormButtons from 'components/form/components/FormButtons';
import CustomRichText from 'components/form/components/CustomRichText';
import { useDispatch, useSelector } from 'react-redux';
// import { Creators as BankCreators } from 'store/ducks/admin/bank';
import { Creators as AddressCreators } from 'store/ducks/admin/address';
import CustomMaskField from 'components/form/CustomMaskField';
import { validateBr } from 'js-brasil';
import validators from 'utils/validators';
import CustomImageField from 'components/form/components/CustomImageField';
import { formatCityName } from 'utils/converters';
import { BoxStyled } from 'components/Pages/MyAccountPage/PainelAccount/TabsPanel/ProductsTab/components/ProductForm/styles';
import appColors from 'utils/appColors';
import { appTabs } from 'components/Pages/MyAccountPage/PainelAccount/TabPanelShopkeeper';
import { Creators as PanelTabsCreators } from 'store/ducks/panelTabs';
import { MapBox } from './styles';
import GoogleMapReact from 'google-map-react';
import Geocode from 'react-geocode';
// import CustomCurrencyField from 'components/form/components/CustomCurrencyField';
import { Switch } from '@material-ui/core';
import { useState } from 'react';
import { key as googleMapsKey } from 'services/maps';
import Segments from './Segments';
import { key as googleMapskey } from './../../../services/maps';
import CustomMaskField2 from 'components/form/components/CustomMaskField2';

import { Stack } from '@chakra-ui/react';

const AnyReactComponent = ({ text }) => (
  <div style={{ fontWeight: 'bold', color: 'red', fontSize: '14px' }}>
    {text}
  </div>
);

const TabContainer = ({ children }) => {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
};

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export const formInitialValues = {
  id: '',
  name: '',
  email: '',
  enable_automatic_withdrawal: '',
  transfer_interval: '',
  transfer_day: '',
  minimum_transfer_value: '',
  description: '',
  cnpj: '',
  social_name: '',
  quantity_min_whole: '',
  cell_phone: '',
  segment: '',
  website: '',
  status: 0,
  address: {
    code_post: '',
    street: '',
    number: '',
    district: '',
    complement: '',
    city: {
      id: '',
    },
    mim_time_for_preparation: 0,
  },
  manager: {
    name: '',
    father_name: '',
    mother_name: '',
    email: '',
    cpf: '',
    rg_number: '',
    rg_issuer: '',
    rg_issuer_date: '',
    cell_phone: '',
    birth_date: '',
    code_post: '',
    street: '',
    number: '',
    district: '',
    complement: '',
    genre: '',
    city: {
      id: '',
    },
  },
  // bank: {
  //   bank: {
  //     id: '',
  //   },
  //   agency: '',
  //   agency_check: '',
  //   account: '',
  //   account_check: '',
  //   type: '',
  //   doc_type: 'CPF',
  //   doc_number: '',
  //   account_holder: '',
  // },
  payment: {
    card: {
      number: '',
      validate: '',
      cvc: '',
      holder: {
        name: '',
        birth_date: '',
        cpf: '',
        observation: '',
      },
    },
  },
};

const StoreForm = ({
  onSubmit,
  store,
  segments,
  initialValues = formInitialValues,
  submitText,
  isLoading,
}) => {
  const dispatch = useDispatch();

  const cepTypes = {
    STORE: 0,
    MANAGER: 1,
  };

  const [value, setValue] = React.useState(0);

  const [cepType, setCepType] = React.useState(cepTypes.STORE);

  const [formikForm, setFormikForm] = React.useState({});

  const [cityStoreInfo, setCityStoreInfo] = React.useState({
    cityList: [],
    cityListLoading: true,
  });

  const [cityManagerInfo, setCityManagerInfo] = React.useState({
    cityList: [],
    cityListLoading: true,
  });

  const formikRef = useRef();

  React.useEffect(() => {
    setFormikForm(formikRef.current);
  }, [formikRef]);

  // const { bankList, bankListLoading } = useSelector(state => state.admin.bank);
  const { addressValidate, addressValidateLoading } = useSelector(
    state => state.admin.address
  );

  const [storeLocation, setStoreLocation] = React.useState({
    lat: initialValues.lat || -16.742394,
    lng: initialValues.long || -49.277259,
  });

  const getInitialData = () => {
    // dispatch(BankCreators.getBankListRequest({ perPage: 1000 }));
    if (initialValues.address && initialValues.address.code_post) {
      dispatch(
        AddressCreators.getAddressValidateRequest({
          code_post: initialValues.address.code_post,
        })
      );
    } else {
      setCityStoreInfo({
        cityList: [],
        cityListLoading: false,
      });
    }

    if (!initialValues.manager || !initialValues.manager.code_post) {
      setCityManagerInfo({
        cityList: [],
        cityListLoading: false,
      });
    }
  };

  React.useEffect(() => {
    getInitialData();
  }, []);

  React.useEffect(() => {
    if (
      !addressValidateLoading &&
      formikForm.setFieldValue &&
      addressValidate
    ) {
      if (cepType === cepTypes.STORE) {
        formikForm.setFieldValue('address.street', addressValidate.street);
        formikForm.setFieldValue('address.district', addressValidate.district);
        formikForm.setFieldValue('address.city.id', addressValidate.city.id);

        setCityStoreInfo({
          cityList: [
            {
              ...addressValidate.city,
            },
          ],
          cityListLoading: false,
        });

        if (
          initialValues.manager &&
          initialValues.manager.code_post &&
          cityManagerInfo.cityList.length === 0
        ) {
          setCepType(cepTypes.MANAGER);
          dispatch(
            AddressCreators.getAddressValidateRequest({
              code_post: initialValues.manager.code_post,
            })
          );
        }
      } else if (cepType === cepTypes.MANAGER) {
        formikForm.setFieldValue('manager.street', addressValidate.street);
        formikForm.setFieldValue('manager.district', addressValidate.district);
        formikForm.setFieldValue('manager.city.id', addressValidate.city.id);

        setCityManagerInfo({
          cityList: [
            {
              ...addressValidate.city,
            },
          ],
          cityListLoading: false,
        });
      }
    } else if (
      addressValidateLoading === false &&
      formikForm.setFieldValue &&
      typeof addressValidate === 'undefined'
    ) {
      if (cepType === cepTypes.STORE) {
        formikForm.setFieldValue('address.street', '');
        formikForm.setFieldValue('address.district', '');
        formikForm.setFieldValue('address.city.id', '');
        setCityStoreInfo({
          cityList: [],
          cityListLoading: false,
        });
      } else if (cepType === cepTypes.MANAGER) {
        formikForm.setFieldValue('manager.street', '');
        formikForm.setFieldValue('manager.district', '');
        formikForm.setFieldValue('manager.city.id', '');
        setCityManagerInfo({
          cityList: [],
          cityListLoading: false,
        });
      }
    }
  }, [addressValidate]);

  const onCepChange = curCepType => fieldValue => {
    setCepType(curCepType);
    if (fieldValue.length === 9) {
      dispatch(
        AddressCreators.getAddressValidateRequest({ code_post: fieldValue })
      );
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleBack = () => {
    dispatch(PanelTabsCreators.changeTab(appTabs.panelClient));
  };

  const [fields, setFields] = React.useState('');
  const [iconAba, setIconAba] = React.useState({
    icon1: true,
  });

  // const [isAutomatic, setIsAutomatic] = React.useState(
  //   store.enable_automatic_withdrawal === true
  // );

  // const handleSetAutomatic = status => {
  //   formikForm.setFieldValue('enable_automatic_withdrawal', status);
  //   setIsAutomatic(status);
  // };

  const [resellerEnbale, setResellerEnable] = useState(
    store.show_products_reseller_catalog || false
  );
  const handleEnableReseller = status => {
    formikForm.setFieldValue('show_products_reseller_catalog', status);
    setResellerEnable(status);
  };

  React.useEffect(() => {
    var firstIcon = true;
    if (
      fields === 'name' ||
      fields === 'social_name' ||
      fields === 'cell_phone' ||
      fields === 'cnpj' ||
      fields === 'email' ||
      fields === 'address.code_post' ||
      fields === 'address.street' ||
      fields === 'address.number' ||
      fields === 'address.complement' ||
      fields === 'address.district' ||
      fields === 'address.city.id' ||
      fields === 'mim_time_for_preparation'
    ) {
      firstIcon = false;
    }
    setIconAba({ icon1: firstIcon });
  }, [fields]);

  function verifyField(error) {
    setFields(error.path);
    return 'Campo obrigatório';
  }

  const schema = Yup.object().shape({
    id: Yup.number(),
    mim_time_for_preparation: Yup.number().nullable(),
    name: Yup.string().required(verifyField),
    email: Yup.string().email('E-mail inválido').required(verifyField),
    cnpj: Yup.string()
      .test('cnpj', 'CNPJ inválido', val =>
        val === undefined ? false : validateBr.cnpj(val)
      )
      .required(verifyField),
    social_name: Yup.string().required(verifyField),
    quantity_min_whole: Yup.string(),
    cell_phone: Yup.string().required(verifyField),
    status: Yup.number().required(verifyField),
    address: Yup.object().shape({
      code_post: Yup.string()
        .test('cep', 'CEP inválido', val =>
          val === undefined ? false : validateBr.cep(val)
        )
        .required(verifyField),
      street: Yup.string().required(verifyField),
      number: Yup.string().required(verifyField),
      district: Yup.string().required(verifyField),
      complement: Yup.string(),
      city: Yup.object().shape({
        id: Yup.string()
          .test(...validators.numberNotRequired())
          .required(verifyField),
      }),
    }),
    enable_automatic_withdrawal: Yup.boolean(),
    transfer_interval: Yup.string().nullable(),
    minimum_transfer_value: Yup.number()
      .test(
        'minimum_transfer_value',
        'O valor deve ser maior que R$ 1,00',
        val => val >= 1
      )
      .nullable(),
    transfer_day: Yup.number().nullable(),
    segments: Yup.array().min(1).required('Campo obrigatório'),
    // bank: Yup.object().shape({
    //   bank: Yup.object().shape({
    //     id: Yup.string().test(...validators.numberNotRequired()),
    //   }),
    //   agency: Yup.string().test(...validators.numberNotRequired()),
    //   agency_check: Yup.string().test(
    //     ...validators.numberNotRequired('Apenas numeros')
    //   ),
    //   account: Yup.string().test(...validators.numberNotRequired()),
    //   account_check: Yup.string().test(...validators.numberNotRequired()),
    //   type: Yup.string(),
    //   doc_type: Yup.string(),
    //   doc_number: Yup.string(),
    //   account_holder: Yup.string(),
    // }),
  });

  React.useEffect(() => {
    if (addressValidate.code_post < 9) return;
    const { street, district, number, city, code_post } = addressValidate;
    if (!city) return;
    Geocode.setApiKey(googleMapskey);
    Geocode.enableDebug();
    Geocode.setLanguage('br');
    Geocode.setRegion('br');
    Geocode.fromAddress(
      `${street} ${number}, ${district}, ${city.name || ''} - ${code_post}`
    ).then(
      response => {
        const { location } = response.results[0].geometry;
        setStoreLocation(old => ({ ...old, open: false, ...location }));
        setTimeout(
          () => setStoreLocation(old => ({ ...old, open: true })),
          1000
        );
      },
      () => {
        //
      }
    );
  }, [addressValidate]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      enableReinitialize
      ref={formikRef}
      render={({ values }) => (
        <Form>
          <BoxStyled
            style={{
              marginBottom: 20,
              backgroundColor: appColors.LIGHT_COLOR,
              borderRadius: 10,
            }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab
                label="LOJA"
                icon={
                  <Icon>
                    {iconAba.icon1 === true ? 'store_mall_directory' : 'error'}
                  </Icon>
                }
              />
              {/* <Tab
                data-cy="tab_attach_money"
                label="INFORMAÇÕES BANCÁRIAS"
                icon={<Icon>attach_money</Icon>}
              /> */}

              <Tab
                data-cy="tab_description"
                label="QUEM SOMOS "
                icon={<Icon>text_format</Icon>}
              />
            </Tabs>
          </BoxStyled>
          {value === 0 && (
            <TabContainer>
              <InputContainer>
                <InputItem>
                  <Typography variant="h6">Logomarca da loja</Typography>
                  <FastField
                    data-cy="btn_image_store"
                    name="image"
                    label="Imagem"
                    component={CustomImageField}
                    previewUrl={
                      values.image_info && values.image_info.small
                        ? store.image.small
                        : 'image.png'
                    }
                  />
                </InputItem>
                <InputItem>
                  <InputContainer>
                    <InputItem>
                      <Typography variant="h6">Dados Básico</Typography>
                    </InputItem>
                  </InputContainer>
                  <InputContainer></InputContainer>
                  <InputContainer>
                    <InputItem>
                      <FastField
                        isClient={true}
                        name="name"
                        id="inputNomeLoja"
                        label="Nome da loja"
                        component={CustomTextField}
                      />
                    </InputItem>
                  </InputContainer>
                  <InputContainer>
                    <InputItem>
                      <FastField
                        name="social_name"
                        id="inputRazaoSocial"
                        label="Razão Social"
                        component={CustomTextField}
                      />
                    </InputItem>
                    <InputItem>
                      <FastField
                        name="cell_phone"
                        label="Telefone"
                        mask="(99) 99999-9999"
                        component={CustomMaskField}
                      />
                    </InputItem>
                  </InputContainer>
                  <InputContainer>
                    <InputItem>
                      <FastField
                        name="cnpj"
                        label="CNPJ"
                        mask="99.999.999/9999-99"
                        component={CustomMaskField}
                      />
                    </InputItem>
                    <InputItem>
                      <FastField
                        name="email"
                        id="inputEmailLoja"
                        label="E-mail"
                        component={CustomTextField}
                      />
                    </InputItem>
                  </InputContainer>
                  <InputContainer>
                    <InputItem>
                      <Field
                        name="company_type"
                        options={[
                          { id: 'MEI', name: 'Micro Empreendedor Individual' },
                          { id: 'LIMITED', name: 'Empresa Limitada' },
                          { id: 'INDIVIDUAL', name: 'Empresa Individual' },
                          { id: 'ASSOCIATION', name: 'Associação' },
                        ]}
                        label="Tipo da Compania"
                        placeholder="Tipo da Compania"
                        component={CustomSelect}
                        isLoading={false}
                      />
                    </InputItem>
                  </InputContainer>
                  <InputContainer>
                    <InputItem>
                      <Typography variant="h6">
                        Revendedor pode gerar catálogo?
                      </Typography>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={!!resellerEnbale}
                            onChange={() =>
                              handleEnableReseller(!resellerEnbale)
                            }
                            data-cy="show_products_reseller_catalog"
                            name="show_products_reseller_catalog"
                            color="primary"
                          />
                        }
                        label="Permite que revendedores criem catálogos com seus produtos"
                      />
                    </InputItem>
                  </InputContainer>
                </InputItem>
              </InputContainer>

              <InputContainer>
                <InputItem>
                  <Field
                    name="segments"
                    segments={segments || []}
                    label="Segmentos da loja"
                    component={Segments}
                  />
                </InputItem>
              </InputContainer>

              <InputContainer>
                <InputItem>
                  <FastField
                    name="website"
                    label="Website"
                    component={CustomTextField}
                  />
                </InputItem>
              </InputContainer>
              <InputContainer>
                <InputItem>
                  <Typography variant="h6">Endereço</Typography>
                </InputItem>
              </InputContainer>

              <Stack direction="row">
                <InputItem>
                  <InputContainer>
                    <InputItem>
                      <FastField
                        name="address.code_post"
                        label="CEP"
                        component={CustomMaskField2}
                        mask="99999-999"
                        onKeyUp={onCepChange(cepTypes.STORE)}
                      />
                    </InputItem>
                  </InputContainer>
                  <InputContainer>
                    <InputItem>
                      <FastField
                        name="address.street"
                        label="Logradouro"
                        component={CustomTextField}
                      />
                    </InputItem>
                  </InputContainer>
                  <InputContainer>
                    <InputItem>
                      <FastField
                        name="address.number"
                        label="Número"
                        component={CustomTextField}
                      />
                    </InputItem>
                  </InputContainer>
                  <InputContainer>
                    <InputItem>
                      <FastField
                        name="address.complement"
                        label="Complemento"
                        component={CustomTextField}
                      />
                    </InputItem>
                  </InputContainer>
                  <InputContainer>
                    <InputItem>
                      <FastField
                        name="address.district"
                        label="Bairro"
                        component={CustomTextField}
                      />
                    </InputItem>
                  </InputContainer>
                  <InputContainer>
                    <InputItem style={{ pointerEvents: 'none' }}>
                      <Field
                        name="address.city.id"
                        label="Cidade"
                        options={formatCityName(cityStoreInfo.cityList)}
                        component={CustomSelect}
                        placeholder="Cidade"
                        isLoading={cityStoreInfo.cityListLoading}
                      />
                    </InputItem>
                  </InputContainer>
                </InputItem>
                <Stack display={['none', 'initial']} w="400px">
                  <MapBox>
                    {storeLocation.open && (
                      <GoogleMapReact
                        bootstrapURLKeys={{ key: googleMapsKey }}
                        defaultCenter={storeLocation}
                        defaultZoom={15}>
                        <AnyReactComponent
                          lat={storeLocation.lat}
                          lng={storeLocation.lng}
                          text={storeLocation.name || ''}
                        />
                      </GoogleMapReact>
                    )}
                  </MapBox>
                </Stack>
              </Stack>
              <InputItem style={{ display: 'none' }}>
                <FastField
                  name="quantity_min_whole"
                  label="Quantidade mínima para atacado da loja"
                  type="number"
                  component={CustomTextField}
                />
              </InputItem>
              <InputContainer>
                <InputItem>
                  <Typography variant="h6">Parcelamento</Typography>
                </InputItem>
              </InputContainer>
              <InputContainer>
                <InputItem data-cy="input_installment">
                  <Field
                    name="max_installment_number"
                    label="Máximo de parcelamento"
                    options={[
                      { id: 1, name: '1' },
                      { id: 2, name: '2' },
                      { id: 3, name: '3' },
                      { id: 4, name: '4' },
                      { id: 5, name: '5' },
                      { id: 6, name: '6' },
                    ]}
                    component={CustomSelect}
                    placeholder="Máximo de parcelamento"
                    isLoading={false}
                  />
                </InputItem>
              </InputContainer>
              <InputItem>
                <Typography>
                  Este parcelamento é apenas para produtos, para comidas apenas
                  pagamento â vista.
                </Typography>
              </InputItem>
              <InputContainer>
                <InputItem>
                  <Typography variant="h6">Tempo médio de preparo</Typography>
                </InputItem>
              </InputContainer>
              <InputContainer>
                <InputItem>
                  <FastField
                    name="mim_time_for_preparation"
                    label="Tempo médio de preparo"
                    component={CustomMaskField2}
                    mask="999"
                    endAdornment="Minutos"
                  />
                </InputItem>
              </InputContainer>
            </TabContainer>
          )}

          {/* {value === 1 && (
            <TabContainer>
              <InputContainer>
                <InputItem data-cy="input_bank_id">
                  <Field
                    name="bank.bank.id"
                    label="Banco"
                    options={formatBankName(bankList)}
                    component={CustomSelect}
                    placeholder="Banco"
                    isLoading={bankListLoading}
                  />
                </InputItem>
                <InputItem>
                  <FastField
                    name="bank.agency"
                    label="Número da agência"
                    type="number"
                    component={CustomTextField}
                  />
                </InputItem>
                <InputItem>
                  <FastField
                    name="bank.agency_check"
                    label="Dígito da agência"
                    component={CustomTextField}
                  />
                </InputItem>
              </InputContainer>
              <InputContainer>
                <InputItem>
                  <FastField
                    name="bank.account"
                    label="Número da Conta"
                    type="number"
                    component={CustomTextField}
                  />
                </InputItem>
                <InputItem>
                  <FastField
                    name="bank.account_check"
                    label="Dígito da Conta"
                    type="number"
                    component={CustomTextField}
                  />
                </InputItem>
                <InputItem data-cy="bank_type">
                  <FastField
                    name="bank.type"
                    label="Tipo de conta"
                    options={[
                      { id: 'Corrente', name: 'Corrente' },
                      { id: 'Poupança', name: 'Poupança' },
                    ]}
                    component={CustomSelect}
                    placeholder="Tipo de conta"
                    isLoading={false}
                  />
                </InputItem>
              </InputContainer>
              <InputContainer>
                <InputItem>
                  <FastField
                    name="bank.account_holder"
                    label="Titular da conta"
                    component={CustomTextField}
                  />
                </InputItem>
              </InputContainer>
              <InputContainer>
                <InputItem data-cy="input_bank_doc_type">
                  <FastField
                    name="bank.doc_type"
                    label="Tipo de conta"
                    options={[
                      { id: 'CPF', name: 'CPF' },
                      { id: 'CNPJ', name: 'CNPJ' },
                    ]}
                    placeholder="Tipo de conta"
                    component={CustomSelect}
                    isLoading={false}
                  />
                </InputItem>
                <InputItem>
                  <Field
                    name="bank.doc_number"
                    label="Número do documento"
                    component={CustomMaskField}
                    mask={
                      values?.bank?.doc_type === 'CPF'
                        ? '999.999.999-99'
                        : '99.999.999/9999-99'
                    }
                  />
                </InputItem>
              </InputContainer>
              <InputContainer>
                <InputItem>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!!isAutomatic}
                        onChange={() => handleSetAutomatic(!isAutomatic)}
                        name="enable_automatic_withdrawal"
                        color="primary"
                      />
                    }
                    label="Saque automático?"
                  />
                  <section style={{ color: '#666', marginTop: '5px' }}>
                    <strong style={{ width: '100%' }}>Observações: </strong>
                    Esta função programa o saque automático na conta bancária,
                    caso queira configurar os intervalos, clique no botão salvar
                    também!
                  </section>
                </InputItem>
              </InputContainer>
              {isAutomatic && (
                <InputContainer>
                  <InputItem>
                    <FastField
                      name="transfer_interval"
                      label="Frequência de Transferência"
                      options={[
                        { id: 'daily', name: 'Todos os dias' },
                        { id: 'weekly', name: 'Toda semana' },
                        { id: 'monthly', name: 'Uma vez ao mês' },
                      ]}
                      placeholder="Intervalo de Transferência"
                      component={CustomSelect}
                      isLoading={false}
                    />
                  </InputItem>
                  <InputItem>
                    <FastField
                      name="minimum_transfer_value"
                      label="Valor Mínimo"
                      component={CustomCurrencyField}
                    />
                  </InputItem>
                  {values.transfer_interval === 'monthly' && (
                    <InputItem>
                      <FastField
                        name="transfer_day"
                        label="Dia do mês para transferência"
                        type="number"
                        component={CustomTextField}
                      />
                    </InputItem>
                  )}
                </InputContainer>
              )}
            </TabContainer>
          )} */}

          {value === 1 && (
            <TabContainer>
              <InputContainer>
                <InputItem data-cy="text_description" style={{ width: '50%' }}>
                  <Field
                    name="description"
                    label="Descrição"
                    component={CustomRichText}
                  />
                </InputItem>
              </InputContainer>
            </TabContainer>
          )}
          <FormButtons
            handleBack={handleBack}
            isLoading={isLoading}
            submitText={submitText}
          />
        </Form>
      )}
    />
  );
};

StoreForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.oneOfType([PropTypes.object]),
  submitText: PropTypes.string,
  handleBack: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isLoading: PropTypes.bool.isRequired,
  stateImages: PropTypes.any.isRequired,
  setStateImages: PropTypes.func.isRequired,
};

StoreForm.defaultProps = {
  initialValues: formInitialValues,
  submitText: 'Salvar',
  handleBack: false,
  onSubmit: () => {},
};

export default StoreForm;
