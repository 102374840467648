import React, { useEffect } from 'react';
import CustomCurrencyField from 'components/form/components/CustomCurrencyField';
import CustomSelect from 'components/form/CustomSelect';
import CustomTextField from 'components/form/CustomTextField';

import { InputView, InputItem } from './styles';
import Accordion from '@material-ui/core/Accordion';

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { FastField, Field } from 'formik';
import { useSelector } from 'react-redux';
import AutoCompleteSelect from 'components/form/components/CustomSearchSelect/AutoCompleteSelect';
import useProduct from 'hooks/products';

//imagens

import img1 from './img/caixaGrande.PNG';
import img2 from './img/caixaMedia.PNG';
import img3 from './img/caixaSuper.PNG';
import img4 from './img/Comprida.PNG';
import img5 from './img/pequena.PNG';
import appColors from 'utils/appColors';

const bags = [
  {
    id: 10,
    width: '32',
    height: '28',
    length: '48',
    weight: 4000,
    name: 'Caixa Super',
    img: img3,
  },
  {
    id: 11,
    width: '24',
    height: '20',
    length: '32',
    weight: 3000,
    name: 'Caixa Grande',
    img: img1,
  },
  {
    id: 12,
    width: '12',
    height: '12',
    length: '32',
    weight: 2000,
    name: 'Caixa Comprida',
    img: img4,
  },
  {
    id: 13,
    width: '16',
    height: '12',
    length: '24',
    name: 'Caixa Média',
    weight: 1000,
    img: img2,
  },
  {
    id: 14,
    width: '12',
    height: '4',
    length: '16',
    weight: 1000,
    name: 'Caixa Pequena',
    img: img5,
  },
];

const Details = ({ setFieldValue, values }) => {
  const [bag, setBag] = React.useState(99);

  const {
    admin: {
      category: { categoryList },
    },
  } = useSelector(state => state);

  const handleChangeBag = (e, setFieldValue) => {
    setBag(Number(e.target.value));
    const bagsInfo = bags.find(item => item.id === e.target.value);
    const bagInfo = bags.find(item => item.id === e.target.value);
    if (bagsInfo) {
      setFieldValue('width', bagsInfo.width);
      setFieldValue('height', bagsInfo.height);
      setFieldValue('length', bagsInfo.length);
      setFieldValue('weight', bagsInfo.weight);
    }
    if (bagInfo) {
      setFieldValue('width', bagInfo.width);
      setFieldValue('height', bagInfo.height);
      setFieldValue('length', bagInfo.length);
      setFieldValue('weight', bagInfo.weight);
    }
    if (e.target.value === 98) {
      setFieldValue('width', '');
      setFieldValue('height', '');
      setFieldValue('length', '');
      setFieldValue('weight', '');
    }
  };

  const {
    getPrototipy,
    prototipy,
    getsearchPrototipy,
    prototipyList,
    loading: productAutocompleteLoading,
  } = useProduct();

  const searchProduct = search => {
    getsearchPrototipy({
      perPage: 5,
      ...search,
    });
  };

  useEffect(() => {
    if (prototipy !== null) {
      setFieldValue('price_whole', prototipy.price);
      setFieldValue('code_ean', prototipy.bar_code);
      setFieldValue('name', prototipy.name);
      setFieldValue('product_prototype_id', prototipy.id);
      if (prototipy.sizes.original && prototipy.image_key) {
        const new_image_id = new Date().getTime();
        setFieldValue('images_info', [
          ...values.images_info,
          {
            id: new_image_id,
            sizes: prototipy.sizes,
            isPrototype: true,
          },
        ]);
        setFieldValue('featured', new_image_id);
      }
      if (prototipy.category) {
        const category = categoryList.find(
          m =>
            String(m.name)
              .toLowerCase()
              .replaceAll(' ', '')
              .normalize('NFD') ===
            String(prototipy.category)
              .toLowerCase()
              .replaceAll(' ', '')
              .normalize('NFD')
        );
        if (category) {
          setFieldValue('categories', [category]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prototipy]);

  return (
    <div>
      <InputView>
        <InputItem>
          <Typography variant="h6">Nome do produto</Typography>
          <Field
            name="name"
            id="nome"
            label="Nome"
            options={prototipyList}
            component={AutoCompleteSelect}
            placeholder="Produtos"
            search={searchProduct}
            getPrototipy={getPrototipy}
            isLoading={productAutocompleteLoading}
          />
        </InputItem>
      </InputView>
      <InputView>
        <InputItem>
          <Typography variant="h6">Preço</Typography>
          <Field
            name="price_whole"
            id="preco"
            label="Preço"
            component={CustomCurrencyField}
          />
        </InputItem>
        <div style={{ width: '10px' }} />
        <InputItem>
          <Typography variant="h6">Status do produto</Typography>
          <Field
            name="status"
            id="status"
            label="Status"
            options={[
              { id: 0, name: 'Inativo' },
              { id: 1, name: 'Ativo' },
            ]}
            component={CustomSelect}
            placeholder="Status"
            isLoading={false}
          />
        </InputItem>
      </InputView>

      <InputView>
        <InputItem>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.enable_cashback}
                onChange={e => {
                  setFieldValue(
                    'enable_cashback',
                    e.target.checked ? true : false
                  );
                }}
                value="checkedA"
                style={{ color: appColors.PRIMARY_COLOR }}
              />
            }
            label="Habilitar cashback para esse produto"
          />
        </InputItem>
      </InputView>

      <InputView>
        <InputItem>
          <a
            style={{ textDecoration: 'none' }}
            href="https://www.correios.com.br/enviar-e-receber/encomendas/prepare-seu-objeto/arquivos/GuiaTecnicoEmbalagensRPC_v1.1.pdf">
            Entenda mais sobre os tamanhos das caixas
          </a>
          <Typography variant="h6">Tamanho do produto</Typography>
          <FormControl component="fieldset">
            <RadioGroup
              row
              value={bag}
              onChange={e => handleChangeBag(e, setFieldValue)}>
              {bags.map((bagCaixa, i) => (
                <FormControlLabel
                  key={i}
                  value={bagCaixa.id}
                  control={<Radio data-cy={bagCaixa.name} color="default" />}
                  label={
                    <div
                      style={{
                        maxWidth: '150px',
                        display: 'flex',
                        flexDirection: 'column',
                      }}>
                      <img alt="" src={bagCaixa.img} width="80" />
                      <Typography>{bagCaixa.name}</Typography>
                      <p style={{ fontSize: '12px' }}>
                        Medidas de {bagCaixa.width} cm de largura{' '}
                        {bagCaixa.length}cm de profundidade e {bagCaixa.height}
                        cm de altura
                      </p>
                    </div>
                  }
                />
              ))}
            </RadioGroup>
            <RadioGroup
              row
              value={bag}
              onChange={e => handleChangeBag(e, setFieldValue)}>
              <FormControlLabel
                data-cy="other_dimensions"
                key={`bagoutros`}
                value={98}
                control={<Radio color="default" />}
                label={
                  <div>
                    <Typography
                      style={{
                        display: bag !== 98 ? 'flex' : 'none',
                      }}>
                      Outros
                    </Typography>
                    <div
                      style={{
                        display: bag === 98 ? 'flex' : 'none',
                      }}>
                      <InputItem>
                        <FastField
                          name="width"
                          label="Largura"
                          component={CustomTextField}
                          endAdornment="cm"
                        />
                      </InputItem>
                      <InputItem>
                        <FastField
                          name="height"
                          label="Altura"
                          component={CustomTextField}
                          endAdornment="cm"
                        />
                      </InputItem>
                      <InputItem>
                        <FastField
                          name="length"
                          label="Profundidade"
                          component={CustomTextField}
                          endAdornment="cm"
                        />
                      </InputItem>
                      <InputItem>
                        <FastField
                          name="weight"
                          id="peso"
                          label="Peso"
                          component={CustomTextField}
                          endAdornment="g"
                        />
                      </InputItem>
                    </div>
                  </div>
                }
              />
            </RadioGroup>
          </FormControl>
        </InputItem>
      </InputView>
      <InputView>
        <InputItem>
          <Accordion style={{ flex: 1, backgroundColor: '#f4f4f4' }}>
            <AccordionSummary
              data-cy="advance_produto"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography>Avançado</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <InputItem>
                <FastField
                  name="code_ean"
                  label="Código EAN"
                  component={CustomTextField}
                />
              </InputItem>
              <InputItem>
                <FastField
                  name="code_ncm"
                  label="Código NCM"
                  component={CustomTextField}
                />
              </InputItem>
              <InputItem>
                <FastField
                  name="code_integration"
                  id="code_internal"
                  label="Código de integração"
                  component={CustomTextField}
                />
              </InputItem>
            </AccordionDetails>
          </Accordion>
        </InputItem>
      </InputView>
    </div>
  );
};

export default Details;
