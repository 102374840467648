/* eslint-disable react-hooks/exhaustive-deps */
// @flow
import React from 'react';
import HeaderInfoDetails from 'components/Pages/InfoPage/HeaderInfoDetails';
import InfoText from 'components/Pages/InfoPage/InfoText';
import { CircularProgress, Box } from '@material-ui/core';
import appColors from 'utils/appColors';
import { Creators as InfoPageCreators } from 'store/ducks/infoPage';
import { useSelector, useDispatch } from 'react-redux';
import { StyledBoxDetail, StoreDetailContainer } from './styles';

const InfoPage = ({ match }) => {
  const { infoPage, infoPageLoading } = useSelector(state => state.infoPage);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(InfoPageCreators.getInfoPageRequest(match.params.route));
  }, [dispatch, match.params.route]);

  return (
    <StoreDetailContainer>
      {!infoPageLoading && Object.keys(infoPage).length > 0 && (
        <>
          <HeaderInfoDetails />
          <StyledBoxDetail display="flex" flexWrap="wrap" flexDirection="row">
            <InfoText data={infoPage} route={match.params.route} />
          </StyledBoxDetail>
        </>
      )}
      {infoPageLoading && (
        <Box
          flex="1"
          display="flex"
          justifyContent="center"
          alignItems="flex-start"
          style={{ minHeight: 800 }}>
          <CircularProgress style={{ color: appColors.PRIMARY_COLOR }} />
        </Box>
      )}
    </StoreDetailContainer>
  );
};

export default InfoPage;
