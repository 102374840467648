/* eslint-disable camelcase */
import { call, takeLatest, all, put } from 'redux-saga/effects';
import api from 'services/api';
import { Types, Creators } from 'store/ducks/cupom';
import { callApi } from 'store/sagas/auth';

import { Creators as NotificationCreators } from 'store/ducks/app';

// Teste

function* getCupomListRequest({ payload }) {
  try {
    const { discount_coupon, products } = payload;
    const request = call(
      api.post,
      `/v1/client/cart/coupon/${discount_coupon}`,
      {
        products,
      }
    );
    const response = yield call(callApi, request);
    yield put(
      Creators.getCupomListSuccess({
        data: response.data.data.products,
        discount_coupon,
      })
    );
  } catch (err) {
    yield put(
      NotificationCreators.openNotification({
        message: err.data.data.Error || err.data.msg,
        type: 'error',
      })
    );
  }
}

// Individual exports for testing
export default function* cupomSaga() {
  yield all([takeLatest(Types.GET_LIST_REQUEST, getCupomListRequest)]);
}
