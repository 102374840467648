import React, { useState, useEffect } from 'react';
import { Box, Typography, Breadcrumbs } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from 'react-router-dom';
import styles, { Container } from './styles';
import { useSelector } from 'react-redux';

const HeaderProductDetails = ({ title }) => {
  const [state, setState] = useState('/');
  const historypatch = useSelector(state => state.history);

  useEffect(() => {
    setState(historypatch.patch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Box display="flex" justifyContent="flex-start">
        <Typography style={styles.purpleTitle}>{title}</Typography>
      </Box>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb">
        <Link to={`${state ? state : '/'}`} style={styles.links}>
          {state ? 'Voltar' : 'Início'}
        </Link>
        <Typography style={styles.primary} color="textPrimary">
          Detalhes
        </Typography>
      </Breadcrumbs>
    </Container>
  );
};

export default HeaderProductDetails;
