import React from 'react';
import PageBase from 'components/PageBase';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as VirtualCatalogCreators } from 'store/ducks/admin/virtualCatalog';
import HeaderComponent from 'components/HeaderComponent';
import VirtualCatalogForm from 'components/Pages/VirtualCatalogPage/VirtualCatalogForm';
import VirtualCatalogFormNew from 'components/Pages/VirtualCatalogPageNew/VirtualCatalogForm';
import { withRouter } from 'react-router-dom';
import { appTabs } from 'components/Pages/MyAccountPage/PainelAccount/TabPanelShopkeeper';
import { Creators as PanelTabsCreators } from 'store/ducks/panelTabs';
import useQuery from 'hooks/useQuery';
import moment from 'moment';

const VirtualCatalogUpdatePage = () => {
  const dispatch = useDispatch();
  const query = useQuery();

  const [localState, setLocalState] = React.useState(null);

  const { virtualCatalog, virtualCatalogLoading, virtualCatalogUpdateLoading } =
    useSelector(state => state.admin.virtualCatalog);

  const isUpdate = query.get('id_catalogo');

  const getInitialData = () => {
    if (isUpdate) {
      dispatch(
        VirtualCatalogCreators.getVirtualCatalogRequest({
          id: isUpdate,
        })
      );
      setLocalState(null);
    }
  };

  const { client } = useSelector(state => state.client);

  React.useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdate]);

  React.useEffect(() => {
    if (
      Object.keys(virtualCatalog).length > 0 &&
      !virtualCatalogLoading &&
      isUpdate &&
      virtualCatalog.id === Number(isUpdate)
    ) {
      setLocalState({
        ...virtualCatalog,
        image_info: virtualCatalog.image,
        expiration_date: moment(
          virtualCatalog.expiration_date,
          'YYYY-MM-DD'
        ).format('DD/MM/YYYY'),
        image: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [virtualCatalog]);

  const onSubmit = data => {
    dispatch(VirtualCatalogCreators.getVirtualCatalogUpdateRequest(data));
  };

  const handleBack = () => {
    dispatch(PanelTabsCreators.changeTab(appTabs.virtualCatalogList));
  };

  return (
    <PageBase>
      <HeaderComponent title="Atualizar Catálogo Virtual" />
      {localState && client.group.id === 4 ? (
        <VirtualCatalogFormNew
          initialValues={localState}
          isLoading={virtualCatalogUpdateLoading}
          onSubmit={onSubmit}
          handleBack={handleBack}
        />
      ) : (
        localState && (
          <VirtualCatalogForm
            isUpdate={isUpdate}
            initialValues={localState}
            isLoading={virtualCatalogUpdateLoading}
            onSubmit={onSubmit}
            handleBack={handleBack}
          />
        )
      )}
    </PageBase>
  );
};

export default withRouter(VirtualCatalogUpdatePage);
