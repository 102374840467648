import React from 'react';
import { Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import appColors from 'utils/appColors';

import { Stack, Text } from '@chakra-ui/react';

const StatisticItemPanelDashboardNoIcon = ({ icon, title, description }) => (
  <Stack as={Paper} bg={appColors.LIGHT_COLOR} p="16px">
    {icon && <Icon>{icon}</Icon>}

    <Text
      fontSize="18px"
      fontWeight="bold"
      textTransform="uppercase"
      color={appColors.PRIMARY_COLOR}>
      {title}
    </Text>

    <Text fontSize="24px" color="#606060" fontWeight="bold">
      {description}
    </Text>
  </Stack>
);

StatisticItemPanelDashboardNoIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default StatisticItemPanelDashboardNoIcon;
