/*
 *
 * history patch
 *
 */

import { Types as AuthTypes } from 'store/ducks/auth';

export const Types = {
  GET_HISTORY_REQUEST: 'cupom/GET_HISTORY_REQUEST',
  GET_HISTORY_SUCCESS: 'cupom/GET_HISTORY_SUCCESS',
  GET_HISTORY_FAILURE: 'cupom/GET_HISTORY_FAILURE',
};

export const initialState = {
  // Categoria por id
  patch: '',
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Lista  cupome
    case Types.GET_HISTORY_REQUEST:
      return {
        ...state,
        patch: action.payload,
        loading: false,
        error: null,
      };

    case Types.GET_HISTORY_FAILURE:
      return { state };

    case AuthTypes.GET_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export const Creators = {
  setHistoryPatch: payload => ({
    type: Types.GET_HISTORY_REQUEST,
    payload,
  }),
};
