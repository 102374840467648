import styled from 'styled-components';
import { Box } from '@material-ui/core';
import appColors from 'utils/appColors';

export const ImageProducts = styled(Box)`
  && {
    margin-bottom: 8px;
    background: url(${props => props.src}) #fff;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: calc(100%);
    height: 200px;
    border-radius: 20px;

    @media all and (max-width: 500px) {
      width: 100%;
      min-width: 100px;
      height: 150px;
      margin-right: 10px;
      justify-content: center;
    }
    @media all and (min-width: 501px) and (max-width: 1024px) {
      height: 200px;
    }
    @media all and (min-width: 841px) {
      justify-content: center;
    }
    @media all and (min-width: 1480px) and (max-width: 1740px) {
      height: 200px;
    }
  }
`;

export const ContainerProducts = styled(Box)`
  && {
    justify-content: space-around;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 20px;

    @media all and (max-width: 500px) {
      justify-content: space-around;
      margin-right: 0px;
      padding: 5px;
    }
    @media all and (min-width: 501px) and (max-width: 1024px) {
      justify-content: center;
    }
    @media all and (min-width: 1025px) and (max-width: 1700px) {
      justify-content: center;
    }
  }
`;

export const BoxProduct = styled(Box)`
  && {
    margin-bottom: 20px;
    margin-right: 20px;
    width: 30%;
    padding: 10px 15px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 1px 1px 10px #ccc;

    @media all and (max-width: 500px) {
      width: 49%;
      margin-right: 3px;
      min-width: 120px;
    }

    @media all and (min-width: 501px) and (max-width: 1166px) {
      width: 45%;
    }
  }
`;

export const BoxInfo = styled(Box)`
  && {
    margin-bottom: 20px;
    max-width: 280px;
    @media all and (max-width: 500px) {
      padding-left: 0px;
      max-width: 100%;
    }
  }
`;

const styles = {
  purpleTitle: {
    color: appColors.SECUNDARY_COLOR,
    fontWeight: 'bold',
    fontSize: 32,
    textAlign: 'center',
  },
  ProductPrice: {
    color: appColors.SECUNDARY_COLOR,
    fontWeight: 'bold',
    fontSize: 22,
    padding: '0px 10px',
  },
  smallSubTitle: {
    marginTop: 10,
    color: '#373737',
    fontSize: 17,
    textAlign: 'center',
  },
};

export default styles;
