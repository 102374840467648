import { useState } from 'react';
import moment from 'moment';
import api from 'services/api';
import { Creators as NotificationCreators } from 'store/ducks/app';
import { useDispatch } from 'react-redux';

export const useRecomendations = () => {
  const dispatch = useDispatch();
  const [recomentation, setRecommendationList] = useState({
    linked_resellers: [],
    pagination: {},
  });

  const today = moment();
  const actualDate = moment();
  const weekdate = today.add(-7, 'day');

  const [localState, setLocalState] = useState({
    search: '',
    orderByColumn: '',
    order_by_direction: 'desc',
    page: 1,
    per_page: 10,
    dateStart: weekdate.format('YYYY-MM-DD'),
    dateEnd: actualDate.format('YYYY-MM-DD'),
  });

  const getRecomendations = async () => {
    api.get(`/v1/site/users/partners`, localState).then(response => {
      if (response.data) {
        setRecommendationList(response.data.data);
      }
    });
  };

  const getFunction = data => {
    data.per_page = data.perPage;
    setLocalState(oldLocalState => ({
      ...oldLocalState,
      ...data,
      search: data.search,
    }));
  };

  const copyToClipboard = text => {
    const ta = document.createElement('textarea');
    ta.innerText = text;
    document.body.appendChild(ta);
    ta.select();
    document.execCommand('copy');
    ta.remove();
    dispatch(
      NotificationCreators.openNotification({
        message: `Copiado para área de trabalho`,
        type: 'success',
      })
    );
  };

  return {
    copyToClipboard,
    getRecomendations,
    localState,
    recomentation,
    getFunction,
  };
};
