// @flow
/*
 *
 * checkout-address reducer
 *
 */
export const Types = {
  // Adiciona endereço
  ADD_ADDRESS_TO_CART_REQUEST: 'checkout-address/ADD_ADDRESS_TO_CART_REQUEST',
  ADD_ADDRESS_TO_CART_SUCCESS: 'checkout-address/ADD_ADDRESS_TO_CART_SUCCESS',
  // remove endereço
  REMOVE_ADDRESS_TO_CART_REQUEST:
    'checkout-address/REMOVE_ADDRESS_TO_CART_REQUEST',
  REMOVE_ADDRESS_TO_CART_SUCCESS:
    'checkout-address/REMOVE_ADDRESS_TO_CART_SUCCESS',
  // remove um grupo de endereço
  REMOVE_GROUP_ADDRESS_TO_CART_REQUEST:
    'checkout-address/REMOVE_GROUP_ADDRESS_TO_CART_REQUEST',
  REMOVE_GROUP_ADDRESS_TO_CART_SUCCESS:
    'checkout-address/REMOVE_GROUP_ADDRESS_TO_CART_SUCCESS',

  // altera endereço
  UPDATE_ADDRESS_REQUEST: 'checkout-address/UPDATE_ADDRESS_REQUEST',
  UPDATE_ADDRESS_SUCCESS: 'checkout-address/UPDATE_ADDRESS_SUCCESS',

  //reseta endereço
  RESET_ADDRESS_CART_REQUEST: 'checkout-address/RESET_ADDRESS_CART_REQUEST',
};

export const initialState = {
  addresses: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Adiciona endereço
    case Types.ADD_ADDRESS_TO_CART_SUCCESS:
      return {
        ...state,
        addresses: action.payload,
      };
    // Remove endereço
    case Types.REMOVE_ADDRESS_TO_CART_SUCCESS:
      return {
        ...state,
        addresses: action.payload,
      };
    // Remove grupo de endereços
    case Types.REMOVE_GROUP_ADDRESS_TO_CART_SUCCESS:
      return {
        ...state,
        addresses: action.payload,
      };
    // altera endereços
    case Types.UPDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        addresses: action.payload,
      };
    //reseta endereços
    case Types.RESET_ADDRESS_CART_REQUEST:
      return {
        ...state,
        addresses: [],
      };
    default:
      return state;
  }
};

export const Creators = {
  // Adiciona endereço
  addAddressToCart: address => ({
    type: Types.ADD_ADDRESS_TO_CART_REQUEST,
    payload: { address },
  }),
  addAddressToCartSucess: address => ({
    type: Types.ADD_ADDRESS_TO_CART_SUCCESS,
    payload: address,
  }),
  // Remove endereço
  removeAddressFromCart: address => ({
    type: Types.REMOVE_ADDRESS_TO_CART_REQUEST,
    payload: { address },
  }),
  removeAddressFromCartSuccess: address => ({
    type: Types.REMOVE_ADDRESS_TO_CART_SUCCESS,
    payload: address,
  }),
  // Remove grupo de endereço
  removeGroupFromCart: address => ({
    type: Types.REMOVE_GROUP_ADDRESS_TO_CART_REQUEST,
    payload: { address },
  }),
  removeGroupFromCartSuccess: address => ({
    type: Types.REMOVE_GROUP_ADDRESS_TO_CART_SUCCESS,
    payload: address,
  }),
  // altera o endereço
  updatePriceRequest: ({ price_actual, code_internal }) => ({
    type: Types.UPDATE_ADDRESS_REQUEST,
    price_actual,
    code_internal,
  }),
  updatePriceSuccess: data => ({
    type: Types.UPDATE_ADDRESS_SUCCESS,
    payload: data,
  }),
  resetCart: () => ({
    type: Types.RESET_ADDRESS_CART_REQUEST,
  }),
};
