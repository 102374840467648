import { Box, Typography } from '@material-ui/core';
import ProductSelect from 'components/form/components/CustomSearchSelect/ProductSelect';
import ProductsRelated from 'components/Pages/MyAccountPage/PainelAccount/TabsPanel/ProductsTab/components/ProductForm/ProductsRelated';
import { Field } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as ProductCreators } from 'store/ducks/admin/product';
import { InputView, InputItem } from './styles';

function Related() {
  const dispatch = useDispatch();
  const {
    admin: {
      product: { productList, productListLoading },
    },
  } = useSelector(state => state);

  const searchProduct = searchInfo => {
    dispatch(
      ProductCreators.getProductListRequest({ perPage: 50, ...searchInfo })
    );
  };

  useEffect(() => {
    dispatch(ProductCreators.getProductListRequest({ perPage: 50 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Box style={{ marginTop: '15px' }}>
        <Typography variant="h6">Produtos Relacionados</Typography>
        <InputView>
          <InputItem>
            <Field
              name="related"
              label="Produtos"
              options={productList}
              component={ProductSelect}
              placeholder="Produtos"
              isMulti
              search={searchProduct}
              isLoading={productListLoading}
            />
          </InputItem>
        </InputView>
        <InputView>
          <InputItem>
            <Field
              name="products_preview"
              component={ProductsRelated}
              items={productList}
              isLoading={productListLoading}
            />
          </InputItem>
        </InputView>
      </Box>
    </div>
  );
}

export default Related;
