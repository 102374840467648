import React from 'react';
import { Box, Button } from '@material-ui/core';
import appConfig from 'utils/appConfig';
import { Link } from 'react-router-dom';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import styles, {
  CartContainer,
  BigTextBlack,
  BigTitlePurple,
  BoxContainerText,
  BoxContainer,
  BoxContainerOkImg,
} from './styles';
import appColors from 'utils/appColors';
import { Center } from '@chakra-ui/react';

const ConfimationEmailRegister = () => {
  return (
    <CartContainer flex="1" flexDirection="row" flexWrap="wrap">
      <Center>
        <BoxContainer
          flex="1"
          display="flex"
          flexDirection="row"
          flexWrap="wrap">
          <BoxContainerOkImg display="flex" alignItems="center">
            <CheckCircleIcon
              style={{ fontSize: 150, color: appColors.PRIMARY_COLOR }}
            />
          </BoxContainerOkImg>
          <BoxContainerText
            flex="1"
            display="flex"
            justifyContent="center"
            flexDirection="column">
            <BigTextBlack>
              CONFIRMAÇÃO DE EMAIL REALIZADA COM SUCESSO!
            </BigTextBlack>
            <BigTitlePurple>AGRADECEMOS A PREFERÊNCIA!</BigTitlePurple>
          </BoxContainerText>
          <Box
            flex="1"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center">
            <img alt="" src={appConfig.LOGO_VERTICAL} width={160} />
            <Box
              marginTop="20px"
              flex="1"
              display="flex"
              alignItems="center"
              justifyContent="center">
              <Link
                to="/lojas"
                style={{ textDecoration: 'none', color: 'inherit' }}>
                <Button style={styles.purpleButton}>COMEÇAR A COMPRAR</Button>
              </Link>
            </Box>
          </Box>
        </BoxContainer>
      </Center>
    </CartContainer>
  );
};

export default ConfimationEmailRegister;
