import styled from 'styled-components';
import { Box } from '@material-ui/core';
import appColors from 'utils/appColors';

export const BoxContainer = styled(Box)`
  && {
    width: 1266px;
    display: flex;
    flex: 1;

    @media all and (max-width: 580px) {
      width: 100%;
    }
    @media all and (min-width: 581px) and (max-width: 920px) {
      width: 100%;
    }
    @media all and (min-width: 921px) and (max-width: 1290px) {
      width: 100%;
    }
  }
`;
export const BoxContainerInfo = styled(Box)`
  && {
    width: 1266px;
    display: flex;
    flex-wrap: wrap;
    flex: 1;

    @media all and (max-width: 580px) {
      width: 100%;
      justify-content: center;
    }
    @media all and (min-width: 581px) and (max-width: 920px) {
      width: 50%;
    }
    @media all and (min-width: 921px) and (max-width: 1290px) {
      width: 70%;
    }
  }
`;

export const ItemInfo = styled(Box)`
  && {
    margin-bottom: 40px;
    max-width: 300px;
    @media all and (max-width: 500px) {
      min-width: 250px;
    }
    @media all and (min-width: 501px) and (max-width: 768px) {
      min-width: 350px;
    }
    @media all and (min-width: 921px) and (max-width: 1290px) {
      width: 400;
    }
  }
`;

const styles = {
  slogan: {
    color: appColors.SECUNDARY_COLOR,
    fontWeight: 'bold',
    fontSize: 30,
    alignSelf: 'center',
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 60,
  },
};

export default styles;
