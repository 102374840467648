/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PageBase from 'components/PageBase';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as WalletCreators } from 'store/ducks/admin/wallet';
import { Creators as WithdrawCreators } from 'store/ducks/admin/wallet/withdraw';
import { Creators as StoreCreators } from 'store/ducks/admin/stores';
import HeaderComponent from 'components/HeaderComponent';
import { Button, CircularProgress } from '@material-ui/core';
import CashExpressInfo from 'components/CashExpressPage/CashExpressInfo';
import CashExpressWithdraw from 'components/CashExpressPage/CashExpressWithdraw';
import styles from './styles';
import PropTypes from 'prop-types';
import appColors from 'utils/appColors';
import WithDrawForm from 'components/WithdrawlForm';

export const formInitialValues = {
  enable_automatic_withdrawal: '',
  transfer_interval: '',
  transfer_day: '',
  minimum_transfer_value: '',
};

const CashExpressPage = ({ initialValues = formInitialValues }) => {
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = React.useState(false);

  const {
    admin: {
      wallet: { wallet, walletLoading },

      store: { store, storeLoading, storeUpdateLoading },
      withdraw: { withdrawLoading },
    },
    client: { client },
  } = useSelector(state => state);

  React.useEffect(() => {
    dispatch(WalletCreators.getWalletRequest());
    dispatch(StoreCreators.getStoreRequest({ id: client.store.id }));
  }, []);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const onSubmit = (form, onCloseModal) => {
    dispatch(WithdrawCreators.getWithdrawRequest(form, onCloseModal));
  };

  const [localState, setLocalState] = React.useState(initialValues);

  React.useEffect(() => {
    if (
      Object.keys(store).length > 0 &&
      !storeLoading &&
      store.id === Number(client.store.id)
    ) {
      setLocalState({
        ...store,
        enable_automatic_withdrawal: store.enable_automatic_withdrawal,
      });
    }
  }, [store]);

  const onSubmitAutomatic = data => {
    dispatch(StoreCreators.getStoreUpdateRequest(data));
    window.scrollTo(0, 0);
  };

  return (
    <PageBase>
      <HeaderComponent title="Sacar Dinheiro" />
      <CashExpressInfo wallet={wallet} walletLoading={walletLoading} />
      {!storeLoading && wallet.cash && (
        <>
          <Button
            wallet={wallet}
            style={styles.purpleButtonProceed}
            onClick={handleModalOpen}>
            Realizar um saque
          </Button>
          {storeLoading && storeUpdateLoading && localState ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '20px',
              }}>
              <CircularProgress style={{ color: appColors.PRIMARY_COLOR }} />
            </div>
          ) : (
            <WithDrawForm
              initialValues={localState}
              onSubmit={onSubmitAutomatic}
              store={store}
              isLoading={storeUpdateLoading}
            />
          )}
          <CashExpressWithdraw
            onSubmit={onSubmit}
            handleClose={handleModalClose}
            cash={wallet.cash}
            isOpen={modalOpen}
            isLoading={withdrawLoading}
            wallet={wallet}
          />
        </>
      )}
    </PageBase>
  );
};

CashExpressPage.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.oneOfType([PropTypes.object]),
  submitText: PropTypes.string,
  handleBack: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isLoading: PropTypes.bool.isRequired,
  stateImages: PropTypes.any.isRequired,
  setStateImages: PropTypes.func.isRequired,
};

CashExpressPage.defaultProps = {
  initialValues: formInitialValues,
  submitText: 'Salvar',
  handleBack: false,
  onSubmit: () => {},
};
export default CashExpressPage;
