const styles = {
  columnsFilter: {
    marginBottom: 30,
    marginTop: 10,
  },
  smallTextPurple: {
    color: '#444',
    fontSize: 16,
    fontWeight: 'bold',
  },
};

export default styles;
