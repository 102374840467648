import { call, takeLatest, all, put } from 'redux-saga/effects';
import api from 'services/api';

import { Types, Creators } from 'store/ducks/stores/byCode';

function* getStoresByCodeRequest({ payload }) {
  try {
    const { slug } = payload;
    const response = yield call(api.get, `v1/site/stores/${slug}`);
    if (response.status !== 200) throw response;
    yield put(Creators.getStoresByCodeSuccess(response.data));
  } catch (err) {
    yield put(Creators.getStoresByCodeFailure('Erro ao buscar na API'));
  }
}

// Individual exports for testing
export default function* storesByCodeSaga() {
  yield all([
    takeLatest(Types.GET_STORES_BY_CODE_REQUEST, getStoresByCodeRequest),
  ]);
}
