import styled from 'styled-components';
import { Box } from '@material-ui/core';
import appColors from 'utils/appColors';

export const CouponContainer = styled(Box)`
  && {
    flex-direction: column;
    justify-content: flex-end;

    @media all and (max-width: 500px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const ContainerBottonCoupon = styled(Box)`
  && {
    margin-left: 20px;
    margin-top: 15px;
    @media all and (max-width: 500px) {
      margin-left: 0px;
    }
  }
`;

const styles = {
  purpleTitle: {
    color: appColors.SECUNDARY_COLOR,
    fontWeight: 'bold',
    fontSize: 32,
  },

  iconsAddRemove: {
    marginLeft: 10,
    marginRight: 10,
    color: appColors.SECUNDARY_COLOR,
    cursor: 'pointer',
  },
  titleProduct: {
    color: appColors.SECUNDARY_COLOR,
    fontWeight: 'bold',
    textAlign: 'left',
  },
  fontshipping: {
    color: appColors.SECUNDARY_COLOR,
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  resumeText: {
    borderBottom: '2px solid #FA8F47',
    fontWeight: 'bold',
  },
  totalText: {
    borderBottom: '2px solid #FA8F47',
    fontWeight: 'bold',
    marginLeft: 20,
  },
  smallTextBlackBold: {
    color: '#373737',
    fontWeight: 'bold',
  },
  smallTextBlack: {
    color: '#373737',
    textAlign: 'left',
  },
  bigBoldTextBlack: {
    color: '#373737',
    fontWeight: 'bold',
    fontSize: 22,
  },
  purpleButton: {
    backgroundColor: appColors.PRIMARY_COLOR,
    color: 'white',
    paddingLeft: 20,
    paddingRight: 20,
  },
};

export default styles;
