/*
 *
 * Product reducer
 *
 */

export const Types = {
  // Obtem produto por id
  GET_REQUEST: 'product/GET_REQUEST',
  GET_SUCCESS: 'product/GET_SUCCESS',
  GET_FAILURE: 'product/GET_FAILURE',
  GET_RESET: 'product/GET_RESET',

  // Obtem lista de produtos
  GET_LIST_REQUEST: 'product/GET_LIST_REQUEST',
  GET_LIST_SUCCESS: 'product/GET_LIST_SUCCESS',
  GET_LIST_FAILURE: 'product/GET_LIST_FAILURE',

  // Obtem produtos por categoria
  GET_BY_CATEGORY_REQUEST: 'product/GET_BY_CATEGORY_REQUEST',
  GET_BY_CATEGORY_SUCCESS: 'product/GET_BY_CATEGORY_SUCCESS',
  GET_BY_CATEGORY_FAILURE: 'product/GET_BY_CATEGORY_FAILURE',
  RESET_BY_CATEGORY: 'product/RESET_BY_CATEGORY',
  // CATEGORIAS UNICAS DA HOMEPAGE
  // Obtem categorias SELECTED
  GET_SELECTED_REQUEST: 'category/GET_SELECTED_REQUEST',
  GET_SELECTED_SUCCESS: 'category/GET_SELECTED_SUCCESS',
  GET_SELECTED_FAILURE: 'category/GET_SELECTED_FAILURE',
  // Obtem categorias NEWS
  GET_NEWS_REQUEST: 'category/GET_NEWS_REQUEST',
  GET_NEWS_SUCCESS: 'category/GET_NEWS_SUCCESS',
  GET_NEWS_FAILURE: 'category/GET_NEWS_FAILURE',
  // Obtem categorias SALES
  GET_SALES_REQUEST: 'category/GET_SALES_REQUEST',
  GET_SALES_SUCCESS: 'category/GET_SALES_SUCCESS',
  GET_SALES_FAILURE: 'category/GET_SALES_FAILURE',
};

export const initialState = {
  // Produto por id
  product: {},
  productLoading: false,
  productError: null,
  // Lista de categorias
  productList: [],
  productListLoading: false,
  productListError: null,
  productListTotal: 0,
  // Lista produtos por categoria
  productByCategory: [],
  productByCategoryLoading: false,
  productByCategoryError: null,
  productByCategoryTotal: 0,
  // CATEGORIAS UNICAS DA HOMEPAGE
  // Lista de categorias SELECTED
  categoryListSelected: [],
  categoryListSelectedLoading: false,
  categoryListSelectedError: null,
  categoryListSelectedTotal: 0,
  // Lista de categorias NEWS
  categoryListNews: [],
  categoryListNewsLoading: false,
  categoryListNewsError: null,
  categoryListNewsTotal: 0,
  // Lista de categorias SALES
  categoryListSales: [],
  categoryListSalesLoading: false,
  categoryListSalesError: null,
  categoryListSalesTotal: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Produto por id
    case Types.GET_REQUEST:
      return {
        ...state,
        productError: null,
        productLoading: true,
      };
    case Types.GET_SUCCESS:
      return {
        ...state,
        product: action.payload.data,
        productLoading: false,
        productError: null,
      };
    case Types.GET_FAILURE:
      return {
        ...state,
        productLoading: false,
        productError: action.payload,
      };
    case Types.GET_RESET:
      return {
        ...state,
        product: {},
        productLoading: false,
        productError: null,
      };
    // Lista de produtos
    case Types.GET_LIST_REQUEST:
      return {
        ...state,
        productListError: null,
        productListLoading: true,
      };
    case Types.GET_LIST_SUCCESS:
      return {
        ...state,
        productList: action.payload.data,
        productListLoading: false,
        productListError: null,
        productListTotal: action.payload.total,
      };
    case Types.GET_LIST_FAILURE:
      return {
        ...state,
        productListLoading: false,
        productListError: action.payload,
      };
    // Lista de produtos
    case Types.GET_BY_CATEGORY_REQUEST:
      return {
        ...state,
        productByCategoryError: null,
        productByCategoryLoading: true,
      };
    case Types.GET_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        productByCategory: action.payload.data,
        productByCategoryLoading: false,
        productByCategoryError: null,
        productByCategoryTotal: action.payload.total,
      };
    case Types.GET_BY_CATEGORY_FAILURE:
      return {
        ...state,
        productByCategoryLoading: false,
        productByCategoryError: action.payload,
      };
    case Types.RESET_BY_CATEGORY:
      return {
        ...state,
        productByCategory: [],
        productByCategoryLoading: false,
        productByCategoryError: null,
        productByCategoryTotal: 0,
      };
    // CATEGORIAS UNICAS DA HOMEPAGE
    // Lista de categorias SELECTED
    case Types.GET_SELECTED_REQUEST:
      return { ...state, categoryListSelectedLoading: true };
    case Types.GET_SELECTED_SUCCESS:
      return {
        ...state,
        categoryListSelected: action.payload.data,
        categoryListSelectedLoading: false,
        categoryListSelectedError: null,
        categoryListSelectedTotal: action.payload.total,
      };
    case Types.GET_SELECTED_FAILURE:
      return {
        ...state,
        categoryListSelectedLoading: false,
        categoryListSelectedError: action.payload,
      };
    // Lista de categorias NEWS
    case Types.GET_NEWS_REQUEST:
      return { ...state, categoryListNewsLoading: true };
    case Types.GET_NEWS_SUCCESS:
      return {
        ...state,
        categoryListNews: action.payload.data,
        categoryListNewsLoading: false,
        categoryListNewsError: null,
        categoryListNewsTotal: action.payload.total,
      };
    case Types.GET_NEWS_FAILURE:
      return {
        ...state,
        categoryListNewsLoading: false,
        categoryListNewsError: action.payload,
      };
    // Lista de categorias SALES
    case Types.GET_SALES_REQUEST:
      return { ...state, categoryListSalesLoading: true };
    case Types.GET_SALES_SUCCESS:
      return {
        ...state,
        categoryListSales: action.payload.data,
        categoryListSalesLoading: false,
        categoryListSalesError: null,
        categoryListSalesTotal: action.payload.total,
      };
    case Types.GET_SALES_FAILURE:
      return {
        ...state,
        categoryListSalesLoading: false,
        categoryListSalesError: action.payload,
      };
    default:
      return state;
  }
};

export const Creators = {
  // Busca um produto por id
  getProductRequest: (id, catalog_code_internal, catalog_product_id) => ({
    type: Types.GET_REQUEST,
    payload: { id, catalog_code_internal, catalog_product_id },
  }),
  getProductSuccess: ({ data }) => ({
    type: Types.GET_SUCCESS,
    payload: { data },
  }),
  getProductFailure: error => ({
    type: Types.GET_FAILURE,
    payload: error,
  }),
  getReset: () => ({
    type: Types.GET_RESET,
  }),
  getProductDeleteRequest: id => ({
    type: Types.GET_DELETE_REQUEST,
    payload: { id },
  }),
  // Busca lista de produtos
  getProductListRequest: ({
    page,
    perPage,
    search,
    orderByColumn,
    orderByDirection,
    price_min,
    price_max,
    sizes,
    colors,
    order,
  }) => ({
    type: Types.GET_LIST_REQUEST,
    payload: {
      page,
      perPage,
      search,
      orderByColumn,
      orderByDirection,
      price_min,
      price_max,
      sizes,
      colors,
      order,
    },
  }),
  getProductListSuccess: ({ data, total }) => ({
    type: Types.GET_LIST_SUCCESS,
    payload: { data, total },
  }),
  getProductListFailure: error => ({
    type: Types.GET_LIST_FAILURE,
    payload: error,
  }),
  // Busca lista de produtos por categoria
  getProductsByCategoryRequest: (filters, slug) => ({
    type: Types.GET_BY_CATEGORY_REQUEST,
    payload: { filters, slug },
  }),
  getProductsByCategorySuccess: ({ data, total }) => ({
    type: Types.GET_BY_CATEGORY_SUCCESS,
    payload: { data, total },
  }),
  getProductsByCategoryFailure: error => ({
    type: Types.GET_BY_CATEGORY_FAILURE,
    payload: error,
  }),
  resetByCategory: () => ({
    type: Types.RESET_BY_CATEGORY,
  }),
  // CATEGORIAS UNICAS DA HOMEPAGE
  // Busca lista de categorias SELECTED
  getCategorySelectedRequest: ({
    page,
    perPage,
    search,
    orderByColumn,
    orderByDirection,
  }) => ({
    type: Types.GET_SELECTED_REQUEST,
    payload: {
      page,
      perPage,
      search,
      orderByColumn,
      orderByDirection,
    },
  }),
  getCategoryListSelectedSuccess: ({ data, total }) => ({
    type: Types.GET_SELECTED_SUCCESS,
    payload: { data, total },
  }),
  getCategoryListSelectedFailure: error => ({
    type: Types.GET_SELECTED_FAILURE,
    payload: error,
  }),
  // Busca lista de categorias NEWS
  getCategoryListNewsRequest: ({
    page,
    perPage,
    search,
    orderByColumn,
    orderByDirection,
  }) => ({
    type: Types.GET_NEWS_REQUEST,
    payload: {
      page,
      perPage,
      search,
      orderByColumn,
      orderByDirection,
    },
  }),
  getCategoryListNewsSuccess: ({ data, total }) => ({
    type: Types.GET_NEWS_SUCCESS,
    payload: { data, total },
  }),
  getCategoryListNewsFailure: error => ({
    type: Types.GET_NEWS_FAILURE,
    payload: error,
  }),
  // Busca lista de categorias SALES
  getCategoryListSalesRequest: ({
    page,
    perPage,
    search,
    orderByColumn,
    orderByDirection,
  }) => ({
    type: Types.GET_SALES_REQUEST,
    payload: {
      page,
      perPage,
      search,
      orderByColumn,
      orderByDirection,
    },
  }),
  getCategoryListSalesSuccess: ({ data, total }) => ({
    type: Types.GET_SALES_SUCCESS,
    payload: { data, total },
  }),
  getCategoryListSalesFailure: error => ({
    type: Types.GET_SALES_FAILURE,
    payload: error,
  }),
};
