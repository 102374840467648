import React from 'react';
import EditAccountForm from './EditAccountForm';
import ProfileContainer from 'components/Pages/MyAccountPage/PainelAccount/ProfileContainer';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as ClientCreators } from 'store/ducks/client';
import { CircularProgress, Box } from '@material-ui/core';
import appColors from 'utils/appColors';

const AccountDetailsTab = () => {
  const { client, clientLoading, clientUpdateLoading } = useSelector(
    state => state.client
  );
  const [localState, setLocalState] = React.useState(
    {
      formInitialValues: null,
    },
    []
  );

  const { formInitialValues } = localState;

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(ClientCreators.getClientRequest());
  }, [dispatch]);

  const onSubmit = values => {
    setLocalState({ ...localState, formInitialValues: null });
    let dateInverse = values.birth_date.replace(/[^\d]+/g, '-');
    dateInverse = dateInverse.split('-');
    values.birth_date =
      dateInverse[2] + '-' + dateInverse[1] + '-' + dateInverse[0];
    dispatch(ClientCreators.getClientUpdateRequest(values));
  };

  React.useEffect(() => {
    if (typeof client.name !== 'undefined') {
      let dateInverse = 'indefinido';
      if (client.birth_date) {
        dateInverse = client.birth_date.replace(/[^\d]+/g, '-');
        dateInverse = dateInverse.split('-');
        dateInverse =
          dateInverse[2] + '-' + dateInverse[1] + '-' + dateInverse[0];
      }

      setLocalState({
        ...localState,
        formInitialValues: {
          name: client.name,
          email: client.email,
          cpf: client.cpf,
          birth_date: dateInverse,
          cell_phone: client.cell_phone,
          genre: client.genre,
          password: '',
          confirmPassword: '',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  return (
    <>
      {formInitialValues !== null && !clientUpdateLoading && (
        <>
          <ProfileContainer client={client} clientLoading={clientLoading} />
          <EditAccountForm
            onSubmit={onSubmit}
            initialValues={formInitialValues}
          />
        </>
      )}
      {clientUpdateLoading && (
        <Box
          display="flex"
          flex="1"
          justifyContent="center"
          alignItems="center"
          style={{ height: 300 }}>
          <CircularProgress style={{ color: appColors.PRIMARY_COLOR }} />
        </Box>
      )}
    </>
  );
};

export default AccountDetailsTab;
