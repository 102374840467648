import styled from 'styled-components';
import { Box, Button } from '@material-ui/core';
import appColors from 'utils/appColors';

export const BoxFilters = styled(Box)`
  && {
    width: 280px;
    margin-right: 20px;
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 1px 1px 10px #ccc;

    @media all and (max-width: 500px) {
      padding-left: 5px;
      padding-right: 5px;
      margin-right: auto;
      margin-left: auto;
      width: 100%;
    }

    @media all and (min-width: 501px) and (max-width: 1070px) {
      width: 200px;
    }
  }

  .MuiSlider-root {
    color: ${appColors.SECUNDARY_COLOR};
  }
  .MuiSlider-track {
    height: 4px;
  }
  .MuiSlider-rail {
    height: 4px;
  }
  .MuiSlider-thumb {
    margin-top: -5px;
    width: 15px;
    height: 15px;
  }
  .MuiSlider-active {
  }
`;

export const ColorButton = styled(Button)`
  && {
    margin-top: 10px;
    margin-right: 10px;
    min-width: 0px;
    border: 1px solid ${appColors.SECUNDARY_COLOR};
    color: ${props => (props.checked ? `white` : appColors.SECUNDARY_COLOR)};
    background-color: ${props =>
      props.checked ? appColors.SECUNDARY_COLOR : `white`};
    &:hover {
      color: ${props => (props.checked ? `white` : appColors.SECUNDARY_COLOR)};
      background-color: ${props =>
        props.checked ? appColors.SECUNDARY_COLOR : `white`};
    }
  }
`;

export const FilterColorButton = styled(Button)`
  && {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border: 1px solid ${appColors.SECUNDARY_COLOR};
    color: ${appColors.SECUNDARY_COLOR};
    background-color: white;
    margin-bottom: 0px;
  }
  height: 50px;
  margin: 0px;
`;

export const BoxColors = styled(Box)`
  width: 20px;
  margin-right: 5px;
  margin-left: 5px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #dedede;
  background-color: ${props => props.hexa};
`;

const styles = {
  purpleButton: {
    backgroundColor: appColors.PRIMARY_COLOR,
    color: 'white',
    paddingLeft: 40,
    paddingRight: 40,
  },
  smallTextPurple: {
    color: '#333',
    fontSize: 16,
    fontWeight: 'bold',
  },
  textMediumPurple: {
    color: appColors.SECUNDARY_COLOR,
    fontSize: 16,
    fontWeight: 'bold',
  },
  priceSlider: {
    color: '#373737',
    fontSize: 14,
  },
  fontButton: {
    fontSize: 12,
  },
  columnsFilter: {
    marginTop: 18,
    paddingBottom: 30,
  },
  fontFilterButton: {
    fontSize: 12,
  },
};

export default styles;
