import styled from 'styled-components';

export const ViewStep = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 1;
`;

export const Footer = styled.div`
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  display: flex;
  margin: 27px 0px;
`;

export const ErrorView = styled.div`
  padding: 8px 20px;
  background-color: #e17055;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
`;
