import styled, {css} from 'styled-components';
import { Box } from '@material-ui/core';
import appColors from 'utils/appColors';

export const BoxColors = styled(Box)`
  width: 20px;
  margin-right: 5px;
  margin-left: 5px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #dedede;
  background-color: ${props => props.hexa};
`;

export const Img = styled.div`
  width: 50px;
  height: 75px;

  ${props=> props.src && css`
    background-image: url(${props.src});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  `}
`
const styles = {
  purpleButton: {
    backgroundColor: appColors.PRIMARY_COLOR,
    color: 'white',
    paddingLeft: 40,
    paddingRight: 40,
  },
  bigBoldTextBlack: {
    color: '#373737',
    fontWeight: 'bold',
    fontSize: 18,
    textTransform: 'uppercase',
  },

  mediumBoldTextBlack: {
    color: '#373737',
    fontWeight: 'bold',
    fontSize: 16,
  },
  smallTextBlackBold: {
    color: '#373737',
    fontWeight: 'bold',
  },
  imgFlag: {
    width: 50,
    marginRight: 10,
    marginTop: 10,
  },
  bigTitlePurple: {
    color: appColors.PRIMARY_COLOR,
    fontWeight: 'bold',
    fontSize: 25,
  },
};

export default styles;
