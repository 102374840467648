export const Types = {
  // Obtem produtos por sessão
  GET_LIST_REQUEST: 'productsBySection/GET_LIST_REQUEST',
  GET_LIST_SUCCESS: 'productsBySection/GET_LIST_SUCCESS',
  GET_LIST_FAILURE: 'productsBySection/GET_LIST_FAILURE',
  GET_LIST_RESET: 'productBySection/GET_LIST_RESET',


};

export const initialState = {
  // Lista de produtos por sessão
  productsBySection: [],
  productsBySectionLoading: false,
  productsBySectionError: null,
  productsBySectionTotal: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Lista de produtos por sessão
    case Types.GET_LIST_REQUEST:
      return { ...state, productsBySectionLoading: true };
    case Types.GET_LIST_SUCCESS:
      return {
        ...state,
        productsBySection: action.payload.data,
        productsBySectionLoading: false,
        productsBySectionError: null,
        productsBySectionTotal: action.payload.total,
      };
    case Types.GET_LIST_FAILURE:
      return {
        ...state,
        productsBySectionLoading: false,
        productsBySectionError: action.payload,
      };
    case Types.GET_LIST_RESET:
      return {
        ...state,
        productsBySection: [],
        productsBySectionLoading: false,
        productsBySectionError: null,
        productsBySectionTotal: 0,
      };
    default:
      return state;
  }
};

export const Creators = {
  // Busca lista de produtos por sessão
  getProductsBySectionRequest: (filters, slug) => ({
    type: Types.GET_LIST_REQUEST,
    payload: { filters, slug },
  }),
  getProductsBySectionSuccess: ({ data, total }) => ({
    type: Types.GET_LIST_SUCCESS,
    payload: { data, total },
  }),
  getProductsBySectionFailure: error => ({
    type: Types.GET_LIST_FAILURE,
    payload: error,
  }),
  getProductsBySectionReset: () => ({
    type: Types.GET_LIST_RESET,
  }),

};
