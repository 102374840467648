import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FaBarcode } from 'react-icons/fa';
import appUtils from 'utils/appUtils';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import CustomButton from 'components/form/CustomButton';
import styles from './styles';
import { useSelector } from 'react-redux';

const ResumeOrder = ({ data }) => {
  const openInNewTab = url => {
    var win = window.open(url, '_blank');
    win.focus();
  };

  const { client } = useSelector(state => state.client);

  const totalServices = manPowerData => {
    return (
      manPowerData?.reduce(
        (accumulator, service) => accumulator + service.price,
        0
      ) ?? 0
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      flex="1"
      flexWrap="wrap"
      style={styles.resumeContainer}>
      <Box flex="1" style={{ minWidth: 200, marginBottom: 20 }}>
        <Typography
          data-cy="type_payment_order_details"
          style={styles.titleBoldPurple}>
          Pagamento
        </Typography>
        <Typography>
          {data?.payment_type === '1'
            ? 'Cartão de Crédito'
            : data?.payment_type === '2'
            ? 'Boleto Bancário'
            : 'Pagamento na Entrega'}
        </Typography>
        <Box display="flex" flexDirection="row" alignItems="center">
          {data?.payment_type === '1' ? (
            <CreditCardIcon fontSize="large" style={{ marginRight: 10 }} />
          ) : data?.payment_type === '2' ? (
            <FaBarcode fontSize={40} style={{ marginRight: 10 }} />
          ) : (
            ''
          )}
          <Typography data-cy="price_type_payment" style={styles.bigFontBold}>
            {appUtils.formatPrice(Number(data.price))}
          </Typography>
        </Box>
        {data.payment_type === '2' &&
          data.payments &&
          Object.keys(data.payments).length > 0 && (
            <Box>
              <CustomButton
                label="Baixar Boleto"
                onClick={() => {
                  openInNewTab(data.payments.line_url);
                }}
                style={{ backgroundColor: 'rgb(43, 201, 26)', marginTop: 10 }}
              />
            </Box>
          )}
      </Box>
      <Box flex="1" style={{ minWidth: 200, marginBottom: 20 }}>
        <Typography style={styles.titleBoldPurple}>Total Pago</Typography>
        {data?.budget?.manpower && data?.budget?.manpower?.length > 0 && (
          <Typography>
            <b data-cy="delivery_details_order" style={{ padidngRight: 6 }}>
              Mão de Obra:{' '}
            </b>
            {appUtils.formatPrice(totalServices(data?.budget?.manpower))}
          </Typography>
        )}
        <Typography>
          <b data-cy="subtotal_details_order" style={{ marginRight: 13 }}>
            Subtotal:
          </b>
          {appUtils.formatPrice(
            data?.price -
              data?.delivery_price -
              totalServices(data?.budget?.manpower)
          )}
        </Typography>
        <Typography>
          <b>Desconto:</b> R$ 0,00
        </Typography>
        {client.group.id === 5 && (
          <Typography>
            <b data-cy="delivery_details_order" style={{ marginRight: 35 }}>
              Frete:
            </b>{' '}
            {appUtils.formatPrice(data.delivery_price)}
          </Typography>
        )}
        <Typography>
          <b data-cy="total_details_order" style={{ marginRight: 37 }}>
            Total:
          </b>{' '}
          {appUtils.formatPrice(Number(data.price))}
        </Typography>
      </Box>
      <Box flex="1" style={{ minWidth: 200 }}>
        <Typography style={styles.titleBoldPurple}>Endereço</Typography>
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          <Typography data-cy="addres_details_order" style={{ padding: 10 }}>
            {data.address.street}, {data.address.number},{' '}
            {data.address.complement}
          </Typography>
          <Typography data-cy="district_details_order" style={{ padding: 10 }}>
            <b>Bairro:</b> {data.address.district}
          </Typography>
          <Typography data-cy="city_details_order" style={{ padding: 10 }}>
            <b>Cidade:</b> {data.address.city.name}
          </Typography>
          <Typography data-cy="CEP_details_order" style={{ padding: 10 }}>
            <b>CEP:</b> {data.address.code_post}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

ResumeOrder.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ResumeOrder;
