import React from 'react';
import styles, { OnlyModile } from './styles';
import orderStatus from 'utils/order/status';
import { Typography, Box } from '@material-ui/core';
import appUtils from 'utils/appUtils';

const StatusDataMobile = ({ data }) => {
  return (
    <OnlyModile display="flex" flexDirection="column">
      <Box display="flex" alignItems="center">
        <Typography style={styles.headerPurple}>Status:</Typography>
        <Box style={styles.containerStatus}>
          <Typography style={styles.status}>
            {orderStatus(data.status)}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" style={{ marginTop: 5 }}>
        <Typography style={styles.headerPurple}>Pedido:</Typography>
        <Typography style={styles.fontBold}>{data.id}</Typography>
      </Box>
      <Box display="flex" flexDirection="row" style={{ marginTop: 5 }}>
        <Typography style={styles.headerPurple}>Valor Total:</Typography>
        <Typography style={styles.fontBold}>
          {appUtils.formatPrice(data.price + data.delivery_price)}
        </Typography>
      </Box>
    </OnlyModile>
  );
};

export default StatusDataMobile;
