import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import notImage from 'images/Products/not_image.jpg';

const StyledImg = styled('img')`
  && {
    max-height: 1800px;
    max-width: 90px;
  }
`;

const ProductImage = ({ rowData }) => (
  <div>
    {rowData.images.length > 0 && (
      <StyledImg
        src={rowData.images[0].sizes.small}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = notImage;
        }}
      />
    )}
    {rowData.images.length === 0 && <StyledImg src={notImage} />}
  </div>
);

ProductImage.propTypes = {
  rowData: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default ProductImage;
