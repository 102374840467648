import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import appUtils from 'utils/appUtils';
import styles, {
  ImageProducts,
  ContainerProducts,
  BoxProduct,
  BoxInfo,
} from './styles';

const ProductsList = ({
  title,
  subtitle,
  data,
  preco,
  catalog_code_internal,
}) => {
  const [totalLoad, setTotalLoad] = React.useState(0);
  const [isLoad, setIsLoad] = React.useState(true);

  React.useEffect(() => {
    if (totalLoad === data.length && data.length > 0) {
      setIsLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalLoad]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        flex="1"
        alignItems="center"
        style={{ display: !isLoad ? '' : 'none' }}>
        <Typography style={styles.purpleTitle}>{title}</Typography>
        <Typography style={styles.smallSubTitle}>{subtitle}</Typography>
        <ContainerProducts
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          flex="1">
          {data.map(item => (
            <BoxProduct display="flex" flexDirection="column" key={item.id}>
              <Link
                to={`/produto/${item.slug}?catalog_code_internal=${catalog_code_internal}`}
                style={{
                  width: '100%',
                  textDecoration: 'none',
                  color: 'inherit',
                }}>
                <ImageProducts>
                  <img
                    alt=""
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                    onLoad={() => {
                      setTotalLoad(totalLoad + 1);
                    }}
                    src={
                      item.images.length > 0
                        ? item.images[0].sizes.original
                        : undefined
                    }
                  />
                </ImageProducts>
                <BoxInfo
                  display="flex"
                  flex="1"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start">
                  <Typography style={{ overflow: 'hidden', maxWidth: 300 }}>
                    {item.name}
                  </Typography>
                  <Typography style={styles.ProductPrice}>
                    {preco === 'atacado'
                      ? appUtils.formatPrice(item.price_whole)
                      : appUtils.formatPrice(item.price)}
                  </Typography>
                </BoxInfo>
              </Link>
            </BoxProduct>
          ))}
        </ContainerProducts>
      </Box>
    </>
  );
};

ProductsList.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};

export default ProductsList;
