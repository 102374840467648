import styled from 'styled-components';
import { Box, Card } from '@material-ui/core';
import appColors from 'utils/appColors';

export const CartContainer = styled(Box)`
&&{
  display: flex;
  flex: 1;
  margin-top:60px;
  width: 1266px;
  margin-bottom:20px;

  @media all and (max-width:1025px){
    width:100%;
    margin-top:40px;
    padding:10px;
  }
}
`;

export const BoxColors = styled(Box)`
    width:20px; 
    margin-right:5px; 
    margin-left:5px;
    height:20px; 
    border-radius:10px; 
    border: 1px solid #DEDEDE; 
    background-color: ${props => props.hexa}
`;


export const FooterContainer = styled(Box)`
&&{
  display: flex;
  flex: 1;
  margin-top:60px;
  width: 90%;
  margin-bottom:40px;
}
`;

export const CardShopCart = styled(Card)`
    && {
        flex: 1;
        display: flex;
        padding: 40px;
        flex-direction: row;
        margin-top: 20px;
        flex-wrap: wrap;
        margin-bottom: 20px;

        @media all and (max-width: 500px) {
          padding:10px;
        }
    }
`;

export const ContainerForm = styled(Box)`
    && {
      flex: 3;
      margin-right: 20px;

      @media all and (max-width: 500px) {
        margin-right: 0px;
      }
    }
`;




const styles = {

  purpleButton: {
    backgroundColor: appColors.PRIMARY_COLOR,
    color: 'white',
    paddingLeft: 40,
    paddingRight: 40,
  },
  bigBoldTextBlack: {
    color: '#373737',
    fontWeight: 'bold',
    fontSize: 18,
    textTransform: 'uppercase',
  },

  mediumBoldTextBlack: {
    color: '#373737',
    fontWeight: 'bold',
    fontSize: 16,
  },
  smallTextBlackBold: {
    color: '#373737',
    fontWeight: 'bold',
  },
  imgFlag: {
    width: 50,
    marginRight: 10,
    marginTop: 10
  },
  bigTitlePurple:{
    color: appColors.PRIMARY_COLOR,
    fontWeight:'bold',
    fontSize:25,
  }
};

export default styles;
