/*
 *
 * cupom reducer
 *
 */

import { Types as AuthTypes } from 'store/ducks/auth';

export const Types = {
  // Obtem usuario
  GET_LIST_REQUEST: 'cupom/GET_LIST_REQUEST',
  GET_LIST_SUCCESS: 'cupom/GET_LIST_SUCCESS',
  GET_LIST_FAILURE: 'cupom/GET_LIST_FAILURE',
};

export const initialState = {
  // Categoria por id
  discount_coupon: '',
  data: [],
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Lista  cupome
    case Types.GET_LIST_REQUEST:
      return { ...state, loading: true, discount_coupon: '' };
    case Types.GET_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        discount_coupon: action.payload.discount_coupon,
        loading: false,
        error: null,
      };
    case Types.GET_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        discount_coupon: '',
      };

    case AuthTypes.GET_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export const Creators = {
  // BuscaDesconto
  // eslint-disable-next-line camelcase
  getCupomListRequest: ({ discount_coupon }, products) => ({
    type: Types.GET_LIST_REQUEST,
    payload: { discount_coupon, products },
  }),
  // eslint-disable-next-line camelcase
  getCupomListSuccess: ({ data, discount_coupon }) => ({
    type: Types.GET_LIST_SUCCESS,
    payload: { data, discount_coupon },
  }),
  getCupomListFailure: error => ({
    type: Types.GET_LIST_FAILURE,
    payload: error,
  }),
};
