import React from 'react';
import { Typography, Box } from '@material-ui/core';
import notImage from 'images/Products/not_image.jpg';
import PropTypes from 'prop-types';
import appUtils from 'utils/appUtils';
import { useSelector } from 'react-redux';
import FooterList from 'components/FooterList';
import styles, {
  ImageProducts,
  ContainerProducts,
  BoxProduct,
  BoxInfo,
} from './styles';

const ProductsListCategory = ({
  data,
  nextPage,
  total,
  page,
  perPage,
  previousPage,
  handlePage,
  action,
}) => {
  const { preco } = useSelector(state => state.parameters);

  return (
    <Box display="flex" flexDirection="column">
      <ContainerProducts>
        <Box
          style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          {data.map(item => (
            <BoxProduct key={item.id} onClick={() => action(item)}>
              <ImageProducts
                src={
                  item.images.length > 0
                    ? item.images[0].sizes.original
                    : notImage
                }
              />
              <BoxInfo
                display="flex"
                flex="1"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="flex-start">
                <Typography style={{ overflow: 'hidden', maxWidth: 300 }}>
                  {item.name}
                </Typography>
                <Typography style={styles.ProductPrice}>
                  {preco === 'atacado'
                    ? ` ${appUtils.formatPrice(item.price_whole)}`
                    : ` ${appUtils.formatPrice(item.price)}`}
                </Typography>
              </BoxInfo>
            </BoxProduct>
          ))}
        </Box>
        <FooterList
          page={page}
          total={total}
          perPage={perPage}
          previousPage={previousPage}
          nextPage={nextPage}
          handlePage={handlePage}
        />
      </ContainerProducts>
    </Box>
  );
};

ProductsListCategory.propTypes = {
  data: PropTypes.array.isRequired,
  nextPage: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  previousPage: PropTypes.func.isRequired,
  handlePage: PropTypes.func.isRequired,
};

export default ProductsListCategory;
