import React from 'react';
import styles from './styles';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@material-ui/core';
import appColors from 'utils/appColors';
import CustomModal from 'components/Modal';
import EditAddressForm from './EditAddressForm';
import ConsultaCepForm from './ConsultCepForm';



const EditAddressModal = ({ isOpen, closeModal, onSubmit, address, onSubmitCEP, addressLoading, updateLoading, addressError }) => {

  return (
    <CustomModal isOpen={isOpen} closeModal={closeModal} title='Editar Modal'>
      <Box display='flex' justifyContent='center' flexDirection='column' flex='1' style={styles.containerForm}>
        {Object.keys(address).length > 0 && !addressLoading &&
          <EditAddressForm onSubmit={onSubmit} updateLoading={updateLoading} onSubmitCEP={onSubmitCEP} cepError={addressError} initialValues={address} />
        }
        {Object.keys(address).length === 0 && !addressLoading &&
          <ConsultaCepForm onSubmit={onSubmitCEP} addressLoading={addressLoading} cepError={addressError} />
        }
        {addressLoading &&
          <Box flex='1' display='flex' justifyContent='center' alignItems='center'>
            <CircularProgress style={{ color: appColors.PRIMARY_COLOR }} />
          </Box>
        }
      </Box>
    </CustomModal>
  );
}

EditAddressModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  address: PropTypes.object.isRequired,
  onSubmitCEP: PropTypes.func.isRequired,
  addressLoading: PropTypes.bool.isRequired,
  addressError: PropTypes.any,
}

export default EditAddressModal;