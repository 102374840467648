import styled from 'styled-components';

export const ContainerImage = styled.div`
    flex: 1;
`;

export const ContainerRequest = styled.div`
    
    flex: 3;
    padding: 20px 50px;

    h3 {
        font-size: 20px;        
    }

    span {

    }

    button {
        margin: 15px 0px;
    }

`