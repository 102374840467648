export const Types = {
  // Atualiza
  GET_STOCK_UPDATE_REQUEST: 'product/GET_STOCK_UPDATE_REQUEST',
  GET_STOCK_UPDATE_SUCCESS: 'product/GET_STOCK_UPDATE_SUCCESS',
  GET_STOCK_UPDATE_FAILURE: 'product/GET_STOCK_UPDATE_FAILURE',
};

export const initialState = {
  // Atualiza
  stockProdUpdateLoading: false,
  stockProdUpdateError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Atualiza
    case Types.GET_STOCK_UPDATE_REQUEST:
      return {
        ...state,
        stockProdUpdateLoading: true,
        stockProdUpdateError: null,
      };
    case Types.GET_STOCK_UPDATE_SUCCESS:
      return {
        ...state,
        stockProdUpdateLoading: false,
        stockProdUpdateError: null,
      };
    case Types.GET_STOCK_UPDATE_FAILURE:
      return {
        ...state,
        stockProdUpdateLoading: false,
        stockProdUpdateError: action.payload,
      };
    default:
      return state;
  }
};

export const Creators = {
  // Atualiza
  getProductUpdateRequest: values => ({
    type: Types.GET_STOCK_UPDATE_REQUEST,
    payload: values,
  }),
  getProductUpdateSuccess: () => ({
    type: Types.GET_STOCK_UPDATE_SUCCESS,
  }),
  getProductUpdateFailure: error => ({
    type: Types.GET_STOCK_UPDATE_FAILURE,
    payload: error,
  }),
};
