import { call, takeLatest, all, put } from 'redux-saga/effects';
import api from 'services/api';

import { Types, Creators } from 'store/ducks/config';

function* getConfig() {
  try {
    const response = yield call(api.get, `/v1/site/config`);
    if (response.status !== 200) throw response;
    yield put(Creators.getConfigSuccess(response.data));
  } catch (err) {
    yield put(Creators.getConfigFailure('Erro ao buscar na API'));
  }
}

// Individual exports for testing
export default function* configSaga() {
  yield all([
    takeLatest(Types.GET_REQUEST, getConfig),
  ]);
}
