import { Button } from '@material-ui/core';
import React from 'react';
import appColors from 'utils/appColors';
import { withStyles } from '@material-ui/core/styles';
import { Container, Content, Titles, Buttons } from './styles';
import AppleIcon from '@material-ui/icons/Apple';
import AndroidIcon from '@material-ui/icons/Android';

const ButtonApp = withStyles(() => ({
  root: {
    color: appColors.PRIMARY_COLOR,
    borderRadius: '15px',
    padding: '10px 20px',
    '&:hover': {
      backgroundColor: appColors.PRIMARY_COLOR,
      color: '#fff',
    },
  },
}))(Button);

const BannerHome = () => {
  return (
    <Container image="/assets/Oferta/banners/b1.png">
      <Content>
        <Titles>
          <h1>Encontre as melhores ofertas bem perto de você</h1>
          <p>
            Baixe agora e confira as promoções das melhores lojas da sua cidade
          </p>

          <Buttons>
            <ButtonApp
              variant="outlined"
              color="primary"
              onClick={() =>
                (window.location.href =
                  'https://apps.apple.com/br/app/oferta-play/id1537470689')
              }>
              <AppleIcon /> Iphone
            </ButtonApp>
            <ButtonApp
              variant="outlined"
              color="primary"
              onClick={() =>
                (window.location.href =
                  'https://play.google.com/store/apps/details?id=br.com.ofertaplay.app')
              }>
              <AndroidIcon /> Android
            </ButtonApp>
          </Buttons>
        </Titles>
      </Content>
    </Container>
  );
};

export default BannerHome;
