import styled from 'styled-components';
import { Box } from '@material-ui/core';
import appColors from 'utils/appColors';


export const StoreDetailContainer = styled(Box)`
    && {
        width:1266px;
        margin-top:20px;


        @media all and (max-width:1250px) {
            margin-top:80px;
            width:90%;
        }
    }
`;

export const BoxImage = styled(Box)`
    && {
        max-height:500px;

        @media all and (max-width:500px) {
            height:400px;
            display: flex;
            justify-content: center;
            align-itens: center;
            flex-direction: column;
        }
    }
`;

export const StyledBoxDetail = styled(Box)`
    && {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid ${appColors.PRIMARY_COLOR};
        justify-content: flex-start;


        @media all and (max-width:500px) {
            justify-content: center;
            margin-left: -5%;
            margin-right: -5%;
            display: flex;
            justify-content: center;
            align-itens: center;
            flex-direction: column;
        }

    }
`;

export const LineSeparator = styled(Box)`
    && {
        border-top: 1px solid ${appColors.PRIMARY_COLOR};
        margin-top: 50px;
        padding-bottom: 20px;
    }
`;
