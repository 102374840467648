// Lista todas as Lojas
export const Types = {
  GET_STORES_LIST_REQUEST: 'stores/GET_STORES_LIST_REQUEST',
  GET_STORES_LIST_SUCCESS: 'stores/GET_STORES_LIST_SUCCESS',
  GET_STORES_LIST_FAILURE: 'stores/GET_STORES_LIST_FAILURE',
};

export const initialState = {

  storesList: [],
  storesLoading: false,
  storesListTotal: 0,
  storesError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_STORES_LIST_REQUEST:
      return {
        ...state,
        storesList: [],
        storesError: null,
        storesLoading: true,
      };
    case Types.GET_STORES_LIST_SUCCESS:
      return {
        ...state,
        storesList: action.payload.data,
        storesLoading: false,
        storesError: null,
        storesListTotal: action.payload.total,
      };
    case Types.GET_STORES_LIST_FAILURE:
      return {
        ...state,
        storesLoading: false,
        storesError: action.payload,
      };
    default:
      return state;
  }
};

export const Creators = {
  getStoresListRequest: ({
    page,
    perPage,
    search,
    orderByColumn,
    orderByDirection,
  }) => ({
    type: Types.GET_STORES_LIST_REQUEST,
    payload: { page, perPage, search, orderByColumn, orderByDirection },

  }),
  getStoresListSuccess: ({ data, total }) => ({
    type: Types.GET_STORES_LIST_SUCCESS,
    payload: { data, total },
  }),
  getStoresListFailure: error => ({
    type: Types.GET_STORES_LIST_FAILURE,
    payload: error,
  }),
};
