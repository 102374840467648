/* eslint-disable use-isnan */
import React from 'react';
import { Grid } from '@material-ui/core';
import StatisticItem from 'components/StatisticItem';
import PropTypes from 'prop-types';
import { toPrice } from 'utils/converters';
import styles from './styles';
import { Link } from 'react-router-dom';

const SaleInfo = ({ wallet, walletLoading, walletDocuments, walletStatus }) => (
  <Grid justify="center" style={{ marginTop: 30, marginBottom: 30 }} container>
    {!walletLoading && wallet && (
      <>
        <Grid item xs={12} sm={4} md={4} lg={4} style={styles}>
          <StatisticItem
            icon="attach_money"
            title="Em Conta"
            description={
              wallet?.current === null
                ? 'R$ 0'
                : `R$ ${toPrice(wallet.current)}`
            }
          />
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4} style={styles}>
          <StatisticItem
            icon="compare_arrows"
            title="A Receber"
            description={
              wallet?.future === null ? 'R$ 0' : `R$ ${toPrice(wallet.future)}`
            }
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} style={styles}>
          <Link to="/minha-conta?aba=documents">
            <StatisticItem
              icon="folder_shared"
              title="Documentos"
              description={
                walletDocuments[0]?.status === 'APPROVED'
                  ? 'Aprovado'
                  : 'Pendente'
              }
            />
          </Link>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} style={styles}>
          <StatisticItem
            icon="diversity_3"
            title="Status Comercial"
            description={
              walletStatus.commercial === 'APPROVED'
                ? 'Aprovado'
                : 'Aguardando Aprovação'
            }
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} style={styles}>
          <StatisticItem
            icon="assignment_ind"
            title="Conta"
            description={
              walletStatus.account === 'APPROVED'
                ? 'Aprovado'
                : 'Aguardando Aprovação'
            }
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} style={styles}>
          <StatisticItem
            icon="contact_page"
            title="Status Geral Da conta"
            description={
              walletStatus.general === 'APPROVED' ? 'Aprovado' : 'Pendente'
            }
          />
        </Grid>
      </>
    )}
  </Grid>
);

SaleInfo.propTypes = {
  wallet: PropTypes.oneOfType([PropTypes.object]).isRequired,
  walletLoading: PropTypes.bool.isRequired,
};

export default SaleInfo;
