import styled from 'styled-components';
import { Box } from '@material-ui/core';
import appColors from 'utils/appColors';

export const ImageProducts = styled(Box)`
  && {
    margin-bottom:8px; 
    width: 297px;
    height:368px;
    overflow:hidden;

    @media all and (max-width:500px) {
      width:100%;
      min-width:100px;
      height:238px;
      margin-right: 10px;
      justify-content: center;
    }
    @media all and (min-width:841px) {
      justify-content: center;
    }
  }
`;

export const ContainerProducts = styled(Box)`
  && {
    justify-content: flex-start;
    align-items:flex-start;
    margin-right:-25px;
    
    margin-bottom:30px;
    margin-top:20px;

    @media all and (max-width:500px) {
      justify-content: space-between;
      margin-right:0px;
      padding:5px;
    }
    @media all and (min-width:501px) and (max-width:1024px) {
      justify-content: center;
    }
    @media all and (min-width:1025px) and (max-width:1700px) {
      justify-content: center;
  }

  }
`;

export const BoxProduct = styled(Box)`
  && {
    margin-bottom: 20px;
    margin-right: 25px;

    @media all and (max-width:500px) {
      width:49%;
      margin-right: 0px;
      min-width:120px;
    }

  }
`;

export const BoxInfo = styled(Box)`
  && {
    margin-bottom:20px;
    @media all and (max-width:501px) {
      padding-left:0px;
    }

  }
`;


const styles = {

  purpleTitle: {
    color: appColors.SECUNDARY_COLOR,
    fontWeight: 'bold',
    fontSize: 32,
    textAlign:'center',
  },
  ProductPrice: {
    color: appColors.SECUNDARY_COLOR,
    fontWeight: 'bold',
    fontSize: 22,
  },
  smallSubTitle: {
    marginTop: 10,
    color: '#373737',
    fontSize: 17,
    textAlign:'center',
  },
}

export default styles
