import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { MUIDatatablesTranslations } from 'constants/translations';
import * as S from './styles';

const DefaultTable = ({
  getFunction,
  perPage,
  columns,
  data,
  isLoading,
  total,
  page,
}) => {
  const loadData = (pageL = 1, perPageL = perPage) => {
    getFunction({ page: pageL, perPage: perPageL });
  };

  const onChangePage = value => {
    if (value + 1 !== page) {
      loadData(value + 1, perPage);
    }
  };

  const onChangeRowsPerPage = value => {
    loadData(1, value);
  };

  return (
    <S.Container>
      <MaterialTable
        page={page - 1}
        localization={MUIDatatablesTranslations}
        isLoading={isLoading}
        data={data}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        totalCount={total}
        columns={columns}
        options={{
          pageSize: perPage,
          filtering: false,
          search: false,
          showTitle: false,
          toolbar: false,
          emptyRowsWhenPaging: false,
          headerStyle: {
            color: 'black',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            fontSize: 15,
          },
        }}
      />
    </S.Container>
  );
};

DefaultTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  getFunction: PropTypes.func,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  total: PropTypes.number.isRequired,
  perPage: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
};

DefaultTable.defaultProps = {
  getFunction: () => {},
  columns: [],
  perPage: 10,
};

export default DefaultTable;
