/*
 *
 * Client reducer
 *
 */

import { Types as AuthTypes } from 'store/ducks/auth';

export const Types = {
  // Obtem usuario
  GET_LIST_REQUEST: 'client/GET_LIST_REQUEST',
  GET_LIST_SUCCESS: 'client/GET_LIST_SUCCESS',
  GET_LIST_FAILURE: 'client/GET_LIST_FAILURE',
  // Obtem todos os usuario do Cliente
  GET_LISTALL_REQUEST: 'client/GET_LISTALL_REQUEST',
  GET_LISTALL_SUCCESS: 'client/GET_LISTALL_SUCCESS',
  GET_LISTALL_FAILURE: 'client/GET_LIST_FAILURE',

  // Insere um usuario
  GET_INSERT_REQUEST: 'client/GET_INSERT_REQUEST',
  GET_INSERT_SUCCESS: 'client/GET_INSERT_SUCCESS',
  GET_INSERT_FAILURE: 'client/GET_INSERT_FAILURE',

  // Insere um usuario atraves do cliente
  GET_INSERTUSERALL_REQUEST: 'client/GET_INSERTUSERALL_REQUEST',
  GET_INSERTUSERALL_SUCCESS: 'client/GET_INSERTUSERALL_SUCCESS',
  GET_INSERTUSERALL_FAILURE: 'client/GET_INSERTUSERALL_FAILURE',

  // Deleta um usuario atraves do cliente
  GET_DELETEALL_REQUEST: 'client/GET_DELETEALL_REQUEST',
  GET_DELETEALL_SUCCESS: 'client/GET_DELETEALL_SUCCESS',
  GET_DELETEALL_FAILURE: 'client/GET_DELETEALL_FAILURE',
  //envia email para usuario do cliente
  GET_SENDEMAILALL_REQUEST: 'client/GET_SENDEMAILALL_REQUEST',
  GET_SENDEMAILALL_SUCCESS: 'client/GET_SENDEMAILALL_SUCCESS',
  GET_SENDEMAILALL_FAILURE: 'client/GET_SENDEMAILALL_FAILURE',

  // Atualiza um usuario do cliente
  GET_UPDATEALL_REQUEST: 'client/GET_UPDATEALL_REQUEST',
  GET_UPDATEALL_SUCCESS: 'client/GET_UPDATEALL_SUCCESS',
  GET_UPDATEALL_FAILURE: 'client/GET_UPDATEALL_FAILURE',

  // Reativa um usu[ario atraves do cliente
  GET_RESTOREALL_REQUEST: 'client/GET_RESTOREALL_REQUEST',
  GET_RESTOREALL_SUCCESS: 'client/GET_RESTOREALL_SUCCESS',
  GET_RESTOREALL_FAILURE: 'client/GET_RESTOREALL_FAILURE',

  // Obtem collaborators deletados
  GET_LIST_DELETEDALL_REQUEST: 'client/GET_LIST_DELETEDALL_REQUEST',
  GET_LIST_DELETEDALL_SUCCESS: 'client/GET_LIST_DELETEDALL_SUCCESS',
  GET_LIST_DELETEDALL_FAILURE: 'client/GET_LIST_DELETEDALL_FAILURE',
  // Obtem Client
  GET_ClIENTALLBYID_REQUEST: 'client/GET_ClIENTALLBYID_REQUEST',
  GET_ClIENTALLBYID_SUCCESS: 'client/GET_ClIENTALLBYID_SUCCESS',
  GET_ClIENTALLBYID_FAILURE: 'client/GET_ClIENTALLBYID_FAILURE',
  // Atualiza um usuario
  GET_UPDATE_REQUEST: 'client/GET_UPDATE_REQUEST',
  GET_UPDATE_SUCCESS: 'client/GET_UPDATE_SUCCESS',
  GET_UPDATE_FAILURE: 'client/GET_UPDATE_FAILURE',

  // Deleta um usuario
  GET_DELETE_REQUEST: 'client/GET_DELETE_REQUEST',
  GET_DELETE_SUCCESS: 'client/GET_DELETE_SUCCESS',
  GET_DELETE_FAILURE: 'client/GET_DELETE_FAILURE',

  //solicita nova senha
  GET_FORGOT_REQUEST: 'client/GET_FORGOT_REQUEST',
  GET_FORGOT_SUCCESS: 'client/GET_FORGOT_SUCCESS',
  GET_FORGOT_FAILURE: 'client/GET_FORGOT_FAILURE',

  // upload profile picture
  GET_UPLOAD_PROFILE_REQUEST: 'client/GET_UPLOAD_PROFILE__REQUEST',
  GET_UPLOAD_PROFILE_SUCCESS: 'client/GET_UPLOAD_PROFILE_SUCCESS',
  GET_UPLOAD_PROFILE_FAILURE: 'client/GET_UPLOAD_PROFILE_FAILURE',
};

export const initialState = {
  // Categoria por id
  client: {},
  clientLoading: false,
  clientError: null,
  // Todos os clientes
  clientAll: [],
  clientAllLoading: false,
  clientAllTotal: false,
  clientAllError: null,
  // Insere uma categoria
  clientInsertLoading: false,
  clientInsertError: false,
  // Insere um usuário atraves do cliente
  clientInsertAll: [],
  clientInsertAllLoading: false,
  clientInsertAllError: false,
  // Atualiza uma categoria
  clientUpdateLoading: false,
  clientUpdateError: false,
  // Deleta categoria
  clientDeleteLoading: false,
  clientDeleteError: null,
  // Deleta um usuário atraves do cliente
  clientDeleteALLLoading: false,
  clientDeleteALLError: null,
  //envia email para usuario do cliente
  sendEmailAllLoading: false,
  sendEmailAllError: null,
  // atualiza um usuário atraves do cliente
  clientUpdateALLLoading: false,
  clientUpdateALLError: null,
  // Reativa um usuário atraves do cliente
  clientRestoreALLLoading: false,
  clientRestoreALLError: null,
  //lista de todos os clientes deletados
  clientListDeletedALL: [],
  clientListDeletedALLLoading: false,
  clientListDeletedALLError: null,
  clientListDeletedALLTotal: 0,
  // CLIENT por id
  clientById: {},
  clientByIdLoading: false,
  clientByIdError: false,
  // Solicita nova senha
  clientForgotLoading: false,
  clientForgotError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Lista  cliente
    case Types.GET_LIST_REQUEST:
      return { ...state, clientLoading: true };
    case Types.GET_LIST_SUCCESS:
      return {
        ...state,
        client: action.payload,
        clientLoading: false,
        clientError: null,
      };
    case Types.GET_LIST_FAILURE:
      return {
        ...state,
        clientLoading: false,
        clientError: action.payload,
      };
    // Lista todos cliente
    case Types.GET_LISTALL_REQUEST:
      return { ...state, clientAllLoading: true };
    case Types.GET_LISTALL_SUCCESS:
      return {
        ...state,
        clientAll: action.payload.data,
        clientAllLoading: false,
        clientAllTotal: action.payload.total,
        clientAllError: null,
      };
    case Types.GET_LISTALL_FAILURE:
      return {
        ...state,
        clientAllLoading: false,
        clientAllError: action.payload,
      };
    // insere cliente
    case Types.GET_INSERT_REQUEST:
      return {
        ...state,
        clientInsertLoading: true,
        clientInsertError: null,
      };
    case Types.GET_INSERT_SUCCESS:
      return {
        ...state,
        clientInsertLoading: false,
        clientInsertError: null,
      };
    case Types.GET_INSERT_FAILURE:
      return {
        ...state,
        clientInsertLoading: false,
        clientInsertError: action.payload,
      };
    // Insere um usuário atraves do cliente
    case Types.GET_INSERTUSERALL_REQUEST:
      return {
        ...state,
        clientInsertAllLoading: true,
        clientInsertAllError: null,
      };
    case Types.GET_INSERTUSERALL_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        clientInsertAll: action.payload.data,
        clientInsertAllLoading: false,
        clientInsertAllError: null,
      };
    case Types.GET_INSERTUSERALL_FAILURE:
      return {
        ...state,
        clientInsertAllLoading: false,
        clientInsertAllError: action.payload,
      };
    // Atualiza um cliente
    case Types.GET_UPDATE_REQUEST:
      return {
        ...state,
        clientUpdateLoading: true,
        clientUpdateError: null,
      };
    case Types.GET_UPDATE_SUCCESS:
      return {
        ...state,
        client: action.payload,
        clientUpdateLoading: false,
        clientUpdateError: null,
      };
    case Types.GET_UPDATE_FAILURE:
      return {
        ...state,
        clientUpdateLoading: false,
        clientUpdateError: action.payload,
      };
    // Deleta uma cliente
    case Types.GET_DELETE_REQUEST:
      return {
        ...state,
        clientDeleteLoading: true,
        clientDeleteError: null,
      };
    case Types.GET_DELETE_SUCCESS:
      return {
        ...state,
        clientDeleteLoading: false,
        clientDeleteError: null,
      };
    case Types.GET_DELETE_FAILURE:
      return {
        ...state,
        clientDeleteLoading: false,
        clientDeleteError: action.payload,
      };
    // Deleta um usuário atraves do cliente
    case Types.GET_DELETEALL_REQUEST:
      return {
        ...state,
        clientDeleteALLLoading: true,
        clientDeleteALLError: null,
      };
    case Types.GET_DELETEALL_SUCCESS:
      return {
        ...state,
        clientDeleteALLLoading: false,
        clientDeleteALLError: null,
      };
    case Types.GET_DELETEALL_FAILURE:
      return {
        ...state,
        clientDeleteALLLoading: false,
        clientDeleteALLError: action.payload,
      };
    //envia email para usuario do cliente
    case Types.GET_SENDEMAILALL_REQUEST:
      return {
        ...state,
        sendEmailAllLoading: true,
        sendEmailAllError: null,
      };
    case Types.GET_SENDEMAILALL_SUCCESS:
      return {
        ...state,
        sendEmailAllLoading: false,
        sendEmailAllError: null,
      };
    case Types.GET_SENDEMAILALL_FAILURE:
      return {
        ...state,
        sendEmailAllLoading: false,
        sendEmailAllError: action.payload,
      };
    // Atualiza um usuário do cliente
    case Types.GET_UPDATEALL_REQUEST:
      return {
        ...state,
        clientUpdateALLLoading: true,
        clientUpdateALLError: null,
      };
    case Types.GET_UPDATEALL_SUCCESS:
      return {
        ...state,
        clientUpdateALLLoading: false,
        clientUpdateALLError: null,
      };
    case Types.GET_UPDATEALL_FAILURE:
      return {
        ...state,
        clientUpdateALLLoading: false,
        clientUpdateALLError: action.payload,
      };
    // Reativa um usuário atraves do cliente
    case Types.GET_RESTOREALL_REQUEST:
      return {
        ...state,
        clientRestoreALLLoading: true,
        clientRestoreALLError: null,
      };
    case Types.GET_RESTOREALL_SUCCESS:
      return {
        ...state,
        clientRestoreALLLoading: false,
        clientRestoreALLError: null,
      };
    case Types.GET_RESTOREALL_FAILURE:
      return {
        ...state,
        clientRestoreALLLoading: false,
        clientRestoreALLError: action.payload,
      };
    // Lista de collaborators deletados
    case Types.GET_LIST_DELETEDALL_REQUEST:
      return { ...state, clientListDeletedALLLoading: true };
    case Types.GET_LIST_DELETEDALL_SUCCESS:
      return {
        ...state,
        clientListDeletedALL: action.payload.data,
        clientListDeletedALLLoading: false,
        clientListDeletedALLError: null,
        clientListDeletedALLTotal: action.payload.total,
      };
    case Types.GET_LIST_DELETEDALL_FAILURE:
      return {
        ...state,
        clientListDeletedALLLoading: false,
        clientListDeletedALLError: action.payload,
      };
    //CLiente by id
    case Types.GET_ClIENTALLBYID_REQUEST:
      return { ...state, clientByIdLoading: true };
    case Types.GET_ClIENTALLBYID_SUCCESS:
      return {
        ...state,
        clientById: action.payload,
        clientByIdLoading: false,
        clientByIdError: null,
      };
    case Types.GET_ClIENTALLBYID_FAILURE:
      return {
        ...state,
        clientByIdLoading: false,
        clientByIdError: action.payload,
      };
    // Solicita nova senha
    case Types.GET_FORGOT_REQUEST:
      return {
        ...state,
        clientForgotLoading: true,
        clientForgotError: null,
      };
    case Types.GET_FORGOT_SUCCESS:
      return {
        ...state,
        clientForgotLoading: false,
        clientForgotError: null,
      };
    case Types.GET_FORGOT_FAILURE:
      return {
        ...state,
        clientForgotLoading: false,
        clientForgotError: action.payload,
      };

    case AuthTypes.GET_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export const Creators = {
  // Busca uma categoria
  getClientRequest: () => ({
    type: Types.GET_LIST_REQUEST,
  }),
  getClientSuccess: ({ data }) => ({
    type: Types.GET_LIST_SUCCESS,
    payload: data,
  }),
  getClientFailure: error => ({
    type: Types.GET_LIST_FAILURE,
    payload: error,
  }),
  // Insere uma categoria
  getClientInsertRequest: (personalData, addressData, func) => ({
    type: Types.GET_INSERT_REQUEST,
    payload: {
      personalData,
      addressData,
    },
    func,
  }),
  getClientInsertSuccess: () => ({
    type: Types.GET_INSERT_SUCCESS,
  }),
  getClientInsertFailure: error => ({
    type: Types.GET_INSERT_FAILURE,
    payload: error,
  }),
  // Insere um usuário atraves do cliente
  getClientInsertAllRequest: data => ({
    type: Types.GET_INSERTUSERALL_REQUEST,
    payload: data,
  }),
  getClientInsertAllSuccess: data => ({
    type: Types.GET_INSERTUSERALL_SUCCESS,
    payload: data,
  }),
  getClientInsertAllFailure: error => ({
    type: Types.GET_INSERTUSERALL_FAILURE,
    payload: error,
  }),
  // Busca lista de categorias
  getClientListRequest: ({
    page,
    perPage,
    search,
    orderByColumn,
    orderByDirection,
  }) => ({
    type: Types.GET_LIST_REQUEST,
    payload: { page, perPage, search, orderByColumn, orderByDirection },
  }),
  getClientListSuccess: ({ data, total }) => ({
    type: Types.GET_LIST_SUCCESS,
    payload: { data, total },
  }),
  getClientListFailure: error => ({
    type: Types.GET_LIST_FAILURE,
    payload: error,
  }),
  // Busca todos os clientes
  getClientListAllRequest: ({
    page,
    perPage,
    search,
    orderByColumn,
    orderByDirection,
  }) => ({
    type: Types.GET_LISTALL_REQUEST,
    payload: { page, perPage, search, orderByColumn, orderByDirection },
  }),
  getClientListAllSuccess: ({ data, total }) => ({
    type: Types.GET_LISTALL_SUCCESS,
    payload: { data, total },
  }),
  getClientListAllFailure: error => ({
    type: Types.GET_LISTALL_FAILURE,
    payload: error,
  }),
  // Atualiza uma  Categoria
  // Insere uma categoria
  getClientUpdateRequest: ({
    email,
    password,
    name,
    cpf,
    genre,
    cell_phone,
    birth_date,
  }) => ({
    type: Types.GET_UPDATE_REQUEST,
    payload: {
      email,
      password,
      name,
      cpf,
      genre,
      cell_phone,
      birth_date,
    },
  }),
  getClientUpdateSuccess: ({ data }) => ({
    type: Types.GET_UPDATE_SUCCESS,
    payload: data,
  }),
  getClientUpdateFailure: error => ({
    type: Types.GET_UPDATE_FAILURE,
    payload: error,
  }),
  // Deleta uma categoria
  getClientDeleteRequest: id => ({
    type: Types.GET_DELETE_REQUEST,
    payload: { id },
  }),
  getClientDeleteSuccess: () => ({
    type: Types.GET_DELETE_SUCCESS,
  }),
  getClientDeleteFailure: error => ({
    type: Types.GET_DELETE_FAILURE,
    payload: error,
  }),
  // Deleta um usuário atraves do cliente
  getClientDeletALLeRequest: id => ({
    type: Types.GET_DELETEALL_REQUEST,
    payload: { id },
  }),
  getClientDeleteALLSuccess: () => ({
    type: Types.GET_DELETEALL_SUCCESS,
  }),
  getClientDeleteALLFailure: error => ({
    type: Types.GET_DELETEALL_FAILURE,
    payload: error,
  }),
  //envia email para usuario do cliente
  getSendEmailAllRequest: id => ({
    type: Types.GET_SENDEMAILALL_REQUEST,
    payload: { id },
  }),
  getSendEmailAllSuccess: () => ({
    type: Types.GET_SENDEMAILALL_SUCCESS,
  }),
  getSendEmailAllFailure: error => ({
    type: Types.GET_SENDEMAILALL_FAILURE,
    payload: error,
  }),
  // solicita nova senha
  getClientForgotRequest: ({ email }, handleClose) => ({
    type: Types.GET_FORGOT_REQUEST,
    payload: { email },
    handleClose,
  }),
  getClientForgotSuccess: () => ({
    type: Types.GET_FORGOT_SUCCESS,
  }),
  getClientForgotFailure: error => ({
    type: Types.GET_FORGOT_FAILURE,
    payload: error,
  }),
  getClientALLUpdateRequest: ({
    id,
    address,
    birth_date,
    cpf,
    email,
    genre,
    name,
    cell_phone,
    password,
  }) => ({
    type: Types.GET_UPDATEALL_REQUEST,
    payload: {
      id,
      address,
      birth_date,
      cpf,
      email,
      genre,
      name,
      cell_phone,
      password,
    },
  }),
  getClientALLUpdateSuccess: () => ({
    type: Types.GET_UPDATEALL_SUCCESS,
  }),
  getClientALLUpdateFailure: error => ({
    type: Types.GET_UPDATEALL_FAILURE,
    payload: error,
  }),
  // Reativa um usuário atraves do cliente
  getClientRestoreALLRequest: id => ({
    type: Types.GET_RESTOREALL_REQUEST,
    payload: { id },
  }),
  getClientRestoreALLSuccess: () => ({
    type: Types.GET_RESTOREALL_SUCCESS,
  }),
  getClientRestoreALLFailure: error => ({
    type: Types.GET_RESTOREALL_FAILURE,
    payload: error,
  }),
  // Busca lista de clientes deleted
  getCleinteDeletedListRequest: ({
    page,
    perPage,
    search,
    orderByColumn,
    orderByDirection,
    group_id,
  }) => ({
    type: Types.GET_LIST_DELETEDALL_REQUEST,
    payload: {
      page,
      perPage,
      search,
      orderByColumn,
      orderByDirection,
      group_id,
    },
  }),
  getClienteDeletedListSuccess: ({ data, total }) => ({
    type: Types.GET_LIST_DELETEDALL_SUCCESS,
    payload: { data, total },
  }),
  getClienteListFailure: error => ({
    type: Types.GET_LIST_DELETEDALL_FAILURE,
    payload: error,
  }),
  // Busca um colaborador
  getclientByIDRequest: ({ access_token }) => ({
    type: Types.GET_ClIENTALLBYID_REQUEST,
    payload: { access_token },
  }),
  getclientByIDSuccess: ({ data }) => ({
    type: Types.GET_ClIENTALLBYID_SUCCESS,
    payload: data,
  }),
  getclientByIDFailure: error => ({
    type: Types.GET_ClIENTALLBYID_FAILURE,
    payload: error,
  }),

  // profile
  getClientUploadProfileRequest: data => ({
    type: Types.GET_UPLOAD_PROFILE_REQUEST,
    payload: data,
  }),
  getClientUploadProfileSuccess: data => ({
    type: Types.GET_UPLOAD_PROFILE_SUCCESS,
    payload: data,
  }),
  getClientUploadProfileFailure: error => ({
    type: Types.GET_UPLOAD_PROFILE_FAILURE,
    payload: error,
  }),
};
