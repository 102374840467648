import { createMuiTheme } from '@material-ui/core';

export default createMuiTheme({
  props: {
    name: 'Oferta',
  },
  palette: {
    primary: {
      main: '#FA8F47',
      light: '#FA8F47',
      dark: '#E76308',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ff0',
      contrastText: '#fff',
    },
    companyBlue: {
      backgroundColor: '#65CFE9',
      color: '#fff',
    },
    companyRed: {
      backgroundColor: '#E44D69',
      color: '#000',
    },
  },
});
