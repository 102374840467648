import React from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { InputContainer, InputItem } from 'components/form/StyledComponents';
import CustomMaskField from 'components/form/CustomMaskField';
import CustomTextField from 'components/form/CustomTextField';
import FormButtons from 'components/form/components/FormButtons';
import { useDispatch } from 'react-redux';
import { Creators as StoreCreators } from 'store/ducks/admin/stores';

const TabContainer = ({ children }) => {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
};

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export const formInitialValues = {
  name: '',
  phone: '',
  email: '',
};
const schema = Yup.object().shape({
  name: Yup.string().required('Este campo é obrigatório'),
  phone: Yup.string().required('Este campo é obrigatório'),
  email: Yup.string()
    .email('E-mail invalido!')
    .required('Este campo é obrigatório'),
});

const CollaboratorForm = ({
  onSubmit,
  initialValues = formInitialValues,
  submitText,
  isLoading,
}) => {
  const dispatch = useDispatch();

  const formikRef = React.useRef(null);

  React.useEffect(() => {
    dispatch(StoreCreators.getStoreListRequest({ perPage: 1000 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      ref={formikRef}
      onSubmit={onSubmit}
      validateOnBlur
      enableReinitialize
      render={() => (
        <Form>
          <InputContainer>
            <InputItem>
              <InputItem style={{ flex: 2 }}>
                <InputContainer>
                  <InputItem>
                    <Field
                      name="name"
                      label="Nome"
                      component={CustomTextField}
                    />
                  </InputItem>
                </InputContainer>
                <InputContainer style={{ marginTop: 25 }}>
                  <InputItem>
                    <Field
                      name="email"
                      label="E-mail"
                      component={CustomTextField}
                    />
                  </InputItem>
                  <InputItem>
                    <Field
                      name="phone"
                      label="Telefone"
                      mask="(99) 99999-9999"
                      component={CustomMaskField}
                    />
                  </InputItem>
                </InputContainer>
                <br></br>
              </InputItem>
            </InputItem>
          </InputContainer>

          <FormButtons isLoading={isLoading} submitText={submitText} />
        </Form>
      )}
    />
  );
};

CollaboratorForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.oneOfType([PropTypes.object]),
  submitText: PropTypes.string,
  handleBack: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isLoading: PropTypes.bool.isRequired,
};

CollaboratorForm.defaultProps = {
  onSubmit: () => {},
  initialValues: formInitialValues,
  submitText: 'Salvar',
  handleBack: false,
};

export default CollaboratorForm;
