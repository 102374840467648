import React from 'react';
import { FormHelperText, TextField, InputAdornment } from '@material-ui/core';

import styled from 'styled-components';
import { ErrorMessage } from 'formik';
import InputMask from 'react-input-mask';

const StyledTextField = styled(TextField)`
  && {
    .MuiOutlinedInput-root {
      background: white;
    }
    & .MuiOutlinedInput-input {
      padding: 12px 12px;
    }
    & .MuiInputLabel-outlined[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }
`;

const CustomMaskField2 = ({
  mask,
  field,
  label,
  form,
  endAdornment,
  maxLength,
  style,
  placeholder,
}) => {
  return (
    <>
      <InputMask
        {...field}
        style={style}
        fullWidth
        variant="outlined"
        maskChar={''}
        mask={mask}
        placeholder={placeholder}
        InputProps={
          endAdornment
            ? {
                endAdornment: (
                  <InputAdornment position="end">{endAdornment}</InputAdornment>
                ),
              }
            : maxLength
            ? { inputProps: { maxLength } }
            : null
        }
        error={Boolean(form.touched[field.name] && form.errors[field.name])}>
        {inputProps => <StyledTextField label={label} {...inputProps} />}
      </InputMask>
      <ErrorMessage name={field.name}>
        {msg => <FormHelperText error>{msg}</FormHelperText>}
      </ErrorMessage>
    </>
  );
};

export default CustomMaskField2;
