import styled, { css } from 'styled-components';
import { Box, Card } from '@material-ui/core';
import { isMobile } from 'react-device-detect';

export const CartContainer = styled(Box)`
  && {
    width: 1266px;
    margin-top: 10px;

    @media all and (max-width: 500px) {
      margin-top: 80px;
      width: 90%;
    }
    @media all and (min-width: 501px) and (max-width: 920px) {
      width: 96%;
    }
    @media all and (min-width: 921px) and (max-width: 1740px) {
      width: 90%;
    }
  }
`;

export const CardShopCart = styled(Card)`
  && {
    flex: 1;
    display: flex;
    padding: 40px;
    flex-direction: row;
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;

    @media all and (max-width: 500px) {
      padding: 10px;
    }
  }
`;

export const MobileBar = styled.a`
  width: 100%;
  height: 60px;
  margin: 10px 0px;
  text-align: center;
  background: #fa8f47;
  color: #fff;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${props =>
    !isMobile &&
    css`
      display: none;
    `}
`;
