import React from 'react';
import PageBase from 'components/PageBase';
import UserForm from 'components/UserPage/UserForm';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from 'store/ducks/admin/user';
import { push } from 'connected-react-router';

const UserInsertPage = () => {
  const dispatch = useDispatch();
  const onSubmit = data => {
    dispatch(Creators.getUserInsertRequest(data));
  };

  const handleBack = () => {
    dispatch(push(`/users`));
  };

  const { userInsertLoading } = useSelector(state => state.admin.user);

  return (
    <PageBase title="Cadastrar Usuário">
      <UserForm
        onSubmit={onSubmit}
        handleBack={handleBack}
        isLoading={userInsertLoading}
      />
    </PageBase>
  );
};

export default UserInsertPage;
