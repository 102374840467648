/*
 *
 * Store reducer
 *
 */

export const Types = {
  // Obtem usuario
  GET_LIST_REQUEST: 'store/GET_LIST_REQUEST',
  GET_LIST_SUCCESS: 'store/GET_LIST_SUCCESS',
  GET_LIST_FAILURE: 'store/GET_LIST_FAILURE',

  // Insere um usuario
  GET_INSERT_REQUEST: 'store/GET_INSERT_REQUEST',
  GET_INSERT_SUCCESS: 'store/GET_INSERT_SUCCESS',
  GET_INSERT_FAILURE: 'store/GET_INSERT_FAILURE',

  // Atualiza um usuario
  GET_UPDATE_REQUEST: 'store/GET_UPDATE_REQUEST',
  GET_UPDATE_SUCCESS: 'store/GET_UPDATE_SUCCESS',
  GET_UPDATE_FAILURE: 'store/GET_UPDATE_FAILURE',

  // Deleta um usuario
  GET_DELETE_REQUEST: 'store/GET_DELETE_REQUEST',
  GET_DELETE_SUCCESS: 'store/GET_DELETE_SUCCESS',
  GET_DELETE_FAILURE: 'store/GET_DELETE_FAILURE',

  //solicita nova senha
  GET_FORGOT_REQUEST: 'store/GET_FORGOT_REQUEST',
  GET_FORGOT_SUCCESS: 'store/GET_FORGOT_SUCCESS',
  GET_FORGOT_FAILURE: 'store/GET_FORGOT_FAILURE',
};

export const initialState = {
  // Insere uma loja
  storeInsertLoading: false,
  storeInsertError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // insere store
    case Types.GET_INSERT_REQUEST:
      return {
        ...state,
        storeInsertLoading: true,
        storeInsertError: null,
      };
    case Types.GET_INSERT_SUCCESS:
      return {
        ...state,
        storeInsertLoading: false,
        storeInsertError: null,
      };
    case Types.GET_INSERT_FAILURE:
      return {
        ...state,
        storeInsertLoading: false,
        storeInsertError: action.payload,
      };
    default:
      return state;
  }
};

export const Creators = {
  // Insere uma loja
  getStoreInsertRequest: data => ({
    type: Types.GET_INSERT_REQUEST,
    payload: {
      ...data,
    },
  }),
  getStoreInsertSuccess: () => ({
    type: Types.GET_INSERT_SUCCESS,
  }),
  getStoreInsertFailure: error => ({
    type: Types.GET_INSERT_FAILURE,
    payload: error,
  }),
};
