import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import ConfimationEmailRegister from 'components/Pages/ConfirmationEmailRegister';

const RegisterSucess = () => {
  return (
    <Box display="flex" justifyContent="center" flexDirection="column" flex="1">
      <ConfimationEmailRegister />
    </Box>
  );
};

RegisterSucess.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default RegisterSucess;
