/*
 *
 * reseller reducer
 *
 */

import { Types as AuthTypes } from 'store/ducks/auth';
export const Types = {
  // Insere
  GET_INSERT_REQUEST: 'reseller/GET_INSERT_REQUEST',
  GET_INSERT_SUCCESS: 'reseller/GET_INSERT_SUCCESS',
  GET_INSERT_FAILURE: 'reseller/GET_INSERT_FAILURE',
};

export const initialState = {
  // Categoria por id
  reseller: {},
  resellerLoading: false,
  resellerError: null,
  // Insere uma categoria
  resellerInsertLoading: false,
  resellerInsertError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // insere reseller
    case Types.GET_INSERT_REQUEST:
      return {
        ...state,
        resellerInsertLoading: true,
        resellerInsertError: null,
      };
    case Types.GET_INSERT_SUCCESS:
      return {
        ...state,
        resellerInsertLoading: false,
        resellerInsertError: null,
      };
    case Types.GET_INSERT_FAILURE:
      return {
        ...state,
        resellerInsertLoading: false,
        resellerInsertError: action.payload,
      };
    case AuthTypes.GET_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export const Creators = {
  // Insere
  getResellerInsertRequest: (enterpriseData, resellerData, func) => ({
    type: Types.GET_INSERT_REQUEST,
    payload: {
      enterpriseData,
      resellerData,
    },
    func,
  }),
  getResellerInsertSuccess: () => ({
    type: Types.GET_INSERT_SUCCESS,
  }),
  getResellerInsertFailure: error => ({
    type: Types.GET_INSERT_FAILURE,
    payload: error,
  }),
};
