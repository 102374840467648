import appTheme from './appTheme';
import LogoVerticalBuriti from 'images/logos/logo2.png';
import LogoVerticalOferta from 'images/logos/logo_vertical_oferta.png';
import LogoVerticalSimer from 'images/logos/simerpay.png';

const appConfig = {
  Buriti: {
    // GENERAL
    SITE_WEB: 'e.buritishopping.com.br',
    SITE_BACKOFFICE: 'admin.e.buritishopping.com.br/www.buriti.com.br',
    SITE_APP: 'www.buriti.com.br',
    FONE_01: '(62) 3996-2143',
    FONE_02: '(62) 3996-2143',
    EMAIL: 'administrativo@buritishopping.app',
    ADDRESS:
      'CEP 74916-260, Avenida Rio Verde, N 1640, Qd.67d, Lt.04, Jardim Nova Era, Aparecida de Goiânia, Goiás',
    CEP: '74916-260',
    CNPJ: '01.599.199/0001-89',
    LOGO_VERTICAL: LogoVerticalBuriti,
  },
  Oferta: {
    SITE_WEB: 'minhaconta.ofertaplay.com.br',
    SITE_BACKOFFICE: 'admin.ofertaplay.com.br',
    SITE_APP: 'www.ofertaplay.com.br',
    FONE_01: '(62) 3996-2143',
    FONE_02: '(62) 3996-2143',
    EMAIL: 'administrativo@ofertaplay.app',
    ADDRESS:
      'CEP 74916-260, Avenida Rio Verde, N 1640, Qd.67d, Lt.04, Jardim Nova Era, Aparecida de Goiânia, Goiás',
    CEP: '74916-260',
    CNPJ: '01.599.199/0001-89',
    LOGO_VERTICAL: LogoVerticalOferta,
    APP_LINK_ANDROID:
      'https://play.google.com/store/apps/details?id=br.com.ofertaplay.app',
    APP_LINK_IOS: 'https://apps.apple.com/br/app/oferta-play/id1537470689',
  },
  SimerPay: {
    SITE_WEB: 'simerpay.site.sensiconnect.io',
    SITE_BACKOFFICE: 'simerpay.admin.sensiconnect.io',
    SITE_APP: 'simerpay.site.sensiconnect.io',
    FONE_01: '(62) 3996-2143',
    FONE_02: '(62) 3996-2143',
    EMAIL: 'administrativo@simerpay.com.br',
    ADDRESS:
      'Endereço: Rua C-152 Nº 1131 Qd 405 Lt 10 Sl 01 Jardim América, Goiânia-GO | Telefone: (62) 3286-6591 | CEP: 74.275-120 | Horario de atendimento: 08:00hrs as 17:00hrs | Email: financeiro@dduo.com.br',
    CEP: '74916-260',
    CNPJ: '14.545.013/0001-50',
    LOGO_VERTICAL: LogoVerticalSimer,
    APP_LINK_ANDROID:
      'https://play.google.com/store/apps/details?id=com.simerpay',
    APP_LINK_IOS: 'https://apps.apple.com/br/app/simer-pay/id1590387388',
  },
  Sandbox: {
    SITE_WEB: 'sandbox.site.sensiconnect.io',
    SITE_BACKOFFICE: 'sandbox.admin.sensiconnect.io',
    SITE_APP: 'sandbox.site.sensiconnect.io',
    FONE_01: '(62) 3996-2143',
    FONE_02: '(62) 3996-2143',
    EMAIL: 'sandbox@sensiconnect.io',
    ADDRESS:
      'CEP 74916-260, Avenida Rio Verde, N 1640, Qd.67d, Lt.04, Jardim Nova Era, Aparecida de Goiânia, Goiás',
    CEP: '74916-260',
    CNPJ: '01.599.199/0001-89',
  },
  OlharPix: {
    SITE_WEB: 'olharpix.site.sensiconnect.io',
    SITE_BACKOFFICE: 'olharpix.admin.sensiconnect.io',
    SITE_APP: 'olharpix.site.sensiconnect.io',
    FONE_01: '(62) 3996-2143',
    FONE_02: '(62) 3996-2143',
    EMAIL: 'olharpix@sensiconnect.io',
    ADDRESS:
      'CEP 74916-260, Avenida Rio Verde, N 1640, Qd.67d, Lt.04, Jardim Nova Era, Aparecida de Goiânia, Goiás',
    CEP: '74916-260',
    CNPJ: '01.599.199/0001-89',
  },
}[appTheme.name];

export default appConfig;
